import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import Constants from '../constants';
import { UserContext } from '../contexts/user';
import { WpLandingPage } from '../contexts/campaign';
import Spinner from '../components/Spinner';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import Input, { Label } from '../components/Input';
import Thumbnail from '../components/Shared/Thumbnail';
import { GET_LANDING_PAGES } from '../graphql/queries';
import { ARCHIVE_LANDING_PAGES, CREATE_LANDING_PAGE } from '../graphql/campaign';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, IconButton } from '@whistlepitch/wp-components'
import { numberWithCommas } from '../tools/tools'
import Checkbox from '../components/Checkbox';
import Icon from '../components/Icon';
import WpModal from '../components/WpModal';
import Toast from 'react-native-toast-message';
import NewButton from '../components/NewButton';
import { useNavigate } from '../react-router';
import BasicLayout from '../components/Shared/BasicLayout';
import LandingPageModal from '../components/Campaign/LandingPageModal';

export enum LandingPageAction {
  ARCHIVE = "ARCHIVE",
  CAMPAIGN = "CAMPAIGN",
  MESSAGE = "MESSAGE",
}


export default function LandingPages(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [landingPages, setLandingPages] = useState<WpLandingPage[]>([]);
  const [selectedLandingPages, setSelectedLandingPages] = useState<string[]>([]);
  const [hovering, setHovering] = useState<"landingPages"|"leads">();
  const [editingLandingPage, setEditingLandingPage] = useState<WpLandingPage>();
  const [newName, setNewName] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<LandingPageAction>();
  const [campaignId, setCampaignId] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [messageContent, setMessageContent] = useState<object>();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);

  const navigate = useNavigate();

  const [getLandingPages, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_LANDING_PAGES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [createLandingPage, createLandingPageData] = useMutation(CREATE_LANDING_PAGE, {
    fetchPolicy: "no-cache"
  });

  const [archiveLandingPages, archiveLandingPagesData] = useMutation(ARCHIVE_LANDING_PAGES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (archiveLandingPagesData.data) {
      closeModal();
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: `The landing page(s) have been archived.`
      });
      loadLandingPages();
    }
  }, [archiveLandingPagesData.data])

  useEffect(() => {
    loadLandingPages();
  }, [state.user])

  useEffect(() => {
    if (data?.landingPages?.landingPages) {
      setLandingPages(data.landingPages.landingPages)
    }
  }, [data])

  useEffect(() => {
    if (createLandingPageData?.data?.createLandingPage) {
      if (createLandingPageData.data.createLandingPage.success) {
        setEditingLandingPage(createLandingPageData.data.createLandingPage.landingPage);
      }
    }
  }, [createLandingPageData?.data])

  const loadLandingPages = () => {
    if (state.user) {
      if (data) {
        refetch();
      } else {
        getLandingPages();
      }
    }
  }

  const onHoverLandingPages = () => {
    setHovering("landingPages");
  }

  const onHoverOut = () => {
    setHovering(undefined);
  }

  const openLandingPageModal = (landingPage: WpLandingPage) => {
    navigate('/landing-page/' + landingPage.id)
  }

  const closeLandingPageModal = () => {
    loadLandingPages();
    setEditingLandingPage(undefined)
  }

  const onSelectLandingPage = (id: string) => {
    const index = selectedLandingPages.indexOf(id);
    if (index > -1) {
      setSelectedLandingPages([
         ...selectedLandingPages.slice(0, index),
         ...selectedLandingPages.slice(index + 1),
      ])
    } else {
      setSelectedLandingPages([
        ...selectedLandingPages,
        id
      ])
    }
  }

  const onCreateLandingPage = () => {
    createLandingPage()
  }

  const doneEditLandingPage = () => {
    setEditingLandingPage(undefined)
    loadLandingPages();
  }

  const closeModal = () => {
    setIsVisible(false);
    setLoading(false);
    setAction(undefined);
  }

  const getSelectedText = () => {
    if (selectedLandingPages.length) {
      let name = 'Landing Page'
      return `${ selectedLandingPages.length } ${name}${ selectedLandingPages.length > 1 ? 's' : '' } Selected`
    } else {
      return 'Select'
    }
  }

  const onClear = () => {
    setSelectedLandingPages([]);
  }

  const getTitle = () => {
    switch (action) {
      default:
        return "Are You Sure?"
    }
  }

  const renderActionBody = () => {
    let name = 'landing page'
    let archiveText = `Archiving a ${name} will remove it from your account. You will no longer be able to see or manage this ${name}.`;
    switch (action) {
      case LandingPageAction.ARCHIVE:
        return (<Text>{archiveText}</Text>)
      default:
        return null;
    }
  }

  const getButtonText = () => {
    switch (action) {
      case LandingPageAction.ARCHIVE:
        return Constants.blastView ? "Delete" : "Archive"
      default:
        return "Confirm"
    }
  }

  const isDisabled = () => {
    switch (action) {
      default:
        return false
    }
  }

  const onConfirm = () => {
    setLoading(true);
    switch (action) {
      case LandingPageAction.ARCHIVE:
        archiveLandingPages({ variables: { landingPageIds: selectedLandingPages }});
        setSelectedLandingPages([]);
        return;
      default:
        closeModal();
    }
  }

  const archive = () => {
    startAction(LandingPageAction.ARCHIVE);
  }

  const startAction = (action: LandingPageAction) => {
    if (selectedLandingPages.length > 0) {
      setAction(action);
      openModal();
    } else {
      Toast.show({
        type: 'error',
        text1: 'Select Landing Pages',
        text2: `Select landing pages from below first.`
      });
    }
  }

  const openModal = () => {
    setIsVisible(true);
  }

  const renderBody = () => {
    if (loading) {
      return (
        <View>
          <Spinner />
        </View>
      )
    } else if (state.user) {
      return (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.heading}>{'Landing Pages'}</Text>
            <NewButton name={'Landing Page'} onClick={onCreateLandingPage} loading={createLandingPageData?.loading} />
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 10, marginBottom: 20 }}>
            { selectedLandingPages.length > 0 ?
              <View style={styles.controlsWrapper}>
                <View style={styles.controls}>
                  <Checkbox color={Constants.colors.darkBlue} onSelect={onClear} selected={true} />
                  <Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium' }}>{ getSelectedText() }</Text>
                </View>
              </View> : null }
            <IconButton type="White" shape="Circle" onClick={archive}>
              <Icon name="trash" width={20} raw={true} colorHover="currentColor" color="currentColor" />
            </IconButton>
          </View>
          <View style={styles.thumbnailWrapper}>
            { landingPages && landingPages.length ? landingPages.map((landingPage, index) => {
              return (
                <Thumbnail
                  key={`landing-page-${landingPage.id}-${index}`}
                  text={landingPage.title ? landingPage.title : 'New Landing Page'}
                  subtext={`${'Leads'}: ${numberWithCommas(landingPage.stats.leads)}`}
                  selectable={true}
                  selected={selectedLandingPages.indexOf(landingPage.id) > -1}
                  onSelect={() => onSelectLandingPage(landingPage.id)}
                  onPress={() => setEditingLandingPage(landingPage)}
                />
              );
            }) : <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary }}>- No Landing Pages Found -</Text> }
            </View>
        </View>
      )
    } else {
      return (
        <View>
        </View>
      )
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/landing-pages'}>
      { renderBody() }

      <WpModal isVisible={isVisible} closeModal={closeModal}>
        <ModalBody title={getTitle()} closeModal={closeModal}>
          { renderActionBody() }
          <ModalFooter>
            <Button disabled={isDisabled()} onClick={onConfirm} shape="Pill" size="Default" type={action === LandingPageAction.ARCHIVE ? "Danger" : "Primary"}>
              { isLoading ? <Spinner /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>{getButtonText()}</Text> }
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <LandingPageModal landingPage={editingLandingPage} isVisible={!!editingLandingPage} closeModal={doneEditLandingPage} />
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap'
  },
  controlsWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: Constants.colors.lightBlue,
    alignItems: 'center',
    marginRight: 10
  },
  controls: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
})
