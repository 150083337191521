import React, { useState } from "react";
import { View, Text, StyleSheet, LayoutChangeEvent } from "react-native";
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { Brand, BrandHeading, BrandLogo } from '@whistlepitch/wp-components'
import Constants from "../constants";
import Wave from "react-wavify";

/*
## HIPAA/HITECH Compliance

[Your Company Name] is dedicated to maintaining the privacy and protection of your personal health information in compliance with the Health Insurance Portability and Accountability Act (HIPAA) and the Health Information Technology for Economic and Clinical Health (HITECH) Act. We use this information only to provide the services you have requested and as permitted by law.

### Use and Disclosure of Protected Health Information
Your protected health information may be used and disclosed for treatment, payment, healthcare operations, and other purposes permitted or required by law. We implement stringent measures to ensure the confidentiality, integrity, and security of this information.

### Safeguards
We employ physical, administrative, and technical safeguards to protect your information and to ensure compliance with HIPAA and HITECH regulations.

### Your Rights Under HIPAA
You have the right to access and control your health information. This includes requests to access, correct, or amend your health records as well as to receive an accounting of disclosures.

### Breach Notification
In the unlikely event of a breach of your unsecured personal health information, we will notify you as required by law.

### Contact Information
If you have any questions about our privacy practices or the protection of your information, please contact our Privacy Officer at [Contact Information].

*/

export default function Policy(): JSX.Element {
  const [viewWidth, setViewWidth] = useState<number>(0);

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  return (
    <View style={styles.container} onLayout={onLayout}>
      <Brand size="Large" brand={Constants.brand} />
      <Text style={styles.title}>Privacy Policy</Text>
      <View style={styles.textWrapper}>
        <Text style={styles.heading}>1. Introduction</Text>
        <Text style={styles.text}>
          Welcome to {Constants.brand}. We are committed to protecting your privacy and ensuring that your personal information is handled safely and responsibly. This Privacy Policy outlines how we collect, use, and protect the information you provide when using our services.
        </Text>

        <Text style={styles.heading}>2. Information We Collect</Text>
        <Text style={styles.text}>
          We collect the following types of information:
          {"\n"}- Personal Identification Information: Including your name, email address, phone number, company name, and payment information.
          {"\n"}- Activity Data: Information on how you interact with our site, gathered through tracking technologies.
          {Constants.oppText ? "\n- Health Information: Details related to your health as provided by account holders restricted to name, phone, possible appointment dates and limited details about your state of physical or mental health." : null}
        </Text>

        <Text style={styles.heading}>3. Use of Your Information</Text>
        <Text style={styles.text}>
          Your information is used to:
          {"\n"}- Deliver the services you have requested.
          {"\n"}- Improve our services and website through analytics.
          {"\n"}- Conduct marketing activities for services{Constants.oppText ? ' unrelated to your health data.' : '.'}
        </Text>

        <Text style={styles.heading}>4. Sharing Your Information</Text>
        <Text style={styles.text}>
          We do not share your personal information with third parties unless it is necessary to offer our services, comply with the law, or manage our business operations. In cases of a merger or acquisition, your information may be transferred as part of our business assets.
        </Text>

        <Text style={styles.heading}>5. Your Rights</Text>
        <Text style={styles.text}>
          You have the right to access, correct, or delete your personal information. Please contact our customer support at support@{Constants.domain} to exercise these rights.
        </Text>

        <Text style={styles.heading}>6. Data Security</Text>
        <Text style={styles.text}>
          We protect your information using industry-standard security measures, including encryption and secure servers. We are committed to ensuring your information is secure with us.
        </Text>

        <Text style={styles.heading}>7. Changes to This Policy</Text>
        <Text style={styles.text}>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.
        </Text>

        {Constants.oppText ? <Text style={styles.heading}>8. HIPAA/HITECH Compliance</Text> : null}
        {Constants.oppText ?
          <Text style={styles.text}>
            {Constants.brand} is dedicated to maintaining the privacy and protection of your personal health information (PHI) in compliance with the Health Insurance Portability and Accountability Act (HIPAA) and the Health Information Technology for Economic and Clinical Health (HITECH) Act. The PHI we collect is limited to names, phone numbers, possible appointment dates, and details concerning the physical or mental health of clients. This information is used exclusively by account owners to contact their clients via SMS to confirm to provide updates and additional information regarding their health and care.

            {"\n"}Your PHI may be disclosed only for the purpose of delivering these services, and as permitted or required by law. We implement stringent physical, administrative, and technical safeguards to ensure the confidentiality, integrity, and security of your PHI.

            {"\n"}You have the right to access, control, and correct your PHI. Requests to amend health records or receive an accounting of disclosures can be directed to our Privacy Officer.

            {"\n"}In the event of a breach involving your unsecured PHI, you will be notified as required by law.

            {"\n"}If you have any questions about how we protect your health information or would like to request a BAA, please contact us at support@{Constants.domain}.
          </Text> : null}

        <Text style={styles.heading}>{Constants.oppText ? '9' : '8'}. Contact Us</Text>
        <Text style={styles.text}>
          If you have any questions about this Privacy Policy, please contact us at support@{Constants.domain} or call us at (801) 683-9514.
        </Text>
      </View>

      <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2 }}>
        <Wave
          style={{ height: 500 }}
          fill={Constants.colors.darkBlue}
          paused={true}
          options={{
            height: 200,
            amplitude: 500,
            points: 2
          }}
        />
        <View style={{ position: 'absolute', bottom: 40, left: 0, right: 0, flexDirection: viewWidth > 675 ? 'row' : 'column', justifyContent: 'space-between', alignItems: viewWidth > 675 ? 'flex-start' : 'center', paddingHorizontal: 40 }}>
          <View style={{ alignItems: viewWidth > 675 ? 'flex-start' : 'center' }}>
            <View style={{ flexDirection: 'row', gap: 20, marginTop: 20 }}>
              <View style={{ flexDirection: 'row' }}>
                <Feather name="phone" size={14} color="white" />
                {viewWidth > 400 ? <Text style={{ color: '#FFF', paddingLeft: 15 }}>(801) 683-9514</Text> : null}
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Feather name="mail" size={14} color="white" />
                {viewWidth > 400 ? <Text style={{ color: '#FFF', paddingLeft: 15 }}>info@{Constants.domain}</Text> : null}
              </View>
            </View>
          </View>
          <View style={{ alignItems: viewWidth > 675 ? 'flex-start' : 'center', marginTop: 10 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
              <Text style={{ color: '#FFF' }}>{Constants.brand}</Text>
              <MaterialIcons name="copyright" size={12} color="white" />
              <Text style={{ color: '#FFF' }}>2019-{new Date().getFullYear()}</Text>
            </View>
            <Text style={{ color: '#FFF', opacity: 0.5, fontSize: 10, marginTop: 5 }}>{Constants.version}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    alignItems: 'center',
    paddingTop: 50,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 30,
  },
  text: {
    fontSize: 16,
    textAlign: 'justify',
    lineHeight: 24,
    marginBottom: 30,
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
    marginRight: 'auto'
  },
  textWrapper: {
    width: '90%',
    maxWidth: 1000,
  }
});
