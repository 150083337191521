import { View, Text, StyleSheet, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import Toast from 'react-native-toast-message';

import Constants from '../constants';
import { UserContext, WpUserAction } from '../contexts/user';
import CampaignOverview from '../components/CampaignOverview';
import ConfirmPhoneModal from '../components/User/ConfirmPhoneModal';
import LeadTable from '../components/Leads/LeadTable';
import Navbar from '../components/Navbar';
import { LOGIN_USER } from '../graphql/user';
import { useNavigate, useParams, useSearchParams } from '../react-router';
import { LoginView } from '@whistlepitch/wp-components';
import { useQuery, useMutation } from "@apollo/client";
import { GET_USER } from '../graphql/queries';
import Spinner from '../components/Spinner';

interface RegisterParams {
  token: string,
  name: string
  email: string,
  phone: string,
  password: string,
  password2: string,
  organizationName: string
}

export default function Login(): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const { data, loading, error } = useQuery(GET_USER, {
    fetchPolicy: "no-cache"
  });

  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [confirmingPhone, setConfirmingPhone] = useState<boolean>(false);
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string | null>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [token, setToken] = useState<string>();
  const [variables, setVariables] = useState<RegisterParams>();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const [loginUser, loginUserData] = useMutation(LOGIN_USER, {
    fetchPolicy: "no-cache"
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      if (data.user.success) {
        dispatch({ type: WpUserAction.REFRESH_USER, user: data?.user?.user })
        navigate('/dashboard')
      }
    }
  }, [data])

  useEffect(() => {
    if (loginUserData.data) {
      if (!loginUserData.data.loginUser.success) {
        setLoggingIn(false)
        if (loginUserData.data.loginUser.errors.length) {
          setErrorMessage(loginUserData.data.loginUser.errors[0])
        } else {
          setErrorMessage('An unknown error occurred.')
        }
      } else {
        if (loginUserData.data.loginUser.token) {
          localStorage.setItem('old-user-token', loginUserData.data.loginUser.token)
          window.location.replace('https://www.whistlepitch.com/api/v1/login/' + loginUserData.data.loginUser.token)
        } else {
          localStorage.setItem('token', loginUserData.data.loginUser.user.token)
          loginUserData.client.resetStore()
          console.log("Logged in user")
          navigate('/dashboard')
        }
      }
    }
  }, [loginUserData.data])

  useEffect(() => {
    let token = localStorage.getItem('old-user-token')
    if (token) {
      // TODO: refresh token before redirecting
      window.location.replace('https://www.whistlepitch.com/api/v1/login/' + token)
    }
  }, [])

  if (state.user) {
    console.log("User already logged in")
    navigate('/dashboard')
  }

  const onLogin = (params) => {
    setLoggingIn(true);
    loginUser({ variables: { email: params.email, password: params.password } })
  }

  const onForgotPassword = () => {
    navigate('/forgot-password-request')
  }

  const renderAlternateLogos = () => {
    if (Constants.tattooCare && !loggingIn) {
      return (
        <View style={{ position: 'absolute', top: 20, left: 40, padding: 60, zIndex: 100, backgroundColor: 'white' }}>
          <Image style={{ width: 175, height: 40 }} source={require('./../assets/tattoocare_heading_inline.png')} />
        </View>
      )
    }
    return null
  }

  return (
    <View style={{ flex: 1 }}>
      {loggingIn ? <Spinner style={{ marginTop: 40 }} /> : <LoginView brand={Constants.brand} onLogin={onLogin} errorMessage={errorMessage} onForgotPassword={onForgotPassword} />}
      {renderAlternateLogos()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  }
})
