import { View, Text, ActivityIndicator, StyleSheet, Dimensions } from 'react-native';
import React, { useEffect, useState } from 'react';

import Constants from '../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import Input, { Label } from './../Input';
import Spinner from '../Spinner';
import { Button } from '@whistlepitch/wp-components';


export default function ConfirmPhoneModal({ isVisible, onClose, loading, onSubmit, title="Confirm Phone" } : { isVisible:boolean, onClose:Function, loading?: boolean, onSubmit: (code: string) => void, title?: string }): JSX.Element {
  const [code, setCode]= useState<string>('');

  const onConfirm = () => {
    onSubmit(code);
  }

  return (
    <WpModal isVisible={isVisible} closeModal={onClose}>
      <ModalBody style={{ position: 'relative' }} title={title} closeModal={onClose} width={400}>
        <View style={{ flex: 1, position: 'relative' }}>
          <Input key={`user-phone-confirm`} style={{ flex: 1 }} name={"6-digit Code"} value={code} placeholder="Enter 6-Digit Code..." setValue={setCode} />
          <ModalFooter>
            <Button type="Primary" size="Default" shape="Pill" onClick={onConfirm} disabled={loading}>
              { loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Confirm</Text> }
            </Button>
          </ModalFooter>
        </View>
      </ModalBody>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  }
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
