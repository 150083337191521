import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import Dropdown, { Item } from './Dropdown';
import { Label, Info } from './Input';
import { GET_GROUPS } from './../graphql/queries';
import { useQuery } from "@apollo/client";

export default function GroupDropdown({ label, info, optional, value, setValue, style={} } : { label?:string, info?:string, optional?: boolean, value:string|undefined, setValue:Function, style?:object }): JSX.Element {
  const [groupItems, setGroupItems] = useState<Item[]>([]);

  const { data } = useQuery(GET_GROUPS)

  useEffect(() => {
    if (data?.groups?.groups) {
      let groups = []
      for (let i = 0; i < data.groups.groups.length; i++) {
        let c = data.groups.groups[i];
        groups.push({value: c.id, label: c.name})
      }
      setGroupItems(groups)
    }
  }, [data])

  return (
    <View style={[{ position: 'relative' }, style]}>
      { label ? <Label name={label} optional={optional} /> : null }
      { info ? <Info info={info} /> : null }
      <Dropdown
        key={`dropdown-groups`}
        style={{ position: 'relative', zIndex: 1 }}
        placeholder={"Select a Group"}
        items={groupItems}
        value={value}
        onChange={setValue}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
