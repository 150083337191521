import React from 'react';
import { WpPitchStructure, WpSlideStructure, WpFullPitch, WpSlideWidget } from './pitch'
import { WpCampaign, WpModelSummary } from './user'


export interface WpFullCampaign {
  id: string
  v2: boolean
  leadId: string
  leadName: string
  name: string
  code: string
  lastActive: string
  hasAppt: boolean
  calendlyEventUri: string
  calendlyEventName: string
  calendlyEventLink: string
  brandImage: string
  brandName: string
  brandColor: string
  public: boolean
  description: string
  category: string
  userName: string
  features: WpCampaignFeature[]
  structure: {
    feature: WpCampaignStructure[],
    appointment: WpCampaignStructure[],
    before: WpCampaignStructure[]
  }
  stats: CampaignStats
  landingPage: {
    title: string,
    description: string,
    color: string,
    image: string,
    structure: WpSlideStructure[],
    widgets: WpSlideWidget[]
  }
}

export interface WpLandingPage {
  id: string,
  code: string,
  title: string,
  description: string,
  color: string,
  image: string,
  structure: WpSlideStructure[],
  widgets: WpSlideWidget[],
  campaigns: WpCampaign[]
  stats: {
    leads: number
  }
  dropdownOptions: [{
    id: string,
    label: string
  }]
}

export interface WpLandingPageDropdownOption {
  id: string,
  label: string,
  campaigns: WpCampaign[]
}

export interface CampaignStats {
  leads: number,
  opened: number,
  complete: number,
  currentLeads: number,
  response: number,
  messages: number,
  messagesSuccess: number,
  messagesFailed: number,
  features: FeatureStats[]
}

export interface FeatureStats {
  slideId: string
  featureId: string
  label: string
  leads: number
  complete: number
}

export enum WpFeatureType {
  MESSAGE = "MESSAGE",
  PITCH = "PITCH",
  REMIND = "REMIND",
  WAIT = "WAIT",
  APPOINTMENT = "APPOINTMENT",
  REDIRECT = "REDIRECT"
}

export interface WpPitch {
  id: string
  title: string
  structure: WpPitchStructure[]
}

export interface WpCampaignFeature {
  id: string,
  label: string,
  created: string,
  campaignId: string,
  days: number,
  hours: number,
  minutes: number,
  window: string,
  delayUntil: string,
  structureType: WpStructureType,
  featureType: WpFeatureType,
  message: string,
  messageContent: object,
  pitch: WpFullPitch,
  workflows: WpCampaignWorkflow[],
  after?: string
}

export interface WpCampaignWorkflow {
  id: string,
  label: string,
  triggers: WpWorkflowTrigger[],
  actions: WpWorkflowAction[],
}

export enum WpWorkflowTriggerType {
  VIEWED = "VIEWED",
  TIME = "TIME",
  WIDGET = "WIDGET",
  REDIRECT = "REDIRECT",
  CALENDAR = "CALENDAR",
  UNOPENED = "UNOPENED"
}

export enum WpWorkflowActionType {
  ME = "ME",
  LEAD = "LEAD",
  SPECIFIC = "SPECIFIC",
  CAMPAIGN = "CAMPAIGN",
  WEBHOOK = "WEBHOOK"
}

export enum WpWorkflowExpression {
  EQ = "EQ",
  LT = "LT",
  LE = "LE",
  GT = "GT",
  GE = "GE",
  NE = "NE"
}

export interface WpWorkflowTrigger {
  id: string,
  label: string,
  triggerType: WpWorkflowTriggerType,
  triggerOption: string,
  triggerResponses: string[],
  seconds: number,
  days: number,
  hours: number,
  performedAction: boolean,
  widgetExpression: WpWorkflowExpression,
  widgetId: string
}

export interface WpWorkflowAction {
  action: any;
  id: string,
  label: string,
  created: string,
  actionType: WpWorkflowActionType,
  default: boolean,
  messageContent: object,
  webhookUrl: string,
  nextCampaignId: string,
  users: WpModelSummary[],
  userIds: string[]
}

export interface WpCampaignStructure {
  id: string,
  featureType: WpFeatureType,
  label: string,
  pitchId: string,
  structure: WpPitchStructure[]
}

interface WpCampaignState {
  campaign: WpFullCampaign | null,
  editing: string | null
}

export const initialState: WpCampaignState = {
  campaign: null,
  editing: null
}

export enum WpCampaignAction {
  REFRESH_CAMPAIGN = "REFRESH_CAMPAIGN",
  REFRESH_CAMPAIGN_FEATURE = "REFRESH_CAMPAIGN_FEATURE",
  CREATE_CAMPAIGN_FEATURE = "CREATE_CAMPAIGN_FEATURE",
  DELETE_CAMPAIGN_FEATURE = "DELETE_CAMPAIGN_FEATURE",
  UPDATE_CAMPAIGN_FEATURE = "UPDATE_CAMPAIGN_FEATURE",
  CREATE_CAMPAIGN_WORKFLOW = "CREATE_CAMPAIGN_WORKFLOW",
  DELETE_CAMPAIGN_WORKFLOW = "DELETE_CAMPAIGN_WORKFLOW",
  CREATE_CAMPAIGN_TRIGGER = "CREATE_CAMPAIGN_TRIGGER",
  UPDATE_CAMPAIGN_TRIGGER = "UPDATE_CAMPAIGN_TRIGGER",
  DELETE_CAMPAIGN_TRIGGER = "DELETE_CAMPAIGN_TRIGGER",
  CREATE_CAMPAIGN_ACTION = "CREATE_CAMPAIGN_ACTION",
  UPDATE_CAMPAIGN_ACTION = "UPDATE_CAMPAIGN_ACTION",
  DELETE_CAMPAIGN_ACTION = "DELETE_CAMPAIGN_ACTION",
  CLEAR_EDITING = "CLEAR_EDITING",
  SET_HAS_APPT = "SET_HAS_APPT",
  CREATE_WIDGET = "CREATE_WIDGET",
  SAVE_WIDGET = "SAVE_WIDGET",
  DELETE_WIDGET = "DELETE_WIDGET",
  REFRESH_WORKFLOW = "REFRESH_WORKFLOW",
}

export enum WpStructureType {
  FEATURE = "feature",
  APPOINTMENT = "appointment",
  BEFORE = "before"
}

interface WpCampaignActions {
  type: WpCampaignAction,
  campaign?: WpFullCampaign,
  campaignFeature?: WpCampaignFeature
  structure?: WpCampaignStructure[]
  structureType?: WpStructureType
  workflow?: WpCampaignWorkflow
  trigger?: WpWorkflowTrigger
  action?: WpWorkflowAction
  hasAppt?: boolean
}

export const CampaignContext = React.createContext<{
  state: WpCampaignState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});

export const campaignReducer = (state: WpCampaignState, action: WpCampaignActions) => {
  let features = null;
  let workflows = null;
  let triggers = null;
  let actions = null;
  let findex = null;
  let windex = null;
  let tindex = null;
  let aindex = null;

  switch (action.type) {
    case WpCampaignAction.REFRESH_CAMPAIGN:
      const { campaign } = action;

      return {
        ...state,
        campaign: campaign
      }
    case WpCampaignAction.CLEAR_EDITING:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === 'new');
      if (findex > -1) {
        features = [
          ...features.slice(0, findex),
          ...features.slice(findex + 1)
        ]
      }

      let structure_feature = state.campaign?.structure.feature ?? [];
      let sfindex = structure_feature.findIndex(feature => feature.id === 'new');
      if (sfindex > -1) {
        structure_feature = [
          ...structure_feature.slice(0, sfindex),
          ...structure_feature.slice(sfindex + 1)
        ]
      }

      let structure_before = state.campaign?.structure.before ?? [];
      let sbindex = structure_before.findIndex(feature => feature.id === 'new');
      if (sbindex > -1) {
        structure_before = [
          ...structure_before.slice(0, sbindex),
          ...structure_before.slice(sbindex + 1)
        ]
      }

      let structure_appointment = state.campaign?.structure.appointment ?? [];
      let saindex = structure_appointment.findIndex(feature => feature.id === 'new');
      if (saindex > -1) {
        structure_appointment = [
          ...structure_appointment.slice(0, saindex),
          ...structure_appointment.slice(saindex + 1)
        ]
      }

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: features,
          structure: {
            feature: structure_feature,
            before: structure_before,
            appointment: structure_appointment,
          }
        },
        editing: null
      }
    case WpCampaignAction.REFRESH_WORKFLOW:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)

      if (findex > -1 && windex > -1) {
        triggers = [...features[findex].workflows[windex].triggers];
        actions = [...features[findex].workflows[windex].actions];

        tindex = triggers.findIndex(trigger => trigger.id === 'new');
        if (tindex > -1) {
          triggers = [
            ...triggers.slice(0, tindex),
            ...triggers.slice(tindex + 1)
          ]
        }

        aindex = actions.findIndex(action => action.id === 'new');

        if (aindex > -1) {
          actions = [
            ...actions.slice(0, aindex),
            ...actions.slice(aindex + 1)
          ]
        }

        return {
          ...state,
          campaign: {
            ...state.campaign,
            features: [
              ...features.slice(0, findex),
              {
                ...features[findex],
                workflows: [
                  ...features[findex].workflows.slice(0, windex),
                  {
                    ...features[findex].workflows[windex],
                    triggers: triggers,
                    actions: actions
                  },
                  ...features[findex].workflows.slice(windex + 1)
                ]
              },
              ...features.slice(findex + 1)
            ]
          },
          editing: null
        }
      }
      return {
        ...state
      }
    case WpCampaignAction.SET_HAS_APPT:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          hasAppt: action.hasAppt,
          structure: {
            ...state.campaign?.structure,
            before: action.hasAppt ? state.campaign?.structure?.before ?? [] : [],
            appointment: action.hasAppt ? state.campaign?.structure?.appointment ?? [] : []
          }
        }
      }
    case WpCampaignAction.REFRESH_CAMPAIGN_FEATURE:
      const { campaignFeature } = action;
      let updated_campaign = Object.assign({}, state);
      if (!updated_campaign.campaign) {
        return updated_campaign
      }

      updated_campaign.campaign.structure[action.structureType ? action.structureType : 'feature'] = action.structure!;

      for (let i = 0; i < updated_campaign.campaign.features.length ?? 0; i++) {
        if (updated_campaign.campaign.features[i].id === campaignFeature?.id) {
          updated_campaign.campaign.features[i] = campaignFeature;
          break
        }
      }
      return {
        ...state,
        campaign: updated_campaign
      }
    case WpCampaignAction.CREATE_CAMPAIGN_FEATURE:
      features = state.campaign?.features ?? [];
      findex = 0
      let new_structure = action.structure ? [...action.structure] : [...state.campaign?.structure[action.structureType ? action.structureType : 'feature']] ?? []
      if (action.campaignFeature?.after) {
        findex = new_structure.findIndex(feature => feature.id === action.campaignFeature?.after)
      } else {
        findex = -1
      }
      // if (findex === -1 && !!action.campaignFeature) {
      //   new_structure.push(action.campaignFeature)
      if (action.campaignFeature) {
        new_structure.splice(findex + 1, 0, action.campaignFeature)
      }
      return {
        ...state,
        campaign: {
          ...state.campaign,
          structure: {
            ...state?.campaign?.structure,
            [action.structureType ? action.structureType : 'feature']: new_structure
          },
          features: [...state.campaign?.features ?? [], action.campaignFeature]
        },
        editing: action.campaignFeature?.id
      }
    case WpCampaignAction.UPDATE_CAMPAIGN_FEATURE:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            action.campaignFeature,
            ...features.slice(findex + 1)
          ],
          structure: {
            ...state?.campaign?.structure,
            [action.structureType ? action.structureType : 'feature']: action.structure
          }
        }
      }
    case WpCampaignAction.DELETE_CAMPAIGN_FEATURE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          structure: {
            ...state?.campaign?.structure,
            [action.structureType ? action.structureType : 'feature']: action.structure
          }
        }
      }
    case WpCampaignAction.CREATE_CAMPAIGN_WORKFLOW:
      let newFeatures = state.campaign?.features ?? [];
      const index = newFeatures.findIndex(feature => feature.id === action.campaignFeature?.id)

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...newFeatures.slice(0, index),
            {
              ...newFeatures[index],
              workflows: [...newFeatures[index].workflows ?? [], action.workflow]
            },
            ...newFeatures.slice(index + 1),
          ]
        }
      }
    case WpCampaignAction.DELETE_CAMPAIGN_WORKFLOW:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)

      workflows = [...features[findex].workflows]
      workflows.splice(windex, 1);

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            {
              ...features[findex],
              workflows: workflows
            },
            ...features.slice(findex + 1)
          ]
        }
      }
    case WpCampaignAction.DELETE_CAMPAIGN_TRIGGER:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)
      tindex = features[findex].workflows[windex].triggers.findIndex(trigger => trigger.id === action.trigger?.id)

      triggers = [...features[findex].workflows[windex].triggers]
      triggers.splice(tindex, 1);

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            {
              ...features[findex],
              workflows: [
                ...features[findex].workflows.slice(0, windex),
                {
                  ...features[findex].workflows[windex],
                  triggers: triggers
                },
                ...features[findex].workflows.slice(windex + 1)
              ]
            },
            ...features.slice(findex + 1)
          ]
        }
      }
    case WpCampaignAction.CREATE_CAMPAIGN_TRIGGER:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            {
              ...features[findex],
              workflows: [
                ...features[findex].workflows.slice(0, windex),
                {
                  ...features[findex].workflows[windex],
                  triggers: [...features[findex].workflows[windex].triggers ?? [], action.trigger]
                },
                ...features[findex].workflows.slice(windex + 1)
              ]
            },
            ...features.slice(findex + 1)
          ]
        }
      }
    case WpCampaignAction.UPDATE_CAMPAIGN_TRIGGER:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)
      tindex = features[findex].workflows[windex].triggers.findIndex(trigger => trigger.id === action.trigger?.id || trigger.id === 'new')

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            {
              ...features[findex],
              workflows: [
                ...features[findex].workflows.slice(0, windex),
                {
                  ...features[findex].workflows[windex],
                  triggers: [
                    ...features[findex].workflows[windex].triggers.slice(0, tindex),
                    action.trigger,
                    ...features[findex].workflows[windex].triggers.slice(tindex + 1)
                  ]
                },
                ...features[findex].workflows.slice(windex + 1)
              ]
            },
            ...features.slice(findex + 1)
          ]
        }
      }
    case WpCampaignAction.UPDATE_CAMPAIGN_ACTION:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)
      aindex = features[findex].workflows[windex].actions.findIndex(a => a.id === action.action?.id || a.id === 'new')

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            {
              ...features[findex],
              workflows: [
                ...features[findex].workflows.slice(0, windex),
                {
                  ...features[findex].workflows[windex],
                  actions: [
                    ...features[findex].workflows[windex].actions.slice(0, aindex),
                    action.action,
                    ...features[findex].workflows[windex].actions.slice(aindex + 1)
                  ]
                },
                ...features[findex].workflows.slice(windex + 1)
              ]
            },
            ...features.slice(findex + 1)
          ]
        }
      }
    case WpCampaignAction.DELETE_CAMPAIGN_ACTION:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)
      aindex = features[findex].workflows[windex].actions.findIndex(a => a.id === action.action?.id)

      actions = [...features[findex].workflows[windex].actions]
      actions.splice(aindex, 1);

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            {
              ...features[findex],
              workflows: [
                ...features[findex].workflows.slice(0, windex),
                {
                  ...features[findex].workflows[windex],
                  actions: actions
                },
                ...features[findex].workflows.slice(windex + 1)
              ]
            },
            ...features.slice(findex + 1)
          ]
        }
      }
    case WpCampaignAction.CREATE_CAMPAIGN_ACTION:
      features = state.campaign?.features ?? [];
      findex = features.findIndex(feature => feature.id === action.campaignFeature?.id)
      windex = features[findex].workflows.findIndex(workflow => workflow.id === action.workflow?.id)

      return {
        ...state,
        campaign: {
          ...state.campaign,
          features: [
            ...features.slice(0, findex),
            {
              ...features[findex],
              workflows: [
                ...features[findex].workflows.slice(0, windex),
                {
                  ...features[findex].workflows[windex],
                  actions: [...features[findex].workflows[windex].actions ?? [], action.action]
                },
                ...features[findex].workflows.slice(windex + 1)
              ]
            },
            ...features.slice(findex + 1)
          ]
        }
      }
    case WpCampaignAction.CREATE_WIDGET:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          landingPage: {
            ...state.campaign?.landingPage,
            structure: action.structure ? action.structure : state.campaign?.landingPage?.structure
          }
        }
      }
    case WpCampaignAction.SAVE_WIDGET:
      let structure = action.structure ? action.structure : state.campaign?.landingPage.structure ?? [];
      let sindex = structure.findIndex(widget => widget.id === 'new');
      if (sindex > -1) {
        structure = [
          ...structure.slice(0, sindex),
          ...structure.slice(sindex + 1)
        ]
      }

      return {
        ...state,
        campaign: {
          ...state.campaign,
          landingPage: {
            ...state.campaign?.landingPage,
            structure: structure
          }
        }
      }
    case WpCampaignAction.DELETE_WIDGET:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          landingPage: {
            ...state.campaign?.landingPage,
            structure: action.structure ? action.structure : state.campaign?.landingPage?.structure
          }
        }
      }
    default:
      return state;
  }
};
