import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions, Pressable } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import { Ionicons } from '@expo/vector-icons';
import FadeInOut from 'react-native-fade-in-out';

const MAX_WIDTH = 340;

export default function Hoverable({ children, tooltip, style = {}, top = 50, left = '-20%', fade = true, onHover, doneHover, onPress }: { children?: JSX.Element | JSX.Element[] | null, tooltip?: string, style?: object, top?: string | number, left?: string | number, fade?: boolean, onHover?: () => void, onPress?: () => void, doneHover?: () => void }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);
  const [position, setPosition] = useState<'relative'>('relative');
  const [textAlign, setTextAlign] = useState<'center' | 'left'>('center');
  const [tooltipWidth, setTooltipWidth] = useState<number>(0);

  useEffect(() => {
    if (tooltip) {
      let width = 0;
      if (tooltip.length > 0) {
        width = tooltip.length * 10.5;
      }
      if (width > MAX_WIDTH) {
        width = MAX_WIDTH;
        setTextAlign('left');
      } else {
        setTextAlign('center');
      }

      setTooltipWidth(width);
    }
  }, [tooltip]);

  const startHover = () => {
    setHovering(true);
    if (onHover) {
      onHover();
    }
  }

  const endHover = () => {
    setHovering(false);
    if (doneHover) {
      doneHover();
    }
  }

  return (
    <View style={[styles.hoverable, style]} onClick={onPress} onMouseEnter={startHover} onMouseLeave={endHover}>
      <FadeInOut style={styles.optionsWrapper} visible={(hovering && !!tooltip) ?? false} scale={false} duration={150}>
        <View style={[styles.options, { position: position, left: left, top: top, width: tooltipWidth }]} onClick={onPress}>
          <Text style={{ fontFamily: 'GothamBold', color: '#EEE', width: '100%', textAlign }}>{tooltip}</Text>
        </View>
      </FadeInOut>
      <View style={[hovering && fade ? { opacity: 0.5 } : { opacity: 1 }]}>
        {children ? children : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  hoverable: {
    position: 'relative',
    cursor: 'pointer'
  },
  optionsWrapper: {
    transition: '0.15s ease-in-out',
    position: 'absolute',
  },
  options: {
    maxWidth: MAX_WIDTH,
    transition: '0.15s ease-in-out',
    borderRadius: 5,
    color: '#FFF',
    backgroundColor: Constants.colors.dark,
    paddingVertical: 5,
    paddingHorizontal: 10,
    zIndex: 10000,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.37,
    shadowRadius: 15.19,
    shadowColor: Constants.colors.secondary,
    elevation: 23,
    flex: -1
  },
});

const cssStyles = {
}
