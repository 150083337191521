import { View, Text, StyleSheet } from 'react-native';
import React, { useState } from 'react';

import Constants from './../../constants';
import { LeadContext, leadReducer } from './../../contexts/lead';
import PitchPresenter from './PitchPresenter';


export default function PitchProvider({ pitchId, campaignId, campaignFeatureCode, campaignLeadCode }: { pitchId?: string, campaignId?: string, campaignFeatureCode?: string, campaignLeadCode?: string }): JSX.Element {
  const [state, dispatch] = React.useReducer(leadReducer, {});

  return (
    <View style={{ flex: 1 }}>
      <LeadContext.Provider value={{ state, dispatch }}>
        <PitchPresenter pitchId={pitchId} campaignId={campaignId} campaignFeatureCode={campaignFeatureCode} campaignLeadCode={campaignLeadCode} />
      </LeadContext.Provider>
    </View>
  );
}
