import { Text, View, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import Spinner from './Spinner';
import Icon from './Icon';

export default function NewButton({ name, text, small, onClick, loading, adder=true, style={} }: { name?: string, text?: string, small?: boolean, onClick: () => void, loading?: boolean, adder?: boolean, style?: object }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);
  const [button, setButton] = useState<string>(text ? text : `New ${name}`);

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const renderButton = () => {
    if (loading) {
      return <Spinner />
    } else if (small) {
      return <Text style={[styles.newBtnSmall, {opacity: hovering ? 0.7 : 1}]} onPress={onClick} onMouseEnter={onHover} onMouseLeave={onHoverOut}>+ { button }</Text>
    } else {
      return (
        <View style={[styles.btnWrapper, {opacity: hovering ? 0.7 : 1}]} onClick={onClick} onMouseEnter={onHover} onMouseLeave={onHoverOut}>
          { adder ? <Icon name="plus" color={Constants.colors.darkBlue} /> : null }
          <Text style={styles.newBtn}>{ button }</Text>
        </View>
      )
    }
  }

  return (
    <View style={[styles.container, style]}>
      { renderButton() }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15
  },
  btnWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    cursor: 'pointer'
  },
  newBtn: {
    fontFamily: 'GothamBold',
    fontSize: 15,
    color: Constants.colors.darkBlue,
  },
  newBtnSmall: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
});
