import { TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import { CampaignContext, WpCampaignAction, WpCampaignFeature, WpCampaignStructure, WpFeatureType, WpStructureType } from '../contexts/campaign';
import { CREATE_CAMPAIGN_FEATURE } from '../graphql/campaign';
import Constants from '../constants';
import { useMutation } from "@apollo/client";
import { PlusCircleIcon } from '@heroicons/react/solid'
import GraphCreateButton from './GraphCreateButton'
import CampaignFeatureOptions from './CampaignFeatureOptions'


export default function CampaignFeatureCreateButton({ structureType, after, muted, onPress, defaultToSlide = false, showRedirect = false, showSlides = false, isLast = false, isFirst = false, shortened = false }: { structureType: WpStructureType, muted?: boolean, onPress?: (after?: string) => void, after?: string, defaultToSlide?: boolean, showRedirect?: boolean, showSlides?: boolean, isLast?: boolean, isFirst?: boolean, shortened?: boolean }): JSX.Element {
  const { state, dispatch } = React.useContext(CampaignContext);
  const [createCampaignFeature, { data, loading, error }] = useMutation(CREATE_CAMPAIGN_FEATURE);

  const newFeature = (featureType?: WpFeatureType) => {
    if (featureType && featureType !== WpFeatureType.REDIRECT) {
      // createCampaignFeature({variables: {campaignId: state.campaign?.id, structureType: structureType, after: after, featureType: featureType}})

      let feature: WpCampaignFeature = {
        id: 'new',
        label: 'New Message',
        campaignId: state.campaign?.id ?? '',
        featureType: WpFeatureType.MESSAGE,
        structureType: structureType,
        workflows: [],
        after: after === 'new' ? undefined : after
      }

      let current_structure: WpCampaignStructure[] = [];
      if (structureType === 'feature') {
        current_structure = state.campaign?.structure.feature ?? [];
      } else if (structureType === 'before') {
        current_structure = state.campaign?.structure.before ?? [];
      } else if (structureType === 'appointment') {
        current_structure = state.campaign?.structure.appointment ?? [];
      }

      dispatch({
        type: WpCampaignAction.CREATE_CAMPAIGN_FEATURE,
        campaignFeature: feature,
        structureType: structureType,
        structure: current_structure
      })
    } else if (onPress !== undefined) {
      onPress(featureType === WpFeatureType.REDIRECT ? 'redirect' : 'slide');
    }
  }

  const newDefaultFeature = (a?: undefined | string) => {
    if (defaultToSlide) {
      newFeature();
    } else {
      newFeature(WpFeatureType.MESSAGE);
    }
  }

  useEffect(() => {
    if (data?.createCampaignFeature?.feature) {
      dispatch({
        type: WpCampaignAction.CREATE_CAMPAIGN_FEATURE,
        campaignFeature: data?.createCampaignFeature?.feature,
        structureType: structureType,
        structure: data?.createCampaignFeature?.structure
      })
    }
  }, [data])

  return (
    <GraphCreateButton muted={muted} after={after} isLast={isLast} isFirst={isFirst} shortened={shortened} onPress={showSlides ? undefined : newDefaultFeature}>
      <CampaignFeatureOptions showRedirect={showRedirect} onPress={newFeature} />
    </GraphCreateButton>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  line: {
    width: 1,
    height: 15,
    border: '1px solid ' + Constants.colors.info
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
});

const cssStyles = {
  icon: {
    width: 25,
    height: 25,
    color: Constants.colors.info,
    cursor: 'pointer'
  }
}
