import { View, Text, Image, StyleSheet, LayoutChangeEvent } from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { WpSlideType } from './../../contexts/pitch'
import { WpCustomValue } from './../../contexts/user'
import { LeadContext, WpLeadAction, WpLeadEvent, WpLeadViewRange } from './../../contexts/lead'
import Constants from './../../constants';
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { Input } from '@whistlepitch/wp-components'
import { InlineWidget, CalendlyEventListener } from "react-calendly";
import Spinner from '../Spinner';
import { useInterval } from '../../tools/hooks'
import TextEditor from '../Shared/TextEditor';
import AudioPlayer from '../Shared/AudioPlayer';


// Define a debounce function to limit how often a function can be called
const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export default function SlideMainContent({ slideId, title, description, slideType, thumbnail, url, audioFile, calendarUrl, content, isIdle, isVideo, calendars, color, landingPageComplete }: { slideId?: string, title?: string, description?: string, viewSeconds?: number, videoSeconds?: number, slideType?: WpSlideType | null, thumbnail?: string, url?: string, audioFile?: string, calendarUrl?: string, content?: string, isIdle?: () => boolean, isVideo?: boolean, calendars?: WpCustomValue[], color?: string, landingPageComplete?: boolean }): JSX.Element {
  const { state, dispatch } = React.useContext(LeadContext);

  const playerRef = useRef<ReactPlayerProps | null>(null);

  const [viewWidth, setViewWidth] = useState<number>(0);
  const [prevSlideId, setPrevSlideId] = useState<string>();
  const [was, setWas] = useState<number>(Date.now());
  const [seconds, setSeconds] = useState<number>(0);
  const [video, setVideo] = useState<number>(0);
  const [audio, setAudio] = useState<number>(0);
  const [newAudioSeconds, setNewAudioSeconds] = useState<number>(0);
  const [timer, setTimer] = useState<NodeJS.Timer>();
  const [event, setEvent] = useState<WpLeadEvent>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  // const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [videoKey, setVideoKey] = useState<number>(1);
  const [audioKey, setAudioKey] = useState<number>(1);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [audioDuration, setAudioDuration] = useState<number>(0);
  const [reload, setReload] = useState<number>(0);
  const [aspectRatio, setAspectRatio] = useState(0)

  const [watchedRanges, setWatchedRanges] = useState<WpLeadViewRange[]>([]);
  const [currentStart, setCurrentStart] = useState<number | null>(0);
  const [lastSeekTime, setLastSeekTime] = useState<number | null>(null);  // Store the last seek time

  const onPlay = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      setWatchedRanges(prevRanges => {
        const lastRange = prevRanges.length ? prevRanges[prevRanges.length - 1] : null;
        if (lastRange && lastRange.end < currentTime && currentTime - lastRange.end <= 0.1) {
          return [
            ...prevRanges.slice(0, -1),
            { ...lastRange, end: currentTime },
          ];
        }
        return prevRanges;  // No update if beyond 0.1 seconds
      });
      setCurrentStart(currentTime);
    }
  };

  const handleSeek = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      if (lastSeekTime === null || Math.abs(lastSeekTime - currentTime) > 0.1) {  // Check for significant seek
        setLastSeekTime(currentTime);
        setWatchedRanges([...watchedRanges, { start: currentTime, end: currentTime }]);
      }
    }
  };

  // Debounce the handleSeek function to limit how often it can be called
  const onSeek = debounce(handleSeek, 100);

  const onProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    setVideo(playedSeconds) // Old method

    if (playerRef.current && currentStart !== null) {
      const currentTime = playerRef.current.getCurrentTime();

      setWatchedRanges(prevRanges => {
        const lastRange = prevRanges.length ? prevRanges[prevRanges.length - 1] : null;
        if (lastRange && currentTime - lastRange.end <= 0.9) {
          if (currentTime > lastRange.end) {
            return [
              ...prevRanges.slice(0, -1),
              { ...lastRange, end: currentTime },
            ];
          } else if (currentTime < lastRange.start) {
            return [
              ...prevRanges,
              { start: currentTime, end: currentTime },
            ];
          }
        } else {
          return [
            ...prevRanges,
            { start: currentTime, end: currentTime },
          ];
        }
        return prevRanges;  // No update
      });
    }
  };

  useEffect(() => {
    if (!url || slideType === WpSlideType.CALENDAR || slideType === WpSlideType.YOUTUBE || isVideo) {
      return;
    }

    let isValid = true;
    Image.getSize(url, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [url, isVideo, slideType, reload]);

  useEffect(() => {
    if (slideId && (seconds || video || audio)) {
      dispatchTime(seconds, video, audio + newAudioSeconds, slideId);
    }
  }, [seconds, video, audio, newAudioSeconds, watchedRanges])

  useEffect(() => {
    updateVideoDuration();
  }, [playerRef])

  useEffect(() => {
    setVideoKey(prev => prev + 1);
    setAudioKey(prev => prev + 1);
  }, [slideId]);

  useEffect(() => {
    if (state.events && state.events.length) {
      const eindex = state.events.findIndex((e) => e.pitchSlideId === slideId)
      if (eindex > -1) {
        setEvent(state.events[eindex])
      }
    }
  }, [state.events, slideId])

  // useEffect(() => {
  //   setIsVideoPlaying(true)

  //   let timeout = setTimeout(() => {
  //     setIsVideoPlaying(false)
  //   }, 2000);

  //   return () => clearInterval(timeout)
  // }, [video, watchedRanges])

  useInterval(() => {
    // console.log("Checking idle", !!isIdle, !!isIdle && !isIdle(), isVideoPlaying)
    if (isIdle === undefined || !isIdle()) {
      updateSeconds();
    } else {
      updateWas()
    }
  }, isLoaded ? 3000 : null)

  useEffect(() => {
    if (prevSlideId) {
      // Store time since last interval and then restart the timer for the new slide
      dispatchTime(seconds + ((Date.now() - was) / 1000), video, audio + newAudioSeconds, prevSlideId);
    }
    setPrevSlideId(slideId);

    if (slideId) {
      loadSeconds();

      setWas(Date.now());
      setIsLoaded(true);
    }
  }, [slideId])

  const updateVideoDuration = () => {
    if (playerRef && playerRef.current) {
      setVideoDuration(playerRef.current.getDuration())
    }
  }

  const updateAudioDuration = (duration: number) => {
    if (duration > audioDuration) {
      setAudioDuration(duration)
    }
  }

  const loadSeconds = () => {
    let tindex = state?.times?.findIndex((t) => t.pitchSlideId === slideId)
    if (tindex !== undefined && tindex > -1 && state?.times) {
      setSeconds(state.times[tindex].seconds);
      setVideo(state.times[tindex].video);
      setAudio(state.times[tindex].audio);
      setAudioDuration(state.times[tindex].audioDuration);
    } else {
      setSeconds(0)
      setVideo(0)
      setAudio(0)
      setAudioDuration(0)
    }
    setVideoDuration(0)
    setWatchedRanges([])
  }

  const dispatchTime = (seconds: number, v: number, a: number, pitchSlideId: string) => {
    console.log('dispatchTime', seconds, v, a, pitchSlideId, watchedRanges)
    dispatch({
      type: WpLeadAction.UPDATE_LEAD_TIME, time: {
        pitchSlideId: pitchSlideId,
        seconds: seconds,
        video: v,
        videoDuration: videoDuration,
        viewRanges: watchedRanges,
        audio: a,
        audioDuration: audioDuration
      }
    })
  }

  const updateSeconds = () => {
    let { now, previousWas } = updateWas();
    // console.log('updateSeconds', now, previousWas, now - previousWas)
    setSeconds(seconds => seconds + ((Math.min(now - previousWas, 3100)) / 1000))
  }

  const updateWas = () => {
    const now = Date.now();
    const previousWas = was;

    setWas(now);

    return { now, previousWas };
  };

  const onProgressAudio = (playedSeconds: number) => {
    setNewAudioSeconds(playedSeconds)
  }

  // const clear = () => {
  //   if (timer) {
  //     clearInterval(timer)
  //   }
  // }

  const onScheduled = (e) => {
    if (e && e?.data?.event === 'calendly.event_scheduled') {
      dispatch({
        type: WpLeadAction.SET_LEAD_EVENT, event: {
          pitchSlideId: slideId,
          eventUrl: e.data.payload.event.uri
        }
      })
    }
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const renderContent = () => {
    if (slideType === undefined) {
      if (description !== undefined) {
        return (
          <View style={{ marginHorizontal: 30, marginVertical: 20 }}>
            <Text style={[styles.landingPageTitle, { color: color ? color : Constants.colors.darkBlue }]}>{title}</Text>
            {description ? <Text style={[styles.paragraph, { color: color ? color : Constants.colors.darkBlue }]}>Description</Text> : null}
            <Text style={styles.paragraph}>{description}</Text>
          </View>
        )
      } else {
        if (landingPageComplete) {
          return (
            <View>
              <Text style={[styles.title, { color: color ? color : Constants.colors.darkBlue, textAlign: 'center' }]}>{title}</Text>
              <Text style={[styles.paragraph, { marginTop: 20 }]}>Thank you for your submission! Your information has been saved and we'll be reaching out to you soon.</Text>
              <Text style={[styles.paragraph, { opacity: 0.6, marginTop: 5 }]}>This landing page is powered by {Constants.brand}. For more information{Constants.oppText ? '' : ' about how you can create engaging landing pages'} visit <a style={{ color: Constants.colors.primary }} href={Constants.app_url}>{Constants.brand}.</a></Text>
            </View>
          )
        } else {
          return (
            <View>
              <Text style={[styles.title, { color: color ? color : Constants.colors.darkBlue, textAlign: 'center' }]}>{title}</Text>
              <Text style={[styles.paragraph, { marginTop: 20, textAlign: 'center', paddingHorizontal: 15 }]}>This {Constants.localizations.pitch} was powered by {Constants.brand}. For more information about how you can create engaging {Constants.localizations.pitches} visit <a style={{ color: Constants.colors.primary }} href={Constants.app_url}>{Constants.brand}</a></Text>
            </View>
          )
        }
      }
    } else if (slideType && [WpSlideType.UPLOAD, WpSlideType.YOUTUBE, WpSlideType.SCREEN, WpSlideType.RECORD].indexOf(slideType) > -1) {
      if (isVideo || slideType === WpSlideType.YOUTUBE) {
        return (
          <View style={styles.playerWrapper}>
            <View style={styles.calendarWrapper}>
              <ReactPlayer
                ref={playerRef}
                key={`video-${videoKey}`}
                style={cssStyles.player}
                url={url}
                controls={true}
                pip={false}
                width='100%'
                playsinline={true}
                progressInterval={750}
                onProgress={onProgress}
                onReady={updateVideoDuration}
                height={viewWidth * 9 / 16}

                onPlay={onPlay}
                onSeek={onSeek}
              />
            </View>
            <Text style={[styles.title, { color: color ? color : Constants.colors.darkBlue }]}>{title}</Text>
          </View>
        )
      } else {
        return (
          <View style={styles.playerWrapper}>
            <View style={styles.calendarWrapper}>
              {aspectRatio === 0 ? <Spinner onClick={() => setReload(reload + 1)} /> : <Image style={{ aspectRatio }} source={{ uri: url }} />}
            </View>
            <Text style={[styles.title, { color: color ? color : Constants.colors.darkBlue }]}>{title}</Text>
          </View>
        )
      }
    } else if (slideType === WpSlideType.CALENDAR && calendars?.length) {
      let users_calendar = calendars[0].value;

      return (
        <View>
          <Text style={[styles.title, { color: color ? color : Constants.colors.darkBlue }, event ? {} : { marginBottom: 25 }]}>{title}</Text>
          <View style={styles.calendarWrapper}>
            {event ?
              <Text style={[styles.paragraph, { marginTop: 20 }]}>Thank you for scheduling a time to meet with us!</Text> :
              <InlineWidget
                styles={{ height: 750, borderRadius: 15 }}
                url={users_calendar}
                prefill={{
                  name: state?.lead?.name,
                  // email: state?.lead?.email
                }}
              />
            }
            <CalendlyEventListener onEventScheduled={onScheduled} />
          </View>
        </View>
      )
    } else if (slideType === WpSlideType.TEXT && content && slideId) {
      let key = Constants.tattooCare ? `artist${state?.lead?.landingPageId}` : `funnel${state?.lead?.landingPageId}`;
      let linkParams = state?.lead?.landingPageId ? { tcid: key } : {};

      return (
        <View key={`text-editor-${slideId}`}>
          {/* { html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : null } */}
          <TextEditor initialContent={content} viewOnly={true} linkParams={linkParams} />
        </View>
      )
    } else if (slideType === WpSlideType.AUDIO && audioFile) {
      let numListens = 0;
      if ((audio || newAudioSeconds) && audioDuration) {
        numListens = Math.floor((audio + newAudioSeconds) / audioDuration);
      }
      if (numListens > 2) {
        return (
          <View style={styles.playerWrapper}>
            <Text style={[styles.title, { color: color ? color : Constants.colors.darkBlue }]}>{title}</Text>
            <View style={{ flex: 1, padding: 10 }}>
              <AudioPlayer
                key={`audio-${audioKey}`}
                artwork={url}
                color={color}
                details={"This is exclusive content! You'll already listened 3 times and can't listen again."}
              />
            </View>
          </View>
        )
      }
      return (
        <View style={styles.playerWrapper}>
          <Text style={[styles.title, { color: color ? color : Constants.colors.darkBlue }]}>{title}</Text>
          <View style={{ flex: 1, padding: 10 }}>
            <AudioPlayer
              key={`audio-${audioKey}`}
              url={audioFile}
              artwork={url}
              color={color}
              details={"This is exclusive content! You'll only be able to listen to it 3 times. You've listened " + numListens + " times so far."}
              onProgress={onProgressAudio}
              onReady={updateAudioDuration}
            />
          </View>
        </View>
      )
    }
    return null;
  }

  return (
    <View style={{ justifyContent: 'flex-start' }} onLayout={onLayout}>
      {renderContent()}
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    color: Constants.colors.darkBlue,
    fontWeight: 'bold',
    marginTop: 20,
    marginHorizontal: 20,
    fontFamily: 'GothamMedium'
  },
  landingPageTitle: {
    fontSize: 20,
    color: Constants.colors.darkBlue,
    fontWeight: 'bold',
    marginVertical: 20,
    textAlign: 'center',
    width: '100%',
    fontFamily: 'GothamMedium'
  },
  paragraph: {
    color: Constants.colors.dark,
    fontSize: 16,
    lineHeight: 22,
    fontFamily: 'Gotham'
  },
  playerWrapper: {
    position: 'relative',
    // flex: 1,
    overflow: 'hidden'
  },
  calendarWrapper: {
    overflow: 'hidden'
  }
})

const cssStyles = {
  player: {
    padding: 0,
    overflow: 'hidden',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  }
}
