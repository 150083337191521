import { Text, View, StyleSheet, ActivityIndicator, Dimensions, TextInput } from 'react-native';
import React, { useState, useEffect } from 'react';
import { PitchContext, WpWidgetType, PitchSlideContext, WpPitchSlideAction, WpSlideType, WpSlideWidget } from './../../contexts/pitch';
import { WpCampaignAction, CampaignContext } from './../../contexts/campaign';
import { UPDATE_SLIDE_WIDGET, DELETE_SLIDE_WIDGET, CREATE_SLIDE_WIDGET } from './../../graphql/pitch';
import { GET_SLIDE_WIDGET } from './../../graphql/queries';
import Constants from './../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import SortableLabels from './../SortableLabels';
import { Button, Theme } from './../Button';
import Input from './../Input';
import WidgetOptions from './WidgetOptions';
import RadioOptions from './../RadioOptions';
import { AdjustmentsIcon, AnnotationIcon, ThumbUpIcon, ShieldCheckIcon, PlusIcon, CheckCircleIcon, TrashIcon } from '@heroicons/react/outline';
import { useMutation, useLazyQuery } from "@apollo/client";
import { IconButton } from '@whistlepitch/wp-components';


const window = Dimensions.get('window');

export default function SlideWidget({ widgetId=null, label, startEditing, clearEditing, isEditing, widgetType, campaignId } : { widgetId:string|null, label:string, startEditing?: (id: string) => void, clearEditing?: () => void, isEditing:boolean, widgetType?: WpWidgetType, campaignId?: string }): JSX.Element {
  const { state, dispatch } = React.useContext(PitchSlideContext);
  const campaignState = React.useContext(CampaignContext);
  const [getWidget, getWidgetData] = useLazyQuery(GET_SLIDE_WIDGET, {
    fetchPolicy: "no-cache"
  })
  const [createWidget, createWidgetData] = useMutation(CREATE_SLIDE_WIDGET, {
    fetchPolicy: "no-cache"
  })
  const [updateWidget, updateWidgetData] = useMutation(UPDATE_SLIDE_WIDGET, {
    fetchPolicy: "no-cache"
  })
  const [deleteWidget, deleteWidgetData] = useMutation(DELETE_SLIDE_WIDGET, {
    fetchPolicy: "no-cache"
  })

  const [dimensions, setDimensions]= useState(window)
  const [editing, setEditing]= useState<boolean>(isEditing)
  const [widget, setWidget]= useState<WpSlideWidget|null>(null)
  const [question, setQuestion]= useState<string>()
  const [fineText, setFineText]= useState<string>()
  const [wt, setWidgetType]= useState<WpWidgetType|undefined>(widgetType)
  const [labels, setLabels]= useState<string[]>([])
  const [start, setStart]= useState<number>()
  const [end, setEnd]= useState<number>()

  const updateWidgetState = (w: WpSlideWidget) => {
    setWidget(w)
    setQuestion(w.label)
    setFineText(w.fineText)
    setWidgetType(w.widgetType)
    setLabels(w.labels ? w.labels : [])
    setStart(w.start)
    setEnd(w.end)
  }

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  useEffect(() => {
    if (widgetId && getWidgetData?.data?.slideWidget?.widget?.id === widgetId) {
      updateWidgetState(getWidgetData.data.slideWidget.widget)
    }
  }, [getWidgetData?.data]);

  useEffect(() => {
    // if (widgetId && state.slide?.widgets) {
    //   const windex = state.slide.widgets.findIndex(w => w.id === widgetId);
    //   if (windex > -1) {
    //     updateWidgetState(state.slide.widgets[windex])
    //   }
    // } else if (widgetId && campaignId) {
    //   getWidget({ variables: { slideWidgetId: widgetId }})
    // }
    if (widgetId && widgetId !== 'new') {
      getWidget({ variables: { slideWidgetId: widgetId }})
    }
  }, [widgetId]);

  useEffect(() => {
    if (updateWidgetData?.data || createWidgetData?.data) {
      // TODO: make sure campaign structure updates so if/thens can always see the new widget (don't wait for slide to be saved)
      let data = updateWidgetData?.data ? updateWidgetData.data.updateSlideWidget : createWidgetData?.data.createSlideWidget;
      if (campaignId) {
        campaignState.dispatch({ type: WpCampaignAction.SAVE_WIDGET, structure: data.structure });
        if (clearEditing) {
          clearEditing();
        }
      } else if (state.slide) {
        dispatch({ type: WpPitchSlideAction.SAVE_WIDGET, structure: data.structure });
      }
      setEditing(false);
      updateWidgetState(data.widget)
    }
  }, [updateWidgetData?.data, createWidgetData?.data]);

  useEffect(() => {
    if (deleteWidgetData.data) {
      if (campaignId) {
        campaignState.dispatch({ type: WpCampaignAction.DELETE_WIDGET, structure: deleteWidgetData.data.deleteSlideWidget.structure });
        if (clearEditing) {
          clearEditing();
        }
      } else if (state.slide) {
        dispatch({ type: WpPitchSlideAction.DELETE_WIDGET, structure: deleteWidgetData.data.deleteSlideWidget.structure });
      }
      setEditing(false);
    }
  }, [deleteWidgetData.data]);

  const saveWidget = () => {
    if (widgetId === 'new') {
      createWidget({ variables: {
        pitchSlideId: state.slide?.id,
        campaignId: campaignId,
        slideWidget: {
          label: question,
          fineText: fineText,
          widgetType: wt,
          labels: labels,
          start: start,
          end: end
        }
      }})
    } else if (widget) {
      updateWidget({ variables: {
        slideWidgetId: widget.id,
        label: question,
        fineText: fineText,
        widgetType: wt,
        labels: labels,
        start: start,
        end: end
      }})
    }
  }

  const onCloseModal = () => {
    if (state.slide) {
      dispatch({ type: WpPitchSlideAction.CLEAR_EDITING_WIDGET });
    } else if (clearEditing && campaignId) {
      clearEditing();
    }
  }

  const openModal = () => {
    if (widget) {
      if (campaignId) {
        if (startEditing) {
          startEditing(widget.id);
        }
      } else if (state.slide) {
        dispatch({ type: WpPitchSlideAction.SET_EDITING_WIDGET, editing: widget.id });
      }
    }
  }

  const onDeleteWidget = () => {
    if (widget) {
      deleteWidget({ variables: { slideWidgetId: widget.id }})
    } else {
      onCloseModal();
    }
  }

  const onSelectType = (type:WpWidgetType) => {
    setWidgetType(type);
  }

  const getTheme = (type:WpWidgetType|undefined) => {
    if (type) {
      return {
        SCALE: Theme.DANGER,
        YESNO: Theme.SUCCESS,
        RATING: Theme.DANGER_MEDIUM,
        SHORT: Theme.WARNING,
        CHOICE: Theme.PRIMARY,
        CHECKBOX: Theme.PRIMARY,
        VERIFY: Theme.ACCENT,
      }[type]
    } else {
      return Theme.PRIMARY
    }
  }

  const renderQuestion = () => {
    return (
      <Input style={{ marginBottom: 10 }} key="widget-question" name="Question" placeholder="Enter your question..." value={question} setValue={setQuestion} />
    )
  }

  const renderSettings = () => {
    if (wt) {
      if ([WpWidgetType.CHOICE, WpWidgetType.CHECKBOX].indexOf(wt) > -1) {
        const options = [
          {id: WpWidgetType.CHOICE, label: 'Single Choice', info: 'Leads can only select one option'},
          {id: WpWidgetType.CHECKBOX, label: 'Multiple Choice', info: 'Leads can select multiple options'},
        ]
        return (
          <View style={{ width: '100%' }}>
            <RadioOptions options={options} current={wt} onChange={onSelectType} />
            { renderQuestion() }
            <Text style={{ marginTop: 10, marginBottom: 5, fontFamily: 'Gotham', color: Constants.colors.blue }}>Options</Text>
            <SortableLabels labels={labels} setLabels={setLabels} />
          </View>
        )
      } else if (wt === WpWidgetType.RATING) {
        return (
          <View>
            { renderQuestion() }
            <Input style={{ flex: 1, marginBottom: 10 }} key="widget-start" name="Start" keyboardType="numeric" placeholder="1" value={start} setValue={(val:string) => setStart(parseInt(val))} />
            <Input style={{ flex: 1, marginBottom: 10 }} key="widget-end" name="End" keyboardType="numeric" placeholder="10" value={end} setValue={(val:string) => setEnd(parseInt(val))} />
          </View>
        )
      } else if (wt === WpWidgetType.VERIFY) {
        return (
          <Input style={{ marginBottom: 10 }} key="widget-verification" name="Verification" placeholder="I agree to the terms..." value={question} setValue={setQuestion} />
        )
      } else {
        return renderQuestion();
      }
    }
    return null;
  }

  const getType = (iconType: WpWidgetType) : "Red"|"AccentRed"|"Green"|"AccentGreen"|"Yellow"|"AccentYellow"|"Blue"|"AccentBlue"|"Purple"|"AccentPurple" => {
    switch (iconType) {
      case WpWidgetType.RATING:
        return wt === iconType ? "Red" : "AccentRed"
      case WpWidgetType.YESNO:
        return wt === iconType ? "Green" : "AccentGreen"
      case WpWidgetType.SHORT:
        return wt === iconType ? "Yellow" : "AccentYellow"
      case WpWidgetType.CHOICE:
        return wt === iconType ? "Blue" : "AccentBlue"
      case WpWidgetType.CHECKBOX:
        return wt === iconType ? "Blue" : "AccentBlue"
      case WpWidgetType.VERIFY:
        return wt === iconType ? "Purple" : "AccentPurple"
      default:
        return "Blue";
    }
  }

  const renderIcon = () => {
    if (wt === WpWidgetType.RATING) {
      return (
        <IconButton shape="Circle" type="Red" onClick={openModal}>
          <AdjustmentsIcon style={cssStyles.icon} />
        </IconButton>
      );
    } else if (wt === WpWidgetType.YESNO) {
      return (
        <IconButton shape="Circle" type="Green" onClick={openModal}>
          <ThumbUpIcon style={cssStyles.icon} />
        </IconButton>
      );
    } else if (wt === WpWidgetType.SHORT) {
      return (
        <IconButton shape="Circle" type="Cyan" onClick={openModal}>
          <AnnotationIcon style={cssStyles.icon} />
        </IconButton>
      );
    } else if (wt === WpWidgetType.CHOICE) {
      return (
        <IconButton shape="Circle" type="Blue" onClick={openModal}>
          <AdjustmentsIcon style={cssStyles.icon} />
        </IconButton>
      );
    } else if (wt === WpWidgetType.CHECKBOX) {
      return (
        <IconButton shape="Circle" type="Blue" onClick={openModal}>
          <CheckCircleIcon style={cssStyles.icon} />
        </IconButton>
      );
    } else if (wt === WpWidgetType.VERIFY) {
      return (
        <IconButton shape="Circle" type="Purple" onClick={openModal}>
          <ShieldCheckIcon style={cssStyles.icon} />
        </IconButton>
      );
    } else {
      return (
        <IconButton shape="Circle" type="AccentPurple" onClick={openModal}>
          <PlusIcon style={cssStyles.icon} />
        </IconButton>
      );
    }
  }

  return (
    <View style={{ marginBottom: 20 }}>
      <WpModal isVisible={isEditing} closeModal={onCloseModal}>
        <ModalBody title={`Edit Widget`} closeModal={onCloseModal}>
          <WidgetOptions active={wt} onPress={onSelectType} />
          { renderSettings() }
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
            <View style={{ width: 125, flexDirection: 'row', cursor: 'pointer' }} onClick={onDeleteWidget}>
              <TrashIcon style={{ color: Constants.colors.red, width: 15, height: 15 }} />
              <Text style={{ marginLeft: 5, color: Constants.colors.red }}>Delete Widget</Text>
            </View>
            <Button key="btn-widget-save" text="Save" onPress={saveWidget} />
            <View style={{ width: 125, backgroundColor: 'red', flexDirection: 'row', opacity: 0 }}>
              <TrashIcon style={{ color: Constants.colors.red, width: 15, height: 15 }} />
              <Text style={{ color: Constants.colors.red }}>Delete Widget</Text>
            </View>
          </View>
        </ModalBody>
      </WpModal>
      { renderIcon() }
    </View>
  );
}

const styles = StyleSheet.create({
  pitch: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
});

const cssStyles = {
  icon: {
    paddingBottom: 5,
    height: 20,
    minWidth: 50,
  }
}
