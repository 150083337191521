import { View, StyleSheet, Text, FlatList } from 'react-native';
import React, { useCallback, useEffect, useRef, useState, FC, useMemo } from 'react';
import Constants from './../../constants';
import { BlockNoteEditor, defaultBlockSchema } from "@blocknote/core";
import { BlockNoteView, InlineContent, createReactBlockSpec, useBlockNote, ReactSlashMenuItem, getDefaultReactSlashMenuItems, HyperlinkToolbarPositioner } from "@blocknote/react";
import "@blocknote/core/style.css";
import { Feather } from '@expo/vector-icons';
import { FormattingToolbarPositioner } from './Positioners/FormattingToolbar';
import { SlashMenuPositioner } from './Positioners/SlashMenu';
import { SideMenuPositioner } from './Positioners/SideMenu';
// import { HyperlinkToolbarPositioner } from './Positioners/HyperlinkToolbar';


export default function TextEditor({ initialContent, onChange, viewOnly, linkParams = {} }: { initialContent?: string, onChange?: (content: string) => void, viewOnly?: boolean, linkParams?: object }): JSX.Element {
  const identifier = useRef(Math.random());

  const ImageBlock = createReactBlockSpec({
    type: "image",
    propSchema: {
      src: {
        default: "https://via.placeholder.com/1000",
      },
    },
    containsInlineContent: true,
    render: ({ block }) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}>
        <img
          style={{
            width: "100%",
          }}
          src={block.props.src}
          alt={"Image"}
          contentEditable={false}
        />
        <InlineContent />
      </div>
    ),
  });

  const insertImage: ReactSlashMenuItem = {
    name: "Insert Image",
    execute: (editor) => {
      const src: string | null = prompt("Enter image URL");
      editor.insertBlocks(
        [
          {
            type: "image",
            props: {
              src: src || "https://via.placeholder.com/1000",
            },
          },
        ],
        editor.getTextCursorPosition().block,
        "after"
      );
    },
    aliases: ["image", "img", "picture", "media"],
    group: "Media",
    icon: <Feather name="image" size={22} />,
    hint: "Insert an image.",
  };

  const customSlashMenuItemList = [
    ...getDefaultReactSlashMenuItems(),
    insertImage,
  ];

  const cleanedContent = useMemo(() => {
    if (initialContent) {
      let content = JSON.parse(initialContent);
      if (Constants.tattooCare && viewOnly && linkParams) {
        // Add linkParams to all links in initialContent by looping through content[].content[] and checking for type: 'link'
        content = content.map((block: any) => {
          block.content = block.content.map((inline: any) => {
            if (inline.type === 'link' && Object.keys(linkParams).length) {
              let connector = inline.href.includes('?') ? '&' : '?';
              inline.href = inline.href + connector + Object.keys(linkParams).map((key: string) => `${key}=${linkParams[key]}`).join('&');
            }
            return inline;
          })
          return block;
        });
      }
      return content;
    }
    return null;
  }, [initialContent, viewOnly, linkParams]);

  // Creates a new editor instance.
  const editor: BlockNoteEditor | null = useBlockNote({
    editable: !viewOnly,
    initialContent: cleanedContent,
    blockSchema: {
      ...defaultBlockSchema,
      image: ImageBlock,
    },
    // customElements: {
    //   formattingToolbar: CustomFormattingToolbar
    // },
    slashMenuItems: customSlashMenuItemList,
    onEditorContentChange: (editor) => {
      // TODO: Remove links
      // editor.removeStyles({ underline: true });
      // console.log('editor.topLevelBlocks', editor.topLevelBlocks)
      if (onChange) {
        onChange(JSON.stringify(editor.topLevelBlocks));
      }
    },
  });

  return (
    <View style={{ flex: 1, position: 'relative', overflow: 'visible', zIndex: 10 }}>
      <div id={`text-editor-${identifier.current}`} style={{ overflow: 'visible', position: 'relative' }}>
        <View style={{ flex: 1, position: 'relative', zIndex: 10, borderWidth: viewOnly ? 0 : 1, borderColor: Constants.colors.info + '44', borderRadius: 10, padding: viewOnly ? 0 : 5, paddingBottom: 15, ...(viewOnly ? {} : { minHeight: 100, maxHeight: 400, overflowX: 'visible', overflowY: 'scroll' }) }}>
          <BlockNoteView style={{ position: 'relative' }} editor={editor}>
            <FormattingToolbarPositioner editor={editor} parentId={`text-editor-${identifier.current}`} />
            <HyperlinkToolbarPositioner editor={editor} />
            <SlashMenuPositioner editor={editor} parentId={`text-editor-${identifier.current}`} />
            <SideMenuPositioner editor={editor} parentId={`text-editor-${identifier.current}`} />
          </BlockNoteView>
          {/* <Button text="Add Text" onPress={() => editor?.createLink('https://whistlepitch.com', 'link')} /> */}
        </View>
      </div>
    </View>
  )
}

const styles = StyleSheet.create({
  messageSubtext: {
    color: Constants.colors.info,
    fontSize: 12,
    paddingHorizontal: 35,
    marginTop: 4,
  },
  bubbleWrapper: {
    width: '100%',
    flexDirection: 'column',
    marginVertical: 8
  },
  bubbleReceived: {
    backgroundColor: "#E3E3E3",
    padding: 10,
    marginLeft: '5%',
    borderRadius: 15,
    marginTop: 5,
    marginRight: "45%",
    maxWidth: '50%',
    alignSelf: 'flex-start',
  },
  bubbleSent: {
    backgroundColor: Constants.colors.primary,
    padding: 10,
    marginLeft: '45%',
    borderRadius: 15,
    marginTop: 5,
    marginRight: "5%",
    maxWidth: '50%',
    alignSelf: 'flex-end',
  },
  rightArrow: {
    position: "absolute",
    backgroundColor: Constants.colors.primary,
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomLeftRadius: 25,
    right: -10
  },
  rightArrowOverlap: {
    position: "absolute",
    backgroundColor: "#F3F3F3",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomLeftRadius: 18,
    right: -20
  },

  /*Arrow head for recevied messages*/
  leftArrow: {
    position: "absolute",
    backgroundColor: "#E3E3E3",
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomRightRadius: 25,
    left: -10
  },
  leftArrowOverlap: {
    position: "absolute",
    backgroundColor: "#F3F3F3",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomRightRadius: 18,
    left: -20
  },
})
