import React from 'react';
import { WpFullPitch } from './pitch';
import { WpFullCampaign } from './campaign';
import { WpCampaign } from './user';


export interface WpLead {
  id: string
  name: string
  phone: string
  lastActive?: string,
  lastViewed?: string,
  optedOut: boolean,
  optedIn: boolean,
  invalidPhone: boolean,
  appointments: WpLeadEvent[]
  widgets: WpLeadWidget[]
  campaigns: WpCampaignLead[]
  groups: WpGroup[]
  messages: WpLeadMessage[]
  messageQueue: WpLeadMessage[]
  featureQueue: WpLeadFeature[]
  activity: WpLeadActivity[]
  landingPageId?: string
}

export interface WpLeadFeature {
  id: string
  leadId: string
  campaignName: string
  appointmentDate: string
  message: string
  created: string
  scheduledFor: string
  error: string
  complete: boolean
}

export interface WpLeadMessage {
  id: string
  userId?: string
  campaignId: string
  campaignMessageId: string
  message: string
  created?: string
  sent?: string
  success: boolean
  delivered: boolean
  aiGenerated: boolean
  senderName: string
}

export interface WpLeadBasic {
  id: string,
  name: string,
  phone: string,
  optedOut: boolean,
  optedIn: boolean,
  invalidPhone: boolean,
  campaigns: WpCampaignLead[],
  created?: string,
  lastActive?: string,
  lastViewed?: string,
  responsibleUserId?: string
  pseudoTags: WpLeadPseudoTag[]
  stats: {
    views: number,
    seconds: number
  }
}

export interface WpCampaignLead {
  id: string
  code: string
  codes: WpCampaignLeadCode[]
  campaignId: string
  campaign: WpFullCampaign
  created: string
}

export interface WpCampaignLeadCode {
  code: string
  url: string
  campaignName: string
  pitchName: string
  path: string
}

export interface WpLeadPseudoTag {
  campaignId: string
  pitchId: string
  slideId: string
  widgetId: string
  verified: boolean
  video: boolean
  widget: boolean
  scheduled: boolean
  redirected: boolean
  unopened: boolean
  opened: boolean
  reopened: boolean
  complete: boolean
  optedOut: boolean
  sent: boolean
}

export interface WpLeadWidget {
  widgetId: string
  pitchId: string
  label: string
  values: string[]
}

export interface WpLeadViewRange {
  start: number
  end: number
}

export interface WpLeadTime {
  seconds: number
  video: number
  videoDuration: number
  viewRanges: WpLeadViewRange[]
  audio: number
  audioDuration: number
  pitchSlideId: string
  pitchSlideTitle: string
}

interface WpLeadState {
  pitch?: WpFullPitch,
  lead?: WpLead,
  activity?: WpLeadActivity,
  campaign?: WpFullCampaign,
  times?: WpLeadTime[],
  events?: WpLeadEvent[]
}

export interface WpLeadActivity {
  ssid: string
  deviceId: string
  leadId: string
  pitch: WpFullPitch
  userId: string
  campaignFeatureId: string
  campaignId: string
  times: WpLeadTime[]
  lastActive: string
  archived: boolean
  active: boolean
  created: string
}

export interface WpGroup {
  id: string
  name: string
  code: string
  stats: WpGroupStats
  landingPage: WpGroupLandingPage
}

export interface WpEvent {
  id: string
  organizationId: string
  cancelled: boolean
  eventTime: string
  eventLocation: string
  eventTimeZone: string
  eventAddress: string
  created: string
  appointments: [WpLeadEvent]
}

export interface WpGroupLandingPage {
  title: string
  description: string
  explainer: string
  thumbnail: string
  explainerMimeType: string
  color: string
  profile: string
  schedule: WpGroupLandingPageSchedule
  price: number
  sendCampaign: boolean
  campaignId: string
  trialPeriod: number
}

export interface WpGroupStats {
  leads: number
}

export interface WpLeadEvent {
  id: string
  eventUrl: string,
  eventDate: string,
  eventTime: string,
  campaigns: WpCampaign[],
  pitchSlideId: string
  pitchId: string
}

export const initialState: WpLeadState = {}


export enum WpGroupLandingPageSchedule {
  WEEKLY = "WEEKLY",
  BI_MONTHLY = "BI_MONTHLY",
  MONTHLY = "MONTHLY",
  OTHER = "OTHER"
}

export enum WpLeadAction {
  SET_DATA = "SET_DATA",
  TRACK_LEAD = "TRACK_LEAD",
  UPDATE_LEAD_WIDGET = "UPDATE_LEAD_WIDGET",
  UPDATE_LEAD_TIME = "UPDATE_LEAD_TIME",
  SET_LEAD_EVENT = "SET_LEAD_EVENT"
}

interface WpLeadActions {
  type: WpLeadAction,
  pitch?: WpFullPitch,
  lead?: WpLead,
  activity?: WpLeadActivity,
  campaign?: WpFullCampaign
  leadWidget?: WpLeadWidget,
  times?: WpLeadTime[],
  time?: WpLeadTime,
  event?: WpLeadEvent
}

export const LeadContext = React.createContext<{
  state: WpLeadState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});

export const leadReducer = (state: WpLeadState, action: WpLeadActions) => {
  let times;

  switch (action.type) {
    case WpLeadAction.SET_DATA:
      let { pitch, lead, activity, campaign, times } = action;

      return {
        ...state,
        lead: lead,
        pitch: pitch,
        activity: activity,
        campaign: campaign,
        times: times,
        events: lead?.appointments ?? []
      }
    case WpLeadAction.UPDATE_LEAD_WIDGET:
      const { leadWidget } = action;

      if (!leadWidget) {
        return state
      }

      const lindex = state?.lead?.widgets.findIndex((w) => w.widgetId === leadWidget.widgetId)

      let widgets = state?.lead?.widgets;
      if (lindex !== undefined && lindex > -1 && state?.lead?.widgets) {
        widgets = [
          ...state.lead.widgets.slice(0, lindex),
          leadWidget,
          ...state.lead.widgets.slice(lindex + 1),
        ]
      } else {
        widgets = [
          ...state?.lead?.widgets ?? [],
          leadWidget
        ]
      }

      return {
        ...state,
        lead: {
          ...state?.lead,
          widgets: widgets
        }
      }
    case WpLeadAction.UPDATE_LEAD_TIME:
      const { time } = action;

      if (!time) {
        return state
      }

      const tindex = state?.times?.findIndex((t) => t.pitchSlideId === time.pitchSlideId)

      times = state?.times;
      if (tindex !== undefined && tindex > -1 && state?.times) {
        times = [
          ...state.times.slice(0, tindex),
          time,
          ...state.times.slice(tindex + 1),
        ]
      } else {
        times = [
          ...state?.times ?? [],
          time
        ]
      }

      return {
        ...state,
        times: times
      }
    case WpLeadAction.SET_LEAD_EVENT:
      const { event } = action;

      if (!event) {
        return state
      }

      const eindex = state?.events?.findIndex((e) => e.pitchSlideId === event.pitchSlideId)

      let events = state?.events;
      if (eindex !== undefined && eindex > -1 && state?.events) {
        events = [
          ...state.events.slice(0, tindex),
          event,
          ...state.events.slice(eindex + 1),
        ]
      } else {
        events = [
          ...state?.events ?? [],
          event
        ]
      }

      return {
        ...state,
        events: events
      }
    case WpLeadAction.TRACK_LEAD:
      return {
        ...state,
        activity: action?.activity
      }
    default:
      return state;
  }
};
