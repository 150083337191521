import { View, Text, StyleSheet, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import Toast from 'react-native-toast-message';

import Constants from '../constants';
import { UserContext } from '../contexts/user';
import CampaignOverview from '../components/CampaignOverview';
import ConfirmPhoneModal from '../components/User/ConfirmPhoneModal';
import LeadTable from '../components/Leads/LeadTable';
import Navbar from '../components/Navbar';
import { RESET_PASSWORD } from '../graphql/user';
import { useNavigate, useParams, useSearchParams } from '../react-router';
import { ForgotPasswordView } from '@whistlepitch/wp-components';
import { useQuery, useMutation } from "@apollo/client";


export default function ResetPassword(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [token, setToken] = useState<string>();

  const params = useParams();

  const [resetPassword, { data, loading, client }] = useMutation(RESET_PASSWORD, {
    fetchPolicy: "no-cache"
  })

  const navigate = useNavigate();

  useEffect(() => {
    setToken(params.token)
  }, [])

  useEffect(() => {
    if (data) {
      if (!data.resetPassword.success) {
        if (data.resetPassword.errors.length) {
          alert(data.resetPassword.errors[0])
          setErrorMessage(data.resetPassword.errors[0]) // TODO: Fix this
        } else {
          setErrorMessage('An unknown error occurred.')
        }
      } else {
        localStorage.setItem('token', data.resetPassword.token)
        client.resetStore();
        navigate('/dashboard')
      }
    }
  }, [data])

  if (state.user) {
    navigate('/dashboard')
  }

  const onForgotPassword = ({password, password2} : {password: String, password2: String}) => {
    resetPassword({
      variables: {
        token: token,
        password: password,
        password2: password2
      }
    })
  }

  const toLogin = () => {
    navigate('/login')
  }

  const renderAlternateLogos = () => {
    if (Constants.tattooCare) {
      return (
        <View style={{ position: 'absolute', top: 20, left: 40, padding: 60, zIndex: 100, backgroundColor: 'white' }}>
          <Image style={{ width: 175, height: 40 }} source={require('./../assets/tattoocare_heading_inline.png')} />
        </View>
      )
    }
    return null
  }

  return (
    <View style={{ flex: 1 }}>
      <ForgotPasswordView brand={Constants.brand} onForgotPassword={onForgotPassword} onBackToLogin={toLogin} />
      { renderAlternateLogos() }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  }
})
