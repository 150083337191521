import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import Spinner from './../Spinner';
import LeadTable, { TableControls, Filter, Report } from './../Leads/LeadTable';
import CampaignOverview from './../CampaignOverview';
import { CampaignContext, WpCampaignAction, WpFeatureType } from './../../contexts/campaign';
import { WpPitchStructure, WpSlideType, WpWidgetType } from './../../contexts/pitch';
import { GET_CAMPAIGN } from './../../graphql/queries';
import { CSV_REPORT, UPDATE_CAMPAIGN } from './../../graphql/campaign';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { PageTitle, StatsCard, Button, PercentageCard, Chart } from "@whistlepitch/wp-components";
import { UsersIcon, CheckCircleIcon, FireIcon } from '@heroicons/react/solid'
import Icon from '../Icon';
import NewButton from '../NewButton';
import ManageLeadsModal from '../Leads/ManageLeadsModal';
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';


interface SummaryData {
  label: string;
  value: number;
  tooltip: string;
}

export default function CampaignReport({ onEdit } : { onEdit: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(CampaignContext);
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
  const [slideIds, setSlideIds] = useState<string[]>([]);
  const [pitchIds, setPitchIds] = useState<string[]>([]);
  const [completeFilter, setCompleteFilter] = useState<object>({});
  const [receivedFilter, setReceivedFilter] = useState<object>({});
  const [filters, setFilters] = useState<{[id:string]: Filter}>({});
  const [reports, setReports] = useState<{[id:string]: Report}>({});
  const [report, setReport] = useState<Report>();
  const [slides, setSlides] = useState<WpPitchStructure[]>([]);
  const [redirectPitchIds, setRedirectPitchIds] = useState<string[]>([])
  const [refresh, setRefresh] = useState<number>(0)
  const [ReceivedTable, setReceivedTable] = useState<JSX.Element>()
  const [ReportTables, setReportTables] = useState<JSX.Element[]>()
  const [showManageLeads, setShowManageLeads] = useState<boolean>(false)
  const [showCsvReport, setShowCsvReport] = useState<boolean>(false)
  const [csv, setCsv] = useState<string>()

  const [csvReport, csvReportData] = useLazyQuery(CSV_REPORT, {
    fetchPolicy: "no-cache"
  })

  useEffect(() => {
    if (state.campaign) {
      let newSlides = [];
      let pids = [];
      let rpids: string[] = [];
      let sids = [];
      let filters: {[id:string]: Filter} = {};
      let reports: {[id:string]: Report} = {};
      let new_report: Report = {
        campaignId: state?.campaign?.id ?? '',
        calendar: [],
        video: [],
        verified: [],
        response: []
      };

      for (let i = 0; i < state.campaign.structure.feature.length; i++) {
        let feature = state.campaign.structure.feature[i];
        if (feature.featureType === WpFeatureType.PITCH) {
          if (feature.pitchId) {
            pids.push(feature.pitchId)
          }
          for (let j = 0; j < state.campaign.structure.feature[i].structure.length; j++) {
            let slide = state.campaign.structure.feature[i].structure[j];
            if (slide.id === "redirect") {
              if (slide.type !== null && rpids.indexOf(feature.pitchId) === -1) {
                rpids.push(feature.pitchId);
              }
            } else {
              newSlides.push(slide)
              sids.push(slide.id)

              let filter: Filter = {slideIdsSeen: [slide.id]};
              if (i < state.campaign.structure.feature.length - 1) {
                filter.slideIdsUnseen = slideIds.slice(i + 1);
              }
              filters[slide.id] = filter;

              // let report: Report = { campaignId: state?.campaign?.id ?? '' }
              if (slide.slideType === WpSlideType.CALENDAR) {
                // report.calendar = [slide.id];
                new_report.calendar.push(slide.id);
              }
              if (slide.isVideo || slide.slideType === WpSlideType.YOUTUBE) {
                // report.video = [slide.id];
                new_report.video.push(slide.id);
              }
              if (slide.structure) {
                for (let i = 0; i < slide.structure.length; i++) {
                  if (slide.structure[i].type === WpWidgetType.VERIFY) {
                    // report.verified = [...report.verified ?? [], slide.structure[i].id]
                    new_report.verified.push(slide.structure[i].id)
                  } else {
                    // report.response = [...report.response ?? [], slide.structure[i].id]
                    new_report.response.push(slide.structure[i].id)
                  }
                }
              }
              // reports[slide.id] = report;
            }
          }
        }
      }
      setSlides(newSlides)
      setPitchIds(pids)
      setRedirectPitchIds(rpids)
      setReport(new_report)
      setCompleteFilter({complete: pids})
      setReceivedFilter({unopened: pids})

      for (let i = 0; i < sids.length; i++) {
        if (i < sids.length - 1) {
          filters[sids[i]].slideIdsUnseen = sids.slice(i + 1);
        }
        filters[sids[i]].incomplete = pids;
      }

      setFilters(filters);
      setReports(reports);
      setSlideIds(sids)

      let stats = [];
      let messageIndex = 1;
      for (let i = 0; i < state.campaign.stats.features.length; i++) {
        let feature = state.campaign.stats.features[i];
        let name = feature.featureId ?
          `Message ${messageIndex++}`
          : feature.label.substr(0, 12) + (feature.label.length > 12 ? '...' : '')

        stats.push({
          label: name,
          value: feature.complete && feature.leads ? parseInt((feature.complete / feature.leads * 100).toFixed(2)) : 0,
          tooltip: feature.featureId ? `Delivered: ${feature.complete}` : `Viewed: ${feature.complete}`
        })
      }
      setSummaryData(stats)
    }
  }, [state.campaign?.stats.features, state.campaign?.structure.feature])

  useEffect(() => {
    if (csvReportData.data && csvReportData.data.csvReport.success) {
      setCsv(csvReportData.data.csvReport.csv)
    } else {
      setCsv(undefined)
    }
  }, [csvReportData.data])

  // useEffect(() => {
  //   if (pitchIds.length) {
  //     setReceivedTable(
  //
  //     )
  //   } else {
  //     setReceivedTable(undefined)
  //   }
  // }, [pitchIds])
  //
  // useEffect(() => {
  //   if (slides.length) {
  //     setReportTables(
  //     )
  //   } else {
  //     setReportTables(undefined)
  //   }
  // }, [slides])

  const onRefresh = () => {
    setRefresh(refresh + 1)
  }

  const getCsv = () => {
    if (!csvReportData.loading && state.campaign) {
      setShowCsvReport(true)
      csvReport({ variables: { campaignId: state.campaign.id }})
    }
  }

  const saveCsv = () => {
    if (csv) {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Report.csv';
      hiddenElement.click();
    }
  }

  const renderHeader = () : JSX.Element => {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 20 }}>
        <Text style={{ width: 200, marginLeft: 50, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Name</Text>
        <Text style={{ width: 200, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Phone</Text>
        <Text style={{ width: 150, fontFamily: 'GothamMedium', color: Constants.colors.info }}>{Constants.blastView ? 'Blasts' : 'Campaigns'}</Text>
      </View>
    )
  }

  const renderStats = () : JSX.Element|null => {
    if (!Constants.blastView) {
      return (
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 20, marginTop: 30, flexWrap: 'wrap' }}>
            <StatsCard
              style={Constants.blastView ? { flex: 1 } : { flex: 1, maxWidth: 400, minWidth: 250 }}
              key="stats-leads"
              description={'' + state.campaign.stats.leads}
              title="Leads"
              type="White"
            >
              <Icon style={{ padding: 12 }} name="users" color={Constants.colors.darkBlue} raw={true} />
            </StatsCard>
            <StatsCard
              style={Constants.blastView ? { flex: 1 } : { flex: 1, maxWidth: 400, minWidth: 250 }}
              key="stats-messages"
              description={'' + state.campaign.stats.messages}
              title="Messages Sent"
              type="White"
            >
              <Icon style={{ padding: 15 }} name="message" color={Constants.colors.darkBlue} raw={true} />
            </StatsCard>
            <StatsCard
              style={{ flex: 1, maxWidth: 400, minWidth: 250 }}
              key="stats-current-leads"
              description={'' + state.campaign.stats.currentLeads}
              title="Currently Viewing"
              type="White"
            >
              <Icon style={{ padding: 12 }} name="eye" color={Constants.colors.darkBlue} raw={true} />
            </StatsCard>
          </View>
          <View style={{ flexDirection: 'row', gap: 20, marginTop: 20, marginBottom: 40, flexWrap: 'wrap' }}>
            <View style={{ flex: 3, minWidth: 350, borderRadius: 10, paddingRight: 20, backgroundColor: '#FFFFFF' }}>
              <Chart data={summaryData} type="Percentage" legend="Lead Rate" />
            </View>
            <PercentageCard style={{ flex: 1 }} title="Completed" percentage={state.campaign.stats.complete ? Math.round(state.campaign.stats.complete / state.campaign.stats.leads * 100) : 0} />
          </View>
        </View>
      )
    } else {
      return (
        <View style={{ flexDirection: 'row', gap: 20, marginVertical: 30, flexWrap: 'wrap' }}>
          <View style={{ flexGrow: 2, flexBasis: 'auto', minWidth: 250, flexDirection: 'column', justifyContent: 'flex-start', gap: 20 }}>
            <StatsCard
              style={{ flex: 1 }}
              description={'' + state.campaign.stats.messages}
              title={"Messages Sent"}
              type="White"
            >
              <Icon style={{ padding: 15 }} name="message" color={Constants.colors.darkBlue} raw={true} />
            </StatsCard>
            <StatsCard
              style={{ flex: 1 }}
              description={'' + state.campaign.stats.leads}
              title={"Contacts"}
              type="White"
            >
              <Icon style={{ padding: 12 }} name="users" color={Constants.colors.darkBlue} raw={true} />
            </StatsCard>
          </View>
          <PercentageCard key="pc-delivery" style={{ flexGrow: 1, flexBasis: 'auto', minWidth: 250 }} title={"Delivery Rate"} percentage={state.campaign.stats.messages ? parseFloat((state.campaign.stats.messagesSuccess / state.campaign.stats.messages * 100).toFixed(2)) : 0} />
          <PercentageCard key="pc-response" style={{ flexGrow: 1, flexBasis: 'auto', minWidth: 250 }} title={"Response Rate"} percentage={state.campaign.stats.leads ? parseFloat((state.campaign.stats.responses / state.campaign.stats.leads * 100).toFixed(2)) : 0} />
        </View>
      )
    }
  }

  const renderTable = () : JSX.Element|null => {
    if (Constants.blastView) {
      return (
        <View style={{ flexDirection: 'row', gap: 20, marginTop: 20, marginBottom: 40, flexWrap: 'wrap' }}>
          <LeadTable header={renderHeader()} campaignId={state.campaign.id} selectable={true} searchable={true} selectedLeads={selectedLeads} setSelectedLeads={setSelectedLeads} />
        </View>
      )
    } else {
      return (
        <View style={{ marginTop: 30 }}>
         <TableControls thisCampaignId={state.campaign.id} onDownload={getCsv} onRefresh={onRefresh} selectedLeads={selectedLeads} setSelectedLeads={setSelectedLeads} />
         <View style={{ justifyContent: 'space-between', alignItems: 'stretch', flexDirection: 'row', gap: 10, overflow: 'scroll' }}>
           <View style={styles.table}>
             <Text style={styles.tableLabel}>Received</Text>
             <View style={styles.tablePadding}>
               <LeadTable selectedLeads={selectedLeads} selectable={!Constants.blastView} setSelectedLeads={setSelectedLeads} refresh={refresh} campaignId={state.campaign.id} filter={receivedFilter} report={{ ...report, unopened: pitchIds, optedOut: true }} showControls={false} />
             </View>
           </View>
           { slides.map((item, index) => {
               return (
                 <View key={`item-${item.id}`} style={styles.table}>
                   <Text style={styles.tableLabel}>{(item.label.substr(0, 25) + (item.label.length > 25 ? '...' : ''))}</Text>
                   <View style={styles.tablePadding}>
                     <LeadTable selectedLeads={selectedLeads} selectable={!Constants.blastView} setSelectedLeads={setSelectedLeads} refresh={refresh} campaignId={state?.campaign?.id} filter={filters[item.id]} report={report} showControls={false} />
                   </View>
                 </View>
               )
             }) }
           <View style={styles.table}>
             <Text style={styles.tableLabel}>Complete</Text>
             <View style={styles.tablePadding}>
               <LeadTable selectedLeads={selectedLeads} selectable={!Constants.blastView} setSelectedLeads={setSelectedLeads} refresh={refresh} campaignId={state.campaign.id} filter={completeFilter} report={{ ...report, redirected: redirectPitchIds }} showControls={false} />
             </View>
           </View>
         </View>
       </View>
      )
    }
  }

  if (!state.campaign) {
    return <View></View>
  }

  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 35, alignItems: 'center' }}>
        <Text style={[styles.heading, { marginRight: 'auto' }]}>{state.campaign.name}</Text>
        <Button type="Primary" size="Default" shape="Pill" onClick={onEdit}>
          <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Edit {Constants.localizations.campaign}</Text>
        </Button>
      </View>
      {renderStats()}
      {renderTable()}
      <WpModal isVisible={showCsvReport}>
        <ModalBody title="CSV Report" closeModal={setShowCsvReport}>
          <Text style={{ color: '#111' }}>We are generating a report of all the data for each { Constants.localizations.lead } in this campaign. Come back here any time to generate and download the most up to date report.</Text>
          <ModalFooter>
            { !csv || csvReportData.loading ? <Spinner /> :
              <Button type="Primary" size="Default" shape="Pill" onClick={saveCsv}>
                <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Download Report</Text>
              </Button> }
          </ModalFooter>
        </ModalBody>
      </WpModal>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  table: {
    flexBasis: 300,
    flexGrow: 1,
    backgroundColor: '#F0F2F9',
    borderRadius: 10,
  },
  tablePadding: {
    padding: 10
  },
  tableLabel: {
    color: Constants.colors.darkBlue,
    backgroundColor: '#E7EBF7',
    marginBottom: 10,
    fontSize: 16,
    fontFamily: 'GothamMedium',
    fontWeight: 'bold',
    width: '100%',
    padding: 15,
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  stat: {
    width: 40,
    height: 40,
    color: Constants.colors.blue
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
