import { gql } from "@apollo/client";

export const CREATE_PITCH = gql`
  mutation newPitch {
    createPitch {
      success
      errors
      pitch {
        id
      }
    }
  }
`;

export const UPDATE_PITCH = gql`
  mutation updatePitch($pitchId: ID!, $title: String, $color: String, $image: String, $redirectType: RedirectType, $redirectUrl: String, $redirectPhone: String, $redirectButton: String) {
    updatePitch(pitchId: $pitchId, pitch: {title: $title, color: $color, image: $image, redirectType: $redirectType, redirectUrl: $redirectUrl, redirectPhone: $redirectPhone, redirectButton: $redirectButton}) {
      success
      errors
      pitch {
        id
      }
    }
  }
`;

export const CREATE_PITCH_SLIDE = gql`
  mutation createPitchSlide($pitchId: ID, $featureId: ID, $slideType: PitchSlideType, $after: ID, $pitchSlide: PitchSlideInput) {
    createPitchSlide(pitchId: $pitchId, featureId: $featureId, slideType: $slideType, after: $after, pitchSlide: $pitchSlide) {
      success
      errors
      structure {
        id
        label
        slideType
        thumbnail
        isVideo
        structure {
          id
          label
          type
        }
      }
      slide {
        id
        title
        pitchId
        created
      }
    }
  }
`;

export const UPDATE_PITCH_SLIDE = gql`
  mutation updatePitchSlide($pitchSlideId: ID!, $slideType: PitchSlideType, $internalTitle: String, $title: String, $description: String, $thumbnail: String, $url: String, $audioFile: String, $content: String, $mimeType: String, $calendars: [String], $calendarsRemove: [String]) {
    updatePitchSlide(pitchSlideId: $pitchSlideId, pitchSlide: {slideType: $slideType, internalTitle: $internalTitle, title: $title, description: $description, thumbnail: $thumbnail, url: $url, audioFile: $audioFile, content: $content, mimeType: $mimeType, calendars: $calendars, calendarsRemove: $calendarsRemove}) {
      success
      errors
      structure {
        id
        label
        slideType
        thumbnail
        isVideo
        structure {
          id
          label
          type
        }
      }
    }
  }
`;

export const DELETE_PITCH_SLIDE = gql`
  mutation deletePitchSlide($pitchSlideId: ID!) {
    deletePitchSlide(pitchSlideId: $pitchSlideId) {
      success
      errors
      structure {
        id
        label
        slideType
        thumbnail
        isVideo
        structure {
          id
          label
          type
        }
      }
    }
  }
`;

export const CREATE_SLIDE_WIDGET = gql`
  mutation createSlideWidget($pitchSlideId: ID, $campaignId: ID, $widgetType: WidgetType, $slideWidget: SlideWidgetInput) {
    createSlideWidget(pitchSlideId: $pitchSlideId, campaignId: $campaignId, widgetType: $widgetType, slideWidget: $slideWidget) {
      success
      errors
      structure {
        id
        type
        label
      }
      widget {
        id
        pitchSlideId
        label
        fineText
        widgetType
        labels
        start
        end
        created
      }
    }
  }
`;

export const UPDATE_SLIDE_WIDGET = gql`
  mutation updateSlideWidget($slideWidgetId: ID!, $label: String, $fineText: String, $widgetType: WidgetType, $labels: [String], $start: Int, $end: Int) {
    updateSlideWidget(slideWidgetId: $slideWidgetId, slideWidget: {label: $label, fineText: $fineText, widgetType: $widgetType, labels: $labels, start: $start, end: $end}) {
      success
      errors
      structure {
        id
        type
        label
      }
      widget {
        id
        pitchSlideId
        label
        fineText
        widgetType
        labels
        start
        end
        created
      }
    }
  }
`;

export const DELETE_SLIDE_WIDGET = gql`
  mutation deleteSlideWidget($slideWidgetId: ID!) {
    deleteSlideWidget(slideWidgetId: $slideWidgetId) {
      success
      errors
      structure {
        id
        type
        label
      }
    }
  }
`;
