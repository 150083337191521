import { View, Text, ActivityIndicator, StyleSheet, Image } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from '../react-router';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import Constants from '../constants';
import { UserContext } from '../contexts/user';
import { GET_LANDING_PAGE } from '../graphql/queries';
import Input, { Label } from '../components/Input';
import Spinner from '../components/Spinner';
import { WpLandingPage } from '../contexts/campaign';
import { UPDATE_LANDING_PAGE } from '../graphql/campaign';
import BasicLayout from '../components/Shared/BasicLayout';
import { Button, Theme } from '../components/Button';
import Uppyup from '../components/Uppyup';
import CampaignDropdown from '../components/CampaignDropdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from 'react-native-toast-message';


export default function LandingPage(): JSX.Element {
  const { state } = React.useContext(UserContext);

  const [landingPage, setLandingPage] = useState<WpLandingPage>();
  const [title, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [newProfile, setNewProfile] = useState<boolean>();
  const [showNewLead, setShowNewLead] = useState<boolean>(false);
  const [reload, setReload] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [showLandingPage, setShowLandingPage] = useState<boolean>(false);

  const [color, setColor] = useState<string>()
  const [profile, setProfile] = useState<string>()
  const [campaignId, setCampaignId] = useState<string>()

  const [copyText, setCopyText] = useState<string>("Save and Copy Link")
  const [aspectRatioExplainer, setAspectRatioExplainer] = useState<number>(0)
  const [aspectRatioProfile, setAspectRatioProfile] = useState<number>(0)
  const [ExplainerElement, setExplainerElement] = useState<JSX.Element | null>(null)
  const [ProfileElement, setProfileElement] = useState<JSX.Element>()

  const params = useParams();

  const navigate = useNavigate();

  const [getLandingPage, { data, loading, refetch, error }] = useLazyQuery(GET_LANDING_PAGE, {
    fetchPolicy: "no-cache",
  });

  const [updateLandingPage, updateLandingPageData] = useMutation(UPDATE_LANDING_PAGE, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    loadLandingPage();
  }, [reload])

  useEffect(() => {
    if (data?.landingPage?.success) {
      setLandingPage(data.landingPage.landingPage)
      setName(data.landingPage.landingPage.title ? data.landingPage.landingPage.title : 'New Landing Page')
      setDescription(data.landingPage.landingPage.description)
    }
  }, [data])

  useEffect(() => {
    if (title && landingPage && title !== landingPage.title) {
      if (saveTimeout) {
        clearTimeout(saveTimeout)
      }

      let gid = landingPage.id;
      setSaveTimeout(setTimeout(() => {
        updateLandingPage({
          variables: { landingPageId: gid, landingPage: { title } }
        })
      }, 1200))
    }

    return () => { if (saveTimeout) { clearTimeout(saveTimeout) } }
  }, [title])

  useEffect(() => {
    if (!profile) {
      return;
    }

    let isValid = true;
    Image.getSize(profile, (width, height) => {
      if (isValid) {
        setAspectRatioProfile(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [profile, aspectRatioProfile]);

  useEffect(() => {
    if (profile) {
      setProfileElement(<View style={styles.logo} ><Image style={[styles.profile, { aspectRatio: aspectRatioProfile }]} source={{ uri: profile }} /></View>)
    }
  }, [aspectRatioProfile, profile])

  useEffect(() => {
    if (updateLandingPageData.data?.updateLandingPage?.success) {
      setCopyText("Save and Copy Link")
    }
  }, [updateLandingPageData.data?.updateLandingPage])

  const onUploadProfileComplete = (url: string, mime: string) => {
    setProfile(url);
    setNewProfile(false);
  }

  const onNewProfile = () => {
    setNewProfile(true);
  }

  const loadLandingPage = () => {
    if (data && landingPage) {
      refetch({ landingPageId: landingPage.id });
    } else {
      getLandingPage({ variables: { landingPageId: params.landingPageId } })
    }
  }

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const onEditLandingPage = () => {
    setShowLandingPage(true);
  }

  const doneEditLandingPage = () => {
    setShowLandingPage(false);
    setReload(reload + 1);
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const onOpenNewLead = () => {
    setShowNewLead(true)
  }

  const onNewLead = (ids: string[]) => {
    // console.log("New lead", ids, ids ? ids.length : -1)
    setRefresh(refresh + 1);
    onCloseNewLead();
  }

  const onCloseNewLead = () => {
    setShowNewLead(false);
  }

  const onSave = () => {
    if (landingPage) {
      // TODO: Submit campaign ids

      updateLandingPage({
        variables: {
          landingPageId: landingPage.id,
          landingPage: {
            title: title,
            description: description,
            color: color,
            profile: profile,
          }
        }
      })
    }
  }

  const confirmCopy = () => {
    setCopyText('Copied! Saving...')
    Toast.show({
      type: 'success',
      text1: 'Link Copied',
    });
    onSave();
  }

  const renderHeader = (): JSX.Element => {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 20 }}>
        <Text style={{ width: 200, marginLeft: 50, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Name</Text>
        <Text style={{ width: 200, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Phone</Text>
        {Constants.blastView ? null : <Text style={{ width: 150, fontFamily: 'GothamMedium', color: Constants.colors.info }}>View Time</Text>}
        {Constants.blastView ? null : <Text style={{ width: 100, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Views</Text>}
        <Text style={{ width: 150, fontFamily: 'GothamMedium', color: Constants.colors.info }}>{Constants.blastView ? 'Blasts' : 'Campaigns'}</Text>
      </View>
    )
  }

  const renderBody = () => {
    if (!data) {
      return <Text key="landing-page-not-found" style={{ flex: 1, textAlign: 'center', color: Constants.colors.secondary }}>Not Found</Text>
    } else if (loading) {
      return <Spinner />
    } else if (landingPage) {
      return (
        <View>
          <View style={styles.header}>
            <Input textAlign='left' isHeading={true} fontColor={Constants.colors.blue} value={title} setValue={setName} />
          </View>
          <View style={styles.block}>
            <View style={styles.row}>
              <Input style={{ marginBottom: 10 }} name="Description" optional={true} multiline={true} placeholder="Enter a description" value={description} setValue={setDescription} />

              <Label style={{ marginTop: 10 }} name={`Send ${Constants.localizations.campaign}`} optional={true} />
              <CampaignDropdown style={{ position: 'relative', zIndex: 15 }} info={`Send ${Constants.localizations.campaign} to ${Constants.localizations.lead} once they submit.`} value={campaignId} setValue={setCampaignId} />
            </View>
            <View style={styles.row}>
              <Input name="Landing Page Branding Color" style={{ marginBottom: 20 }} isColor={true} value={color} setValue={setColor} />
              <Label name="Landing Page Branding Image" />
              {profile && !newProfile ?
                <View style={{ alignItems: 'center' }}>
                  {ProfileElement}
                  <Button style={{ marginTop: 25 }} width={'100%'} text="Upload New Brand Image" theme={Theme.LINK_PRIMARY} onPress={onNewProfile} />
                </View> :
                <Uppyup plugins={[]} height={200} onDone={() => { }} onUploadComplete={onUploadProfileComplete} />
              }

              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 25, marginTop: 25, marginBottom: 10 }}>
                <CopyToClipboard text={`${Constants.app_url}/p/${landingPage?.code}`} onCopy={confirmCopy}>
                  <Text style={{ marginLeft: 5, color: Constants.colors.blue, cursor: 'pointer' }}>{copyText}</Text>
                </CopyToClipboard>
                <Button key="btn-feature-save" text="Save" onPress={onSave} />
              </View>
            </View>

          </View>
        </View>
      )
    } else {
      return null;
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/landing-pages'}>
      {renderBody()}
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: Constants.colors.backgroundLight,
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
  block: {
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 15,
    flexDirection: 'row',
    gap: 15
  },
  row: {
    flex: 1
  },
  logo: {
    width: 110,
    height: 110,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 55,
    overflow: "hidden"
  },
  profile: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%'
  }
});
