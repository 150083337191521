import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions, Dimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import WpModal from './WpModal';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import Input from './Input';
import { PitchContext, WpRedirectType } from './../contexts/pitch';
import { Button, Theme } from './Button';
import { InformationCircleIcon } from '@heroicons/react/solid'
import Collapsible from 'react-native-collapsible/Collapsible';
import { TrashIcon } from '@heroicons/react/outline';

const window = Dimensions.get('window');


export default function PitchTermintor({ startEditing=false, type, onClear, onSave } : { startEditing?:boolean, type:WpRedirectType, onClear:Function, onSave:Function }): JSX.Element {
  const { state, dispatch } = React.useContext(PitchContext);

  const [dimensions, setDimensions]= useState(window)

  const [hovering, setHovering] = useState<boolean>(false);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(startEditing);
  const [label, setLabel] = useState<string>(type === WpRedirectType.LINK ? 'Redirect to URL' : 'Prompt to Call/Text')
  const [redirectUrl, setRedirectUrl] = useState<string>('')
  const [redirectPhone, setRedirectPhone] = useState<string>('')
  const [redirectButton, setRedirectButton] = useState<string>('')

  useEffect(() => {
    setRedirectUrl(state.pitch?.redirectUrl)
    setRedirectPhone(state.pitch?.redirectPhone)
    setRedirectButton(state.pitch?.redirectButton)
  }, [state.pitch])

  useEffect(() => {
    checkDisabled();
  }, [redirectUrl, redirectPhone])

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const onClose = () => {
    setIsVisible(false);
  }

  const saveRedirect = () => {
    onClose();
    onSave(redirectUrl, redirectPhone, redirectButton);
  }

  const handleClear = () => {
    onClose();
    onClear();
  }

  const checkDisabled = () => {
    if (type === WpRedirectType.LINK) {
      setIsDisabled(!redirectUrl)
    } else {
      setIsDisabled(!redirectPhone)
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <WpModal isVisible={isVisible} closeModal={onClose}>
        <ModalBody title={`Edit ${label}`} closeModal={onClose} style={{ width: dimensions.width * 0.8, maxWidth: 500 }}>
          { type === WpRedirectType.LINK ?
            <Input
              key="redirect-url"
              name="URL"
              info={`When your leads click the last button in the pitch, we will redirect them to the url you enter here.`}
              placeholder="https://www.whistlepitch.com/"
              value={redirectUrl}
              setValue={setRedirectUrl}
            /> :
            <Input
              key="redirect-phone"
              name="Phone"
              info={`When your leads click the last button in the pitch, we will prompt them to call/text the number you enter here.`}
              value={redirectPhone}
              setValue={setRedirectPhone}
            />
          }
          <Input
            key="redirect-button-text"
            name="Button"
            info="The text of the final button. By default this is 'Continue' but you can change it to anything you'd like."
            value={redirectButton}
            setValue={setRedirectButton}
          />
          <ModalFooter>
            <Button key="btn-redirect-cancel" text="Cancel" onPress={onClose} theme={Theme.LINK} />
            <Button key="btn-redirect-save" text="Save" disabled={isDisabled} onPress={saveRedirect} />
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <View style={styles.buttonWrapper}>
        <TrashIcon style={{...cssStyles.icon, color: hovering ? Constants.colors.danger : Constants.colors.info}} onClick={handleClear} onMouseEnter={onHover} onMouseLeave={onHoverOut} />
        <Button width={200} text={label} onPress={() => setIsVisible(true)} theme={type === WpRedirectType.LINK ? Theme.ACCENT : Theme.PRIMARY} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  buttonWrapper: {
    width: 200,
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center'
  }
});

const cssStyles = {
  icon: {
    position: 'absolute',
    right: -30,
    top: 12,
    height: 15,
    width: 15,
    cursor: 'pointer'
  },
  iconImage: {
    paddingBottom: 5,
    height: 50,
    color: '#FFF'
  }
}
