import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import Constants from '../constants';
import { UserContext } from '../contexts/user';
import { WpLandingPage, WpLandingPageDropdownOption } from '../contexts/campaign';
import Spinner from '../components/Spinner';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import Input, { Label } from '../components/Input';
import Thumbnail from '../components/Shared/Thumbnail';
import { GET_LANDING_PAGE_DROPDOWN_OPTION, GET_LANDING_PAGE_DROPDOWN_OPTIONS } from '../graphql/queries';
import { ARCHIVE_LANDING_PAGES, CREATE_LANDING_PAGE, CREATE_LANDING_PAGE_DROPDOWN_OPTION, DELETE_LANDING_PAGE_DROPDOWN_OPTION, UPDATE_LANDING_PAGE_DROPDOWN_OPTION } from '../graphql/campaign';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button as WpButton, IconButton } from '@whistlepitch/wp-components'
import { numberWithCommas } from '../tools/tools'
import Checkbox from '../components/Checkbox';
import Icon from '../components/Icon';
import WpModal from '../components/WpModal';
import Toast from 'react-native-toast-message';
import NewButton from '../components/NewButton';
import { useNavigate } from '../react-router';
import BasicLayout from '../components/Shared/BasicLayout';
import LandingPageModal from '../components/Campaign/LandingPageModal';
import { Button, Theme } from '../components/Button';
import CampaignDropdown from '../components/CampaignDropdown';
import { set } from 'react-hook-form';


export default function LandingPages(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [landingPageDropdownOptions, setLandingPageDropdownOptions] = useState<WpLandingPageDropdownOption[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<string>();
  const [selectedOptionId, setSelectedOptionId] = useState<string>();
  const [selectingCampaign, setSelectingCampaign] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();

  const [getLandingPageDropdownOptions, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_LANDING_PAGE_DROPDOWN_OPTIONS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [getLandingPageDropdownOption, getOptionData] = useLazyQuery(GET_LANDING_PAGE_DROPDOWN_OPTION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [createLandingPageDropdownOption, createOptionData] = useMutation(CREATE_LANDING_PAGE_DROPDOWN_OPTION, {
    fetchPolicy: "no-cache"
  });

  const [updateLandingPageDropdownOption, updateOptionData] = useMutation(UPDATE_LANDING_PAGE_DROPDOWN_OPTION, {
    fetchPolicy: "no-cache"
  });

  const [deleteLandingPageDropdownOption, deleteOptionData] = useMutation(DELETE_LANDING_PAGE_DROPDOWN_OPTION, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (deleteOptionData.data) {
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: `The option has been deleted.`
      });
      loadOptions();
    }
  }, [deleteOptionData.data])

  useEffect(() => {
    loadOptions();
  }, [state.user])

  useEffect(() => {
    if (data?.landingPageDropdownOptions?.landingPageDropdownOptions) {
      setLandingPageDropdownOptions(data.landingPageDropdownOptions.landingPageDropdownOptions)
    }
  }, [data])

  useEffect(() => {
    if (createOptionData?.data?.createLandingPageDropdownOption) {
      if (createOptionData.data.createLandingPageDropdownOption.success) {
        setLandingPageDropdownOptions([
          createOptionData.data.createLandingPageDropdownOption.landingPageDropdownOption,
          ...landingPageDropdownOptions
        ])
      }
    }
  }, [createOptionData?.data])

  useEffect(() => {
    if (getOptionData?.data?.landingPageDropdownOption) {
      if (getOptionData.data.landingPageDropdownOption.success) {
        let newOption = getOptionData.data.landingPageDropdownOption.landingPageDropdownOption;
        let option = landingPageDropdownOptions.find((option) => option.id === newOption.id);
        if (option) {
          option.campaigns = newOption.campaigns;
          setLandingPageDropdownOptions([...landingPageDropdownOptions]);
        }
      }
    }
  }, [getOptionData?.data])

  useEffect(() => {
    if (updateOptionData?.data?.updateLandingPageDropdownOption) {
      if (updateOptionData.data.updateLandingPageDropdownOption.success) {
        let newOption = updateOptionData.data.updateLandingPageDropdownOption.landingPageDropdownOption;
        let option = landingPageDropdownOptions.find((option) => option.id === newOption.id);
        if (option) {
          option.campaigns = newOption.campaigns;
          setLandingPageDropdownOptions([...landingPageDropdownOptions]);
        }
      }
    }
  }, [updateOptionData?.data])

  const loadOptions = () => {
    if (state.user) {
      if (data) {
        refetch();
      } else {
        getLandingPageDropdownOptions();
      }
    }
  }

  const onCreateOption = () => {
    createLandingPageDropdownOption()
  }

  const onDeleteOption = () => {
    if (selectedOptionId) {
      deleteLandingPageDropdownOption({
        variables: {
          landingPageDropdownOptionIds: [selectedOptionId]
        }
      })
      setSelectedOptionId(undefined);
    }
  }

  const removeCampaign = (optionId: string, campaignId: string) => {
    if (optionId && campaignId) {
      updateLandingPageDropdownOption({
        variables: {
          landingPageDropdownOptionId: optionId,
          landingPageDropdownOption: {
            campaignsToRemove: [campaignId]
          }
        }
      })
    }
  }

  const updateOptionLabel = (optionId: string, val: string) => {
    if (optionId && val) {
      let option = landingPageDropdownOptions.find((option) => option.id === optionId);
      if (option) {
        option.label = val;
        setLandingPageDropdownOptions([...landingPageDropdownOptions]);
      }

      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }

      setSaveTimeout(setTimeout(() => {
        updateLandingPageDropdownOption({
          variables: {
            landingPageDropdownOptionId: optionId,
            landingPageDropdownOption: {
              label: val
            }
          }
        })
      }, 1500))
    }
  }

  const onAddCampaign = (optionId: string) => {
    setSelectingCampaign(true);
    setSelectedOptionId(optionId);
  }

  const confirmAddCampaign = () => {
    if (selectedOptionId) {
      updateLandingPageDropdownOption({ variables: {
        landingPageDropdownOptionId: selectedOptionId,
        landingPageDropdownOption: {
          campaignsToAdd: [campaignId],
        }
      }})
      setCampaignId(undefined);
      setSelectingCampaign(false);
      setSelectedOptionId(undefined);
    }
  }

  const renderBody = () => {
    if (loading) {
      return (
        <View>
          <Spinner />
        </View>
      )
    } else if (state.user) {
      return (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.heading}>{'Body Parts'}</Text>
            <NewButton name={'Body Part'} onClick={onCreateOption} loading={createOptionData?.loading} />
          </View>
          {
            landingPageDropdownOptions && landingPageDropdownOptions.length ? landingPageDropdownOptions.map((option, index) => {
              return (
                <View key={`option-${option.id}-${index}`} style={styles.optionWrapper}>
                  <Input style={{ marginTop: 10, width: '100%', position: 'relative', textAlign: 'left' }} isHeading={true} textAlign={"left"} value={option.label ?? 'New Label'} setValue={(val) => updateOptionLabel(option.id, val)} />
                  <Label style={{ marginTop: 20, marginBottom: 7 }} name={Constants.localizations.Campaigns} />
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 5 }}>
                    { option.campaigns?.length ? option.campaigns.map((campaign, index) => {
                        return <Button key={`campaign-button-${index}`} theme={Theme.DETAILS} style={{ marginBottom: 10, paddingRight: 20 }} width={'auto'} text={campaign.name} shadow={false} onDelete={() => removeCampaign(option.id, campaign.id)} /> 
                      }) : null
                    }
                  </View>
                  <Text onPress={() => onAddCampaign(option.id)} style={[styles.newBtn, { marginTop: 15 }]}>+ Add {Constants.localizations.campaign}</Text>
                </View>
              )
            }) : <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary }}>- No Body Parts Found -</Text>
          }
        </View>
      )
    } else {
      return (
        <View>
        </View>
      )
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/body-parts'}>
      { renderBody() }

      <WpModal isVisible={isVisible} closeModal={setVisible}>
        <ModalBody title={`Are You Sure?`} closeModal={setVisible}>
          <Text>Deleting a dropdown option will remove it from your account and all landing pages. You will no longer be able to see or manage this option.</Text>
          <ModalFooter>
            <WpButton disabled={deleteOptionData.loading} onClick={onDeleteOption} shape="Pill" size="Default" type={"Danger"}>
              { deleteOptionData.loading ? <Spinner /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>Delete</Text> }
            </WpButton>
          </ModalFooter>
        </ModalBody>
      </WpModal>

      <WpModal key="modal-add-campaign" isVisible={selectingCampaign}>
        <ModalBody title={"Add Campaign"} closeModal={setSelectingCampaign}>
          <View style={{ position: 'relative', zIndex: 10 }}>
            <CampaignDropdown optional={false} label={Constants.localizations.Campaign} info={`Select a ${Constants.localizations.campaign} to send out when the body part is selected on a landing page.`} value={campaignId} setValue={setCampaignId} />
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 25, position: 'relative', zIndex: 1 }}>
            <Button key="btn-campaign-add" text="Confirm" onPress={confirmAddCampaign} />
          </View>
        </ModalBody>
      </WpModal>
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap'
  },
  controlsWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: Constants.colors.lightBlue,
    alignItems: 'center',
    marginRight: 10
  },
  controls: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
  optionWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    backgroundColor: Constants.colors.lightBlue,
    marginBottom: 20
  }
})
