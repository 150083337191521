import { View, Text, ActivityIndicator, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import Constants from '../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import Input, { Label } from './../Input';
import Spinner from '../Spinner';
import StripeCardElement from '../Shared/StripeCardElement';
import { Card, UserContext } from '../../contexts/user';
import { CcCard } from './UserDetailsModal';
import { Button, Theme } from './../Button';


export default function BillingModal({ isVisible, onClose, loading, onComplete, card, title="Billing", newUser=false } : { isVisible:boolean, onClose:Function, loading?: boolean, onComplete: (token?: string, plan?: string) => void, card?: Card, stripeError?: string, title?: string, newUser?: boolean }): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [stripeLoading, setStripeLoading]= useState<boolean>(false);
  const [changingCard, setChangingCard]= useState<boolean>(false);
  const [stripeError, setStripeError]= useState<string>('');
  const [planSubtext, setPlanSubtext]= useState<string>('');
  const [activePlan, setActivePlan]= useState<'free'|'basic'|'pro'>('free');
  const [hoveringPlan, setHoveringPlan]= useState<'free'|'basic'|'pro'>();

  useEffect(() => {
    useFree();
  }, [])

  useEffect(() => {
    if (state.user?.organization.orgType) {
      if (state.user?.organization.orgType === 'free') {
        useFree()
      } else if (state.user?.organization.orgType === 'basic') {
        useBasic();
      } else if (state.user?.organization.orgType === 'pro') {
        usePro();
      }
    }
  }, [state.user?.organization.orgType])

  const onCompleteCard = (error: any, paymentMethod: any) => {
    if (error) {
      setStripeError(error)
    } else {
      onComplete(paymentMethod ? paymentMethod.id : undefined, Constants.blastView ? activePlan : undefined)
    }
  }

  const useFree = () => {
    if (Constants.oppText) {
      setActivePlan()
      setPlanSubtext()
    } else {
      setActivePlan('free')
      setPlanSubtext('Your only charge will be 3 cents per message')
    }
  }

  const useBasic = () => {
    setActivePlan('basic')
    setPlanSubtext('$25 plus 2 cents per message billed monthly (first charge today)')
  }

  const usePro = () => {
    setActivePlan('pro')
    setPlanSubtext('$49 plus 1 cents per message billed monthly (first charge today)')
  }

  const hoverFree = () => {
    if (activePlan !== 'free') {
      setHoveringPlan('free')
    }
  }

  const hoverBasic = () => {
    if (activePlan !== 'basic') {
      setHoveringPlan('basic')
    }
  }

  const hoverPro = () => {
    if (activePlan !== 'pro') {
      setHoveringPlan('pro')
    }
  }

  const renderPlans = () => {
    if (Constants.blastView && newUser) {
      return (
        <View style={[styles.plans, {marginBottom: 40}]}>
          <Text key="few-things" style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamBold' }}>A few things:</Text>
          <Text key="free-messages" style={{ color: Constants.colors.dark, fontFamily: 'Gotham' }}>• Your first 100 texts are free, then it's $0.01 per text.</Text>
          <Text key="blastview-contacts" style={{ color: Constants.colors.dark, fontFamily: 'Gotham' }}>• BlastView is free (no monthly payment) until you have 25 contacts. Once you cross 25 contacts, then it's $25/mo.</Text>
          <Text key="max-contacts" style={{ color: Constants.colors.dark, fontFamily: 'Gotham' }}>• Once you cross 250 contacts it's $49/mo and no matter how many contacts you add from there the price will remain $49/mo.</Text>
          <Text key="for-example" style={{ color: Constants.colors.secondary, fontFamily: 'Gotham', textAlign: 'center', marginTop: -4 }}>For example - 5,000,000 contacts is still only $49/mo</Text>
        </View>
      )
    }
    // if (Constants.blastView) {
    //   return (
    //     <View style={styles.plans}>
    //       <View style={activePlan === 'free' ? styles.activePlan : styles.plan} onClick={useFree} onMouseEnter={hoverFree} onMouseLeave={setHoveringPlan}>
    //         <View style={styles.icon}>
    //           { activePlan === 'free' ?
    //             <FontAwesome name="circle" size={24} color={Constants.colors.white} /> :
    //             <FontAwesome name="circle-o" size={24} color={Constants.colors.blue} /> }
    //         </View>
    //         <View style={styles.body}>
    //           <Text style={activePlan === 'free' ? styles.activePlanTitle : styles.planTitle}>Free</Text>
    //           <Text style={activePlan === 'free' ? styles.activePlanDescription : styles.planDescription}>15 contacts, 1 group</Text>
    //         </View>
    //         <View style={styles.billing}>
    //           <Text style={activePlan === 'free' ? styles.activePlanPricing : styles.planPricing}>$0 monthly</Text>
    //           <Text style={activePlan === 'free' ? styles.activePlanPricingDescription : styles.planPricingDescription}>3 cents per message</Text>
    //         </View>
    //       </View>
    //       <View style={activePlan === 'basic' ? styles.activePlan : styles.plan} onClick={useBasic} onMouseEnter={hoverBasic} onMouseLeave={setHoveringPlan}>
    //         <View style={styles.icon}>
    //           { activePlan === 'basic' ?
    //             <FontAwesome name="circle" size={24} color={Constants.colors.white} /> :
    //             <FontAwesome name="circle-o" size={24} color={Constants.colors.blue} /> }
    //         </View>
    //         <View style={styles.body}>
    //           <Text style={activePlan === 'basic' ? styles.activePlanTitle : styles.planTitle}>Basic</Text>
    //           <Text style={activePlan === 'basic' ? styles.activePlanDescription : styles.planDescription}>250 contacts, 4 group</Text>
    //         </View>
    //         <View style={styles.billing}>
    //           <Text style={activePlan === 'basic' ? styles.activePlanPricing : styles.planPricing}>$25 monthly</Text>
    //           <Text style={activePlan === 'basic' ? styles.activePlanPricingDescription : styles.planPricingDescription}>2 cents per message</Text>
    //         </View>
    //       </View>
    //       <View style={activePlan === 'pro' ? styles.activePlan : styles.plan} onClick={usePro} onMouseEnter={hoverPro} onMouseLeave={setHoveringPlan}>
    //         <View style={styles.icon}>
    //           { activePlan === 'pro' ?
    //             <FontAwesome name="circle" size={24} color={Constants.colors.white} /> :
    //             <FontAwesome name="circle-o" size={24} color={Constants.colors.blue} /> }
    //         </View>
    //         <View style={styles.body}>
    //           <Text style={activePlan === 'pro' ? styles.activePlanTitle : styles.planTitle}>Pro</Text>
    //           <Text style={activePlan === 'pro' ? styles.activePlanDescription : styles.planDescription}>2500 contacts, unlimited groups</Text>
    //         </View>
    //         <View style={styles.billing}>
    //           <Text style={activePlan === 'pro' ? styles.activePlanPricing : styles.planPricing}>$49 monthly</Text>
    //           <Text style={activePlan === 'pro' ? styles.activePlanPricingDescription : styles.planPricingDescription}>1 cents per message</Text>
    //         </View>
    //       </View>
    //     </View>
    //   )
    // }
    return null;
  }

  const onChangeCard = () => {
    setChangingCard(true)
  }

  const cancelChangeCard = () => {
    setChangingCard(false)
  }

  const renderCard = () => {
    if (card && !changingCard) {
      return (
        <View>
          <CcCard last4={card.last4} expMonth={card.expMonth} expYear={card.expYear} onClick={onChangeCard} />

          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 35 }}>
            <Button style={{ width: '100%', marginTop: 20 }} loading={loading} shadow={false} buttonStyles={{ backgroundColor: Constants.colors.darkBlue }} text="Confirm" width={'100%'} onPress={onCompleteCard} />
          </View>
        </View>
      )
    } else {
      return (
        <View>
          <Label name={"Payment Method"} />
          <StripeCardElement onComplete={onCompleteCard} stripeLoading={stripeLoading || loading} setStripeLoading={setStripeLoading}  />
          { stripeError ? <Text style={{ color: Constants.colors.red, fontFamily: 'Gotham', textAlign: 'center', margin: 'auto', marginTop: 15 }}>{ stripeError }</Text> : null }
          { Constants.blastView && newUser ? <Text style={{ color: Constants.colors.secondary, fontFamily: 'Gotham', textAlign: 'center', margin: 'auto', marginTop: 15 }}>You can get started without your credit card but we recommend you add it so you don't miss out when that 26th person wants to join one of your groups!</Text> : null }
          { card ? <Text style={{ cursor: 'pointer', paddingLeft: 7, paddingTop: 10, color: Constants.colors.blue, textDecorationLine: "underline" }} onPress={cancelChangeCard}>Use card ending in {card.last4}</Text> : null }
        </View>
      )
    }
  }

  return (
    <WpModal key="modal-card" isVisible={isVisible}>
      <ModalBody title={Constants.blastView && newUser ? "Welcome!" : title} closeModal={onClose}>
        { renderPlans() }
        { renderCard() }
      </ModalBody>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  plans: {
    gap: 20,
    marginBottom: 20
  },
  plan: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    borderRadius: 15,
    borderColor: Constants.colors.secondaryLight,
    borderWidth: 1,
    padding: 15,
    cursor: 'pointer'
  },
  activePlan: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    borderRadius: 15,
    borderColor: Constants.colors.lightBlue,
    borderWidth: 1,
    padding: 15,
    backgroundColor: Constants.colors.darkBlue,
    cursor: 'pointer'
  },
  icon: {},
  body: {},
  planTitle: {
    color: Constants.colors.blue,
    fontSize: 24,
    fontFamily: 'GothamMedium'
  },
  activePlanTitle: {
    color: Constants.colors.white,
    fontSize: 24,
    fontFamily: 'GothamMedium'
  },
  planDescription: {
    color: Constants.colors.secondary,
    fontSize: 14,
    fontFamily: 'Gotham'
  },
  activePlanDescription: {
    color: Constants.colors.light,
    fontSize: 14,
    fontFamily: 'Gotham'
  },
  billing: {
    marginLeft: 'auto'
  },
  planPricing: {
    color: Constants.colors.blue,
    fontSize: 22,
    fontFamily: 'Gotham'
  },
  activePlanPricing: {
    color: Constants.colors.white,
    fontSize: 22,
    fontFamily: 'Gotham'
  },
  planPricingDescription: {
    color: Constants.colors.secondary,
    fontSize: 14,
    fontFamily: 'Gotham'
  },
  activePlanPricingDescription: {
    color: Constants.colors.light,
    fontSize: 14,
    fontFamily: 'Gotham'
  },
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
