import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import { Button, Theme } from './Button';
import { ClockIcon, DotsHorizontalIcon, ArrowCircleUpIcon, ArrowCircleDownIcon } from '@heroicons/react/solid'
import { SketchPicker } from 'react-color';
import { Duration, DateTime } from "luxon";

export enum Hoverable {
  BUTTON="BUTTON",
  UP_DAYS="UP_DAYS",
  UP_HOURS="UP_HOURS",
  UP_MINUTES="UP_MINUTES",
  DOWN_DAYS="DOWN_DAYS",
  DOWN_HOURS="DOWN_HOURS",
  DOWN_MINUTES="DOWN_MINUTES",
}

export default function CampaignFeatureDelay({ style={}, days, hours, minutes, delayUntil, onChange, onClick } : { style?:object, days?:number|null, hours?:number|null, minutes?:number|null, delayUntil?:string|null, onChange:Function, onClick?: () => void }): JSX.Element {
  const [hovering, setHovering] = useState<Hoverable|null>(null);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [useCalendar, setUseCalendar] = useState<boolean>(false);
  const [localDays, setDays] = useState<number|null>(days ? days : 0)
  const [localHours, setHours] = useState<number|null>(hours ? hours : 0)
  const [localMinutes, setMinutes] = useState<number|null>(minutes ? minutes : 0)

  useEffect(() => {
    setDays(days ? days : 0)
    setHours(hours ? hours : 0)
    setMinutes(minutes ? minutes : 0)
  }, [days, hours, minutes])

  const handleOnChange = () => {
    onChange({ days: localDays, hours: localHours, minutes: localMinutes, delayUntil });
    onHidePicker();
  }

  const onHover = (what:Hoverable) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(null);
  }

  const onShowPicker = () => {
    if (onClick === undefined) {
      setShowPicker(true);
    } else {
      onClick();
    }
  }

  const onHidePicker = () => {
    setShowPicker(false);
  }

  const onUseCalendar = () => {
    setUseCalendar(true);
  }

  const dontUseCalendar = () => {
    setUseCalendar(false);
  }

  const clearDelay = () => {
    onChange({ days: 0, hours: 0, minutes: 0, delayUntil });
    onHidePicker();
    setDays(0);
    setHours(0);
    setMinutes(0);
  }

  const handleOnAdjust = (what:Hoverable) => {
    switch (what) {
      case Hoverable.UP_DAYS:
        setDays(localDays ? localDays + 1 : 1);
        return
      case Hoverable.UP_HOURS:
        setHours(localHours ? localHours + 1 : 1);
        return
      case Hoverable.UP_MINUTES:
        setMinutes(localMinutes ? localMinutes + 1 : 1);
        return
      case Hoverable.DOWN_DAYS:
        setDays(localDays ? Math.max(localDays - 1, 0) : 0);
        return
      case Hoverable.DOWN_HOURS:
        setHours(localHours ? Math.max(localHours - 1, 0) : 0);
        return
      case Hoverable.DOWN_MINUTES:
        setMinutes(localMinutes ? Math.max(localMinutes - 1, 0) : 0);
        return
    }
  }

  const renderPressable = () => {
    if (localDays || localHours || localMinutes || delayUntil) {
      let label = '';
      if (delayUntil) {

      } else {
        let labels = []
        if (localDays) {
          labels.push(`${localDays}d`)
        }
        if (localHours) {
          labels.push(`${localHours}h`)
        }
        if (localMinutes) {
          labels.push(`${localMinutes}m`)
        }
        label = labels.join(', ')
      }
      return (
        <View style={{ position: 'relative', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
          <Button width={'auto'} text={label} theme={Theme.LINK_WARNING} onPress={onShowPicker} />
          <ClockIcon key="clock" style={cssStyles.icon} />
        </View>
      )
    }
    return (
      <View style={{ position: 'relative', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', cursor: 'pointer' }} onClick={onShowPicker}>
        <Button width={10} text={'+'} theme={Theme.LINK} onPress={onShowPicker} disabled={true} />
        <ClockIcon key="clock" style={{...cssStyles.icon, color: Constants.colors.info}} />
      </View>
    );
  }

  const renderPicker = () => {
    if (useCalendar) {
      return (
        <View>
          <Text>Choose from Calendar</Text>
          <Button width={'100%'} text={'Use Duration'} theme={Theme.LINK} small={true} onPress={dontUseCalendar} />
        </View>
      )
    } else {
      return (
        <View>
          <View style={styles.individualPickers}>
            <View style={styles.individualPicker}>
              <IncrementUp what={Hoverable.UP_DAYS} onAdjust={handleOnAdjust} />
              <TextInput
                disabled={true}
                style={styles.pickerText}
                placeholder={'0'}
                onChangeText={(val) => {
                  setDays(parseInt(val))
                }}
                value={ localDays === null ? undefined : localDays }
              />
              <IncrementDown what={Hoverable.DOWN_DAYS} onAdjust={handleOnAdjust} />
            </View>
            <View style={styles.individualPicker}>
              <IncrementUp what={Hoverable.UP_HOURS} onAdjust={handleOnAdjust} />
              <TextInput
                disabled={true}
                style={styles.pickerText}
                placeholder={'0'}
                onChangeText={(val) => {
                  setHours(parseInt(val))
                }}
                value={ localHours === null ? undefined : localHours }
              />
              <IncrementDown what={Hoverable.DOWN_HOURS} onAdjust={handleOnAdjust} />
            </View>
            <View style={styles.individualPicker}>
              <IncrementUp what={Hoverable.UP_MINUTES} onAdjust={handleOnAdjust} />
              <TextInput
                disabled={true}
                style={styles.pickerText}
                placeholder={'0'}
                onChangeText={(val) => {
                  setMinutes(parseInt(val))
                }}
                value={ localMinutes === null ? undefined : localMinutes }
              />
              <IncrementDown what={Hoverable.DOWN_MINUTES} onAdjust={handleOnAdjust} />
            </View>
          </View>
          <Button style={{ marginVertical: 15 }} width={'100%'} text={'Clear Delay'} theme={Theme.LINK} small={true} onPress={clearDelay} />
        </View>
      )
    }
  }

  const renderPickerWrapper = () => {
    if (showPicker) {
      return (
        <View style={styles.pickerWrapper}>
          { renderPicker() }
          <View style={styles.pickerFooter}>
            <Button width={'auto'} text={'Cancel'} theme={Theme.LINK} small={true} onPress={onHidePicker} />
            <Button width={75} text={'Save'} theme={Theme.PRIMARY} small={true} onPress={handleOnChange} />
          </View>
        </View>
      )
    }
    return null
  }

  const IncrementUp = ({ what, onAdjust } : { what:Hoverable, onAdjust:Function }): JSX.Element => {
    return <ArrowCircleUpIcon style={{...cssStyles.durationIcon, opacity: hovering === what ? 0.6 : 1 }} onClick={() => onAdjust(what)} onMouseEnter={() => onHover(what)} onMouseLeave={onHoverOut} />
  }

  const IncrementDown = ({ what, onAdjust } : { what:Hoverable, onAdjust:Function }): JSX.Element => {
    return <ArrowCircleDownIcon style={{...cssStyles.durationIcon, opacity: hovering === what ? 0.6 : 1 }} onClick={() => onAdjust(what)} onMouseEnter={() => onHover(what)} onMouseLeave={onHoverOut} />
  }

  return (
    <View style={[{ zIndex: 2 }, style]}>
      { renderPressable() }
      { renderPickerWrapper() }
    </View>
  );
}

const styles = StyleSheet.create({
  tester: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: Constants.colors.accent
  },
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  pickerWrapper: {
    position: 'absolute',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF',
    bottom: '60%',
    left: -40,
  },
  pickerFooter: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center'
  },
  individualPickers: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  individualPicker: {
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pickerText: {
    padding: 5,
    borderRadius: 5,
    fontSize: 18,
    width: 30,
    textAlign: 'center',
    border: '1px solid ' + Constants.colors.info + '44'
  }
});

const cssStyles = {
  icon: {
    width: 20,
    height: 20,
    minWidth: 20,
    minHeight: 20,
    color: Constants.colors.warning
  },
  dotsIcon: {
    width: 16,
    height: 16,
    minWidth: 16,
    minHeight: 16,
    color: Constants.colors.info,
    marginTop: 12,
    marginRight: 10
  },
  durationIcon: {
    width: 20,
    height: 20,
    marginTop: 3,
    marginBottom: 3,
    color: Constants.colors.primary
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
