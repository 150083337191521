import { View, StyleSheet, Text, FlatList, Image } from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import Constants from './../../constants';
import { Feather, Entypo } from '@expo/vector-icons';
import { Audio } from 'expo-av';
import { Slider } from '@miblanchard/react-native-slider';
import Spinner from '../Spinner';

export default function AudioPlayer({ url, artwork, color=Constants.colors.darkBlue, details, onProgress, onReady } : { url?: string, artwork?: string, color?: string, details?: string, onProgress?: (playedSeconds: number) => void, onReady?: (duration: number) => void }): JSX.Element {
  const sound = useRef(new Audio.Sound());
  const [duration, setDuration] = useState<number>(0);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const [prevPosition, setPrevPosition] = useState<number>(0);
  const [totalListen, setTotalListen] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  
  // useEffect(() => {
  //   return sound
  //     ? () => {
  //         console.log('Unloading Sound');
  //         sound.current.unloadAsync();
  //       }
  //     : undefined;
  // }, [sound]);
  
  useEffect(() => {
    console.log('Current Position', currentPosition, prevPosition, totalListen)
    if (currentPosition && playing) {
      if (currentPosition > prevPosition) {
        setTotalListen(totalListen + (currentPosition - prevPosition));
      }
      setPrevPosition(currentPosition);
    }
  }, [currentPosition]);
  
  useEffect(() => {
    if (!url) {
      pauseAudio();
    }
  }, [url]);
  
  useEffect(() => {
    console.log('Total Listen', totalListen)
    if (onProgress) {
      onProgress(totalListen / 1000);
    }
  }, [totalListen]);

  const updateStatus = async (data) => {
    try {
      console.log('Update Status', data)
      if (data.didJustFinish) {
        setPlaying(false);
        if (data.durationMillis) {
          setPrevPosition(data.durationMillis);
          setCurrentPosition(data.durationMillis); 
        }
      } else if (data.positionMillis && data.durationMillis) {
        if (onReady) {
          onReady(data.durationMillis / 1000)
        }
        setDuration(data.durationMillis);
        setCurrentPosition(data.positionMillis);
      }
    } catch (error) {
      console.log('Update Status Error', error);
    }
  };

  const loadAudio = async () => {
    setLoading(true);
    const checkLoading = await sound.current.getStatusAsync();
    if (checkLoading.isLoaded === false) {
      try {
        const result = await sound.current.loadAsync({ uri: url });
        if (result.isLoaded === false) {
          setLoading(false);
          setPlaying(false);
          setError('Could not load audio file');
        } else {
          sound.current.setOnPlaybackStatusUpdate(updateStatus);
          setLoading(false);
          playAudio();
        }
      } catch (error) {
        setLoading(false);
        setError('Could not load audio file');
      }
    } else {
      setLoading(false);
      playAudio();
    }
  };

  const playAudio = async () => {
    setLoading(true);
    try {
      const result = await sound.current.getStatusAsync();
      if (result.isLoaded) {
        if (result.isPlaying === false) {
          await sound.current.playAsync();
        }
        setLoading(false);
        setPlaying(true);
      } else {
        loadAudio();
      }
    } catch (error) {
      setLoading(false);
      setPlaying(false);
    }
  };

  const pauseAudio = async () => {
    try {
      const result = await sound.current.getStatusAsync();
      if (result.isLoaded) {
        if (result.isPlaying === true) {
          sound.current.pauseAsync();
        }
        setPlaying(false);
      }
      setPlaying(false);
    } catch (error) {
      setLoading(false);
      setPlaying(false);
    }
  }

  const seekAudio = async (data) => {
    try {
      const checkLoading = await sound.current.getStatusAsync();
      if (checkLoading.isLoaded === true && duration) {
        // const result = (data / 100) * duration;
        setTotalListen(totalListen + (currentPosition - prevPosition));
        setCurrentPosition(Math.round(data));
        setPrevPosition(Math.round(data));
        await sound.current.setPositionAsync(Math.round(data));
      }
    } catch (error) {
      console.log('Seek Error', error);
    }
  };

  const skipBack = () => {
    if (currentPosition) {
      seekAudio(currentPosition - 10000 > 0 ? currentPosition - 10000 : 0);
    }
  }

  const skipForward = () => {
    if (currentPosition && duration) {
      seekAudio(currentPosition + 10000 < duration ? currentPosition + 10000 : duration);
    }
  }

  const formatDuration = (durationMillis: number | undefined) => {
    if (durationMillis) {
      const minutes = Math.floor(durationMillis / 60000); // Calculate the number of minutes
      const seconds = Math.floor((durationMillis % 60000) / 1000); // Calculate the number of seconds
    
      // Add leading zero if seconds is less than 10
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    
      return `${minutes}:${formattedSeconds}`;
    }
    return '0:00';
  };

  const renderControler = () => {
    if (loading) {
      return <Spinner />
    } else if (playing) {
      return <Feather name="pause" size={44} color={color} onPress={pauseAudio} />
    } else {
      return <Feather name="play" size={44} color={color} onPress={playAudio} />
    }
  }

  const renderControls = () => {
    if (url) {
      return (
        <View>
          <View style={{ flexDirection: 'row', gap: 15, justifyContent: 'center', marginTop: 15 }}>
            <Entypo name="forward" size={44} color={color} onClick={skipBack} style={{ cursor: 'pointer', transform: 'scaleX(-1)' }} />
            { renderControler() }
            <Entypo name="forward" size={44} color={color} onClick={skipForward} style={{ cursor: 'pointer' }} />
          </View>
          <Slider
            containerStyle={{ cursor: 'pointer' }}
            step={1}
            value={currentPosition || 0}
            onValueChange={seekAudio}
            minimumValue={0}
            maximumValue={duration || 0}
            minimumTrackTintColor={`${color}AA`}
            thumbTintColor={color}
          />
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ color: Constants.colors.info }}>{formatDuration(currentPosition)}</Text>
            <Text style={{ color: Constants.colors.info }}>{currentPosition && duration ? `-${formatDuration(duration - currentPosition)}` : '0:00'}</Text>
          </View>
        </View>
      )
    }
    return null;
  }
  
  return (
    <View style={{ padding: 15 }}>
      { artwork ? 
        <Image style={{ aspectRatio: 1, borderRadius: 15, height: 200, maxHeight: 200, margin: 'auto' }} source={{ uri: artwork }} /> : null }
      { renderControls()}
      { details ? 
        <View style={{ marginTop: 10 }}>
          <Text style={{ textAlign: 'center', color: Constants.colors.info }}>{details}</Text>
        </View> : null }
    </View>
  )
}

const styles = StyleSheet.create({
})
