import React, { useState, useEffect, useCallback } from 'react';
import constants from '../constants';
import { GET_USERS } from '../graphql/queries';
import axios, { AxiosResponse } from 'axios';
import { UserContext, WpOrganization, WpUser } from '../contexts/user';
import { DocumentNode, print } from 'graphql';
import BasicLayout from '../components/Shared/BasicLayout';
import Spinner from '../components/Spinner';
import { Button, PercentageCard, SearchInput, StatsCard } from '@whistlepitch/wp-components';
import Heading from '../components/Shared/Heading';
import { View, Text } from 'react-native';
import WpModal from '../components/WpModal';
import ModalBody from '../components/ModalBody';
import Input, { Label } from '../components/Input';
import Toast from 'react-native-toast-message';
import { Ionicons } from '@expo/vector-icons';
import StripeCardElement from '../components/Shared/StripeCardElement';
import * as tools from './../tools/tools';
import { AntDesign } from '@expo/vector-icons';
import Hoverable from '../components/Shared/Hoverable';
import Icon from '../components/Icon';


interface ApiTokens {
  [platform: string]: string;
}

interface SendStats {
  total: number,
  pending: number,
  sent: number,
  paused: number,
  finishes?: string,
  campaignId: string,
  campaignName: string
}

interface GraphQLResponse<T = any> {
  data?: T;
  errors?: Array<{ message: string;[key: string]: any }>;
}

const makeGraphQLRequest = async <T = any>(
  query: string | DocumentNode,
  variables: Record<string, any>,
  apiUrl: string,
  headers: Record<string, string>
): Promise<GraphQLResponse<T> | null> => {
  try {
    const queryString = typeof query === 'string' ? query : print(query);
    const response: AxiosResponse<GraphQLResponse<T>> = await axios.post(
      apiUrl,
      {
        query: queryString,
        variables,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('GraphQL request failed:', error);
    return null;
  }
};

const BRANDS = ['WhistlePitch', 'BlastView', 'OppText', 'TattooCare'];

export const OrganizationOverview = ({ orgId, data, setData, token, baseUrl }: { orgId: string, data: { organization: WpOrganization, stats: SendStats[] }, setData: (orgId: string, data: { organization: WpOrganization, stats: SendStats[] }) => void, token?: string, baseUrl?: string }) => {
  const { state, dispatch } = React.useContext(UserContext)

  const [loading, setLoading] = useState<string>('');
  const [isSubscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [monthlyRate, setMonthlyRate] = useState(250);
  const [smsRate, setSmsRate] = useState(0.03);
  const [stripeError, setStripeError] = useState<string>('');
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [isCardModalVisible, setCardModalVisible] = useState<boolean>(false);
  const [localData, setLocalData] = useState<{ organization: WpOrganization, stats: SendStats[] }>(data);

  useEffect(() => {
    // Fetch API Tokens
    const fetchData = async () => {
      setLoading('data')
      try {
        const response = await axios.post(`${baseUrl}/super/organization-data/${token}`, {
          organization_id: orgId,
        });
        setLocalData(response.data);
        setData(orgId, response.data);
      } catch (error) {
        console.error('Failed to fetch API tokens:', error);
      }
      setLoading('')
    };

    if (state.user && token && !data && baseUrl && orgId) {
      fetchData();
    }
  }, [state.user, data, baseUrl, orgId, token]);

  const handleOpenSubscriptionModal = () => {
    setMonthlyRate(250);
    setSmsRate(0.03);

    localData?.organization?.billing?.products?.forEach((product) => {
      if (product.name.toLowerCase().indexOf('access') > -1) {
        setMonthlyRate(product.price);
      } else if (product.name.toLowerCase().indexOf('sms') > -1) {
        setSmsRate(product.price);
      }
    });

    setSubscriptionModalVisible(true);
  };

  const handleOpenCardModal = () => {
    setCardModalVisible(true);
  };

  const handleSubmit = async () => {
    try {
      setLoading('subscription')
      const response = await axios.post(`${baseUrl}/super/change-subscription/${token}`, {
        organization_id: localData?.organization?.id,
        monthly_rate: monthlyRate,
        sms_rate: smsRate,
      });

      if (response.data.success) {
        localData?.organization?.billing?.products?.forEach((product) => {
          if (product.name.toLowerCase().indexOf('access') > -1) {
            product.price = monthlyRate;
          } else if (product.name.toLowerCase().indexOf('sms') > -1) {
            product.price = smsRate;
          }
        });

        Toast.show({
          type: 'success',
          text1: 'Subscription Updated',
          text2: `The subscription for ${localData?.organization.name} has been updated.`
        });
        setData(orgId, localData);
        setLocalData(localData);
      }

      setSubscriptionModalVisible(false);
    } catch (error) {
      console.error('Failed to update subscription:', error);
    }
    setLoading('')
  };

  const onCompleteCard = async (error, paymentMethod) => {
    if (error) {
      setStripeError(error)
    } else {
      try {
        setLoading('card')
        const response = await axios.post(`${baseUrl}/super/change-card/${token}`, {
          organization_id: localData?.organization.id,
          token: paymentMethod ? paymentMethod.id : undefined,
        });

        if (response.data.success) {
          if (localData?.organization.billing) {
            const localChange = {
              ...localData,
              organization: {
                ...localData.organization,
                billing: {
                  ...localData.organization.billing,
                  card: response.data.card
                }
              }
            }
            setLocalData(localChange);
            setData(orgId, localChange);
          }

          Toast.show({
            type: 'success',
            text1: 'Card Updated',
            text2: `The card on file for ${localData?.organization.name} has been updated.`
          });
        }

        setCardModalVisible(false);
      } catch (error) {
        console.error('Failed to update subscription:', error);
      }
      setLoading('')
    }
  }

  const pauseSend = async (campaignId: string) => {
  }

  const resumeSend = async (campaignId: string) => {
  }

  const cancelSend = async (campaignId: string) => {
  }

  const renderBody = useCallback(() => {
    if (loading !== 'data' && localData) {
      return (
        <>
          {/* Add more organization details here */}
          <View style={{ flexDirection: 'row', marginBottom: 10 }}>
            <div style={{ flexDirection: 'column', display: 'flex', width: 300 }}>
              <Heading color={constants.colors.darkBlue} text={localData?.organization.name} />

              {localData?.organization?.billing?.card ?
                <span style={{ color: constants.colors.secondary, fontSize: 12 }}>{localData?.organization.billing.card.brand.toUpperCase()} ending in {localData?.organization.billing.card.last4} (expires: {localData?.organization.billing.card.expMonth}/{localData?.organization.billing.card.expYear})</span> :
                <span>No card on file</span>}
            </div>
            {localData?.organization?.billing?.products ? (
              localData?.organization.billing.products.map((product) => (
                <div key={product.id} style={{ flexDirection: 'column', display: 'flex', marginLeft: 15 }}>
                  <Heading text={`$${product.price}`} />

                  {product.name.toLowerCase().indexOf('sms') > -1 ?
                    <span style={{ color: constants.colors.secondary, fontSize: 12 }}>Per Text Message</span> :
                    <span style={{ color: constants.colors.secondary, fontSize: 12 }}>Monthly Acess</span>}
                </div>
              ))
            ) : null}
            {localData?.organization?.billing?.card ?
              <Button
                type="Primary"
                size="Default"
                shape="Pill"
                onClick={handleOpenSubscriptionModal}
                style={{ marginRight: '10px', marginLeft: 'auto' }}
              >
                Change Subscription
              </Button> : null}
            <Button
              type="Primary"
              size="Default"
              shape="Pill"
              style={{ marginRight: '10px', marginLeft: localData?.organization?.billing?.card ? 15 : 'auto' }}
              onClick={handleOpenCardModal}
            >
              Change Card
            </Button>
          </View>

          {/* Add Overall Org Stats Section */}
          <View style={{ flexDirection: 'row', gap: 5, marginVertical: 15, flexWrap: 'wrap' }}>
            <View style={{ flexGrow: 2, flexBasis: 'auto', minWidth: 250, flexDirection: 'column', justifyContent: 'flex-start', gap: 5 }}>
              <StatsCard
                style={{ flex: 1 }}
                description={'' + (localData?.organization?.stats.messages)}
                title={"Messages Sent"}
                type="White"
              >
                <Icon style={{ padding: 15 }} name="message" color={constants.colors.darkBlue} raw={true} />
              </StatsCard>
              <StatsCard
                style={{ flex: 1 }}
                description={'' + localData?.organization?.stats.leads}
                title={"Leads"}
                type="White"
              >
                <Icon style={{ padding: 12 }} name="users" color={constants.colors.darkBlue} raw={true} />
              </StatsCard>
              <StatsCard
                style={{ flex: 1 }}
                description={'' + localData?.organization?.users.length}
                title={"Users"}
                type="White"
              >
                <Icon style={{ padding: 12 }} name="users" color={constants.colors.darkBlue} raw={true} />
              </StatsCard>
            </View>
            <PercentageCard key="pc-delivery" style={{ flexGrow: 1, flexBasis: 'auto', minWidth: 250 }} title={"Delivery Rate"} percentage={localData?.organization?.stats?.deliveryRate ? parseFloat((localData?.organization.stats.deliveryRate * 100).toFixed(1)) : 0} />
            <PercentageCard key="pc-open" style={{ flexGrow: 1, flexBasis: 'auto', minWidth: 250 }} title={"Open Rate"} percentage={localData?.organization?.stats?.openRate ? parseFloat((localData?.organization.stats.openRate * 100).toFixed(1)) : 0} />
          </View>

          {/* Add Stats Section */}
          {localData?.stats && localData?.stats.length ?
            <div style={{ marginTop: '30px' }}>
              {localData?.stats.map((stat, index) => (
                <div key={index}
                  style={{
                    backgroundColor: constants.colors.light,
                    marginBottom: '10px',
                    border: '1px solid ' + constants.colors.light,
                    borderRadius: '8px',
                    padding: '8px',
                    boxShadow: '0px 2px 3px rgba(0,0,0,0.1)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <View style={{ padding: '10px' }}>
                    <h4 style={{ fontWeight: 'bold', fontSize: 16, color: constants.colors.primary }}>{stat.campaignName}</h4>
                    {stat.total ? <small style={{ color: constants.colors.secondary }}>{stat.total} pending messages</small> : null}
                  </View>
                  <View><span style={{ fontSize: 14, color: constants.colors.dark, marginLeft: 20 }}>{stat.sent}/{stat.total} messages sent</span></View>
                  {/* {stat.paused ? <Hoverable onPress={() => resumeSend(stat.campaignId)} style={{ marginLeft: 'auto' }}><AntDesign name="play" size={28} color={constants.colors.primary} /></Hoverable> : <Hoverable onPress={() => pauseSend(stat.campaignId)} style={{ marginLeft: 'auto' }}><AntDesign name="pausecircle" size={28} color={constants.colors.primary} /></Hoverable>} */}
                  {/* <Hoverable onPress={() => cancelSend(stat.campaignId)}><AntDesign style={{ marginLeft: 16, marginRight: 8 }} name="closecircle" size={28} color={constants.colors.red} /></Hoverable> */}
                </div>
              ))}
            </div> : null}
        </>
      )
    } else if (loading === 'data') {
      return <Spinner />
    } else {
      return <div>Could not load organization.</div>
    }
  }, [loading, localData, orgId])
  console.log('data', localData?.organization?.name, localData, localData?.stats)

  return (
    <div
      key={`org-${orgId}`}
      style={{
        backgroundColor: '#fff',
        marginBottom: '40px',
        border: '1px solid ' + constants.colors.light,
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0px 4px 6px rgba(0,0,0,0.1)'
      }}
    >
      {renderBody()}

      <WpModal key="modal-subscription" isVisible={isSubscriptionModalVisible}>
        <ModalBody title={`Change Subscription for ${localData?.organization?.name}`} closeModal={() => setSubscriptionModalVisible(false)}>
          <Input key={`access-rate`} style={{ flex: 1, marginBottom: 10 }} name={"Access Rate"} value={monthlyRate} placeholder="Enter Monthly Access Rate..." setValue={setMonthlyRate} />
          <Input key={`sms-rate`} style={{ flex: 1 }} name={"SMS Rate"} value={smsRate} placeholder="Enter SMS Rate..." setValue={setSmsRate} />
          <Button style={{ marginTop: 30, marginBottom: 5 }} type="Primary" size="Default" shape="Pill" onClick={handleSubmit}>
            {loading === 'subscription' ? <Spinner /> : 'Submit'}
          </Button>
        </ModalBody>
      </WpModal>
      <WpModal key="modal-card" isVisible={isCardModalVisible}>
        <ModalBody title={`Change Card for ${localData?.organization?.name}`} closeModal={() => setCardModalVisible(false)}>
          <StripeCardElement onComplete={onCompleteCard} stripeLoading={stripeLoading || loading === 'card'} setStripeLoading={setStripeLoading} />
          {stripeError ? <div style={{ color: constants.colors.red }}>{stripeError}</div> : null}
        </ModalBody>
      </WpModal>
    </div>
  )
};

export default function SuperAdmin() {
  const { state, dispatch } = React.useContext(UserContext)

  const [brand, setBrand] = useState<string>(constants.brand);
  // const [apiTokens, setApiTokens] = useState<ApiTokens | null>(null);
  const [orgIds, setOrgIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<string>('');
  const [hovering, setHovering] = useState<"prev" | "next">();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [organizations, setOrganizations] = useState<{ [id: string]: { organization: WpOrganization, stats: SendStats[] } }>({});
  const [search, setSearch] = useState<string>();
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  const perPage = 5;

  const variables = {
    orgAdmins: true,
    allUsers: true
  }

  useEffect(() => {
    // Fetch API Tokens
    const fetchApiTokens = async () => {
      setLoading('tokens')
      try {
        const response = await axios.post(`${constants.base_url}/super/tokens/${state.user?.token}`, { search });
        // setApiTokens(response.data.tokens);
        setOrgIds(response.data.organizations);
      } catch (error) {
        console.error('Failed to fetch API tokens:', error);
      }
      setLoading('')
    };

    if (state.user) {
      fetchApiTokens();
    }
  }, [state.user, search]);

  const storeOrganization = (orgId: string, data: { organization: WpOrganization, stats: SendStats[] }) => {
    setOrganizations((orgs) => {
      orgs[orgId] = data;
      return orgs;
    });
  }

  const onHoverPrev = () => {
    setHovering("prev")
  }

  const onHoverNext = () => {
    setHovering("next")
  }

  const onHoverOut = () => {
    setHovering(undefined)
  }

  const onPrev = () => {
    setCurrentPage((page) => Math.max(0, page - 1))
  }

  const onNext = () => {
    setCurrentPage((page) => Math.min(Math.ceil(orgIds.length / perPage), page + 1))
  }

  const clearSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    setSearch(undefined)
  }

  const onSearch = (e: any) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    let newSearch = e.target.value;
    setSearchTimeout(setTimeout(() => {
      setSearch(newSearch)
      setCurrentPage(0)
    }, 500))
  }

  return (
    <BasicLayout style={{ flex: 1 }} active={'/super-admin'}>
      <div>
        <div style={{ display: 'flex', marginTop: 10, marginBottom: 20, gap: 5 }}>
          <Heading text={`Super Admin`} />
          {/* <Button
            type={brand === 'WhistlePitch' ? 'Primary' : 'Neutral'}
            shape="Pill"
            style={{ marginRight: 5 }}
            onClick={() => setBrand('WhistlePitch')}
          >
            WhistlePitch
          </Button>
          <Button
            type={brand === 'BlastView' ? 'Primary' : 'Neutral'}
            shape="Pill"
            style={{ marginRight: 5 }}
            onClick={() => setBrand('BlastView')}
          >
            BlastView
          </Button>
          <Button
            type={brand === 'OppText' ? 'Primary' : 'Neutral'}
            shape="Pill"
            style={{ marginRight: 5 }}
            onClick={() => setBrand('OppText')}
          >
            OppText
          </Button>
          <Button
            type={brand === 'TattooCare' ? 'Primary' : 'Neutral'}
            shape="Pill"
            style={{ marginRight: 5 }}
            onClick={() => setBrand('TattooCare')}
          >
            TattooCare
          </Button> */}
          <SearchInput style={{ marginLeft: 'auto', maxWidth: 350, width: '100%' }} onChange={onSearch} onCleanData={clearSearch} shape="Pill" />
        </div>
        {orgIds.length && !loading ? orgIds.slice(currentPage * perPage, (currentPage + 1) * perPage).map((orgId) => (
          <OrganizationOverview
            key={orgId}
            orgId={orgId}
            data={organizations[orgId]}
            setData={storeOrganization}
            token={state.user?.token}
            baseUrl={constants.base_urls[constants.brand]}
          />
        )) : null}

        {loading === 'data' || loading === 'tokens' || orgIds?.length < 6 ? null :
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Ionicons
              style={{ cursor: 'pointer' }}
              name={hovering === "prev" ? "chevron-back-circle" : "chevron-back-circle-outline"}
              size={24}
              color={constants.colors.primary}
              onPress={onPrev}
              onMouseEnter={onHoverPrev}
              onMouseLeave={onHoverOut}
            />
            <Text style={{ padding: 10 }}>{currentPage + 1} / {Math.ceil(orgIds.length / perPage)}</Text>
            <Ionicons
              style={{ cursor: 'pointer' }}
              name={hovering === "next" ? "chevron-forward-circle" : "chevron-forward-circle-outline"}
              size={24}
              color={constants.colors.primary}
              onPress={onNext}
              onMouseEnter={onHoverNext}
              onMouseLeave={onHoverOut}
            />
          </View>
        }
        {loading === 'data' || loading === 'tokens' ? <Spinner /> : null}
      </div>
    </BasicLayout>
  );
}