import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import Dropdown, { Item } from './../Dropdown';
import { Button, Theme } from './../Button';
import { Label, Info } from './../Input';
import NewLeadModal from './NewLeadModal';
import { GET_LEADS } from './../../graphql/queries';
import { useLazyQuery } from "@apollo/client";

export default function LeadDropdown({ value, setValue, showCreateNew=false, onCreated, campaignId, groupId, onClose=() => {}, style={} } : { value:string|string[]|undefined, setValue: (id: string|string[]) => void, showCreateNew?: boolean, onClose: () => void, onCreated?: (ids: string[]) => void, campaignId?: string, groupId?: string, style?:object }): JSX.Element {
  const [leadItems, setLeadItems] = useState<Item[]>([]);
  const [search, setSearch] = useState<string>();
  const [showNewLead, setShowNewLead] = useState<boolean>(false);

  const [getLeads, { data, networkStatus, refetch }] = useLazyQuery(GET_LEADS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (data?.leads?.leads) {
      let leads = []
      for (let i = 0; i < data.leads.leads.length; i++) {
        let c = data.leads.leads[i];
        leads.push({value: c.id, label: c.name})
      }
      setLeadItems(leads)
    }
  }, [data, networkStatus])

  useEffect(() => {
    loadLeads();
  }, [search])

  const loadLeads = () => {
    if (data) {
      refetch({ search: search, page: 1 });
    } else {
      getLeads({ variables: { search: search, page: 1 }})
    }
  }

  const onCloseNewLead = () => {
    setShowNewLead(false);
  }

  const onCreateLead = () => {
    setShowNewLead(true);
  }

  const handleOnCreated = (ids: string[]) => {
    if (onCreated) {
      onCreated(ids)
    }
    onCloseNewLead();
  }

  const onSetValue = (id: string) => {
    setValue(id)
    onCloseNewLead();
  }

  const updateSearch = (s: string) => {
    setSearch(s.toLowerCase());
  }

  return (
    <View style={[{ position: 'relative', zIndex: 2 }, style]}>
      <NewLeadModal campaignId={campaignId} groupId={groupId} onCreated={handleOnCreated} isVisible={showNewLead} onClose={onCloseNewLead} />
      <Dropdown
        key={`dropdown-leads`}
        style={{ position: 'relative', zIndex: 1 }}
        placeholder={`Select existing ${Constants.localizations.lead}(s)`}
        items={leadItems}
        value={value}
        onChange={onSetValue}
        onOpen={loadLeads}
        onClose={onClose}
        multiple={true}
        onChangeSearchText={updateSearch}
        ListEmptyComponent={() => (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <Text style={{ color: Constants.colors.info, textAlign: 'center' }}>Lead Not Found.</Text>
              <Button text={`Create New ${Constants.localizations.lead}`} theme={Theme.LINK_PRIMARY_MEDIUM} width={140} onPress={onCreateLead} />
          </View>
        )}
      />
      { showCreateNew ? <Button text={`Create New ${Constants.localizations.lead}`} width={'100%'} theme={Theme.LINK_PRIMARY_MEDIUM} onPress={onCreateLead} /> : null}
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
