import { Text, View, TextInput, StyleSheet } from 'react-native';
import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import DropDownPicker from 'react-native-dropdown-picker';
import Constants from './../constants';
import Spinner from './../components/Spinner';

export interface Item {
  label: string,
  value: string,
  containerStyle?: object,
  labelStyle?: {
    color: string
  },
  custom?: boolean
}

function useOutsideAlerter(ref, closeDropdown) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeDropdown();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function Dropdown({ placeholder, items, value, onChange, onClose, searchPlaceholder = "Search...", selectedText = "{count} item(s)", loading = false, style = {}, multiple = false, small = false, plain = false, ListEmptyComponent, onChangeSearchText, onOpen, addCustomItem = false }: { placeholder: string, items: Item[], value: string | string[] | undefined, onChange: (value: string | string[] | null) => void, selectedText?: string, style?: object, multiple?: boolean, small?: boolean, plain?: boolean, loading?: boolean, ListEmptyComponent?: JSX.Element, onClose?: () => void, searchPlaceholder?: string, onChangeSearchText?: (text: string) => void, onOpen?: () => void, addCustomItem?: boolean }): JSX.Element {
  const [open, setOpen] = useState(false);
  const [dropdownValue, setValue] = useState<string | string[] | null>(value === undefined ? null : value);
  const [customItemWas, setCustomItemWas] = useState<Item>()
  const [searchText, setSearchText] = useState('');
  const [dropdownItems, setItems] = useState<Item[]>(items);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeDropdown = () => {
    setOpen(false);
  }

  const handleOnOpen = () => {
    if (onOpen !== undefined) {
      onOpen();
    }
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, closeDropdown);

  useEffect(() => {
    setValue(value === undefined ? null : value)
  }, [value])

  useEffect(() => {
    let new_items: Item[] = [];

    let changed = false;
    for (let i = 0; i < dropdownItems.length; i++) {
      let temp = dropdownItems[i];
      if (temp.custom !== undefined) {
        setCustomItemWas({ ...temp })

        changed = true;
        if (!temp.containerStyle) {
          temp.containerStyle = {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 12,
            paddingRight: 12,
            flexDirection: 'row'
          };
        }
        if (!temp.labelStyle) {
          temp.labelStyle = { color: Constants.colors.dark };
        }
        delete temp.custom;

        temp.value = temp.label;
      }

      new_items.push(temp);
    }

    if (changed) {
      setItems(new_items)
    }
  }, [dropdownItems])

  useEffect(() => {
    let new_items: Item[] = [];

    for (let i = 0; i < items.length; i++) {
      let temp = items[i];
      if (!temp.containerStyle) {
        temp.containerStyle = {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 12,
          paddingRight: 12,
          flexDirection: 'row'
        };
      }
      if (!temp.labelStyle) {
        temp.labelStyle = { color: Constants.colors.dark };
      }

      new_items.push(temp);
    }

    setItems(new_items)
  }, [items])

  useEffect(() => {
    if (customItemWas && customItemWas.value === dropdownValue) {
      let newVal = customItemWas.label;
      setCustomItemWas(undefined);

      setValue(newVal);
    } else {
      onChange(dropdownValue);
    }
  }, [dropdownValue, customItemWas])


  const handleOnChangeSearchText = (text: string) => {
    setSearchText(text);
    if (onChangeSearchText) {
      onChangeSearchText(text)
    };
  };

  const getListEmptyComponent = () => {
    if (loading) {
      return (
        <View style={{ flex: 1 }}>
          <Spinner />
        </View>
      )
    } else if (ListEmptyComponent) {
      return ListEmptyComponent
    } else {
      return (
        <View style={{ flex: 1 }}>
          <Text style={{ flex: 1, color: Constants.colors.info, textAlign: 'center' }}>- Nothing Found -</Text>
        </View>
      )
    }
  }

  return (
    <View ref={wrapperRef} style={[style, isOpen ? { zIndex: 100 } : {}]}>
      <DropDownPicker
        style={small ? styles.dropdownSmall : (plain ? styles.dropdownPlain : styles.dropdown)}
        textStyle={{ fontFamily: 'Gotham' }}
        labelStyle={{ fontFamily: 'Gotham', backgroundColor: 'white' }}
        dropDownContainerStyle={styles.dropdownContainer}
        searchable={true}
        open={open}
        multiple={multiple}
        value={dropdownValue}
        items={dropdownItems}
        placeholderStyle={styles.placeholderStyle}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        searchContainerStyle={styles.searchContainer}
        searchTextInputStyle={styles.searchTextInput}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        onOpen={handleOnOpen}
        onClose={onClose}
        addCustomItem={addCustomItem}
        onChangeSearchText={handleOnChangeSearchText}
        badgeColors={[Constants.colors.primary]}
        badgeStyle={{ padding: 5, backgroundColor: Constants.colors.primary, borderRadius: 20 }}
        badgeTextStyle={{ color: '#FFF', fontSize: 12, paddingHorizontal: 5 }}
        mode={multiple ? "SIMPLE" : "SIMPLE"}
        ListEmptyComponent={() => getListEmptyComponent()}
        translation={{
          SELECTED_ITEMS_COUNT_TEXT: selectedText
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  dropdownContainer: {
    border: '1px solid ' + Constants.colors.secondaryLight,
    borderColor: Constants.colors.secondaryLight,
    padding: 10,
    backgroundColor: 'white'
  },
  searchContainer: {
    padding: 5,
    marginBottom: 10,
    borderBottomColor: Constants.colors.secondaryLight,
  },
  searchTextInput: {
    border: 0,
    margin: 0,
    padding: 0
  },
  dropdown: {
    border: '1px solid ' + Constants.colors.secondaryLight,
    borderColor: Constants.colors.secondaryLight,
    backgroundColor: 'white',
    cursor: 'pointer',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    paddingLeft: 15,
    borderRadius: 10,
    flexDirection: 'row'
  },
  dropdownSmall: {
    cursor: 'pointer',
    paddingTop: 9,
    paddingBottom: 9,
    paddingRight: 10,
    paddingLeft: 10,
    flexDirection: 'row',
    border: '1px solid ' + Constants.colors.info + '44',
    borderColor: Constants.colors.secondaryLight,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  dropdownPlain: {
    cursor: 'pointer',
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 10,
    paddingLeft: 10,
    flexDirection: 'row',
    borderWidth: 0,
    borderRadius: 10,
    backgroundColor: '#FFF',
    minHeight: 44
  },
  placeholderStyle: {
  }
});
