import { View, Text, StyleSheet, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import Toast from 'react-native-toast-message';

import Constants from '../constants';
import { UserContext } from '../contexts/user';
import CampaignOverview from '../components/CampaignOverview';
import { Button } from '../components/Button';
import WpModal from '../components/WpModal';
import ModalBody from '../components/ModalBody';
import ConfirmPhoneModal from '../components/User/ConfirmPhoneModal';
import LeadTable from '../components/Leads/LeadTable';
import Navbar from '../components/Navbar';
import { FORGOT_PASSWORD_REQUEST } from '../graphql/user';
import { useNavigate, useParams, useSearchParams } from '../react-router';
import { RequestPasswordView, RequestPasswordViewProps } from '@whistlepitch/wp-components';
import { useQuery, useMutation } from "@apollo/client";

interface RegisterParams {
  token: string,
  name: string
  email: string,
  phone: string,
  password: string,
  password2: string,
  organizationName: string
}

export default function ForgotPasswordRequest(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [userEmail, setEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [token, setToken] = useState<string>();
  const [variables, setVariables] = useState<RegisterParams>();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const [forgotPassword, { data, loading }] = useMutation(FORGOT_PASSWORD_REQUEST, {
    fetchPolicy: "no-cache"
  })

  const navigate = useNavigate();

  if (state.user) {
    navigate('/dashboard')
  }

  const onForgotPassword = ({ email } : RequestPasswordViewProps) => {
    forgotPassword({ variables: { email: email, brand: Constants.brand }})
    setEmail(email);
    setIsVisible(true);
  }

  const toLogin = () => {
    navigate('/login')
  }

  const renderAlternateLogos = () => {
    if (Constants.tattooCare) {
      return (
        <View style={{ position: 'absolute', top: 20, left: 40, padding: 60, zIndex: 100, backgroundColor: 'white' }}>
          <Image style={{ width: 175, height: 40 }} source={require('./../assets/tattoocare_heading_inline.png')} />
        </View>
      )
    }
    return null
  }

  return (
    <View style={{ flex: 1 }}>
      <WpModal isVisible={isVisible}>
        <ModalBody title="Request Sent">
          <Text>An email has been sent to { userEmail } with a special link for resetting your password. You should receive the email within the next few minutes.</Text>
          <View style={styles.footer}>
            <Button text="Done" onPress={setIsVisible} />
          </View>
        </ModalBody>
      </WpModal>
      <RequestPasswordView brand={Constants.brand} onRequestPassword={onForgotPassword} onBackToLogin={toLogin} />
      { renderAlternateLogos() }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20
  }
})
