import { View, Text, ActivityIndicator, StyleSheet, Dimensions, LayoutChangeEvent } from 'react-native';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate } from '../../react-router';

import { formatDateString } from './../../tools/tools';
import Constants from '../../constants';
import Spinner from './../Spinner';
import Input, { Label } from './../Input';
import UserDropdown from './../UserDropdown';
import MessageFeed from './../Shared/MessageFeed';
import { GET_LEAD, GET_USERS } from '../../graphql/queries';
import { WpLead, WpLeadActivity } from '../../contexts/lead';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import FullscreenModal from './../Shared/FullscreenModal';
import Heading from './../Shared/Heading';
import { SimpleLineIcons, Ionicons, Entypo, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { BarChart, XAxis, YAxis, Bar, Line, Legend, Tooltip, CartesianGrid } from "recharts";
import { Access, UserContext, WpUser, Subscription, CustomValue, WpUserAction } from '../../contexts/user';
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import Dropdown, { Item } from '../Dropdown';
import ModalFooter from '../ModalFooter';
import { Button } from '@whistlepitch/wp-components';
import { NEW_PASSWORD, SWITCH_ORGANIZATION, UPDATE_USER } from '../../graphql/user';
import Toast from 'react-native-toast-message';
import Icon from '../Icon';


const permissionItems: Item[] = [
  {
    label: "Notifications Only",
    value: Access.LIMITED,
  },
  {
    label: "Can View Account",
    value: Access.READONLY,
  },
  {
    label: "Can Make Changes",
    value: Access.MANAGER,
  },
  {
    label: "Admin",
    value: Access.OWNER,
  },
]

export default function SuperAdminModal({ isVisible, onClose } : { isVisible:boolean, onClose: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);

  const [users, setUsers]= useState<WpUser[]>([])
  const [hovering, setHovering]= useState<boolean>(false)
  const [switching, setSwitching]= useState<boolean>(false)

  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_USERS, {
    fetchPolicy: "no-cache",
    variables: { orgAdmins: true }
  });

  const [switchOrganization, switchOrganizationData] = useMutation(SWITCH_ORGANIZATION, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (switchOrganizationData.data && switchOrganizationData.data?.switchOrganization) {
      dispatch({ type: WpUserAction.REFRESH_USER, user: switchOrganizationData.data?.switchOrganization?.user})
      onClose();
    }
  }, [switchOrganizationData.data])

  useEffect(() => {
    if (state.user && switchOrganizationData.data) {
      setSwitching(false)
    }
  }, [state.user?.id])

  useEffect(() => {
    if (data?.users) {
      setUsers(data.users.users)
    }
  }, [data])

  const onHover = () => {
    setHovering(true)
  }

  const onHoverOut = () => {
    setHovering(false)
  }

  const handleOnClose = () => {
    onClose()
  }

  const openUser = (organizationId: string) => {
    setSwitching(true)
    switchOrganization({
      variables: {
        organizationId: organizationId
      }
    })
  }

  const leaveSuperAdmin = () => {
    openUser("1")
  }

  const renderUser = (user: WpUser, index: number) => {
    if (user.organization?.id) {
      return (
        <View key={`user-${user.id}`} style={styles.row} onClick={() => openUser(user.organization.id)}>
          <View>
            <Text key="user-name" style={{ fontFamily: 'GothamMedium', color: Constants.colors.blue }}>{user.name}</Text>
            <Text key="user-name2" style={{ fontFamily: 'Gotham', color: Constants.colors.secondary, fontSize: 10 }}>{user.organization.name}</Text>
          </View>
          <Text key="user-phone" style={{ fontFamily: 'Gotham' }}>{user.phone}</Text>
          <Text key="user-email" style={{ fontFamily: 'Gotham' }}>{user.email}</Text>
        </View>
      )
    }
    return null;
  }

  const renderUsers = () => {
    return (
      <View style={{ flex: 1, position: 'relative', gap: 10 }}>
        { users.map(renderUser) }
      </View>
    )
  }

  return (
    <View>
      <WpModal key="modal-details" isVisible={isVisible}>
        <ModalBody title={state.user?.wpAdmin ? "All Users" : ""} closeModal={onClose}>
          { state.user?.wpAdmin && users.length ? renderUsers() : <View key="users" style={{ flex: 1, padding: 15 }}><Spinner /></View> }
          <ModalFooter style={{ position: 'relative', justifyContent: 'space-between', zIndex: 1 }}>
            { state.user?.organization?.id === "1" ? null : <Text key="link-leave" style={{ cursor: 'pointer', flex: 1, color: Constants.colors.blue, fontFamily: 'Gotham' }} onPress={leaveSuperAdmin}>Leave Super Admin</Text> }
            <Button style={{ flex: 1 }} type="Primary" size="Default" shape="Pill" onClick={handleOnClose}>
              <Text style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'GothamMedium' }}>Done</Text>
            </Button>
            { state.user?.organization?.id === "1" ? null : <Text key="link-balance" style={{ flex: 1 }}></Text> }
          </ModalFooter>
        </ModalBody>
      </WpModal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    marginVertical: 25
  },
  btnLink: {
    paddingTop: 5,
    color: Constants.colors.blue,
    textDecorationLine: "underline",
    fontFamily: "Gotham",
    cursor: "pointer"
  },
  error: {
    width: '100%',
    textAlign: 'center',
    color: Constants.colors.red,
    fontFamily: 'GothamMedium'
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1
  },
  card: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 15,
    backgroundColor: Constants.colors.lightBlue,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 15
  },
  cardRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 10,
    paddingHorizontal: 10,
    marginBottom: 10
  },
  cardIcon: {
    borderRadius: 20,
    width: 40,
    height: 40,
    backgroundColor: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 15
  },
  cardBody: {
    fontFamily: 'GothamMedium',
    fontSize: 14,
    color: Constants.colors.dark
  },
  cardBodyWrapper: {
  },
  cardSubtext: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.info
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
    marginVertical: 15
  },
  row: {
    borderRadius: 5,
    flexDirection: 'row',
    gap: 15,
    justifyContent: 'space-between',
    backgroundColor: '#Fafafd',
    padding: 8,
    cursor: 'pointer'
  }
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
