import { Text, LayoutChangeEvent, View, StyleSheet, ActivityIndicator, Dimensions, TextInput, Image } from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import { PitchContext, PitchSlideContext, WpPitchSlide, WpPitchSlideAction, WpSlideType, WpWidgetType } from './../../contexts/pitch';
import { GET_PITCH_SLIDE } from './../../graphql/queries';
import { UPDATE_PITCH_SLIDE, CREATE_SLIDE_WIDGET, DELETE_PITCH_SLIDE } from './../../graphql/pitch';
import Constants from './../../constants';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import WpModal from './../WpModal';
import { Button, Theme } from './../Button';
import Uppyup from './../Uppyup';
import SlideWidget from './SlideWidget';
import CustomFieldDropdown from './../CustomFieldDropdown';
import { NetworkStatus, useLazyQuery, useMutation } from "@apollo/client";
// import { Input } from "@whistlepitch/wp-components";
// import Collapsible from 'react-native-collapsible/Collapsible';
import ReactPlayer from 'react-player';
import PitchSlideOptions from '../PitchSlideOptions';
import Spinner from '../Spinner';
import Input, { Label } from '../Input';
// import ContentEditor from '../Shared/ContentEditor';
import TextEditor from '../Shared/TextEditor';
import AudioPlayer from '../Shared/AudioPlayer';

const window = Dimensions.get('window');


export default function PitchSlideEditor({ slideId, isNew, onSave, onClose }: { slideId?: string, isNew?: boolean, onSave: () => void, onClose?: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(PitchSlideContext);

  const [viewWidth, setViewWidth] = useState<number>(0);

  const [getSlide, getSlideData] = useLazyQuery(GET_PITCH_SLIDE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })
  const [updateSlide, updateSlideData] = useMutation(UPDATE_PITCH_SLIDE, {
    fetchPolicy: "no-cache"
  })
  const [updateCalendars, updateCalendarsData] = useMutation(UPDATE_PITCH_SLIDE, {
    fetchPolicy: "no-cache"
  })
  const [createWidget, createWidgetData] = useMutation(CREATE_SLIDE_WIDGET, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  const [deleteSlide, deleteSlideData] = useMutation(DELETE_PITCH_SLIDE, {
    fetchPolicy: "no-cache"
  })

  const [dimensions, setDimensions] = useState(window)
  const [title, setTitle] = useState<string>()
  const [slideType, setSlideType] = useState<WpSlideType | null>()
  const [description, setDescription] = useState<string>()
  const [contentId, setContentId] = useState<string>()
  const [content, setContent] = useState<string>()
  const [internalTitle, setInternalTitle] = useState<string>()
  const [url, setUrl] = useState<string>()
  const [audioFile, setAudioFile] = useState<string>()
  const [thumbnail, setThumbnail] = useState<string>()
  const [mimeType, setMimeType] = useState<string>()

  const [plugins, setPlugins] = useState<string[]>([])
  const [customField, setCustomField] = useState<string | string[]>('')
  const [newVideo, setNewVideo] = useState<boolean>(false)
  const [newAudio, setNewAudio] = useState<boolean>(false)
  const [aspectRatio, setAspectRatio] = useState(0)

  useEffect(() => {
    if (!url || slideType === WpSlideType.CALENDAR || slideType === WpSlideType.YOUTUBE || state?.slide?.isVideo) {
      return;
    }

    let isValid = true;
    Image.getSize(url, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [url, slideType]);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  const loadSlide = () => {
    if (getSlideData.data && state.slide) {
      getSlideData.refetch({ pitchSlideId: state.slide.id });
    } else {
      getSlide({ variables: { pitchSlideId: slideId } })
    }
  }

  useEffect(() => {
    if (slideId) {
      loadSlide();
    }
  }, [slideId]);

  useEffect(() => {
    if (getSlideData.data?.pitchSlide?.slide && slideId && getSlideData.networkStatus === NetworkStatus.ready) {
      dispatch({ type: WpPitchSlideAction.REFRESH_PITCH_SLIDE, slide: getSlideData.data?.pitchSlide?.slide })
    }
  }, [getSlideData.data?.pitchSlide?.slide, getSlideData.networkStatus])

  useEffect(() => {
    if (createWidgetData.data?.createSlideWidget?.success) {
      dispatch({ type: WpPitchSlideAction.SET_EDITING_WIDGET, editing: createWidgetData.data.createSlideWidget.widget.id, structure: createWidgetData.data.createSlideWidget.structure })
      loadSlide();
    }
  }, [createWidgetData.data])

  useEffect(() => {
    if (state.slide) {
      if (!title) {
        setTitle(state.slide.title);
      }
      if (!internalTitle) {
        setInternalTitle(state.slide.internalTitle);
      }
      if (!description) {
        setDescription(state.slide.description);
      }

      if (contentId !== state.slide.id) {
        setContent(state.slide.content)
        setContentId(state.slide.id)
      }
      if (!url) {
        setUrl(state.slide.url)
      }
      if (!audioFile) {
        setAudioFile(state.slide.audioFile)
      }

      if (!slideType && !isNew) {
        setSlideType(state.slide.slideType)
      }
      if (!mimeType) {
        setMimeType(state.slide.mimeType)
      }

      updatePlugins();
    } else if (slideType) {
      onSave();
      setSlideType(undefined);
    }
  }, [state.slide])

  useEffect(() => {
    updatePlugins();
  }, [slideType])

  useEffect(() => {
    if (updateSlideData.data?.updatePitchSlide?.success) {
      onCloseModal(true);
    }
  }, [updateSlideData.data?.updatePitchSlide])

  useEffect(() => {
    if (updateCalendarsData.data?.updatePitchSlide) {
      loadSlide();
    }
  }, [updateCalendarsData.data?.updatePitchSlide])

  useEffect(() => {
    if (deleteSlideData.data) {
      onCloseModal(true);
    }
  }, [deleteSlideData.data])

  const updatePlugins = () => {
    if (slideType === WpSlideType.SCREEN) {
      setPlugins(['ScreenCapture'])
    } else if (slideType === WpSlideType.RECORD) {
      setPlugins(['Webcam'])
    } else {
      setPlugins([])
    }
  }

  const saveSlide = () => {
    if (state.slide) {
      updateSlide({
        variables: {
          pitchSlideId: state.slide.id,
          title: title,
          internalTitle: internalTitle,
          slideType: slideType,
          description: description,
          content: content,
          url: url,
          audioFile: audioFile,
          thumbnail: thumbnail,
          mimeType: mimeType
        }
      })
    }
  }

  const onCloseModal = (handled?: boolean) => {
    if (!handled && isNew && !!state.slide) {
      deleteSlide({ variables: { pitchSlideId: state.slide.id } })
    } else {
      dispatch({ type: WpPitchSlideAction.CLEAR_PITCH_SLIDE });

      setTitle(undefined);
      setSlideType(undefined);
      setInternalTitle(undefined);
      setDescription(undefined);
      setUrl(undefined);
      setAudioFile(undefined);
      setThumbnail(undefined);
      setMimeType(undefined);
      setPlugins([]);
      setCustomField('');
      setNewVideo(false);
      setAspectRatio(0);

      if (onClose !== undefined) {
        onClose();
      }
      let tooltip = document.getElementById('tippy-16');
      if (tooltip) {
        tooltip.remove();
      }
    }
  }

  const onUploadComplete = (u: string, mime: string) => {
    if (mime.includes('image')) {
      setThumbnail(u);
    }
    setUrl(u);
    setMimeType(mime);
  }

  const onUploadAudioComplete = (u: string, mime: string) => {
    setAudioFile(u);
    setMimeType(mime);
  }

  const onSetUrl = (val: string) => {
    if (slideType === WpSlideType.YOUTUBE) {
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      let match = val.match(regExp);
      let vid = (match && match[7].length == 11) ? match[7] : false;
      setThumbnail(`https://img.youtube.com/vi/${vid}/sddefault.jpg`)
    }
    setUrl(val)
  }

  const onCreateWidget = () => {
    if (state.slide) {
      let s = state.slide.widgetStructure;
      s.push({
        id: 'new',
        label: '',
        options: [],
        type: WpWidgetType.YESNO
      })
      dispatch({ type: WpPitchSlideAction.SET_EDITING_WIDGET, editing: 'new', structure: s })
      // createWidget({ variables: {pitchSlideId: state.slide.id}})
    }
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onNewVideo = () => {
    setNewVideo(true);
  }

  const onNewAudio = () => {
    setNewAudio(true);
  }

  const onSelectCalendar = (id: string | string[]) => {
    if (state.slide && ((Array.isArray(id) && id.length > 0) || (!Array.isArray(id) && !!id))) {
      updateCalendars({
        variables: {
          pitchSlideId: state.slide.id,
          slideType: slideType,
          calendars: Array.isArray(id) ? id : [id],
          title: title,
          internalTitle: internalTitle,
          description: description,
          content: content,
          url: url,
          thumbnail: thumbnail,
          mimeType: mimeType
        }
      })
    }
  }

  const removeCalendar = (id: string) => {
    if (state.slide && !!id) {
      updateCalendars({
        variables: {
          pitchSlideId: state.slide.id,
          slideType: slideType,
          calendarsRemove: [id],
          title: title,
          internalTitle: internalTitle,
          description: description,
          content: content,
          url: url,
          thumbnail: thumbnail,
          mimeType: mimeType
        }
      })
    }
  }

  const changeType = (newType?: WpSlideType | null) => {
    setSlideType(newType)
  }

  const changeTitle = (e: any) => {
    setTitle(e.target.value)
  }

  const changeUrl = (e: any) => {
    setUrl(e.target.value)
  }

  const renderWidgets = () => {
    return (
      <View style={{ width: '100%' }}>
        <Text style={{ fontSize: 14, fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, marginVertical: 15 }}>Widgets</Text>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }}>
          {!state?.slide?.widgetStructure || !state.slide.widgetStructure.length ? null :
            state.slide.widgetStructure.map((widget, index, list) => {
              return (
                <SlideWidget key={`slide-widget-${widget.id}-option`} widgetId={widget.id} widgetType={widget.type} label={widget.label} isEditing={state.editing === widget.id} />
              );
            })
          }
        </View>
        <Text onPress={onCreateWidget} style={styles.newBtn}>+ Add widgets</Text>
      </View>
    )
  }

  const renderVideo = () => {
    if (mimeType?.includes('video')) {
      return <ReactPlayer
        style={cssStyles.player}
        url={url}
        controls={true}
        pip={false}
        width='100%'
        height={WpSlideType.YOUTUBE ? viewWidth * 9 / 16 : 'auto'}
      />
    } else {
      return (
        <View style={{ width: '100%', borderRadius: 10, backgroundColor: Constants.colors.info, justifyContent: 'center', alignItems: 'center', paddingVertical: 25 }}>
          <Text style={{ color: Constants.colors.lightBlue, fontFamily: 'GothamMedium' }}>Video Is Processing</Text>
          <Spinner />
          <Text style={{ color: Constants.colors.lightBlue, fontFamily: 'GothamMedium', fontSize: 10 }}>Processing will begin when you click save</Text>
        </View>
      )
    }
  }

  const renderUrl = () => {
    if (mimeType?.includes('video') && state.slide?.isVideo && state.slide?.processed) {
      return <ReactPlayer
        style={cssStyles.player}
        url={url}
        controls={true}
        pip={false}
        width='100%'
        height={WpSlideType.YOUTUBE ? viewWidth * 9 / 16 : 'auto'}
      />
    } else if (mimeType?.includes('video')) {
      return (
        <View style={{ width: '100%', borderRadius: 10, backgroundColor: Constants.colors.lightBlue, justifyContent: 'center', alignItems: 'center', paddingTop: 25, paddingBottom: 45 }}>
          <Text style={{ color: Constants.colors.blue, fontFamily: 'GothamMedium', marginTop: 15 }}>Video Is Processing</Text>
          <Spinner style={{ marginVertical: 25 }} />
          {state.slide?.isVideo ? null : <Text style={{ color: Constants.colors.info, fontFamily: 'GothamMedium', fontSize: 10 }}>Processing will begin when you click save</Text>}
        </View>
      )
    } else if (url) {
      return <Image style={{ aspectRatio, borderRadius: 15, height: 200, maxHeight: 200, margin: 'auto' }} source={{ uri: url }} />
    }
  }

  const renderAudio = () => {
    if (audioFile) {
      return <AudioPlayer url={audioFile} details={`Your ${Constants.localizations.leads} will be limited to 3 listens of your audio file.`} />
    }
  }

  const renderSettingsBody = () => {
    if (state.slide && slideType && [WpSlideType.UPLOAD, WpSlideType.SCREEN, WpSlideType.RECORD].indexOf(slideType) > -1) {
      if (url && !newVideo) {
        return (
          <View style={{ marginBottom: 25 }} onLayout={onLayout}>
            {renderUrl()}
            <Button style={{ marginTop: 25 }} width={'100%'} small={true} text={`Upload New ${mimeType?.includes('video') || state.slide.isVideo ? 'Video' : 'Image'}`} theme={Theme.LINK_PRIMARY} onPress={onNewVideo} />
          </View>
        )
      } else {
        return <Uppyup plugins={plugins} onDone={onCloseModal} onUploadComplete={onUploadComplete} />
      }
    } else if (state.slide && slideType && [WpSlideType.AUDIO].indexOf(slideType) > -1) {
      let coverImg: JSX.Element | null = null;
      if (url && !newVideo) {
        coverImg = (
          <View style={{ marginBottom: 25 }} onLayout={onLayout}>
            <View style={{ width: '100%', justifyContent: 'center' }}>
              {renderUrl()}
            </View>
            <Button style={{ marginTop: 25 }} width={'100%'} small={true} text={`Upload New Artwork`} theme={Theme.LINK_PRIMARY} onPress={onNewVideo} />
          </View>
        )
      } else {
        coverImg = (
          <View style={{ marginBottom: 25 }} onLayout={onLayout}>
            <Label name={"Artwork"} />
            <Uppyup height={200} plugins={plugins} onDone={onCloseModal} onUploadComplete={onUploadComplete} />
          </View>
        )
      }
      let audio: JSX.Element | null = null;
      if (audioFile && !newAudio) {
        audio = (
          <View style={{ marginBottom: 25 }} onLayout={onLayout}>
            {renderAudio()}
            <Button style={{ marginTop: 25 }} width={'100%'} small={true} text={`Upload New Audio`} theme={Theme.LINK_PRIMARY} onPress={onNewAudio} />
          </View>
        )
      } else {
        audio = (
          <View style={{ marginBottom: 25 }} onLayout={onLayout}>
            <Label name={"Audio File"} />
            <Uppyup audioOnly={true} height={200} plugins={plugins} onDone={onCloseModal} onUploadComplete={onUploadAudioComplete} />
          </View>
        )
      }
      return (
        <View style={{ marginBottom: 25 }} onLayout={onLayout}>
          {coverImg}
          {audio}
        </View>
      )
    } else if (slideType === WpSlideType.YOUTUBE) {
      return <Input name="YouTube Link" placeholder="Enter Link..." value={url} setValue={setUrl} />
    } else if (slideType === WpSlideType.TEXT && state.slide) {
      // return <ContentEditor key={`text-editor-${state.slide.id}`} initialContent={content} onChange={setContent} />
      return <TextEditor key={`text-editor-${state.slide.id}`} initialContent={content} onChange={setContent} />
    } else if (state.slide && slideType === WpSlideType.CALENDAR) {
      return (
        <View style={{ position: 'relative', zIndex: 5 }}>
          {state.slide.calendars.map((value, index) => {
            return <Button style={{ marginBottom: 10 }} width={'100%'} text={`${value.modelName} - ${value.name}`} subText={value.value} shadow={false} onDelete={() => removeCalendar(value.id)} />
          })}
          <CustomFieldDropdown value={customField} setValue={onSelectCalendar} placeholder="Choose Calendly" calendarOnly={true} calendlyOnly={false} />
        </View>
      )
    }
    return null
  }

  // if (!state.slide) {
  //   return (
  //     <ModalBody title="Slide Settings" closeModal={onCloseModal} style={{ width: dimensions.width * 0.8, maxWidth: 500 }} >
  //       <View style={{ flex: 1 }}>
  //         <Text style={{ textAlign: 'center', padding: 15, color: Constants.colors.secondary }}>Create or select a slide to start editing</Text>
  //       </View>
  //     </ModalBody>
  //   )
  // }

  return (
    <WpModal isVisible={!!state.slide} closeModal={onCloseModal}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ModalBody title={`Edit ${title ? title : 'New Slide'}`} closeModal={onCloseModal} style={{ width: dimensions.width * 0.8, maxWidth: 500, zIndex: 5 }} >
          <PitchSlideOptions active={slideType} onPress={changeType} />
          {slideType !== undefined ?
            <Input style={{ marginBottom: 20, position: 'relative', zIndex: 1 }} name="Title" optional={slideType !== WpSlideType.TEXT ? "Visible to leads" : undefined} placeholder="Enter Optional Slide Title..." value={title} setValue={setTitle} /> : null}
          {renderSettingsBody()}

          {slideType !== undefined && !Constants.blastView ? renderWidgets() : null}
          <ModalFooter>
            <Button key="btn-feature-save" text="Save" loading={updateSlideData.loading} onPress={saveSlide} />
          </ModalFooter>
        </ModalBody>
      </View>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  pitch: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
});

const cssStyles = {
  player: {
    marginTop: 15,
    borderRadius: 15,
    padding: 0,
    overflow: 'hidden'
  }
}
