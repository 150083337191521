import { View, Text, ActivityIndicator, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from '../react-router';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import Constants from '../constants';
import { CampaignContext, WpCampaignAction, campaignReducer } from '../contexts/campaign';
import { UserContext } from '../contexts/user';
import { GET_GROUP } from '../graphql/queries';
import CampaignOverview from '../components/CampaignOverview';
import CampaignEditor from '../components/Campaign/CampaignEditor';
import Navbar from '../components/Navbar';
import { Button, DashboardNav, IconButton } from '@whistlepitch/wp-components';
import LeadTable from '../components/Leads/LeadTable';
import Input from '../components/Input';
import Spinner from '../components/Spinner';
import { WpGroup } from '../contexts/lead';
import NewLeadModal from '../components/Leads/NewLeadModal';
import NewButton from '../components/NewButton';
import { UPDATE_GROUP } from '../graphql/lead';
import UserDetailsModal from '../components/User/UserDetailsModal';
import GroupLandingPageModal from '../components/Leads/GroupLandingPageModal';
import BasicLayout from '../components/Shared/BasicLayout';
import Icon from '../components/Icon';


export default function Group(): JSX.Element {
  const { state } = React.useContext(UserContext);

  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [group, setGroup] = useState<WpGroup>();
  const [name, setName] = useState<string>();
  const [showNewLead, setShowNewLead] = useState<boolean>(false);
  const [reload, setReload] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [showLandingPage, setShowLandingPage] = useState<boolean>(false);

  const params = useParams();

  const navigate = useNavigate();

  const [getGroup, { data, loading, refetch, error }] = useLazyQuery(GET_GROUP, {
    fetchPolicy: "no-cache",
  });

  const [updateGroup, updateGroupData] = useMutation(UPDATE_GROUP, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    loadGroup();
  }, [reload])

  useEffect(() => {
    if (data?.group?.success) {
      setGroup(data.group.group)
      setName(data.group.group.name ? data.group.group.name : 'New Group')
    }
  }, [data])

  useEffect(() => {
    if (name && group && name !== group.name) {
      if (saveTimeout) {
        clearTimeout(saveTimeout)
      }

      let gid = group.id;
      setSaveTimeout(setTimeout(() => {
        updateGroup({
          variables: { groupId: gid, group: { name } }
        })
      }, 1200))
    }

    return () => { if (saveTimeout) { clearTimeout(saveTimeout) } }
  }, [name])

  const loadGroup = () => {
    if (data && group) {
      refetch({ groupId: group.id });
    } else {
      getGroup({ variables: { groupId: params.groupId } })
    }
  }

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const onEditLandingPage = () => {
    setShowLandingPage(true);
  }

  const doneEditLandingPage = () => {
    setShowLandingPage(false);
    setReload(reload + 1);
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const onOpenNewLead = () => {
    setShowNewLead(true)
  }

  const onNewLead = (ids: string[]) => {
    // console.log("New lead", ids, ids ? ids.length : -1)
    setRefresh(refresh + 1);
    onCloseNewLead();
  }

  const onCloseNewLead = () => {
    setShowNewLead(false);
  }

  const renderHeader = (): JSX.Element => {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 20 }}>
        <Text style={{ width: 200, marginLeft: 50, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Name</Text>
        <Text style={{ width: 200, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Phone</Text>
        {Constants.blastView ? null : <Text style={{ width: 150, fontFamily: 'GothamMedium', color: Constants.colors.info }}>View Time</Text>}
        {Constants.blastView ? null : <Text style={{ width: 100, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Views</Text>}
        <Text style={{ width: 150, fontFamily: 'GothamMedium', color: Constants.colors.info }}>{Constants.blastView ? 'Blasts' : 'Campaigns'}</Text>
      </View>
    )
  }

  const renderBody = () => {
    if (!data) {
      return <Text key="group-not-found" style={{ flex: 1, textAlign: 'center', color: Constants.colors.secondary }}>Not Found</Text>;
    } else if (loading) {
      return <Spinner />
    } else if (group) {
      return (
        <View>
          <View style={styles.header}>
            <Input textAlign='left' isHeading={true} fontColor={Constants.colors.blue} value={name} setValue={setName} />
            {Constants.blastView || Constants.whistlePitch ?
              <IconButton style={{ marginLeft: 'auto', marginRight: 15 }} type="White" shape="Circle" onClick={onEditLandingPage}>
                <Icon width={20} height={20} name="dashboard" color="currentColor" colorHover="currentColor" />
              </IconButton> : null}
            <NewButton name={Constants.localizations.Lead} onClick={onOpenNewLead} />
          </View>
          {Constants.blastView || Constants.whistlePitch ? <GroupLandingPageModal isVisible={showLandingPage} group={group} closeModal={doneEditLandingPage} /> : null}
          <LeadTable key={`group-table-${refresh}`} header={renderHeader()} refresh={refresh} selectable={true} searchable={true} selectedLeads={selectedLeads} setSelectedLeads={setSelectedLeads} groupId={group?.id} />

          <NewLeadModal isVisible={showNewLead} groupId={group?.id} onClose={onCloseNewLead} onCreated={onNewLead} onAdded={onNewLead} />
        </View>
      )
    } else {
      return null;
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/groups'}>
      {renderBody()}
    </BasicLayout>
  )

  // return (
  //   <View style={styles.container}>
  //     <Navbar active={'/groups'} groupId={group?.id} />
  //     <View style={{ flex: 1, padding: 25 }}>
  //       <UserDetailsModal isVisible={showUserDetails} user={state.user ? state.user : undefined} onClose={closeUserDetails} />
  //       <DashboardNav name={state.user?.name ?? ''} dropOptions={Constants.dropdownOptions} actionOne={state.user?.wpAdmin} onAction={onDashboardAction} onDropdownSelect={onDashboardAction} />
  //
  //       <View style={{ marginTop: 25, padding: 10 }}>
  //         { renderBody() }
  //       </View>
  //     </View>
  //   </View>
  // );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: Constants.colors.backgroundLight,
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
});
