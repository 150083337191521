import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import Constants from '../constants';
import { UserContext } from '../contexts/user';
import CampaignOverview from '../components/CampaignOverview';
import Navbar from '../components/Navbar';
import Spinner from '../components/Spinner';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import Thumbnail from '../components/Shared/Thumbnail';
import Input, { Label } from '../components/Input';
import { Button, Theme } from '../components/Button';
import { useNavigate } from '../react-router';
import { GET_CAMPAIGN, GET_CAMPAIGNS, GET_CAMPAIGN_CATEGORIES, GET_PITCH_SLIDE } from '../graphql/queries';
import { CampaignContext, campaignReducer, WpFullCampaign, WpFeatureType, WpCampaignStructure } from '../contexts/campaign';
import { WpCampaign } from '../contexts/user';
import { CLONE_CAMPAIGN, CREATE_CAMPAIGN, UPDATE_CAMPAIGN } from '../graphql/campaign';
import { NetworkStatus, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { DashboardNav, SearchInput } from '@whistlepitch/wp-components'
import NewButton from '../components/NewButton';
import UserDetailsModal from '../components/User/UserDetailsModal';
import BasicLayout from '../components/Shared/BasicLayout';
import WpModal from '../components/WpModal';
import Dropdown, { Item } from '../components/Dropdown';
import { WpFullPitch, WpPitchSlide } from '../contexts/pitch';
import Header from '../components/Pitch/Header';
import SlideMainContent from '../components/Pitch/SlideMainContent';
import SlideWidgets from '../components/Pitch/SlideWidgets';
import PitchControls from '../components/Pitch/Controls';
import Footer from '../components/Pitch/Footer';
import MessageFeed from '../components/Shared/MessageFeed';
import { WpLeadMessage } from '../contexts/lead';
import { set } from 'react-hook-form';


export default function CampaignLibrary(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [campaigns, setCampaigns] = useState<WpFullCampaign[]>([]);
  const [campaign, setCampaign] = useState<WpFullCampaign>();
  const [search, setSearch] = useState<string>('');
  const [hovering, setHovering] = useState<"campaigns" | "leads" | "prev" | "next">();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewItems, setPreviewItems] = useState<Item[]>([]);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState<number>(0);
  const [currentPitchId, setCurrentPitchId] = useState<string>();
  const [currentSlide, setCurrentSlide] = useState<WpPitchSlide>();
  const [currentPreview, setCurrentPreview] = useState<string>();
  const [pitchRefresh, setPitchRefresh] = useState<number>(0);
  const [pitchObjs, setPitches] = useState<{ [k: string]: WpFullPitch }>({});
  const [messages, setMessages] = useState<WpLeadMessage[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [categoryItems, setCategoryItems] = useState<Item[]>([]);

  const navigate = useNavigate();

  const [getSlide, getSlideData] = useLazyQuery(GET_PITCH_SLIDE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  const [getCampaigns, { data, loading, refetch }] = useLazyQuery(GET_CAMPAIGNS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [getCampaign, getCampaignData] = useLazyQuery(GET_CAMPAIGN, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [cloneCampaign, cloneCampaignData] = useMutation(CLONE_CAMPAIGN, {
    fetchPolicy: "no-cache"
  })

  const getCampaignCategoriesData = useQuery(GET_CAMPAIGN_CATEGORIES, {
    fetchPolicy: "no-cache"
  })

  useEffect(() => {
    if (!data) {
      loadCampaigns();
    }
  }, [state.user])

  useEffect(() => {
    if (data?.campaigns?.campaigns) {
      setCampaigns(data.campaigns.campaigns)
    }
  }, [data])

  useEffect(() => {
    if (getCampaignCategoriesData.data?.campaignCategories?.categories) {
      setCategoryItems(getCampaignCategoriesData.data.campaignCategories.categories.map((category: string) => {
        return {
          value: category,
          label: category
        }
      }));
    }
  }, [getCampaignCategoriesData.data])

  useEffect(() => {
    if (getCampaignData.data?.campaign?.campaign) {
      setCampaign(getCampaignData.data.campaign.campaign)
    }
  }, [getCampaignData.data])

  useEffect(() => {
    if (cloneCampaignData.data && cloneCampaignData.data.cloneCampaign.campaign.id) {
      setShowPreview(false);
      window.location.href = `/campaign/${cloneCampaignData.data.cloneCampaign.campaign.id}`
    }
  }, [cloneCampaignData.data])

  useEffect(() => {
    if (campaign) {
      let structures = [
        campaign.structure.feature,
        campaign.structure.before,
        campaign.structure.appointment,
      ]

      let items = []
      let pitches: { [k: string]: WpFullPitch } = {};
      let new_messages: WpLeadMessage[] = [];
      for (let i = 0; i < structures.length; i++) {
        for (let j = 0; j < structures[i].length; j++) {
          let f = structures[i][j];

          if (f) {
            let findex = campaign.features.findIndex((feature) => feature.id === f.id)

            if (findex > -1) {
              let feature = campaign.features[findex];
              let message_text = '';
              if (feature.messageContent?.blocks && feature.messageContent.blocks.length) {
                for (let i = 0; i < feature.messageContent.blocks.length; i++) {
                  message_text += feature.messageContent.blocks[i].text + '\n';
                }
              }

              if (feature.pitch && feature.pitch.id && feature.pitch.structure.length) {
                message_text += `\n${Constants.app_url}/c/xY89zq/99ssnr`
              }

              new_messages.push({
                id: feature.id,
                campaignId: campaign.id,
                campaignMessageId: 'id',
                message: message_text,
                senderName: '',
                success: true,
                delivered: true,
                userId: state.user?.id ?? '',
                aiGenerated: false,
              })

              items.push({ value: 'feature-' + j, label: feature.label.substring(0, 23) + (feature.label.length > 23 ? '...' : '') })
              if (feature.pitch && feature.pitch.id && feature.pitch.structure.length) {
                pitches[feature.pitch.id] = feature.pitch;
                for (let k = 0; k < feature.pitch.structure.length; k++) {
                  let slide = feature.pitch.structure[k];
                  let val = 'pitch-' + feature.pitch.id + '-slide-' + slide.id;
                  if (k === feature.pitch.structure.length - 1) {
                    val += '-last';
                  }
                  items.push({ value: val, label: slide.label.substring(0, 23) + (slide.label.length > 23 ? '...' : '') })
                }
              }
            }
          }
        }
      }

      setMessages(new_messages)
      setPreviewItems(items)
      setPitches(pitches)
      if (items.length) {
        updatePreview(items[0].value);
      }
    }
  }, [campaign])

  useEffect(() => {
    if (getSlideData.data?.pitchSlide?.slide && getSlideData.networkStatus === NetworkStatus.ready) {
      setCurrentSlide(getSlideData.data?.pitchSlide?.slide)
      setPitchRefresh(pitchRefresh + 1)
    }
  }, [getSlideData.data?.pitchSlide?.slide, getSlideData.networkStatus])

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const onCloneCampaign = () => {
    if (campaign) {
      cloneCampaign({ variables: { campaignId: campaign.id, isPublic: true } })
    }
  }

  const loadCampaigns = () => {
    if (state.user) {
      if (data) {
        refetch({
          includeFeatures: false,
          includeStats: false,
          publicOnly: true
        });
      } else {
        getCampaigns({
          variables: {
            includeFeatures: false,
            includeStats: false,
            publicOnly: true
          }
        });
      }
    }
  }

  const updatePreview = (val: string | string[] | null) => {
    let this_val: string = val;
    if (Array.isArray(val)) {
      if (val.length > 0) {
        this_val = val[0];
      } else {
        this_val = null;
      }
    }

    if (!this_val && previewItems.length) {
      this_val = previewItems[0].value;
    }

    setCurrentPreview(this_val)
    if (this_val && this_val.indexOf('feature') > -1) {
      setCurrentFeatureIndex(parseInt(this_val.split('-')[1]))
      setCurrentPitchId(undefined)
      setCurrentSlide(undefined)
    } else if (this_val && this_val.indexOf('pitch') > -1) {
      setCurrentPitchId(this_val.split('-')[1])
      loadSlide(this_val.split('-')[3])
    }
    setPitchRefresh(pitchRefresh + 1)
  }

  const loadSlide = (slideId: string) => {
    if (slideId) {
      if (getSlideData.data) {
        getSlideData.refetch({ pitchSlideId: slideId, isPublic: true });
      } else {
        getSlide({ variables: { pitchSlideId: slideId, isPublic: true } })
      }
    }
  }

  const onHoverCampaigns = () => {
    setHovering("campaigns");
  }

  const onHoverOut = () => {
    setHovering(undefined);
  }

  const onHoverPrev = () => {
    setHovering("prev")
  }

  const onHoverNext = () => {
    setHovering("next")
  }

  const onPrev = () => {
    let pindex = previewItems.findIndex((preview) => preview.value === currentPreview);
    if (pindex > 0) {
      updatePreview(previewItems[pindex - 1].value)
    }
  }

  const onNext = () => {
    let pindex = previewItems.findIndex((preview) => preview.value === currentPreview);
    if (pindex < previewItems.length - 1) {
      updatePreview(previewItems[pindex + 1].value)
    }
  }

  const openCampaign = (campaignId: string) => {
    getCampaign({
      variables: {
        campaignId: campaignId,
        includeFeatures: true,
        includeStats: true,
        isPublic: true
      }
    })
    setShowPreview(true);
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  const handleChangeCategories = (values: string | string[] | null) => {
    if (values) {
      setCategories(Array.isArray(values) ? values : [values]);
    } else {
      setCategories([]);
    }
  }

  const getFirstImage = (campaignStructure: WpCampaignStructure[]) => {
    let image;

    for (let i = 0; i < campaignStructure.length; i++) {
      if (campaignStructure[i].featureType === WpFeatureType.PITCH) {
        let structure = campaignStructure[i].structure;
        for (let j = 0; j < structure.length; j++) {
          if (structure[j].id !== "redirect" && structure[j].thumbnail) {
            image = structure[j].thumbnail
          }
          if (image) {
            break;
          }
        }
      }
      if (image) {
        break;
      }
    }

    return image;
  }

  const renderBody = () => {
    if (loading) {
      return (
        <View>
          <Spinner />
        </View>
      )
    } else if (state.user) {
      // <IconButton type={"Primary"} shape={"Circle"} shadow={true}>
      //   <PlusIcon style={{ width: 20, height: 20 }} />
      // </IconButton>

      return (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.heading}>{Constants.blastView ? 'Public Blast Library' : 'Public Campaign Library'}</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 50, position: 'relative', zIndex: 10, gap: 20 }}>
            <SearchInput onChange={onSearch} onCleanData={() => setSearch('')} style={{ maxWidth: 350, flex: 1 }} />
            <Dropdown
              style={{ maxWidth: 350, position: 'relative', zIndex: 10, flex: 1 }}
              key="library-category"
              plain={true}
              placeholder="Filter by Category"
              multiple={true}
              items={categoryItems}
              value={categories}
              onChange={handleChangeCategories}
            />
          </View>
          <View style={styles.thumbnailWrapper}>
            {campaigns && campaigns.length ? campaigns.map((campaign, index) => {
              console.log('Search', search)
              if (search && (!campaign.name || !campaign.name.toLowerCase().includes(search.toLowerCase()))) {
                return null;
              }

              if (categories && Array.isArray(categories) && categories.length > 0 && !categories.includes(campaign.category)) {
                return null;
              }

              let image = getFirstImage(campaign.structure.feature);
              if (!image) {
                image = getFirstImage(campaign.structure.before);
              }
              if (!image) {
                image = getFirstImage(campaign.structure.appointment);
              }

              // Function to count the number of items in the non-null property
              const countItems = (structure: {
                feature: WpCampaignStructure[],
                appointment: WpCampaignStructure[],
                before: WpCampaignStructure[]
              }) => {
                const calculatePitchSlides = (array: WpCampaignStructure[]) => {
                  return array.reduce((sum, item) => {
                    if (item.featureType === "PITCH") {
                      const slides = item.structure.filter(s => s.id !== 'redirect');
                      return sum + slides.length;
                    }
                    return sum;
                  }, 0);
                };

                if (structure.appointment && Array.isArray(structure.appointment) && structure.appointment.length > 0) {
                  return structure.appointment.length + calculatePitchSlides(structure.appointment);
                } else if (structure.before && Array.isArray(structure.before) && structure.before.length > 0) {
                  return structure.before.length + calculatePitchSlides(structure.before);
                } else if (structure.feature && Array.isArray(structure.feature) && structure.feature.length > 0) {
                  return structure.feature.length + calculatePitchSlides(structure.feature);
                }
                return 0;
              };

              const itemCount = countItems(campaign.structure);
              const body = campaign.userName ? `Created by ${campaign.userName}` : undefined;

              return (
                <Thumbnail
                  key={`campaign-${campaign.id}-${index}`}
                  text={campaign.name}
                  body={body}
                  info={campaign.description}
                  subtext={campaign.category}
                  icon={'tag'}
                  description={`${itemCount} ${itemCount === 1 ? 'step' : 'steps'}`}
                  image={image}
                  descriptionIcon="bars"
                  action={<Button key={`btn-campaign-${campaign.id}-view`} text="Preview" theme={Theme.PRIMARY} small={true} shadow={false} style={{ marginTop: 'auto', marginLeft: 'auto' }} onPress={() => openCampaign(campaign.id)} />}
                  onPress={() => openCampaign(campaign.id)}
                />
              );
            }) : <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary }}>- No {Constants.blastView ? 'Blasts' : 'Campaigns'} Found -</Text>}
          </View>
        </View>
      )
    } else {
      return (
        <View>
        </View>
      )
    }
  }

  const renderPreviewModal = () => {
    return (
      <WpModal isVisible={showPreview} closeModal={setShowPreview}>
        <ModalBody title={campaign?.name ?? 'Preview'} closeModal={setShowPreview} width={400} >
          {renderPreview()}
        </ModalBody>
      </WpModal>
    )
  }

  const renderPreview = () => {
    if (campaign) {
      return (
        <View>
          <Text style={{ fontFamily: 'GothamMedium', fontSize: 14, color: "grey", marginBottom: 30, position: 'relative', zIndex: 1 }}>{campaign.description}</Text>
          <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', position: 'relative', zIndex: 5 }}>
            <Ionicons style={{ flex: 1, cursor: 'pointer', textAlign: 'center', opacity: hovering === "prev" ? 0.7 : 1.0 }} name={"chevron-back"} size={24} color={Constants.colors.blue} onPress={onPrev} onMouseEnter={onHoverPrev} onMouseLeave={onHoverOut} />
            <Dropdown style={{ flex: 4, zIndex: 5, position: 'relative', marginBottom: 5 }} placeholder={"Select Preview"} items={previewItems} value={currentPreview} onChange={updatePreview} />
            <Ionicons style={{ flex: 1, cursor: 'pointer', textAlign: 'center', opacity: hovering === "next" ? 0.7 : 1.0 }} name={"chevron-forward"} size={24} color={Constants.colors.blue} onPress={onNext} onMouseEnter={onHoverNext} onMouseLeave={onHoverOut} />
          </View>
          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', borderWidth: 5, borderRadius: 16, borderColor: '#F3F3F3' }}>
            {currentPitchId ?
              <View style={{ height: 500, width: '100%', borderRadius: 16, overflow: 'scroll', backgroundColor: '#FFF', position: 'relative', zIndex: 1 }}>
                {/* <PitchProvider key={`pitch-${pitchRefresh}`} pitchId={currentPitchId} campaignId={state.campaign.id} /> */}

                <View style={{ alignItems: 'center' }}>
                  <Header onBack={onPrev} />
                  <View style={[styles.body, { width: '100%', maxWidth: 500, marginBottom: 45, paddingTop: 25 }]}>
                    {currentSlide ?
                      <View key={`pitch-${pitchRefresh}`} style={styles.slideWrapper}>
                        <SlideMainContent
                          slideId={currentSlide.id}
                          title={currentSlide.title}
                          slideType={currentSlide.slideType ? currentSlide.slideType : null}
                          thumbnail={currentSlide.thumbnail}
                          url={currentSlide.url}
                          audioFile={currentSlide.audioFile}
                          calendars={currentSlide.calendars}
                          isVideo={currentSlide.isVideo}
                          content={currentSlide.content}
                        />
                        <SlideWidgets widgetStructure={currentSlide.widgetStructure} widgets={currentSlide.widgets} leadWidgets={[]} />
                      </View> : <Spinner />}
                  </View>
                  <View style={{ marginTop: 'auto' }}>
                    <PitchControls
                      onBack={onPrev}
                      onContinue={onNext}
                      showContinue={!!currentPreview && currentPreview.indexOf('last') === -1}
                      isFirst={true}
                      isLast={!!currentPreview && currentPreview.indexOf('last') > -1}
                      redirectType={pitchObjs[currentPitchId]?.redirectType}
                      redirectUrl={pitchObjs[currentPitchId]?.redirectUrl}
                      redirectButton={pitchObjs[currentPitchId]?.redirectButton}
                      redirectPhone={pitchObjs[currentPitchId]?.redirectPhone}
                      redirectPreview={true}
                    />
                  </View>
                  <Footer />
                </View>
              </View> :
              <View style={{ height: 300, width: '100%', borderRadius: 16, position: 'relative', zIndex: 1 }}>
                <MessageFeed onClickLink={onNext} messages={[messages[currentFeatureIndex]]} />
              </View>}
          </View>
          <ModalFooter>
            <Button
              key="btn-campaign-copy"
              text="Add To Campaigns"
              theme={Theme.PRIMARY}
              width={'100%'}
              style={{ flex: 1 }}
              onPress={onCloneCampaign}
              loading={cloneCampaignData.loading}
              disabled={cloneCampaignData.loading}
            />
          </ModalFooter>
        </View>
      )
    } else {
      return <Spinner />
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/campaigns'}>
      <View>
        {renderBody()}
        {renderPreviewModal()}
      </View>
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginTop: 5,
    marginBottom: 10
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
  newBtn: {
    fontFamily: 'GothamBold',
    fontSize: 15,
    color: Constants.colors.darkBlue,
    marginLeft: 10
  },
  icon: {
    cursor: 'pointer'
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap',
    position: 'relative',
    zIndex: 1
  },
  body: {
    flex: 1,
    flexDirection: 'column'
  },
  slideWrapper: {
    flex: 1,
    position: 'relative'
  },
})
