import { View, Text, Image, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Ionicons } from '@expo/vector-icons';

import Constants from './../../constants';


export default function Header({ image, name, color, onBack }: { image?: string, name?: string, color?: string, onBack?: () => void }): JSX.Element {
  const [aspectRatio, setAspectRatio] = useState(1)

  useEffect(() => {
    if (!image) {
      return;
    }

    let isValid = true;
    Image.getSize(image, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [image]);

  const renderBackButton = () => {
    if (onBack === undefined) {
      return <View style={{ marginLeft: 40 }}></View>
    } else {
      return <Ionicons style={{ paddingTop: 4 }} name="arrow-back-sharp" size={24} color={color ? color : Constants.colors.blue} onPress={onBack} />
    }
  }

  return (
    <View style={styles.header}>
      { renderBackButton() }
      { image ?
        <Image style={[styles.brand, { aspectRatio }]} source={{ uri: image }} /> :
        <Text style={{ padding: 2, fontFamily: 'GothamBold', color: color ? color : Constants.colors.darkBlue }}>{ name }</Text>
      }
      <View style={{ marginRight: 40 }}></View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    height: 45,
    backgroundColor: '#FFF',
    borderBottomColor: Constants.colors.infoLight,
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15
  },
  brand: {
    height: 30
  }
})
