import { Text, View, Pressable, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { AdjustmentsIcon, AnnotationIcon, ThumbUpIcon, ShieldCheckIcon, ScaleIcon, ViewListIcon, CheckCircleIcon } from '@heroicons/react/outline';
import Constants from './../../constants'
import { WpWidgetType } from './../../contexts/pitch';
import { IconButton } from '@whistlepitch/wp-components';
import { PresentationChartBarIcon } from '@heroicons/react/solid';


function toTitleCase(str:string) {
  return str.replace(
    /\w\S*/g,
    function(txt:string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export default function WidgetOptions({ active, onPress } : { active?:WpWidgetType, onPress:Function }): JSX.Element {
  const [hovering, setHovering] = useState<WpWidgetType|null>(null);

  const onHover = (what:WpWidgetType) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(null);
  }

  const getColor = (type:WpWidgetType) => {
    return {
      YESNO: Constants.colors.success,
      RATING: Constants.colors.danger,
      SHORT: Constants.colors.warning,
      CHOICE: Constants.colors.primary,
      CHECKBOX: Constants.colors.primary,
      VERIFY: Constants.colors.accent,
    }[type]
  }

  const getExtraStyles = (type:WpWidgetType) => {
    let color = getColor(type);
    let isActive = active === type;
    if (type === WpWidgetType.CHOICE || type === WpWidgetType.CHECKBOX) {
      isActive = (active === WpWidgetType.CHOICE) || (active === WpWidgetType.CHECKBOX);
    }
    let defaultColor = isActive ? getColor(type) : '#FFF';
    let defaultTextColor = isActive ? '#FFF' : getColor(type);
    let opacity = hovering === type ? 0.6 : 1;

    return {
      opacity: opacity,
      backgroundColor: hovering === type ? color : defaultColor,
      color: hovering !== type ? defaultTextColor : '#FFF',
      border: '1px solid ' + color
    }
  }

  return (
    <View style={styles.container}>
      <IconButton style={{ width: 70 }} label="Rating" type={active !== WpWidgetType.RATING ? "AccentRed" : "Red"} onClick={() => onPress(WpWidgetType.RATING)}>
        <AdjustmentsIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ width: 70 }} label="Yes/No" type={active !== WpWidgetType.YESNO ? "AccentGreen" : "Green"} onClick={() => onPress(WpWidgetType.YESNO)}>
        <ThumbUpIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ width: 70 }} label="Short Answer" type={active !== WpWidgetType.SHORT ? "AccentCyan" : "Cyan"} onClick={() => onPress(WpWidgetType.SHORT)}>
        <AnnotationIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ width: 70 }} label="Survey" type={active !== WpWidgetType.CHOICE && active !== WpWidgetType.CHECKBOX ? "AccentBlue" : "Blue"} onClick={() => onPress(WpWidgetType.CHECKBOX)}>
        <CheckCircleIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ width: 70 }} label="Verify" type={active !== WpWidgetType.VERIFY ? "AccentPurple" : "Purple"} onClick={() => onPress(WpWidgetType.VERIFY)}>
        <ShieldCheckIcon style={cssStyles.icon} />
      </IconButton>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    marginBottom: 20
  },
  iconWrapper: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 8,
    textAlign: 'center'
  },
  text: {
    fontWeight: 'bold',
    fontSize: 10,
    maxWidth: 50,
    height: 25,
    textAlignVertical: 'center'
  }
});

const cssStyles = {
  icon: {
    paddingBottom: 5,
    height: 20,
    minWidth: 50,
  }
}
