import { View, StyleSheet, Dimensions, ScrollView, LayoutChangeEvent } from 'react-native';
import React, { useEffect } from 'react';
import Modal from "react-native-modal";
import Constants from '../constants';

export default function WpModal({ isVisible, closeModal = null, children }: { isVisible: boolean, closeModal?: null | Function, children: React.ReactNode }): JSX.Element {
  const [viewWidth, setViewWidth] = React.useState<number>(Dimensions.get("window").width)
  const [viewHeight, setViewHeight] = React.useState<number>(Dimensions.get("window").height)

  const onBackdropPress = () => {
    if (closeModal !== null) {
      closeModal();
    }
  }

  useEffect(() => {
    const updateDimensions = () => {
      setViewWidth(window.innerWidth);
      setViewHeight(window.innerHeight);
    }

    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <View>
      <Modal style={styles.modal} deviceHeight={viewHeight} deviceWidth={viewWidth} isVisible={isVisible} onBackdropPress={onBackdropPress} backdropOpacity={0.2} backdropColor={"#030303"}>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.container}>
          {children}
        </ScrollView>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  modal: {
    position: 'relative',
    justifyContent: 'flex-start',
    margin: 0,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: 15,
    position: 'relative',
    paddingBottom: 30
  },
});
