import { StyleSheet, Text } from 'react-native';
import React from 'react';
import Constants from './../../constants';


export default function Heading({ text, size = "default", color = Constants.colors.primary }: { text: string, size?: "default" | "small" | "large", color?: string }): JSX.Element {
  let fontSize = 24;

  return (
    <Text style={[styles.heading, { fontSize: size === 'large' ? 30 : fontSize, color: color }]}>{text}</Text>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontFamily: 'GothamBold'
  }
});
