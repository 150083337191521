import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import { Button } from './Button';
import { DuplicateIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import { SketchPicker } from 'react-color';

export default function PitchControls(): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const showSettings = () => {
    setSettingsVisible(true);
  }

  const hideSettings = () => {
    setSettingsVisible(false);
  }

  return (
    <View style={styles.container}>
      <DuplicateIcon style={cssStyles.icon} />
      <ExternalLinkIcon style={cssStyles.icon} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 2,
    position: 'absolute',
    top: 30,
    right: -30,
    backgroundColor: '#FFF'
  },
  tooltip: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 20,
    height: 20,
    color: Constants.colors.info,
    cursor: 'pointer'
  }
}
