import { View, Text, StyleSheet, Dimensions, LayoutChangeEvent } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useNavigate } from './../../react-router';

import Constants from './../../constants';
import Spinner from './../Spinner';
import Header from './Header';
import Footer from './Footer';
import SlideMainContent from './SlideMainContent';
import SlideWidgets from './SlideWidgets';
import PitchControls from './Controls';
import { LeadContext, WpLeadAction } from './../../contexts/lead';
import { WpPitchSlide, WpSlideType } from './../../contexts/pitch';
import { GET_LEADS } from './../../graphql/queries';
import { CONFIRM_BROWSER, LOAD_LEAD_ACTIVITY, REGISTER_BROWSER, TRACK_LEAD } from './../../graphql/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import ConfirmPhoneModal from '../User/ConfirmPhoneModal';
import ModalFooter from '../ModalFooter';
import { Button } from '@whistlepitch/wp-components';
import Toast from 'react-native-toast-message';
import { useIdleTimer } from 'react-idle-timer'


export default function PitchPresenter({ pitchId, campaignId, campaignFeatureCode, campaignLeadCode }: { pitchId?: string, campaignId?: string, campaignFeatureCode?: string, campaignLeadCode?: string }): JSX.Element {
  const { state, dispatch } = React.useContext(LeadContext);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [slide, setSlide] = useState<WpPitchSlide>();
  const [showContinue, setShowContinue] = useState<boolean>(true);
  const [unregisteredBrowser, setUnregisteredBrowser] = useState<boolean>(false);
  const [confirmingBrowser, setConfirmingBrowser] = useState<boolean>(false);
  const [pitchRedirectUrl, setPitchRedirectUrl] = useState<string>();
  const [width, setViewWidth] = useState<number>(0);
  const [accessToken, setAccessToken] = useState<string>();

  const navigate = useNavigate();

  const [loadActivity, { data, loading }] = useMutation(LOAD_LEAD_ACTIVITY, {
    fetchPolicy: "no-cache"
  });
  const [trackLead, trackLeadData] = useMutation(TRACK_LEAD, {
    fetchPolicy: "no-cache"
  });
  const [registerBrowser, registerBrowserData] = useMutation(REGISTER_BROWSER, {
    fetchPolicy: "no-cache"
  });
  const [confirmBrowser, confirmBrowserData] = useMutation(CONFIRM_BROWSER, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if ((pitchId && campaignId) || (campaignLeadCode && campaignFeatureCode)) {
      const token = localStorage.getItem('wp-campaign-access-token');
      const deviceId = localStorage.getItem('wp-device-id');

      loadActivity({ variables: { pitchId, campaignId, campaignLeadCode, campaignFeatureCode, token, deviceId } })
    }
  }, [])

  const { isIdle } = useIdleTimer({ timeout: 1000 * 60 * (__DEV__ ? 0.25 : 2) })  // Idle timeout at 2 minutes
  // useEffect(() => {
  //   console.log("Would restart paused timer")
  //   let timeout = setTimeout(() => {
  //     setPaused(true)
  //     console.log("***** Would Pause *****")
  //   }, 1000 * 60 * 2) // 2 minute idle timeout
  //
  //   return () => clearInterval(timeout)
  // })

  // useEffect(() => {
  //   if (campaignLeadCode) {
  //     let timeout = setTimeout(() => {
  //       if (state?.activity?.ssid && !isIdle()) {
  //         trackLead({ variables: { leadActivitySsid: state.activity.ssid, widgets: state?.lead?.widgets, events: state?.events } })
  //       }
  //     }, 2000)

  //     return () => clearInterval(timeout)
  //   }
  // }, [state?.lead?.widgets, state?.events])

  useEffect(() => {
    if (campaignLeadCode) {
      const widgets = state?.lead?.widgets;
      let timeout = setTimeout(() => {
        if (state?.activity?.ssid && !isIdle()) {
          trackLead({ variables: { leadActivitySsid: state.activity.ssid, times: state?.times, widgets: widgets, events: state?.events } })
        }
      }, 650)

      return () => clearInterval(timeout)
    }
  }, [state.times, state?.lead?.widgets, state?.events])

  useEffect(() => {
    if (data?.loadLeadActivity.success) {
      if (data.loadLeadActivity?.activity?.deviceId) {
        localStorage.setItem(`wp-device-id`, data.loadLeadActivity.activity.deviceId);
      }
      dispatch({ type: WpLeadAction.SET_DATA, lead: data.loadLeadActivity.lead, pitch: data.loadLeadActivity.pitch, activity: data.loadLeadActivity.activity, campaign: data.loadLeadActivity.campaign, times: data.loadLeadActivity.times })
    } else if (data?.loadLeadActivity.errors && data?.loadLeadActivity.errors.indexOf('No Access') > -1) {
      setUnregisteredBrowser(true);
    }
  }, [data])

  useEffect(() => {
    if (confirmBrowserData.data?.confirmBrowser.success) {
      let token = confirmBrowserData.data?.confirmBrowser.token;
      setAccessToken(token)
      loadActivity({ variables: { campaignLeadCode: campaignLeadCode, campaignFeatureCode: campaignFeatureCode, token: token } })
      setConfirmingBrowser(false);

      Toast.show({
        type: 'success',
        text1: 'Verification Complete!',
      });

      const old_token = localStorage.getItem('wp-campaign-access-token');
      if (old_token && old_token.length) {
        token = `${old_token}|${token}`;
      }
      localStorage.setItem('wp-campaign-access-token', token);
    }
  }, [confirmBrowserData.data])

  useEffect(() => {
    if (state.pitch && state.pitch.v2) {
      if (currentIndex < state.pitch.slides.length) {
        const slide = state.pitch.slides[currentIndex]
        setSlide(slide);
        return;
      }
    } else if (state.pitch && currentIndex < state.pitch.structure.length) {
      const slideStucture = state.pitch.structure[currentIndex]
      const sindex = state.pitch.slides.findIndex((s) => s.id === slideStucture.id)

      setSlide(sindex > -1 ? state.pitch.slides[sindex] : undefined);
      return;
    }
    setSlide(undefined)
  }, [state.pitch, currentIndex])

  useEffect(() => {
    if (slide && slide.slideType === WpSlideType.CALENDAR) {
      let eindex = -1;
      if (state.events) {
        eindex = state.events.findIndex((e) => e.pitchSlideId === slide.id)
      }

      setShowContinue(eindex > -1)
    } else {
      setShowContinue(true)
    }
  }, [state.events, slide?.id])

  useEffect(() => {
    if (state?.pitch?.redirectUrl) {
      if (state.lead?.landingPageId) {
        // append tcid=artist{state.lead.landingPageId} to redirectUrl
        let url = state.pitch.redirectUrl;
        url += url.indexOf('?') > -1 ? '&' : '?';
        url += `tcid=${Constants.tattooCare ? 'artist' : 'funnel'}${state.lead?.landingPageId}`;
        setPitchRedirectUrl(url);
      } else {
        setPitchRedirectUrl(state.pitch.redirectUrl);
      }
    }
  }, [state?.pitch?.redirectUrl, state.lead?.landingPageId])

  const goHome = () => {
    navigate('/')
  }

  const onSubmitCode = (code: string) => {
    if (campaignLeadCode && campaignFeatureCode) {
      confirmBrowser({
        variables: {
          campaignLeadCode: campaignLeadCode,
          campaignFeatureCode: campaignFeatureCode,
          code: code
        }
      })
    }
  }

  const onSendText = () => {
    if (campaignLeadCode && campaignFeatureCode) {
      setConfirmingBrowser(true)
      setUnregisteredBrowser(false)
      registerBrowser({
        variables: {
          campaignLeadCode: campaignLeadCode,
          campaignFeatureCode: campaignFeatureCode
        }
      })
    }
  }

  const onBack = () => {
    setCurrentIndex(Math.max(currentIndex - 1, 0))
  }

  const onContinue = () => {
    if (state.pitch?.v2) {
      setCurrentIndex(Math.min(state.pitch.slides.length, currentIndex + 1))
    } else if (state?.pitch?.structure) {
      setCurrentIndex(Math.min(state?.pitch?.structure.length, currentIndex + 1))
    }
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const renderInactive = () => {
    return (
      <View style={styles.inactive}>
        <Text style={styles.inactiveText}>The content you are looking for is no longer active or has been removed.</Text>
      </View>
    )
  }

  const renderPitch = () => {
    if (state.pitch) {
      if (state?.pitch?.structure.length === 0 || state.pitch.slides.length === 0) {
        return renderInactive();
      } else if (currentIndex === state.pitch.structure.length || currentIndex === state.pitch.slides.length) {
        return (
          <View style={styles.slideWrapper}>
            <SlideMainContent
              title={"Thank You For Viewing!"}
              color={state?.campaign?.brandColor}
            />
          </View>
        )
      } else if (!slide) {
        return <Spinner />
      }

      return (
        <View style={styles.slideWrapper}>
          <SlideMainContent
            slideId={slide.id}
            title={slide.title}
            slideType={slide.slideType ? slide.slideType : WpSlideType.TEXT}
            thumbnail={slide.thumbnail}
            url={slide.url}
            calendars={slide.calendars}
            isIdle={isIdle}
            isVideo={slide.isVideo}
            content={slide.content}
            audioFile={slide.audioFile}
            color={state?.campaign?.brandColor}
          />
          <SlideWidgets color={state?.campaign?.brandColor} widgetStructure={slide.widgetStructure} widgets={slide.widgets} leadWidgets={state?.lead?.widgets ?? []} />
        </View>
      )
    } else if (data?.loadLeadActivity && !data.loadLeadActivity.success) {
      return renderInactive();
    }
    return <Spinner style={{ marginTop: 50 }} />;
  }

  return (
    <View style={styles.container}>
      <View style={{ alignItems: 'center' }} onLayout={onLayout}>
        <Header image={state?.campaign?.brandImage} name={state?.campaign?.brandName} color={state?.campaign?.brandColor} onBack={currentIndex === 0 ? undefined : onBack} />
        <View style={[styles.body, { width: '100%', maxWidth: 500, marginBottom: 45 }]}>
          {renderPitch()}
        </View>
        <View style={{ marginTop: 'auto' }}>
          {state.pitch && (currentIndex === state.pitch?.structure.length || currentIndex === state.pitch?.slides.length) ?
            <PitchControls onBack={onBack} onContinue={onContinue} showContinue={false} isFirst={false} isLast={false} color={state?.campaign?.brandColor} /> : null}
          {!state.pitch || (currentIndex === state.pitch?.structure.length || currentIndex === state.pitch?.slides.length) ? null :
            <PitchControls
              onBack={onBack}
              onContinue={onContinue}
              showContinue={showContinue}
              isFirst={true}
              isLast={!state?.pitch?.structure || currentIndex === state.pitch.structure.length - 1 || currentIndex === state.pitch.slides.length - 1}
              redirectType={state?.pitch?.redirectType}
              redirectUrl={pitchRedirectUrl}
              redirectButton={state?.pitch?.redirectButton}
              redirectPhone={state?.pitch?.redirectPhone}
              campaignFeatureCode={campaignFeatureCode}
              campaignLeadCode={campaignLeadCode}
              color={state?.campaign?.brandColor}
            />
          }
        </View>
        <Footer />

        <WpModal isVisible={unregisteredBrowser}>
          <ModalBody style={{ position: 'relative' }} title="Link Verification" closeModal={goHome} width={400}>
            <View style={{ flex: 1, position: 'relative' }}>
              <Text style={{ fontFamily: 'GothamMedium' }}>We need to send you a verification code. We only need to do this once per browser. It's how we keep this content exclusive!</Text>
              <ModalFooter>
                <Button type="Primary" size="Default" shape="Pill" onClick={onSendText} disabled={loading}>
                  {loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Send me a code</Text>}
                </Button>
              </ModalFooter>
            </View>
          </ModalBody>
        </WpModal>

        <ConfirmPhoneModal isVisible={confirmingBrowser} onClose={goHome} onSubmit={onSubmitCode} loading={confirmBrowserData.loading} title="Enter Code" />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    position: 'relative',
    flexDirection: 'column',
    flex: 1
    // minHeight: '100vh',
  },
  slideWrapper: {
    flex: 1,
    position: 'relative'
  },
  inactive: {
    margin: 25,
    marginBottom: 0,
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20
  },
  inactiveText: {
    color: Constants.colors.secondary,
    textAlign: 'center'
  },
  body: {
    flex: 1,
    flexDirection: 'column'
  }
})
