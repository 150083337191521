import { View, Text, ActivityIndicator, StyleSheet, Dimensions } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import Constants from '../../constants';
import { Button, Theme } from './../Button';
import { UserContext, WpUserAction } from '../../contexts/user';
import OauthPopup from 'react-oauth-popup'
import { GET_USER } from '../../graphql/queries';


export default function CalendlyOauthPopup({ text, onClose=() => {} } : { text: string, onClose?: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const [getUser, { data, loading, error }] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (data) {
      if (data.user.success) {
        dispatch({ type: WpUserAction.REFRESH_USER, user: data?.user?.user})
      }
      onClose();
    }
  }, [data])

  const handleOnClose = () => {
    getUser();
  }

  const onCode = (code: string, params: URLSearchParams) => {
    // console.log("onCode", code, params)
  }

  return (
    <div>
      <OauthPopup
        url={Constants.base_oauth2 + '/calendly/' + state?.user?.code}
        onCode={onCode}
        onClose={handleOnClose}
      >
        <div style={{ width: '100%', textAlign: 'center', fontFamily: 'GothamMedium', color: Constants.colors.primaryMedium, marginTop: 10, cursor: 'pointer' }}>{text}</div>
      </OauthPopup>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  }
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
