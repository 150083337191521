import React from 'react';
import { CampaignStats } from './campaign';

export interface WpModelSummary {
  id: string,
  name: string
}

export interface WpCustomValue {
  id: string,
  model: string,
  modelName: string,
  name: string,
  value: string,
  customFieldId: string
  created: string
}

export interface WpOrganization {
  id: string,
  v2: boolean,
  name: string,
  brand?: string,
  orgType?: string,
  access: Access,
  color?: string,
  image?: string,
  billing?: Subscription
  bank?: BankAccount
  integrations?: WpIntegration[]
  pitches: WpModelSummary[],
  users: WpModelSummary[],
  messageAlertIds: string[],
  stats: WpOrganizationStats
  message: string
  messageContent: object
  autoReply: string
  welcomeMessageContent: object
  autoReplyContent: object
  autoReplyEnabled: boolean
  twilioPhone: string
  forwardingPhone: string
  allowCallForwarding: boolean
  allowIncomingLeads: boolean
  allowSelfOnboarding: boolean
  allowAfterHours: Boolean
  allowWeekends: Boolean
  allowOldStats: Boolean
  showMessages: Boolean
  showAppt: Boolean
  showCalendly: Boolean
  showAudio: Boolean
  showApptPlaceholders: boolean
  showCustomWindow: boolean
  showEventsPage: boolean
  understandsTiming: string
  understandsContacts: string
  understandsEdit: string
}

export interface WpOrganizationStats {
  leads: number
  messages: number
  messagesSuccess: number
  messagesFailed: number
  responses: number
  deliveryRate: number
  responseRate: number
  openRate: number
  viewRate: number
  slideViewRate: number
}

export interface Products {
  id: string
  name: string
  price: number
}

export interface Subscription {
  active: boolean
  expires: string
  periodEnd: string
  card?: Card
  invoices: Invoice[]
  messages: Message[]
  products: Products[]
}

export interface BankAccount {
  name: string
  last4: string
}

export interface Card {
  brand: string
  last4: string
  expMonth: number
  expYear: number
}

export interface Invoice {
  paid: number
  amount: number
  url: string
}

export interface Message {
  cycle: string
  total: number
}

export interface WpCampaign {
  id: string,
  v2: boolean,
  leadId: string,
  name: string
  stats: CampaignStats
}

export interface WpUser {
  id: string,
  code: string,
  name: string,
  firstName: string,
  email: string,
  phone: string,
  token: string,
  new: boolean,
  wpAdmin: boolean,
  showAppt: boolean,
  showCalendly: boolean,
  organization: WpOrganization
  organizations: WpOrganization[]
  integrations: WpIntegration[]
  stats: WpUserStats
  customValues: CustomValue[]
}

export interface CustomValue {
  id?: string
  model?: string
  modelName?: string
  name: string
  value: string
  customFieldId?: string
  created?: string
}

export interface WpUserStats {
  leads: number,
  messages: number,
  views: number,
  earnings: number
}

export interface WpIntegration {
  id: string,
  userId: string,
  created: string,
  integrationType: WpIntegrationType
}

export interface WpEngagementMetrics {
  id: string
  view: number
  viewTime: number
  share: number
  reopen: number
  widget: number
  video: number
  scheduled: number
  completed: number
}

export enum WpIntegrationType {
  CALENDLY = "CALENDLY",
  HIGHLEVEL = "HIGHLEVEL",
  GOOGLESHEETS = "GOOGLESHEETS",
  MODMED = "MODMED",
  NONE = "NONE"
}

export enum Access {
  NONE = "NONE",
  LIMITED = "LIMITED",
  MANAGER = "MANAGER",
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  READONLY = "READONLY",
}

interface WpUserState {
  user: WpUser | null
}

export const initialState: WpUserState = {
  user: null
}

export enum WpUserAction {
  REFRESH_USER = "REFRESH_USER",
  REFRESH_ORGANIZATION = "REFRESH_ORGANIZATION",
  SWITCH_ORGANIZATION = "SWITCH_ORGANIZATION",
  LOGOUT_USER = "LOGOUT_USER"
}

interface WpUserActions {
  type: WpUserAction,
  user: WpUser | null,
  organization?: WpOrganization,
}

export const UserContext = React.createContext<{
  state: WpUserState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});

export const userReducer = (state: WpUserState, action: WpUserActions) => {
  switch (action.type) {
    case WpUserAction.REFRESH_USER:
      const { user } = action;

      if (user?.token) {
        localStorage.setItem('token', user.token);
      }
      return {
        ...state,
        user: user
      }
    case WpUserAction.REFRESH_ORGANIZATION:
      const { organization } = action;

      if (state.user?.organization) {
        return {
          ...state,
          user: {
            ...state.user,
            organization: organization
          }
        }
      }
      return {
        ...state
      }
    case WpUserAction.LOGOUT_USER:
      localStorage.removeItem('token')

      return {
        ...state,
        user: null
      }
    default:
      return state;
  }
};
