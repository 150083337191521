import React, { useCallback } from 'react'
import { View, Text, StyleSheet } from 'react-native';
import { useDropzone } from 'react-dropzone'
import Constants from './../constants';
import { CloudUploadIcon } from '@heroicons/react/outline';
import { readString } from 'react-native-csv'


export default function CsvDropzone({ setData } : { setData: (data: object[]) => void }) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const results = readString(reader.result, {
          header: true
        })

        setData(results.data)
      }

      reader.readAsText(file)
    })

  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <View style={styles.dropzoneWrapper}>
      <View style={styles.dropzone} {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUploadIcon style={cssStyles.icon} />
        <Text style={styles.message}>Drag or click here to upload a CSV</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dropzone: {
    flex: 1,
    selfAlign: 'stretch',
    border: '1px dashed ' + Constants.colors.info,
    backgroundColor: Constants.colors.lightBlue,
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  dropzoneWrapper: {
    padding: 7,
    backgroundColor: Constants.colors.lightBlue,
    borderRadius: 15
  },
  message: {
    color: Constants.colors.secondary,
    padding: 20
  }
});

const cssStyles = {
  icon: {
    color: Constants.colors.primary,
    width: 50,
    height: 50,
    marginTop: 20
  }
}
