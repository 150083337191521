import { Text, LayoutChangeEvent, View, StyleSheet, Image, ActivityIndicator, Dimensions, TextInput } from 'react-native';
import React, { useState, useEffect } from 'react';
import { PitchContext, PitchSlideContext, WpPitchSlideAction, WpSlideType } from './../../contexts/pitch';
import { CampaignContext, WpCampaignAction } from './../../contexts/campaign';
import { GET_PITCH_SLIDE } from '../../graphql/queries';
import { UPDATE_PITCH_SLIDE, CREATE_SLIDE_WIDGET } from '../../graphql/pitch';
import { UPDATE_CAMPAIGN } from '../../graphql/campaign';
import Constants from '../../constants';
import ModalBody from './../ModalBody';
import { Feather } from '@expo/vector-icons';
import WpModal from './../WpModal';
import ModalFooter from './../ModalFooter';
import { Button, Theme } from './../Button';
import Uppyup from './../Uppyup';
import Input, { Info, Label } from './../Input';
import SlideWidget from './../Pitch/SlideWidget';
import { useLazyQuery, useMutation } from "@apollo/client";
import Collapsible from 'react-native-collapsible/Collapsible';
import ReactPlayer from 'react-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from 'react-native-toast-message';
import { TrashIcon } from '@heroicons/react/outline';
import { UPDATE_GROUP } from '../../graphql/lead';
import { WpGroup, WpGroupLandingPageSchedule } from '../../contexts/lead';
import Dropdown from '../Dropdown';
import { Slider } from '@miblanchard/react-native-slider';
import CampaignDropdown from '../CampaignDropdown';
import Checkbox from '../Checkbox';
import Spinner from '../Spinner';
import { UserContext } from '../../contexts/user';

const window = Dimensions.get('window');
const scheduleItems: { label: string, value: WpGroupLandingPageSchedule }[] = [
  { label: 'Weekly', value: WpGroupLandingPageSchedule.WEEKLY },
  { label: 'Monthly', value: WpGroupLandingPageSchedule.MONTHLY },
  { label: 'Bi-monthly', value: WpGroupLandingPageSchedule.BI_MONTHLY },
  { label: 'Other', value: WpGroupLandingPageSchedule.OTHER }
]

export default function GroupLandingPageModal({ isVisible, group, closeModal }: { group: WpGroup, isVisible?: boolean, closeModal: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const [viewWidth, setViewWidth] = useState<number>(0);
  const [editing, setEditing] = useState<string>();
  const [newProfile, setNewProfile] = useState<boolean>();
  const [newExplainer, setNewExplainer] = useState<boolean>();

  const [dimensions, setDimensions] = useState(window)
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [color, setColor] = useState<string>()
  const [explainer, setExplainer] = useState<string>()
  const [explainerMimeType, setExplainerMimeType] = useState<string>()
  const [trialPeriod, setTrialPeriod] = useState<number>()
  const [profile, setProfile] = useState<string>()
  const [schedule, setSchedule] = useState<WpGroupLandingPageSchedule>()
  const [price, setPrice] = useState<number>(5)
  const [sendCampaign, setSendCampaign] = useState<boolean>(false)
  const [campaignId, setCampaignId] = useState<string>()

  const [copyText, setCopyText] = useState<string>("Save and Copy Link")
  const [viewing, setViewing] = useState<"details" | "branding">("details")

  const [plugins, setPlugins] = useState<string[]>([])
  const [newVideo, setNewVideo] = useState<boolean>(false)
  const [showControls, setShowControls] = useState<boolean>(false)
  const [dropownOpened, setDropownOpened] = useState<boolean>(false)
  const [aspectRatioExplainer, setAspectRatioExplainer] = useState<number>(0)
  const [aspectRatioProfile, setAspectRatioProfile] = useState<number>(0)
  const [ExplainerElement, setExplainerElement] = useState<JSX.Element | null>(null)
  const [ProfileElement, setProfileElement] = useState<JSX.Element>()

  const [getSlide, getSlideData] = useLazyQuery(GET_PITCH_SLIDE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })
  const [updateGroup, updateGroupData] = useMutation(UPDATE_GROUP, {
    fetchPolicy: "no-cache"
  })

  useEffect(() => {
    if (!explainer || !explainerMimeType || explainerMimeType.indexOf('video') > -1) {
      return;
    }

    let isValid = true;
    Image.getSize(explainer, (width, height) => {
      if (isValid) {
        setAspectRatioExplainer(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [explainer, explainerMimeType]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    let isValid = true;
    Image.getSize(profile, (width, height) => {
      if (isValid) {
        setAspectRatioProfile(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [profile, aspectRatioProfile]);

  useEffect(() => {
    if (profile) {
      setProfileElement(<View style={styles.logo} ><Image style={[styles.profile, { aspectRatio: 1 }]} source={{ uri: profile }} /></View>)
    }
  }, [aspectRatioProfile, profile])

  useEffect(() => {
    if (explainer && explainerMimeType) {
      if (explainerMimeType.indexOf('video') === -1) {
        setExplainerElement(<Image style={{ borderRadius: 10, alignSelf: 'center', marginTop: 20, width: 200, aspectRatio: aspectRatioExplainer }} source={{ uri: explainer }} />)
      } else if (explainerMimeType.indexOf('video') > -1 && explainer.toLowerCase().indexOf('.mp4') > -1) {
        setExplainerElement(
          <ReactPlayer
            style={cssStyles.player}
            url={explainer}
            controls={true}
            pip={false}
            width='100%'
            height={'auto'}
          />
        )
      } else {
        setExplainerElement(
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8, marginTop: 20 }}>
            <Spinner />
            <Text style={{ fontFamily: 'Gotham', color: Constants.colors.secondary }}>Video Processing</Text>
          </View>
        )
      }
    } else {
      setExplainerElement(null)
    }
  }, [aspectRatioExplainer, explainer, explainerMimeType])

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  useEffect(() => {
    if (group.landingPage) {
      if (title === undefined) {
        setTitle(group.landingPage.title)
        setDescription(group.landingPage.description)
        setExplainer(group.landingPage.explainer)
        setExplainerMimeType(group.landingPage.explainerMimeType)
        setColor(group.landingPage.color)
        setProfile(group.landingPage.profile)
        setSchedule(group.landingPage.schedule)
        setPrice(group.landingPage.price ? group.landingPage.price : 5)
        setSendCampaign(group.landingPage.sendCampaign)
        setCampaignId(group.landingPage.campaignId)
        setTrialPeriod(group.landingPage.trialPeriod)
        setShowControls(!!group.landingPage.profile)
      }
    }
  }, [group.landingPage]);

  useEffect(() => {
    if (updateGroupData.data?.updateGroup?.success) {
      onCloseModal();
    }
  }, [updateGroupData.data?.updateGroup])

  const onCloseModal = () => {
    closeModal();
    setCopyText("Save and Copy Link")
  }

  const onUploadProfileComplete = (url: string, mime: string) => {
    setProfile(url);
    setNewProfile(false);
  }

  const onUploadExplainerComplete = (url: string, mime: string) => {
    setExplainer(url);
    setExplainerMimeType(mime);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onSave = () => {
    updateGroup({
      variables: {
        groupId: group.id,
        group: {
          landingPage: {
            title: title,
            description: description,
            explainer: explainer,
            explainerMimeType: explainerMimeType,
            color: color,
            profile: profile,
            schedule: schedule,
            price: price,
            sendCampaign: sendCampaign,
            campaignId: campaignId,
            trialPeriod: trialPeriod && !isNaN(trialPeriod) ? parseInt(trialPeriod) : 0
          }
        }
      }
    })
  }

  const onNewExplainer = () => {
    setNewExplainer(true);
  }

  const onNewProfile = () => {
    setNewProfile(true);
  }

  const confirmCopy = () => {
    setCopyText('Copied! Saving...')
    Toast.show({
      type: 'success',
      text1: 'Link Copied',
    });
    onSave();
  }

  const changeSchedule = (value: string | string[] | null) => {
    if (!Array.isArray(value)) {
      setSchedule(value)
    }
  }

  const changePrice = (value: number | number[]) => {
    if (Array.isArray(value) && value.length) {
      setPrice(value[0])
    }
  }

  const openScheduleDropdown = () => {
    setDropownOpened(true)
  }

  const closeScheduleDropdown = () => {
    setDropownOpened(false)
  }

  const renderBranding = () => {
    if (Constants.blastView || Constants.whistlePitch) {
      return (
        <View style={{ position: 'relative', zIndex: 2 }}>
          <Input name="Landing Page Branding Color" style={{ marginBottom: 20 }} isColor={true} value={color} setValue={setColor} />
          <Label name="Landing Page Branding Image" />
          {profile && !newProfile ?
            <View style={{ alignItems: 'center' }}>
              {ProfileElement}
              <Button style={{ marginTop: 25 }} width={'100%'} text="Upload New Brand Image" theme={Theme.LINK_PRIMARY} onPress={onNewProfile} />
            </View> :
            <Uppyup plugins={[]} height={200} onDone={onCloseModal} onUploadComplete={onUploadProfileComplete} />
          }
          <Label style={{ marginTop: 10 }} name={`Send ${Constants.localizations.campaign}`} optional={true} />
          <CampaignDropdown style={{ position: 'relative', zIndex: 15 }} info={`Send ${Constants.localizations.campaign} to ${Constants.localizations.lead} once they submit.`} value={campaignId} setValue={setCampaignId} />
        </View>
      )
    }
    return null;
  }

  return (
    <WpModal key="modal-card" isVisible={!!isVisible}>
      <ModalBody title={"Edit Group Landing Page"} closeModal={onCloseModal}>
        <Input style={{ marginBottom: 10 }} name="Title" placeholder="Enter a title" value={title} setValue={setTitle} />
        <Input style={{ marginBottom: 10 }} name="Description" optional={true} multiline={true} placeholder="Enter a description" value={description} setValue={setDescription} />

        {renderBranding()}

        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25, position: 'relative', zIndex: 1 }}>
          <CopyToClipboard text={`${Constants.app_url}/g/${group?.code}`} onCopy={confirmCopy}>
            <Text style={{ marginLeft: 5, color: Constants.colors.blue, cursor: 'pointer' }}>{copyText}</Text>
          </CopyToClipboard>
          <Button key="btn-feature-save" text="Save" onPress={onSave} />
          <CopyToClipboard text={`${Constants.app_url}/g/${group?.code}`} onCopy={confirmCopy}>
            <Text style={{ marginLeft: 5, color: 'white' }}>{copyText}</Text>
          </CopyToClipboard>
        </View>
      </ModalBody>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  pitch: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 15,
    flexWrap: 'wrap',
    flex: 1,
    position: 'relative'
  },
  column: {
    flexWrap: 'wrap',
    flex: 1,
    minWidth: 300,
    position: 'relative'
  },
  logo: {
    width: 110,
    height: 110,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 55,
    overflow: "hidden"
  },
  profile: {
    width: 110,
    clipPath: 'circle()'
  }
});

const cssStyles = {
  player: {
    marginTop: 15,
    borderRadius: 15,
    padding: 0,
    overflow: 'hidden'
  }
}
