import { Text, View, TextInput, StyleSheet, Dimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import LeadTable from './LeadTable';
import LeadDropdown from './LeadDropdown';
import ModalFooter from './../ModalFooter';
import NewLeadModal from './NewLeadModal';
import { Label, Info } from './../Input';
import { Button, Theme } from './../Button';
import { ADD_LEADS_TO_CAMPAIGN, ADD_LEADS_TO_GROUP } from './../../graphql/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import { PageTitle } from '@whistlepitch/wp-components';



export default function ManageLeadsModal({ editing, title="Manage Leads", onClose, leads, campaignId, groupId, children } : { editing: boolean, title?: string, onClose: Function, leads?: string[], campaignId?: string, groupId?: string, children?: JSX.Element|JSX.Element[] }): JSX.Element {
  const [selectedLeads, setSelectedLeads] = useState<string[]>([])
  const [leadsToAdd, setLeadsToAdd] = useState<string[]>([])
  const [refresh, setRefresh] = useState<number>(0)
  const [addingLeads, setAddingLeads] = useState<boolean>(false)
  const [creatingLeads, setCreatingLeads] = useState<boolean>(false)

  const [addLeadsToCampaign, addLeadsToCampaignData] = useMutation(ADD_LEADS_TO_CAMPAIGN)
  const [addLeadsToGroup, addLeadsToGroupData] = useMutation(ADD_LEADS_TO_GROUP)

  useEffect(() => {
    onRefresh();
  }, [campaignId, groupId])

  useEffect(() => {
    onCloseAddLead();
    onCloseNewLead();
  }, [addLeadsToCampaignData.data, addLeadsToGroupData.data])

  const onRefresh = () => {
    // setRefresh(refresh + 1);
    onClose();
  }

  const onSelectLead = (value:string|string[]) => {
    if (value) {
      if (Array.isArray(value)) {
        setLeadsToAdd(value)
      } else {
        setLeadsToAdd([value])
      }
    }
  }

  const onAddLeads = () => {
    if (leadsToAdd.length) {
      if (campaignId) {
        addLeadsToCampaign({ variables: { campaignId: campaignId, leadIds: leadsToAdd }});
      } else if (groupId) {
        addLeadsToGroup({ variables: { groupId: groupId, leadIds: leadsToAdd }});
      }
      setLeadsToAdd([])
    }
  }

  const onCreated = (ids:string[]) => {
    onRefresh();
  }

  const onOpenAddLead = () => {
    setAddingLeads(true);
  }

  const onOpenNewLead = () => {
    setCreatingLeads(true);
  }

  const onCloseAddLead = () => {
    setAddingLeads(false);
    onRefresh();
  }

  const onCloseNewLead = () => {
    setCreatingLeads(false);
    onRefresh();
  }

  return (
    <View>
      <WpModal isVisible={editing}>
        <View style={{ flex: 1, alignItems: 'center' }}>
          { children }
          <ModalBody width={650} title="Manage leads" closeModal={onClose}>
            <LeadDropdown style={{ marginBottom: 20 }} onClose={onAddLeads} value={leadsToAdd} campaignId={campaignId} groupId={groupId} setValue={onSelectLead} onCreated={onCreated} />
            <Text style={{ marginBottom: 25, color: Constants.colors.blue, fontFamily: 'GothamMedium' }} onPress={onOpenNewLead}>+ Add new lead(s)</Text>
            {/*<PageTitle style={{ marginBottom: 15, fontFamily: 'GothamMedium' }} title={"Leads in this " + (!!campaignId ? "campaign" : "group")} type="DarkBlue" size="Small" />
            <LeadTable backgroundColor={'#FAFAFA'} removeable={true} refresh={refresh} leads={leads} campaignId={campaignId} groupId={groupId} selectedLeads={selectedLeads} setSelectedLeads={setSelectedLeads} />*/}
          </ModalBody>
        </View>
      </WpModal>
      <WpModal isVisible={addingLeads}>
        <View style={{ flex: 1, alignItems: 'center' }}>
          <ModalBody width={650} title="Add Leads" closeModal={onCloseAddLead}>
            <ModalFooter>
              <Button loading={addLeadsToGroupData?.loading} key="btn-add-leads-confirm" text="Confirm" onPress={onAddLeads} />
            </ModalFooter>
          </ModalBody>
        </View>
      </WpModal>
      <NewLeadModal campaignId={campaignId} groupId={groupId} onCreated={onCloseNewLead} isVisible={creatingLeads} onClose={onCloseNewLead} />
    </View>
  );
}

const styles = StyleSheet.create({
});

const cssStyles = {
}
