import { View, Text, StyleSheet, Dimensions, LayoutChangeEvent, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Ionicons } from '@expo/vector-icons';

import Constants from './../../constants';
import Spinner from './../Spinner';
import Header from './../Pitch/Header';
import Footer from './../Pitch/Footer';
import SlideMainContent from './../Pitch/SlideMainContent';
import SlideWidgets from './../Pitch/SlideWidgets';
import PitchControls from './../Pitch/Controls';
import { LeadContext } from './../../contexts/lead';
import { WpPitchSlide, WpSlideType } from './../../contexts/pitch';
import { GET_CAMPAIGN, GET_LANDING_PAGE, GET_LEADS } from './../../graphql/queries';
import { SUBMIT_LANDING_PAGE } from './../../graphql/campaign';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { WpFullCampaign, WpLandingPage } from '../../contexts/campaign';
import ReactPlayer from 'react-player';
import { FontAwesome } from '@expo/vector-icons';
import StripeCardElement from '../Shared/StripeCardElement';
import { Button, Input } from '@whistlepitch/wp-components';
import ReactPixel from 'react-facebook-pixel';
// import {Helmet} from "react-helmet";


export default function LandingPage({ landingPageCode }: { landingPageCode?: string }): JSX.Element {
  const { state, dispatch } = React.useContext(LeadContext);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [landingPage, setLandingPage] = useState<WpLandingPage>();
  const [showContinue, setShowContinue] = useState<boolean>(true);
  const [checkingOut, setCheckingOut] = useState<boolean>(false);
  const [stripeLoading, setStripeLoading] = useState<boolean>(false)
  const [width, setViewWidth] = useState<number>(0);
  const [stripeError, setStripeError] = useState<string>();
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [done, setDone] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [themeColor, setThemeColor] = useState<string>(Constants.colors.darkBlue);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [aspectRatioExplainer, setAspectRatioExplainer] = useState(0)
  const [aspectRatioProfile, setAspectRatioProfile] = useState(0)
  const [allowSelfOnboarding, setAllowSelfOnboarding] = useState<boolean>(false);

  const { data, loading } = useQuery(GET_LANDING_PAGE, {
    fetchPolicy: "no-cache",
    variables: {
      landingPageCode: landingPageCode
    }
  });

  const [submitLandingPage, submitLandingPageData] = useMutation(SUBMIT_LANDING_PAGE, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (!landingPage || !landingPage.image) {
      return;
    }

    let isValid = true;
    Image.getSize(landingPage.image, (width, height) => {
      if (isValid) {
        setAspectRatioProfile(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [landingPage?.image]);

  useEffect(() => {
    if (data && data?.landingPage?.landingPage) {
      setLandingPage(data.landingPage.landingPage)
      setThemeColor(data.landingPage.landingPage.color ? data.landingPage.landingPage.color : Constants.colors.darkBlue)
      setAllowSelfOnboarding(data.landingPage?.organization?.allowSelfOnboarding ?? false)
    }
  }, [data])

  // useEffect(() => {
  //   if (landingPageCode === 'KaX6hY' || landingPageCode === '5Rm7d8') {
  //     ReactPixel.track('ViewContent', {
  //       content_ids: [landingPageCode],
  //       content_name: 'Buried Briefcase Landing Page',
  //       content_category: 'Landing Page',
  //       content_type: 'product',
  //       contents: [{id: landingPageCode, quantity: 1}],
  //       currency: 'USD',
  //       value: 5
  //     });
  //   }
  // }, [])

  useEffect(() => {
    if (submitLandingPageData.data) {
      if (submitLandingPageData.data.submitLandingPage.success) {
        setDone(true)
      } else if (submitLandingPageData.data.submitLandingPage.errors.length) {
        setError(submitLandingPageData.data.submitLandingPage.errors[0])
      } else {
        setError('An unknown error occurred. Please refresh the page and try again.')
      }
    }
  }, [submitLandingPageData.data])

  useEffect(() => {
    if (state.lead?.widgets) {
      let vindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'opt-in')
      let pindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'phone')
      let hindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'hipaa')
      let dindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'appointment')
      // let oindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'dropdown')

      if (vindex > -1 && pindex > -1 && state.lead.widgets[vindex].values && state.lead.widgets[vindex].values.indexOf('Verified') !== -1 && state.lead.widgets[pindex].values.length && state.lead.widgets[pindex].values[0].length >= 10) {
        // if (Constants.tattooCare && (dindex > -1 && state.lead.widgets[dindex].values.length && state.lead.widgets[dindex].values[0]) && (oindex > -1 && state.lead.widgets[oindex].values.length && state.lead.widgets[oindex].values[0])) {
        if (Constants.tattooCare && (dindex > -1 && state.lead.widgets[dindex].values.length && state.lead.widgets[dindex].values[0])) {
          setEnabled(true)
          return;
        }
        if (Constants.oppText && (allowSelfOnboarding || (hindex > -1 && state.lead.widgets[hindex].values && state.lead.widgets[hindex].values.indexOf('Verified') !== -1))) {
          setEnabled(true)
          return;
        }
      }
    }
    setEnabled(false);
  }, [state.lead?.widgets])

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const renderInactive = () => {
    return (
      <View style={styles.inactive}>
        <Text style={styles.inactiveText}>The content you are looking for is no longer active or has been removed.</Text>
      </View>
    )
  }

  const onSubmit = () => {
    submitLandingPage({
      variables: {
        landingPageCode: landingPageCode,
        widgets: state.lead?.widgets ?? []
      }
    })
  }

  const renderLandingPage = () => {
    if (landingPage) {
      if (done) {
        return (
          <View style={styles.slideWrapper}>
            <SlideMainContent
              title={"Submitted!"}
              landingPageComplete={true}
              color={themeColor}
            />
          </View>
        )
      } else if (!landingPage) {
        return <Spinner />
      }

      let structure = [
        { 'id': 'name', 'label': 'Your Full Name', 'type': 'SHORT' },
        { 'id': 'phone', 'label': 'Your Phone Number', 'type': 'SHORT' }
      ]
      if (Constants.tattooCare) {
        structure.push({ 'id': 'appointment', 'label': 'Appointment date', 'type': 'DATE' })
        if (landingPage?.dropdownOptions) {
          let labels = landingPage.dropdownOptions.map((option) => {
            return option.label
          })
          // structure.push({'id': 'dropdown', 'label': 'Where will the tattoo be drawn?', 'type': 'DROPDOWN', 'labels': labels})
        }
      }

      if (allowSelfOnboarding) {
        structure.push({ 'id': 'opt-in', 'label': `I have received the ${Constants.localizations.lead}'s consent to receive communications via OppText.`, 'type': 'VERIFY' })
      } else {
        structure.push({ 'id': 'opt-in', 'label': Constants.localizations.optIn, 'type': 'VERIFY' })
        if (Constants.oppText) {
          structure.push({ 'id': 'hipaa', 'label': 'I hereby authorize use or disclosure of my name, phone (protected health information) to OppText for the use of sending me timely materials related to my care.', 'type': 'VERIFY' })
        }
      }

      return (
        <View accessibilityLabel={`landing-page-${landingPageCode}`} style={styles.slideWrapper}>
          {landingPage?.title ?
            <SlideMainContent
              title={landingPage?.title ?? ''}
              description={landingPage?.description ?? ''}
              color={themeColor}
            /> : null}
          <SlideWidgets color={themeColor} widgetStructure={structure} leadWidgets={state.lead?.widgets ?? []} />
          <Button style={{ width: 150, marginLeft: 'auto', marginRight: 'auto', marginTop: 15, backgroundColor: themeColor }} type="Primary" size="Default" shape="Pill" onClick={onSubmit} disabled={submitLandingPageData.loading || !enabled}>
            {submitLandingPageData.loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Submit</Text>}
          </Button>
          {!!error ? <Text style={styles.error}>{error}</Text> : null}
        </View>
      )
    }
    return <Spinner style={{ marginTop: 55 }} />;
  }

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, alignItems: 'center' }} onLayout={onLayout}>
        <View style={{ width: '100%', backgroundColor: themeColor ? themeColor : Constants.colors.darkBlue, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative', zIndex: 5, marginBottom: 55, height: 150 }}>
          <View style={styles.logo}>
            {landingPage?.image ?
              <Image style={[styles.brand, { aspectRatio: 1 }]} source={{ uri: landingPage?.image }} /> :
              <Text style={{ padding: 2, fontFamily: 'GothamBold', color: themeColor ? themeColor : Constants.colors.blue }}> </Text>
            }
          </View>
        </View>
        <View style={[styles.body, { width: '100%', maxWidth: 500, marginBottom: 45 }]}>
          {renderLandingPage()}
        </View>
      </View>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    position: 'relative',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  error: {
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
    color: Constants.colors.red,
    fontFamily: 'GothamMedium'
  },
  slideWrapper: {
    flex: 1,
    position: 'relative'
  },
  inactive: {
    margin: 25,
    marginBottom: 0,
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20
  },
  inactiveText: {
    color: Constants.colors.secondary,
    textAlign: 'center'
  },
  body: {
    flex: 1,
    position: 'relative',
    zIndex: 1
  },
  // logo: {
  //   overflow: "hidden",
  //   position: 'relative',
  //   top: '50%',
  //   width: 110,
  //   height: 110,
  //   backgroundColor: '#FFF',
  //   border: '1px solid #DADADA',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   borderRadius: 55,
  //   zIndex: 10,
  //   padding: 15
  // },
  brand: {
    width: 110,
    clipPath: 'circle()'
  },
  logo: {
    position: 'relative',
    top: "50%",
    width: 110,
    height: 110,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 55,
    overflow: "hidden"
  }
})

const cssStyles = {
  player: {
    padding: 0,
    overflow: 'hidden',
    maxWidth: 600
  }
}
