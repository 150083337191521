import { Text, View, ActivityIndicator, StyleSheet, Image } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import Toast from 'react-native-toast-message'
import NewLeadModal from '../components/Leads/NewLeadModal';
import NewUserModal from '../components/User/NewUserModal';
import { GET_USER } from '../graphql/queries';
import { CREATE_CAMPAIGN } from '../graphql/campaign';
import { CREATE_PITCH } from '../graphql/pitch';
import { LOGOUT_USER } from '../graphql/user';
import { useNavigate } from '../react-router';
import Constants from '../constants';
import { Access, UserContext, WpUserAction } from '../contexts/user';
import { Sidebar } from '@whistlepitch/wp-components';
import UserDetailsModal from './User/UserDetailsModal';

interface OrgOption {
  id: string,
  label: string,
  value: string
}

interface Option {
  id: string,
  label: string,
  url: string,
  icon: string
}

export default function Navbar({ active, campaignId, groupId, onRefresh } : { active: string, campaignId?: string, groupId?: string, onRefresh?: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const { data, loading, error } = useQuery(GET_USER, {
    fetchPolicy: "no-cache"
  });
  const [logoutUser, logoutUserData] = useLazyQuery(LOGOUT_USER, {
    fetchPolicy: "no-cache"
  });

  const [newCampaign, newCampaignData] = useMutation(CREATE_CAMPAIGN);
  const [newPitch, newPitchData] = useMutation(CREATE_PITCH);

  const [structure, setStructure] = useState<Option[]>([]);
  const [showNewLead, setShowNewLead] = useState<boolean>(false);
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [invitingUser, setInvitingUser] = useState<boolean>(false);
  const [limitedAccess, setLimitedAccess] = useState<boolean>(false);
  const [orgOptions, setOrgOptions] = useState<OrgOption[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      if (data.user.success) {
        dispatch({ type: WpUserAction.REFRESH_USER, user: data?.user?.user})
      } else {
        navigate('/login')
      }
    }
  }, [data])

  useEffect(() => {
    if (state.user?.organization.access) {
      setLimitedAccess(state.user?.organization.access === Access.LIMITED)
    }
  }, [state.user?.organization])

  useEffect(() => {
    let els = document.querySelector(".WP_BrandLogo")
    if (els) {
      els.outerHTML = "";
    }

    // console.log("els", els, els[0]);
    // for (var i = 0; i < els.length; i++) {
    //   console.log(els[i].id); //second console output
    //   els[i].outerHTML = "";
    // }
    //
    // Array.from(document.getElementsByClassName("WP_BrandLogo")).forEach(function(item) {
    //  console.log("item", item);
    // });
  }, [Constants.blastView, active, campaignId])

  useEffect(() => {
    if (logoutUserData.data) {
      localStorage.removeItem('token');
      dispatch({ type: WpUserAction.LOGOUT_USER, user: data?.user?.user})
    }
  }, [logoutUserData.data])

  useEffect(() => {
    if (data && !state.user) {
      navigate('/login')
    } else if (data && state.user) {
      if ([Access.ADMIN, Access.OWNER].indexOf(state.user.organization.access) > -1) {
        setStructure([...sidebarStructure, ...adminStructure])
      } else {
        setStructure(sidebarStructure)
      }
    }
  }, [state.user])

  useEffect(() => {
    if (state.user?.organizations) {
      let options = [];
      for (let i = 0; i < state.user.organizations.length; i++) {
        let org = state.user.organizations[i]
        options.push({
          id: org.id,
          label: org.name,
          value: org.name
        })
      }
      setOrgOptions(options)
    }
  }, [state.user?.organizations])

  useEffect(() => {
    if (newCampaignData.data?.createCampaign?.campaign) {
      navigate("/campaign/" + newCampaignData.data.createCampaign?.campaign.id + '?' + Math.floor(1000 + Math.random() * 9000))
    }
  }, [newCampaignData.data])

  useEffect(() => {
    if (newPitchData.data?.createPitch?.pitch) {
      console.log("Would open pitch editor")
    }
  }, [newPitchData.data])

  const onCreateCampaign = () => {
    newCampaign({variables: {name: "New Campaign"}})
  }

  const onCreateLead = () => {
    setShowNewLead(true);
  }

  const onCloseNewLead = () => {
    setShowNewLead(false);
  }

  const onAddNewLead = () => {
    Toast.show({
      type: 'success',
      text1: 'Leads Added',
      text2: `Added to your ${campaignId ? 'campaign' : 'group'}`
    });
    setShowNewLead(false);
  }

  const onCloseNewUser = () => {
    setInvitingUser(false);
  }

  const openUserDetails = () => {
    setShowUserDetails(true);
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const onCreatedNewUser = (emails: {email: string}[]) => {
    let email_text = ''
    if (emails.length === 1) {
      email_text = emails[0].email;
    } else if (emails.length === 2) {
      email_text = `${emails[0].email} and 1 other`
    } else if (emails.length > 2) {
      email_text = `${emails[0].email} and ${emails.length-1} others`
    }

    Toast.show({
      type: 'success',
      text1: 'User Invitations',
      text2: `An invite has been sent to ${email_text}`
    });
    setInvitingUser(false);
  }

  const onSidebarNavigate = (url: string) => {
    if (url === '/library') {
      navigate('/library');
    } else if (url === '/dashboard') {
      navigate("/dashboard")
    } else if (url === '/groups') {
      navigate("/groups")
    } else if (url === '/campaigns') {
      navigate("/campaigns")
    } else if (url === '/users') {
      navigate("/users")
    } else if (url === '/account') {
      openUserDetails()
    }
    return url
  }

  const onSidebarAction = (e: any) => {
    if (e === 'add-leads') {
      onCreateLead();
    } else if (e === 'new-campaign'){
      onCreateCampaign()
    } else if (e === 'USER'){
      setInvitingUser(true)
    }
  }

  const onLogout = () => {
    logoutUser();
  }

  if (!state.user) {
    return <View></View>
  }

  return (
    <View style={{ backgroundColor: Constants.colors.backgroundLight }}>
      <NewLeadModal isVisible={showNewLead} onClose={onCloseNewLead} onCreated={onCloseNewLead} onAdded={onAddNewLead} />
      <NewUserModal isVisible={invitingUser} onClose={onCloseNewUser} onCreated={onCreatedNewUser} />
      <UserDetailsModal isVisible={showUserDetails} user={state.user} onClose={closeUserDetails} />

      <Sidebar
        brand={Constants.brand}
        actionTwo={!Constants.blastView && !limitedAccess}
        actionOne={!limitedAccess}
        actionOneText={Constants.localizations.actionOneText}
        defaultLabel={state?.user?.organization.name}
        structure={limitedAccess ? [{
          "id": "sidebar-link-1",
          "label": "Overview",
          "url": "/dashboard",
          "icon": "M21 20h-1V5a1 1 0 0 0-2 0v15h-2V9a1 1 0 0 0-2 0v11h-2v-7a1 1 0 0 0-2 0v7H8v-3a1 1 0 1 0-2 0v3H4V3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2Z"
        }] : sidebarStructure}
        setRoute={active}
        onChange={onSidebarNavigate}
        onAction={onSidebarAction}
        onLogout={onLogout}
        version={Constants.version}
      />
    </View>
  );
}

const sidebarStructure = [
  {
    "id": "sidebar-link-1",
    "label": "Overview",
    "url": "/dashboard",
    "icon": "M21 20h-1V5a1 1 0 0 0-2 0v15h-2V9a1 1 0 0 0-2 0v11h-2v-7a1 1 0 0 0-2 0v7H8v-3a1 1 0 1 0-2 0v3H4V3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2Z"
  },
  {
    "id": "sidebar-link-2",
    "label": Constants.blastView ? "Blasts" : "Campaigns",
    "url": "/campaigns",
    "icon": "M19.991 2.002a1 1 0 0 0-1 1v.637a9.036 9.036 0 0 1-7 3.363h-6a3.003 3.003 0 0 0-3 3v2a3.003 3.003 0 0 0 3 3h.484l-2.403 5.606a.999.999 0 0 0 .92 1.394h4a.999.999 0 0 0 .918-.607l2.724-6.355a9.026 9.026 0 0 1 6.357 3.325v.637a1 1 0 0 0 2 0v-16a.998.998 0 0 0-1-1Zm-14 11a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1v4h-1Zm2.341 7H6.508l2.142-5h1.825l-2.143 5Zm10.66-4.478a11.052 11.052 0 0 0-7-2.522h-3v-4h3a11.053 11.053 0 0 0 7-2.522v9.044Z"
  },
  // {
  //   "id": "sidebar-link-3",
  //   "label": "Library",
  //   "url": "/library",
  //   "icon": "m22.47 18.82-1-3.86-3.15-11.59a1 1 0 0 0-1.22-.71l-3.87 1a1 1 0 0 0-.73-.33h-10a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-8l2.2 8.22a1 1 0 0 0 1 .74c.086.01.174.01.26 0L21.79 20a.999.999 0 0 0 .61-.47 1.05 1.05 0 0 0 .07-.71Zm-16 .55h-3v-2h3v2Zm0-4h-3v-6h3v6Zm0-8h-3v-2h3v2Zm5 12h-3v-2h3v2Zm0-4h-3v-6h3v6Zm0-8h-3v-2h3v2Zm2.25-1.74 2.9-.78.52 1.93-2.9.78-.52-1.93Zm2.59 9.66-1.55-5.8 2.9-.78 1.55 5.8-2.9.78Zm1 3.86-.52-1.93 2.9-.78.52 1.93-2.9.78Z"
  // },
  {
    "id": "sidebar-link-4",
    "label": "Groups",
    "url": "/groups",
    "icon": "M12.3 12.22A4.92 4.92 0 0 0 14 8.5a5 5 0 0 0-10 0 4.92 4.92 0 0 0 1.7 3.72A8 8 0 0 0 1 19.5a1 1 0 1 0 2 0 6 6 0 1 1 12 0 1 1 0 0 0 2 0 8 8 0 0 0-4.7-7.28ZM9 11.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm9.74.32A5 5 0 0 0 15 3.5a1 1 0 1 0 0 2 3 3 0 0 1 3 3 3 3 0 0 1-1.5 2.59 1 1 0 0 0-.05 1.7l.39.26.13.07a7 7 0 0 1 4 6.38 1 1 0 0 0 2 0 9 9 0 0 0-4.23-7.68Z"
  },
  // {
  //   "id": "sidebar-link-5",
  //   "label": "Users",
  //   "url": "/users",
  //   "icon": "M12 2a10 10 0 0 0-7.35 16.76 10.001 10.001 0 0 0 14.7 0A9.999 9.999 0 0 0 12 2Zm0 18a8 8 0 0 1-5.55-2.25 6 6 0 0 1 11.1 0A8 8 0 0 1 12 20Zm-2-10a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm8.91 6A8 8 0 0 0 15 12.62a4 4 0 1 0-6 0A8 8 0 0 0 5.09 16 7.92 7.92 0 0 1 4 12a8 8 0 1 1 16 0 7.92 7.92 0 0 1-1.09 4Z"
  // },
  // {
  //   "id": "sidebar-link-6",
  //   "label": "Account",
  //   "url": "/account",
  //   "icon": "M12 2a10 10 0 0 0-7.35 16.76 10.001 10.001 0 0 0 14.7 0A9.999 9.999 0 0 0 12 2Zm0 18a8 8 0 0 1-5.55-2.25 6 6 0 0 1 11.1 0A8 8 0 0 1 12 20Zm-2-10a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm8.91 6A8 8 0 0 0 15 12.62a4 4 0 1 0-6 0A8 8 0 0 0 5.09 16 7.92 7.92 0 0 1 4 12a8 8 0 1 1 16 0 7.92 7.92 0 0 1-1.09 4Z",
  //   "modal": true
  // }
]

const adminStructure = [
  // {
  //   "id": "sidebar-link-6",
  //   "label": "Account",
  //   "url": "/account",
  //   "icon": "M12 2a10 10 0 0 0-7.35 16.76 10.001 10.001 0 0 0 14.7 0A9.999 9.999 0 0 0 12 2Zm0 18a8 8 0 0 1-5.55-2.25 6 6 0 0 1 11.1 0A8 8 0 0 1 12 20Zm-2-10a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm8.91 6A8 8 0 0 0 15 12.62a4 4 0 1 0-6 0A8 8 0 0 0 5.09 16 7.92 7.92 0 0 1 4 12a8 8 0 1 1 16 0 7.92 7.92 0 0 1-1.09 4Z",
  //   "modal": true
  // }
]

const styles = StyleSheet.create({
  header: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  button: {
    marginBottom: 10
  }
});
