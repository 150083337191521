import { View, Text, StyleSheet, Image, LayoutChangeEvent } from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import Toast from 'react-native-toast-message';

import Constants from '../../constants';
import { UserContext } from '../../contexts/user';
import CampaignOverview from '../../components/CampaignOverview';
import LeadTable from '../../components/Leads/LeadTable';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import WpModal from '../../components/WpModal';
import ModalFooter from '../../components/ModalFooter';
import ModalBody from '../../components/ModalBody';
import { Button, Brand, PageTitle, Input, BrandLogo, BrandHeading } from '@whistlepitch/wp-components';
import Wave from 'react-wavify'
import ReactPlayer from 'react-player'
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { useNavigate } from '../../react-router';
import { TRY_NOW } from '../../graphql/mutations';
import { useLazyQuery, useMutation } from "@apollo/client";


enum IconType {
  SETTINGS = "SETTINGS"
}

interface SummaryData {
  label: string
  value: number
  tooltip: string
}

function WpIcon({ type=IconType.SETTINGS } : { type?: IconType }): JSX.Element {
  if (type === IconType.SETTINGS) {
    return <Image style={{ width: 26, height: 26 }} source={require('./../../assets/fi_settings.png')} />
  } else {
    return <View></View>
  }
}


export default function WhistlePitchLandingPage(): JSX.Element {
  const player = useRef(null);

  const [viewWidth, setViewWidth] = useState<number>(0);
  const [videoWidth, setVideoWidth] = useState<number>(0);
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [showTryNow, setShowTryNow] = useState<boolean>(false);

  const navigate = useNavigate();

  const [tryNow, { data, loading }] = useMutation(TRY_NOW, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (__DEV__) {
      navigate('/dashboard')
    } else {
      window.location.href = 'https://home.opptext.com/'
    }
  }, [])

  const onTryNow = () => {
    tryNow({ variables: { name: name, phone: phone }})
  }

  const openTryNow = () => {
    setShowTryNow(true);
  }

  const closeTryNow = () => {
    setShowTryNow(false);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var {x, y, width, height} = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onVideoLayout = (event: LayoutChangeEvent) => {
    var {x, y, width, height} = event.nativeEvent.layout;

    setVideoWidth(width)
  }

  const onLogin = () => {
    // window.top.location.href = `https://www.whistlepitch.com/dashboard`;
    navigate('/dashboard')
  }

  const changeName = (e: any) => {
    setName(e.target.value)
  }

  const changePhone = (e: any) => {
    setPhone(e.target.value)
  }

  return (
    <View style={{ flex: 1, backgroundColor: '#FFF', position: 'relative', paddingTop: 40 }} onLayout={onLayout}>
      <Spinner />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight,
  },
  login: {
    color: Constants.colors.darkBlue,
    marginHorizontal: 25,
    fontFamily: 'GothamMedium',
  },
  navbar: {
    backgroundColor: '#FFF',
    zIndex: 10,
    position: 'absolute',
    top: 0,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: 20,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 14,
    shadowColor: '#000',
  },
  subheading: {
    fontFamily: 'GothamBold',
    color: Constants.colors.mediumBlue,
    textAlign: 'center',
    fontSize: 20
  },
  featureText: {
    fontFamily: 'GothamMedium',
    width: 220,
    fontSize: 18,
    color: Constants.colors.darkBlue,
    marginLeft: 17,
    paddingVertical: 25
  },
  howWorksImg: {
    width: 65,
    height: 65
  },
  howWorksText: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Gotham',
    color: Constants.colors.darkBlue,
    width: 250,
    textAlign: 'center'
  },
  useCasesText: {
    fontSize: 13,
    lineHeight: 19,
    fontFamily: 'Gotham',
    color: Constants.colors.dark,
    width: 400
  },
  customersText: {
    fontSize: 13,
    lineHeight: 19,
    fontFamily: 'Gotham',
    color: Constants.colors.dark,
    width: 400,
    textAlign: 'center'
  },
  playerWrapper: {
    position: 'relative',
    // flex: 1,
    overflow: 'hidden'
  }
})

const cssStyles = {
  svg: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  container: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden'
  },
  howWorksTitle: {
    fontFamily: 'GothamBold',
    color: Constants.colors.darkBlue,
    fontSize: 16,
    marginTop: 15,
    marginBottom: 10
  },
  useCasesTitle: {
    fontFamily: 'GothamBold',
    color: Constants.colors.blue,
    fontSize: 20,
    marginTop: 15,
    marginBottom: 10,
    width: 400,
    lineHeight: 1.2
  },
  player: {
    borderRadius: 10,
    padding: 0,
    overflow: 'hidden',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  }
}
