import { gql } from "@apollo/client";

export const TRY_NOW = gql`
  mutation tryNow($name: String, $phone: String!) {
    tryNow(name: $name, phone: $phone) {
      success
      errors
    }
  }
`;
