import React, { useCallback, useEffect, useRef, useState, FC, useMemo } from 'react';
import { BlockNoteEditor, DefaultBlockSchema, BlockSchema } from "@blocknote/core";
import { DefaultFormattingToolbar } from "@blocknote/react";
import "@blocknote/core/style.css";
import Tippy from "@tippyjs/react";
import { sticky } from "tippy.js";


export type FormattingToolbarProps<
  BSchema extends BlockSchema = DefaultBlockSchema
> = {
  editor: BlockNoteEditor<BSchema>;
};

export const FormattingToolbarPositioner = <
  BSchema extends BlockSchema = DefaultBlockSchema
>(props: {
  editor: BlockNoteEditor<BSchema>;
  formattingToolbar?: FC<FormattingToolbarProps<BSchema>>;
  parentId?: string;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [parent, setParent] = useState<HTMLElement | null>(null);

  const referencePos = useRef<DOMRect>();

  useEffect(() => {
    return props.editor.formattingToolbar.onUpdate((state) => {
      setShow(true);

      referencePos.current = state.referencePos;
    });
  }, [props.editor]);

  useEffect(() => {
    if (props.parentId && !parent) {
      const parentElement = document.getElementById(props.parentId);
      setParent(parentElement)
    }
  });
  
  const getReferenceClientRect = useMemo(
    () => {
      if (!referencePos) {
        return undefined;
      }
      return () => referencePos.current!;
    },
    [referencePos.current] // eslint-disable-line
  );

  const formattingToolbarElement = useMemo(() => {
    const FormattingToolbar =
      props.formattingToolbar || DefaultFormattingToolbar;

    return <FormattingToolbar editor={props.editor} />;
  }, [props.editor, props.formattingToolbar]);

  if (!parent) {
    return null;
  }
  return (
    <Tippy
      onClickOutside={() => setShow(false)}
      appendTo={parent}
      content={formattingToolbarElement}
      getReferenceClientRect={getReferenceClientRect}
      interactive={true}
      visible={show}
      animation={"fade"}
      placement={"top-start"}
      sticky={true}
      plugins={tippyPlugins}
    />
  );
};

const tippyPlugins = [sticky];