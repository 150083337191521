import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import { Button } from './Button';
import { CheckCircleIcon } from '@heroicons/react/solid'

interface Option {
  id: string,
  label: string,
  info?: string
}

export default function RadioOptions({ options, current, onChange } : { options:Option[], current:string, onChange:Function }): JSX.Element {
  const [hovering, setHovering] = useState<number>();

  const onHover = (what:number) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(-1);
  }

  const renderOption = (option:Option, index:number, list:Option[]) => {
    return (
      <View key={`survey-option-${index}`} style={[styles.option, { opacity: hovering === index ? 0.7 : 1 }]} onClick={() => onChange(option.id)} onMouseEnter={() => onHover(index)} onMouseLeave={onHoverOut}>
        { option.id === current ?
          <CheckCircleIcon style={cssStyles.icon} /> :
          <View style={[styles.emptyCircle, { backgroundColor: hovering === index ? Constants.colors.primaryLight : '#FFF' }]}></View>
        }
        <Text style={styles.label}>{option.label}</Text>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      { options.map(renderOption) }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    gap: 10,
    marginBottom: 20
  },
  tooltip: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#FFF'
  },
  option: {
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    borderRadius: 10,
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1
  },
  emptyCircle: {
    border: '2px solid ' + Constants.colors.primary,
    width: 19,
    height: 19,
    margin: 3,
    marginRight: 2,
    borderRadius: 10,
    backgroundColor: 'white',
    cursor: 'pointer'
  },
  label: {
    paddingLeft: 5,
    color: Constants.colors.dark
  }
});

const cssStyles = {
  icon: {
    width: 24,
    height: 24,
    color: Constants.colors.primary,
    cursor: 'pointer'
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
