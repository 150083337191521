import { Text, View, Pressable, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { EyeIcon, ClockIcon, PuzzleIcon, ReceiptRefundIcon, LockClosedIcon, CalendarIcon } from '@heroicons/react/outline';
import Constants from './../constants'
import { WpWorkflowTriggerType } from './../contexts/campaign';
import { IconButton } from '@whistlepitch/wp-components';


export default function WorkflowTriggerOptions({ active, onPress }: { active: WpWorkflowTriggerType, onPress: Function }): JSX.Element {
  const [hovering, setHovering] = useState<WpWorkflowTriggerType | null>(null);

  const onHover = (what: WpWorkflowTriggerType) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(null);
  }

  const getColor = (type: WpWorkflowTriggerType) => {
    return {
      VIEWED: Constants.colors.primary,
      TIME: Constants.colors.warning,
      WIDGET: Constants.colors.success,
      REDIRECT: Constants.colors.accent,
      CALENDAR: Constants.colors.primaryMedium,
      UNOPENED: Constants.colors.danger,
    }[type]
  }

  const getExtraStyles = (type: WpWorkflowTriggerType) => {
    let color = getColor(type);
    let opacity = hovering === type ? 0.6 : 1;

    if (active !== type) {
      return {
        opacity: opacity,
        backgroundColor: hovering === type ? color : '#FFF',
        color: hovering !== type ? color : '#FFF',
        border: '1px solid ' + color
      }
    } else {
      return {
        opacity: opacity,
        backgroundColor: color,
        color: '#FFF',
        border: '1px solid ' + color
      }
    }
  }

  return (
    <View style={styles.container}>
      <IconButton style={{ flex: 1 }} label="View" type={active === WpWorkflowTriggerType.VIEWED ? "Blue" : "AccentBlue"} onClick={() => onPress(WpWorkflowTriggerType.VIEWED)}>
        <EyeIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ flex: 1 }} label="Widget" type={active === WpWorkflowTriggerType.WIDGET ? "Green" : "AccentGreen"} onClick={() => onPress(WpWorkflowTriggerType.WIDGET)}>
        <PuzzleIcon style={cssStyles.icon} />
      </IconButton>
      {/* <IconButton style={{ flex: 1 }} label="Calendar" type={active === WpWorkflowTriggerType.CALENDAR ? "Cyan" : "AccentCyan"} onClick={() => onPress(WpWorkflowTriggerType.CALENDAR)}>
        <CalendarIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ flex: 1 }} label="Redirect" type={active === WpWorkflowTriggerType.REDIRECT ? "Purple" : "AccentPurple"} onClick={() => onPress(WpWorkflowTriggerType.REDIRECT)}>
        <ReceiptRefundIcon style={cssStyles.icon} />
      </IconButton> */}
      <IconButton style={{ flex: 1 }} label="Unopened" type={active === WpWorkflowTriggerType.UNOPENED ? "Red" : "AccentRed"} onClick={() => onPress(WpWorkflowTriggerType.UNOPENED)}>
        <LockClosedIcon style={cssStyles.icon} />
      </IconButton>
    </View>
  );
}

const styles = StyleSheet.create({
  separator: {
    marginHorizontal: 10,
    borderRightColor: Constants.colors.info,
    borderRightWidth: 1,
    width: 2,
    height: '90%'
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    marginBottom: 15
  },
  iconWrapper: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 8,
    textAlign: 'center'
  },
  text: {
    fontWeight: 'bold',
    fontSize: 10
  }
});

const cssStyles = {
  icon: {
    paddingBottom: 5,
    height: 20,
    minWidth: 50,
  }
}
