import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import Dropdown, { Item } from './Dropdown';
import { Label, Info } from './Input';
import { GET_CAMPAIGNS } from './../graphql/queries';
import { useQuery } from "@apollo/client";

export default function CampaignDropdown({ label, info, value, setValue, instantOnly=false, optional=true, style={} } : { label?:string, info?:string, value:string|undefined, instantOnly?:boolean, optional?:boolean, setValue: (value?: string) => void, style?:object }): JSX.Element {
  const [campaignItems, setCampaignItems] = useState<Item[]>([]);

  const { data } = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: "no-cache",
    variables: { includeFeatures: false, includeStats: false }
  })

  useEffect(() => {
    if (data?.campaigns?.campaigns) {
      let campaigns = []
      for (let i = 0; i < data.campaigns.campaigns.length; i++) {
        let c = data.campaigns.campaigns[i];
        if (instantOnly) {
          if (!c.structure.before.length && !c.structure.appointment.length) {
            campaigns.push({value: c.id, label: c.name})
          }
        } else {
          campaigns.push({value: c.id, label: c.name})
        }
      }
      setCampaignItems(campaigns)
    }
  }, [data])

  return (
    <View style={[{ position: 'relative' }, style]}>
      { label ? <Label name={label} optional={optional} /> : null }
      { info ? <Info info={info} /> : null }
      <Dropdown
        key={`dropdown-campaigns`}
        style={{ position: 'relative', zIndex: 1 }}
        placeholder={`Select a ${Constants.localizations.campaign}`}
        items={campaignItems}
        value={value}
        onChange={setValue}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
