import { Text, View, StyleSheet, Pressable, ActivityIndicator, Dimensions } from 'react-native';
import React, { useEffect, useState } from 'react';
import { CampaignContext, WpCampaignFeature, WpPitch, WpStructureType, WpCampaignWorkflow, WpFeatureType, WpCampaignAction, WpWorkflowTriggerType, WpWorkflowTrigger, WpWorkflowAction, WpWorkflowActionType } from '../../contexts/campaign';
import { UserContext, WpModelSummary } from '../../contexts/user';
import { PitchSlideContext, WpRedirectType } from '../../contexts/pitch';
import { UPDATE_CAMPAIGN_FEATURE, DELETE_CAMPAIGN_FEATURE, CREATE_CAMPAIGN_WORKFLOW, DELETE_CAMPAIGN_WORKFLOW, DELETE_CAMPAIGN_TRIGGER, DELETE_CAMPAIGN_ACTION, CREATE_CAMPAIGN_TRIGGER, CREATE_CAMPAIGN_ACTION, CREATE_CAMPAIGN_FEATURE } from '../../graphql/campaign';
import { useMutation } from "@apollo/client";
import Constants from "../../constants"
import CampaignFeatureCreateButton from './../CampaignFeatureCreateButton';
import PitchSlideCreateButton from './../PitchSlideCreateButton';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import MessageEditor from './../MessageEditor';
import PitchEditor from './../PitchEditor';
import { Button, Theme } from './../Button';
import { TrashIcon } from '@heroicons/react/outline';
import { PlusCircleIcon } from '@heroicons/react/solid';
import PitchSelector from './../PitchSelector';
import WorkflowTriggerEditor from './../WorkflowTriggerEditor';
import WorkflowActionEditor from './../WorkflowActionEditor';
import CampaignFeatureDelay from './../CampaignFeatureDelay';
import PitchSlideEditor from '../Pitch/PitchSlideEditor';
import GraphCreateButton from '../GraphCreateButton';
import { CREATE_PITCH_SLIDE, DELETE_PITCH_SLIDE, UPDATE_PITCH } from '../../graphql/pitch';
import Input from '../Input';
import { MaterialCommunityIcons, Entypo, FontAwesome5 } from '@expo/vector-icons';
import { SimpleLineIcons } from '@expo/vector-icons';
import Hoverable from '../Shared/Hoverable';


const enum HOVERABLES {
  TRASH = "TRASH",
  BODY = "BODY",
  REDIRECT = "REDIRECT"
}

const COLORS = [
  [Constants.colors.blue, Constants.colors.lightBlue],
  [Constants.colors.red, Constants.colors.lightRed],
  [Constants.colors.purple, Constants.colors.lightPurple],
  [Constants.colors.green, Constants.colors.lightGreen],
  [Constants.colors.yellow, Constants.colors.lightYellow],
]

const window = Dimensions.get('window');

export default function CampaignFeature({ componentKey, index, featureId, structureType, isAppointment, passedPitch, isLast, isFirst, setPitchId, onSave, onDelete, onSelectSlide }: { componentKey?: string, index?: number, featureId?: string, structureType: WpStructureType, isAppointment?: boolean, passedPitch?: boolean, isLast?: boolean, isFirst?: boolean, setPitchId?: (id: string) => void, onSave: () => void, onDelete?: () => void, onSelectSlide?: (id?: string, isNew?: boolean) => void }): JSX.Element {
  const campaignContext = React.useContext(CampaignContext);
  const pitchSlideContext = React.useContext(PitchSlideContext);
  const userContext = React.useContext(UserContext);

  const [dimensions, setDimensions] = useState(window);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  const [feature, setFeature] = useState<WpCampaignFeature | null>(null);
  const [workflow, setWorkflow] = useState<WpCampaignWorkflow | null>(null);
  const [trigger, setTrigger] = useState<WpWorkflowTrigger | null>(null);
  const [action, setAction] = useState<WpWorkflowAction | null>(null);
  const [hovering, setHovering] = useState<string | null>(null);
  const [pitchTitle, setPitchTitle] = useState<string>();
  const [featurePitchId, setFeaturePitchId] = useState<string | null>(null);
  const [editingPitchId, setEditingPitchId] = useState<string | null>(null);
  const [editingSlideId, setEditingSlideId] = useState<string>();
  const [redirectType, setRedirectType] = useState<string>();
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [redirectPhone, setRedirectPhone] = useState<string>();
  const [redirectButton, setRedirectButton] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showPitchSettings, setShowPitchSettings] = useState<boolean>(false);
  const [isNewWorkflow, setIsNewWorkflow] = useState<boolean>(false);
  const [editingWorkflow, setEditingWorkflow] = useState<boolean>(false);
  const [showRedirect, setShowRedirect] = useState<boolean>(false);
  const [creatingWorkflow, setCreatingWorkflow] = useState<boolean>(false);

  const [messageContent, setMessageContent] = useState<object | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [days, setDays] = useState<number | null>(null)
  const [hours, setHours] = useState<number | null>(null)
  const [minutes, setMinutes] = useState<number | null>(null)
  const [delayWindow, setWindow] = useState<'morning' | 'afternoon' | 'evening' | 'custom'>('morning')
  const [customWindow, setCustomWindow] = useState<string>('')
  const [customWindowAmPm, setCustomWindowAmPm] = useState<'am' | 'pm'>('am')

  // const [PitchModal, setPitchModal] = useState(null);
  const [createCampaignWorkflow, createWorkflowData] = useMutation(CREATE_CAMPAIGN_WORKFLOW);
  const [deleteCampaignWorkflow, deleteWorkflowData] = useMutation(DELETE_CAMPAIGN_WORKFLOW);
  const [createCampaignFeature, createData] = useMutation(CREATE_CAMPAIGN_FEATURE);
  const [updateCampaignFeature, updateData] = useMutation(UPDATE_CAMPAIGN_FEATURE);
  const [deleteCampaignFeature, deleteData] = useMutation(DELETE_CAMPAIGN_FEATURE);
  const [createCampaignTrigger, createTriggerData] = useMutation(CREATE_CAMPAIGN_TRIGGER);
  const [deleteCampaignTrigger, deleteTriggerData] = useMutation(DELETE_CAMPAIGN_TRIGGER);
  const [createCampaignAction, createActionData] = useMutation(CREATE_CAMPAIGN_ACTION);
  const [deleteCampaignAction, deleteActionData] = useMutation(DELETE_CAMPAIGN_ACTION);
  const [createSlide, createSlideData] = useMutation(CREATE_PITCH_SLIDE)
  const [deleteSlide, deleteSlideData] = useMutation(DELETE_PITCH_SLIDE)
  const [updatePitch, updatePitchData] = useMutation(UPDATE_PITCH)

  useEffect(() => {
    if (deleteSlideData.data) {
      onSave();
    }
  }, [deleteSlideData.data])

  useEffect(() => {
    if (updatePitchData.data) {
      if (setPitchId !== undefined) {
        setPitchId(updatePitchData.data.updatePitch.pitch.id)
      }
      onSave();
    }
  }, [updatePitchData.data])

  useEffect(() => {
    if (feature?.pitch && setPitchId !== undefined) {
      setPitchId(feature.pitch.id)
    }
    setEditingSlideId(pitchSlideContext.state?.slide?.id ?? undefined)
  }, [pitchSlideContext.state?.slide])

  useEffect(() => {
    if (featureId && campaignContext.state.editing === featureId) {
      openModal();
    }
  }, [campaignContext.state.editing]);

  useEffect(() => {
    if (deleteWorkflowData.data) {
      doneEditingWorkflow(true);
    }
  }, [deleteWorkflowData.data]);

  useEffect(() => {
    if (campaignContext.state.campaign?.features) {
      const findex = campaignContext.state.campaign.features.findIndex(f => f.id === featureId);
      if (findex > -1) {
        setFeature(campaignContext.state.campaign.features[findex]);
        setMessageContent(campaignContext.state.campaign.features[findex].messageContent)
        if (workflow?.id) {
          const windex = campaignContext.state.campaign.features[findex].workflows.findIndex(w => w.id === workflow?.id)
          if (windex > -1) {
            setWorkflow(campaignContext.state.campaign.features[findex].workflows[windex])
          }
        }
        if (campaignContext.state.campaign.features[findex].pitch) {
          let p = campaignContext.state.campaign.features[findex].pitch;
          setPitchTitle(p.title)
          setRedirectType(p.redirectType)
          setRedirectUrl(p.redirectUrl)
          setRedirectPhone(p.redirectPhone)
          setRedirectButton(p.redirectButton)
        }
      }
    }
  }, [campaignContext.state.campaign]);

  useEffect(() => {
    if (deleteData.data?.deleteCampaignFeature.success) {
      campaignContext.dispatch({ type: WpCampaignAction.DELETE_CAMPAIGN_FEATURE, structureType: structureType, structure: deleteData.data.deleteCampaignFeature.structure })
    }
  }, [deleteData.data]);

  useEffect(() => {
    if (createSlideData.data?.createPitchSlide.success) {
      onSave();
      if (onSelectSlide !== undefined) {
        onSelectSlide(createSlideData.data.createPitchSlide.slide.id, true)
      }
    }
  }, [createSlideData.data]);

  useEffect(() => {
    if (updateData.data?.updateCampaignFeature.success) {
      campaignContext.dispatch({ type: WpCampaignAction.UPDATE_CAMPAIGN_FEATURE, structureType: structureType, structure: updateData.data.updateCampaignFeature.structure, campaignFeature: updateData.data.updateCampaignFeature.feature })
      setFeature(updateData.data.updateCampaignFeature.feature);
    }
  }, [updateData.data]);

  useEffect(() => {
    if (updateData.data?.deleteCampaignFeature.success) {
      campaignContext.dispatch({ type: WpCampaignAction.DELETE_CAMPAIGN_FEATURE, structureType: structureType, structure: deleteData.data.deleteCampaignFeature.structure })
    }
  }, [deleteData.data]);

  useEffect(() => {
    if (createTriggerData.data?.createWorkflowTrigger.success) {
      campaignContext.dispatch({ type: WpCampaignAction.CREATE_CAMPAIGN_TRIGGER, campaignFeature: feature, workflow: workflow, trigger: createTriggerData.data?.createWorkflowTrigger.trigger })
      setTrigger(createTriggerData.data?.createWorkflowTrigger.trigger);
      startEditingTrigger(createTriggerData.data?.createWorkflowTrigger.trigger);
    }
  }, [createTriggerData.data]);

  useEffect(() => {
    if (createActionData.data?.createWorkflowAction.success) {
      campaignContext.dispatch({ type: WpCampaignAction.CREATE_CAMPAIGN_ACTION, campaignFeature: feature, workflow: workflow, action: createActionData.data?.createWorkflowAction.action })
      setAction(createActionData.data?.createWorkflowAction.action);
      startEditingAction(createActionData.data?.createWorkflowAction.action);
    }
  }, [createActionData.data]);

  useEffect(() => {
    if (createWorkflowData.data?.createCampaignWorkflow.workflow) {
      setWorkflow(createWorkflowData.data?.createCampaignWorkflow.workflow)
      setCreatingWorkflow(false)
      campaignContext.dispatch({ type: WpCampaignAction.CREATE_CAMPAIGN_WORKFLOW, campaignFeature: feature, workflow: createWorkflowData.data?.createCampaignWorkflow.workflow })
    }
  }, [createWorkflowData.data]);

  useEffect(() => {
    if (feature?.pitch?.id && featurePitchId !== feature.pitch.id) {
      setFeaturePitchId(feature.pitch.id)
    }

    if (feature) {
      setDays(feature.days)
      setHours(feature.hours)
      setMinutes(feature.minutes)

      let isNumber = feature.window ? !isNaN(Number(feature.window)) : false;
      let isTimeOfDay = ['morning', 'afternoon', 'evening'].includes(feature.window);
      if (isTimeOfDay) {
        setWindow(feature.window)
      } else if (isNumber) {
        setWindow('custom')
        let window = Number(feature.window)
        if (window === 0) {
          setCustomWindow('12')
        } else {
          setCustomWindow(window != 12 ? (window % 12).toString() : '12')
        }
        setCustomWindowAmPm(window < 12 ? 'am' : 'pm')
      } else {
        setWindow('morning')
      }
    }

    // if (feature?.featureType === WpFeatureType.PITCH) {
    //   setPitchModal(renderPitchModal(feature && feature.pitch ? feature.pitch : null))
    // } else {
    //   setPitchModal(null);
    // }
  }, [feature]);

  let color = Constants.colors.primary;

  let style = {
    backgroundColor: '#FFF',
    color: color,
  };

  if (feature && !isAppointment) {
    style.backgroundColor = index ? COLORS[index % COLORS.length][0] : COLORS[0][0];
    style.color = "#FFF";
  }

  const updateRedirect = (newType?: WpRedirectType) => {
    if (feature?.pitch) {
      updatePitch({ variables: { pitchId: feature.pitch.id, redirectType: newType ? newType : redirectType, redirectUrl: redirectUrl, redirectPhone: redirectPhone, redirectButton: redirectButton } })
    }
    setShowRedirect(false)
  }

  const clearRedirect = () => {
    if (feature?.pitch) {
      updatePitch({ variables: { pitchId: feature.pitch.id, redirectType: 'NONE' } })
    }
  }

  const onHover = (what: HOVERABLES | string) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(null);
  }

  const openModal = () => {
    setIsVisible(true);
  }

  const closeModal = () => {
    setWorkflow(null);
    setIsVisible(false);
    campaignContext.dispatch({ type: WpCampaignAction.CLEAR_EDITING })
  }

  const saveFeature = () => {
    console.log("Creatign feature with strutureType", feature?.structureType)
    if (feature?.id === 'new') {
      let after;
      if (feature.structureType && campaignContext.state.campaign?.structure[feature.structureType].length) {
        for (let i = 0; i < campaignContext.state.campaign?.structure[feature.structureType].length; i++) {
          let f = campaignContext.state.campaign?.structure[feature.structureType][i];
          if (f.id === 'new' && i > 0) {
            after = campaignContext.state.campaign?.structure[feature.structureType][i - 1].id
          }
        }
      }
      createCampaignFeature({ variables: { campaignId: campaignContext.state.campaign?.id, structureType: feature.structureType, after: after, featureType: WpFeatureType.MESSAGE } })
    } else {
      let window: string = delayWindow;
      if (window === 'custom' && !isNaN(parseInt(customWindow))) {
        window = customWindowAmPm === 'am' ? customWindow : (parseInt(customWindow) + 12).toString();
        if (window === '24') {
          window = '12';
        } else if (window === '12') {
          window = '0';
        }
      }
      let variables = { campaignFeatureId: feature?.id, structureType: feature?.structureType ?? WpStructureType.FEATURE, message: message, messageContent: messageContent, days: days, hours: hours, minutes: minutes, window: window }
      if (feature?.featureType === WpFeatureType.PITCH) {
        variables.pitchId = featurePitchId;
      }
      updateCampaignFeature({ variables: variables })
      setIsVisible(false);
    }
  }

  const deleteFeature = (id: string | undefined) => {
    if (id) {
      if (id === 'redirect') {

      } else {
        deleteSlide({ variables: { pitchSlideId: id } });
      }
    } else {
      deleteCampaignFeature({ variables: { campaignFeatureId: feature?.id, structureType: structureType } })
    }
  }

  const newWorkflow = () => {
    setIsNewWorkflow(true);
    createCampaignWorkflow({ variables: { campaignFeatureId: feature?.id } });
  }

  const newWorkflowTrigger = () => {
    // createCampaignTrigger({ variables: {campaignWorkflowId: workflow?.id}});
    let newTrigger: WpWorkflowTrigger = {
      id: 'new',
      label: 'if ...'
    };
    campaignContext.dispatch({ type: WpCampaignAction.CREATE_CAMPAIGN_TRIGGER, campaignFeature: feature, workflow: workflow, trigger: newTrigger })
    setTrigger(newTrigger);
    startEditingTrigger(newTrigger);
  }

  const newWorkflowAction = () => {
    // createCampaignAction({ variables: {campaignWorkflowId: workflow?.id}});
    let newAction: WpWorkflowAction = {
      id: 'new',
      label: 'then ...',
      default: true
    };
    campaignContext.dispatch({ type: WpCampaignAction.CREATE_CAMPAIGN_ACTION, campaignFeature: feature, workflow: workflow, action: newAction })
    setAction(newAction);
    startEditingAction(newAction);
  }

  const deleteWorkflow = (w: WpCampaignWorkflow) => {
    setWorkflow(null);
    deleteCampaignWorkflow({ variables: { campaignWorkflowId: w.id } })
    campaignContext.dispatch({ type: WpCampaignAction.DELETE_CAMPAIGN_WORKFLOW, campaignFeature: feature, workflow: w })
  }

  const deleteTrigger = (t: WpWorkflowTrigger) => {
    deleteCampaignTrigger({ variables: { workflowTriggerId: t.id } })
    campaignContext.dispatch({ type: WpCampaignAction.DELETE_CAMPAIGN_TRIGGER, campaignFeature: feature, workflow: workflow, trigger: t })
  }

  const deleteAction = (a: WpWorkflowAction) => {
    deleteCampaignAction({ variables: { workflowActionId: a.id } })
    campaignContext.dispatch({ type: WpCampaignAction.DELETE_CAMPAIGN_ACTION, campaignFeature: feature, workflow: workflow, action: a })
  }

  useEffect(() => {
    if (createData.data?.createCampaignFeature.success) {
      let window: string = delayWindow;
      if (window === 'custom' && !isNaN(parseInt(customWindow))) {
        window = customWindowAmPm === 'am' ? customWindow : (parseInt(customWindow) + 12).toString();
        if (window === '24') {
          window = '12';
        } else if (window === '12') {
          window = '0';
        }
      }
      let variables = { campaignFeatureId: createData.data?.createCampaignFeature.feature.id, structureType: createData.data?.createCampaignFeature.feature.structureType, message: message, messageContent: messageContent, days: days, hours: hours, minutes: minutes, window: window }
      updateCampaignFeature({ variables: variables })
      setIsVisible(false);
    }
  }, [createData.data]);

  const getTheme = (type: WpWorkflowTriggerType | WpWorkflowActionType) => {
    return {
      VIEWED: Theme.PRIMARY,
      TIME: Theme.PRIMARY,
      WIDGET: Theme.SUCCESS,
      REDIRECT: Theme.ACCENT,
      CALENDAR: Theme.PRIMARY_MEDIUM,
      UNOPENED: Theme.DANGER,

      ME: Theme.PRIMARY,
      SPECIFIC: Theme.PRIMARY_MEDIUM,
      LEAD: Theme.ACCENT,
      CAMPAIGN: Theme.SUCCESS,
      WEBHOOK: Theme.WARNING
    }[type]
  }

  const renderDeleteButton = (id?: string) => {
    if (id === 'redirect' && feature?.pitch) {
      return <TrashIcon style={{ ...cssStyles.icon, color: hovering === HOVERABLES.REDIRECT ? Constants.colors.danger : Constants.colors.info }} onClick={clearRedirect} onMouseEnter={() => onHover(HOVERABLES.REDIRECT)} onMouseLeave={onHoverOut} />
    } else if (featureId) {
      if (id && hovering === id && deleteData.loading) {
        return <ActivityIndicator />
      } else {
        return <TrashIcon style={{ ...cssStyles.icon, color: (id && hovering === id) || (id === undefined && hovering === HOVERABLES.TRASH) ? Constants.colors.danger : Constants.colors.info }} onClick={() => deleteFeature(id)} onMouseEnter={() => onHover(id ? id : HOVERABLES.TRASH)} onMouseLeave={onHoverOut} />
      }
    } else if (isAppointment) {
      return <TrashIcon style={{ ...cssStyles.icon, opacity: 0, color: hovering === HOVERABLES.TRASH ? Constants.colors.danger : Constants.colors.info }} onClick={() => { }} onMouseEnter={() => onHover(HOVERABLES.TRASH)} onMouseLeave={onHoverOut} />
    }
  }

  const doneEditing = (saved?: boolean) => {
    if (saved) {
      campaignContext.dispatch({ type: WpCampaignAction.REFRESH_WORKFLOW, campaignFeature: feature, workflow: workflow })
    }
    setEditingWorkflow(false)
    setTrigger(null)
    setAction(null)
  }

  const doneEditingWorkflow = (handled?: boolean) => {
    if (!handled && isNewWorkflow && !!workflow) {
      deleteWorkflow(workflow)
      closePitchSettings();
    } else {
      setIsNewWorkflow(false)
      setWorkflow(null)
      onSave();
    }
  }

  const startEditingTrigger = (trigger: WpWorkflowTrigger) => {
    setTrigger(trigger)
    setEditingWorkflow(true)
  }

  const startEditingAction = (action: WpWorkflowAction) => {
    setAction(action)
    setEditingWorkflow(true)
  }

  const setPitch = (pitchId: string) => {
    updateCampaignFeature({ variables: { campaignFeatureId: feature?.id, structureType: structureType, pitchId: pitchId } })
  }

  const editPitch = (pitchId: string) => {
    setEditingPitchId(pitchId);
  }

  const closePitchEditor = () => {
    setEditingPitchId(null);
  }

  const clearPitch = () => {
    updateCampaignFeature({ variables: { campaignFeatureId: feature?.id, structureType: structureType, pitchId: null } })
  }

  const updateDelay = ({ days, hours, minutes, delayUntil }: { days?: number | null, hours?: number | null, minutes?: number | null, delayUntil?: string | null }) => {
    updateCampaignFeature({ variables: { campaignFeatureId: feature?.id, structureType: structureType, days: days, hours: hours, minutes: minutes, delayUntil: delayUntil } })
  }

  const onSaveTrigger = (newTrigger: WpWorkflowTrigger) => {
    // refresh campaign feature
    campaignContext.dispatch({ type: WpCampaignAction.UPDATE_CAMPAIGN_TRIGGER, campaignFeature: feature, workflow: workflow, trigger: newTrigger })
    doneEditing(true);
  }

  const onSaveAction = (newAction: WpWorkflowAction) => {
    // refresh campaign feature
    campaignContext.dispatch({ type: WpCampaignAction.UPDATE_CAMPAIGN_ACTION, campaignFeature: feature, workflow: workflow, action: newAction })
    doneEditing(true);
  }

  // const renderPitchModal = (pitch:WpPitch|null) => {
  //   if (pitch) {
  //     return (
  //       <ModalBody title={pitch.title} style={{ width: dimensions.width * 0.8, maxWidth: 500 }}>
  //         <Button style={{ marginBottom: 20 }} key={`btn-pitch-edit-${pitch.id}`} text={`Edit ${pitch.title}`} width="100%" theme={Theme.PRIMARY} onPress={() => editPitch(pitch.id)} onDelete={clearPitch} />
  //       </ModalBody>
  //     )
  //   } else {
  //     return (
  //       <ModalBody title={`Select Pitch`} style={{ width: dimensions.width * 0.8, maxWidth: 500 }}>
  //         <PitchSelector onSelect={setPitch} />
  //       </ModalBody>
  //     )
  //   }
  // }

  const onCreateSlide = (after: string | undefined) => {
    if (after === 'redirect') {
      setRedirectType(WpRedirectType.LINK)
      // updateRedirect(WpRedirectType.LINK);
      openRedirect();
    } else if (feature) {
      if (after === 'slide') {
        after = undefined;
        for (let i = 0; i < feature.pitch?.structure.length; i++) {
          after = feature.pitch.structure[i].id;
        }
      }
      createSlide({ variables: { featureId: feature.id, pitchId: feature.pitch?.id, after: after } })
    }
  }

  const doneEditingSlide = () => {
    onSave();
    setEditingSlideId(undefined)
  }

  const updateDays = (val: string) => {
    setDays(parseInt(val))
  }

  const updateCustomWindow = (val: string) => {
    let cw = parseInt(val)
    if (cw < 0) {
      cw = 0
    } else if (cw > 12) {
      cw = 12
    }
    setCustomWindow(cw.toString())
  }

  const updateHours = (val: string) => {
    setHours(parseInt(val))
  }

  const updateMinutes = (val: string) => {
    setMinutes(parseInt(val))
  }

  const openRedirect = () => {
    setShowRedirect(true)
  }

  const closeRedirect = () => {
    if (!feature?.pitch.redirectType) {
      setRedirectType(undefined);
    }
    setShowRedirect(false);
  }

  const redirectToLink = () => {
    setRedirectType(WpRedirectType.LINK)
  }

  const redirectToContact = () => {
    setRedirectType(WpRedirectType.CONTACT)
  }

  const openPitchSettings = () => {
    if (!feature?.workflows?.length) {
      newWorkflow();
      setCreatingWorkflow(true)
    }
    setShowPitchSettings(true)
  }

  const closePitchSettings = () => {
    setShowPitchSettings(false)
  }

  const onSavePitchSettings = () => {
    if (feature?.pitch) {
      updatePitch({ variables: { pitchId: feature.pitch.id, title: pitchTitle } })
      onSave();
    }
    setShowPitchSettings(false)
  }

  const renderPitchControl = () => {
    if (feature?.pitch && feature.pitch.structure.length && (Constants.controls.allowIfThen || userContext.state.user?.id === "15" || userContext.state.user?.id === "16" || userContext.state.user?.wpAdmin) && !Constants.blastView) {
      return (
        <View style={{ alignItems: 'center' }}>
          <WpModal isVisible={showPitchSettings && !creatingWorkflow && !workflow}>
            <ModalBody title="Edit If/then" style={{ width: dimensions.width * 0.8, maxWidth: 500 }} closeModal={closePitchSettings}>
              {/* <Input name="Name your pitch" optional={`Not visible to ${Constants.localizations.leads}`} fontSize={18} value={pitchTitle} setValue={setPitchTitle} /> */}

              <Text style={{ fontSize: 14, fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, marginVertical: 15 }}>If/then</Text>
              <View style={{ gap: 10 }}>
                {feature.workflows.length ? feature.workflows.map((workflow, i, list) => {
                  return (
                    <View key={`workflow-${workflow.id}`} style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                      <Text key={`btn-workflow-edit-${workflow.id}`} style={[styles.ifThen, { opacity: hovering === `workflow-${workflow.id}` ? 0.7 : 1 }]} onPress={() => setWorkflow(workflow)} onMouseEnter={() => onHover(`workflow-${workflow.id}`)} onMouseLeave={onHoverOut}>{workflow.label}</Text>
                      <TrashIcon style={{ ...cssStyles.icon, marginLeft: 5, marginRight: 5, color: hovering === `trash-workflow-${workflow.id}` ? Constants.colors.danger : Constants.colors.info }} onClick={() => deleteWorkflow(workflow)} onMouseEnter={() => onHover(`trash-workflow-${workflow.id}`)} onMouseLeave={onHoverOut} />
                    </View>
                  );
                }) : null
                }
              </View>
              <Text onPress={newWorkflow} style={styles.newBtn}>+ Add if/then</Text>
              <ModalFooter>
                <Button key="btn-feature-save" text="Save" onPress={onSavePitchSettings} />
              </ModalFooter>
            </ModalBody>
          </WpModal>

          <WpModal isVisible={creatingWorkflow || !!workflow}>
            <ModalBody style={{ width: 500, maxWidth: dimensions.width * 0.8 }} title={`Edit if/then`} closeModal={editingWorkflow ? () => doneEditing(true) : doneEditingWorkflow}>
              {editingWorkflow ? null : <Text style={{ width: '100%', color: Constants.colors.info, fontFamily: 'GothamMedium', textAlign: 'center', marginBottom: 10, position: 'relative', top: -20 }}>Once all "Ifs" occur each "Then" will be triggered</Text>}
              {editingWorkflow ? null :
                <View style={{ flexDirection: 'row' }}>
                  <View key="trigger" style={{ flex: 1, paddingLeft: 10, paddingRight: 30 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, marginBottom: 15 }}>If</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                      {workflow?.triggers ? workflow.triggers.map((trigger, i, list) => {
                        return (
                          <View key={`workflow-trigger-${trigger.id}`} style={{ flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 10 }}>
                            <Text key={`btn-trigger-edit-${trigger.id}`} style={[styles.ifThen, { opacity: hovering === `trigger-${trigger.id}` ? 0.7 : 1, backgroundColor: '#F7F7F7', color: '#838383' }]} onPress={() => startEditingTrigger(trigger)} onMouseEnter={() => onHover(`trigger-${trigger.id}`)} onMouseLeave={onHoverOut}>{trigger.label}</Text>
                            <TrashIcon style={{ ...cssStyles.icon, marginLeft: 5, marginRight: 5, color: hovering === `trash-trigger-${trigger.id}` ? Constants.colors.danger : Constants.colors.info }} onClick={() => deleteTrigger(trigger)} onMouseEnter={() => onHover(`trash-trigger-${trigger.id}`)} onMouseLeave={onHoverOut} />
                          </View>
                        );
                      }) : null}
                      <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                        <Text key={`btn-trigger-new`} style={[styles.ifThen, { opacity: hovering === `trigger-new` ? 0.7 : 1, paddingVertical: workflow?.triggers.length || workflow?.actions.length ? 10 : 50 }]} onPress={newWorkflowTrigger} onMouseEnter={() => onHover(`trigger-new`)} onMouseLeave={onHoverOut}>+ Add</Text>
                        <View style={{ marginRight: workflow?.triggers.length || workflow?.actions.length ? 25 : 0 }}></View>
                      </View>
                    </View>
                  </View>
                  <View key="action" style={{ flex: 1, paddingLeft: 10, paddingRight: 30 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, marginBottom: 15 }}>Then</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                      {workflow?.actions ? workflow.actions.map((action, i, list) => {
                        return (
                          <View key={`workflow-action-${action.id}`} style={{ flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 10 }}>
                            <Text key={`btn-action-edit-${action.id}`} style={[styles.ifThen, { opacity: hovering === `action-${action.id}` ? 0.7 : 1, backgroundColor: '#F7F7F7', color: '#838383' }]} onPress={() => startEditingAction(action)} onMouseEnter={() => onHover(`action-${action.id}`)} onMouseLeave={onHoverOut}>{action.label}</Text>
                            <TrashIcon style={{ ...cssStyles.icon, marginLeft: 5, marginRight: 5, color: hovering === `trash-action-${action.id}` ? Constants.colors.danger : Constants.colors.info }} onClick={() => deleteAction(action)} onMouseEnter={() => onHover(`trash-action-${action.id}`)} onMouseLeave={onHoverOut} />
                          </View>
                        );
                      }) : null}
                      <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                        <Text key={`btn-action-new`} style={[styles.ifThen, { opacity: hovering === `action-new` ? 0.7 : 1, paddingVertical: workflow?.triggers.length || workflow?.actions.length ? 10 : 50 }]} onPress={newWorkflowAction} onMouseEnter={() => onHover(`action-new`)} onMouseLeave={onHoverOut}>+ Add</Text>
                        <View style={{ marginRight: workflow?.triggers.length || workflow?.actions.length ? 25 : 0 }}></View>
                      </View>
                    </View>
                  </View>
                </View>}
              {editingWorkflow && workflow && (!!trigger || !!action) && trigger && feature?.pitch ? <WorkflowTriggerEditor trigger={trigger} workflowId={workflow.id} pitchId={feature.pitch.id} onSave={onSaveTrigger} closeModal={doneEditing} /> : null}
              {editingWorkflow && workflow && (!!trigger || !!action) && action ? <WorkflowActionEditor action={action} workflowId={workflow.id} onSave={onSaveAction} closeModal={doneEditing} /> : null}
              {!editingWorkflow ?
                <ModalFooter>
                  <Button key="btn-feature-save" text="Save" onPress={() => doneEditingWorkflow(true)} />
                </ModalFooter> : null}
            </ModalBody>
          </WpModal>

          {/* <WpModal isVisible={editingWorkflow && (!!trigger || !!action)} closeModal={doneEditing}>
            <ModalBody title={`Edit ${trigger ? 'Trigger' : 'Action'}`} closeModal={doneEditing} style={{ width: dimensions.width * 0.8, maxWidth: 500 }}>
            </ModalBody>
          </WpModal> */}

          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 5, cursor: 'pointer' }} onClick={openPitchSettings}>
            <SimpleLineIcons style={{ position: 'relative', top: 1, marginRight: 3 }} name="pencil" size={12} color={style.backgroundColor} />
            <Text style={{ fontFamily: 'GothamMedium', color: style.backgroundColor }}>Edit if/then</Text>
          </View>
          <View style={styles.line}></View>
        </View>
      )
    }
    return null;
  }

  const renderSlides = () => {
    if (feature?.pitch?.structure?.length) {
      let after: undefined | string = undefined;

      return feature.pitch.structure.map((item, i, list) => {
        let this_after = after;
        after = item.id;

        return (
          <View key={`feature-slide-${item.id}`}>
            <GraphCreateButton muted={true} after={this_after} onPress={onCreateSlide} />
            <View style={{ position: 'relative', justifyContent: 'center', gap: 15, alignItems: 'center', flexDirection: 'row' }}>
              <View key="spacer" style={{ width: 150 }}></View>
              <View key="label" style={[styles.container, style, { backgroundColor: index ? COLORS[index % COLORS.length][1] : COLORS[0][1], opacity: hovering === `slide-${item.id}` ? 0.8 : 1 }]} onMouseEnter={() => onHover(`slide-${item.id}`)} onMouseLeave={onHoverOut} onClick={() => onSelectSlide(item.id)}>
                <Text style={[styles.text, { color: style.backgroundColor }]} >{(item.label.replace('Lead', Constants.localizations.Lead).substr(0, 25) + (item.label.length > 25 ? '...' : ''))}</Text>
              </View>
              {renderDeleteButton(item.id)}
            </View>
          </View>
        )
      })
    }
    return null;
  }

  const renderRedirect = () => {
    if (redirectType) {
      let after;
      if (feature?.pitch.structure.length) {
        after = feature.pitch.structure[feature.pitch.structure.length - 1].id
      }
      return (
        <View key={'feature-redirect'}>
          <WpModal isVisible={showRedirect}>
            <ModalBody title="Redirect" style={{ width: dimensions.width * 0.8, maxWidth: 500 }} closeModal={closeRedirect}>
              {/* <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: 25 }}>
                <Text onPress={redirectToLink} style={redirectType === WpRedirectType.LINK ? styles.redirectTypeActive : styles.redirectType}>Send { Constants.localizations.lead } to URL</Text>
                <Text onPress={redirectToContact} style={redirectType === WpRedirectType.CONTACT ? styles.redirectTypeActive : styles.redirectType}>Prompt { Constants.localizations.lead } to call/text</Text>
              </View> */}
              {redirectType === WpRedirectType.LINK ?
                <Input style={{ marginBottom: 20 }} name="Redirect URL" fontSize={18} fontColor={Constants.colors.blue} value={redirectUrl} setValue={setRedirectUrl} /> :
                <Input name="Phone Number" fontSize={18} fontColor={Constants.colors.blue} value={redirectPhone} setValue={setRedirectPhone} />
              }
              <Input name="Button text" info="If no button text is provided, the button text will be 'Continue' just like every other slide." fontSize={18} fontColor={Constants.colors.blue} value={redirectButton} setValue={setRedirectButton} />
              <ModalFooter style={{ justifyContent: 'start' }}>
                <Button textStyles={{ padding: 0, textAlign: 'left' }} key="btn-redirect-delete" width={172} text="Remove redirect" theme={Theme.LINK_DANGER} onPress={clearRedirect} />
                <Button key="btn-feature-save" text="Save" onPress={updateRedirect} />
              </ModalFooter>
            </ModalBody>
          </WpModal>
          {/* <GraphCreateButton muted={true} after={after} onPress={onCreateSlide} /> */}
          {/* <View style={{ position: 'relative', justifyContent: 'center', gap: 15, alignItems: 'center', flexDirection: 'row' }}>
            <View key="spacer" style={{ width: 150 }}></View>
            <View key="label" style={[styles.container, style, { backgroundColor: index ? COLORS[index % COLORS.length][1] : COLORS[0][1], opacity: hovering === `redirect` ? 0.8 : 1 }]} onMouseEnter={() => onHover(`redirect`)} onMouseLeave={onHoverOut} onClick={openRedirect}>
              <Text style={[styles.text, { color: style.backgroundColor }]} >{ redirectType === WpRedirectType.CONTACT ? 'Prompt to call/text' : 'Send to URL' }</Text>
            </View>
            { renderDeleteButton('redirect') }
          </View> */}
        </View>
      )
    }
    return null;
  }

  const renderDelay = () => {
    if (structureType === WpStructureType.FEATURE) {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 20 }}>
          <Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium', fontSize: 18, marginRight: 15 }}>Set delay</Text>
          <Input style={{ width: 30 }} fontSize={18} fontColor={Constants.colors.blue} isNumber={true} isHeading={true} value={days} setValue={updateDays} />
          <Text style={{ color: Constants.colors.blue, fontFamily: 'GothamMedium', fontSize: 18 }}>:</Text>
          <Input style={{ width: 30 }} fontSize={18} fontColor={Constants.colors.blue} isNumber={true} isHeading={true} value={hours} setValue={updateHours} />
          <Text style={{ color: Constants.colors.blue, fontFamily: 'GothamMedium', fontSize: 18 }}>:</Text>
          <Input style={{ width: 30 }} fontSize={18} fontColor={Constants.colors.blue} isNumber={true} isHeading={true} value={minutes} setValue={updateMinutes} />
          <Text style={{ color: '#A5A5A5', fontFamily: 'Gotham', fontSize: 14, marginLeft: 3 }}>(d/h/m)</Text>
        </View>
      )
    } else {
      return (
        <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: 20 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 10 }}>
            <Input style={{ width: 30 }} fontSize={18} fontColor={Constants.colors.blue} isNumber={true} isHeading={true} value={days} setValue={updateDays} />
            <Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium', fontSize: 18, marginRight: 15 }}>Days {structureType === WpStructureType.BEFORE ? 'Before' : 'After'}</Text>
          </View>
          {delayWindow === 'custom' ?
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 10 }}>
              <Input style={{ width: 30 }} fontSize={18} fontColor={Constants.colors.blue} isNumber={true} isHeading={true} value={customWindow} setValue={updateCustomWindow} />
              <Hoverable onPress={() => setCustomWindowAmPm(customWindowAmPm === 'am' ? 'pm' : 'am')}><Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium', fontSize: 18, marginRight: 15 }}>{customWindowAmPm.toUpperCase()}</Text></Hoverable>
            </View> : null}
          <View style={styles.appointmentControls}>
            <View style={styles.appointmentControlGroup}>
              <Hoverable onPress={() => setWindow('morning')}>
                <Text style={[styles.appointmentControl, styles.appointmentControlStart, delayWindow === 'morning' ? styles.appointmentActive : {}]}>Morning</Text>
                <Text style={{ fontSize: 10, textAlign: 'center', color: Constants.colors.secondary, paddingTop: 3 }}>9 am</Text>
              </Hoverable>
              <Hoverable onPress={() => setWindow('afternoon')}>
                <Text style={[styles.appointmentControl, delayWindow === 'afternoon' ? styles.appointmentActive : {}]}>Afternoon</Text>
                <Text style={{ fontSize: 10, textAlign: 'center', color: Constants.colors.secondary, paddingTop: 3 }}>1 pm</Text>
              </Hoverable>
              <Hoverable onPress={() => setWindow('evening')}>
                <Text style={[styles.appointmentControl, userContext.state.user?.organization.showCustomWindow || Constants.oppText ? {} : styles.appointmentControlEnd, delayWindow === 'evening' ? styles.appointmentActive : {}]}>Evening</Text>
                <Text style={{ fontSize: 10, textAlign: 'center', color: Constants.colors.secondary, paddingTop: 3 }}>6 pm</Text>
              </Hoverable>
              {userContext.state.user?.organization.showCustomWindow || Constants.oppText ?
                <Hoverable onPress={() => setWindow('custom')}>
                  <Text style={[styles.appointmentControl, styles.appointmentControlEnd, delayWindow === 'custom' ? styles.appointmentActive : {}]}>Custom</Text>
                  <Text style={{ fontSize: 10, textAlign: 'center', color: Constants.colors.secondary, paddingTop: 3 }}>&nbsp;</Text>
                </Hoverable> : null}
            </View>
          </View>
        </View>
      )
    }
  }

  const renderPitchControlButton = () => {
    if (!isAppointment && componentKey !== 'campaign-feature-initial') {
      if (!!feature?.pitch && Constants.controls.showRedirect) {
        return (
          <View style={{ position: 'relative' }}>
            <CampaignFeatureCreateButton key={`feature-${feature?.id}-slide`} muted={true} defaultToSlide={true} onPress={onCreateSlide} showSlides={false} showRedirect={!!feature?.pitch && !feature?.pitch?.redirectType && Constants.controls.showRedirect} after={feature ? feature?.id : undefined} isFirst={isFirst && structureType !== WpStructureType.BEFORE} isLast={isLast && structureType !== WpStructureType.BEFORE} structureType={structureType} />
            <FontAwesome5 style={{ cursor: 'pointer', position: 'absolute', top: 13, right: -30 }} name="link" size={16} color={!feature.pitch.redirectType ? Constants.colors.info : Constants.colors.green} onClick={() => !feature.pitch.redirectType ? onCreateSlide("redirect") : openRedirect()} />
          </View>
        )
      } else if (!!feature && Constants.controls.allowSlides) {
        return <CampaignFeatureCreateButton key={`feature-${feature?.id}-slide`} muted={true} defaultToSlide={true} onPress={onCreateSlide} showSlides={false} showRedirect={!!feature?.pitch && !feature?.pitch?.redirectType && Constants.controls.showRedirect} after={feature ? feature?.id : undefined} isFirst={isFirst && structureType !== WpStructureType.BEFORE} isLast={isLast && structureType !== WpStructureType.BEFORE} structureType={structureType} />
      }
    }
    return null
  }

  if (featureId && !feature) {
    return (<View></View>)
  }

  return (
    <View key={`feature-${featureId}`}>
      {/* <PitchEditor pitchId={editingPitchId} onClose={closePitchEditor} /> */}

      {featureId ?
        <WpModal isVisible={isVisible} closeModal={closeModal}>
          <View style={{ flex: 1, alignItems: 'center' }}>
            {feature && !isAppointment ?
              <ModalBody title={`Edit ${feature?.label.replace('Lead', Constants.localizations.Lead)}`} closeModal={closeModal} style={{ width: dimensions.width * 0.8, maxWidth: 500 }}>
                {renderDelay()}
                <MessageEditor messageContent={messageContent} apptPlaceholders={userContext.state.user?.organization.showApptPlaceholders} setMessageContent={setMessageContent} setMessage={setMessage} />

                <ModalFooter>
                  <Button key="btn-feature-save" text="Save" onPress={saveFeature} />
                </ModalFooter>
              </ModalBody> :
              <ModalBody title={`Edit ${feature?.label.replace('Lead', Constants.localizations.Lead)}`} closeModal={closeModal} style={{ width: dimensions.width * 0.8, maxWidth: 500 }}>
                <Text>For {Constants.localizations.leads} with appointments, you can use this to represent their scheduled event. The delays for any messages or pitches can then be set based on this appointment (i.e. 2h before, 'Send Pitch to {Constants.localizations.lead}').</Text>
                <ModalFooter>
                  <Button key="btn-feature-save" text="Close" onPress={closeModal} />
                </ModalFooter>
              </ModalBody>
            }
          </View>
        </WpModal> : null
      }

      <View style={styles.wrapper}>
        {isFirst && structureType !== WpStructureType.FEATURE && Constants.controls.allowSlides ? <CampaignFeatureCreateButton key={`feature-${feature?.id}`} onPress={onCreateSlide} showSlides={passedPitch} showRedirect={!!feature?.pitch && !feature?.pitch?.redirectType && Constants.controls.showRedirect} after={feature ? feature?.id : undefined} isFirst={structureType === WpStructureType.BEFORE} isLast={isLast && structureType !== WpStructureType.BEFORE} structureType={structureType} /> : null}
        {renderPitchControl()}
        <View key="feature-body" style={{ position: 'relative', justifyContent: 'center', gap: 15, alignItems: 'center', flexDirection: 'row' }}>
          {feature && !isAppointment ? <CampaignFeatureDelay style={{ width: 150, flexDirection: 'row', justifyContent: 'flex-end' }} days={days} hours={hours} minutes={minutes} delayUntil={feature?.delayUntil} onChange={updateDelay} onClick={openModal} /> : <View style={{ width: 150 }}></View>}
          {feature || isAppointment ?
            <View style={[styles.container, style, { opacity: hovering === HOVERABLES.BODY ? 0.8 : 1 }]} onMouseEnter={() => onHover(HOVERABLES.BODY)} onMouseLeave={onHoverOut} onClick={openModal}>
              <Text style={[styles.text, { color: style.color }]} >{feature ? (feature.label.replace('Lead', Constants.localizations.Lead).substr(0, 23) + (feature.label.length > 23 ? '...' : '')) : `${isAppointment ? Constants.localizations.Lead + ' Appointment' : Constants.localizations.Lead + ' Added to Campaign'}`}</Text>
            </View> : null
          }
          {renderDeleteButton()}
        </View>
        {renderSlides()}
        {renderRedirect()}
        {renderPitchControlButton()}
        {!isAppointment || isLast || (isAppointment && structureType === WpStructureType.APPOINTMENT) ? <CampaignFeatureCreateButton key={`feature-${feature?.id}`} onPress={onCreateSlide} showSlides={false} showRedirect={!!feature?.pitch && !feature?.pitch?.redirectType && Constants.controls.showRedirect} after={feature ? feature?.id : undefined} isFirst={isFirst && structureType !== WpStructureType.BEFORE} isLast={isLast && structureType !== WpStructureType.BEFORE} structureType={structureType} shortened={Constants.controls.allowSlides} /> : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative'
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 10,
    paddingTop: 25,
    paddingBottom: 25,
    width: 225,
    cursor: "pointer",
    position: 'relative'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  text: {
    fontFamily: 'GothamMedium',
    fontSize: 14,
    paddingHorizontal: 10
  },
  redirectTypeActive: {
    color: Constants.colors.blue,
    textDecorationLine: 'underline',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer'
  },
  redirectType: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer'
  },
  line: {
    width: 1,
    height: 10,
    border: '1px solid ' + Constants.colors.info,
  },
  newBtn: {
    marginTop: 15,
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
  ifThen: {
    textAlign: 'center',
    flex: 1,
    padding: 10,
    borderRadius: 10,
    backgroundColor: Constants.colors.lightBlue,
    color: Constants.colors.darkBlue,
    fontFamily: 'GothamMedium',
    width: '100%'
  },
  appointmentControlGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  appointmentControls: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 25
  },
  appointmentControlStart: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRightWidth: 0,
  },
  appointmentControl: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: Constants.colors.mediumGrey,
    color: Constants.colors.darkBlue,
    fontFamily: 'GothamMedium',
    fontSize: 12
  },
  appointmentActive: {
    borderColor: Constants.colors.darkBlue,
    backgroundColor: Constants.colors.darkBlue,
    color: Constants.colors.white,
  },
  appointmentHovering: {
    backgroundColor: Constants.colors.mediumGrey
  },
  appointmentControlEnd: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderLeftWidth: 0,
  }
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    marginRight: 135,
    cursor: 'pointer'
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
