import { LayoutChangeEvent, Text, View, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import * as tools from './../../tools/tools';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import { Label, Info } from './../Input';
import Checkbox from './../Checkbox';
import { Report } from './LeadTable';
import LeadDetailsModal from './LeadDetailsModal';
import LeadReportIcon, { Required } from './LeadReportIcon';
import { GET_LEADS } from './../../graphql/queries';
import { WpLeadBasic } from './../../contexts/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Ionicons, Feather, FontAwesome5, Entypo } from '@expo/vector-icons';
import Bubble from '../Shared/Bubble';
import { Avatar, IconButton } from '@whistlepitch/wp-components';
import { UserContext } from '../../contexts/user';
import Hoverable from '../Shared/Hoverable';
import Icon from '../Icon';
import Spinner from '../Spinner';
import { CREATE_CAMPAIGN } from '../../graphql/campaign';


export default function LeadRow({ lead, backgroundColor = '#FFFFFF', onRefresh = () => { }, selectable, removeable, onSelect, onRemove, report, index = 0, selected = false, masked = false }: { lead: WpLeadBasic, backgroundColor?: string, onRefresh?: () => void, selectable?: boolean, removeable?: boolean, onSelect: Function, onRemove?: (leadId: string) => void, report?: Report, index?: number, selected?: boolean, masked?: boolean }): JSX.Element {
  const { state } = React.useContext(UserContext);

  // const [localLeads, setLocalLeads] = useState<WpLeadBasic[]>([]);
  const [viewWidth, setViewWidth] = useState<number>(0);
  const [hovering, setHovering] = useState<boolean>();
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(false);
  const [hoveringCampaign, setHoveringCampaign] = useState<boolean>(false);
  const [hoveringEye, setHoveringEye] = useState<boolean>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [createCampaign, createCampaignData] = useMutation(CREATE_CAMPAIGN, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (createCampaignData?.data?.createCampaign?.campaign && createCampaignData.data.createCampaign.campaign.id) {
      window.location.href = `https://main.d16ta6dyg5gp.amplifyapp.com/campaigns/${createCampaignData.data.createCampaign.campaign.id}?token=${state.user?.token}`
      // window.location.href = `http://localhost:3000/campaigns/${createCampaignData.data.createCampaign.campaign.id}?token=${state.user?.token}`
    }
  }, [createCampaignData])

  const handleCreateCampaign = () => {
    if (!loadingCampaign) {
      setLoadingCampaign(true);
      createCampaign({ variables: { leadId: lead.id, v2: true } });
    }
  }

  // const [getLeads, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_LEADS, {
  //   fetchPolicy: "no-cache",
  //   notifyOnNetworkStatusChange: true
  // });
  //
  // useEffect(() => {
  //   if (data?.leads?.leads) {
  //     setLocalLeads(data.leads.leads)
  //   }
  // }, [data, networkStatus])
  //
  // const loadLeads = (variables: object) => {
  //   if (data) {
  //     refetch(variables);
  //   } else {
  //     getLeads({ variables: variables })
  //   }
  // }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onHover = () => {
    setHovering(true)
  }

  const onHoverOut = () => {
    setHovering(false)
  }

  const onHoverEye = () => {
    setHoveringEye(true)
  }

  const onHoverEyeOut = () => {
    setHoveringEye(false)
  }

  const handleOnSelect = () => {
    onSelect(lead.id)
  }

  const renderReport = () => {
    if (report) {
      let components = [];
      if (report.redirected?.length) {
        let complete: boolean | undefined = true;
        for (let i = 0; i < report.redirected.length; i++) {
          let pitchId = report.redirected[i];
          let index = lead.pseudoTags.findIndex((tag) => tag.pitchId === pitchId && tag.redirected);
          if (index === -1) {
            complete = undefined;
            break;
          }
        }
        components.push(<LeadReportIcon key="lead-report-redirect" type={Required.REDIRECT} complete={complete} />)
      }
      if (report.video?.length) {
        let complete: boolean | undefined = true;
        let has_activity = lead.pseudoTags.findIndex((tag) => tag.video !== null);

        for (let i = 0; i < report.video.length; i++) {
          let slideId = report.video[i];
          let index = lead.pseudoTags.findIndex((tag) => tag.slideId === slideId && tag.video);

          if (index === -1) {
            complete = false;
            break;
          }
        }

        if (lead.pseudoTags.findIndex((tag) => tag.campaignId === report.campaignId && tag.video !== null) === -1) {
          complete = undefined;
        }
        components.push(<LeadReportIcon key="lead-report-video" type={Required.VIDEO} complete={complete} />)
      }
      if (report.verified?.length) {
        let complete: boolean | undefined = true;
        for (let i = 0; i < report.verified.length; i++) {
          let widgetId = report.verified[i];
          let index = lead.pseudoTags.findIndex((tag) => tag.widgetId === widgetId && tag.verified);
          if (index === -1) {
            complete = false;
            break;
          }
        }

        if (lead.pseudoTags.findIndex((tag) => tag.campaignId === report.campaignId && tag.verified) === -1) {
          complete = undefined;
        }
        components.push(<LeadReportIcon key="lead-report-verify" type={Required.VERIFY} complete={complete} />)
      }
      if (report.calendar?.length) {
        let complete: boolean | undefined = true;
        for (let i = 0; i < report.calendar.length; i++) {
          let slideId = report.calendar[i];
          let index = lead.pseudoTags.findIndex((tag) => tag.slideId === slideId && tag.scheduled);
          if (index === -1) {
            complete = false;
            break;
          }
        }

        if (lead.pseudoTags.findIndex((tag) => tag.campaignId === report.campaignId && tag.scheduled) === -1) {
          complete = undefined;
        }
        components.push(<LeadReportIcon key="lead-report-calendar" type={Required.CALENDAR} complete={complete} />)
      }
      if (report.response?.length) {
        let complete: boolean | undefined = true;
        for (let i = 0; i < report.response.length; i++) {
          let widgetId = report.response[i];
          let index = lead.pseudoTags.findIndex((tag) => tag.widgetId === widgetId && tag.widget);
          if (index === -1) {
            complete = false;
            break;
          }
        }

        if (lead.pseudoTags.findIndex((tag) => tag.campaignId === report.campaignId && tag.widget) === -1) {
          complete = undefined;
        }
        components.push(<LeadReportIcon key="lead-report-response" type={Required.RESPONSE} complete={complete} />)
      }
      // if (report.unopened?.length) {
      //   for (let i = 0; i < report.unopened.length; i++) {
      //     let pitchId = report.unopened[i];
      //     let index = lead.pseudoTags.findIndex((tag) => tag.pitchId === pitchId && tag.unopened);
      //     if (index !== -1) {
      //       components.push(<LeadReportIcon type={Required.UNOPENED} />)
      //       break;
      //     }
      //   }
      // }
      if (report.optedOut) {
        let index = lead.pseudoTags.findIndex((tag) => tag.optedOut);
        if (index !== -1) {
          components.push(<LeadReportIcon key="lead-report-opted-out" type={Required.OPTEDOUT} />)
        }
      }

      return (
        <View key="lead-report" style={{ flex: 1, marginLeft: 'auto', justifyContent: 'flex-end', flexDirection: 'row', paddingTop: 2 }}>
          {components}
        </View>
      );
    }
  }

  const getInitials = (name: string) => {
    // Get first two prominent characters to be used as a initials
    let names = name.split(' ')
    let it = name.substr(0, 2)
    if (names.length > 1) {
      it = names[0].substr(0, 1) + names[names.length - 1].substr(0, 1)
    }

    return it.toUpperCase();
  }

  const renderCampaigns = () => {
    let campaigns = [];
    if (lead && lead.campaigns && lead.campaigns.length) {
      for (let i = 0; i < lead.campaigns.length; i++) {
        if (i > 3 && lead.campaigns.length - i - 1 > 0) {
          campaigns.push(<span id={`avatar-${i + 1}`} key={`avatar-${i + 1}`} className="wp-avatar-small-faded"><Avatar name={`+ ${lead.campaigns.length - i}`} /></span>)
          break;
        }
        campaigns.push(<span id={`avatar-${i}`} key={`avatar-${i}`} className="wp-avatar-small"><Avatar name={lead.campaigns[i].campaign.name} /></span>)
        // campaign = <Bubble text={lead.campaigns[i].campaign.name} small={true} />
      }
    }

    return (
      <View style={{ width: 200, flexDirection: 'row', gap: 5 }}>
        {campaigns}
        {/* lead.campaigns.length > 1 ? <Text style={{ fontFamily: 'Gotham', color: Constants.colors.info, paddingTop: 4 }}>+{lead.campaigns.length - 1}</Text> : null */}
      </View>
    );
  }

  const formatSeconds = (seconds: number) => {
    if (seconds) {
      return new Date(1000 * seconds).toISOString().substr(14, 5);
    } else {
      return '--:--'
    }
  }

  const onPress = () => {
    if (!removeable && !hoveringEye && !hoveringCampaign) {
      setIsVisible(true);
    }
  }

  const closeDetails = () => {
    setIsVisible(false);
    onRefresh();
  }

  const handleOnRemove = () => {
    if (onRemove !== undefined) {
      onRemove(lead.id)
    }
  }

  const renderControl = () => {
    if (!report && !removeable) {
      if (state.user?.organization?.v2) {
        return (
          <View style={{ marginLeft: 'auto', marginRight: 20, flexDirection: 'row', gap: 5 }}>
            <Hoverable fade={false} style={{ marginLeft: 'auto', marginRight: 20, justifyContent: 'center', alignItems: 'center' }} onPress={handleCreateCampaign} onHover={() => setHoveringCampaign(true)} doneHover={() => setHoveringCampaign(false)}>
              {!loadingCampaign ?
                <Icon
                  name="campaignPlus"
                  height={16}
                  width={20}
                  color={hoveringCampaign ? Constants.colors.white : Constants.colors.primary}
                  style={{
                    padding: 5,
                    borderRadius: 5,
                    width: 28,
                    height: 28,
                    textAlign: 'center',
                    backgroundColor: hoveringCampaign ? Constants.colors.darkBlue : Constants.colors.lightBlue
                  }}
                /> : <Spinner size={20} color={Constants.colors.darkBlue} />}
            </Hoverable>
            <Hoverable fade={false} style={{ marginLeft: 'auto', marginRight: 20, justifyContent: 'center', alignItems: 'center' }} onPress={() => setIsVisible(true)} onHover={() => setHoveringEye(true)} doneHover={() => setHoveringEye(false)}>
              <Icon
                name="eye"
                height={14}
                width={18}
                color={hoveringEye ? Constants.colors.white : Constants.colors.primary}
                style={{
                  padding: 5,
                  paddingTop: 7,
                  borderRadius: 5,
                  width: 28,
                  height: 28,
                  textAlign: 'center',
                  backgroundColor: hoveringEye ? Constants.colors.darkBlue : Constants.colors.lightBlue
                }}
              />
            </Hoverable>
          </View>
        )
      } else {
        return <Ionicons key="lead-row-control" style={{ marginLeft: 'auto', marginRight: 20 }} name={"eye-outline"} size={18} color={Constants.colors.primary} />
      }
    } else if (removeable) {
      return <Feather key="lead-row-control" name="x-circle" size={16} color={hoveringEye ? Constants.colors.red : "grey"} onClick={handleOnRemove} onMouseEnter={onHoverEye} onMouseLeave={onHoverEyeOut} />
    }
    return null;
  }

  const renderTimestamp = (lead: WpLeadBasic) => {
    // <Text key="lead-last-active" style={styles.lastActive}>{lead.lastActive ? tools.formatDateString(lead.lastActive) : (lead.created ? tools.formatDateString(lead.created) : null)}</Text>
    let timestamp = '';
    if (lead.lastActive) {
      timestamp = `Last active ${tools.formatDateString(lead.lastActive)}`
    } else if (lead.created) {
      timestamp = `Created ${tools.formatDateString(lead.created)}`
    }
    return <Text key="lead-last-active" style={styles.lastActive}>{timestamp}</Text>
  }

  const getLeadName = (lead: WpLeadBasic) => {
    if (masked) {
      let firstName = lead.name.split(' ')[0];
      return `${firstName}`
    } else if (lead.name && lead.name !== lead.phone) {
      return lead.name;
    } else {
      return 'New Incoming Lead';
    }
  }

  const getLeadPhone = (lead: WpLeadBasic) => {
    if (masked) {
      let lastFour = lead.phone.substr(lead.phone.length - 4);
      return `(XXX) XXX-${lastFour}`;
    } else if (lead.phone) {
      return tools.formatPhoneNumber(lead.phone);
    } else {
      return 'No Phone Number';
    }
  }

  return (
    <View key={`lead-row-${lead.id}`}>
      <LeadDetailsModal masked={masked} isVisible={isVisible} onClose={closeDetails} leadId={lead.id} />
      <View key={`lead-${lead.id}`} style={[styles.row, { opacity: hovering && !hoveringEye && !hoveringCampaign ? 0.7 : 1, backgroundColor: backgroundColor }]} onLayout={onLayout} onClick={onPress} onMouseEnter={onHover} onMouseLeave={onHoverOut}>
        {selectable ? <Checkbox key="selector" onSelect={handleOnSelect} selected={selected} hoverable={false} /> : <View key="placeholder" style={{ marginRight: 33 }}></View>}
        <View key="lead-info" style={styles.item}>
          <Text key="lead-name" style={{ fontSize: 12 }}>{getLeadName(lead)}</Text>
          {renderTimestamp(lead)}
        </View>
        {viewWidth > 400 && !report ?
          <View key="lead-phone" style={{ width: 200, flexDirection: 'row', alignItems: 'center', paddingRight: 25 }}>
            <Text>{getLeadPhone(lead)} {lead.invalidPhone}</Text>
            {lead.invalidPhone && !lead.optedOut ? <FontAwesome5 style={{ marginLeft: 8 }} name="phone-slash" size={10} color={Constants.colors.red} /> : null}
            {lead.optedOut ? <Entypo name="block" style={{ marginLeft: 5 }} size={12} color={Constants.colors.red} /> : null}
          </View> : null}
        {viewWidth > 500 && !report && !Constants.blastView ? <View key="lead-time" style={{ width: 150 }}><Text style={{ paddingRight: 25 }}>{formatSeconds(lead.stats.seconds)}</Text></View> : null}
        {viewWidth > 600 && !report && !Constants.blastView ? <View key="lead-views" style={{ width: 100 }}><Text style={{ paddingRight: 25 }}>{lead.stats.views ? lead.stats.views : '-'}</Text></View> : null}
        {viewWidth > 700 && !report ? renderCampaigns() : null}
        {renderReport()}
        {renderControl()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
    width: '100%',
    marginBottom: 10,
    backgroundColor: '#FFF',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    height: 45
  },
  item: {
    width: 200,
  },
  lastActive: {
    color: Constants.colors.info,
    fontSize: 10
  }
});

const cssStyles = {
}
