import { Text, View, Pressable, StyleSheet, ActivityIndicator } from 'react-native';
import React, { useState } from 'react';
import Constants from './../constants';
import { XCircleIcon } from '@heroicons/react/solid';

export enum Theme {
  PRIMARY="PRIMARY",
  PRIMARY_NEUTRAL="PRIMARY_NEUTRAL",
  PRIMARY_MEDIUM="PRIMARY_MEDIUM",
  DETAILS="DETAILS",
  ACCENT="ACCENT",
  WARNING="WARNING",
  SUCCESS="SUCCESS",
  WHITE="WHITE",
  DANGER="DANGER",
  DANGER_MEDIUM="DANGER_MEDIUM",
  LINK="LINK",
  LINK_DANGER="LINK_DANGER",
  LINK_PRIMARY="LINK_PRIMARY",
  LINK_PRIMARY_MEDIUM="LINK_PRIMARY_MEDIUM",
  LINK_WARNING="LINK_WARNING",
  LINK_NEUTRAL="LINK_NEUTRAL",
}

export enum Hoverable {
  BUTTON="BUTTON",
  DELETE="DELETE"
}

export function Button({ text, onPress, subText, small=false, loading=false, disabled=false, icon=null, style={}, buttonStyles={}, textStyles={}, width=100, theme=Theme.PRIMARY, onDelete=null, circle=false, shadow=true, children, childrenHover } : { style?:object, small?:boolean, subText?:string, loading?:boolean, disabled?:boolean, icon?:JSX.Element|null, buttonStyles?:object, textStyles?:object, text?:string, onPress?: () => void, shadow?: boolean, width?:number|string, theme?:Theme, onDelete?:Function|null, circle?:boolean, children?: JSX.Element|JSX.Element[], childrenHover?: JSX.Element|JSX.Element[] }): JSX.Element {
  const [hovering, setHovering] = useState<Hoverable>();

  const onHover = (what:Hoverable) => {
    if (!disabled && !loading && ((what === Hoverable.DELETE && onDelete !== undefined) || (what === Hoverable.BUTTON && onPress !== undefined))) {
      setHovering(what);
    }
  }

  const onHoverOut = () => {
    setHovering(undefined);
  }

  let defaultStyles = {
    width: width,
    opacity: hovering === Hoverable.BUTTON || disabled || loading ? 0.7 : 1,
    textDecorationLine: hovering === Hoverable.BUTTON && onDelete !== null ? 'underline' : 'none',
  }
  if (circle) {
    defaultStyles.height = width;
  } else {
    defaultStyles.padding = small ? 7 : 10;
  }

  let extraStyles = shadow ? {
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.57,
    shadowRadius: 15.19,
    shadowColor: Constants.colors.primaryLight,
  } : {};

  extraStyles.backgroundColor = Constants.colors.primary;
  extraStyles.elevation = 23;

  let textExtraStyles = {
    textDecorationStyle: 'none',
    color: '#FFF',
    fontSize: small ? 12 : 14,
    fontWeight: 'bold',
    paddingRight: onDelete !== null ? 25 : undefined,
  }

  let deletableExtraStyles = {
    color: '#FFF'
  }

  if (theme === Theme.ACCENT) {
    extraStyles.backgroundColor = Constants.colors.accent;
    extraStyles.shadowColor = !shadow ? undefined : Constants.colors.accentLight
  } else if (theme === Theme.PRIMARY_MEDIUM) {
    extraStyles.backgroundColor = Constants.colors.primaryMedium;
    extraStyles.shadowColor = !shadow ? undefined : Constants.colors.primaryLight
  } else if (theme === Theme.SUCCESS) {
    extraStyles.backgroundColor = Constants.colors.success;
    extraStyles.shadowColor = !shadow ? undefined : Constants.colors.success
  } else if (theme === Theme.WHITE) {
    extraStyles.backgroundColor = '#FFFFFF';
    extraStyles.shadowColor = !shadow ? undefined : '#FFFFFF'

    textExtraStyles.color = Constants.colors.darkBlue;
    textExtraStyles.textDecorationLine = hovering === Hoverable.BUTTON ? 'underline' : 'none';
  } else if (theme === Theme.DANGER) {
    extraStyles.backgroundColor = Constants.colors.danger;
    extraStyles.shadowColor = !shadow ? undefined : Constants.colors.dangerLight
  } else if (theme === Theme.DANGER_MEDIUM) {
    extraStyles.backgroundColor = Constants.colors.dangerMedium;
    extraStyles.shadowColor = !shadow ? undefined : Constants.colors.dangerLight
  } else if (theme === Theme.WARNING) {
    extraStyles.backgroundColor = Constants.colors.warning;
    extraStyles.shadowColor = !shadow ? undefined : Constants.colors.warningLight
  } else if (theme === Theme.LINK) {
    extraStyles = {};
    textExtraStyles.color = Constants.colors.secondary;
    textExtraStyles.textDecorationLine = hovering === Hoverable.BUTTON ? 'underline' : 'none';
  } else if (theme === Theme.LINK_PRIMARY) {
    extraStyles = {};
    textExtraStyles.color = Constants.colors.primary;
    textExtraStyles.textDecorationLine = hovering === Hoverable.BUTTON ? 'underline' : 'none';
  } else if (theme === Theme.LINK_PRIMARY_MEDIUM) {
    extraStyles = {};
    textExtraStyles.color = Constants.colors.primaryMedium;
    textExtraStyles.textDecorationLine = hovering === Hoverable.BUTTON ? 'underline' : 'none';
  } else if (theme === Theme.LINK_DANGER) {
    extraStyles = {};
    textExtraStyles.color = Constants.colors.danger;
    textExtraStyles.textDecorationLine = hovering === Hoverable.BUTTON ? 'underline' : 'none';
  } else if (theme === Theme.LINK_WARNING) {
    extraStyles = {};
    textExtraStyles.color = Constants.colors.warning;
    textExtraStyles.textDecorationLine = hovering === Hoverable.BUTTON ? 'underline' : 'none';
  } else if (theme === Theme.LINK_NEUTRAL) {
    extraStyles = {};
    textExtraStyles.color = Constants.colors.grey;
    textExtraStyles.textDecorationLine = hovering === Hoverable.BUTTON ? 'underline' : 'none';
  } else if (theme === Theme.PRIMARY_NEUTRAL) {
    extraStyles = {
      backgroundColor: hovering === Hoverable.BUTTON ? Constants.colors.primary : '#FFF'
    };
    textExtraStyles.color = hovering === Hoverable.BUTTON ? '#FFF' : Constants.colors.primary;
  } else if (theme === Theme.DETAILS) {
    // color a tiny bit darker than #F5F7FB
    extraStyles = {
      backgroundColor: hovering === Hoverable.BUTTON ? '#EDEFF2' : '#F5F7FB'
    };
    deletableExtraStyles.color = Constants.colors.darkBlue;
    textExtraStyles.color = Constants.colors.darkBlue;
  }

  const handleOnPress = (which:Hoverable) => {
    if (hovering === Hoverable.DELETE && onDelete !== null && which === Hoverable.DELETE) {
      onDelete();
    } else if (which === Hoverable.BUTTON && hovering !== Hoverable.DELETE) {
      setHovering(undefined);
      if (onPress) {
        onPress();
      }
    }
  }

  const renderBody = () => {
    if (loading) {
      return <ActivityIndicator color={'#FFF'} />
    } else {
      if (children) {
        return hovering === Hoverable.BUTTON ? childrenHover : children
      } else {
        if (subText) {
          return (
            <View style={{ alignItems: 'center', position: 'relative', zIndex: 1 }}>
              <Text style={[styles.text, textExtraStyles, textStyles]}>{text}</Text>
              <Text style={[styles.text, textExtraStyles, styles.subText]}>{subText}</Text>
            </View>
          )
        } else {
          return <Text style={[styles.text, textExtraStyles, textStyles, { position: 'relative', zIndex: 1 }]}>{text}</Text>
        }

      }
    }
  }

  return (
    <View style={style}>
      <Pressable disabled={disabled || loading || (onPress === undefined && onDelete === undefined)} style={[styles.button, extraStyles, defaultStyles, buttonStyles]} onPress={() => handleOnPress(Hoverable.BUTTON)} onMouseEnter={() => onHover(Hoverable.BUTTON)} onMouseLeave={onHoverOut}>
        { icon }
        { renderBody() }
        { onDelete !== null ? <XCircleIcon style={{...cssStyles.deletable, ...deletableExtraStyles, opacity: hovering === Hoverable.DELETE ? 0.7 : 1 }} onClick={() => handleOnPress(Hoverable.DELETE)} onMouseEnter={() => onHover(Hoverable.DELETE)} onMouseLeave={() => onHover(Hoverable.BUTTON)} /> : null }
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    color: '#FFF',
    borderRadius: 20,
    textAlign: 'center',
    position: 'relative',
  },
  text: {
    fontFamily: 'GothamMedium'
  },
  subText: {
    opacity: 0.7,
    fontSize: 10
  }
});

const cssStyles = {
  deletable: {
    color: '#FFF',
    width: 20,
    height: 20,
    position: 'absolute',
    top: 8,
    right: 10,
    cursor: 'pointer',
    zIndex: 2,
  }
}
