import { Text, View, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import MessageEditor from './../MessageEditor';
import CampaignDropdown from './../CampaignDropdown';
import GroupDropdown from './../GroupDropdown';
import { Theme } from './../Button';
import LeadRow from './UserRow';
import Checkbox from './../Checkbox';
import { Label, Info } from './../Input';
import { GET_LEADS, GET_USER, GET_USERS } from './../../graphql/queries';
import { CREATE_CUSTOM_VALUE, DELETE_USERS, UPDATE_USER } from './../../graphql/user';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Ionicons } from '@expo/vector-icons';
import Icon from '../Icon';
import { IconButton, Button } from '@whistlepitch/wp-components';
import Spinner from '../Spinner';
import Toast from 'react-native-toast-message';
import { WpUser } from '../../contexts/user';
import UserRow from './UserRow';
import UserDetailsModal from './UserDetailsModal';

export interface Filter {
  unopened?: string[],
  complete?: string[],
  incomplete?: string[],
  slideIdsSeen?: string[]
  slideIdsUnseen?: string[]
}

export interface Report {
  campaignId: string,
  verified?: string[],
  video?: string[],
  response?: string[],
  calendar?: string[],
  redirected?: string[],
  unopened?: string[],
  optedOut?: boolean,
}

export default function UserTable({ refresh }: { refresh?: number }): JSX.Element {
  const [user, setUser] = useState<WpUser>();
  const [users, setUsers] = useState<WpUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [showUser, setShowUser] = useState<boolean>(false);

  const [getUsers, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_USERS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [getUser, getUserData] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      includeBilling: true
    }
  });

  const [deleteUsers, deleteUsersData] = useMutation(DELETE_USERS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.users?.users) {
      setUsers(data.users.users)
    }
  }, [data, networkStatus])

  useEffect(() => {
    if (getUserData.data?.user?.user) {
      setUser(getUserData.data.user.user)
    }
  }, [getUserData.data, getUserData.networkStatus])

  useEffect(() => {
    loadUsers();
  }, [refresh, deleteUsersData.data])

  const loadUsers = () => {
    if (data) {
      refetch();
    } else {
      getUsers()
    }
  }

  const onSelect = (uid: string) => {
    let lindex = selectedUsers.indexOf(uid)
    if (lindex > -1) {
      setSelectedUsers([
        ...selectedUsers.slice(0, lindex),
        ...selectedUsers.slice(lindex + 1),
      ])
    } else {
      setSelectedUsers([...selectedUsers, uid])
    }
  }

  const onClickUser = (uid: string) => {
    setUser(undefined)
    setShowUser(true)
    getUser({ variables: { userId: uid } })
  }

  const onCloseUser = () => {
    setShowUser(false)
    setUser(undefined)
  }

  const onSaveUser = () => {
    loadUsers();
    setShowUser(false)
    setUser(undefined)
  }

  const renderUsers = () => {
    if (loading) {
      return <Spinner style={{ paddingVertical: 25 }} />
    } else if (users.length) {
      return users.map((u) => <UserRow key={`user-row-${u.id}`} onClick={onClickUser} user={u} onSelect={onSelect} selected={selectedUsers.indexOf(u.id) > -1} />)
    } else {
      return <Text key="users-not-found" style={{ flex: 1, textAlign: 'center', color: Constants.colors.secondary, paddingVertical: 25 }}>- No Users Found -</Text>
    }
  }

  return (
    <View style={{ flex: 1, minWidth: 250 }}>
      <TableControls loadUsers={loadUsers} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
      <View style={styles.table}>
        <View key={`user-header-row`} style={styles.tableHeader}>
          <Text key="user-name" style={{ fontSize: 14, width: 250, marginLeft: 50, fontFamily: 'GothamMedium', color: Constants.colors.secondary }}>Name</Text>
          <Text key="user-phone" style={{ fontSize: 14, width: 150, fontFamily: 'GothamMedium', color: Constants.colors.secondary }}>Phone</Text>
          <Text key="user-email" style={{ fontSize: 14, width: 200, fontFamily: 'GothamMedium', color: Constants.colors.secondary }}>Email</Text>
          <Text key="user-access" style={{ fontSize: 14, marginLeft: 'auto', marginRight: 45, fontFamily: 'GothamMedium', color: Constants.colors.secondary }}>Permission</Text>
        </View>
        {renderUsers()}
      </View>
      <UserDetailsModal key={`user-modal`} isVisible={showUser} user={user} onSave={onSaveUser} onClose={onCloseUser} />
    </View>
  );
}

export enum UserAction {
  DELETE = "DELETE",
}

export function TableControls({ loadUsers, selectedUsers, setSelectedUsers }: { loadUsers: () => void, selectedUsers: string[], setSelectedUsers: Function }): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<UserAction>();

  const [deleteUsers, deleteUsersData] = useMutation(DELETE_USERS, {
    fetchPolicy: "no-cache",
  });

  const closeModal = () => {
    setIsVisible(false);
    setLoading(false);
    setAction(undefined);
  }

  useEffect(() => {
    if (deleteUsersData.data) {
      closeModal();
      loadUsers();
    }
  }, [deleteUsersData.data])

  const onClear = () => {
    setSelectedUsers([]);
  }

  const selectAll = () => {
    console.log("Select All!")
  }

  const onDelete = () => {
    startAction(UserAction.DELETE);
  }

  const startAction = (action: UserAction) => {
    if (selectedUsers.length > 0) {
      setAction(action);
      openModal();
    } else {
      Toast.show({
        type: 'error',
        text1: 'Select Users',
        text2: `Select users from the table first.`
      });
    }
  }

  const openModal = () => {
    setIsVisible(true);
  }

  const renderBody = () => {
    switch (action) {
      case UserAction.DELETE:
        return (<Text>Deleting a user will remove them from your organization. They will no longer have any access to your leads, campaigns, or any other WhistlePitch data associated with your organization.</Text>)
      default:
        return null;
    }
  }

  const getTitle = () => {
    switch (action) {
      default:
        return "Are You Sure?"
    }
  }

  const getButtonText = () => {
    switch (action) {
      case UserAction.DELETE:
        return "Delete"
      default:
        return "Confirm"
    }
  }

  const getSelectedText = () => {
    if (selectedUsers.length) {
      return `${selectedUsers.length} User${selectedUsers.length > 1 ? 's' : ''} Selected`
    } else {
      return 'Select'
    }
  }

  const isDisabled = () => {
    switch (action) {
      default:
        return false
    }
  }

  const onConfirm = () => {
    setLoading(true);
    switch (action) {
      case UserAction.DELETE:
        deleteUsers({ variables: { userIds: selectedUsers } });
        setSelectedUsers([]);
        return;
      default:
        closeModal();
    }
  }

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginBottom: 20 }}>
      <WpModal isVisible={isVisible} closeModal={closeModal}>
        <ModalBody title={getTitle()} closeModal={closeModal}>
          {renderBody()}
          <ModalFooter>
            <Button disabled={isDisabled()} onClick={onConfirm} shape="Pill" size="Default" type={action === UserAction.DELETE ? "Danger" : "Primary"}>
              {loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>{getButtonText()}</Text>}
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 10, marginBottom: 5 }}>
        {selectedUsers.length > 0 ?
          <View style={styles.controlsWrapper}>
            <View style={styles.controls}>
              <Checkbox color={Constants.colors.darkBlue} onSelect={onClear} selected={true} />
              <Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium' }}>{getSelectedText()}</Text>
            </View>
          </View> : null}
        <IconButton type="White" shape="Circle" onClick={onDelete}>
          <Icon name="trash" width={20} raw={true} colorHover="currentColor" color="currentColor" />
        </IconButton>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    padding: 8,
    borderRadius: 8,
    width: '100%',
    border: '1px solid ' + Constants.colors.info + '44',
    marginBottom: 10
  },
  controlsWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: Constants.colors.lightBlue,
    alignItems: 'center',
    marginRight: 10
  },
  controls: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controlButton: {
    marginRight: 10,
    marginVertical: 10
  },
  tableHeader: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottomColor: '#E5E5E5',
    borderBottomWidth: 1,
    paddingVertical: 20
  },
  table: {
    backgroundColor: '#FFF',
    borderRadius: 15,
    overflow: 'hidden'
  }
});

const cssStyles = {
}
