import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import Dropdown, { Item } from './Dropdown';
import { Button, Theme } from './Button';
import { Label, Info } from './Input';
import NewCustomValueModal from './NewCustomValueModal';
import CalendlyOauthPopup from './User/CalendlyOauthPopup';
import { GET_CUSTOM_VALUES } from './../graphql/queries';
import { useLazyQuery } from "@apollo/client";
import { UserContext, WpIntegrationType } from '../contexts/user';


export default function CustomFieldDropdown({ value, setValue, placeholder, showCreateNew=false, model="USER", leadId, userId, calendarOnly, calendlyOnly, style={} } : { value:string|string[]|undefined, setValue: (id: string|string[], label?: string) => void, placeholder?: string, showCreateNew?: boolean, model?: "USER"|"LEAD", leadId?: string, userId?: string, calendarOnly?: boolean, calendlyOnly?: boolean, style?:object }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext)

  const [fieldItems, setFieldItems] = useState<Item[]>([]);
  const [search, setSearch] = useState<string>();
  const [showNewValue, setShowNewValue] = useState<boolean>(false);

  const [getCustomValues, { data, loading, networkStatus, refetch }] = useLazyQuery(GET_CUSTOM_VALUES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (data?.customValues?.values) {
      let fields: Item[] = [];
      if (!calendlyOnly) {
        fields = [{value: 'new', label: '+ Add Calendly', labelStyle: { color: Constants.colors.primary }}]
      }

      for (let i = 0; i < data.customValues.values.length; i++) {
        let c = data.customValues.values[i];
        fields.push({value: c.id, label: `${c.modelName} - ${c.name}`})
      }
      setFieldItems(fields)
    }
  }, [data, networkStatus])

  // useEffect(() => {
  //   loadFields();
  // }, [search])

  const loadFields = () => {
    let variables = { model, userId, leadId, search, calendarOnly, calendlyOnly };

    if (data) {
      refetch(variables);
    } else {
      getCustomValues({ variables: variables })
    }
  }

  const onCloseNewValue = () => {
    setShowNewValue(false);
  }

  const onCreateValue = () => {
    setShowNewValue(true);
  }

  const onSetValue = (id: string | string[]) => {
    if (!Array.isArray(id) && id === 'new') {
      onCreateValue();
    } else {
      let this_id: string | string[] | undefined = id;
      if (Array.isArray(id)) {
        if (id.length) {
          this_id = id[0];
        } else {
          this_id = undefined;
        }
      }

      if (this_id) {
        let vindex = fieldItems.findIndex((item) => item.value === this_id)
        let label = vindex === -1 ? undefined : fieldItems[vindex].label;
        setValue(id, label)
        onCloseNewValue();
      }
    }
  }

  const updateSearch = (s: string) => {
    setSearch(s);
  }

  return (
    <View style={[{ position: 'relative', zIndex: 2 }, style]}>
      <NewCustomValueModal isVisible={showNewValue} onClose={onCloseNewValue} onCreated={onSetValue} isCalendar={true} />
      <Dropdown
        key={`dropdown-custom-fields`}
        style={{ position: 'relative', zIndex: 1 }}
        placeholder={placeholder ? placeholder : "Search Options..."}
        items={fieldItems}
        value={value}
        loading={loading}
        onChange={onSetValue}
        onOpen={loadFields}
        onChangeSearchText={updateSearch}
        ListEmptyComponent={() => (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <Text style={{ color: Constants.colors.info, textAlign: 'center' }}>Not Found.</Text>
              <Button text="Create New" theme={Theme.LINK_PRIMARY_MEDIUM} width={120} onPress={onCreateValue} />
          </View>
        )}
      />
      { showCreateNew ? <Button text="Create New" width={'100%'} theme={Theme.LINK_PRIMARY_MEDIUM} onPress={onCreateValue} /> : null}
      { calendarOnly && state.user && state.user.integrations.findIndex(integration => integration.integrationType === WpIntegrationType.CALENDLY) === -1 ?
        <CalendlyOauthPopup text="Connect Calendly"/> : null }
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
