import { View, Text, ActivityIndicator, StyleSheet, Dimensions } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useParams } from '../../react-router';

import Constants from '../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import Dropzone from './../Dropzone';
import { Button, Theme } from './../Button';
import Input, { Label } from './../Input';
import Dropdown, { Item } from './../Dropdown';
import { INVITE_USERS } from '../../graphql/user';
import { UserContext, Access } from '../../contexts/user';
import { useMutation } from "@apollo/client";
import { TrashIcon } from '@heroicons/react/outline';


const window = Dimensions.get('window');

interface NewUser {
  email: string,
  role: Access
}

const defaultNew:NewUser = {
  email: '',
  role: Access.LIMITED
}

const access_items: Item[] = [
  {
    label: "Notifications Only",
    value: Access.LIMITED,
  },
  {
    label: "Can View Account",
    value: Access.READONLY,
  },
  {
    label: "Can Make Changes",
    value: Access.MANAGER,
  },
  {
    label: "Admin",
    value: Access.ADMIN,
  },
]

export default function NewUserModal({ isVisible, onClose, onCreated, campaignId, groupId } : { isVisible:boolean, onClose:Function, onCreated: (emails: string[]) => void, campaignId?: string, groupId?: string }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);

  const [dimensions, setDimensions]= useState(window)

  const [users, setUsers]= useState<NewUser[]>([defaultNew])
  const [hovering, setHovering]= useState<number>()

  const [inviteUsers, { data, loading }] = useMutation(INVITE_USERS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.inviteUsers?.users) {
      onCreated(data.inviteUsers.users);
      onClose();
    }
  }, [data])

  // useEffect(() => {
  //   console.log('users', users)
  // }, [users])

  const addAnotherUser = () => {
    setUsers([...users, defaultNew])
  }

  const removeUser = (i: number) => {
    setUsers([
       ...users.slice(0, i),
       ...users.slice(i + 1),
    ])
    onHoverOut();
  }

  const onHover = (i: number) => {
    setHovering(i)
  }

  const onHoverOut = () => {
    setHovering(undefined)
  }

  const onContinue = () => {
    inviteUsers({ variables: {
      users: users,
      campaignId: campaignId,
      groupId: groupId
    }})
  }

  const setEmail = (val: string, i: number) => {
    setUsers([
       ...users.slice(0, i),
       {
         ...users[i],
         email: val
       },
       ...users.slice(i + 1),
    ])
  }

  const setRole = (val: string, i: number) => {
    let role = Access.LIMITED;

    if (val === Access.MANAGER) {
      role = Access.MANAGER;
    } else if (val === Access.READONLY) {
      role = Access.READONLY;
    } else if (val === Access.ADMIN) {
      role = Access.ADMIN;
    }

    setUsers([
       ...users.slice(0, i),
       {
         ...users[i],
         role: role
       },
       ...users.slice(i + 1),
    ])
  }

  const renderUser = (user: NewUser, i: number) => {
    return (
      <View key={`user-${i}`} style={{ flex: 1, justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row', gap: 10, position: 'relative', zIndex: 15, marginBottom: 10 }}>
        <Input key={`user-email-${i}`} style={{ flex: 1 }} name={i === 0 ? "User Email" : undefined} value={user.email} placeholder="Enter User Email..." setValue={(val:string) => setEmail(val, i)} />
        <View style={{ flex: 1, zIndex: 3, alignSelf: 'flex-start', position: 'relative' }}>
          { i === 0 ? <Label name={"User Access Level"} /> : null }
          <Dropdown items={access_items} small={true} placeholder="User Access Level" multiple={false} value={user.role ? user.role : Access.LIMITED} onChange={(val:string) => setRole(val, i)} />
        </View>
        <TrashIcon style={{...cssStyles.icon, color: hovering === i ? Constants.colors.danger : Constants.colors.info}} onClick={() => removeUser(i)} onMouseEnter={() => onHover(i)} onMouseLeave={onHoverOut} />
      </View>
    )
  }

  return (
    <WpModal isVisible={isVisible} closeModal={onClose}>
      <ModalBody style={{ position: 'relative' }} title="New User" closeModal={onClose} style={{ width: dimensions.width * 0.8, maxWidth: 650 }}>
        <View style={{ flex: 1, position: 'relative' }}>
          { users.map(( user, i ) => renderUser(user, i))}
          <Text onPress={addAnotherUser} style={styles.newBtn}>+ Add Another User</Text>
          <View key="controls" style={{ width: '100%', justifyContent: 'flex-end', flexDirection: 'row', position: 'relative', zIndex: 1 }}>
            <Button key="btn-trigger-edit-save" loading={loading} width={125} text="Continue" onPress={onContinue} />
          </View>
          {/*}<View key="divider" style={styles.divider}></View>
          <View key="dropzone" style={{ flex: 1 }}>
            <Dropzone />
          </View>*/}
        </View>
      </ModalBody>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  newBtn: {
    marginTop: 15,
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
