import { View, StyleSheet, Text, Image } from 'react-native';
import React, { useState } from 'react';
import Constants from './../../constants';
import Icon, { IconTypes } from '../Icon';
import Checkbox from '../Checkbox';
import Hoverable from './Hoverable';

export default function Thumbnail({ onPress, selected, onSelect, image, text, body, info, icon = "users", subtext, action, description, descriptionIcon, large = false, short = false }: { onPress?: () => void, image?: string, selectable?: boolean, selected?: boolean, onSelect?: () => void, text: string, body?: string, info?: string, icon?: IconTypes, subtext?: string, action?: JSX.Element, description?: string, descriptionIcon?: IconTypes, large?: boolean, short?: boolean }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);

  const onHover = () => {
    if (onPress) {
      setHovering(true);
    }
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const handleOnPress = () => {
    if (onPress) {
      onPress();
    }
  }

  const handleOnSelect = () => {
    if (onSelect) {
      onSelect();
    }
  }

  if (large) {
    return (
      <View style={[!!onSelect ? styles.thumbnailSelectable : styles.thumbnail, { opacity: hovering ? 0.7 : 1, cursor: onPress ? 'pointer' : 'default', backgroundColor: !!onSelect && (selected || hovering) ? Constants.colors.lightBlue : '#FFF' }, large ? { width: 260, backgroundColor: Constants.colors.lightBlue, height: 170 } : {}]} onClick={handleOnPress} onMouseEnter={onHover} onMouseLeave={onHoverOut}>
        {!!onSelect ? <Checkbox style={styles.checkbox} selected={selected} onSelect={onSelect} /> :
          <View style={[styles.imageWrapper, { backgroundColor: Constants.colors.lightBlue }, large ? { height: 110 } : {}]}>
            {image ? <Image style={styles.image} source={{ uri: image }} /> : null}
          </View>}
        <View style={large ? { padding: 13 } : { padding: 30 }}>
          <Text style={[styles.text, { flex: 1 }]}>{text}</Text>
          {subtext ?
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
              <Icon style={{ position: 'relative', top: 5 }} width={20} name="users" />
              <Text style={styles.subtext}>{subtext}</Text>
            </View> : null}
        </View>
      </View>
    );
  }

  // TODO: Truncate long text
  return (
    <View style={[styles.thumbnail, { flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', opacity: hovering ? 0.85 : 1, cursor: onPress ? 'pointer' : 'default', backgroundColor: !!onSelect && (selected || hovering) ? Constants.colors.lightBlue : '#FFF', width: short ? 285 : 500, maxWidth: '100%', position: 'relative' }]} onClick={handleOnPress} onMouseEnter={onHover} onMouseLeave={onHoverOut}>
      {!!onSelect ? <Checkbox style={styles.checkbox} selected={selected} onSelect={onSelect} /> : null}
      {image ?
        <View style={[styles.imageWrapper, { backgroundColor: Constants.colors.lightBlue }, { height: 50 }]}>
          <Image style={styles.image} source={{ uri: image }} />
        </View> : null}
      <View style={{ paddingRight: 18, paddingVertical: 18, paddingLeft: !!onSelect ? 0 : 18, width: '100%', flexDirection: 'row' }}>
        <View style={{ flex: 1, position: 'relative', zIndex: 5 }}>
          <View style={{ maxWidth: '90%', flexDirection: 'row', alignItems: 'center', position: 'relative', zIndex: 10 }}>
            <Text style={[styles.text]} numberOfLines={1}>{text}</Text>
            {info ?
              <Hoverable tooltip={info} top={30} left={'-40%'} style={{ marginLeft: 5, position: 'relative', zIndex: 100 }}>
                <Icon name="info" />
              </Hoverable> : null}
          </View>
          {body ? <Text style={[styles.body, { maxWidth: '90%', position: 'relative', zIndex: 1 }]}>{body}</Text> : null}
          {description ?
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, position: 'relative', zIndex: 1 }}>
              {descriptionIcon ? <Icon style={{ position: 'relative', top: 5 }} width={20} name={descriptionIcon} /> : null}
              <Text style={styles.subtext}>{description}</Text>
            </View> : null}
          {subtext ?
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10, position: 'relative', zIndex: 1 }}>
              <Icon style={{ position: 'relative', top: 5 }} width={20} name={icon} />
              <Text style={styles.subtext}>{subtext}</Text>
            </View> : null}
        </View>
        {action ? action : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  thumbnail: {
    borderRadius: 10,
    fontFamily: 'GothamMedium',
    fontWeight: 'bold',
    position: 'relative',
    width: 285,
  },
  thumbnailSelectable: {
    borderRadius: 10,
    fontFamily: 'GothamMedium',
    fontWeight: 'bold',
    position: 'relative',
    width: 285,
    justifyContent: 'center',
    alignItems: 'center',
    height: 200
  },
  imageWrapper: {
    height: 145,
    width: '100%',
    backgroundColor: Constants.colors.secondaryLight,
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium'
  },
  body: {
    flex: 1,
    fontSize: 14,
    paddingTop: 10,
    fontWeight: 'bold',
    color: '#7C7C7C',
    fontFamily: 'GothamMedium'
  },
  subtext: {
    paddingTop: 10,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#7C7C7C',
    fontFamily: 'GothamMedium'
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
  },
  checkbox: {
    paddingLeft: 15
  }
});
