import { View, Text, ActivityIndicator, StyleSheet, Dimensions } from 'react-native';
import React, { useEffect, useState } from 'react';

import Constants from '../constants';
import WpModal from './WpModal';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import { Button, Theme } from './Button';
import Input from './Input';
import UserDropdown from './UserDropdown';
import { CREATE_CUSTOM_VALUE } from '../graphql/user';
import { UserContext, WpCustomValue } from '../contexts/user';
import { useMutation } from "@apollo/client";
import { TrashIcon } from '@heroicons/react/outline';


const window = Dimensions.get('window');

interface NewValue {
  name: string,
  value: string,
  userId?: string,
  leadId?: string,
}

const defaultNew:NewValue = {
  name: '',
  value: ''
}

export default function NewCustomValueModal({ isVisible, onClose, onCreated, model="USER", isCalendar } : { isVisible:boolean, onClose: () => void, onCreated: (id: string|string[]) => void, model?: "USER"|"LEAD", isCalendar?: boolean }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext)

  const [values, setValues]= useState<NewValue[]>([defaultNew])

  const [modelName, setModelName] = useState<"Calendly"|"Custom Value">(isCalendar ? "Calendly" : "Custom Value")
  const [hovering, setHovering]= useState<number>()

  const [createCustomValues, { data, loading }] = useMutation(CREATE_CUSTOM_VALUE, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.createCustomValues?.values) {
      let ids = [];
      for (let i = 0; i < data.createCustomValues.values.length; i++) {
        ids.push(data.createCustomValues.values[i].id)
      }
      onCreated(ids);
      onClose();
    }
  }, [data])

  const onContinue = () => {
    createCustomValues({ variables: {
      values: values,
      isCalendar: isCalendar,
    }})
  }

  const addAnotherValue = () => {
    setValues([...values, defaultNew])
  }

  const removeValue = (i: number) => {
    setValues([
       ...values.slice(0, i),
       ...values.slice(i + 1),
    ])
    onHoverOut();
  }

  const onHover = (i: number) => {
    setHovering(i)
  }

  const onHoverOut = () => {
    setHovering(undefined)
  }

  const setName = (val: string, i: number) => {
    setValues([
       ...values.slice(0, i),
       {
         ...values[i],
         name: val
       },
       ...values.slice(i + 1),
    ])
  }

  const setValue = (val: string, i: number) => {
    setValues([
       ...values.slice(0, i),
       {
         ...values[i],
         value: val
       },
       ...values.slice(i + 1),
    ])
  }

  const setUserId = (val: string, i: number) => {
    setValues([
       ...values.slice(0, i),
       {
         ...values[i],
         userId: val
       },
       ...values.slice(i + 1),
    ])
  }

  const setLeadId = (val: string, i: number) => {
    setValues([
       ...values.slice(0, i),
       {
         ...values[i],
         leadId: val
       },
       ...values.slice(i + 1),
    ])
  }

  const renderValue = (value: NewValue, i: number) => {
    return (
      <View key={`lead-${i}`} style={{ flex: 1, justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row', gap: 10, position: 'relative', zIndex: 2 }}>
        <Input key={`value-name-${i}`} style={{ flex: 1 }} name={i === 0 ? `Name` : undefined} value={value.name} placeholder={`Enter ${modelName} Name...`} setValue={(val:string) => setName(val, i)} />
        <Input key={`value-value-${i}`} style={{ flex: 1 }} name={i === 0 ? (modelName === 'Calendly' ? 'Calendly Link' : `${modelName}`) : undefined} value={value.value} placeholder={`Enter ${(modelName === 'Calendly' ? 'Calendly Link' : modelName)}...`} setValue={(val:string) => setValue(val, i)} />
        { model === "USER" ? <UserDropdown style={{ flex: 1, zIndex: 3, alignSelf: 'flex-start' }} label={i === 0 ? 'User' : undefined} small={true} placeholder="Select User" multiple={false} value={value.userId ? value.userId : state.user?.id} setValue={(val:string) => setUserId(val, i)} /> : null }
        <TrashIcon style={{...cssStyles.icon, color: hovering === i ? Constants.colors.danger : Constants.colors.info}} onClick={() => removeValue(i)} onMouseEnter={() => onHover(i)} onMouseLeave={onHoverOut} />
      </View>
    )
  }

  return (
    <WpModal isVisible={isVisible} closeModal={onClose}>
      <ModalBody title={`New ${modelName}`} closeModal={onClose} width={650}>
        <View style={{ flex: 1, position: 'relative' }}>
          { values.map(( value, i ) => renderValue(value, i))}
          <Text onPress={addAnotherValue} style={styles.newBtn}>{`+ Add ${modelName}`}</Text>
          <View key="controls" style={{ width: '100%', justifyContent: 'center', flexDirection: 'row', position: 'relative', zIndex: 1 }}>
            <Button key="btn-trigger-edit-save" loading={loading} width={125} text="Continue" onPress={onContinue} />
          </View>
        </View>
      </ModalBody>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
    marginVertical: 15
  },
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
