import { View, Text, ActivityIndicator, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from '../react-router';
import { useQuery } from "@apollo/client";

import Constants from '../constants';
import { CampaignContext, WpCampaignAction, campaignReducer } from '../contexts/campaign';
import { UserContext } from '../contexts/user';
import { GET_CAMPAIGN } from '../graphql/queries';
import CampaignOverview from '../components/CampaignOverview';
import CampaignEditor from '../components/Campaign/CampaignEditor';
import Navbar from '../components/Navbar';
import { DashboardNav } from '@whistlepitch/wp-components';
import UserDetailsModal from '../components/User/UserDetailsModal';
import BasicLayout from '../components/Shared/BasicLayout';


export default function Campaign(): JSX.Element {
  const { state } = React.useContext(UserContext);

  const [campaignState, dispatch] = React.useReducer(campaignReducer, {campaign: null});
  const params = useParams();

  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);

  const navigate = useNavigate();

  const onRefresh = () => {
    setRefresh(refresh + 1)
  }

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  // return (
  //   <View style={styles.container}>
  //     <Navbar active={'/campaigns'} campaignId={params.campaignId} onRefresh={onRefresh} />
  //     <View style={{ flex: 1, padding: 25 }}>
  //       <UserDetailsModal isVisible={showUserDetails} user={state.user ? state.user : undefined} onClose={closeUserDetails} />
  //       <DashboardNav name={state.user?.name ?? ''} dropOptions={Constants.dropdownOptions} actionOne={state.user?.wpAdmin} onAction={onDashboardAction} onDropdownSelect={onDashboardAction} />
  //
  //       <View style={{ marginTop: 25 }}></View>
  //       <CampaignContext.Provider value={{ state: campaignState, dispatch }}>
  //         <CampaignEditor campaignId={params.campaignId} refresh={refresh} />
  //       </CampaignContext.Provider>
  //     </View>
  //   </View>
  // );

  return (
    <BasicLayout style={styles.container} active={'/campaigns'}>
      <CampaignContext.Provider value={{ state: campaignState, dispatch }}>
        <CampaignEditor campaignId={params.campaignId} refresh={refresh} />
      </CampaignContext.Provider>
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: Constants.colors.backgroundLight,
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
});
