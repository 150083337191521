import { View, Text, ActivityIndicator, StyleSheet, Dimensions, LayoutChangeEvent, Image } from 'react-native';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate } from '../../react-router';

import { formatDateString } from './../../tools/tools';
import Constants from '../../constants';
import Spinner from './../Spinner';
import Input, { Label } from './../Input';
import UserDropdown from './../UserDropdown';
import MessageFeed from './../Shared/MessageFeed';
import { GET_LEAD, GET_USER } from '../../graphql/queries';
import { WpLead, WpLeadActivity } from '../../contexts/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import FullscreenModal from './../Shared/FullscreenModal';
import Heading from './../Shared/Heading';
import { SimpleLineIcons, Ionicons, Entypo, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { BarChart, XAxis, YAxis, Bar, Line, Legend, Tooltip, CartesianGrid } from "recharts";
import { Access, UserContext, WpUser, Subscription, BankAccount, CustomValue, WpUserAction } from '../../contexts/user';
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import Dropdown, { Item } from '../Dropdown';
import ModalFooter from '../ModalFooter';
import { Button } from '@whistlepitch/wp-components';
import { NEW_PASSWORD, UPDATE_ORGANIZATION, UPDATE_USER } from '../../graphql/user';
import Toast from 'react-native-toast-message';
import Icon from '../Icon';
import StripeCardElement from '../Shared/StripeCardElement';
import StripeBankAccountElement from '../Shared/StripeBankAccountElement';
import Uppyup from '../Uppyup';
import BillingModal from './BillingModal';
import MessageEditor from '../MessageEditor';
import Checkbox from '../Checkbox';


const permissionItems: Item[] = [
  {
    label: "Notifications Only",
    value: Access.LIMITED,
  },
  {
    label: "Can View Account",
    value: Access.READONLY,
  },
  {
    label: "Can Make Changes",
    value: Access.MANAGER,
  },
  {
    label: "Admin",
    value: Access.ADMIN,
  },
]

export default function UserDetailsModal({ isVisible, onSave, onClose, user }: { isVisible: boolean, onSave?: () => void, onClose: () => void, user?: WpUser }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);

  const [name, setName] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [allowIncomingLeads, setAllowIncomingLeads] = useState<boolean>(false)
  const [allowSelfOnboarding, setAllowSelfOnboarding] = useState<boolean>(false)
  const [color, setColor] = useState<string>()
  const [orgType, setOrgType] = useState<string>()
  const [image, setImage] = useState<string>()
  const [newImage, setNewImage] = useState<boolean>(false)
  const [orgName, setOrgName] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [newPassword, setNewPassword] = useState<string>()
  const [newPassword2, setNewPassword2] = useState<string>()
  const [error, setError] = useState<string>()
  const [viewingInvoices, setViewingInvoices] = useState<boolean>()
  const [viewing, setViewing] = useState<"settings" | "billing" | "branding" | "opt-in">("settings")

  const [changingPassword, setChangingPassword] = useState<boolean>(false)
  const [cancelingPlan, setCancelingPlan] = useState<boolean>(false)
  const [changingBankAccount, setChangingBankAccount] = useState<boolean>(false)
  const [changingCard, setChangingCard] = useState<boolean>(false)
  const [changingPlanOnly, setChangingPlanOnly] = useState<boolean>(false)
  const [stripeError, setStripeError] = useState<string>();
  const [access, setAccess] = useState<Access>()
  const [billing, setBilling] = useState<Subscription>()
  const [bank, setBank] = useState<BankAccount>()
  const [hovering, setHovering] = useState<boolean>()
  const [stripeLoading, setStripeLoading] = useState<boolean>(false)
  const [customValues, setCustomValues] = useState<CustomValue[]>([])
  const [timestamps, setTimestamps] = useState<{ [activityId: string]: string }>({})
  const [ProfileElement, setProfileElement] = useState<JSX.Element>()
  const [aspectRatio, setAspectRatio] = useState<number>(0)
  const [optinMessage, setOptinMessage] = useState<string>();
  const [optinMessageContent, setOptinMessageContent] = useState<object>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const [updateUser, { data, loading }] = useMutation(UPDATE_USER, {
    fetchPolicy: "no-cache",
  });

  const [getUser, getUserData] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    variables: {
      includeBilling: true
    }
  });

  const [updateOrganization, updateOrganizationData] = useMutation(UPDATE_ORGANIZATION, {
    fetchPolicy: "no-cache",
  });

  const [saveNewPassword, newPasswordData] = useMutation(NEW_PASSWORD, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (user) {
      if (getUserData.data?.user?.user?.id !== user.id) {
        setLoading(true)
        if (user.id === state.user?.id) {
          getUser();
        } else {
          getUser({
            variables: {
              userId: user.id,
            }
          });
        }
      } else {
        const u = getUserData.data?.user.user ?? {};

        setName(u.name);
        setPhone(u.phone);
        setEmail(u.email);

        const organization = u.organization ?? {};

        setColor(organization.color);
        setOrgType(organization.orgType);
        setImage(organization.image);
        setOrgName(organization.name);
        setAccess(organization.access);
        setBilling(organization.billing);
        setBank(organization.bank);
        setAllowIncomingLeads(organization.allowIncomingLeads);
        setAllowSelfOnboarding(organization.allowSelfOnboarding);

        setCustomValues(user.customValues);
        setLoading(false)
      }
    } else {
      setName(undefined)
      setPhone(undefined)
      setEmail(undefined)
      setOrgType(undefined)
      setColor(undefined)
      setImage(undefined)
      setOrgName(undefined)
      setAccess(undefined)
      setBilling(undefined)
      setBank(undefined)
      setAllowIncomingLeads(false)
      setAllowSelfOnboarding(false)
    }
    setError(undefined)
  }, [user?.id, getUserData.data?.user])

  useEffect(() => {
    if (data) {
      if (changingCard) {
        if (data?.updateUser.user.organization.orgType) {
          setOrgType(data.updateUser.user.organization.orgType)
        }
        if (data?.updateUser.user.organization.billing) {
          setBilling(data.updateUser.user.organization.billing)
        }
        doneChangeCard();
      } else if (changingBankAccount) {
        if (data?.updateUser.user.organization.bank) {
          setBank(data.updateUser.user.organization.bank)
        }
        doneChangeBankAccount();
      } else {
        setViewing('settings')

        Toast.show({
          type: 'success',
          text1: 'Saved',
          text2: `Your account changes have been saved.`
        });
        if (onSave === undefined) {
          onClose();
        } else {
          onSave();
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (!optinMessageContent) {
      setOptinMessage(state.user?.organization.message)
      setOptinMessageContent(state.user?.organization.messageContent)
    }
  }, [state.user?.organization.messageContent])

  useEffect(() => {
    if (updateOrganizationData.data?.updateOrganization) {
      let org = updateOrganizationData.data.updateOrganization.organization;
      setOptinMessage(org.message)
      setOptinMessageContent(org.messageContent)

      // dispatch({ type: WpUserAction.REFRESH_ORGANIZATION, organization: org })
      saveUser();
    }
  }, [updateOrganizationData.data])

  // useEffect(() => {
  //   if (!!user && getUserData.data?.user?.success && !state.user?.organization.billing?.invoices) {
  //     dispatch({ type: WpUserAction.REFRESH_USER, user: getUserData.data?.user?.user })
  //   }
  // }, [getUserData.data, state.user?.organization.billing?.invoices])
  // useEffect(() => {
  //   if (!!user && user.id && state.user?.id === user.id) {
  //     dispatch({ type: WpUserAction.REFRESH_USER, user: getUserData.data?.user?.user })
  //   }
  // }, [getUserData.data, state.user?.organization.billing?.invoices])

  useEffect(() => {
    if (newPasswordData.data) {
      if (newPasswordData.data.newPassword.success) {
        Toast.show({
          type: 'success',
          text1: 'Password Changed',
          text2: `Your password has been updated`
        });
        setError(undefined);
        doneChangePassword();
      } else {
        if (newPasswordData.data.newPassword.errors && newPasswordData.data.newPassword.errors.length > 0) {
          setError(newPasswordData.data.newPassword.errors[0]);
        }
      }
    }
  }, [newPasswordData.data])

  useEffect(() => {
    if (!image) {
      return;
    }

    let isValid = true;
    Image.getSize(image, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [image, aspectRatio]);

  useEffect(() => {
    if (image) {
      setProfileElement(<Image style={{ borderRadius: '50%', alignSelf: 'center', marginTop: 20, width: 150, height: 150, objectFit: 'cover' }} source={{ uri: image }} />)
    }
  }, [aspectRatio, image])

  const onCompleteCard = (token?: string, plan?: string) => {
    if (user) {
      updateUser({
        variables: {
          userId: user.id,
          user: {
            token: token,
            plan: plan
          }
        }
      })
    }
  }

  const onCompleteBank = (error, token) => {
    if (error) {
      setStripeError(error)
    } else if (user) {
      setStripeError(undefined)
      updateUser({
        variables: {
          userId: user.id,
          user: {
            bankToken: token ? token.id : undefined
          }
        }
      })
    }
  }

  const getMessagePrice = (): number => {
    if (orgType) {
      if (orgType === 'free') {
        return 0.01
      } else if (orgType === 'basic') {
        return 0.01
      } else if (orgType === 'pro') {
        return 0.01
      }
    } else if (state.user?.organization.id && ["4", "1"].indexOf(state.user.organization.id) > -1 && Constants.brand_key === 'whistlepitch') {
      return 0.00
    }

    return 0.02
  }

  const getMonthlyLeadPrice = (): number => {
    if (orgType) {
      if (orgType === 'basic') {
        return 25
      } else if (orgType === 'pro') {
        return 49
      }
    }

    return 0
  }

  const onHover = () => {
    setHovering(true)
  }

  const onHoverOut = () => {
    setHovering(undefined)
  }

  const formatTime = (duration: number) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const formatName = (name: string) => {
    return timestamps[name]
  }

  const onChangePlan = () => {
    setChangingPlanOnly(true)
    setChangingCard(true)
  }

  const onChangeCard = () => {
    setChangingPlanOnly(false)
    setChangingCard(true)
  }

  const doneChangeCard = () => {
    setChangingCard(false)
  }

  const onChangeBankAccount = () => {
    setChangingBankAccount(true)
  }

  const doneChangeBankAccount = () => {
    setChangingBankAccount(false)
  }

  const onChangePassword = () => {
    setChangingPassword(true)
  }

  const doneChangePassword = () => {
    setChangingPassword(false)
    setError(undefined)
  }

  const onCancelPlan = () => {
    setCancelingPlan(true)
  }

  const doneCancelingPlan = () => {
    setCancelingPlan(false)
  }

  const submitCancelPlan = () => {
    if (user) {
      updateUser({
        variables: {
          userId: user.id,
          user: {
            name: name,
          },
          cancelSubscription: true
        }
      })
    }
  }

  const viewSettings = () => {
    setViewing("settings")
  }

  const viewOptIn = () => {
    setViewing("opt-in")
  }

  const viewBranding = () => {
    setViewing("branding")
  }

  const viewBilling = () => {
    setViewing("billing")
  }

  const updateCustomValue = (i: number, name?: string, value?: string) => {
    let newValue = customValues[i];
    if (value === undefined) {
      if (name) {
        newValue.name = name;
      }
    } else {
      newValue.value = value
    }

    setCustomValues([
      ...customValues.slice(0, i),
      newValue,
      ...customValues.slice(i + 1),
    ])
  }

  const onSaveUser = () => {
    if (user) {
      if ([Access.ADMIN, Access.MANAGER, Access.OWNER].indexOf(user.organization.access) > -1) {
        updateOrganization({
          variables: {
            organizationId: user.organization.id,
            organization: {
              name: orgName,
              color: color,
              image: image,
              messageContent: optinMessageContent,
              allowIncomingLeads: allowIncomingLeads,
              allowSelfOnboarding: allowSelfOnboarding,
            }
          }
        })
      } else {
        saveUser();
      }
    }
  }

  const saveUser = () => {
    if (user) {
      let cvs = [];
      if (customValues) {
        for (let i = 0; i < customValues.length; i++) {
          cvs.push({ customFieldId: customValues[i].customFieldId, name: customValues[i].name, value: customValues[i].value })
        }
      }

      updateUser({
        variables: {
          userId: user.id,
          user: {
            name: name,
            phone: phone,
            email: email,
            access: access,
            customValues: cvs
          }
        }
      })
    }
  }

  const onSavePassword = () => {
    if (password && newPassword && newPassword2 && newPassword === newPassword2) {
      saveNewPassword({
        variables: {
          oldPassword: password,
          password: newPassword,
          password2: newPassword2,
        }
      })
    } else {
      if (!password) {
        setError("Please enter your current password")
      } else if (!newPassword) {
        setError("Please enter your new password")
      } else if (!newPassword2) {
        setError("Please confirm your new password")
      } else {
        setError("Passwords must match.")
      }
    }
  }

  const renderCard = () => {
    if (billing) {
      return (
        <CcCard last4={billing.card?.last4} expMonth={billing.card?.expMonth} expYear={billing.card?.expYear} onClick={onChangeCard} />
      )
    }
    return null;
  }

  const getPlanTitle = () => {
    if (state.user?.organization?.billing?.active) {
      if (orgType === 'free') {
        return <Text style={[styles.cardBody, { marginHorizontal: 10, color: Constants.colors.darkBlue }]}>Free</Text>
      } else if (orgType === 'basic') {
        return <Text style={[styles.cardBody, { marginHorizontal: 10, color: Constants.colors.darkBlue }]}>Basic</Text>
      } else if (orgType === 'pro') {
        return <Text style={[styles.cardBody, { marginHorizontal: 10, color: Constants.colors.darkBlue }]}>Pro</Text>
      }
    } else {
      return <Text style={[styles.cardBody, { marginHorizontal: 10, color: Constants.colors.darkBlue }]}>Inactive Account</Text>
    }
  }

  const getPlanSubtext = () => {
    if (state.user?.organization?.billing?.active) {
      if (state.user.organization.billing.expires) {
        let stamp = new Date(state.user.organization.billing.expires * 1000);
        return <Text style={[styles.cardSubtext, { marginHorizontal: 10 }]}>Expires {stamp.getMonth() + 1}/{stamp.getDate()}</Text>
      } else if (orgType === 'free') {
        return <Text style={[styles.cardSubtext, { marginHorizontal: 10 }]}>25 contacts, 1 group, 3 cents per message</Text>
      } else if (orgType === 'basic') {
        return <Text style={[styles.cardSubtext, { marginHorizontal: 10 }]}>250 contacts, 4 groups, 2 cents per message</Text>
      } else if (orgType === 'pro') {
        return <Text style={[styles.cardSubtext, { marginHorizontal: 10 }]}>2500 contacts, 1 cents per message</Text>
      }
    } else {
      return <Text style={[styles.cardSubtext, { marginHorizontal: 10 }]}>25 contacts, 1 group, and no messages</Text>
    }
  }

  const renderPlan = () => {
    if (Constants.blastView) {
      return (
        <View style={[styles.card]}>
          <View style={styles.cardIcon}>
            <FontAwesome name="ticket" size={22} color={Constants.colors.darkBlue} />
          </View>
          <View style={[styles.cardBodyWrapper, { alignSelf: 'center' }]}>
            {getPlanTitle()}
            {getPlanSubtext()}
            <Text style={{ cursor: 'pointer', paddingLeft: 7, paddingTop: 10, color: Constants.colors.blue, textDecorationLine: "underline" }} onPress={onChangePlan}>Change plan</Text>
          </View>
        </View>
      )
    }
    return null;
  }

  const getAmount = (amount: number) => {
    if (amount) {
      return `$${(amount / 100).toFixed(2)}`
    } else {
      return '$0.00'
    }
  }

  const showInvoices = () => {
    setViewingInvoices(true)
  }

  const hideInvoices = () => {
    setViewingInvoices(false)
  }

  const addCalendar = () => {
    setCustomValues([...customValues, { name: '', value: '' }])
  }

  const onDone = () => {
    // console.log("Done")
  }

  const onUploadProfileComplete = (url: string, mime: string) => {
    setImage(url);
    setNewImage(false);
  }

  const onNewImage = () => {
    setNewImage(true);
  }

  const getPaidIcon = (paid: number) => {
    if (paid) {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
          <FontAwesome style={{ marginHorizontal: 5 }} name="check-circle-o" size={18} color={Constants.colors.green} />
          <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.green }}>Paid</Text>
        </View>
      )
    } else {
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
          <FontAwesome style={{ marginHorizontal: 5 }} name="times-circle-o" size={18} color={Constants.colors.red} />
          <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.red }}>Unpaid</Text>
        </View>
      )
    }
  }

  const renderCustomValues = () => {
    if (customValues) {
      return (
        <View>
          {customValues.map((customValue, index) => {
            return (
              <View key={`user-settings-${index}`} style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', gap: 10, marginTop: 10 }}>
                <Input key={`value-${index}-key`} style={{ flex: 1 }} name={"Calendar Name"} value={customValue.name} placeholder="Enter Calendar Name..." setValue={(val: string) => updateCustomValue(index, val)} />
                <Input key={`value-${index}-value`} style={{ flex: 1 }} name="Calendly Link" value={customValue.value} placeholder="Enter Calendly Link..." setValue={(val: string) => updateCustomValue(index, undefined, val)} />
              </View>
            )
          })}
          {Constants.controls.allowSlideTypeCalendly ? <Text onPress={addCalendar} style={styles.newBtn}>{`+ Add Calendly`}</Text> : null}
        </View>
      )
    }
    return null;
  }

  const renderInvoices = () => {
    if (billing) {
      if (viewingInvoices) {
        return (
          <View>
            <View style={[styles.card, { flexDirection: 'column' }]}>
              <View style={[styles.card, { padding: 0 }]}>
                <View style={styles.cardIcon}>
                  <FontAwesome5 name="file-invoice-dollar" size={20} color={Constants.colors.darkBlue} />
                </View>

                <View style={[styles.cardBodyWrapper, { alignSelf: 'center' }]}>
                  <Text key="last-4" style={[styles.cardBody, { marginHorizontal: 10 }]}>Invoices</Text>
                </View>
              </View>
              <View style={{ width: '100%' }}>
                {billing?.invoices.map((invoice) => {
                  return (
                    <View style={styles.cardRow}>
                      <View style={{ flexDirection: 'row', justifyContent: 'center', paddingVertical: 4 }}>
                        <Text style={{ width: 80 }}>{getAmount(invoice.amount)}</Text>
                        {getPaidIcon(invoice.paid)}
                      </View>
                      <View style={{ flexDirection: 'row', paddingVertical: 4 }}>
                        <a href={invoice.url} target="_blank">
                          <FontAwesome name="external-link" size={20} color={Constants.colors.blue} />
                          <Text style={{ cursor: 'pointer', paddingLeft: 7, position: 'relative', top: -3, color: Constants.colors.blue, textDecorationLine: "underline" }}>View Invoice</Text>
                        </a>
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>
            <Text onPress={hideInvoices} style={{ cursor: 'pointer', paddingLeft: 7, paddingTop: 10, color: Constants.colors.blue, textDecorationLine: "underline" }}>View Invoices</Text>
          </View>
        )
      } else if (billing.invoices && billing.invoices.length) {
        return <Text onPress={showInvoices} style={{ cursor: 'pointer', paddingLeft: 7, paddingTop: 10, color: Constants.colors.blue, textDecorationLine: "underline" }}>View Invoices</Text>
      }
    }
    return null;
  }

  const renderLeads = () => {
    if (Constants.blastView && state.user?.stats?.leads) {
      return (
        <View style={[styles.card, { flexDirection: 'column' }]}>
          <View key="leads-heading" style={[styles.card, { padding: 0 }]}>
            <View style={styles.cardIcon}>
              <Icon style={{ textAlign: 'center', alignItems: 'center' }} name="users" color={Constants.colors.darkBlue} width={20} />
            </View>

            <View style={[styles.cardBodyWrapper, { alignSelf: 'center' }]}>
              <Text key="leads-title" style={[styles.cardBody, { marginHorizontal: 10 }]}>{Constants.localizations.Leads}</Text>
            </View>
          </View>
          <View key="leads-content" style={{ width: '100%' }}>
            <View key={`leads-period`} style={styles.cardRow}>
              <View key="leads-card" style={{ paddingVertical: 4 }}>
                <Text key="leads-card-total" style={[styles.cardBody, { paddingBottom: 2 }]}>{state.user.stats.leads} {state.user.stats.leads === 1 ? Constants.localizations.lead : Constants.localizations.leads}</Text>
                <Text key="leads-card-pricing" style={styles.cardSubtext}>Billing tier adjusts automatically</Text>
              </View>
              <View key="leads-card-description" style={{ flexDirection: 'row', paddingVertical: 4 }}>
                <Text style={[styles.cardBody, { color: Constants.colors.darkBlue }]}>${getMonthlyLeadPrice()}/month</Text>
              </View>
            </View>
          </View>
        </View>
      )
    }
    return null;
  }

  const renderMessages = () => {
    if (billing && billing?.messages && billing?.messages.length) {
      return (
        <View style={[styles.card, { flexDirection: 'column' }]}>
          <View key="messages-heading" style={[styles.card, { padding: 0 }]}>
            <View style={styles.cardIcon}>
              <Icon style={{ textAlign: 'center', alignItems: 'center' }} name="message" color={Constants.colors.darkBlue} width={20} />
            </View>

            <View style={[styles.cardBodyWrapper, { alignSelf: 'center' }]}>
              <Text key="messaging-title" style={[styles.cardBody, { marginHorizontal: 10 }]}>Messaging</Text>
              {Constants.blastView ? <Text key="messaging-subtext" style={[styles.cardSubtext, { marginHorizontal: 10 }]}>First 100 message segments are free</Text> : null}
            </View>
          </View>
          <View key="messages-content" style={{ width: '100%' }}>
            {billing.messages.map((message, index) => {
              return (
                <View key={`message-period-${index}`} style={styles.cardRow}>
                  <View key="message-card" style={{ paddingVertical: 4 }}>
                    <Text key="message-card-total" style={[styles.cardBody, { paddingBottom: 2 }]}>{message.total} message segments sent</Text>
                    <Text key="message-card-pricing" style={styles.cardSubtext}>{message.cycle} at {parseInt(getMessagePrice() * 100)}¢/text</Text>
                  </View>
                  <View key="message-card-description" style={{ flexDirection: 'row', paddingVertical: 4 }}>
                    <Text style={[styles.cardBody, { color: Constants.colors.darkBlue }]}>{message.total ? `$${(message.total * getMessagePrice()).toFixed(2)}` : '$0.00'}</Text>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      )
    }
    return null;
  }

  const renderSettings = () => {
    if (viewing === "settings") {
      return (
        <View key={`user-settings`} style={{ flex: 1, gap: 15 }}>
          <Input key={`user-name`} style={{ flex: 1 }} name={"Name"} value={name} placeholder="Enter Name..." setValue={setName} />
          <Input key={`user-phone`} style={{ flex: 1 }} name="Phone" value={phone} placeholder="Enter Phone..." setValue={setPhone} />
          <Input key={`user-email`} style={{ flex: 1 }} name="Email" value={email} placeholder="Enter Email..." setValue={setEmail} />
          <Text style={styles.btnLink} onPress={onChangePassword}>Change Password</Text>
          {Constants.controls.allowSelfOnboarding ?
            <View style={{ flex: 1, position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
              <Checkbox key={`org-incoming-leads`} style={{ flex: 1 }} selected={allowSelfOnboarding} onSelect={setAllowSelfOnboarding} />
              <Text style={{ fontFamily: 'Gotham', color: Constants.colors.dark }}>We will manually onboard {Constants.localizations.leads}. {Constants.localizations.Leads} will not fill out the landing page themselves.</Text>
            </View> : null}
          {Constants.controls.allowIncomingLeads ?
            <View style={{ flex: 1, position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
              <Checkbox key={`org-incoming-leads`} style={{ flex: 1 }} selected={allowIncomingLeads} onSelect={setAllowIncomingLeads} />
              <Text style={{ fontFamily: 'Gotham', color: Constants.colors.dark }}>When a {Constants.localizations.lead} texts your account phone number, create a new {Constants.localizations.lead}</Text>
            </View> : null}
          {Constants.controls.allowIncomingLeads ?
            <Input key={`org-phone`} style={{ flex: 1 }} name="Account Phone Number" value={user?.organization.twilioPhone} disabled={true} setValue={() => { }} /> : null}
          {renderCustomValues()}
        </View>
      )
    }
    return null;
  }

  const renderOptIn = () => {
    if (viewing === "opt-in" && Constants.blastView) {
      return (
        <View key={`user-settings`} style={{ flex: 1, gap: 15 }}>
          <MessageEditor messageContent={optinMessageContent} includePlaceholders={false} setMessageContent={setOptinMessageContent} setMessage={setOptinMessage} />
        </View>
      )
    }
    return null;
  }

  const renderBranding = () => {
    if (viewing === "branding") {
      return (
        <View key={`user-organization-branding`} style={{ flex: 1, gap: 15, position: 'relative' }}>

          {image && !newImage ?
            <View>
              {ProfileElement}
              <Text style={{ width: '100%', textAlign: 'center', marginTop: 20, cursor: 'pointer', color: Constants.colors.primary }} onPress={onNewImage}>Upload New Image</Text>
            </View> : null
          }
          <View style={!image || newImage ? { marginBottom: 20 } : { display: 'none', marginBottom: 20 }}>
            <Uppyup plugins={[]} height={200} onDone={onDone} onUploadComplete={onUploadProfileComplete} />
          </View>
          <Input style={{ marginBottom: 10 }} name="Brand Name" value={orgName} setValue={setOrgName} />
          <Input style={{ marginBottom: 10, position: 'relative', zIndex: 150 }} name="Color" isColor={true} value={color} setValue={setColor} />
        </View>
      )
    }
    return null;
  }

  const renderBilling = () => {
    if (viewing === "billing") {
      if (getUserData.loading) {
        return <Spinner />
      } else {
        return (
          <View>
            {/* { renderPlan() } */}
            {renderCard()}
            {renderLeads()}
            {renderMessages()}
            {renderInvoices()}
          </View>
        )
      }
    }
    return null;
  }

  const renderModalBody = () => {
    if (!state.user || isLoading) {
      return <Spinner />
    } else if (!user) {
      return null
    } else if (state.user.id === user.id) {
      return (
        <View style={{ position: 'relative', zIndex: 3 }}>
          {[Access.ADMIN, Access.OWNER].indexOf(state.user.organization.access) > -1 ?
            <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: 30 }}>
              <Text key="nav-settings" onPress={viewSettings} style={viewing === "settings" ? styles.controlActive : styles.control}>Settings</Text>
              {Constants.blastView ? <Text key="nav-opt-in" onPress={viewOptIn} style={viewing === "opt-in" ? styles.controlActive : styles.control}>Opt-In Message</Text> : null}
              <Text key="nav-branding" onPress={viewBranding} style={viewing === "branding" ? styles.controlActive : styles.control}>Branding</Text>
              <Text key="nav-billing" onPress={viewBilling} style={viewing === "billing" ? styles.controlActive : styles.control}>{'Billing'}</Text>
            </View> : null}
          {renderSettings()}
          {renderOptIn()}
          {renderBranding()}
          {renderBilling()}
        </View>
      )
    } else {
      return (
        <View style={{ position: 'relative', zIndex: 3 }}>
          <View key={`user-settings`} style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', gap: 10 }}>
            <Input key={`user-name`} style={{ flex: 1 }} name={"Name"} value={name} placeholder="Enter User Name..." setValue={setName} />
            <Input key={`user-phone`} style={{ flex: 1 }} name="Phone" value={phone} placeholder="Enter User Phone..." setValue={setPhone} />
          </View>
          <Input key={`user-email`} style={{ flex: 1, marginTop: 10 }} name="Email" value={email} disabled={true} setValue={() => { }} />
          <View style={{ alignItems: 'flex-start', marginTop: 10, position: 'relative', zIndex: 2 }}>
            <Label name={"Permission"} />
            <Dropdown style={{ width: '100%', zIndex: 3 }} placeholder="Select permission" items={permissionItems} value={access} onChange={setAccess} />
          </View>
          {renderCustomValues()}
        </View>
      )
    }
  }

  const renderBody = () => {
    if (user !== undefined) {
      // console.log("billing", user.organization.billing)
      return (
        <View style={{ flex: 1, position: 'relative' }}>
          {renderModalBody()}

          <ModalFooter style={{ position: 'relative', zIndex: 1, justifyContent: 'space-between' }}>
            <Text style={{ textDecorationLine: 'underline', color: Constants.colors.red, cursor: 'pointer', opacity: viewing === 'billing' && Constants.blastView && !state.user?.organization?.billing?.expires && orgType ? 1 : 0 }} onClick={onCancelPlan}>Cancel Plan</Text>
            <Button type="Primary" size="Default" shape="Pill" onClick={onSaveUser} disabled={loading || updateOrganizationData.loading}>
              {(loading || updateOrganizationData.loading) && !changingCard ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Save</Text>}
            </Button>
            <Text style={{ color: 'white', opacity: 0 }}>Cancel Plan</Text>
          </ModalFooter>
        </View>
      )
    }
    return <View style={{ flex: 1 }}><Spinner /></View>;
  }

  return (
    <View style={{ position: 'relative' }}>
      <WpModal key="modal-details" isVisible={isVisible && !changingPassword}>
        <ModalBody title={!user || state.user?.id !== user.id ? "Manage User" : "Manage Account"} closeModal={onClose}>
          {renderBody()}
        </ModalBody>
      </WpModal>
      <WpModal key="modal-password" isVisible={changingPassword}>
        <ModalBody title="Change Password" closeModal={doneChangePassword}>
          <Input key={`user-password`} style={{ flex: 1, marginBottom: 15 }} secure={true} name="Current Password" value={password} placeholder="Current Password" setValue={setPassword} />
          <Input key={`user-new-password`} style={{ flex: 1, marginBottom: 15 }} secure={true} name="New Password" value={newPassword} placeholder="New Password" setValue={setNewPassword} />
          <Input key={`user-new-password-2`} style={{ flex: 1, marginBottom: 15 }} secure={true} name="Confirm New Password" value={newPassword2} placeholder="Confirm New Password" setValue={setNewPassword2} />
          {!!error ? <Text style={styles.error}>{error}</Text> : null}
          <ModalFooter style={{ position: 'relative', zIndex: 1 }}>
            <Button type="Primary" size="Default" shape="Pill" onClick={onSavePassword} disabled={newPasswordData.loading || !password || !newPassword || newPassword !== newPassword2}>
              {newPasswordData.loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Save</Text>}
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <WpModal key="modal-cancel" isVisible={cancelingPlan}>
        <ModalBody title="Are You Sure?" closeModal={doneCancelingPlan}>
          <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary, fontFamily: 'GothamMedium' }}>Your subscription will cancel at the end of your current billing period. At that time you will be charged for any remaining messages and your account will then be cancelled.</Text>
          <ModalFooter style={{ position: 'relative', zIndex: 1 }}>
            <Button type="Danger" size="Default" shape="Pill" onClick={submitCancelPlan} disabled={loading}>
              {loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Confirm</Text>}
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <BillingModal isVisible={changingCard} card={changingPlanOnly ? billing?.card : undefined} loading={loading} onClose={doneChangeCard} onComplete={onCompleteCard} stripeError={stripeError} />
      <WpModal key="modal-bank-account" isVisible={changingBankAccount}>
        <ModalBody title="Payout Account" closeModal={doneChangeBankAccount}>
          <StripeBankAccountElement onComplete={onCompleteBank} stripeLoading={stripeLoading || loading} setStripeLoading={setStripeLoading} />
          {stripeError ? <Text style={{ color: Constants.colors.red, fontFamily: 'Gotham', textAlign: 'center', margin: 'auto', marginTop: 15 }}>{stripeError}</Text> : null}
        </ModalBody>
      </WpModal>
    </View>
  );
}

export function CcCard({ last4, expMonth, expYear, onClick }: { last4?: string, expMonth?: number, expYear?: number, onClick?: () => void }): JSX.Element {
  if (last4 && expMonth && expYear) {
    return (
      <View style={styles.card}>
        <View style={styles.cardIcon}>
          <Ionicons name="card" size={22} color={Constants.colors.darkBlue} />
        </View>

        <View style={styles.cardBodyWrapper}>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Entypo key="dot-1" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-2" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-3" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-4" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Text key="gap-1" style={{ padding: 5 }}> </Text>
            <Entypo key="dot-5" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-6" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-7" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-8" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Text key="gap-2" style={{ padding: 5 }}> </Text>
            <Entypo key="dot-9" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-10" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-11" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Entypo key="dot-12" style={{ width: 10 }} name="dot-single" size={22} color={Constants.colors.dark} />
            <Text key="gap-3" style={{ padding: 5 }}> </Text>
            <Text key="last-4" style={styles.cardBody}>{last4}</Text>
          </View>
          <Text key="exp" style={[styles.cardSubtext, { paddingLeft: 7 }]}>{expMonth} / {expYear}</Text>
          <Text key="change-method" style={{ cursor: 'pointer', paddingLeft: 7, paddingTop: 10, color: Constants.colors.blue, textDecorationLine: "underline" }} onPress={onClick}>Change payment method</Text>
        </View>
      </View>
    )
  } else {
    return (
      <View style={styles.card}>
        <View key="icon-card" style={styles.cardIcon}>
          <Ionicons name="card" size={22} color={Constants.colors.darkBlue} />
        </View>
        <View key="card-body" style={styles.cardBodyWrapper}>
          <Text style={{ cursor: 'pointer', paddingLeft: 7, paddingTop: 10, color: Constants.colors.blue, textDecorationLine: "underline" }} onPress={onClick}>Add payment method</Text>
        </View>
      </View>
    )
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    marginVertical: 25
  },
  btnLink: {
    paddingTop: 5,
    color: Constants.colors.blue,
    textDecorationLine: "underline",
    fontFamily: "Gotham",
    cursor: "pointer"
  },
  error: {
    width: '100%',
    textAlign: 'center',
    color: Constants.colors.red,
    fontFamily: 'GothamMedium'
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1
  },
  card: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 15,
    backgroundColor: Constants.colors.lightBlue,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 15
  },
  cardRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 10,
    paddingHorizontal: 10,
    marginBottom: 10
  },
  cardIcon: {
    borderRadius: 20,
    width: 40,
    height: 40,
    backgroundColor: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 15
  },
  cardBody: {
    fontFamily: 'GothamMedium',
    fontSize: 14,
    color: Constants.colors.dark
  },
  cardBodyWrapper: {
  },
  cardSubtext: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.info
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
    marginVertical: 15
  },
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
