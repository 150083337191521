import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import Spinner from './../Spinner';
import CampaignGraph from './CampaignGraph';
import CampaignReport from './CampaignReport';
import CampaignGraphModal from './CampaignGraphModal';
import CampaignOverview from './../CampaignOverview';
import { CampaignContext, WpCampaignAction } from './../../contexts/campaign';
import { GET_CAMPAIGN } from './../../graphql/queries';
import { UPDATE_CAMPAIGN } from './../../graphql/campaign';
import { useLazyQuery, useMutation } from "@apollo/client";
import SwitchSelector from "react-native-switch-selector";
import { SpeakerphoneIcon } from '@heroicons/react/outline'
import { Foundation } from '@expo/vector-icons';
import { useNavigate } from './../../react-router';


export default function CampaignEditor({ campaignId, refresh } : { campaignId?: string, refresh?: number }): JSX.Element {
  const { state, dispatch } = React.useContext(CampaignContext);
  const [ value, setValue ] = useState<number>();
  const [ toggled, setToggled ] = useState<boolean>(false);

  const navigate = useNavigate();

  const [updateCampaign, updateCampaignData] = useMutation(UPDATE_CAMPAIGN);
  const [getCampaign, { data, loading, refetch, error }] = useLazyQuery(GET_CAMPAIGN);

  useEffect(() => {
    setValue(undefined)
    loadCampaign()
  }, [campaignId])

  useEffect(() => {
    if (data?.campaign) {
      if (!data.campaign.success) {
        navigate('/campaigns')
      } else if (data.campaign.campaign) {
        if (value === undefined) {
          setValue(!Constants.oppText && !Constants.tattooCare && data.campaign.campaign.lastActive ? 1 : 0)
        }
        dispatch({ type: WpCampaignAction.REFRESH_CAMPAIGN, campaign: data?.campaign?.campaign})
      }
    }
  }, [data])

  const loadCampaign = () => {
    if (data) {
      refetch({ campaignId: campaignId });
    } else {
      getCampaign({ variables: { campaignId: campaignId }})
    }
  }

  const toggleView = (value: number) => {
    setToggled(true)

    loadCampaign();
    setValue(value);
  }

  const onBack = () => {
    if (toggled) {
      toggleView(value ? 0 : 1)
    } else {
      navigate('/campaigns')
    }
  }

  const toReport = () => {
    toggleView(1)
  }

  const toGraph = () => {
    toggleView(0)
  }

  if (!campaignId) {
    return <View></View>
  }

  if (!state.campaign || (!data && loading)) {
    return (
      <View>
        <Spinner />
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <CampaignReport key={`campaign-report-${refresh}`} onEdit={toGraph} />
      <CampaignGraphModal isVisible={value !== undefined && !value} onSave={loadCampaign} onBack={onBack} onReport={toReport} />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
