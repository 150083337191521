import { gql } from "@apollo/client";

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($name: String, $leadId: ID, $v2: Boolean) {
    createCampaign(name: $name, leadId: $leadId, v2: $v2) {
      success
      errors
      campaign {
        id
      }
    }
  }
`;

export const CLONE_CAMPAIGN = gql`
  mutation cloneCampaign($campaignId: ID!, $organizationId: ID, $isPublic: Boolean) {
    cloneCampaign(campaignId: $campaignId, organizationId: $organizationId, isPublic: $isPublic) {
      success
      errors
      campaign {
        id
      }
    }
  }
`;

export const ARCHIVE_CAMPAIGN = gql`
  mutation archiveCampaign($campaignId: ID!) {
    archiveCampaign(campaignId: $campaignId) {
      success
      errors
    }
  }
`;

export const CSV_REPORT = gql`
  query csvReport($campaignId: ID!) {
    csvReport(campaignId: $campaignId) {
      success
      errors
      csv
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($campaignId: ID!, $name: String, $hasAppt: Boolean, $landingPageTitle: String, $landingPageDescription: String, $landingPageColor: String, $landingPageImage: String, $calendlyEventUri: String, $brandName: String, $brandImage: String, $brandColor: String, $public: Boolean, $description: String, $category: String) {
    updateCampaign(campaignId: $campaignId, campaign: {name: $name, hasAppt: $hasAppt, landingPageTitle: $landingPageTitle, landingPageDescription: $landingPageDescription, landingPageColor: $landingPageColor, landingPageImage: $landingPageImage, calendlyEventUri: $calendlyEventUri, brandName: $brandName, brandImage: $brandImage, brandColor: $brandColor, public: $public, description: $description, category: $category}) {
      success
      errors
      campaign {
        landingPage {
          structure {
            id
            label
            type
          }
        }
      }
    }
  }
`;

export const CREATE_CAMPAIGN_FEATURE = gql`
  mutation createCampaignFeature($campaignId: ID!, $featureType: FeatureType, $structureType: CampaignStructureType!, $after: ID) {
    createCampaignFeature(campaignId: $campaignId, featureType: $featureType, structureType: $structureType, after: $after) {
      success
      errors
      structure {
        id
        label
        featureType
        structure {
          id
          label
        }
      }
      feature {
        id
        label
        created
        campaignId
        days
        hours
        minutes
        window
        delayUntil
        structureType
        featureType
        message
        messageContent
        pitch {
          id
          title
        }
        workflows {
          id
          label
          triggers {
            id
            label
            triggerType
            triggerOption
            triggerResponses
            seconds
            days
            hours
            widgetId
            performedAction
            widgetExpression
          }
          actions {
            id
            label
            actionType
            default
            messageContent
            webhookUrl
            nextCampaignId
            userIds
          }
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_FEATURE = gql`
  mutation updateCampaignFeature($campaignFeatureId: ID!, $structureType: CampaignStructureType!, $message: String, $messageContent: JSON, $pitchId: ID, $days: Int, $hours: Int, $minutes: Int, $window: String, $delayUntil: String) {
    updateCampaignFeature(campaignFeatureId: $campaignFeatureId, structureType: $structureType, feature: {message: $message, pitchId: $pitchId, messageContent: $messageContent, days: $days, hours: $hours, minutes: $minutes, window: $window, delayUntil: $delayUntil}) {
      success
      errors
      structure {
        id
        label
        featureType
        structure {
          id
          label
        }
      }
      feature {
        id
        label
        created
        campaignId
        days
        hours
        minutes
        window
        delayUntil
        structureType
        featureType
        message
        messageContent
        pitch {
          id
          title
          redirectType
          redirectUrl
          redirectPhone
          redirectButton
          structure {
            id
            label
            slideType
            isVideo
            type
            button
            phone
            url
            structure {
              id
              label
              type
            }
          }
        }
        workflows {
          id
          label
          triggers {
            id
            label
            triggerType
            triggerOption
            triggerResponses
            seconds
            days
            hours
            widgetId
            performedAction
            widgetExpression
          }
          actions {
            id
            label
            default
            actionType
            messageContent
            webhookUrl
            nextCampaignId
            userIds
          }
        }
      }
    }
  }
`;

export const DELETE_CAMPAIGN_FEATURE = gql`
  mutation deleteCampaignFeature($campaignFeatureId: ID!, $structureType: CampaignStructureType!) {
    deleteCampaignFeature(campaignFeatureId: $campaignFeatureId, structureType: $structureType) {
      success
      errors
      structure {
        id
        label
        featureType
        structure {
          id
          label
        }
      }
    }
  }
`;

export const RETRY_CAMPAIGN_FEATURE_QUEUE = gql`
  mutation retryCampaignFeatureQueue($campaignFeatureQueueId: ID!) {
    retryCampaignFeatureQueue(campaignFeatureQueueId: $campaignFeatureQueueId) {
      success
      errors
    }
  }
`;

export const CREATE_CAMPAIGN_WORKFLOW = gql`
  mutation createCampaignWorkflow($campaignFeatureId: ID!) {
    createCampaignWorkflow(campaignFeatureId: $campaignFeatureId) {
      success
      errors
      workflow {
        id
        label
        created
        campaignId
        triggers {
          id
          label
        }
        actions {
          id
          label
        }
      }
    }
  }
`;

export const DELETE_CAMPAIGN_WORKFLOW = gql`
  mutation deleteCampaignWorkflow($campaignWorkflowId: ID!) {
    deleteCampaignWorkflow(campaignWorkflowId: $campaignWorkflowId) {
      success
      errors
    }
  }
`;

export const CREATE_CAMPAIGN_TRIGGER = gql`
  mutation createWorkflowTrigger($campaignWorkflowId: ID!, $triggerType: WorkflowTriggerType) {
    createWorkflowTrigger(campaignWorkflowId: $campaignWorkflowId, triggerType: $triggerType) {
      success
      errors
      trigger {
        id
        label
        campaignWorkflowId
        triggerType
        created
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_TRIGGER = gql`
  mutation updateWorkflowTrigger($workflowTriggerId: ID!, $triggerType: WorkflowTriggerType, $triggerOption: ID, $triggerResponses: [String], $seconds: Int, $days: Int, $hours: Int, $performedAction: Boolean, $widgetExpression: Expression, $widgetId: ID) {
    updateWorkflowTrigger(workflowTriggerId: $workflowTriggerId, trigger: {triggerType: $triggerType, triggerOption: $triggerOption, triggerResponses: $triggerResponses, seconds: $seconds, days: $days, hours: $hours, performedAction: $performedAction, widgetExpression: $widgetExpression, widgetId: $widgetId}) {
      success
      errors
      trigger {
        id
        label
        triggerType
        triggerOption
        triggerResponses
        seconds
        days
        hours
        widgetId
        performedAction
        widgetExpression
      }
    }
  }
`;

export const DELETE_CAMPAIGN_TRIGGER = gql`
  mutation deleteWorkflowTrigger($workflowTriggerId: ID!) {
    deleteWorkflowTrigger(workflowTriggerId: $workflowTriggerId) {
      success
      errors
    }
  }
`;

export const CREATE_CAMPAIGN_ACTION = gql`
  mutation createWorkflowAction($campaignWorkflowId: ID!, $actionType: WorkflowActionType) {
    createWorkflowAction(campaignWorkflowId: $campaignWorkflowId, actionType: $actionType) {
      success
      errors
      action {
        id
        label
        default
        created
        campaignWorkflowId
        actionType
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_ACTION = gql`
  mutation updateWorkflowAction($workflowActionId: ID!, $actionType: WorkflowActionType, $messageContent: JSON, $webhookUrl: String, $nextCampaignId: ID, $userIds: [String]) {
    updateWorkflowAction(workflowActionId: $workflowActionId, action: {actionType: $actionType, messageContent: $messageContent, webhookUrl: $webhookUrl, nextCampaignId: $nextCampaignId, userIds: $userIds}) {
      success
      errors
      action {
        id
        label
        default
        actionType
        messageContent
        webhookUrl
        nextCampaignId
        userIds
      }
    }
  }
`;

export const DELETE_CAMPAIGN_ACTION = gql`
  mutation deleteWorkflowAction($workflowActionId: ID!) {
    deleteWorkflowAction(workflowActionId: $workflowActionId) {
      success
      errors
    }
  }
`;

export const CREATE_LANDING_PAGE = gql`
  mutation createLandingPage($title: String) {
    createLandingPage(title: $title) {
      success
      errors
      landingPage {
        id
        title
        description
        color
        image
        code
        widgets {
          id
          pitchSlideId
          label
          fineText
          widgetType
          labels
          start
          end
          created
        }
        structure {
          id
          label
          type
        }
        stats {
          leads
        }
        campaigns {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_LANDING_PAGE = gql`
  mutation updateLandingPage($landingPageId: ID!, $landingPage: LandingPageInput) {
    updateLandingPage(landingPageId: $landingPageId, landingPage: $landingPage) {
      success
      errors
      landingPage {
        id
        title
        description
        color
        image
        code
        widgets {
          id
          pitchSlideId
          label
          fineText
          widgetType
          labels
          start
          end
          created
        }
        structure {
          id
          label
          type
        }
        stats {
          leads
        }
        campaigns {
          id
          name
        }
      }
    }
  }
`;

export const ARCHIVE_LANDING_PAGES = gql`
  mutation archiveLandingPages($landingPageIds: [ID]!) {
    archiveLandingPages(landingPageIds: $landingPageIds) {
      success
      errors
    }
  }
`;

export const SUBMIT_LANDING_PAGE = gql`
  mutation submitLandingPage($landingPageCode: String!, $widgets: [LeadWidgetInput]!) {
    submitLandingPage(landingPageCode: $landingPageCode, widgets: $widgets) {
      success
      errors
    }
  }
`;

export const CREATE_LANDING_PAGE_DROPDOWN_OPTION = gql`
  mutation createLandingPageDropdownOption($label: String) {
    createLandingPageDropdownOption(label: $label) {
      success
      errors
      landingPageDropdownOption {
        id
        label
        campaigns {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_LANDING_PAGE_DROPDOWN_OPTION = gql`
  mutation updateLandingPageDropdownOption($landingPageDropdownOptionId: ID!, $landingPageDropdownOption: LandingPageDropdownOptionInput) {
    updateLandingPageDropdownOption(landingPageDropdownOptionId: $landingPageDropdownOptionId, landingPageDropdownOption: $landingPageDropdownOption) {
      success
      errors
      landingPageDropdownOption {
        id
        label
        campaigns {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_LANDING_PAGE_DROPDOWN_OPTION = gql`
  mutation deleteLandingPageDropdownOption($landingPageDropdownOptionIds: [ID]!) {
    deleteLandingPageDropdownOption(landingPageDropdownOptionIds: $landingPageDropdownOptionIds) {
      success
      errors
    }
  }
`;
