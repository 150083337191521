import { View, Text, StyleSheet, LayoutChangeEvent } from 'react-native';
import React, { useRef, useState } from 'react';

import Constants from '../../constants';
import { Button } from '@whistlepitch/wp-components';
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import ReactPlayer from 'react-player';
import Thumbnail from './Thumbnail';


export default function HowToModal({ isVisible, onClose }: { isVisible: boolean, onClose: () => void }): JSX.Element {
  const player = useRef(null);
  const [viewWidth, setViewWidth] = useState<number>(0);
  const [selectedVideo, setSelectedVideo] = useState<keyof typeof videos | "">("")
  const videos = {
    "Blasts": {
      url: "https://whistlepitch.s3.us-west-1.amazonaws.com/ca/8a14252a5a467989e69e7e1eeba26a/Creating-a-Blast-with-BlastView.mp4",
      thumbnail: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/ca/8a14252a5a467989e69e7e1eeba26a/Creating-a-Blast-with-BlastView_thumbnail.png",
      title: "Creating a Blast with BlastView"
    },
    "Groups and Landing Pages": {
      url: "https://whistlepitch.s3.us-west-1.amazonaws.com/3d/a2856bd3c2430997ef91dc6fc250d2/How-to-Create-a-Group-and-a-Landing-Page-for-Your-Fans.mp4",
      thumbnail: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/3d/a2856bd3c2430997ef91dc6fc250d2/How-to-Create-a-Group-and-a-Landing-Page-for-Your-Fans_thumbnail.png",
      title: "Groups and Landing Pages for Your Fans"
    },
    "Send Blasts": {
      url: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/53/71a20d6d044c5998cf25a4d8d3d6bd/How-to-Send-Blasts-to-Groups-.mp4",
      thumbnail: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/53/71a20d6d044c5998cf25a4d8d3d6bd/How-to-Send-Blasts-to-Groups-_thumbnail.png",
      title: "How to Send Blasts to Groups"
    },
    "Quick Text": {
      url: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/67/591c6d28624b17a63ab12fc629aa8b/Loom_-Quick-Texting-with-Groups.mp4",
      thumbnail: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/67/591c6d28624b17a63ab12fc629aa8b/Loom_-Quick-Texting-with-Groups_thumbnail.png",
      title: "Quick Texting with Groups"
    },
    "Branding": {
      url: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/ec/d8e2e1ada94f4b92fc29d8e6ce853b/Setting-Up-Branding.mp4",
      thumbnail: "https://whistlepitch-test.s3.us-west-1.amazonaws.com/ec/d8e2e1ada94f4b92fc29d8e6ce853b/Setting-Up-Branding_thumbnail.png",
      title: "Setting Up Branding"
    },
  }

  const switchVideo = (newVideo: keyof typeof videos | "") => {
    if (newVideo && videos[newVideo]) {
      setSelectedVideo(newVideo);
    }
    return null
  }

  const handleOnClose = () => {
    if (selectedVideo) {
      setSelectedVideo("");
    } else {
      onClose();
    }
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var { width } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const renderVideo = () => {
    if (selectedVideo && videos[selectedVideo]) {
      return (
        <View style={styles.playerWrapper}>
          <Text style={[styles.title, { color: Constants.colors.darkBlue }]}>{videos[selectedVideo].title}</Text>
          <View style={styles.calendarWrapper}>
            <ReactPlayer
              ref={player}
              key={`video-${selectedVideo}`}
              style={cssStyles.player}
              url={videos[selectedVideo].url}
              controls={true}
              pip={false}
              width='100%'
              playsinline={true}
              height={viewWidth * 9 / 16}
            />
          </View>
        </View>
      )
    }
    return null
  }

  return (
    <WpModal isVisible={isVisible}>
      <ModalBody title={`How To Videos`} closeModal={onClose} width={600}>
        <View style={{ flex: 1 }} onLayout={onLayout}>
          {selectedVideo ? renderVideo() :
            <View style={styles.thumbnailWrapper}>
              {Object.keys(videos).map((videoKey, index) => {
                return (
                  <Thumbnail
                    key={`group-${videoKey}-${index}`}
                    text={videos[videoKey as keyof typeof videos].title}
                    image={videos[videoKey as keyof typeof videos].thumbnail}
                    selectable={false}
                    large={true}
                    onPress={() => switchVideo(videoKey as keyof typeof videos)}
                  />
                );
              })}
            </View>}
        </View>

        <ModalFooter>
          <Button type="Primary" size="Default" shape="Pill" onClick={handleOnClose}>
            <Text style={{ color: '#FFF', fontWeight: 'bold' }}>{selectedVideo ? 'Back' : 'Done'}</Text>
          </Button>
        </ModalFooter>
      </ModalBody>
    </WpModal>
  );
}


const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    color: Constants.colors.darkBlue,
    fontWeight: 'bold',
    margin: 20,
    fontFamily: 'GothamMedium'
  },
  playerWrapper: {
    position: 'relative',
    overflow: 'hidden'
  },
  calendarWrapper: {
    overflow: 'hidden'
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    maxHeight: 600
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1,
    width: 140,
    textAlign: 'center'
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1,
    width: 140,
    textAlign: 'center'
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap'
  },
})

const cssStyles = {
  player: {
    padding: 0,
    overflow: 'hidden',
  },
}