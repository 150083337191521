import { View, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import Constants from '../constants';
import { Button } from './Button';
import { PlusCircleIcon } from '@heroicons/react/solid'
import { AntDesign } from '@expo/vector-icons';
import FadeInOut from 'react-native-fade-in-out';

export default function GraphCreateButton({ muted, isLast, isFirst, after, initial, children, onPress, shortened }: { muted?: boolean, isLast?: boolean, isFirst?: boolean, after?: string, initial?: string, onPress?: (after?: string) => void, children?: JSX.Element[] | JSX.Element, shortened?: boolean }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [position, setPosition] = useState<string>('absolute');


  const openOptions = () => {
    setShowOptions(true);
    setTimeout(() => {
      setPosition('relative');
    }, 100)
  }

  const closeOptions = () => {
    setShowOptions(false);
    setTimeout(() => {
      setPosition('absolute');
    }, 100)
  }

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const handleOnPress = () => {
    if (onPress === undefined) {
      openOptions();
    } else {
      onPress(after);
    }
  }

  return (
    <View style={styles.container}>
      {isFirst ? null : <View style={[styles.line, { height: muted ? 10 : shortened ? 10 : 25 }]}></View>}
      {/* initial ?
        <Button width={200} text={initial} onPress={handleOnPress} /> :
        <PlusCircleIcon style={{...cssStyles.icon, opacity: hovering ? 0.8 : 1}} onMouseEnter={onHover} onMouseLeave={onHoverOut} onClick={handleOnPress} />
      */}
      <AntDesign style={{ cursor: 'pointer', opacity: hovering ? 0.8 : 1 }} name="pluscircle" size={muted ? 20 : 30} color={muted ? Constants.colors.info : Constants.colors.blue} onMouseEnter={onHover} onMouseLeave={onHoverOut} onClick={handleOnPress} />
      <FadeInOut style={styles.optionsWrapper} visible={showOptions} scale={true} duration={200}>
        <View style={[styles.options, { position: position }]} onMouseLeave={closeOptions} onClick={closeOptions}>
          {children}
        </View>
      </FadeInOut>
      {isLast ? null : <View style={[styles.line, { height: muted ? 5 : 25 }]}></View>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative'
  },
  optionsWrapper: {
    position: 'absolute',
  },
  options: {
    borderRadius: 10,
    backgroundColor: '#FFF',
    padding: 10,
    zIndex: 10000,
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.57,
    shadowRadius: 15.19,
    shadowColor: Constants.colors.infoLight,
    elevation: 23
  },
  line: {
    width: 1,
    border: '1px solid ' + Constants.colors.info,
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
});

const cssStyles = {
  icon: {
    width: 40,
    height: 40,
    color: Constants.colors.blue,
    cursor: 'pointer',
    transition: 'margin 200ms'
  }
}
