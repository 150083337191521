import { TouchableOpacity, View, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { PitchContext, WpSlideType, WpPitchAction } from '../contexts/pitch';
import Constants from '../constants';
import { useMutation } from "@apollo/client";
import GraphCreateButton from './GraphCreateButton'
import PitchSlideOptions from './PitchSlideOptions'
import { CREATE_PITCH_SLIDE } from '../graphql/pitch';


export default function PitchSlideCreateButton({ after, first, isLast, onPress } : { after?: string, first?: string, isLast?: boolean, onPress: Function }): JSX.Element {
  const { state, dispatch } = React.useContext(PitchContext);
  const [createSlide, { data, loading, error }] = useMutation(CREATE_PITCH_SLIDE)

  const newSlide = (slideType: WpSlideType) => {
    createSlide({variables: {pitchId: state.pitch?.id, after: after, slideType: slideType}})
  }

  useEffect(() => {
    if (data?.createPitchSlide?.slide) {
      dispatch({
        type: WpPitchAction.CREATE_PITCH_SLIDE,
        slide: data?.createPitchSlide?.slide,
        structure: data?.createPitchSlide?.structure
      })
      onPress(data.createPitchSlide.slide.id);
    }
  }, [data])

  return (
    <GraphCreateButton muted={true} initial={first} isLast={isLast}>
      <PitchSlideOptions onPress={newSlide} />
    </GraphCreateButton>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  line: {
    width: 1,
    height: 15,
    border: '1px solid ' + Constants.colors.info
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
});

const cssStyles = {
  icon: {
    width: 25,
    height: 25,
    color: Constants.colors.info,
    cursor: 'pointer'
  }
}
