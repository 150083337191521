import { View, Text, StyleSheet } from 'react-native';
import React, { useState } from 'react';

import Constants from '../constants';
import { LeadContext, leadReducer } from '../contexts/lead';
import CampaignOverview from '../components/CampaignOverview';
import PitchProvider from '../components/Pitch/PitchProvider';
import { useParams } from './../react-router';
import LandingPage from './../components/Campaign/LandingPage';


export default function LandingPageProvider(): JSX.Element {
  const [state, dispatch] = React.useReducer(leadReducer, {});
  const params = useParams();

  return (
    <View style={{ flex: 1, backgroundColor: Constants.colors.backgroundLight }}>
      <LeadContext.Provider value={{ state, dispatch }}>
        <LandingPage landingPageCode={params.landingPageCode} />
      </LeadContext.Provider>
    </View>
  );
}
