import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import Constants from '../constants';
import { Access, UserContext, WpUserAction } from '../contexts/user';
import { WpGroup } from '../contexts/lead';
import GroupOverview from '../components/Leads/GroupOverview';
import Navbar from '../components/Navbar';
import Spinner from '../components/Spinner';
import ManageLeadsModal from '../components/Leads/ManageLeadsModal';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import Input, { Label } from '../components/Input';
import Thumbnail from '../components/Shared/Thumbnail';
import { GET_GROUPS } from '../graphql/queries';
import { ADD_LEADS_TO_CAMPAIGN, ARCHIVE_GROUPS, CREATE_GROUP, MESSAGE_LEADS, UPDATE_GROUP } from '../graphql/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, IconButton, PageTitle, DashboardNav, DashboardLayout } from '@whistlepitch/wp-components'
import { Button as LocalButton, Theme } from './../components/Button';
import { PlusIcon } from '@heroicons/react/outline';
import { numberWithCommas } from '../tools/tools'
import Checkbox from '../components/Checkbox';
import Icon from '../components/Icon';
import WpModal from '../components/WpModal';
import MessageEditor from '../components/MessageEditor';
import CampaignDropdown from '../components/CampaignDropdown';
import Toast from 'react-native-toast-message';
import NewButton from '../components/NewButton';
import { useNavigate } from '../react-router';
import UserDetailsModal from '../components/User/UserDetailsModal';
import BasicLayout from '../components/Shared/BasicLayout';
import BillingModal from '../components/User/BillingModal';
import { UPDATE_ORGANIZATION, UPDATE_USER } from '../graphql/user';
import { MessageBubble } from '../components/Shared/MessageFeed';


export enum GroupAction {
  ARCHIVE = "ARCHIVE",
  CONFIRM = "CONFIRM",
  EDIT_OPTIN = "EDIT_OPTIN",
  CAMPAIGN = "CAMPAIGN",
  MESSAGE = "MESSAGE",
}

export default function Groups(): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const [groups, setGroups] = useState<WpGroup[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [hovering, setHovering] = useState<"groups" | "leads">();
  const [editingGroup, setEditingGroup] = useState<WpGroup>();
  const [newName, setNewName] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isBilling, setIsBilling] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<GroupAction>();
  const [actionWas, setActionWas] = useState<GroupAction>();
  const [campaignId, setCampaignId] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [messageContent, setMessageContent] = useState<object>();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [optinMessage, setOptinMessage] = useState<string>();
  const [optinMessageContent, setOptinMessageContent] = useState<object>();
  const [understandTiming, setUnderstandTiming] = useState<boolean>(false);
  const [understandContacts, setUnderstandContacts] = useState<boolean>(false);
  const [understandEdit, setUnderstandEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const [getGroups, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_GROUPS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [createGroup, createGroupData] = useMutation(CREATE_GROUP, {
    fetchPolicy: "network-only"
  });

  const [updateGroup, updateGroupData] = useMutation(UPDATE_GROUP, {
    fetchPolicy: "network-only"
  });

  const [archiveGroups, archiveGroupsData] = useMutation(ARCHIVE_GROUPS, {
    fetchPolicy: "network-only",
  });

  const [addGroupsToCampaign, addGroupsToCampaignData] = useMutation(ADD_LEADS_TO_CAMPAIGN, {
    fetchPolicy: "network-only",
  });

  const [messageGroups, messageGroupsData] = useMutation(MESSAGE_LEADS, {
    fetchPolicy: "network-only",
  });

  const [updateUser, updateUserData] = useMutation(UPDATE_USER, {
    fetchPolicy: "network-only",
  });

  const [updateOrganization, updateOrganizationData] = useMutation(UPDATE_ORGANIZATION, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (addGroupsToCampaignData.data || messageGroupsData.data) {
      closeModal();
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: `Your messages are now being sent out in the background.`
      });
      loadGroups();
    }
  }, [addGroupsToCampaignData.data, messageGroupsData.data])

  useEffect(() => {
    if (archiveGroupsData.data) {
      closeModal();
      // Toast.show({
      //   type: 'success',
      //   text1: 'Success',
      //   text2: `Your groups have been deleted.`
      // });
      loadGroups();
    }
  }, [archiveGroupsData.data])

  useEffect(() => {
    if (!groups.length) {
      loadGroups();
    }
  }, [])

  useEffect(() => {
    if (!groups.length && state.user) {
      loadGroups();
    }
  }, [state.user])

  useEffect(() => {
    if (updateUserData.data) {
      window.location.reload()
    }
  }, [updateUserData])

  useEffect(() => {
    if (data?.groups?.groups) {
      setGroups(data.groups.groups)
    }
  }, [data])

  useEffect(() => {
    if (!optinMessageContent) {
      setOptinMessage(state.user?.organization.message)
      setOptinMessageContent(state.user?.organization.messageContent)
    }
  }, [state.user?.organization.messageContent])

  useEffect(() => {
    if (updateOrganizationData.data?.updateOrganization) {
      let org = updateOrganizationData.data.updateOrganization.organization;
      setOptinMessage(org.message)
      setOptinMessageContent(org.messageContent)

      dispatch({ type: WpUserAction.REFRESH_ORGANIZATION, organization: org })
      confirmCampaign(actionWas);
      setLoading(false);
    }
  }, [updateOrganizationData.data])

  useEffect(() => {
    if (createGroupData?.data?.createGroup) {
      if (createGroupData.data.createGroup.success) {
        openGroupModal(createGroupData.data.createGroup.group);
      } else {
        Toast.show({
          type: 'error',
          text1: 'You\'ve reached your limit',
          text2: 'Click here to upgrade for more groups',
          onPress: settingUpBilling
        });
      }
    }
  }, [createGroupData?.data])

  useEffect(() => {
    if (updateGroupData?.data?.updateGroup) {
      closeGroupModal();
    }
  }, [updateGroupData?.data])

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const loadGroups = () => {
    if (state.user) {
      if (data) {
        refetch();
      } else {
        getGroups();
      }
    }
  }

  const onHoverGroups = () => {
    setHovering("groups");
  }

  const onHoverOut = () => {
    setHovering(undefined);
  }

  const openGroupModal = (group: WpGroup) => {
    navigate('/group/' + group.id)
    // setNewName(group.name)
    // setEditingGroup(group)
  }

  const closeGroupModal = () => {
    loadGroups();
    setEditingGroup(undefined)
  }

  const onSelectGroup = (id: string) => {
    const index = selectedGroups.indexOf(id);
    if (index > -1) {
      setSelectedGroups([
        ...selectedGroups.slice(0, index),
        ...selectedGroups.slice(index + 1),
      ])
    } else {
      setSelectedGroups([
        ...selectedGroups,
        id
      ])
    }
  }

  const onCreateGroup = () => {
    createGroup()
  }

  const onSave = () => {
    if (editingGroup) {
      updateGroup({ variables: { groupId: editingGroup.id, name: newName } })
    }
  }

  const closeModal = () => {
    setIsVisible(false);
    setLoading(false);
    setAction(undefined);
  }

  const getSelectedText = () => {
    if (selectedGroups.length) {
      let name = 'Group'
      return `${selectedGroups.length} ${name}${selectedGroups.length > 1 ? 's' : ''} Selected`
    } else {
      return 'Select'
    }
  }

  const onClear = () => {
    setSelectedGroups([]);
  }

  const confirmCampaign = (was: GroupAction) => {
    setActionWas(was);
    startAction(GroupAction.CONFIRM);
  }

  const completeConfirm = () => {
    console.log('state.user?.organization.billing?.active', state.user?.organization.billing?.active)
    if (actionWas === GroupAction.CAMPAIGN) {
      if (state.user?.organization.billing?.active) {
        addGroupsToCampaign({ variables: { groupIds: selectedGroups, campaignId: campaignId } });
      } else {
        settingUpBilling()
        setLoading(false);
      }
    } else {
      if (state.user?.organization.billing?.active) {
        messageGroups({ variables: { groupIds: selectedGroups, messageContent: messageContent } });
      } else {
        settingUpBilling()
        setLoading(false);
      }
    }
  }

  const editOptin = () => {
    startAction(GroupAction.EDIT_OPTIN);
  }

  const renderOptInMessage = () => {
    if (Constants.blastView) {
      return (
        <View style={{ flex: 1, position: 'relative' }}>
          <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, textAlign: 'center', fontSize: 18, marginVertical: 20, paddingHorizontal: 10 }}>Opt-in Message</Text>
          {optinMessage ?
            <MessageBubble userId={"1"} message={optinMessage} margin={'5%'} maxWidth={'90%'} backgroundColor={'white'} /> :
            <Text key="no-optin" style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>No opt-in message has been set. Follow the link below to create one.</Text>}
          <LocalButton style={{ marginTop: 5, marginBottom: 15 }} width={'100%'} text="Edit Opt-in Message" theme={Theme.LINK_PRIMARY} onPress={editOptin} />
        </View>
      )
    }
    return null;
  }

  const getTitle = () => {
    let name = 'Group'
    let campaign = Constants.blastView ? 'Blast' : 'Campaign'
    switch (action) {
      case GroupAction.MESSAGE:
        return `Message ${name}`
      case GroupAction.CAMPAIGN:
        return Constants.blastView ? `Send ${campaign} to ${name}` : `Add ${name} to ${campaign}`
      default:
        return "Are You Sure?"
    }
  }

  const renderActionBody = () => {
    let name = 'group'
    let archiveText = `Archiving a ${name} will remove it from your account. You will no longer be able to see or manage this ${name}.`;
    switch (action) {
      case GroupAction.ARCHIVE:
        return (<Text>{archiveText}</Text>)
      case GroupAction.CONFIRM:
        if (Constants.blastView) {
          return (
            <View style={{ flex: 1, position: 'relative' }}>
              <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>The message shown below will be sent to any new {Constants.localizations.leads} that have not yet opted-in to receive SMS messages from you. If they opt-in, {Constants.localizations.campaigns} and messages will continue from that point.</Text>
              {renderOptInMessage()}

              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandTiming(val)} selected={understandTiming} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand that messages are only sent out between 8am-8pm MST. If you try to send a message outside of these times, our servers will wait until 8am the following morning to send them out.</Text>
              </View>
              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandContacts(val)} selected={understandContacts} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand {Constants.localizations.leads} I add personally will need to opt-in to receive future communication. Any {Constants.localizations.leads} added via a {Constants.brand} landing page do not need an additional opt-in.</Text>
              </View>
              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandEdit(val)} selected={understandEdit} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand I can edit the opt-in message anytime in settings.</Text>
              </View>
            </View>
          )
        } else {
          return (
            <View style={{ flex: 1, position: 'relative' }}>
              <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>Adding a {Constants.localizations.lead} to a {Constants.localizations.campaign} will start the process of sending out each of your messages. Make certain your {Constants.localizations.campaign} is fully set up how you want it before adding any {Constants.localizations.leads}.</Text>
              <Text style={{ fontFamily: 'GothamMedium', color: '#999', marginTop: 20, paddingHorizontal: 10 }}>Note: Messages are only sent out between 8am-8pm MST. If you try to send a message outside of these times, our servers will wait until 8am the following morning to send them out.</Text>
            </View>
          )
        }
      case GroupAction.MESSAGE:
        return (<MessageEditor messageContent={messageContent} setMessageContent={setMessageContent} setMessage={setMessage} />)
      case GroupAction.EDIT_OPTIN:
        return (<MessageEditor messageContent={optinMessageContent} includePlaceholders={false} setMessageContent={setOptinMessageContent} setMessage={setOptinMessage} />)
      case GroupAction.CAMPAIGN:
        return (<CampaignDropdown style={{ zIndex: 5 }} value={campaignId} setValue={setCampaignId} />)
      default:
        return null;
    }
  }

  const getButtonText = () => {
    switch (action) {
      case GroupAction.ARCHIVE:
        return Constants.blastView ? "Delete" : "Archive"
      case GroupAction.MESSAGE:
        return "Send Message"
      case GroupAction.CAMPAIGN:
        return "Continue"
      case GroupAction.EDIT_OPTIN:
        return "Continue"
      default:
        return "Confirm"
    }
  }

  const isDisabled = () => {
    switch (action) {
      case GroupAction.CONFIRM:
        return Constants.blastView && (!understandContacts || !understandEdit || !understandTiming)
      case GroupAction.MESSAGE:
        return !messageContent
      case GroupAction.CAMPAIGN:
        return !campaignId
      default:
        return false
    }
  }

  const onConfirm = () => {
    setLoading(true);
    switch (action) {
      case GroupAction.ARCHIVE:
        archiveGroups({ variables: { groupIds: selectedGroups } });
        setSelectedGroups([]);
        return;
      case GroupAction.MESSAGE:
        if (Constants.blastView && state.user?.organization.understandsTiming) {
          setActionWas(GroupAction.MESSAGE)
          completeConfirm();
        } else {
          confirmCampaign(GroupAction.MESSAGE);
          setLoading(false);
        }
        return;
      case GroupAction.CONFIRM:
        completeConfirm();
        return;
      case GroupAction.CAMPAIGN:
        if (Constants.blastView && state.user?.organization.understandsTiming) {
          setActionWas(GroupAction.CAMPAIGN)
          completeConfirm();
        } else {
          confirmCampaign(GroupAction.CAMPAIGN);
          setLoading(false);
        }
      case GroupAction.EDIT_OPTIN:
        if (state.user?.organization.access && [Access.ADMIN, Access.MANAGER, Access.OWNER].indexOf(state.user.organization.access) > -1 && !updateOrganizationData.loading) {
          updateOrganization({
            variables: {
              organizationId: state.user.organization.id,
              organization: {
                messageContent: optinMessageContent
              }
            }
          })
        }
        return;
      default:
        closeModal();
    }
  }

  const archive = () => {
    startAction(GroupAction.ARCHIVE);
  }

  const addToCampaign = () => {
    startAction(GroupAction.CAMPAIGN);
  }

  const onMessageLeads = () => {
    startAction(GroupAction.MESSAGE);
  }

  const startAction = (action: GroupAction) => {
    if (selectedGroups.length > 0) {
      setAction(action);
      openModal();
    } else {
      Toast.show({
        type: 'error',
        text1: 'Select Groups',
        text2: `Select groups from below first.`
      });
    }
  }

  const openModal = () => {
    setIsVisible(true);
  }

  const settingUpBilling = () => {
    setIsBilling(true);
  }

  const doneSetupBilling = () => {
    setIsBilling(false);
  }

  // const onCompleteBilling = () => {
  //   setIsVisible(true);
  // }

  const onCompleteBilling = (token?: string, plan?: string) => {
    if (state.user) {
      updateUser({
        variables: {
          userId: state.user.id,
          user: {
            token: token,
            plan: plan
          }
        }
      })
    }
  }

  const onCancel = () => {
    setLoading(true);
    switch (action) {
      case GroupAction.EDIT_OPTIN:
        setLoading(false);
        if (actionWas) {
          confirmCampaign(actionWas);
        } else {
          closeModal();
        }
        return;
      default:
        return
    }
  }

  const renderBody = () => {
    if (loading) {
      return (
        <View>
          <Spinner />
        </View>
      )
    } else if (state.user) {
      return (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.heading}>{'Groups'}</Text>
            <NewButton name={'Group'} onClick={onCreateGroup} loading={createGroupData?.loading} />
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 10, marginBottom: 20 }}>
            {selectedGroups.length > 0 ?
              <View style={styles.controlsWrapper}>
                <View style={styles.controls}>
                  <Checkbox color={Constants.colors.darkBlue} onSelect={onClear} selected={true} />
                  <Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium' }}>{getSelectedText()}</Text>
                </View>
              </View> : null}
            <IconButton type="White" shape="Circle" onClick={onMessageLeads}>
              <Icon name="message" raw={true} colorHover="currentColor" color="currentColor" />
            </IconButton>
            <IconButton type="White" shape="Circle" onClick={addToCampaign}>
              <Icon name="campaignPlus" raw={true} colorHover="currentColor" color="currentColor" />
            </IconButton>
            <IconButton type="White" shape="Circle" onClick={archive}>
              <Icon name="trash" width={20} raw={true} colorHover="currentColor" color="currentColor" />
            </IconButton>
          </View>
          <View style={styles.thumbnailWrapper}>
            {groups && groups.length ? groups.map((group, index) => {
              return (
                <Thumbnail
                  key={`group-${group.id}-${index}`}
                  text={group.name}
                  subtext={`${'Leads'}: ${numberWithCommas(group.stats.leads)}`}
                  selectable={true}
                  selected={selectedGroups.indexOf(group.id) > -1}
                  onSelect={() => onSelectGroup(group.id)}
                  onPress={() => navigate('/group/' + group.id)}
                />
              );
            }) : <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary }}>- No Groups Found -</Text>}
          </View>
        </View>
      )
    } else {
      return (
        <View>
        </View>
      )
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/groups'}>
      {renderBody()}

      <ManageLeadsModal
        editing={!!editingGroup}
        title="Manage Group Leads"
        onClose={closeGroupModal}
        groupId={editingGroup?.id}
      >
        <ModalBody width={650} title={`Manage ${editingGroup?.name ? editingGroup.name : 'New Group'}`} closeModal={closeGroupModal}>
          <Input name="Group Name" value={newName} setValue={setNewName} placeholder="Enter Group Name..." />
          {newName !== editingGroup?.name ?
            <ModalFooter>
              <Button key="btn-group-name-save" onClick={onSave} shape="Pill" size="Default" type={"Primary"}>
                {loading ? <Spinner /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>Save</Text>}
              </Button>
            </ModalFooter> : null
          }
        </ModalBody>
      </ManageLeadsModal>
      <WpModal isVisible={isVisible} closeModal={closeModal}>
        <ModalBody title={getTitle()} closeModal={closeModal}>
          {renderActionBody()}
          <ModalFooter>
            {action === GroupAction.EDIT_OPTIN ? <LocalButton style={{ marginTop: 10, marginBottom: 15 }} text="Cancel" theme={Theme.LINK_NEUTRAL} onPress={onCancel} /> : null}
            <Button disabled={isDisabled()} onClick={onConfirm} shape="Pill" size="Default" type={action === GroupAction.ARCHIVE ? "Danger" : "Primary"}>
              {loading || updateOrganizationData.loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>{getButtonText()}</Text>}
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <BillingModal isVisible={isBilling} card={state.user?.organization?.billing?.card} loading={updateUserData.loading} onClose={doneSetupBilling} onComplete={onCompleteBilling} />
    </BasicLayout>
  )
  //
  // return (
  //   <View style={{ flex: 1, flexDirection: 'row', backgroundColor: Constants.colors.backgroundLight }}>
  //     <Navbar active={'/groups'} />
  //     <View style={[{ flex: 1, padding: 25 }, styles.container]}>
  //       <UserDetailsModal isVisible={showUserDetails} user={state.user ? state.user : undefined} onClose={closeUserDetails} />
  //       <DashboardNav name={state.user?.name ?? ''} dropOptions={Constants.dropdownOptions} actionOne={state.user?.wpAdmin} onAction={onDashboardAction} onDropdownSelect={onDashboardAction} />
  //
  //       <View style={{ marginTop: 25 }}></View>
  //       { renderBody() }
  //     </View>
  //     <ManageLeadsModal
  //       editing={!!editingGroup}
  //       title="Manage Group Leads"
  //       onClose={closeGroupModal}
  //       groupId={editingGroup?.id}
  //     >
  //       <ModalBody width={650} title={`Manage ${editingGroup?.name ? editingGroup.name : (Constants.blastView ? 'New Subscription' : 'New Group')}`} closeModal={closeGroupModal}>
  //         <Input name="Group Name" value={newName} setValue={setNewName} placeholder="Enter Group Name..." />
  //         { newName !== editingGroup?.name ?
  //           <ModalFooter>
  //             <Button key="btn-group-name-save" onClick={onSave} shape="Pill" size="Default" type={"Primary"}>
  //               { loading ? <Spinner /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>Save</Text> }
  //             </Button>
  //           </ModalFooter> : null
  //         }
  //       </ModalBody>
  //     </ManageLeadsModal>
  //     <WpModal isVisible={isVisible} closeModal={closeModal}>
  //       <ModalBody title={getTitle()} closeModal={closeModal}>
  //         { renderActionBody() }
  //         <ModalFooter>
  //           <Button disabled={isDisabled()} onClick={onConfirm} shape="Pill" size="Default" type={action === GroupAction.ARCHIVE ? "Danger" : "Primary"}>
  //             { isLoading ? <Spinner /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>{getButtonText()}</Text> }
  //           </Button>
  //         </ModalFooter>
  //       </ModalBody>
  //     </WpModal>
  //   </View>
  // );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
  icon: {
    cursor: 'pointer'
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
  newBtn: {
    fontFamily: 'GothamBold',
    fontSize: 15,
    color: Constants.colors.darkBlue,
    marginLeft: 10
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap'
  },
  controlsWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: Constants.colors.lightBlue,
    alignItems: 'center',
    marginRight: 10
  },
  controls: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
})
