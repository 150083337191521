import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions, Pressable } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import { Ionicons } from '@expo/vector-icons';


export default function Radio({ color=Constants.colors.blue, active, onPress, label, value, multiple=false, style={} } : { color?: string, label:string, value?:string, onPress: (value: string) => void, active?: boolean, multiple?: boolean, style?:object }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>();
  const [icon, setIcon] = useState<"checkmark-circle"|"radio-button-off"|"radio-button-on">();

  // let color = active ? Constants.colors.primary : Constants.colors.secondary;
  // color = hovering ? Constants.colors.primaryMedium : color;

  useEffect(() => {
    if (multiple) {
      setIcon(active ? "checkmark-circle" : "radio-button-off")
    } else {
      setIcon(active ? "radio-button-on" : "radio-button-off")
    }
  }, [active, multiple])

  const onHover = () => {
    setHovering(true);
  }

  const handleOnPress = () => {
    onPress(value ? value : label);
  }



  return (
    <View>
      <Pressable style={styles.radio} onPress={handleOnPress} onMouseEnter={onHover} onMouseLeave={setHovering}>
        { icon ? <Ionicons name={icon} size={20} color={active ? color : Constants.colors.secondary} /> : null }
        <Text style={{ fontFamily: 'Gotham' }}>{label}</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  radio: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    marginVertical: 7,
    marginLeft: 10
  }
});

const cssStyles = {
}
