import { View, Text, StyleSheet, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import Toast from 'react-native-toast-message';

import Constants from '../constants';
import { UserContext } from '../contexts/user';
import CampaignOverview from '../components/CampaignOverview';
import ConfirmPhoneModal from '../components/User/ConfirmPhoneModal';
import LeadTable from '../components/Leads/LeadTable';
import Navbar from '../components/Navbar';
import { REGISTER_USER, REGISTER_PHONE } from '../graphql/user';
import { useNavigate, useParams, useSearchParams } from '../react-router';
import { SignUpView } from '@whistlepitch/wp-components';
import { useQuery, useMutation } from "@apollo/client";
import Spinner from '../components/Spinner';

interface RegisterParams {
  token?: string,
  name: string
  email: string,
  phone: string,
  password: string,
  password2: string,
  organizationName: string,
  brand?: string
}

export default function Register(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [confirmingPhone, setConfirmingPhone] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string | null>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [token, setToken] = useState<string>();
  const [variables, setVariables] = useState<RegisterParams>();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const [registerUser, registerUserData] = useMutation(REGISTER_USER, {
    fetchPolicy: "no-cache"
  })
  const [registerPhone, registerPhoneData] = useMutation(REGISTER_PHONE, {
    fetchPolicy: "no-cache"
  })

  const navigate = useNavigate();

  useEffect(() => {
    setCompanyName(searchParams.get("companyname"))
    setToken(params.token)
    if (!params.token && Constants.brand_key === 'whistlepitch') {
      navigate('/')
    } else {
      document.getElementById("sign_up_fullname")?.setAttribute('autocomplete', 'none')
      document.getElementById("sign_up_email")?.setAttribute('autocomplete', 'none')
      document.getElementById("signup_phone")?.setAttribute('autocomplete', 'none')
      document.getElementById("sign_up_password")?.setAttribute('autocomplete', 'none')
      document.getElementById("sign_up_confirm_password")?.setAttribute('autocomplete', 'none')
    }
  }, [])

  useEffect(() => {
    if (registerPhoneData.data) {
      if (!registerPhoneData.data.registerPhone.success) {
        setConfirmingPhone(false);
        if (registerPhoneData.data.registerPhone.errors.length) {
          setErrorMessage(registerPhoneData.data.registerPhone.errors[0])
        } else {
          setErrorMessage('An unknown error occurred.')
        }
      } else {
        setConfirmingPhone(true);
      }
    }
  }, [registerPhoneData.data])

  useEffect(() => {
    if (registerUserData.data) {
      if (!registerUserData.data.registerUser.success) {
        if (registerUserData.data.registerUser.errors.length) {
          setErrorMessage(registerUserData.data.registerUser.errors[0])
        } else {
          setErrorMessage('An unknown error occurred.')
        }
      } else {
        localStorage.setItem('token', registerUserData.data.registerUser.user.token)
        registerUserData.client.resetStore()
        navigate('/dashboard')
      }
    }
  }, [registerUserData.data])

  if (state.user) {
    navigate('/dashboard')
  }

  const onSignUp = (signupParams) => {
    let vars = {
      token: token,
      name: signupParams.fullName,
      email: signupParams.email,
      phone: signupParams.phone,
      password: signupParams.password,
      password2: signupParams.confirmPassword,
      organizationName: signupParams.companyName,
      brand: Constants.brand_key
    }
    setVariables(vars)
    registerPhone({ variables: { phone: signupParams.phone, brand: Constants.brand_key } })
    setConfirmingPhone(true);
    // if (Constants.blastView) {
    //   registerUser({
    //     variables: vars
    //   })
    // } else {
    //   registerPhone({ variables: { phone: signupParams.phone, brand: Constants.brand_key }})
    //   setConfirmingPhone(true);
    // }
  }

  const onSubmit = (code?: string) => {
    registerUser({
      variables: {
        ...variables,
        phoneCode: code
      }
    })
  }

  const onDoneConfirming = () => {
    setConfirmingPhone(false);
  }

  if (registerUserData.loading) {
    return (
      <View style={{ flex: 1, position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner style={{ marginTop: 40 }} />
      </View>
    )
  }

  const renderAlternateLogos = () => {
    if (Constants.tattooCare) {
      return (
        <View style={{ position: 'absolute', top: 20, left: 40, padding: 60, zIndex: 100, backgroundColor: 'white' }}>
          <Image style={{ width: 175, height: 40 }} source={require('./../assets/tattoocare_heading_inline.png')} />
        </View>
      )
    }
    return null
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <ConfirmPhoneModal isVisible={confirmingPhone} onClose={onDoneConfirming} onSubmit={onSubmit} loading={registerUserData.loading} />
      <SignUpView brand={Constants.brand} showOrganization={!Constants.blastView} onSignUp={onSignUp} errorMessage={errorMessage} />
      {renderAlternateLogos()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  }
})
