import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($userId: ID, $includeBilling: Boolean) {
    user(userId: $userId, includeBilling: $includeBilling) {
      success
      errors
      user {
        id
        new
        code
        name
        firstName
        email
        phone
        token
        showAppt
        showCalendly
        wpAdmin
        stats {
          leads
          messages
          views
          earnings
        }
        customValues {
          id
          model
          modelName
          name
          value
          customFieldId
          created
        }
        organization {
          id
          v2
          name
          brand
          orgType
          message
          messageContent
          welcomeMessageContent
          autoReply
          autoReplyContent
          autoReplyEnabled
          twilioPhone
          messageAlertIds
          integrations {
            id
            userId
            created
            integrationType
          }
          forwardingPhone
          allowCallForwarding
          allowIncomingLeads
          allowSelfOnboarding
          allowAfterHours
          allowWeekends
          allowOldStats
          understandsTiming
          understandsContacts
          understandsEdit
          showMessages
          showAppt
          showCalendly
          showAudio
          showApptPlaceholders
          showCustomWindow
          showEventsPage
          stats {
            leads
            messages
            messagesSuccess
            messagesFailed
            responses
            deliveryRate
            responseRate
            openRate
            viewRate
            slideViewRate
          }
          color
          image
          access
          pitches {
            id
            name
          }
          users {
            id
            name
          }
          bank {
            name
            last4
          }
          billing {
            active
            expires
            periodEnd
            products {
              id
              name
              price
            }
            card {
              brand
              last4
              expMonth
              expYear
            }
            invoices {
              paid
              amount
              url
            }
            messages {
              cycle
              total
            }
          }
        }
        integrations {
          id
          userId
          created
          integrationType
        }
        organizations {
          id
          name
          access
          understandsTiming
          understandsContacts
          understandsEdit
          bank {
            name
            last4
          }
        }
      }
    }
  }
`;


export const GET_CUSTOM_VALUES = gql`
  query getCustomValues($model: CustomFieldModel, $leadId: ID, $userId: ID, $calendarOnly: Boolean, $calendlyOnly: Boolean, $search: String) {
    customValues(model: $model, leadId: $leadId, userId: $userId, calendarOnly: $calendarOnly, calendlyOnly: $calendlyOnly, search: $search) {
      success
      errors
      values {
        id
        model
        modelName
        name
        value
        customFieldId
        created
      }
    }
  }
`;

export const GET_LANDING_PAGES = gql`
  query getLandingPages {
    landingPages {
      success
      errors
      landingPages {
        id
        title
        description
        color
        image
        code
        widgets {
          id
          pitchSlideId
          label
          fineText
          widgetType
          labels
          start
          end
          created
        }
        structure {
          id
          label
          type
        }
        stats {
          leads
        }
        campaigns {
          id
          name
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_CATEGORIES = gql`
  query getCampaignCategories {
    campaignCategories {
      success
      errors
      categories
    }
  }
`;

export const GET_LANDING_PAGE = gql`
  query getLandingPage($landingPageId: ID, $landingPageCode: String) {
    landingPage(landingPageId: $landingPageId, landingPageCode: $landingPageCode) {
      success
      errors
      landingPage {
        id
        title
        description
        color
        image
        code
        widgets {
          id
          pitchSlideId
          label
          fineText
          widgetType
          labels
          start
          end
          created
        }
        structure {
          id
          label
          type
        }
        campaigns {
          id
          name
        }
        stats {
          leads
        }
        dropdownOptions {
          id
          label
        }
      }
      organization {
        id
        v2
        name
        brand
        allowSelfOnboarding
        welcomeMessageContent
      }
    }
  }
`;

export const GET_LANDING_PAGE_DROPDOWN_OPTIONS = gql`
  query getLandingPageDropdownOptions {
    landingPageDropdownOptions {
      success
      errors
      landingPageDropdownOptions {
        id
        label
        campaigns {
          id
          name
        }
      }
    }
  }
`;

export const GET_LANDING_PAGE_DROPDOWN_OPTION = gql`
  query getLandingPageDropdownOption {
    landingPageDropdownOption {
      success
      errors
      landingPageDropdownOption {
        id
        label
        campaigns {
          id
          name
        }
      }
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query getCampaign($campaignId: ID, $campaignCode: String, $isPublic: Boolean) {
    campaign(campaignId: $campaignId, campaignCode: $campaignCode, isPublic: $isPublic) {
      success
      errors
      campaign {
        id
        v2
        leadId
        leadName
        name
        code
        lastActive
        hasAppt
        calendlyEventUri
        calendlyEventName
        calendlyEventLink
        brandName
        brandImage
        brandColor
        public
        description
        category
        features {
          id
          label
          created
          campaignId
          days
          hours
          minutes
          window
          delayUntil
          structureType
          featureType
          message
          messageContent
          pitch {
            id
            title
            redirectType
            redirectUrl
            redirectPhone
            redirectButton
            structure {
              id
              label
              slideType
              isVideo
              type
              button
              phone
              url
              structure {
                id
                label
                type
              }
            }
          }
          workflows {
            id
            label
            triggers {
              id
              label
              triggerType
              triggerOption
              triggerResponses
              seconds
              days
              hours
              widgetId
              performedAction
              widgetExpression
            }
            actions {
              id
              label
              default
              actionType
              messageContent
              webhookUrl
              nextCampaignId
              userIds
            }
          }
        }
        structure {
          feature {
            id
            featureType
            label
            pitchId
            structure {
              id
              label
              slideType
              isVideo
              type
              button
              phone
              url
              structure {
                id
                label
                type
              }
            }
          }
          before {
            id
            featureType
            label
            structure {
              id
              label
              slideType
              structure {
                id
                label
                type
              }
            }
          }
          appointment {
            id
            featureType
            label
            structure {
              id
              label
              slideType
              structure {
                id
                label
                type
              }
            }
          }
        }
        stats {
          leads
          opened
          complete
          currentLeads
          responses
          messages
          messagesSuccess
          messagesFailed
          features {
            slideId
            featureId
            label
            leads
            complete
          }
        }
        landingPage {
          title
          description
          image
          color
          widgets {
            id
            pitchSlideId
            label
            fineText
            widgetType
            labels
            start
            end
            created
          }
          structure {
            id
            label
            type
          }
        }
      }
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query getCampaigns($includeFeatures: Boolean, $includeStats: Boolean, $recent: Boolean, $publicOnly: Boolean) {
    campaigns(includeFeatures: $includeFeatures, includeStats: $includeStats, recent: $recent, publicOnly: $publicOnly) {
      success
      errors
      campaigns {
        id
        v2
        leadId
        leadName
        name
        lastActive
        hasAppt
        public
        description
        category
        userName
        features {
          id
          label
          created
          campaignId
          days
          hours
          minutes
          window
          delayUntil
          structureType
          featureType
          message
          messageContent
          pitch {
            id
            title
            redirectType
            redirectUrl
            redirectPhone
            redirectButton
            structure {
              id
              label
              slideType
              isVideo
              type
              button
              phone
              url
              structure {
                id
                label
                type
              }
            }
          }
          workflows {
            id
            label
            triggers {
              id
              label
              triggerType
              triggerOption
              triggerResponses
              seconds
              days
              hours
              widgetId
              performedAction
              widgetExpression
            }
            actions {
              id
              label
              default
              actionType
              messageContent
              webhookUrl
              nextCampaignId
              userIds
            }
          }
        }
        structure {
          feature {
            id
            featureType
            label
            pitchId
            structure {
              id
              label
              slideType
              isVideo
              type
              button
              phone
              url
              structure {
                id
                label
                type
              }
            }
          }
          before {
            id
            featureType
            label
            structure {
              id
              label
              slideType
              structure {
                id
                label
                type
              }
            }
          }
          appointment {
            id
            featureType
            label
            structure {
              id
              label
              slideType
              structure {
                id
                label
                type
              }
            }
          }
        }
        stats {
          leads
          opened
          complete
          currentLeads
          responses
          messages
          messagesSuccess
          messagesFailed
          features {
            slideId
            featureId
            label
            leads
            complete
          }
        }
        landingPage {
          title
          description
          image
          color
          structure {
            id
            label
            type
          }
        }
      }
    }
  }
`;

export const GET_GROUP = gql`
  query getGroup($groupId: ID, $groupCode: String) {
    group(groupId: $groupId, groupCode: $groupCode) {
      success
      errors
      group {
        id
        name
        code
        stats {
          leads
        }
        landingPage {
          title
          description
          explainer
          thumbnail
          explainerMimeType
          color
          profile
          schedule
          price
          sendCampaign
          campaignId
          trialPeriod
        }
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query getGroups {
    groups {
      success
      errors
      groups {
        id
        name
        stats {
          leads
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query users($allUsers: Boolean, $orgAdmins: Boolean) {
    users(allUsers: $allUsers, orgAdmins: $orgAdmins) {
      success
      errors
      users {
        id
        new
        code
        name
        firstName
        email
        phone
        token
        showAppt
        showCalendly
        wpAdmin
        stats {
          leads
          messages
          views
          earnings
        }
        customValues {
          id
          model
          modelName
          name
          value
          customFieldId
          created
        }
        organization {
          id
          v2
          name
          brand
          orgType
          message
          messageContent
          welcomeMessageContent
          autoReply
          autoReplyContent
          autoReplyEnabled
          twilioPhone
          messageAlertIds
          integrations {
            id
            userId
            created
            integrationType
          }
          forwardingPhone
          allowCallForwarding
          allowIncomingLeads
          allowSelfOnboarding
          allowAfterHours
          allowWeekends
          allowOldStats
          understandsTiming
          understandsContacts
          understandsEdit
          showMessages
          showAppt
          showCalendly
          showAudio
          showApptPlaceholders
          showCustomWindow
          showEventsPage
          stats {
            leads
            messages
            messagesSuccess
            messagesFailed
            responses
            deliveryRate
            responseRate
            openRate
            viewRate
            slideViewRate
          }
          color
          image
          access
          pitches {
            id
            name
          }
          users {
            id
            name
          }
          bank {
            name
            last4
          }
          billing {
            active
            expires
            periodEnd
            products {
              id
              name
              price
            }
            card {
              brand
              last4
              expMonth
              expYear
            }
            invoices {
              paid
              amount
              url
            }
            messages {
              cycle
              total
            }
          }
        }
        integrations {
          id
          userId
          created
          integrationType
        }
        organizations {
          id
          name
          access
          understandsTiming
          understandsContacts
          understandsEdit
          bank {
            name
            last4
          }
        }
      }
    }
  }
`;

export const GET_PITCH = gql`
  query pitch($pitchId: ID!) {
    pitch(pitchId: $pitchId) {
      success
      errors
      pitch {
        id
        userId
        organizationId
        title
        description
        endText
        image
        color
        shareImage
        showPreviews
        created
        archived
        code
        structure {
          id
          label
          slideType
          thumbnail
          isVideo
          structure {
            id
            label
            type
          }
        }
        slides {
          id
          pitchId
          slideType
          internalTitle
          created
          title
          description
          thumbnail
          url
          audioFile
          calendarSettings {
            userId
            url
            highlevelCalendarId
          }
          isVideo
          processed
          content
          mimeType
          widgetStructure{
            id
            label
            type
          }
          widgets {
            id
            pitchSlideId
            label
            fineText
            widgetType
            labels
            start
            end
            created
          }
        }
        redirectType
        redirectUrl
        redirectPhone
        redirectButton
      }
    }
  }
`;

export const GET_LEADS = gql`
  query leads($campaignId: ID, $groupId: ID, $page: Int!, $search: String, $leadFilter: LeadFilter) {
    leads(campaignId: $campaignId, groupId: $groupId, page: $page, search: $search, leadFilter: $leadFilter) {
      success
      errors
      total
      page
      leads {
        id
        name
        phone
        optedIn
        optedOut
        invalidPhone
        created
        lastViewed
        lastActive
        responsibleUser {
          id
          name
        }
        campaigns {
          code
          campaign {
            id
            name
          }
        }
        stats {
          views
          seconds
        }
        pseudoTags {
          campaignId
          pitchId
          slideId
          widgetId
          verified
          video
          widget
          scheduled
          redirected
          unopened
          opened
          reopened
          complete
          sent
          optedOut
        }
      }
    }
  }
`;

export const GET_LEAD = gql`
  query lead($leadId: ID!, $messagesOnly: Boolean) {
    lead(leadId: $leadId, messagesOnly: $messagesOnly) {
      success
      errors
      lead {
        id
        name
        phone
        landingPageId
        optedOut
        optedIn
        invalidPhone
        lastActive
        lastViewed
        responsibleUser {
          id
          name
        }
        appointments {
          id
          eventUrl
          eventDate
          eventTime
          campaigns {
            id
            name
          }
          pitchId
          pitchSlideId
        }
        campaigns {
          code
          codes {
            campaignName
            pitchName
            code
            url
            path
          }
          campaign {
            id
            name
            leadId
            v2
          }
        }
        groups {
          id
          name
        }
        messages {
          id
          userId
          campaignId
          campaignMessageId
          message
          created
          sent
          success
          delivered
          senderName
          aiGenerated
        }
        messageQueue {
          id
          userId
          campaignId
          campaignMessageId
          message
          created
          sent
          success
          delivered
          senderName
          aiGenerated
        }
        featureQueue {
          id
          leadId
          campaignName
          appointmentDate
          message
          created
          scheduledFor
          error
          complete
        }
        widgets {
          pitchId
          widgetId
          label
          content
          values
        }
        activity {
          ssid
          leadId
          pitch {
            id
            title
            structure {
              id
              label
              slideType
              isVideo
              type
              button
              phone
              url
              structure {
                id
                label
                type
              }
            }
          }
          userId
          campaignFeatureId
          campaignId
          times {
            seconds
            video
            videoDuration
            viewRanges {
              start
              end
            }
            audio
            audioDuration
            pitchSlideId
            pitchSlideTitle
          }
          lastActive
          archived
          active
          created
        }
      }
    }
  }
`;

export const GET_PITCHES = gql`
  query pitches {
    pitches {
      success
      errors
      pitches {
        id
        title
        structure {
          id
          slideType
          thumbnail
          isVideo
        }
      }
    }
  }
`;

export const GET_SLIDE_WIDGET = gql`
  query slideWidget($slideWidgetId: ID!) {
    slideWidget(slideWidgetId: $slideWidgetId) {
      success
      errors
      widget {
        id
        pitchSlideId
        label
        fineText
        widgetType
        labels
        start
        end
        created
      }
    }
  }
`;

export const GET_PITCH_SLIDES = gql`
  query pitchSlides($pitchId: ID!) {
    pitchSlides(pitchId: $pitchId) {
      success
      errors
      slides {
        id
        title
        slideType
        isVideo
        widgetStructure {
          id
          label
          type
          options
        }
      }
    }
  }
`;

export const GET_PITCH_SLIDE = gql`
  query getPitchSlide($pitchSlideId: ID!, $isPublic: Boolean) {
    pitchSlide(pitchSlideId: $pitchSlideId, isPublic: $isPublic) {
      success
      errors
      slide {
        id
        pitchId
        slideType
        internalTitle
        audioFile
        created
        title
        description
        thumbnail
        url
        calendarSettings {
          userId
          url
          highlevelCalendarId
        }
        isVideo
        processed
        content
        mimeType
        widgetStructure {
          id
          label
          type
        }
        widgets {
          id
          widgetLabel
          label
          fineText
          widgetType
          labels
          start
          end
        }
        calendars {
          id
          model
          modelName
          name
          value
          customFieldId
          created
        }
      }
    }
  }
`;

export const LOGOUT_USER = gql`
  query logoutUser {
    logoutUser {
      success
      errors
    }
  }
`;

export const SEND_STATS = gql`
  query sendStats {
      sendStats {
          success
          errors
          stats {
              total
              pending
              sent
              paused
              finishes
              campaignId
              campaignName
          }
      }
  }
`;

export const GET_ENGAGEMENT_METRICS = gql`
  query engagementMetrics {
    engagementMetrics {
      success
      errors
      engagementMetrics {
        id
        view
        viewTime
        share
        reopen
        widget
        video
        scheduled
        completed
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents($includeAll: Boolean) {
    events(includeAll: $includeAll) {
        success
        errors
        events {
          id
          organizationId
          cancelled
          eventTime
          eventLocation
          eventTimeZone
          eventAddress
          created
          appointments {
            id
          }
        }
    }
  }
`;