import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import Constants from '../constants';
import { UserContext } from '../contexts/user';
import Navbar from '../components/Navbar';
import Spinner from '../components/Spinner';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import PitchEditor from '../components/PitchEditor';
import Thumbnail from '../components/Shared/Thumbnail';
import Input, { Label } from '../components/Input';
import { Button, Theme } from '../components/Button';
import { useNavigate } from '../react-router';
import { GET_PITCHES } from '../graphql/queries';
import { WpCampaign } from '../contexts/user';
import { WpFullPitch, WpPitchStructure, WpSlideType } from '../contexts/pitch';
import { CREATE_CAMPAIGN, UPDATE_CAMPAIGN } from '../graphql/campaign';
import { CREATE_PITCH } from '../graphql/pitch';
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { IconButton, DashboardNav, PageTitle } from '@whistlepitch/wp-components'
import { PlusIcon } from '@heroicons/react/outline';


export default function Library(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [pitches, setPitches] = useState<WpFullPitch[]>([]);
  const [hovering, setHovering] = useState<"pitches"|"leads">();
  const [editingPitchId, setEditingPitchId] = useState<string>();

  const getPitchesData = useQuery(GET_PITCHES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [createPitch, createPitchData] = useMutation(CREATE_PITCH, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (getPitchesData.data?.pitches?.pitches) {
      setPitches(getPitchesData.data.pitches.pitches)
    }
  }, [getPitchesData.data, getPitchesData.networkStatus])

  useEffect(() => {
    if (createPitchData?.data?.createPitch?.pitch) {
      openPitchModal(createPitchData.data.createPitch.pitch.id)
    }
  }, [createPitchData.data])

  const onCreate = () => {
    createPitch()
  }

  const onHoverPitches = () => {
    setHovering("pitches");
  }

  const onHoverOut = () => {
    setHovering(undefined);
  }

  const openPitchModal = (pitch: WpFullPitch | null) => {
    if (pitch?.id) {
      setEditingPitchId(pitch.id)
    }
  }

  const closePitchModal = () => {
    setEditingPitchId(undefined)
  }

  const onCreatePitch = () => {
    createPitch()
  }

  const openPitch = (pitchId: string) => {
    setEditingPitchId(pitchId)
  }

  const getFirstImage = (pitchStructure: WpPitchStructure[]) => {
    let image;

    for (let i = 0; i < pitchStructure.length; i++) {
      if (pitchStructure[i].id !== "redirect" && pitchStructure[i].thumbnail) {
        image = pitchStructure[i].thumbnail
      }
      if (image) {
        break;
      }
    }

    return image;
  }

  const renderBody = () => {
    if (state.user) {
      // <IconButton type={"Primary"} shape={"Circle"} shadow={true}>
      //   <PlusIcon style={{ width: 20, height: 20 }} />
      // </IconButton>

      return (
        <View style={styles.container}>
          <View style={styles.header}>
            <PageTitle title="Pitches" size="Large" />
            { createPitchData?.loading ? <Spinner /> : <Text onPress={onCreatePitch} style={[styles.newBtn, {opacity: hovering === "pitches" ? 0.7 : 1}]} onMouseEnter={onHoverPitches} onMouseLeave={onHoverOut}>+ New Pitch</Text> }
          </View>
          <View style={styles.thumbnailWrapper}>
            { pitches && pitches.length ? pitches.map((pitch, index) => {
              let image = getFirstImage(pitch.structure);

              return (
                <Thumbnail
                  key={`pitch-${pitch.id}-${index}`}
                  text={pitch.title}
                  image={image}
                  onPress={() => openPitch(pitch.id)}
                />
              );
            }) : <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary }}>- No Pitches Found -</Text> }
            </View>
        </View>
      )
    } else {
      return (
        <View>
        </View>
      )
    }
  }


  return (
    <View style={{ flex: 1, flexDirection: 'row', backgroundColor: Constants.colors.backgroundLight }}>
      <Navbar active={'/library'} />
      <View style={[{ flex: 1 }, styles.container]}>
        <DashboardNav email={state?.user?.email ?? ''} name={state?.user?.name ?? ''} />
        <View style={{ marginTop: 25 }}></View>
        { renderBody() }
      </View>
      <PitchEditor pitchId={editingPitchId} onClose={closePitchModal} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 25
  },
  heading: {
    fontSize: 34,
    color: Constants.colors.blue,
    fontWeight: 'bold',
    marginRight: 10
  },
  newBtn: {
    fontSize: 14,
    color: Constants.colors.darkBlue,
    fontWeight: 'bold',
    marginLeft: 10
  },
  icon: {
    cursor: 'pointer'
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap'
  }
})
