import { Text, LayoutChangeEvent, View, StyleSheet, Image, ActivityIndicator, Dimensions, TextInput } from 'react-native';
import React, { useState, useEffect } from 'react';
import { PitchContext, PitchSlideContext, WpPitchSlideAction, WpSlideType } from './../../contexts/pitch';
import { CampaignContext, WpCampaignAction } from './../../contexts/campaign';
import { GET_PITCH_SLIDE } from '../../graphql/queries';
import { UPDATE_PITCH_SLIDE, CREATE_SLIDE_WIDGET } from '../../graphql/pitch';
import { UPDATE_CAMPAIGN } from '../../graphql/campaign';
import Constants from '../../constants';
import ModalBody from './../ModalBody';
import { Feather } from '@expo/vector-icons';
import WpModal from './../WpModal';
import ModalFooter from './../ModalFooter';
import { Button, Theme } from './../Button';
import Uppyup from './../Uppyup';
import Input, { Label } from './../Input';
import SlideWidget from './../Pitch/SlideWidget';
import { useLazyQuery, useMutation } from "@apollo/client";
import Collapsible from 'react-native-collapsible/Collapsible';
import ReactPlayer from 'react-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from 'react-native-toast-message';
import { TrashIcon } from '@heroicons/react/outline';

const window = Dimensions.get('window');


export default function CampaignLandingPageModal({ isVisible, closeModal }: { isVisible: boolean, closeModal: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(CampaignContext);

  const [viewWidth, setViewWidth] = useState<number>(0);
  const [editing, setEditing] = useState<string>();
  const [newImage, setNewImage] = useState<boolean>();

  const [dimensions, setDimensions] = useState(window)
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [color, setColor] = useState<string>()
  const [image, setImage] = useState<string>()
  const [copyText, setCopyText] = useState<string>("Save and Copy Link")

  const [plugins, setPlugins] = useState<string[]>([])
  const [newVideo, setNewVideo] = useState<boolean>(false)
  const [aspectRatio, setAspectRatio] = useState<number>(0)
  const [ImageElement, setImageElement] = useState<JSX.Element>()

  const [getSlide, getSlideData] = useLazyQuery(GET_PITCH_SLIDE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })
  const [updateCampaign, updateCampaignData] = useMutation(UPDATE_CAMPAIGN, {
    fetchPolicy: "no-cache"
  })

  const [createWidget, createWidgetData] = useMutation(CREATE_SLIDE_WIDGET, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (!image) {
      return;
    }

    let isValid = true;
    Image.getSize(image, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [image]);

  useEffect(() => {
    if (image) {
      setImageElement(<Image style={{ borderRadius: 10, alignSelf: 'center', marginTop: 20, width: 200, aspectRatio }} source={{ uri: image }} />)
    }
  }, [aspectRatio, image])

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  useEffect(() => {
    if (state.campaign?.landingPage) {
      if (title === undefined) {
        setTitle(state.campaign.landingPage.title)
        setDescription(state.campaign.landingPage.description)
        setColor(state.campaign.landingPage.color)
        setImage(state.campaign.landingPage.image)
      }
    }
  }, [state.campaign?.landingPage]);

  useEffect(() => {
    if (createWidgetData.data?.createSlideWidget?.success) {
      dispatch({ type: WpCampaignAction.CREATE_WIDGET, structure: createWidgetData.data.createSlideWidget.structure });
      setEditing(createWidgetData.data.createSlideWidget.widget.id)
    }
  }, [createWidgetData.data]);

  useEffect(() => {
    if (updateCampaignData.data?.updateCampaign?.success) {
      clearEditing();
      onCloseModal();
    }
  }, [updateCampaignData.data?.updateCampaign])

  const clearEditing = () => {
    dispatch({ type: WpCampaignAction.SAVE_WIDGET })
    setEditing(undefined)
  }

  const startEditing = (id: string) => {
    setEditing(id)
  }

  const onCloseModal = () => {
    closeModal();
    setCopyText("Save and Copy Link")
  }

  const onUploadComplete = (url: string, mime: string) => {
    setImage(url);
    setNewImage(false);
  }

  const onCreateWidget = () => {
    let s = [...state.campaign?.landingPage.structure ?? []]
    s.push({
      id: 'new',
    })
    dispatch({ type: WpCampaignAction.CREATE_WIDGET, structure: s })
    setEditing('new')
    // createWidget({ variables: {campaignId: state.campaign?.id}})
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onSave = () => {
    updateCampaign({
      variables: {
        campaignId: state.campaign?.id,
        landingPageTitle: title,
        landingPageDescription: description,
        landingPageColor: color,
        landingPageImage: image
      }
    })
  }

  const onNewImage = () => {
    setNewImage(true);
  }

  const confirmCopy = () => {
    setCopyText('Copied! Saving...')
    Toast.show({
      type: 'success',
      text1: 'Link Copied',
    });
    onSave();
  }

  const renderBranding = () => {
    if (Constants.blastView) {
      return (
        <View>
          <Input name="Landing Page Branding Color" style={{ marginBottom: 20 }} isColor={true} value={color} setValue={setColor} />
          <Label name="Landing Page Branding Image" />
          {image && !newImage ?
            <View>
              {ImageElement}
              <Button style={{ marginTop: 25 }} width={'100%'} text="Upload New Brand Image" theme={Theme.LINK_PRIMARY} onPress={onNewImage} />
            </View> :
            <Uppyup plugins={[]} height={200} onDone={onCloseModal} onUploadComplete={onUploadComplete} />
          }
        </View>
      )
    }
    return null;
  }

  return (
    <WpModal isVisible={isVisible} closeModal={onCloseModal}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ModalBody title={`Edit Landing Page`} closeModal={onCloseModal} style={{ width: dimensions.width * 0.8, maxWidth: 500 }} >
          <Input style={{ marginBottom: 10 }} name="Title" placeholder="Enter a title" value={title} setValue={setTitle} />
          <Input style={{ marginBottom: 10 }} name="Description" optional={true} multiline={true} placeholder="Enter a description" value={description} setValue={setDescription} />

          {renderBranding()}

          {Constants.blastView ? null :
            <View style={{}}>
              <Text style={{ fontSize: 14, fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, marginVertical: 15 }}>Widgets</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }}>
                {!state?.campaign?.landingPage.structure || !state.campaign.landingPage.structure.length ? null :
                  state.campaign.landingPage.structure.map((widget, index, list) => {
                    return (
                      <SlideWidget key={`slide-widget-${widget.id}-option`} widgetId={widget.id} widgetType={widget.type} campaignId={state.campaign?.id} startEditing={startEditing} clearEditing={clearEditing} label={widget.label} isEditing={!!editing && editing === widget.id} />
                    );
                  })
                }
              </View>
              <Text onPress={onCreateWidget} style={styles.newBtn}>+ Add widgets</Text>
            </View>}

          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
            <CopyToClipboard text={`${Constants.app_url}/l/${state.campaign?.code}`} onCopy={confirmCopy}>
              <Text style={{ marginLeft: 5, color: Constants.colors.blue, cursor: 'pointer' }}>{copyText}</Text>
            </CopyToClipboard>
            <Button key="btn-feature-save" text="Save" onPress={onSave} />
            <CopyToClipboard text={`${Constants.app_url}/l/${state.campaign?.code}`} onCopy={confirmCopy}>
              <Text style={{ marginLeft: 5, color: 'white' }}>{copyText}</Text>
            </CopyToClipboard>
          </View>
        </ModalBody>
      </View>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  pitch: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
});

const cssStyles = {
  player: {
    marginTop: 15,
    borderRadius: 15,
    padding: 0,
    overflow: 'hidden'
  }
}
