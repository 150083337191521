import { View, Text, StyleSheet, Image } from 'react-native';
import React, { useState, useEffect } from 'react';

import Constants from '../constants';
import { UserContext, WpUserAction } from '../contexts/user';
import { LOGOUT_USER } from '../graphql/user';
import { useNavigate } from '../react-router';
import { useQuery } from "@apollo/client";
import Spinner from '../components/Spinner';

export default function Logout(): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);

  const { data } = useQuery(LOGOUT_USER, {
    fetchPolicy: "no-cache"
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      localStorage.removeItem('token');
      dispatch({ type: WpUserAction.LOGOUT_USER, user: data?.user?.user})
      navigate("/login")
    }
  }, [data])

  return (
    <View style={{ flex: 1 }}>
      <Spinner />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  }
})
