import { View, Text, ActivityIndicator, StyleSheet, Dimensions, LayoutChangeEvent } from 'react-native';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate } from '../../react-router';

import { formatDateString } from './../../tools/tools';
import Constants from '../../constants';
import Spinner from './../Spinner';
import Input, { Label } from './../Input';
import UserDropdown from './../UserDropdown';
import MessageFeed from './../Shared/MessageFeed';
import { WpLead, WpLeadActivity } from '../../contexts/lead';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import FullscreenModal from './../Shared/FullscreenModal';
import Heading from './../Shared/Heading';
import { SimpleLineIcons, Ionicons, Entypo, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { BarChart, XAxis, YAxis, Bar, Line, Legend, Tooltip, CartesianGrid } from "recharts";
import { Access, UserContext, WpUser, Subscription, CustomValue, WpUserAction, WpEngagementMetrics } from '../../contexts/user';
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import Dropdown, { Item } from '../Dropdown';
import ModalFooter from '../ModalFooter';
import { Button } from '@whistlepitch/wp-components';
import { UPDATE_ENGAGEMENT_METRICS } from '../../graphql/user';
import { GET_ENGAGEMENT_METRICS } from '../../graphql/queries';
import Toast from 'react-native-toast-message';
import Icon from '../Icon';


const permissionItems: Item[] = [
  {
    label: "Notifications Only",
    value: Access.LIMITED,
  },
  {
    label: "Can View Account",
    value: Access.READONLY,
  },
  {
    label: "Can Make Changes",
    value: Access.MANAGER,
  },
  {
    label: "Admin",
    value: Access.OWNER,
  },
]

export default function EngagementMetricsModal({ isVisible, onClose } : { isVisible:boolean, onClose: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);

  const [view, setView]= useState<number>()
  const [viewTime, setViewTime]= useState<number>()
  const [share, setShare]= useState<number>()
  const [reopen, setReopen]= useState<number>()
  const [widget, setWidget]= useState<number>()
  const [video, setVideo]= useState<number>()
  const [scheduled, setScheduled]= useState<number>()
  const [completed, setCompleted]= useState<number>()

  const { data, loading } = useQuery(GET_ENGAGEMENT_METRICS, {
    fetchPolicy: "no-cache"
  });

  const [updateEngagementMetrics, updateEngagementMetricsData] = useMutation(UPDATE_ENGAGEMENT_METRICS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (updateEngagementMetricsData.data && updateEngagementMetricsData.data?.updateEngagementMetrics?.engagementMetrics) {
      let em: WpEngagementMetrics = updateEngagementMetricsData.data?.updateEngagementMetrics?.engagementMetrics;
      setView(em.view)
      setViewTime(em.viewTime)
      setShare(em.share)
      setReopen(em.reopen)
      setWidget(em.widget)
      setVideo(em.video)
      setScheduled(em.scheduled)
      setCompleted(em.completed)
      handleOnClose();
    }
  }, [updateEngagementMetricsData.data])

  useEffect(() => {
    if (data?.engagementMetrics && data?.engagementMetrics?.engagementMetrics) {
      let em: WpEngagementMetrics = data?.engagementMetrics?.engagementMetrics;
      setView(em.view)
      setViewTime(em.viewTime)
      setShare(em.share)
      setReopen(em.reopen)
      setWidget(em.widget)
      setVideo(em.video)
      setScheduled(em.scheduled)
      setCompleted(em.completed)
    }
  }, [data])

  const handleOnClose = () => {
    onClose()
  }

  const saveEngagementMetrics = () => {
    updateEngagementMetrics({
      variables: {
        engagementMetrics: {
          view: parseInt(view),
          viewTime: parseInt(viewTime),
          share: parseInt(share),
          reopen: parseInt(reopen),
          widget: parseInt(widget),
          video: parseInt(video),
          scheduled: parseInt(scheduled),
          completed: parseInt(completed)
        }
      }
    })
  }

  const renderEngagementMetrics = () => {
    if (data?.engagementMetrics) {
      return (
        <View key={`engagement-metrics-row`} style={styles.row}>
          <View key={`engagement-metrics-col-1`} style={styles.col}>
            <Input style={styles.input} name="Views" info="Score for each unique view" keyboardType="numeric" value={view} setValue={setView} />
          </View>
          <View key={`engagement-metrics-col-2`} style={styles.col}>
            <Input style={styles.input} name="Reopens" info="Extra points for each additional view (added on in addition to the view points)." keyboardType="numeric" value={reopen} setValue={setReopen} />
          </View>
          <View key={`engagement-metrics-col-3`} style={styles.col}>
            <Input style={styles.input} name="Widget" info="Score for each widget completed." keyboardType="numeric" value={widget} setValue={setWidget} />
          </View>
          <View key={`engagement-metrics-col-4`} style={styles.col}>
            <Input style={styles.input} name="Scheduled" info="Score given if the lead scheduled in a Calendly widget." keyboardType="numeric" value={scheduled} setValue={setScheduled} />
          </View>
          <View key={`engagement-metrics-col-5`} style={styles.col}>
            <Input style={styles.input} name="View Time" info="Score for each 6 seconds of viewing time. Multiply by 10 for the score per minute (i.e. 1 point for 6 seconds is 10 points for each minute)" keyboardType="numeric" value={viewTime} setValue={setViewTime} />
          </View>
          <View key={`engagement-metrics-col-6`} style={styles.col}>
            <Input style={styles.input} name="Shares" info="Score for each share." keyboardType="numeric" value={share} setValue={setShare} />
          </View>
          <View key={`engagement-metrics-col-7`} style={styles.col}>
            <Input style={styles.input} name="Video" info="Score for watching a video (at least 85% of it)." keyboardType="numeric" value={video} setValue={setVideo} />
          </View>
          <View key={`engagement-metrics-col-8`} style={styles.col}>
            <Input style={styles.input} name="Completed" info="Score for completing a campaign (made it to the completed column in the campaign report)." keyboardType="numeric" value={completed} setValue={setCompleted} />
          </View>
        </View>
      )
    }
    return <View key="engagement-metrics" style={{ flex: 1, padding: 15 }}><Spinner /></View>;
  }

  return (
    <View>
      <WpModal key="modal-details" isVisible={isVisible}>
        <ModalBody title={"EngagementMetrics"} closeModal={handleOnClose}>
          { renderEngagementMetrics() }
          <ModalFooter style={{ position: 'relative', justifyContent: 'space-between', zIndex: 1 }}>
            <Button style={{ flex: 1 }} type="Primary" size="Default" shape="Pill" onClick={saveEngagementMetrics}>
              <Text style={{ color: '#FFF', fontWeight: 'bold', fontFamily: 'GothamMedium' }}>Save</Text>
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    marginVertical: 25
  },
  btnLink: {
    paddingTop: 5,
    color: Constants.colors.blue,
    textDecorationLine: "underline",
    fontFamily: "Gotham",
    cursor: "pointer"
  },
  error: {
    width: '100%',
    textAlign: 'center',
    color: Constants.colors.red,
    fontFamily: 'GothamMedium'
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1
  },
  card: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 15,
    backgroundColor: Constants.colors.lightBlue,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 15
  },
  cardRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 10,
    paddingHorizontal: 10,
    marginBottom: 10
  },
  cardIcon: {
    borderRadius: 20,
    width: 40,
    height: 40,
    backgroundColor: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 15
  },
  cardBody: {
    fontFamily: 'GothamMedium',
    fontSize: 14,
    color: Constants.colors.dark
  },
  cardBodyWrapper: {
  },
  cardSubtext: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.info
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
    marginVertical: 15
  },
  row: {
    flexDirection: 'row',
    gap: 15,
    justifyContent: 'space-between',
    padding: 8,
    width: '100%',
    flexWrap: 'wrap'
  },
  col: {
    gap: 10,
    width: '47%'
  },
  input: {
  }
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
