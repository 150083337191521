import { View, StyleSheet, Text, Image } from 'react-native';
import React, { useState } from 'react';
import Constants from './../../constants';
import {loadStripe} from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Theme } from './../Button';
import Spinner from '../Spinner';


const stripePromise = loadStripe(Constants.stripePk);

export function CardForm({ themeColor, fineText, onComplete, stripeLoading, setStripeLoading } : { themeColor?: string, fineText?: string, onComplete: (error, payentMethod) => void, stripeLoading?: boolean, setStripeLoading?: (val: boolean) => void }): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (elements === null || !stripe) {
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    handleOnComplete(error, paymentMethod)
  };

  const onSubmit = () => {
    if (setStripeLoading) {
      setStripeLoading(true)
      handleSubmit()
    }
  }

  const handleOnComplete = (error, paymentMethod) => {
    if (setStripeLoading) {
      setStripeLoading(false);
    }
    onComplete(error, paymentMethod)
  }

  return (
    <form style={{ justifyContent: 'center', alignItems: 'center' }} onSubmit={handleSubmit}>
      <View style={{ height: 50, paddingTop: 15, paddingHorizontal: 10, borderWidth: 2, borderColor: '#e8edf5', backgroundColor: 'white', borderRadius: 10 }}>
        <CardElement
          options={{ style: {
            base: {
              fontFamily: 'GothamMedium, Open Sans, Segoe UI, sans-serif',
            },
          }}}
        />
      </View>
      <Text style={{ fontFamily: 'Gotham', color: Constants.colors.secondary, fontSize: 10 }}>{fineText}</Text>
      <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
        <Button style={{ width: '100%', marginTop: 20 }} loading={stripeLoading} shadow={false} buttonStyles={{ backgroundColor: themeColor ? themeColor : Constants.colors.darkBlue }} text="Confirm" width={'100%'} theme={Theme.PRIMARY} onPress={onSubmit} />
      </View>
    </form>
  );
}

export default function StripeCardElement({ themeColor, fineText, onComplete, stripeLoading, setStripeLoading } : { themeColor?: string, fineText?: string, onComplete: (error, payentMethod) => void, stripeLoading?: boolean, setStripeLoading?: (val: boolean) => void }): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <CardForm themeColor={themeColor} fineText={fineText} onComplete={onComplete} stripeLoading={stripeLoading} setStripeLoading={setStripeLoading} />
    </Elements>
  );
}

const styles = StyleSheet.create({
  thumbnail: {
    borderRadius: 10,
    backgroundColor: '#FFF',
    fontWeight: 'bold',
    position: 'relative',
    width: 285
  },
  imageWrapper: {
    height: 145,
    width: '100%',
    backgroundColor: Constants.colors.secondaryLight,
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    padding: 30,
    fontSize: 16,
    fontWeight: 'bold',
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium'
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
  }
});
