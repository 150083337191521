import { View, Text, Image, StyleSheet, Pressable } from 'react-native';
import React, { useState, useEffect } from 'react';

import Constants from './../../constants';
import { WpRedirectType, WpSlideStructure, WpSlideWidget, WpWidgetType } from './../../contexts/pitch';
import { LeadContext, WpLeadWidget, WpLeadAction } from './../../contexts/lead';
import Input from './../Input';
import Checkbox from './../Checkbox';
import Radio from './../Radio';
// import { Input } from '@whistlepitch/wp-components';
import { Ionicons } from '@expo/vector-icons';
import { Slider } from '@miblanchard/react-native-slider';
import Dropdown from '../Dropdown';


export default function SlideWidgets({ color, widgetStructure, widgets, leadWidgets }: { color?: string, widgetStructure: WpSlideStructure[], widgets: WpSlideWidget[], leadWidgets: WpLeadWidget[] }): JSX.Element {
  const [themeColor, setThemeColor] = useState<string>()

  useEffect(() => {
    setThemeColor(color ? color : Constants.colors.blue)
  }, [color])

  if (!widgetStructure || widgetStructure.length === 0) {
    return <View></View>;
  }

  return (
    <View style={styles.widgets}>
      {widgetStructure.map((widgetObj, index) => {
        return <SlideWidget key={`widget-${widgetObj.id}`} index={index} color={themeColor} widgetStructure={widgetObj} widgets={widgets} leadWidgets={leadWidgets} />
      })
      }
    </View>
  );
}


export function SlideWidget({ color = Constants.colors.blue, widgetStructure, index, widgets = [], leadWidgets }: { color?: string, index?: number, widgetStructure: WpSlideStructure, widgets?: WpSlideWidget[], leadWidgets: WpLeadWidget[] }): JSX.Element {
  const { state, dispatch } = React.useContext(LeadContext);

  const [widget, setWidget] = useState<WpSlideWidget>()
  const [leadWidget, setLeadWidget] = useState<WpLeadWidget>()
  const [zIndex, setZIndex] = useState<number | undefined>()

  useEffect(() => {
    if (index && [WpWidgetType.DATE, WpWidgetType.DROPDOWN].indexOf(widget?.widgetType) > -1) {
      let plus = 1;
      if ([WpWidgetType.DATE].indexOf(widget?.widgetType) > -1) {
        plus = 10;
      }
      setZIndex(index + plus)
    } else {
      setZIndex()
    }
  }, [index, widget?.widgetType])

  useEffect(() => {
    if (widgetStructure) {
      let windex = widgets.findIndex((w) => w.id === widgetStructure.id)
      let lindex = leadWidgets.findIndex((l) => l.widgetId === widgetStructure.id)
      let w = widgets[windex];
      console.log('widget', w, windex, widgets, widgetStructure)

      setWidget(windex > -1 ? w :
        {
          id: widgetStructure.id,
          label: widgetStructure.label,
          fineText: widgetStructure.label,
          widgetLabel: widgetStructure.label,
          widgetType: widgetStructure.type,
          labels: widgetStructure.labels,
          start: 0,
          end: 0
        }
      )

      if (lindex > -1) {
        setLeadWidget(leadWidgets[lindex])
      } else if (w) {
        setLeadWidget({
          widgetId: w.id,
          values: [],
          label: w.label
        })
      }
    }
  }, [widgetStructure?.id])

  useEffect(() => {
    if (leadWidget) {
      dispatch({ type: WpLeadAction.UPDATE_LEAD_WIDGET, leadWidget: leadWidget })
    }
  }, [leadWidget])

  const setValues = (val: string) => {
    updateWidget([val])
  }

  const setRating = (val: number | number[]) => {
    if (Array.isArray(val)) {
      updateWidget(val.map(value => value.toString()))
    } else {
      updateWidget([val.toString()])
    }
  }

  const toggleVerify = (val: boolean) => {
    if (val) {
      updateWidget(["Verified"])
    } else {
      updateWidget([])
    }
  }

  const toggleValue = (val: string) => {
    let vindex = leadWidget?.values.indexOf(val);

    if (leadWidget?.values && vindex !== undefined && vindex > -1) {
      updateWidget([
        ...leadWidget.values.slice(0, vindex),
        ...leadWidget.values.slice(vindex + 1)
      ])
    } else if (leadWidget?.values) {
      updateWidget([
        ...leadWidget.values,
        val
      ])
    } else {
      updateWidget([val])
    }
  }

  const updateWidget = (values: string[]) => {
    if (widget) {
      setLeadWidget({
        widgetId: widget.id,
        values: values,
        label: widget.label
      })
    }
  }

  const renderOptions = () => {
    let val = leadWidget?.values.length ? leadWidget.values[0] : undefined;

    switch (widget?.widgetType) {
      case (WpWidgetType.YESNO):
        let isYes = leadWidget?.values.length && leadWidget?.values[0] === 'Yes';
        let isNo = leadWidget?.values.length && leadWidget?.values[0] === 'No';

        return (
          <View style={{ marginVertical: 10, position: 'relative', zIndex: index !== undefined ? index + 1 : undefined }}>
            <Radio key={`widget-${widget.id}-yes`} color={color} active={!!isYes} label="Yes" onPress={setValues} />
            <Radio key={`widget-${widget.id}-no`} color={color} active={!!isNo} label="No" onPress={setValues} />
          </View>
        )
      case (WpWidgetType.DATE):
        return (
          <View style={{ marginVertical: 5, position: 'relative', zIndex: index !== undefined ? index + 10 : undefined }}>
            <Input style={{ width: '100%', position: 'relative', zIndex: index !== undefined ? index + 10 : undefined }} isDate={true} value={val} setValue={setValues} placeholder="Enter Appointment Date..." />
          </View>
        )
      case (WpWidgetType.SHORT):
        return (
          <View style={{ marginVertical: 5, position: 'relative', zIndex: index !== undefined ? index + 1 : undefined }}>
            <Input style={{ width: '100%' }} value={val} setValue={setValues} placeholder="Enter Answer Here..." />
          </View>
        )
      case (WpWidgetType.CHOICE):
        return (
          <View style={{ marginVertical: 10, position: 'relative', zIndex: index !== undefined ? index + 1 : undefined }}>
            {
              widget?.labels?.length ? widget.labels.map((label, index) => {
                return <Radio key={`widget-${widget.id}-choice-${index}`} color={color} active={leadWidget?.values.includes(label)} label={label} onPress={setValues} />
              }) : null
            }
          </View>
        )
      case (WpWidgetType.CHECKBOX):
        console.log("Checkbox widget", widget)
        return (
          <View style={{ marginVertical: 10, position: 'relative', zIndex: index !== undefined ? index + 1 : undefined }}>
            {
              widget?.labels?.length ? widget.labels.map((label, index) => {
                return <Radio key={`widget-${widget.id}-choice-${index}`} color={color} active={leadWidget?.values.includes(label)} label={label} onPress={toggleValue} multiple={true} />
              }) : null
            }
          </View>
        )
      case (WpWidgetType.DROPDOWN):
        let options = widget.labels.map((label) => {
          return { label: label, value: label }
        })

        return (
          <View style={{ marginVertical: 10, position: 'relative', zIndex: index !== undefined ? index + 1 : undefined }}>
            <Dropdown placeholder={`Select a body part`} items={options} value={val} onChange={setValues} />
          </View>
        )
      case (WpWidgetType.RATING):
        let start = widget.start ? widget.start : 1;
        let end = widget.end ? widget.end : 10;
        if (start > end) {
          let temp = start;
          start = end;
          end = temp;
        }
        return (
          <View style={{ marginVertical: 10, position: 'relative', marginLeft: 10, zIndex: index !== undefined ? index + 1 : undefined }}>
            <Text>{val ? val : start}</Text>
            <Slider
              step={1}
              value={val ? parseInt(val) : start}
              onValueChange={setRating}
              minimumValue={start}
              maximumValue={end}
              minimumTrackTintColor={`${color}55`}
              thumbTintColor={color}
            />
          </View>
        )
      case (WpWidgetType.VERIFY):
        return (
          <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center', zIndex: index !== undefined ? index + 1 : undefined }}>
            <Checkbox onSelect={toggleVerify} colorActive={color} selected={val === 'Verified'} size={24} />
            <Text style={styles.text}>{widget.label}</Text>
          </View>
        )
      default:
        return null
    }
  }

  if (!widget) {
    return (<View></View>)
  }

  // RATING
  // VERIFY

  return (
    <View style={[styles.widget, { zIndex: zIndex ? zIndex : undefined }]}>
      {widget.label && widget.widgetType !== WpWidgetType.VERIFY ? <Text style={styles.text}>{widget.label}</Text> : null}
      {renderOptions()}
    </View>
  );
}

const styles = StyleSheet.create({
  widgets: {
    justifyContent: 'flex-start',
    gap: 15,
    alignItems: 'flex-start',
    backgroundColor: '#FFF',
    borderRadius: 10,
    padding: 25,
    position: 'relative',
    zIndex: 1
  },
  widget: {
    width: '100%',
    position: 'relative',
  },
  brand: {
    height: 30
  },
  text: {
    fontFamily: 'Gotham'
  }
})
