import { Text, View, TextInput, StyleSheet, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import { PitchContext, WpPitchStructure, WpPitchAction, WpSlideType, WpWidgetType } from './../contexts/pitch';
import { DELETE_PITCH_SLIDE } from './../graphql/pitch';
import Constants from './../constants';
import PitchSlideCreateButton from './PitchSlideCreateButton';
import { TrashIcon } from '@heroicons/react/outline';
import { useMutation } from "@apollo/client";

import { CloudUploadIcon, CalendarIcon, MicrophoneIcon, DesktopComputerIcon } from '@heroicons/react/outline';
import { AdjustmentsIcon, AnnotationIcon, ThumbUpIcon, ShieldCheckIcon, ScaleIcon, ViewListIcon, CheckCircleIcon } from '@heroicons/react/solid';
import { Feather } from '@expo/vector-icons';

const enum HOVERABLES {
  TRASH = "TRASH",
  BODY = "BODY"
}


export default function PitchSlideThumbnail({ slide, isLast, onPress } : { slide:WpPitchStructure, isLast:boolean, onPress:Function }): JSX.Element {
  const { state, dispatch } = React.useContext(PitchContext);
  const [hovering, setHovering] = useState<HOVERABLES|null>(null);
  const [image, setImage] = useState();
  const [deleteSlide, { data, loading, error }] = useMutation(DELETE_PITCH_SLIDE)

  useEffect(() => {
    if (data?.deletePitchSlide?.structure) {
      dispatch({ type: WpPitchAction.DELETE_PITCH_SLIDE, slide: slide, structure: data.deletePitchSlide.structure })
    }
  }, [data])

  useEffect(() => {
    setImage(getImage())
  }, [slide.thumbnail, slide.slideType])

  const onHover = (what: HOVERABLES) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(null);
  }

  const onDeleteSlide = () => {
    deleteSlide({ variables: { pitchSlideId: slide.id }})
  }

  const handleClick = (what: HOVERABLES) => {
    if (what === HOVERABLES.TRASH && hovering === HOVERABLES.TRASH) {
      onDeleteSlide()
    } else if (hovering !== HOVERABLES.TRASH) {
      onPress(slide.id)
    }
  }

  const getImage = () => {
    if (slide.thumbnail) {
      return <Image style={styles.image} source={{ uri: slide.thumbnail }} />
    } else if (slide.slideType === WpSlideType.YOUTUBE) {
      return (<Feather style={[cssStyles.iconImage, { color: getColor() }]} size={48} name="youtube" />)
    } else if (slide.slideType === WpSlideType.CALENDAR) {
      return (<CalendarIcon style={{...cssStyles.iconImage, color: getColor()}} />)
    } else if (slide.slideType === WpSlideType.RECORD) {
      return (<MicrophoneIcon style={{...cssStyles.iconImage, color: getColor()}} />)
    } else if (slide.slideType === WpSlideType.SCREEN) {
      return (<DesktopComputerIcon style={{...cssStyles.iconImage, color: getColor()}} />)
    } else if (slide.slideType === WpSlideType.UPLOAD) {
      return (<CloudUploadIcon style={{...cssStyles.iconImage, color: getColor()}} />)
    }
  }

  const getColor = (light:boolean=false) => {
    return {
      YOUTUBE: light ? Constants.colors.dangerLight : Constants.colors.danger,
      UPLOAD: light ? Constants.colors.primaryLight : Constants.colors.primary,
      SCREEN: light ? Constants.colors.successLight : Constants.colors.success,
      RECORD: light ? Constants.colors.warningLight : Constants.colors.warning,
      CALENDAR: light ? Constants.colors.accentLight : Constants.colors.accent,
    }[slide.slideType]
  }

  const getIconColor = (type:WpWidgetType) => {
    return {
      SCALE: Constants.colors.danger,
      RATING: Constants.colors.dangerMedium,
      YESNO: Constants.colors.success,
      SHORT: Constants.colors.warning,
      CHOICE: Constants.colors.primary,
      CHECKBOX: Constants.colors.primary,
      VERIFY: Constants.colors.accent,
    }[type]
  }

  const renderWidgetIcon = (type:string, index:number) => {
    if (type === WpWidgetType.SCALE) {
      return <ScaleIcon key={`widget-scale-${index}`} style={{...cssStyles.widgetIcon, backgroundColor: getIconColor(type)}} />
    } else if (type === WpWidgetType.RATING) {
      return <AdjustmentsIcon key={`widget-rating-${index}`} style={{...cssStyles.widgetIcon, backgroundColor: getIconColor(type)}} />
    } else if (type === WpWidgetType.YESNO) {
      return <ThumbUpIcon key={`widget-yesno-${index}`} style={{...cssStyles.widgetIcon, backgroundColor: getIconColor(type)}} />
    } else if (type === WpWidgetType.SHORT) {
      return <AnnotationIcon key={`widget-short-${index}`} style={{...cssStyles.widgetIcon, backgroundColor: getIconColor(type)}} />
    } else if (type === WpWidgetType.CHOICE || type === WpWidgetType.CHECKBOX) {
      return <CheckCircleIcon key={`widget-choice-${index}`} style={{...cssStyles.widgetIcon, backgroundColor: getIconColor(type)}} />
    } else if (type === WpWidgetType.VERIFY) {
      return <ShieldCheckIcon key={`widget-verify-${index}`} style={{...cssStyles.widgetIcon, backgroundColor: getIconColor(type)}} />
    }
  }

  return (
    <View style={{ position: 'relative', alignItems: 'center' }}>
      <View style={[styles.container, { opacity: hovering === HOVERABLES.BODY ? 0.6 : 1 }]} onClick={() => handleClick(HOVERABLES.BODY)} onMouseEnter={() => onHover(HOVERABLES.BODY)} onMouseLeave={onHoverOut}>
        <View style={styles.iconWrapper}>
          { slide.structure.map((widget, index, list) => {
            return renderWidgetIcon(widget.type, index)
          })}
        </View>
        <TrashIcon style={{...cssStyles.icon, color: hovering === HOVERABLES.TRASH ? Constants.colors.danger : Constants.colors.info}} onClick={() => handleClick(HOVERABLES.TRASH)} onMouseEnter={() => onHover(HOVERABLES.TRASH)} onMouseLeave={onHoverOut} />
        <View style={[styles.imageWrapper, { backgroundColor: slide.thumbnail ? '#FFF' : getColor(true) }]}>
          { image }
        </View>
        <Text style={styles.text}>{slide.label}</Text>
      </View>
      <PitchSlideCreateButton after={slide.id} isLast={isLast} onPress={onPress} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    cursor: 'pointer',
    borderRadius: 10,
    border: '1px solid ' + Constants.colors.secondary + '33',
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.57,
    shadowRadius: 15.19,
    shadowColor: Constants.colors.secondaryLight,
    backgroundColor: '#FFF',
    elevation: 23,
    width: 200
  },
  iconWrapper: {
    top: 12,
    left: -35,
    position: 'absolute'
  },
  imageWrapper: {
    height: 100,
    width: '100%',
    backgroundColor: Constants.colors.secondaryLight,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomWidth: 1,
    borderBottomColor: Constants.colors.info + '33',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  }
});

const cssStyles = {
  icon: {
    position: 'absolute',
    right: -30,
    top: 12,
    height: 15,
    width: 15,
    cursor: 'pointer'
  },
  iconImage: {
    paddingBottom: 5,
    height: 50,
    color: '#FFF'
  },
  widgetIcon: {
    width: 22,
    height: 22,
    padding: 5,
    marginBottom: 5,
    borderRadius: 12,
    textAlign: 'center',
    color: '#FFF'
  }
}
