import { Text, View, StyleSheet } from 'react-native';
import React from 'react';
import Constants from './../constants';


export default function ModalFooter({ children, style={} } : { children: React.ReactNode, style?: object }): JSX.Element {
  return (
    <View style={[styles.body, style]}>
      { children }
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: '#FFF',
    marginTop: 20,
    paddingTop: 20,
    paddingBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  }
});

const cssStyles = {
  icon : {
    width: 20,
    height: 20,
    cursor: 'pointer',
    color: Constants.colors.secondary
  }
}
