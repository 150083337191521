import { View, Text, ActivityIndicator, StyleSheet, Dimensions, LayoutChangeEvent } from 'react-native';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate } from '../../react-router';

import { formatDateString } from './../../tools/tools';
import Constants from '../../constants';
import Spinner from './../Spinner';
import { Button as LocalButton, Theme } from './../Button';
import Input, { Label } from './../Input';
import UserDropdown from './../UserDropdown';
import MessageFeed from './../Shared/MessageFeed';
import { GET_LEAD } from '../../graphql/queries';
import { WpLead, WpLeadActivity, WpLeadEvent, WpLeadMessage, WpLeadViewRange } from '../../contexts/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import FullscreenModal from './../Shared/FullscreenModal';
import Heading from './../Shared/Heading';
import { FontAwesome5, Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import { Dropdown, Input as WpInput, Button, Chart } from '@whistlepitch/wp-components';
import { BarChart, XAxis, YAxis, Bar, Line, Legend, Tooltip, CartesianGrid } from "recharts";
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import { CREATE_LEAD_APPOINTMENT, DELETE_LEAD_APPOINTMENT, MESSAGE_LEAD, UPDATE_LEAD, UPDATE_LEAD_APPOINTMENT } from '../../graphql/lead';
import { UserContext, WpCampaign } from '../../contexts/user';
import Hoverable from '../Shared/Hoverable';
import CampaignDropdown from '../CampaignDropdown';
import Checkbox from './../Checkbox';

interface SummaryData {
  label: string;
  value: number;
  tooltip: string;
}

interface WidgetData {
  widgetId: string,
  label: string,
  values: string[]
}

interface TimeData {
  slideName: string,
  videoDuration: number,
  viewRanges: WpLeadViewRange[]
}

interface ReportData {
  data: SummaryData[],
  widgets: WidgetData[],
  viewRanges: TimeData[]
}

interface Option {
  label: string,
  selected?: boolean,
  value: string
}

interface Data {
  slideId: string
  slideTitle: string
  [activityId: string]: number | string
}

interface PitchData {
  [k: string]: {
    activities: string[],
    data: Data[],
    numSlides: number,
    widgets: WidgetData[]
  }
}

const barColors = [Constants.colors.primaryMedium, Constants.colors.accentMedium, Constants.colors.dangerMedium, Constants.colors.primary, Constants.colors.accent, Constants.colors.danger]
// Define your color array outside of your component
const colorArray = [
  Constants.colors.blue,
  Constants.colors.red,
  Constants.colors.green,
  Constants.colors.purple,
  Constants.colors.yellow,
  Constants.colors.pink,
];

export default function LeadDetailsModal({ isVisible, onClose, leadId, masked = false }: { isVisible: boolean, onClose: () => void, leadId: string, masked?: boolean }): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [viewWidth, setViewWidth] = useState<number>(0);
  const [lead, setLead] = useState<WpLead>()
  const [sendingMessage, setSendingMessage] = useState<boolean>()
  const [newMessage, setNewMessage] = useState<string>()
  const [viewing, setViewing] = useState<"appointments" | "reporting" | "details" | "messages">(Constants.blastView ? "messages" : (Constants.oppText || Constants.tattooCare) ? "appointments" : "reporting")
  const [leadNewName, setLeadNewName] = useState<string>()
  const [leadNewPhone, setLeadNewPhone] = useState<string>()
  const [graphData, setGraphData] = useState<{ [pitchId: string]: ReportData }>()
  const [timestamps, setTimestamps] = useState<{ [activityId: string]: string }>({})
  const [currentPitchTitle, setCurrentPitchTitle] = useState<string>()
  const [currentPitchId, setCurrentPitchId] = useState<string>()
  const [editingAppointment, setEditingAppointment] = useState<WpLeadEvent>()
  const [newAppointmentDate, setNewAppointmentDate] = useState<string>()
  const [skipDate, setSkipDate] = useState<boolean>(false)
  const [pitchDropdownOptions, setPitchDropdownOptions] = useState<Option[]>([])
  const [selectingCampaign, setSelectingCampaign] = useState<boolean>(false)
  const [campaignId, setCampaignId] = useState<string>()
  const [queuedMessage, setQueuedMessage] = useState<WpLeadMessage>()

  const navigate = useNavigate();

  const [getLead, { data, loading }] = useLazyQuery(GET_LEAD, {
    fetchPolicy: "no-cache",
  });

  const [messageLead, messageLeadData] = useMutation(MESSAGE_LEAD, {
    fetchPolicy: "no-cache",
  });

  const [updateLead, updateLeadData] = useMutation(UPDATE_LEAD, {
    fetchPolicy: "no-cache",
  });

  const [createLeadAppointment, createLeadAppointmentData] = useMutation(CREATE_LEAD_APPOINTMENT, {
    fetchPolicy: "no-cache",
  });

  const [updateLeadAppointment, updateLeadAppointmentData] = useMutation(UPDATE_LEAD_APPOINTMENT, {
    fetchPolicy: "no-cache",
  });

  const [deleteLeadAppointment, deleteLeadAppointmentData] = useMutation(DELETE_LEAD_APPOINTMENT, {
    fetchPolicy: "no-cache",
  });

  function mergeRanges(arr1: WpLeadViewRange[], arr2: WpLeadViewRange[]): WpLeadViewRange[] {
    const allRanges = arr1.concat(arr2);
    allRanges.sort((a, b) => a.start - b.start);

    const mergedRanges: WpLeadViewRange[] = [];
    let currentRange = allRanges[0];

    for (let i = 1; i < allRanges.length; i++) {
      const range = allRanges[i];
      if (range.start <= currentRange.end) {
        currentRange.end = Math.max(currentRange.end, range.end);
      } else {
        mergedRanges.push(currentRange);
        currentRange = range;
      }
    }

    mergedRanges.push(currentRange);

    return mergedRanges;
  }


  useEffect(() => {
    if (data?.lead?.lead) {
      setLead(data.lead.lead)
      setLeadNewName(data.lead.lead.name)
      setLeadNewPhone(data.lead.lead.phone)
      if (queuedMessage) {
        if (data.lead.lead.messages.length && data.lead.lead.messages[data.lead.lead.messages.length - 1].message === queuedMessage.message) {
          setQueuedMessage(undefined)
        }
      }

      let campaigns: { [id: string]: string } = {}
      for (let i = 0; i < data.lead.lead.campaigns.length; i++) {
        let c = data.lead.lead.campaigns[i].campaign;
        campaigns[c.id] = c.name
      }

      let newData: { [campaignId: string]: ReportData } = {}

      let widgetData: { [pitchId: string]: WidgetData[] } = {}
      for (let i = 0; i < data.lead.lead.widgets.length; i++) {
        let w = data.lead.lead.widgets[i];
        if (!widgetData[w.pitchId]) {
          widgetData[w.pitchId] = []
        }

        widgetData[w.pitchId].push({
          label: w.label,
          values: w.values,
          widgetId: w.widgetId
        })
      }

      let ids = [];
      let campaignIds = [];
      let options: Option[] = [];
      let slides: { [activityId: string]: { label: string, value: number, video: number, tooltip: string, pitchId: string, campaignId: string, videoDuration: number, viewRanges?: WpLeadViewRange[] } } = {};
      for (let i = 0; i < data.lead.lead.activity.length; i++) {
        let pitch = data.lead.lead.activity[i]?.pitch;
        let cid = data.lead.lead.activity[i].campaignId;
        if (pitch && cid) {
          if (widgetData[pitch.id]) {
            if (!newData[cid]) {
              newData[cid] = {
                widgets: [],
                data: [],
                viewRanges: []
              }
            }

            newData[cid].widgets = [...newData[cid].widgets, ...widgetData[pitch.id]];
          }
          if (campaignIds.indexOf(cid) === -1) {
            campaignIds.push(cid);

            let title = pitch.title;
            if (campaigns[cid]) {
              title = campaigns[cid]
            }
            options.push({
              label: title,
              value: cid
            })
          }

          for (let i = 0; i < pitch.structure.length; i++) {
            let slide = pitch.structure[i];
            if (slide.id && slide.id !== 'redirect' && ids.indexOf(slide.id) === -1) {
              let slide = pitch.structure[i];
              ids.push(slide.id);
              if (slides[slide.id] === undefined) {
                slides[slide.id] = {
                  label: slide.label,
                  value: 0,
                  video: 0,
                  videoDuration: 0,
                  tooltip: '',
                  pitchId: pitch.id,
                  campaignId: cid
                }
              }
            }
          }
        }

        let times = data.lead.lead.activity[i]?.times ?? [];
        for (let j = 0; j < times.length; j++) {
          if (times[j].seconds > 0) {
            if (times[j].pitchSlideId && slides[times[j].pitchSlideId] !== undefined) {
              slides[times[j].pitchSlideId].value += times[j].seconds;
              slides[times[j].pitchSlideId].video += times[j].video;

              if (times[j].viewRanges && times[j].viewRanges.length > 0) {
                slides[times[j].pitchSlideId].videoDuration = times[j].videoDuration;
                slides[times[j].pitchSlideId].viewRanges = mergeRanges(
                  slides[times[j].pitchSlideId]?.viewRanges ?? [],
                  times[j].viewRanges
                );
              }
            }
          }
        }
      }

      for (let i = 0; i < ids.length; i++) {
        if (slides[ids[i]]) {
          let value = parseInt(slides[ids[i]].value.toFixed(0));
          let video = parseInt(slides[ids[i]].video.toFixed(0));
          let viewRanges = slides[ids[i]].viewRanges;
          const totalSecondsViewed = viewRanges?.reduce((acc, range) => acc + (range.end - range.start), 0) ?? 0;
          if (totalSecondsViewed > video) {
            video = totalSecondsViewed;
          }
          if (video > value) {
            value = video;
          }

          let label = slides[ids[i]].label;
          let tooltip = `Time: ${formatTime(value)}, Video Watched: ${formatTime(video)}`

          if (!newData[slides[ids[i]].campaignId]) {
            newData[slides[ids[i]].campaignId] = {
              widgets: [],
              data: [],
              viewRanges: []
            }
          }

          if (newData[slides[ids[i]].campaignId]) {
            newData[slides[ids[i]].campaignId].data.push({
              label: label.substr(0, 12) + (label.length > 12 ? '...' : ''),
              value: value,
              tooltip: tooltip,
            })

            if (viewRanges && viewRanges.length > 0) {
              newData[slides[ids[i]].campaignId].viewRanges.push({
                slideName: label,
                videoDuration: slides[ids[i]].videoDuration,
                viewRanges: viewRanges
              })
            }
          }
        }
      }

      setGraphData(newData)
      setPitchDropdownOptions(options)
      if (options.length) {
        setCurrentPitchTitle(options[0].label)
        setCurrentPitchId(options[0].value)
      }
      setSendingMessage(false)
      // console.log("Generated:", newData, "newData", options, "options")
    }
  }, [data])

  useEffect(() => {
    if (updateLeadAppointmentData?.data?.updateLeadAppointment) {
      loadLead()
      setSkipDate(false)
      setEditingAppointment(undefined)
    }
  }, [updateLeadAppointmentData.data])

  useEffect(() => {
    if (createLeadAppointmentData?.data?.createLeadAppointment) {
      loadLead()
      setSkipDate(false)
      setEditingAppointment(undefined)
    }
  }, [createLeadAppointmentData.data])

  useEffect(() => {
    if (deleteLeadAppointmentData?.data?.deleteLeadAppointment) {
      loadLead()
      setSkipDate(false)
      setEditingAppointment(undefined)
    }
  }, [deleteLeadAppointmentData.data])

  useEffect(() => {
    if ((isVisible && !lead) || messageLeadData.data) {
      if (messageLeadData.data?.messageLead?.message) {
        setQueuedMessage(messageLeadData.data.messageLead.message)
      }

      loadLead()
    }
  }, [isVisible, messageLeadData.data])

  useEffect(() => {
    if (updateLeadData.data) {
      onClose();
    }
  }, [updateLeadData.data])

  const loadLead = () => {
    getLead({ variables: { leadId: leadId } })
  }

  const changeMessage = (e: any) => {
    setNewMessage(e.target.value)
  }

  const onSendMessage = () => {
    if (lead) {
      setSendingMessage(true);
      messageLead({
        variables: {
          leadId: lead.id,
          message: newMessage
        }
      })
      setNewMessage(undefined)
    }
  }

  const onUpdateLead = () => {
    if (lead) {
      updateLead({
        variables: {
          leadId: lead.id,
          lead: {
            name: leadNewName,
            phone: leadNewPhone,
          }
        }
      })
    }
  }

  const onUpdateLeadAppointment = () => {
    if (editingAppointment) {
      if (editingAppointment.id) {
        updateLeadAppointment({
          variables: {
            leadAppointmentId: editingAppointment.id,
            leadAppointment: {
              eventDate: skipDate ? undefined : newAppointmentDate
            }
          }
        })
      } else if (lead) {
        createLeadAppointment({
          variables: {
            leadId: lead.id,
            leadAppointment: {
              eventDate: skipDate ? undefined : newAppointmentDate
            }
          }
        })
      }
    }
    setSkipDate(false);
  }

  const onDeleteLeadAppointment = () => {
    if (editingAppointment) {
      deleteLeadAppointment({
        variables: {
          leadAppointmentId: editingAppointment.id,
        }
      })
    }
  }

  // const renderLead = () => {
  //   if (lead) {
  //     let links = []
  //     for (let i = 0; i < lead.campaigns.length; i++) {
  //       for (let j = 0; j < lead.campaigns[i].codes.length; j++) {
  //         let cl = lead.campaigns[i].codes[j]
  //         links.push(<Button key={`btn-open-pitch-${i}`} width={250} text={`${cl.campaignName} | ${cl.pitchName}`} onPress={() => navigate(cl.path)} />)
  //       }
  //     }
  //     return links
  //   } else {
  //     return <Spinner />
  //   }
  // }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const formatTime = (duration: number) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const handleSelectPitchGraph = (id: string) => {
    let pindex = pitchDropdownOptions.findIndex((o: Option) => o.value === id)
    let pitch = pindex === -1 ? undefined : pitchDropdownOptions[pindex];

    setCurrentPitchId(pitch?.value)
    setCurrentPitchTitle(pitch?.label)
  }

  const editAppointment = (appointment: WpLeadEvent) => {
    let dateParts = appointment.eventDate?.split('-') ?? [];
    let adjustedDate = null;
    if (dateParts.length === 3) {
      adjustedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
    }

    setNewAppointmentDate(adjustedDate ? adjustedDate : appointment.eventDate)
    setSkipDate(!appointment.eventDate && !!appointment.id)
    setEditingAppointment(appointment)
  }

  const onAddCampaign = (appt?: WpLeadEvent) => {
    setSelectingCampaign(true);
    setEditingAppointment(appt);
  }

  const confirmAddCampaign = () => {
    if (editingAppointment) {
      updateLeadAppointment({
        variables: {
          leadAppointmentId: editingAppointment.id,
          leadAppointment: {
            campaignsToAdd: [campaignId]
          }
        }
      })
      setCampaignId(undefined);
      setSelectingCampaign(false);
      setEditingAppointment(undefined);
    }
  }

  const removeCampaign = (leadAppointmentId: string, removeId: string) => {
    if (leadAppointmentId && removeId) {
      updateLeadAppointment({
        variables: {
          leadAppointmentId: leadAppointmentId,
          leadAppointment: {
            campaignsToRemove: [removeId]
          }
        }
      })
      // setTimeout(() => {
      //   loadLead();
      // }, 250)
    }
  }

  const renderAppointments = () => {
    if (viewing === 'appointments' && !loading) {
      let component: JSX.Element | JSX.Element[] | null = <Spinner />
      let appointments = lead?.appointments ?? []

      if (appointments.length) {
        component = appointments.map((a: WpLeadEvent, i: number) => {
          let eventDate = null;

          if (a.eventDate) {
            let dateParts = a.eventDate.split('-');
            if (dateParts.length === 3) {
              eventDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            }
          } else if (a.eventTime) {
            let eventTime = new Date(a.eventTime + 'Z'); // Parse the UTC time
            eventDate = eventTime.toLocaleDateString(); // Convert to local date string
          }
          return (
            <View key={`appointment-${i}`} style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: 10, borderRadius: 10, backgroundColor: Constants.colors.lightBlue, marginBottom: 10 }}>
              <View style={{ flex: 1, marginBottom: 5, paddingVertical: 5 }}>
                {eventDate ? <Text style={{ color: Constants.colors.darkBlue, fontSize: 16, fontWeight: 'bold', paddingVertical: 5 }}>Appointment for {eventDate}</Text> : null}

                <Label style={{ marginTop: eventDate ? 15 : 0, marginBottom: 7 }} name={Constants.localizations.Campaigns} />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 5 }}>
                  {a.campaigns.length ? a.campaigns.map((campaign, index) => {
                    return <LocalButton key={`campaign-button-${index}`} theme={Theme.DETAILS} style={{ marginBottom: 5, paddingRight: 20 }} width={'auto'} text={campaign.name} shadow={false} onDelete={() => removeCampaign(a.id, campaign.id)} />
                  }) : null
                  }
                </View>
                <Text onPress={() => onAddCampaign(a)} style={[styles.newBtn, { marginTop: 15 }]}>+ Add {Constants.localizations.campaign}</Text>
              </View>
              {a?.eventTime ? null :
                <Hoverable onPress={() => editAppointment(a)}>
                  <MaterialCommunityIcons name="lead-pencil" size={22} color={Constants.colors.blue} />
                </Hoverable>}
            </View>
          )
        })
      } else {
        component = loading || state.user?.organization.v2 ? null : <Text style={{ color: Constants.colors.info, width: '100%', textAlign: 'center' }}>No appointments found for this {Constants.localizations.lead}.</Text>
      }

      return (
        <View style={{ width: '100%' }}>
          <View style={{ position: 'relative', backgroundColor: '#FFF', borderRadius: 15 }}>
            <WpModal isVisible={!!editingAppointment && !selectingCampaign}>
              <ModalBody closeModal={setEditingAppointment} title={"Change Appointment Date"}>
                <View style={{ flex: 1, position: 'relative', zIndex: 10 }}>
                  <Input style={{ width: '100%', position: 'relative', zIndex: 10 }} isDate={true} value={newAppointmentDate} setValue={setNewAppointmentDate} placeholder="Enter Appointment Date..." disabled={skipDate} />

                  <View style={{ marginVertical: 25, position: 'relative', flexDirection: 'row', alignItems: 'center' }}>
                    <Checkbox onSelect={() => setSkipDate(!skipDate)} selected={skipDate} size={24} />
                    <Text style={{ fontFamily: 'Gotham', color: Constants.colors.secondary }}>No date (instant campaigns only)</Text>
                  </View>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25, position: 'relative', zIndex: 1 }}>
                  <View style={{ flex: 1 }}>
                    {editingAppointment?.id ? <LocalButton width={'auto'} text="Delete Appointment" theme={Theme.LINK_DANGER} textStyles={{ fontFamily: 'Gotham' }} onPress={onDeleteLeadAppointment} /> : null}
                  </View>
                  <Button type="Primary" size="Default" shape="Pill" onClick={onUpdateLeadAppointment}>
                    {updateLeadAppointmentData.loading ? <Spinner style={{ marginHorizontal: 15 }} color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Save</Text>}
                  </Button>
                  <View style={{ flex: 1 }}></View>
                </View>
              </ModalBody>
            </WpModal>
            <WpModal key="modal-add-campaign" isVisible={selectingCampaign}>
              <ModalBody title={"Add Campaign"} closeModal={() => { setSelectingCampaign(false); setEditingAppointment(undefined); }}>
                <View style={{ position: 'relative', zIndex: 10 }}>
                  <CampaignDropdown optional={false} label={Constants.localizations.Campaign} info={`Select a ${Constants.localizations.campaign} to assoaciate with this appointment date.`} instantOnly={!editingAppointment?.eventDate && !editingAppointment?.eventTime} value={campaignId} setValue={setCampaignId} />
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 25, position: 'relative', zIndex: 1 }}>
                  <LocalButton key="btn-campaign-add" text="Confirm" onPress={() => confirmAddCampaign()} />
                </View>
              </ModalBody>
            </WpModal>
            {component}
            {state.user?.organization.v2 && state.user?.organization?.id != '47' ? null :
              <View style={{ flex: 1 }}>
                <LocalButton width={'auto'} text="+ New" theme={Theme.LINK_PRIMARY} textStyles={{ fontFamily: 'GothamBold' }} onPress={() => editAppointment({})} />
              </View>}
          </View>
        </View>
      )
    }
    return null;
  }


  const renderGraphs = () => {
    if (viewing === 'reporting') {
      let component = null
      if (graphData && currentPitchId && graphData[currentPitchId]) {
        component = <View style={{ position: 'relative', paddingRight: 40, marginLeft: -20 }}><Chart key={`chart-${currentPitchId}`} data={graphData[currentPitchId].data} type="Time" /></View>
      } else if (graphData && (!currentPitchId || !graphData[currentPitchId])) {
        component = <Text style={{ color: Constants.colors.info, width: '100%', textAlign: 'center' }}>No data found for this {Constants.localizations.lead}.</Text>
      }

      // console.log('graphData[currentPitchId]?.viewRanges', currentPitchId && graphData?.[currentPitchId]?.viewRanges)

      return (
        <View style={{ width: '100%', position: 'relative' }}>
          <View style={{ position: 'relative', backgroundColor: '#FFF', borderRadius: 15 }}>
            {currentPitchTitle ?
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 20, zIndex: 10 }}>
                <Text style={{ fontSize: 18, color: Constants.colors.primary, fontWeight: 'bold' }}>{Constants.localizations.Lead} Data</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginVertical: 15, zIndex: 10 }}>
                  <Text style={{ color: Constants.colors.secondary, position: 'relative', top: -1, fontSize: 14 }}>Viewing Data For: </Text>
                  <Dropdown defaultLabel={currentPitchTitle} structure={pitchDropdownOptions} onChange={handleSelectPitchGraph} />
                </View>
              </View> : null
            }
            {component}

            {/* Display view ranges */}
            {currentPitchId && graphData && graphData[currentPitchId]?.viewRanges.length > 0 ?
              <View key={'video-watch-time'} style={{ paddingHorizontal: 20, marginBottom: 15, width: '100%', position: 'relative' }}>
                <Text style={{ fontSize: 18, color: Constants.colors.primary, fontWeight: 'bold' }}>Video Watch Times</Text></View> : null}
            {currentPitchId && graphData && graphData[currentPitchId]?.viewRanges ?
              graphData[currentPitchId].viewRanges.map((timeData, index) => (
                <View key={index} style={{ paddingHorizontal: 20, marginBottom: 10, width: '100%', position: 'relative', overflow: 'show' }}>
                  <Text style={{ fontWeight: 'bold', marginBottom: 10, fontSize: 14, fontWeight: 'bold', fontFamily: 'GothamMedium', color: Constants.colors.darkBlue }}>{timeData.slideName}</Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 4, width: '100%', position: 'relative', height: 15, borderRadius: 5, backgroundColor: Constants.colors.secondaryLight }}>
                    {timeData.viewRanges.map((range, idx) => {
                      const colorIndex = idx % colorArray.length;  // Cycle through colors
                      const left = (range.start / timeData.videoDuration) * 100;
                      const width = ((range.end - range.start) / timeData.videoDuration) * 100;

                      const startMin = Math.floor(range.start / 60);
                      const startSec = (range.start % 60).toFixed(0);
                      const endMin = Math.floor(range.end / 60);
                      const endSec = (range.end % 60).toFixed(0);
                      const tooltipText = `${startMin > 0 ? `${startMin}m ` : ''}${startSec}s to ${endMin > 0 ? `${endMin}m ` : ''}${endSec}s`;

                      return (
                        <Hoverable key={idx} tooltip={tooltipText} left={0} top={25} style={{
                          position: 'absolute',
                          left: `${left}%`,
                          width: `${width}%`,
                          height: 15,
                          backgroundColor: colorArray[colorIndex],
                          borderRadius: 7,
                        }} />
                      );
                    })}
                  </View>
                  {(() => {
                    const totalSecondsViewed = timeData.viewRanges.reduce((acc, range) => acc + (range.end - range.start), 0);
                    const watchedMin = Math.floor(totalSecondsViewed / 60);
                    const watchedSec = (totalSecondsViewed % 60).toFixed(0);
                    const totalMin = Math.floor(timeData.videoDuration / 60);
                    const totalSec = (timeData.videoDuration % 60).toFixed(0);
                    const percentage = ((totalSecondsViewed / timeData.videoDuration) * 100).toFixed(0);
                    return (
                      <Text style={{ color: Constants.colors.secondary, fontSize: 14 }}>
                        Watched {watchedMin > 0 ? `${watchedMin}m ` : ''}{watchedSec}s out of {totalMin > 0 ? `${totalMin}m ` : ''}{totalSec}s ({percentage}%)
                      </Text>
                    );
                  })()}
                </View>
              )) : null}
          </View>
        </View>
      )
    }
  }

  const renderWidgets = () => {
    if (viewing === 'reporting') {
      let component: JSX.Element | JSX.Element[] | null = null;
      if (graphData && currentPitchId && graphData[currentPitchId] && graphData[currentPitchId].widgets.length) {
        let widgetIds: string[] = [];
        component = graphData[currentPitchId].widgets.map((widget, index) => {
          if (widgetIds.indexOf(widget.widgetId) === -1) {
            widgetIds.push(widget.widgetId);
            return (
              <View key={`widget-${index}`} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                <Text style={{ color: Constants.colors.secondary, fontSize: 14 }}>{widget.label}</Text>
                <Text style={{ color: widget.values.length ? Constants.colors.primary : Constants.colors.info, fontWeight: 'bold', fontSize: 14 }}>{widget.values.length ? widget.values.join(', ') : 'No Response'}</Text>
              </View>
            )
          }
          return null
        })
      }

      if (!component) {
        return null;
      }

      return (
        <View style={{
          position: 'relative',
          padding: 20,
          maxHeight: 600
        }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15, zIndex: 10 }}>
            <Text style={{ fontSize: 18, color: Constants.colors.primary, fontWeight: 'bold' }}>Responses</Text>
            {/*<View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginVertical: 15, zIndex: 10 }}>
              <Text style={{ color: Constants.colors.secondary, position: 'relative', top: -1, fontSize: 14 }}>Viewing Data For: </Text>
              <Dropdown defaultLabel={currentPitchTitle} structure={pitchDropdownOptions} onChange={handleSelectPitchGraph} />
            </View>*/}
          </View>
          {component}
        </View>
      )
    }
  }

  const renderMessages = () => {
    if (viewing === 'messages' && lead) {
      return (
        <View style={styles.modalContainer}>
          <MessageFeed masked={masked} messages={lead.messages} queuedMessage={queuedMessage} featureQueue={lead?.featureQueue} />
          {sendingMessage || !state.user?.organization?.billing?.active || state.user.organization.autoReplyEnabled ? null : <WpInput style={{ marginTop: 20 }} defaultValue={newMessage} placeholder="Send Message" id="new-message" name="new-message" onChange={changeMessage} onInput={changeMessage} />}

          {!state.user?.organization?.billing?.active || state.user.organization.autoReplyEnabled ? null :
            <ModalFooter style={{ backgroundColor: 'transparent' }}>
              <Button type="Primary" size="Default" shape="Pill" onClick={onSendMessage}>
                {sendingMessage ? <Spinner style={{ marginHorizontal: 15 }} color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Send</Text>}
              </Button>
            </ModalFooter>}
        </View>
      )
    } else if (loading) {
      return (
        <View style={styles.modalContainer}>
          <Spinner />
        </View>
      )
    }
    return null;
  }

  const leadNameChange = (e: any) => {
    setLeadNewName(e.target.value)
  }

  const leadPhoneChange = (e: any) => {
    setLeadNewPhone(e.target.value)
  }

  const viewReporting = () => {
    setViewing("reporting")
  }

  const viewAppointments = () => {
    if (Constants.oppText || Constants.tattooCare) {
      setViewing("appointments")
    } else {
      setViewing("reporting")
    }
  }

  const viewMessages = () => {
    if (queuedMessage) {
      loadLead();
    }
    setViewing("messages")
  }

  const viewDetails = () => {
    setViewing("details")
  }

  const renderGroups = () => {
    if (!Constants.oppText && !Constants.tattooCare && lead) {
      return (
        <View>
          <Text style={styles.heading}>{'Groups'}</Text>
          <View style={styles.bubbleWrapper}>
            {lead.groups.length ? lead.groups.map((group, index) => {
              return <Text key={`group-${index}`} style={styles.bubble}>{group.name}</Text>
            }) : <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.info, marginBottom: 25 }}>- No Groups -</Text>
            }
          </View>
        </View>
      )
    }
    return null;
  }

  const renderCampaigns = () => {
    if (((!Constants.oppText && !Constants.tattooCare) || __DEV__) && lead) {
      return (
        <View>
          <Text style={styles.heading}>{Constants.blastView ? 'Blasts' : 'Campaigns'}</Text>
          <View style={styles.bubbleWrapper}>
            {lead.campaigns.length ? lead.campaigns.map((campaign, index) => {
              return <Text key={`campaign-${index}`} style={styles.bubble}>{campaign.campaign.name}</Text>
            }) : <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.info, marginBottom: 25 }}>- No Campaigns -</Text>
            }
          </View>
        </View>
      )
    }
    return null;
  }

  const handleClickCampaign = (campaign: WpCampaign) => {
    if (campaign?.v2 && campaign.id) {
      let domain = __DEV__ ? 'http://localhost:3000' : 'https://main.d16ta6dyg5gp.amplifyapp.com';
      window.location.href = `${domain}/campaigns/${campaign.id}?token=${state.user?.token}`
    } else if (campaign?.id) {
      navigate(`/campaign/${campaign.id}`)
    }
  }

  const renderLeadCampaigns = () => {
    if (viewing === 'appointments' && !loading && lead && state.user?.organization?.v2) {
      const leadCampaigns = lead.campaigns.filter(c => c.campaign.leadId === lead.id);
      if (leadCampaigns.length) {
        return (
          <View style={{ width: '100%' }}>
            <View style={styles.bubbleWrapper}>
              {leadCampaigns.map((campaign, index) => {
                return <Text
                  key={`campaign-${index}`}
                  style={{ ...styles.bubble, cursor: 'pointer' }}
                  onClick={() => handleClickCampaign(campaign.campaign)}
                >
                  {campaign.campaign.name}
                </Text>
              })}
            </View>
          </View>
        )
      } else if (!lead.appointments.length) {
        return <Text style={{ color: Constants.colors.info, width: '100%', textAlign: 'center' }}>No campaigns found for this {Constants.localizations.lead}.</Text>
      }
    }
    return null;
  }

  const renderDetails = () => {
    if (viewing === 'details' && lead && !masked) {
      return (
        <View style={styles.modalContainer}>
          {lead.invalidPhone && !lead.optedOut ?
            <View style={{ marginBottom: 20, width: '100%', backgroundColor: Constants.colors.red, borderRadius: 10, padding: 15, flexDirection: 'row', alignItems: 'center' }}>
              <FontAwesome5 style={{ marginLeft: 8 }} name="phone-slash" size={14} color={'#FFF'} />
              <Text style={{ fontFamily: 'GothamBold', color: '#FFF', marginLeft: 15 }}>{lead.phone} is an invalid number.</Text>
            </View> : null}
          {lead.optedOut ?
            <View style={{ marginBottom: 20, width: '100%', backgroundColor: Constants.colors.red, borderRadius: 10, padding: 15, flexDirection: 'row', alignItems: 'center' }}>
              <Entypo name="block" style={{ marginLeft: 5 }} size={16} color={'#FFF'} />
              <Text style={{ fontFamily: 'GothamBold', color: '#FFF', marginLeft: 10 }}>This lead has opted out and cannot be messaged.</Text>
            </View> : null}
          <View style={{ width: '100%', gap: 10, marginBottom: 10 }}>
            <WpInput style={{ marginBottom: 20, width: '100%' }} label="Name" defaultValue={leadNewName} placeholder="Enter Name..." onChange={leadNameChange} onInput={leadNameChange} />
            <WpInput style={{ marginBottom: 20, width: '100%' }} label="Phone" defaultValue={leadNewPhone} placeholder="Enter Phone..." onChange={leadPhoneChange} onInput={leadPhoneChange} />
          </View>

          {renderGroups()}
          {renderCampaigns()}

          <ModalFooter>
            <Button type="Primary" size="Default" shape="Pill" onClick={onUpdateLead}>
              {updateLeadData.loading ? <Spinner style={{ marginHorizontal: 15 }} color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Save</Text>}
            </Button>
          </ModalFooter>
        </View>
      )
    }
    return null
  }

  return (
    <WpModal isVisible={isVisible}>
      <ModalBody closeModal={onClose} width={600}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
          <Heading text={masked ? Constants.localizations.Lead : lead?.name ?? ''} />
        </View>
        <View style={{ flex: 1, position: 'relative' }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginVertical: 30 }}>
            {Constants.oppText || Constants.tattooCare ? <Text onPress={viewAppointments} style={viewing === "appointments" ? styles.controlActive : styles.control}>Campaigns</Text> : null}
            {Constants.blastView ? null : <Text onPress={viewReporting} style={viewing === "reporting" ? styles.controlActive : styles.control}>Reporting</Text>}
            <Text onPress={viewMessages} style={viewing === "messages" ? styles.controlActive : styles.control}>Messages</Text>
            {masked ? null : <Text onPress={viewDetails} style={viewing === "details" ? styles.controlActive : styles.control}>Details</Text>}
          </View>
          {renderLeadCampaigns()}
          {renderAppointments()}
          {renderGraphs()}
          {renderWidgets()}
          {renderMessages()}
          {renderDetails()}
        </View>
      </ModalBody>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    maxHeight: 600
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1,
    width: 115,
    textAlign: 'center'
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1,
    width: 115,
    textAlign: 'center'
  },
  heading: {
    fontFamily: 'GothamMedium',
    color: Constants.colors.darkBlue,
    marginBottom: 15
  },
  bubbleWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 10,
    marginHorizontal: 10,
    overflowX: 'scroll'
  },
  bubble: {
    fontFamily: 'GothamMedium',
    paddingVertical: 13,
    paddingHorizontal: 20,
    borderRadius: 20,
    height: 40,
    color: Constants.colors.darkBlue,
    fontSize: 12,
    backgroundColor: '#F5F7FB',
    marginBottom: 15,
    whiteSpace: 'nowrap'
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
