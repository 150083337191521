import { LayoutChangeEvent, Text, View, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import { formatDateString } from './../../tools/tools';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import { Label, Info } from './../Input';
import Checkbox from './../Checkbox';
import { Report } from './LeadTable';
import LeadDetailsModal from './LeadDetailsModal';
import LeadReportIcon, { Required } from './LeadReportIcon';
import { GET_LEADS } from './../../graphql/queries';
import { WpLeadBasic } from './../../contexts/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Ionicons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { Access, WpUser } from '../../contexts/user';
import Toast from 'react-native-toast-message'
import { INVITE_USERS } from '../../graphql/user';


export default function UserRow({ onClick, user, onSelect, selected = false }: { onClick: (uid: string) => void, user: WpUser, onSelect: (id: string) => void, selected?: boolean }): JSX.Element {
  const [viewWidth, setViewWidth] = useState<number>(0);
  const [hovering, setHovering] = useState<boolean>();
  const [hoveringResend, setHoveringResend] = useState<boolean>();
  const [hoveringEye, setHoveringEye] = useState<boolean>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [inviteUsers, { data, loading }] = useMutation(INVITE_USERS, {
    fetchPolicy: "no-cache",
  });

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onHover = () => {
    setHovering(true)
  }

  const onHoverOut = () => {
    setHovering(false)
  }

  const onHoverResend = () => {
    setHoveringResend(true)
  }

  const onHoverResendOut = () => {
    setHoveringResend(false)
  }

  const handleOnSelect = () => {
    onSelect(user.id)
  }

  const formatSeconds = (seconds: number) => {
    if (seconds) {
      return new Date(1000 * seconds).toISOString().substr(14, 5);
    } else {
      return '--:--'
    }
  }

  const onPress = () => {
    if (!hoveringResend) {
      onClick(user.id)
    }
  }

  const resendInvite = () => {
    inviteUsers({
      variables: {
        users: [{
          email: user.email,
          role: user.organization.access
        }]
      }
    })

    Toast.show({
      type: 'success',
      text1: 'Invite Resent',
      text2: `An invite has been resent to ${user.email}`
    });
  }

  const getAccessLabel = () => {
    if (user.organization.access === Access.LIMITED) {
      return "Notifications Only"
    } else if (user.organization.access === Access.MANAGER) {
      return "Can Make Changes"
    } else if (user.organization.access === Access.READONLY) {
      return "Can View Account"
    } else if (user.organization.access === Access.ADMIN) {
      return "Admin"
    } else if (user.organization.access === Access.OWNER) {
      return "Owner"
    }
  }

  return (
    <View key={`user-row-${user.id}`} style={[styles.row, { opacity: hovering ? 0.7 : 1, backgroundColor: hovering || selected ? Constants.colors.lightBlue : '#FFF' }]} onLayout={onLayout} onClick={onPress} onMouseEnter={onHover} onMouseLeave={onHoverOut}>
      <Checkbox onSelect={handleOnSelect} selected={selected} hoverable={false} />
      <Text key="user-name" style={{ fontSize: 12, width: 250 }}>{user.name ? user.name : user.email}</Text>
      <Text key="user-phone" style={{ fontSize: 12, width: 150 }}>{user.phone}</Text>
      <Text key="user-email" style={{ fontSize: 12, width: 200 }}>{user.email}</Text>
      <Text key="user-access" style={{ fontSize: 12, marginLeft: 'auto', marginRight: 25 }}>{user.name ? getAccessLabel() : 'Invitation Pending'}</Text>
      {user.name ? null : <Text key="user-reinvite" style={{ fontSize: 12, textDecorationStyle: 'underline', color: Constants.colors.blue, cursor: 'pointer' }} onClick={resendInvite} onMouseEnter={onHoverResend} onMouseLeave={onHoverResendOut}>Resend Invite</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    paddingVertical: 26,
    paddingHorizontal: 20,
    width: '100%',
    backgroundColor: '#FFF',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    height: 45,
    borderTopColor: '#E5E5E5',
    borderTopWidth: 1,
  },
  item: {
    width: 175,
  },
  lastActive: {
    color: Constants.colors.info,
    fontSize: 10
  }
});

const cssStyles = {
}
