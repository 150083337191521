import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import Toast from 'react-native-toast-message';

import Constants from '../constants';
import { Access, UserContext } from '../contexts/user';
import CampaignOverview from '../components/CampaignOverview';
import LeadTable from '../components/Leads/LeadTable';
import Navbar from '../components/Navbar';
import { Button, Chart, DashboardNav, Input, PageTitle, PercentageCard, StatsCard } from '@whistlepitch/wp-components';
import { UsersIcon } from '@heroicons/react/outline';
import WpModal from '../components/WpModal';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import StripeCardElement from '../components/Shared/StripeCardElement';
import { useMutation, useQuery } from '@apollo/client';
import { INVITE_USERS } from '../graphql/user';
import Icon from '../components/Icon';
import { useNavigate } from '../react-router';
import UserDetailsModal from '../components/User/UserDetailsModal';
import SuperAdminModal from '../components/User/SuperAdminModal';
import { Label } from '../components/Input';
import BasicLayout from '../components/Shared/BasicLayout';
import { GET_CAMPAIGNS } from '../graphql/queries';

// const summaryData = [
//   {
//     "label": "Campaign 1",
//     "value": 70,
//     "tooltip": "Leads:1000"
//   },
//   {
//     "label": "Campaign 2",
//     "value": 40,
//     "tooltip": "Leads: 500"
//   },
//   {
//     "label": "Campaign 3",
//     "value": 30,
//     "tooltip": "Leads: 300"
//   },
//   {
//     "label": "Campaign 4",
//     "value": 45,
//     "tooltip": "Leads: 600"
//   },
//   {
//     "label": "Campaign 5",
//     "value": 60,
//     "tooltip": "Leads: 500"
//   }
// ]

interface SummaryData {
  label: string;
  value: number;
  tooltip: string;
}

export default function Dashboard(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
  const [completePercentages, setCompletePercentages] = useState<number[]>([]);
  const [chartSelected, setChartSelected] = useState<number>(0);

  const campaignData = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: "no-cache",
    variables: { includeFeatures: false, includeStats: true, recent: true }
  })

  useEffect(() => {
    if (campaignData.data?.campaigns?.campaigns) {
      let totalLeads = 0;
      let cps: number[] = [];
      let totalComplete = 0;

      let totalData: SummaryData[] = [];
      for (let i = 0; i < campaignData.data?.campaigns?.campaigns.length; i++) {
        let c = campaignData.data?.campaigns?.campaigns[i];

        let opened = '0';
        if (c.stats.leads > 0 && c.stats.opened > 0) {
          opened = (c.stats.opened / c.stats.leads * 100).toFixed(0);
        }

        let tooltip = "Leads: " + c.stats.leads + ", Opened: " + c.stats.opened + ", Completed: " + c.stats.complete;
        totalData.push({
          label: c.name ? c.name.substr(0, 12) + (c.name.length > 12 ? '...' : '') : '',
          value: parseInt(opened),
          tooltip: tooltip
        })

        totalLeads += c.stats.leads
        totalComplete += c.stats.complete

        let comp: string = '0';
        if (c.stats.leads > 0 && c.stats.complete > 0) {
          comp = (c.stats.complete / c.stats.leads * 100).toFixed(0);
        }
        cps.push(parseInt(comp))
      }

      let complete = '0';
      if (totalLeads > 0 && totalComplete > 0) {
        complete = (totalComplete / totalLeads * 100).toFixed(0);
      }
      cps.push(parseInt(complete))

      setSummaryData(totalData);
      setCompletePercentages(cps)
    }
  }, [campaignData.data])

  useEffect(() => {
    console.log('View Rate', state.user?.organization?.stats?.viewRate)
    console.log('Slide View Rate', state.user?.organization?.stats?.slideViewRate)
  }, [state.user])

  const renderHeader = (): JSX.Element => {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 20 }}>
        <Text style={{ width: 200, marginLeft: 50, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Name</Text>
        <Text style={{ width: 200, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Phone</Text>
        {Constants.blastView ? null : <Text style={{ width: 150, fontFamily: 'GothamMedium', color: Constants.colors.info }}>View Time</Text>}
        {Constants.blastView ? null : <Text style={{ width: 100, fontFamily: 'GothamMedium', color: Constants.colors.info }}>Views</Text>}
        <Text style={{ width: 150, fontFamily: 'GothamMedium', color: Constants.colors.info }}>{Constants.blastView ? 'Blasts' : 'Campaigns'}</Text>
      </View>
    )
  }

  const onSelectChart = (index: number, data: SummaryData) => {
    setChartSelected(index);
  }

  const renderBody = () => {
    if (state.user) {
      if (Constants.blastView || Constants.oppText || Constants.tattooCare) {
        return (
          <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text style={styles.heading}>Welcome{state.user.firstName ? ` ${state.user.firstName}` : ''}</Text>
            </View>
            <View style={{ flexDirection: 'row', gap: 20, marginVertical: 30, flexWrap: 'wrap' }}>
              <View style={{ flexGrow: 2, flexBasis: 'auto', minWidth: 250, flexDirection: 'column', justifyContent: 'flex-start', gap: 20 }}>
                <StatsCard
                  style={{ flex: 1 }}
                  description={'' + (state.user.stats.messages)}
                  title={"Messages Sent"}
                  type="White"
                >
                  <Icon style={{ padding: 15 }} name="message" color={Constants.colors.darkBlue} raw={true} />
                </StatsCard>
                <StatsCard
                  style={{ flex: 1 }}
                  description={'' + state.user.stats.leads}
                  title={Constants.localizations.Leads}
                  type="White"
                >
                  <Icon style={{ padding: 12 }} name="users" color={Constants.colors.darkBlue} raw={true} />
                </StatsCard>
              </View>
              <PercentageCard key="pc-delivery" style={{ flexGrow: 1, flexBasis: 'auto', minWidth: 250 }} title={"Delivery Rate"} percentage={state.user?.organization?.stats?.deliveryRate ? parseFloat((state.user.organization.stats.deliveryRate * 100).toFixed(1)) : 0} />
              {
                Constants.oppText || Constants.tattooCare ?
                  <PercentageCard key="pc-open" style={{ flexGrow: 1, flexBasis: 'auto', minWidth: 250 }} title={"Open Rate"} percentage={state.user?.organization?.stats?.openRate ? parseFloat((state.user.organization.stats.openRate * 100).toFixed(1)) : 0} /> :
                  <PercentageCard key="pc-response" style={{ flexGrow: 1, flexBasis: 'auto', minWidth: 250 }} title={"Response Rate"} percentage={state.user?.organization?.stats?.responseRate ? parseFloat((state.user.organization.stats.responseRate * 100).toFixed(1)) : 0} />
              }

            </View>
            <LeadTable header={renderHeader()} masked={Constants.oppText && state.user.wpAdmin && state.user.organization.id !== "1"} selectable={true} searchable={true} selectedLeads={selectedLeads} setSelectedLeads={setSelectedLeads} />
          </View>
        )
      } else {
        return (
          <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              {Constants.oppText ?
                <Text style={styles.heading}>Welcome to OppText</Text> :
                <Text style={styles.heading}>Welcome{state.user.firstName ? ` ${state.user.firstName}` : ''}</Text>}
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 20, marginTop: 30, flexWrap: 'wrap' }}>
              <StatsCard
                style={{ flex: 1, maxWidth: 400, minWidth: 250 }}
                description={'' + (state.user.stats.messages)}
                title={"Messages Sent"}
                type="White"
              >
                <Icon style={{ padding: 15 }} name="message" color={Constants.colors.darkBlue} raw={true} />
              </StatsCard>
              <StatsCard
                style={{ flex: 1, maxWidth: 400, minWidth: 250 }}
                description={'' + state.user.stats.leads}
                title={Constants.localizations.Leads}
                type="White"
              >
                <Icon style={{ padding: 12 }} name="users" color={Constants.colors.darkBlue} raw={true} />
              </StatsCard>
              <StatsCard
                style={{ flex: 1, maxWidth: 400, minWidth: 250 }}
                description={'' + state.user.stats.views}
                title="Views"
                type="White"
              >
                <Icon style={{ padding: 12 }} name="eye" color={Constants.colors.darkBlue} raw={true} />
              </StatsCard>
            </View>
            {summaryData && summaryData.length > 0 ?
              <View style={{ flexDirection: 'row', gap: 20, marginTop: 20, marginBottom: 40, flexWrap: 'wrap' }}>
                <View style={{ flex: 3, minWidth: 350, borderRadius: 10, paddingRight: 20, backgroundColor: '#FFFFFF' }}>
                  <Chart legend={'Lead Open Rate'} defaultSelected={chartSelected} data={summaryData} type="Percentage" onSelect={onSelectChart} />
                </View>
                <PercentageCard style={{ flex: 1 }} title={Constants.blastView ? "Viewed" : "Completed"} percentage={completePercentages.length ? completePercentages[chartSelected] : 0} />
              </View> : <View style={{ marginVertical: 20 }}></View>}

            {state.user.organization.access === Access.LIMITED ? null : <LeadTable header={renderHeader()} selectable={!Constants.blastView || state.user?.wpAdmin || state.user?.id === "15"} searchable={true} selectedLeads={selectedLeads} setSelectedLeads={setSelectedLeads} />}
          </View>
        )
      }
    } else {
      return (
        <View>
        </View>
      )
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/dashboard'}>
      {renderBody()}
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
})

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  stat: {
    width: 40,
    height: 40,
    color: Constants.colors.blue
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
