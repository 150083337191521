import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import Constants from './../constants';
import { ChromePicker } from 'react-color';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import constants from './../constants';

export function Label({ name, optional = false, style = {} }: { name: string, optional?: boolean | string, style?: object }): JSX.Element {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
      <Text style={[styles.text, style]}>{name}</Text>
      {optional ? <Text style={styles.textOptional}>({typeof optional === 'string' || optional instanceof String ? optional : 'Optional'})</Text> : null}
    </View>
  )

}

export function Info({ info, style = {} }: { info: string, style?: object }): JSX.Element {
  return <Text style={[styles.info, style]}>{info}</Text>
}

export default function Input({ name, fontSize = 26, fontColor = Constants.colors.darkBlue, textAlign = "center", style = {}, multiline, optional, isNumber = false, isColor = false, isHeading = false, isDate = false, value, placeholder, info, setValue, keyboardType = 'default', secure = false, disabled = false }: { name?: string, fontSize?: number, textAlign?: string, fontColor?: string, style?: Object, optional?: boolean | string, multiline?: boolean, isHeading?: boolean, isNumber?: boolean, isColor?: boolean, isDate?: boolean, keyboardType?: KeyboardTypeOptions, value: number | string | undefined, placeholder?: string, info?: string, setValue: Function, secure?: boolean, disabled?: boolean }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);
  const [sketchVisible, setSketchVisible] = useState<boolean>(false);
  const [color, setColor] = useState<string>(Constants.colors.primary);
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout>();

  const picker = useRef();

  useEffect(() => {
    setColor(value ? value : Constants.colors.primary);
  }, [value])

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const handleChange = (c, event) => {
    resetTimeout();
    setColor(c ? c.hex : Constants.colors.primary);
  }

  const showSketch = () => {
    setSketchVisible(true);
  }

  const resetTimeout = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout)
    }
  }

  const updateDate = (newDate: Date) => {
    let date_str = null;
    if (newDate) {
      date_str = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
    }
    setValue(date_str)
  }

  const handleOutsideClick = (e) => {
    if (picker.current && !picker.current.contains(e.target)) {
      setSketchVisible(false); // Hide the picker
    }
  };

  const updateColor = (c, event) => {
    setValue(c ? c.hex : Constants.colors.primary);
  }

  const renderInput = () => {
    if (isColor) {
      return (
        <View style={styles.colorWrapper} onClick={showSketch}>
          {sketchVisible ?
            <View style={styles.sketchPicker} ref={picker}>
              <ChromePicker color={color} onChange={handleChange} onChangeComplete={updateColor} disableAlpha={true} />
              {/*<Button style={{ marginTop: 15, marginLeft: 'auto' }} width={80} small={true} text="Save" onPress={hideSketch} />*/}
            </View> : null
          }
          <View style={[styles.colorInput, { backgroundColor: value ? value : Constants.colors.primary }]}>
          </View>
        </View>
      )
    } else if (isDate) {
      // split date value into month, day, year and create new date object
      let date: string | number | Date | undefined = value;
      if (value && typeof value === 'string') {
        const dateParts = value.split('/');
        if (dateParts.length === 3) {
          date = new Date();
          date.setHours(0, 0, 0, 0)
          date.setDate(1)
          date.setFullYear(parseInt(dateParts[2]))
          date.setMonth(parseInt(dateParts[0]) - 1)
          date.setDate(parseInt(dateParts[1]))
        }
      }

      return (
        <DatePicker
          placeholderText={placeholder}
          selected={date}
          onChange={updateDate}
          disabled={disabled}
        />
      )
    } else {
      // Set default
      let currentValue = isNumber ? '00' : '';
      if (value) {
        // Set actual value
        if (isNumber) {
          currentValue = String(value).padStart(2, '0')
        } else {
          currentValue = value;
        }
      }

      return (
        <TextInput
          keyboardType={keyboardType}
          textAlign={isHeading ? (textAlign === "left" || textAlign === "right" ? textAlign : 'center') : 'left'}
          style={isHeading ? [styles.inputHeading, { fontSize, color: fontColor, textAlign }] : [styles.input, { height: multiline ? 120 : 40, backgroundColor: disabled ? Constants.colors.secondaryLight : '#FFF' }]}
          placeholder={placeholder}
          placeholderTextColor="#a9a9a9"
          secureTextEntry={secure}
          onChangeText={(val) => {
            setValue(val)
          }}
          selectTextOnFocus={isNumber}
          multiline={multiline}
          value={currentValue}
          disabled={disabled}
        />
      )
    }
  }

  return (
    <View style={[{ zIndex: 2, position: 'relative' }, style]}>
      {name ?
        <View style={styles.label}>
          {name ? <Label name={name} optional={optional} /> : null}
        </View> : null}
      {renderInput()}
      {info ?
        <View style={styles.label}>
          {info ? <Info info={info} /> : null}
        </View> : null}
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    fontFamily: 'Gotham',
    position: 'relative',
  },
  inputHeading: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    borderRadius: 7,
    color: Constants.colors.darkBlue,
    fontFamily: 'GothamMedium',
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontFamily: 'Gotham',
    color: Constants.colors.darkBlue,
    marginBottom: 5
  },
  textOptional: {
    fontFamily: 'Gotham',
    color: Constants.colors.info,
    marginBottom: 5,
    marginLeft: 10
  },
  info: {
    fontFamily: 'Gotham',
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
