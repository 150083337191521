import { Text, View, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import MessageEditor from './../MessageEditor';
import CampaignDropdown from './../CampaignDropdown';
import GroupDropdown from './../GroupDropdown';
import { Button as LocalButton, Theme } from './../Button';
import LeadRow from './LeadRow';
import Checkbox from './../Checkbox';
import Input, { Label, Info } from './../Input';
import { GET_LEADS } from './../../graphql/queries';
import { WpLeadBasic } from './../../contexts/lead';
import { ARCHIVE_LEADS, ADD_LEADS_TO_GROUP, ADD_LEADS_TO_CAMPAIGN, REMOVE_LEADS_FROM_CAMPAIGN, REMOVE_LEADS_FROM_GROUP, MESSAGE_LEADS } from './../../graphql/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Ionicons, Feather } from '@expo/vector-icons';
import Icon from '../Icon';
import { IconButton, Button, SearchInput } from '@whistlepitch/wp-components';
import Spinner from '../Spinner';
import Toast from 'react-native-toast-message';
import { Access, UserContext, WpUserAction } from '../../contexts/user';
import BillingModal from '../User/BillingModal';
import { UPDATE_ORGANIZATION, UPDATE_USER } from '../../graphql/user';
import { MessageBubble } from '../Shared/MessageFeed';

export interface Filter {
  unopened?: string[],
  complete?: string[],
  incomplete?: string[],
  slideIdsSeen?: string[]
  slideIdsUnseen?: string[]
}

export interface Report {
  campaignId: string,
  verified?: string[],
  video?: string[],
  response?: string[],
  calendar?: string[],
  redirected?: string[],
  unopened?: string[],
  optedOut?: boolean,
}

export default function LeadTable({ header = null, selectable, searchable, selectedLeads, setSelectedLeads, removeable, backgroundColor = '#FFFFFF', leads, campaignId, groupId, refresh, filter, report, showControls = true, masked = false }: { header?: JSX.Element | null, selectable?: boolean, searchable?: boolean, selectedLeads: string[], removeable?: boolean, backgroundColor?: string, setSelectedLeads: Function, leads?: string[], campaignId?: string, groupId?: string, refresh?: number, filter?: Filter, report?: Report, showControls?: boolean, masked?: boolean }): JSX.Element {
  const [localLeads, setLocalLeads] = useState<WpLeadBasic[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [hovering, setHovering] = useState<"prev" | "next">();
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  const [getLeads, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_LEADS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [removeLeadsFromCampaign, removeLeadsFromCampaignData] = useMutation(REMOVE_LEADS_FROM_CAMPAIGN, {
    fetchPolicy: "no-cache",
  });

  const [removeLeadsFromGroup, removeLeadsFromGroupData] = useMutation(REMOVE_LEADS_FROM_GROUP, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.leads?.leads) {
      setLocalLeads(data.leads.leads)
      setTotal(data.leads.total)
      setPage(data.leads.page)
    }
  }, [data, networkStatus])

  useEffect(() => {
    onRefresh();
  }, [leads, groupId, campaignId, refresh, filter, page, removeLeadsFromCampaignData.data, removeLeadsFromGroupData.data])

  useEffect(() => {
    setPage(1)
    onRefresh();
  }, [search])

  const onRefresh = () => {
    if (campaignId) {
      loadCampaignLeads();
    } else if (groupId) {
      loadGroupLeads();
    } else if (!leads) {
      loadDashboardLeads();
    }
  }

  const loadCampaignLeads = () => {
    if (campaignId) {
      loadLeads({ campaignId: campaignId })
    }
  }

  const loadGroupLeads = () => {
    if (groupId) {
      loadLeads({ groupId: groupId })
    }
  }

  const loadDashboardLeads = () => {
    loadLeads({})
  }

  const loadLeads = (variables: object) => {
    // if (data) {
    //   refetch({ ...variables, page: page, leadFilter: filter, search: search });
    // } else {
    getLeads({ variables: { ...variables, page: page, leadFilter: filter, search: search } })
    // }
  }

  const onSelect = (lid: string) => {
    let lindex = selectedLeads.indexOf(lid)
    if (lindex > -1) {
      setSelectedLeads([
        ...selectedLeads.slice(0, lindex),
        ...selectedLeads.slice(lindex + 1),
      ])
    } else {
      setSelectedLeads([...selectedLeads, lid])
    }
  }

  const onHoverPrev = () => {
    setHovering("prev")
  }

  const onHoverNext = () => {
    setHovering("next")
  }

  const onHoverOut = () => {
    setHovering(undefined)
  }

  const onPrev = () => {
    setPage(Math.max(1, page - 1))
  }

  const onNext = () => {
    setPage(Math.min(Math.ceil(total / 10), page + 1))
  }

  const clearSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    setSearch(undefined)
  }

  const onSearch = (e: any) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    let newSearch = e.target.value;
    setSearchTimeout(setTimeout(() => {
      setSearch(newSearch)
    }, 500))
  }

  const onRemove = (leadId: string) => {
    if (removeable) {
      if (!!campaignId) {
        removeLeadsFromCampaign({ variables: { leadIds: [leadId], campaignId: campaignId } });
      } else if (!!groupId) {
        removeLeadsFromGroup({ variables: { leadIds: [leadId], groupId: groupId } });
      }
    }
  }

  // const renderLeads = () => {
  //   if (loading) {
  //     return <Spinner />
  //   } else if (localLeads.length) {
  //     return localLeads.map((lead) => <LeadRow key={`lead-row-${lead.id}`} selectable={selectable} onRefresh={onRefresh} onRemove={onRemove} removeable={removeable} backgroundColor={backgroundColor} lead={lead} onSelect={onSelect} selected={selectedLeads.indexOf(lead.id) > -1} report={report} />)
  //   } else {
  //     return null
  //   }
  // }

  const renderLeads = () => {
    if (loading) {
      return <Spinner />
    } else if (localLeads.length) {
      return localLeads.map((lead, index) => <LeadRow key={`lead-row-${lead.id}`} index={index} masked={masked} selectable={selectable} onRefresh={onRefresh} onRemove={onRemove} removeable={removeable} backgroundColor={backgroundColor} lead={lead} onSelect={onSelect} selected={selectedLeads.indexOf(lead.id) > -1} report={report} index={index} />)
    } else {
      return null
    }
  }

  return (
    <View style={{ flex: 1, minWidth: 250 }}>
      {showControls && (localLeads.length || search) ?
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
          <TableControls thisCampaignId={campaignId} thisGroupId={groupId} onRefresh={onRefresh} selectedLeads={selectedLeads} setSelectedLeads={setSelectedLeads} />
          {searchable ? <SearchInput style={{ marginBottom: 20, maxWidth: 350, width: '100%' }} onChange={onSearch} onCleanData={clearSearch} shape="Pill" /> : null}
        </View> : null}
      {localLeads.length || search ? header : null}
      {renderLeads()}
      {loading || total <= 10 ? null :
        <View style={styles.pagination}>
          <Ionicons style={{ cursor: 'pointer' }} name={hovering === "prev" ? "chevron-back-circle" : "chevron-back-circle-outline"} size={24} color={Constants.colors.primary} onPress={onPrev} onMouseEnter={onHoverPrev} onMouseLeave={onHoverOut} />
          <Text style={{ padding: 10 }}>{page} / {Math.ceil(total / 10)}</Text>
          <Ionicons style={{ cursor: 'pointer' }} name={hovering === "next" ? "chevron-forward-circle" : "chevron-forward-circle-outline"} size={24} color={Constants.colors.primary} onPress={onNext} onMouseEnter={onHoverNext} onMouseLeave={onHoverOut} />
        </View>
      }
    </View>
  );
}

export enum LeadAction {
  ARCHIVE = "ARCHIVE",
  CONFIRM = "CONFIRM",
  EDIT_OPTIN = "EDIT_OPTIN",
  CAMPAIGN = "CAMPAIGN",
  GROUP = "GROUP",
  MESSAGE = "MESSAGE",
  REMOVE = "REMOVE",
  REMOVE_GROUP = "REMOVE_GROUP"
}

export function TableControls({ onRefresh, onDownload, thisCampaignId, thisGroupId, selectedLeads, setSelectedLeads }: { onRefresh: Function, onDownload?: () => void, thisCampaignId?: string, thisGroupId?: string, selectedLeads: string[], setSelectedLeads: Function }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isBilling, setIsBilling] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [understandTiming, setUnderstandTiming] = useState<boolean>(false);
  const [understandContacts, setUnderstandContacts] = useState<boolean>(false);
  const [understandEdit, setUnderstandEdit] = useState<boolean>(false);
  const [stateWas, setStateWas] = useState<LeadAction>();
  const [action, setAction] = useState<LeadAction>();
  const [campaignId, setCampaignId] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [optinMessage, setOptinMessage] = useState<string>();
  const [messageContent, setMessageContent] = useState<object>();
  const [optinMessageContent, setOptinMessageContent] = useState<object>();

  const [archiveLeads, archiveLeadsData] = useMutation(ARCHIVE_LEADS, {
    fetchPolicy: "no-cache",
  });

  const [addLeadsToCampaign, addLeadsToCampaignData] = useMutation(ADD_LEADS_TO_CAMPAIGN, {
    fetchPolicy: "no-cache",
  });

  const [addLeadsToGroup, addLeadsToGroupData] = useMutation(ADD_LEADS_TO_GROUP, {
    fetchPolicy: "no-cache",
  });

  const [messageLeads, messageLeadsData] = useMutation(MESSAGE_LEADS, {
    fetchPolicy: "no-cache",
  });

  const [removeLeadsFromCampaign, removeLeadsFromCampaignData] = useMutation(REMOVE_LEADS_FROM_CAMPAIGN, {
    fetchPolicy: "no-cache",
  });

  const [removeLeadsFromGroup, removeLeadsFromGroupData] = useMutation(REMOVE_LEADS_FROM_GROUP, {
    fetchPolicy: "no-cache",
  });

  const [updateUser, updateUserData] = useMutation(UPDATE_USER, {
    fetchPolicy: "no-cache",
  });

  const [updateOrganization, updateOrganizationData] = useMutation(UPDATE_ORGANIZATION, {
    fetchPolicy: "no-cache",
  });

  const closeModal = () => {
    setIsVisible(false);
    setLoading(false);
    setAction(undefined);
  }

  useEffect(() => {
    if (archiveLeadsData.data || addLeadsToCampaignData.data || addLeadsToGroupData.data || messageLeadsData.data || removeLeadsFromCampaignData.data || removeLeadsFromGroupData.data) {
      closeModal();
      onRefresh();
    }
  }, [archiveLeadsData.data, addLeadsToCampaignData.data, addLeadsToGroupData.data, messageLeadsData.data, removeLeadsFromCampaignData.data, removeLeadsFromGroupData.data])

  useEffect(() => {
    if (updateUserData.data?.updateUser?.user?.organization) {
      dispatch({ type: WpUserAction.REFRESH_ORGANIZATION, organization: updateUserData.data?.updateUser?.user?.organization })
      doneSetupBilling();
    }
  }, [updateUserData.data])

  useEffect(() => {
    if (!optinMessageContent) {
      setOptinMessage(state.user?.organization.message)
      setOptinMessageContent(state.user?.organization.messageContent)
    }
  }, [state.user?.organization.messageContent])

  useEffect(() => {
    if (updateOrganizationData.data?.updateOrganization) {
      let org = updateOrganizationData.data.updateOrganization.organization;
      setOptinMessage(org.message)
      setOptinMessageContent(org.messageContent)

      dispatch({ type: WpUserAction.REFRESH_ORGANIZATION, organization: org })
      confirmCampaign(stateWas);
      setLoading(false);
    }
  }, [updateOrganizationData.data])

  const onClear = () => {
    setSelectedLeads([]);
  }

  const selectAll = () => {
    console.log("Select All!")
  }

  const archive = () => {
    startAction(LeadAction.ARCHIVE);
  }

  const addToCampaign = () => {
    startAction(LeadAction.CAMPAIGN);
  }

  const confirmCampaign = (was: LeadAction) => {
    setStateWas(was);
    startAction(LeadAction.CONFIRM);
  }

  const completeConfirm = () => {
    if (stateWas === LeadAction.CAMPAIGN) {
      if (state.user?.organization?.billing?.active) {
        addLeadsToCampaign({ variables: { leadIds: selectedLeads, campaignId: campaignId } });
      } else {
        setIsBilling(true);
        setLoading(false);
      }
    } else {
      if (state.user?.organization?.billing?.active) {
        messageLeads({ variables: { leadIds: selectedLeads, messageContent: messageContent } });
      } else {
        setIsBilling(true);
        setLoading(false);
      }
    }
  }

  const editOptin = () => {
    startAction(LeadAction.EDIT_OPTIN);
  }

  const addToGroup = () => {
    startAction(LeadAction.GROUP);
  }

  const onMessageLeads = () => {
    startAction(LeadAction.MESSAGE);
  }

  const removeFromCampaign = () => {
    startAction(LeadAction.REMOVE);
  }

  const removeFromGroup = () => {
    startAction(LeadAction.REMOVE_GROUP);
  }

  const startAction = (action: LeadAction) => {
    if (selectedLeads.length > 0) {
      setAction(action);
      openModal();
    } else {
      Toast.show({
        type: 'error',
        text1: `Select ${Constants.localizations.leads}`,
        text2: `Select ${Constants.localizations.leads} from the table first.`
      });
    }
  }

  const openModal = () => {
    setIsVisible(true);
  }

  const renderOptInMessage = () => {
    if (Constants.blastView) {
      return (
        <View style={{ flex: 1, position: 'relative' }}>
          <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, textAlign: 'center', fontSize: 18, marginVertical: 20, paddingHorizontal: 10 }}>Opt-in Message</Text>
          {optinMessage ?
            <MessageBubble userId={"1"} message={optinMessage} margin={'5%'} maxWidth={'90%'} backgroundColor={'white'} /> :
            <Text key="no-optin" style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>No opt-in message has been set. Follow the link below to create one.</Text>}
          <LocalButton style={{ marginTop: 5, marginBottom: 15 }} width={'100%'} text="Edit Opt-in Message" theme={Theme.LINK_PRIMARY} onPress={editOptin} />
        </View>
      )
    }
    return null;
  }

  const renderBody = () => {
    switch (action) {
      case LeadAction.CONFIRM:
        if (Constants.blastView) {
          return (
            <View style={{ flex: 1, position: 'relative' }}>
              <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>The message shown below will be sent to any new {Constants.localizations.leads} that have not yet opted-in to receive SMS messages from you. If they opt-in, {Constants.localizations.campaigns} and messages will continue from that point.</Text>
              {renderOptInMessage()}

              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandTiming(val)} selected={understandTiming} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand that messages are only sent out between 8am-8pm MST. If you try to send a message outside of these times, our servers will wait until 8am the following morning to send them out.</Text>
              </View>
              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandContacts(val)} selected={understandContacts} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand {Constants.localizations.leads} I add personally will need to opt-in to receive future communication. Any {Constants.localizations.leads} added via a {Constants.brand} landing page do not need an additional opt-in.</Text>
              </View>
              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandEdit(val)} selected={understandEdit} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand I can edit the opt-in message anytime in settings.</Text>
              </View>
            </View>
          )
        } else {
          return (
            <View style={{ flex: 1, position: 'relative' }}>
              <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>Adding a {Constants.localizations.lead} to a {Constants.localizations.campaign} will start the process of sending out each of your messages. Make certain your {Constants.localizations.campaign} is fully set up how you want it before adding any {Constants.localizations.leads}.</Text>
              <Text style={{ fontFamily: 'GothamMedium', color: '#999', marginTop: 20, paddingHorizontal: 10 }}>Note: Messages are only sent out between 8am-8pm MST. If you try to send a message outside of these times, our servers will wait until 8am the following morning to send them out.</Text>
            </View>
          )
        }
      case LeadAction.ARCHIVE:
        return (<Text>Archiving a {Constants.localizations.lead} will remove the record from your account. You will no longer be able to see or manage that {Constants.localizations.lead}.</Text>)
      case LeadAction.REMOVE:
        return (<Text>Removing a {Constants.localizations.lead} from this campaign will cancel any scheduled message and remove them from any reporting. You will no longer be able to see or manage that {Constants.localizations.lead}.</Text>)
      case LeadAction.REMOVE_GROUP:
        return (<Text>This {Constants.localizations.lead} will no longer be in this group.</Text>)
      case LeadAction.MESSAGE:
        return (<MessageEditor messageContent={messageContent} setMessageContent={setMessageContent} setMessage={setMessage} />)
      case LeadAction.EDIT_OPTIN:
        return (<MessageEditor messageContent={optinMessageContent} includePlaceholders={false} setMessageContent={setOptinMessageContent} setMessage={setOptinMessage} />)
      case LeadAction.CAMPAIGN:
        return (<CampaignDropdown style={{ zIndex: 5 }} instantOnly={true} value={campaignId} setValue={setCampaignId} />)
      case LeadAction.GROUP:
        return (<GroupDropdown style={{ zIndex: 5 }} value={groupId} setValue={setGroupId} />)
      default:
        return null;
    }
  }

  const getTitle = () => {
    let lead = Constants.localizations.leads[0].toUpperCase() + Constants.localizations.leads.substr(1)
    let campaign = Constants.localizations.campaign[0].toUpperCase() + Constants.localizations.campaign.substr(1)
    switch (action) {
      case LeadAction.CONFIRM:
        return `Messaging Confirmation`
      case LeadAction.EDIT_OPTIN:
        return `Edit Opt-in Message`
      case LeadAction.MESSAGE:
        return `Message ${lead}`
      case LeadAction.CAMPAIGN:
        return `Add ${lead} to ${campaign}`
      case LeadAction.GROUP:
        return `Add ${lead} to Group`
      case LeadAction.MESSAGE:
        return `Remove ${lead} from ${campaign}`
      default:
        return "Are You Sure?"
    }
  }

  const getButtonText = () => {
    switch (action) {
      case LeadAction.ARCHIVE:
        return "Archive"
      case LeadAction.MESSAGE:
        return "Send Message"
      case LeadAction.CAMPAIGN:
        return "Continue"
      case LeadAction.EDIT_OPTIN:
        return "Continue"
      default:
        return "Confirm"
    }
  }

  const getSelectedText = () => {
    if (selectedLeads.length) {
      return `${selectedLeads.length} ${Constants.localizations.lead}${selectedLeads.length > 1 ? 's' : ''} Selected`
    } else {
      return 'Select'
    }
  }

  const doneSetupBilling = () => {
    setIsBilling(false);
  }

  // const onCompleteBilling = () => {
  //   setIsVisible(true);
  // }

  const onCompleteBilling = (token?: string, plan?: string) => {
    if (state.user) {
      updateUser({
        variables: {
          userId: state.user.id,
          user: {
            token: token,
            plan: plan
          }
        }
      })
    }
  }

  const isDisabled = () => {
    switch (action) {
      case LeadAction.CONFIRM:
        return Constants.blastView && (!understandContacts || !understandEdit || !understandTiming)
      case LeadAction.MESSAGE:
        return !messageContent
      case LeadAction.EDIT_OPTIN:
        return !optinMessageContent
      case LeadAction.CAMPAIGN:
        return !campaignId
      case LeadAction.GROUP:
        return !groupId
      default:
        return false
    }
  }

  const onConfirm = () => {
    setLoading(true);
    switch (action) {
      case LeadAction.ARCHIVE:
        archiveLeads({ variables: { leadIds: selectedLeads } });
        setSelectedLeads([]);
        return;
      case LeadAction.MESSAGE:
        if (Constants.blastView && state.user?.organization.understandsTiming) {
          setStateWas(LeadAction.MESSAGE)
          completeConfirm();
        } else {
          confirmCampaign(LeadAction.MESSAGE);
          setLoading(false);
        }
        return;
      case LeadAction.CONFIRM:
        completeConfirm();
        return;
      case LeadAction.CAMPAIGN:
        if (Constants.blastView && state.user?.organization.understandsTiming) {
          setStateWas(LeadAction.CAMPAIGN)
          completeConfirm();
        } else {
          confirmCampaign(LeadAction.CAMPAIGN);
          setLoading(false);
        }
        return;
      case LeadAction.EDIT_OPTIN:
        if (state.user?.organization.access && [Access.ADMIN, Access.MANAGER, Access.OWNER].indexOf(state.user.organization.access) > -1 && !updateOrganizationData.loading) {
          updateOrganization({
            variables: {
              organizationId: state.user.organization.id,
              organization: {
                messageContent: optinMessageContent
              }
            }
          })
        }
        return;
      case LeadAction.REMOVE:
        removeLeadsFromCampaign({ variables: { leadIds: selectedLeads, campaignId: thisCampaignId } });
        setSelectedLeads([]);
        return;
      case LeadAction.REMOVE_GROUP:
        removeLeadsFromGroup({ variables: { leadIds: selectedLeads, groupId: thisGroupId } });
        setSelectedLeads([]);
        return;
      case LeadAction.GROUP:
        addLeadsToGroup({ variables: { leadIds: selectedLeads, groupId: groupId } });
        return;
      default:
        closeModal();
    }
  }

  const onCancel = () => {
    setLoading(true);
    switch (action) {
      case LeadAction.EDIT_OPTIN:
        setLoading(false);
        if (stateWas) {
          confirmCampaign(stateWas);
        } else {
          closeModal();
        }
        return;
      default:
        return
    }
  }

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginBottom: 20 }}>
      <WpModal isVisible={isVisible} closeModal={closeModal}>
        <ModalBody title={getTitle()} closeModal={closeModal}>
          {renderBody()}
          <ModalFooter>
            {action === LeadAction.EDIT_OPTIN ? <LocalButton style={{ marginTop: 10, marginBottom: 15 }} text="Cancel" theme={Theme.LINK_NEUTRAL} onPress={onCancel} /> : null}
            <Button disabled={isDisabled()} onClick={onConfirm} shape="Pill" size="Default" type={action === LeadAction.ARCHIVE ? "Danger" : "Primary"}>
              {loading || updateOrganizationData.loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>{getButtonText()}</Text>}
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 10, marginBottom: 5 }}>
        {selectedLeads.length > 0 ?
          <View style={styles.controlsWrapper}>
            <View style={styles.controls}>
              <Checkbox color={Constants.colors.darkBlue} onSelect={onClear} selected={true} />
              <Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium' }}>{getSelectedText()}</Text>
            </View>
          </View> : null}
        {thisGroupId === undefined ?
          <IconButton type="White" shape="Circle" onClick={onMessageLeads}>
            <Icon name="message" raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton> : null}
        {thisCampaignId !== undefined ?
          <IconButton type="White" shape="Circle" onClick={removeFromCampaign}>
            <Icon name="campaignMinus" raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton> : null}
        {thisGroupId !== undefined ?
          <IconButton type="White" shape="Circle" onClick={removeFromGroup}>
            <Icon name="usersMinus" raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton> : null}
        {thisGroupId === undefined && (!Constants.oppText || !state.user?.organization.v2 || state.user?.organization?.id === '47') ?
          <IconButton type="White" shape="Circle" onClick={addToCampaign}>
            <Icon name="campaignPlus" raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton> : null}
        {__DEV__ ?
          <IconButton type="White" shape="Circle" onClick={addToCampaign}>
            <Icon name="campaignPlus" raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton> : null}
        {Constants.oppText ? null :
          <IconButton type="White" shape="Circle" onClick={addToGroup}>
            <Icon name="usersPlus" raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton>}
        {thisGroupId === undefined ?
          <IconButton type="White" shape="Circle" onClick={archive}>
            <Icon name="trash" width={20} raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton> : null}
        {onDownload !== undefined ?
          <IconButton type="White" shape="Circle" onClick={onDownload}>
            <Icon name="download" width={20} raw={true} color="currentColor" colorHover="currentColor" />
          </IconButton> : null}
      </View>
      <BillingModal isVisible={isBilling} card={state.user?.organization?.billing?.card} loading={updateUserData.loading} onClose={doneSetupBilling} onComplete={onCompleteBilling} />
      {/*<IconButton type="White" shape="Circle" onClick={archive}>
        <Icon name="download" raw={true} colorHover="#FFF" />
      </IconButton>*/}
      {/* thisCampaignId !== undefined ? <Button key="btn-remove-campaign" style={styles.controlButton} text="Remove From Campaign" width={200} theme={Theme.PRIMARY_MEDIUM} onPress={removeFromCampaign} /> : null }
      { thisGroupId !== undefined ? <Button key="btn-remove-group" style={styles.controlButton} text="Remove From Group" width={200} theme={Theme.PRIMARY_MEDIUM} onPress={removeFromGroup} /> : null }
      <Button key="btn-add-campaign" style={styles.controlButton} text="Add to Campaign" width={150} theme={Theme.SUCCESS} onPress={addToCampaign} />
      <Button key="btn-add-group" style={styles.controlButton} text="Add to Group" width={120} theme={Theme.ACCENT} onPress={addToGroup} />
      <Button key="btn-archive" style={styles.controlButton} text="Archive" width={80} theme={Theme.DANGER} onPress={archive} />*/}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    padding: 8,
    borderRadius: 8,
    width: '100%',
    border: '1px solid ' + Constants.colors.info + '44',
    marginBottom: 10
  },
  controlsWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: Constants.colors.lightBlue,
    alignItems: 'center',
    marginRight: 10
  },
  controls: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controlButton: {
    marginRight: 10,
    marginVertical: 10
  }
});

const cssStyles = {
}
