import { View, Text, ActivityIndicator, StyleSheet, Dimensions } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useParams } from '../../react-router';

import Constants from '../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import ModalFooter from './../ModalFooter';
import CsvDropzone from './../CsvDropzone';
import { Button, Theme } from './../Button';
import Input, { Info, Label } from './../Input';
import UserDropdown from './../UserDropdown';
import CampaignDropdown from './../CampaignDropdown';
import GroupDropdown from './../GroupDropdown';
import { ADD_LEADS_TO_CAMPAIGN, ADD_LEADS_TO_GROUP, CREATE_LEAD, CREATE_LEADS, LEADS_FROM_CSV } from '../../graphql/lead';
import { UserContext, WpUserAction } from '../../contexts/user';
import { useMutation } from "@apollo/client";
import { TrashIcon } from '@heroicons/react/outline';
import Checkbox from './../Checkbox';
import { Dropdown } from '@whistlepitch/wp-components';
import Toast from 'react-native-toast-message';
import LeadDropdown from './LeadDropdown';
import BillingModal from '../User/BillingModal';
import { UPDATE_USER } from '../../graphql/user';
import MessageEditor from '../MessageEditor';

interface Option {
  label: string,
  selected?: boolean,
  value: string
}

const window = Dimensions.get('window');

interface NewLead {
  name: string,
  phone: string,
  campaignId?: string
  groupId?: string
}

const defaultNew: NewLead = {
  name: '',
  phone: ''
}

export default function NewLeadModal({ isVisible, onClose, onCreated, onAdded, campaignId, groupId }: { isVisible: boolean, onClose: Function, onCreated: (ids: string[]) => void, onAdded: (ids: string[]) => void, campaignId?: string, groupId?: string }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);

  const [dimensions, setDimensions] = useState(window)

  const [leads, setLeads] = useState<NewLead[]>([defaultNew])
  const [leadsToAdd, setLeadsToAdd] = useState<string[]>([])
  const [campaignLabel, setCampaignLabel] = useState<string>(Constants.localizations.campaign[0].toUpperCase() + Constants.localizations.campaign.substr(1))
  const [label, setLabel] = useState<string>(Constants.localizations.lead[0].toUpperCase() + Constants.localizations.lead.substr(1))
  const [labels, setLabels] = useState<string>(Constants.localizations.leads[0].toUpperCase() + Constants.localizations.leads.substr(1))
  const [hovering, setHovering] = useState<number>()
  const [csvData, setCsvData] = useState<object[]>()
  const [fullNameKey, setFullNameKey] = useState<string>()
  const [firstNameKey, setFirstNameKey] = useState<string>()
  const [lastNameKey, setLastNameKey] = useState<string>()
  const [phoneKey, setPhoneKey] = useState<string>()
  const [confirm, setConfirm] = useState<boolean>()
  const [settingUpBilling, setSettingUpBilling] = useState<boolean>(false)
  const [addCampaignId, setCampaignId] = useState<string | undefined>(campaignId)
  const [addGroupId, setGroupId] = useState<string | undefined>(groupId)
  const [viewing, setViewing] = useState<"new" | "add">("new")
  const [options, setOptions] = useState<Option[]>()
  const [continueNewLead, setContinueNewLead] = useState<boolean>(false);
  const [welcomeMessageContent, setWelcomeMessageContent] = useState<object>();
  const [createOnly, setCreateOnly] = useState<boolean>(false);

  const [showCsvSettings, setShowCsvSettings] = useState<boolean>(false)

  const [addLeadsToCampaign, addLeadsToCampaignData] = useMutation(ADD_LEADS_TO_CAMPAIGN, {
    fetchPolicy: "no-cache",
  })

  const [addLeadsToGroup, addLeadsToGroupData] = useMutation(ADD_LEADS_TO_GROUP, {
    fetchPolicy: "no-cache",
  })

  const [createLead, createLeadData] = useMutation(CREATE_LEAD, {
    fetchPolicy: "no-cache",
  });

  const [createLeads, { data, loading }] = useMutation(CREATE_LEADS, {
    fetchPolicy: "no-cache",
  });

  const [leadsFromCsv, leadsFromCsvData] = useMutation(LEADS_FROM_CSV, {
    fetchPolicy: "no-cache",
  });

  const [updateUser, updateUserData] = useMutation(UPDATE_USER, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (leadsFromCsvData.data) {
      if (leadsFromCsvData.data.leadsFromCsv.success) {
        Toast.show({
          type: 'success',
          text1: 'CSV Upload',
          text2: `Your ${Constants.localizations.leads} are uploading in the background.`
        });
      } else {
        Toast.show({
          type: 'error',
          text1: 'Error: CSV Upload',
          text2: `Your CSV could not be uploaded. Please try again.`
        });
      }
      handleOnClose();
    }
  }, [leadsFromCsvData.data])

  useEffect(() => {
    if (addLeadsToCampaignData.data || addLeadsToGroupData.data) {
      onAdded(leadsToAdd);
      handleOnClose();
    }
  }, [addLeadsToCampaignData.data, addLeadsToGroupData.data])

  useEffect(() => {
    if (updateUserData.data?.updateUser?.user?.organization) {
      dispatch({ type: WpUserAction.REFRESH_ORGANIZATION, organization: updateUserData.data?.updateUser?.user?.organization })
      doneSetupBilling();
    }
  }, [updateUserData.data])

  useEffect(() => {
    if (csvData) {
      let newOptions: Option[] = [{ label: 'Not Included', value: '' }];
      let availableKeys: String[] = [];

      for (let i = 0; i < csvData.length; i++) {
        let keys = Object.keys(csvData[i]);
        for (let j = 0; j < keys.length; j++) {
          if (availableKeys.indexOf(keys[j]) === -1) {
            availableKeys.push(keys[j])
            newOptions.push({
              label: keys[j],
              value: keys[j]
            });
          }
        }
        if (i > 10) {
          break;
        }
      }

      setOptions(newOptions);
      setShowCsvSettings(true);
    }
  }, [csvData])

  useEffect(() => {
    if (data?.createLeads?.leads) {
      onCreated(data.createLeads.leads);
      handleOnClose();
    }
  }, [data])

  useEffect(() => {
    if (createLeadData.data?.createLead.lead) {
      onCreated([createLeadData.data.createLead.lead.id]);
      handleOnClose();
    }
  }, [createLeadData.data])

  useEffect(() => {
    if (groupId !== addGroupId) {
      setGroupId(groupId)
    }
  }, [groupId])

  const addAnotherLead = () => {
    setLeads([...leads, defaultNew])
  }

  const removeLead = (i: number) => {
    setLeads([
      ...leads.slice(0, i),
      ...leads.slice(i + 1),
    ])
    onHoverOut();
  }

  const handleOnClose = () => {
    setLeads([defaultNew])
    setHovering(undefined);
    setCsvData(undefined);
    setFullNameKey(undefined)
    setFirstNameKey(undefined)
    setLastNameKey(undefined)
    setPhoneKey(undefined)
    // setCampaignId(campaignId)
    setGroupId(groupId)
    setOptions(undefined)
    setViewing('new')
    setConfirm(false)
    onClose();
  }

  const onHover = (i: number) => {
    setHovering(i)
  }

  const onHoverOut = () => {
    setHovering(undefined)
  }

  const onCreateLeads = () => {
    if (Constants.oppText && state.user?.organization.v2) {
      if (!continueNewLead) {
        if (!welcomeMessageContent && state.user?.organization.welcomeMessageContent) {
          setWelcomeMessageContent(state.user?.organization.welcomeMessageContent);
        }
        setContinueNewLead(true);
      } else {
        createLead({
          variables: {
            lead: {
              name: leads[0].name,
              phone: leads[0].phone,
            },
            welcomeMessageContent: welcomeMessageContent
          }
        })
      }
    } else if (addCampaignId && !confirm) {
      setConfirm(true)
    } else {
      createLeads({
        variables: {
          leads: leads,
          campaignId: addCampaignId,
          groupId: addGroupId
        }
      })
    }
  }

  const setName = (val: string, i: number) => {
    setLeads([
      ...leads.slice(0, i),
      {
        ...leads[i],
        name: val
      },
      ...leads.slice(i + 1),
    ])
  }

  const setPhone = (val: string, i: number) => {
    setLeads([
      ...leads.slice(0, i),
      {
        ...leads[i],
        phone: val
      },
      ...leads.slice(i + 1),
    ])
  }

  const setLeadCampaignId = (val: string | undefined, i: number) => {
    setLeads([
      ...leads.slice(0, i),
      {
        ...leads[i],
        campaignId: val
      },
      ...leads.slice(i + 1),
    ])
  }

  const setLeadGroupId = (val: string, i: number) => {
    setLeads([
      ...leads.slice(0, i),
      {
        ...leads[i],
        groupId: val
      },
      ...leads.slice(i + 1),
    ])
  }

  const handleDropdownChange = (which: "full" | "first" | "last" | "phone", value?: any) => {
    if (which === "full") {
      setFullNameKey(value)
    } else if (which === "first") {
      setFirstNameKey(value)
    } else if (which === "last") {
      setLastNameKey(value)
    } else if (which === "phone") {
      setPhoneKey(value)
    }
  }

  const onConfirm = () => {
    if (viewing === 'add') {
      onAddLeads();
    } else if (showCsvSettings) {
      onConfirmCsv();
    } else {
      onCreateLeads();
    }
  }

  const onConfirmCsv = () => {
    if (addCampaignId && !confirm) {
      setConfirm(true)
    } else {
      leadsFromCsv({
        variables: {
          leads: csvData,
          campaignId: addCampaignId,
          groupId: addGroupId,
          fullNameKey,
          lastNameKey,
          firstNameKey,
          phoneKey
        }
      })
    }
  }

  const viewNewLead = () => {
    setViewing('new')
  }

  const viewAddLead = () => {
    setViewing('add')
  }

  const onAddLeads = () => {
    if (addCampaignId && !confirm) {
      setConfirm(true);
    } else if (leadsToAdd.length) {
      if (addCampaignId) {
        addLeadsToCampaign({ variables: { campaignId: addCampaignId, leadIds: leadsToAdd } });
      } else if (addGroupId) {
        addLeadsToGroup({ variables: { groupId: addGroupId, leadIds: leadsToAdd } });
      }
      setLeadsToAdd([])
    }
  }

  const onSelectLead = (value: string | string[]) => {
    if (value) {
      if (Array.isArray(value)) {
        setLeadsToAdd(value)
      } else {
        setLeadsToAdd([value])
      }
    }
  }

  const onSetupBilling = () => {
    setSettingUpBilling(true)
  }

  const doneSetupBilling = () => {
    setSettingUpBilling(false)
  }

  const onCompleteBilling = (token?: string, plan?: string) => {
    if (state.user) {
      updateUser({
        variables: {
          userId: state.user.id,
          user: {
            token: token,
            plan: plan
          }
        }
      })
    }
  }

  const getModalTitle = () => {
    if (!state.user?.organization.billing?.active && confirm) {
      return 'Billing Required'
    } else {
      return `Add ${Constants.localizations.Leads}`
    }
  }

  const renderLead = (lead: NewLead, i: number) => {
    return (
      <View key={`lead-${i}`} style={{ gap: 10, position: 'relative', zIndex: 100 - i, marginBottom: 25 }}>
        <View key={`lead-${i}-settings`} style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', gap: 10 }}>
          <Input key={`lead-name-${i}`} style={{ flex: 1 }} name={`${label} Full Name`} value={lead.name} placeholder="Enter Full Name..." setValue={(val: string) => setName(val, i)} />
          <Input key={`lead-phone-${i}`} style={{ flex: 1 }} name={`${label} Phone`} value={lead.phone} placeholder="Enter Phone..." setValue={(val: string) => setPhone(val, i)} />
          <TrashIcon style={{ ...cssStyles.icon, cursor: 'pointer', color: hovering === i ? Constants.colors.danger : Constants.colors.info }} onClick={() => removeLead(i)} onMouseEnter={() => onHover(i)} onMouseLeave={onHoverOut} />
        </View>
      </View>
    )
  }

  const renderNewLead = () => {
    if (viewing === "new") {
      if (Constants.oppText && state.user?.organization.v2) {
        const lead = leads[0] ?? defaultNew;
        return (
          <View key={`new-lead`} style={{ gap: 10, position: 'relative', zIndex: 100, marginBottom: 25 }}>
            <Input key={`new-lead-name`} style={{ flex: 1 }} name={`${label} Full Name`} value={lead.name} placeholder="Jane Doe" setValue={(val: string) => setName(val, 0)} />
            <Input key={`new-lead-phone`} style={{ flex: 1 }} name={`${label} Phone`} value={lead.phone} placeholder="(801) 235-1010" setValue={(val: string) => setPhone(val, 0)} />
            {continueNewLead && !createOnly ? <Label style={{ marginTop: 5, marginBottom: 0 }} name="Welcome Message" /> : null}
            {continueNewLead && !createOnly ? <Info info="This message will be sent to the new lead as soon as they are created." /> : null}
            {continueNewLead && !createOnly ? <MessageEditor placeholderText='Type your welcome message here...' messageContent={welcomeMessageContent} includePlaceholders={false} setMessageContent={setWelcomeMessageContent} setMessage={() => { }} /> : null}

            {continueNewLead ?
              <View style={{ position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Checkbox key={`org-incoming-leads`} style={{ marginLeft: 5, marginRight: 10 }} selected={createOnly} onSelect={setCreateOnly} />
                <Text style={{ fontFamily: 'Gotham', color: Constants.colors.dark }}>
                  Create only (don't send a welcome message)
                </Text>
              </View> : null}
            <Button style={{ marginTop: 25, alignSelf: 'center' }} key="btn-new-leads-confirm" disabled={!lead.phone || !lead.name} loading={createLeadData.loading} width={175} text={continueNewLead ? `Create ${labels}` : 'Continue'} onPress={onCreateLeads} />
          </View>
        )
      } else {
        return (
          <View style={{ flex: 1, position: 'relative' }}>
            {leads.map((lead, i) => renderLead(lead, i))}
            <Text style={{ marginBottom: 25, color: Constants.colors.blue, fontFamily: 'GothamMedium' }} onPress={addAnotherLead}>+ Add Another {label}</Text>
            {!groupId && !campaignId ?
              <View style={{ paddingBottom: 10, position: 'relative', zIndex: 6 }}>
                <CampaignDropdown label={Constants.blastView ? "Send Blast" : "Add to Campaign"} instantOnly={true} optional={true} value={addCampaignId} setValue={setCampaignId} />
              </View> : null}
            {!groupId && !campaignId && !Constants.oppText ?
              <View style={{ paddingBottom: 10, position: 'relative', zIndex: 5 }}>
                <GroupDropdown label="Add to Group" optional={true} value={addGroupId} setValue={setGroupId} />
              </View> : null}
            {Constants.oppText ? null :
              <View key="dropzone" style={{ flex: 1, marginTop: 25 }}>
                <CsvDropzone setData={setCsvData} />
              </View>}
            <ModalFooter key="controls">
              <Button key="btn-new-leads-confirm" loading={loading} width={175} text={`Create ${labels}`} onPress={onCreateLeads} />
            </ModalFooter>
          </View>
        )
      }
    }
    return null
  }

  const renderAddLead = () => {
    if (viewing === "add") {
      return (
        <View style={{ flex: 1, position: 'relative' }}>
          <LeadDropdown style={{ marginBottom: 20, zIndex: 7, position: 'relative' }} onClose={() => { }} value={leadsToAdd} campaignId={campaignId} groupId={groupId} setValue={onSelectLead} />
          {!groupId && !campaignId ?
            <View style={{ paddingBottom: 10, position: 'relative', zIndex: 6 }}>
              <CampaignDropdown label={Constants.blastView ? "Send Blast" : "Add to Campaign"} optional={true} value={addCampaignId} setValue={setCampaignId} />
            </View> : null}
          {!groupId && !campaignId ?
            <View style={{ paddingBottom: 10, position: 'relative', zIndex: 5 }}>
              <GroupDropdown label="Add to Group" optional={true} value={addGroupId} setValue={setGroupId} />
            </View> : null}
          <ModalFooter key="controls">
            <Button key="btn-new-leads-confirm" loading={loading} width={175} text={`Add ${labels}`} disabled={leadsToAdd.length === 0 || (!addCampaignId && !addGroupId)} onPress={onAddLeads} />
          </ModalFooter>
        </View>
      )
    }
    return null
  }

  const renderBody = () => {
    if (showCsvSettings && !confirm) {
      return (
        <View style={{ flex: 1, position: 'relative', zIndex: 1 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10, position: 'relative', zIndex: 10 }}>
            <Text style={styles.label}>Full Name</Text>
            <Dropdown
              defaultLabel={"Select Column"}
              onChange={(e?: any) => handleDropdownChange("full", e)}
              structure={options}
            />
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10, position: 'relative', zIndex: 9 }}>
            <Text style={styles.label}>First Name</Text>
            <Dropdown
              defaultLabel={"Select Column"}
              onChange={(e?: any) => handleDropdownChange("first", e)}
              structure={options}
            />
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10, position: 'relative', zIndex: 8 }}>
            <Text style={styles.label}>Last Name</Text>
            <Dropdown
              defaultLabel={"Select Column"}
              onChange={(e?: any) => handleDropdownChange("last", e)}
              structure={options}
            />
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10, position: 'relative', zIndex: 7 }}>
            <Text style={styles.label}>Phone</Text>
            <Dropdown
              defaultLabel={"Select Column"}
              onChange={(e?: any) => handleDropdownChange("phone", e)}
              structure={options}
            />
          </View>
          {!groupId && !campaignId ?
            <View style={{ padding: 10, position: 'relative', zIndex: 6 }}>
              <CampaignDropdown label={Constants.blastView ? "Send Blast" : "Add to Campaign"} optional={true} value={addCampaignId} setValue={setCampaignId} />
            </View> : null}
          {!groupId && !campaignId ?
            <View style={{ padding: 10, position: 'relative', zIndex: 5 }}>
              <GroupDropdown label="Add to Group" optional={true} value={addGroupId} setValue={setGroupId} />
            </View> : null}
          <ModalFooter>
            <Button loading={leadsFromCsvData?.loading} key="btn-add-leads-csv-confirm" text="Confirm" onPress={onConfirmCsv} />
          </ModalFooter>
        </View>
      )
    } else if (confirm) {
      if (state.user?.organization.billing.active) {
        return (
          <View style={{ flex: 1, position: 'relative' }}>
            <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>Adding a {Constants.localizations.lead} to a {Constants.localizations.campaign} will start the process of sending out each of your messages. Make certain your {Constants.localizations.campaign} is fully set up how you want it before adding any {Constants.localizations.leads}.</Text>
            <Text style={{ fontFamily: 'GothamMedium', color: '#999', marginTop: 20, paddingHorizontal: 10 }}>Note: Messages are only sent out between 8am-8pm MST. If you try to send a message outside of these times, our servers will wait until 8am the following morning to send them out.</Text>
            <ModalFooter key="controls">
              <Button key="btn-leads-confirm" loading={loading || addLeadsToCampaignData.loading || addLeadsToGroupData.loading || leadsFromCsvData.loading} width={175} text="Confirm" onPress={onConfirm} />
            </ModalFooter>
          </View>
        )
      } else {
        return (
          <View style={{ flex: 1, position: 'relative' }}>
            <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>You can't send out campaigns or any messages without an active plan.</Text>
            <ModalFooter key="controls">
              <Button key="btn-leads-billing" loading={loading} width={175} text="Setup Billing" onPress={onSetupBilling} />
            </ModalFooter>
          </View>
        )
      }
    } else {
      return (
        <View style={{ flex: 1, position: 'relative' }}>
          {Constants.oppText ? null :
            <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginBottom: 30 }}>
              <Text onPress={viewNewLead} style={viewing === "new" ? styles.controlActive : styles.control}>New {label}</Text>
              <Text onPress={viewAddLead} style={viewing === "add" ? styles.controlActive : styles.control}>Existing {labels}</Text>
            </View>}
          {renderNewLead()}
          {renderAddLead()}
        </View>
      )
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <WpModal isVisible={isVisible} closeModal={handleOnClose}>
        <ModalBody title={getModalTitle()} closeModal={handleOnClose} width={500}>
          {renderBody()}
        </ModalBody>
      </WpModal>

      <BillingModal isVisible={settingUpBilling} loading={updateUserData.loading} onClose={doneSetupBilling} onComplete={onCompleteBilling} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  label: {
    fontSize: 16,
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium'
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1,
    width: 170,
    textAlign: 'center'
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1,
    width: 170,
    textAlign: 'center'
  },
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    alignSelf: 'flex-start',
    marginTop: 30
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
