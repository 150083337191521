import React from 'react';
import { Access } from './../contexts/user'

export const newDate = (value = '') => {
  var field = value.match(/^([+-]?\d{4}(?!\d\d\b))(?:-?(?:(0[1-9]|1[0-2])(?:-?([12]\d|0[1-9]|3[01]))?)(?:[T\s](?:(?:([01]\d|2[0-3])(?::?([0-5]\d))?|24\:?00)([.,]\d+(?!:))?)?(?::?([0-5]\d)(?:[.,](\d+))?)?([zZ]|([+-](?:[01]\d|2[0-3])):?([0-5]\d)?)?)?)?$/) || [];
  var result = new Date(field[1], field[2] - 1 | 0, field[3] || 1, field[4] | 0, field[5] | 0, field[7] | 0, field[8] | 0);
  result.setUTCMinutes(0 - result.getTimezoneOffset() - ((field[10] * 60 + +field[11]) || 0));

  // result.setMinutes( result.getMinutes() - 12 );
  return result;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export const stringToDate = (dateString: string) => {
  let d = new Date(dateString.replace(' ', 'T') + 'Z')
  return d;
}

export const formatDateString = (dateString: string, seconds?: boolean) => {
  // let d = newDate(dateString);
  let d = new Date(dateString.replace(' ', 'T') + 'Z')
  let month = d.toString().split(' ')[1];
  let date = d.getDate();
  let hours = d.getHours();
  let time = hours >= 12 ? 'PM' : 'AM';
  hours = hours > 12 ? hours % 12 : hours;
  let minutes = `0${d.getMinutes()}`
  let seconds_str = '';
  if (seconds) {
    seconds_str = `0${d.getSeconds()}`
  }
  return `${month} ${date}, ${hours}:${minutes.substr(minutes.length - 2, 2)}${seconds ? ':' : ''}${seconds_str.substr(seconds_str.length - 2, 2)}${time}`;
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDashboardOptions = (role: Access) => {
  return [Access.ADMIN, Access.OWNER].indexOf(role) > -1 ? [
    { id: 'users', label: 'Users', value: 'users' },
    { id: 'account', label: 'Account', value: 'account' },
  ] : [
    { id: 'users', label: 'Users', value: 'users' },
    { id: 'account', label: 'Account', value: 'account' },
  ]
};
