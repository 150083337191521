import { View, StyleSheet } from 'react-native';
import React, { useEffect } from 'react';

import { useNavigate } from '../react-router';
import Spinner from '../components/Spinner';


export default function ClearOldToken(): JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('old-user-token')
    localStorage.removeItem('token')
    navigate('/login')
  }, [])

  return (
    <View style={{ flex: 1 }}><Spinner /></View>
  );
}

const styles = StyleSheet.create({
  container: {}
})
