import { View, Text, Image, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import Constants from './../../constants';


export default function Footer(): JSX.Element {

  return (
    <View style={styles.footer}>
      <Text style={[styles.text, { color: Constants.colors.secondary }]}>Powered By </Text>
      <Text style={[styles.text, { color: Constants.colors.primary }]}>{Constants.brand} </Text>
      <MaterialIcons name="copyright" size={12} color={Constants.colors.secondary} />
      <Text style={[styles.text, { color: Constants.colors.secondary }]}> 2022</Text>
      <Text style={[styles.text, { color: Constants.colors.secondary }]}> ({Constants.version})</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    marginTop: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 15,
    opacity: 0.7
  },
  brand: {
    height: 30
  },
  text: {
    fontWeight: 'bold',
    fontSize: 10
  }
})
