import { View, Text, Dimensions, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from '../react-router';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import Constants from '../constants';
import { CampaignContext, WpCampaignAction, campaignReducer } from '../contexts/campaign';
import { UserContext } from '../contexts/user';
import { GET_GROUP, GET_LEAD, GET_LEADS } from '../graphql/queries';
import CampaignOverview from '../components/CampaignOverview';
import CampaignEditor from '../components/Campaign/CampaignEditor';
import Navbar from '../components/Navbar';
import { Avatar, Button, DashboardNav, IconButton, Input } from '@whistlepitch/wp-components';
import LeadTable from '../components/Leads/LeadTable';
import Spinner from '../components/Spinner';
import { WpGroup, WpLead, WpLeadBasic, WpLeadMessage } from '../contexts/lead';
import NewLeadModal from '../components/Leads/NewLeadModal';
import NewButton from '../components/NewButton';
import { MESSAGE_LEAD, UPDATE_GROUP } from '../graphql/lead';
import UserDetailsModal from '../components/User/UserDetailsModal';
import GroupLandingPageModal from '../components/Leads/GroupLandingPageModal';
import { FontAwesome } from '@expo/vector-icons';
import BasicLayout from '../components/Shared/BasicLayout';
import MessageFeed from '../components/Shared/MessageFeed';
import Heading from '../components/Shared/Heading';
import Icon from '../components/Icon';
import WpModal from '../components/WpModal';
import ModalBody from '../components/ModalBody';
import { UPDATE_ORGANIZATION } from '../graphql/user';
import UserDropdown from '../components/UserDropdown';

const window = Dimensions.get('window');

export default function Messages(): JSX.Element {
  const { state } = React.useContext(UserContext);

  const [leads, setLeads] = useState<WpLeadBasic[]>([]);
  const [lead, setLead] = useState<WpLead>();
  const [showAlertIds, setShowAlertIds] = useState<boolean>(false);
  const [alertIds, setAlertIds] = useState<string[]>([]);
  const [group, setGroup] = useState<WpGroup>();
  const [viewed, setViewed] = useState<string[]>([]);
  const [sendingMessage, setSendingMessage] = useState<boolean>()
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.width < 768);
  const [name, setName] = useState<string>();
  const [showNewLead, setShowNewLead] = useState<boolean>(false);
  const [reload, setReload] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [showLandingPage, setShowLandingPage] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [newMessage, setNewMessage] = useState<string>()
  const [messageKey, setMessageKey] = useState<number>(0)
  const [queuedMessages, setQueuedMessages] = useState<{ [id: string]: WpLeadMessage | undefined }>({})

  const params = useParams();
  const navigate = useNavigate();

  const [getLeads, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_LEADS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });
  const [getLead, getLeadData] = useLazyQuery(GET_LEAD, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });
  const [messageLead, messageLeadData] = useMutation(MESSAGE_LEAD, {
    fetchPolicy: "no-cache",
  });
  const [updateOrganization, updateOrganizationData] = useMutation(UPDATE_ORGANIZATION, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    loadLeads();
  }, [reload])

  useEffect(() => {
    if (data?.leads?.success) {
      setLeads(data.leads.leads)
    }
  }, [data])

  useEffect(() => {
    if (getLeadData.data?.lead?.success) {
      setLead(getLeadData.data.lead.lead)
      if (newMessage) {
        setTimeout(() => {
          setSendingMessage(false);
          setNewMessage(undefined);
          setMessageKey(messageKey + 1);
        }, 1500)
      }
    }
  }, [getLeadData.data])

  useEffect(() => {
    if (lead) {
      let queuedMessage = queuedMessages?.[lead.id];

      // Get the last (up to) 3 messages from lead.messages
      const lastThree = lead.messages.slice(Math.max(lead.messages.length - 3, 0));

      // Check if queuedMessage exists and has a message property before proceeding
      if (queuedMessage && queuedMessage.message) {
        // Check if queuedMessage.message matches any of the last three lead messages
        const isMessageLoaded = lastThree.some(leadMessage => leadMessage.message === queuedMessage.message);

        // If a match is found, clear the queuedMessage for this lead
        if (isMessageLoaded) {
          setQueuedMessages(prevQueuedMessages => ({
            ...prevQueuedMessages,
            [lead.id]: undefined,
          }));
        }
      }
    }
  }, [lead]);

  useEffect(() => {
    if (lead) {
      if (messageLeadData.data?.messageLead?.message) {
        setQueuedMessages({
          ...queuedMessages,
          [lead.id]: messageLeadData.data.messageLead.message
        })
      }

      loadLead(lead.id)
    }
  }, [messageLeadData.data])

  useEffect(() => {
    if (state.user?.organization.messageAlertIds) {
      setAlertIds(state.user?.organization.messageAlertIds)
    }
  }, [state.user?.organization.messageAlertIds])

  useEffect(() => {
    if (updateOrganizationData.data?.updateOrganization?.success) {
      setShowAlertIds(false);
    }
  }, [updateOrganizationData.data])

  const loadLeads = () => {
    if (data && leads) {
      refetch({ page: 0, leadFilter: { messages: true } });
    } else {
      getLeads({ variables: { page: 0, leadFilter: { messages: true } } })
    }
  }

  const loadLead = (leadId: string) => {
    if (leadId) {
      if (viewed.length) {
        setViewed([...viewed, leadId])
      } else {
        setViewed([leadId])
      }
      getLead({ variables: { leadId: leadId, messagesOnly: true } })
      setLead(undefined)
    }
  }

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const onEditLandingPage = () => {
    setShowLandingPage(true);
  }

  const doneEditLandingPage = () => {
    setShowLandingPage(false);
    setReload(reload + 1);
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const onOpenNewLead = () => {
    setShowNewLead(true)
  }

  const onNewLead = (ids: string[]) => {
    if (ids && ids.length) {
      setRefresh(refresh + 1);
    }
    onCloseNewLead();
  }

  const onCloseNewLead = () => {
    setShowNewLead(false);
  }

  const changeMessage = (e: any) => {
    setNewMessage(e.target.value)
  }

  const onSendMessage = () => {
    if (lead) {
      setSendingMessage(true);
      messageLead({
        variables: {
          leadId: lead.id,
          message: newMessage
        }
      })
    }
  }

  const clearLead = () => {
    setLead(undefined)
    setNewMessage(undefined)
  }

  const onManageAlertIds = () => {
    setShowAlertIds(true);
  }

  const onSaveAlertIds = () => {
    if (state.user?.organization.id) {
      updateOrganization({
        variables: {
          organizationId: state.user.organization.id,
          organization: {
            messageAlertIds: alertIds
          }
        }
      })
    } else {
      setShowAlertIds(false);
    }
  }

  const onLayout = () => {
    if (Dimensions.get('window').width < 768) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }

  const onSearch = (val?: string) => {
    if (val) {
      setSearch(val);
    } else {
      setSearch('');
    }
  }

  const renderLead = (basicLead: WpLeadBasic, index: number, array: WpLeadBasic[]) => {
    if (search && basicLead.name.toLowerCase().indexOf(search.toLowerCase()) === -1 && basicLead.phone.toLowerCase().indexOf(search.toLowerCase()) === -1) {
      return null;
    }

    let leadName = basicLead.name;
    // if (Constants.oppText && state?.user?.organization?.id === "29") {
    //   leadName = `${Constants.localizations.Lead} ${index + 1}`;
    // }

    let active = lead && lead.id == basicLead.id;
    let isLastElement = index === array.length - 1;
    return (
      <View
        key={`lead-${basicLead.id}`}
        style={{
          backgroundColor: active ? Constants.colors.blue : undefined,
          cursor: 'pointer',
          padding: 10,
          alignItems: 'center',
          borderBottomWidth: isLastElement && isSmallScreen ? 0 : 1,  // No border for the last element
          borderBottomColor: Constants.colors.secondaryLight,
          flexDirection: 'row',
          width: '100%'
        }}
        onClick={() => loadLead(basicLead.id)}
      >
        <span id={`avatar`} key={`avatar`} className={active ? "wp-avatar-faded" : ""}>
          <Avatar name={leadName} />
        </span>

        <Text style={{ paddingLeft: 10, color: active ? 'white' : Constants.colors.dark }}>
          {leadName}
        </Text>
        {!basicLead.lastViewed && viewed.indexOf(basicLead.id) === -1 ? (
          <FontAwesome style={{ marginLeft: 10 }} name="circle" color={Constants.colors.red} />
        ) : null}
      </View>
    );
  };

  const renderMessages = () => {
    if (lead && state.user) {
      return (
        <View style={{ flex: 1 }}>
          <View style={{ flex: isSmallScreen ? 3 : 4 }}>
            <MessageFeed masked={Constants.oppText && state.user.wpAdmin && state.user.organization.id !== "1"} messages={lead.messages} queuedMessage={queuedMessages?.[lead.id]} backgroundColor={'white'} />
          </View>
          {state.user?.organization?.autoReplyEnabled ? null :
            <View style={{ flex: 1, padding: 25 }}>
              <Input key={`message-input-${messageKey}`} style={{ marginTop: 20, marginBottom: 20 }} placeholder="Send Message" defaultValue={""} name="new-message" onChange={changeMessage} onInput={changeMessage} disabled={sendingMessage} />

              <Button type="Primary" size="Default" shape="Pill" onClick={onSendMessage} disabled={!newMessage}>
                {sendingMessage ? <Spinner style={{ marginHorizontal: 15 }} color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Send</Text>}
              </Button>
            </View>}
        </View>
      )
    } else if (getLeadData.loading) {
      return <Spinner style={{ marginTop: 40 }} />
    } else {
      return <Text key="messages-not-found" style={{ flex: 1, textAlign: 'center', color: Constants.colors.secondary, fontSize: 14, marginTop: 40 }}>Select a {Constants.localizations.lead} to see their messages.</Text>
    }
  }

  const renderBody = () => {
    if (!leads) {
      return (
        <View style={{ flex: 1 }}>
          <Text key="messages-not-found" style={{ flex: 1, textAlign: 'center', color: Constants.colors.secondary, fontSize: 14 }}>No Messages Found</Text>
          <Text key="messages-not-found-subtext" style={{ flex: 1, textAlign: 'center', color: Constants.colors.info }}>Messages will appear here once a lead replies to one of your campaigns.</Text>
        </View>
      )
    } else if (loading || !state.user) {
      return <Spinner />
    } else {
      return (
        <View style={{ flex: 1 }} onLayout={onLayout}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 25 }}>
            <Heading size="large" text="Messages" />
            <IconButton style={{ marginLeft: 'auto', marginRight: 15 }} type="White" shape="Circle" onClick={onManageAlertIds}>
              <Icon width={20} height={20} name="bell" color="currentColor" colorHover="currentColor" />
            </IconButton>
          </View>
          {isSmallScreen && lead ?
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 25 }}>
              <span style={{ fontFamily: 'GothamBold', color: Constants.colors.darkBlue }}>{lead.name}</span>
              <span style={{ color: Constants.colors.secondary, cursor: 'pointer' }} onClick={clearLead}>X Close</span>
            </View> : null}
          <View style={{ flexDirection: 'row', flex: 1, height: 600 }}>
            {isSmallScreen && (lead || getLeadData.loading) ? null :
              <div id="leads" className="no-scroll-width" style={{ flex: 1, backgroundColor: 'white', borderRadius: 5, height: isSmallScreen ? undefined : 600, overflow: 'scroll', marginRight: 15 }}>
                {leads.map(renderLead)}
              </div>}
            {isSmallScreen && !lead && !getLeadData.loading ? null :
              <View key="message-feed" style={{ flex: 3, borderRadius: 5, backgroundColor: 'white', height: isSmallScreen ? 500 : 600 }}>
                {renderMessages()}
              </View>}
          </View>
        </View>
      )
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/messages'} onSearch={onSearch}>
      {renderBody()}
      <WpModal key="modal-card" isVisible={showAlertIds}>
        <ModalBody title={"Edit Group Landing Page"} closeModal={setShowAlertIds}>
          <UserDropdown style={{ marginBottom: 20 }} label="Message Notification Users" info="Users that should receive incoming message notifications. These sms alerts will count toward your total usage." value={alertIds} setValue={setAlertIds} filterSelf={false} />

          <Button type="Primary" size="Default" shape="Pill" onClick={onSaveAlertIds} disabled={updateOrganizationData.loading}>
            {updateOrganizationData.loading ? <Spinner style={{ marginHorizontal: 15 }} color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Save</Text>}
          </Button>
        </ModalBody>
      </WpModal>
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
});
