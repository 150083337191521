import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import { Button } from './Button';
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { SketchPicker } from 'react-color';

export default function SettingsDots({ children } : { children:JSX.Element|JSX.Element[] }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const showSettings = () => {
    setSettingsVisible(true);
  }

  const hideSettings = () => {
    setSettingsVisible(false);
  }

  return (
    <View style={styles.container}>
      <DotsHorizontalIcon style={{...cssStyles.icon, opacity: hovering ? 0.3 : 1}} onClick={showSettings} />
      { settingsVisible ?
        <View style={styles.tooltip}>
          { children }
        </View> : null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 2,
    position: 'absolute',
    top: 30,
    right: -30
  },
  tooltip: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 20,
    height: 20,
    color: Constants.colors.info,
    cursor: 'pointer'
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
