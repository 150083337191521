import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import Input from './Input';
import { Button, Theme } from './Button';
import { TrashIcon } from '@heroicons/react/outline'
import NewButton from './NewButton';


export default function SortableLabels({ labels, setLabels, style={} } : { labels:string[], setLabels:Function, style?: object }): JSX.Element {
  const [hovering, setHovering] = useState<number>();
  const [localLabels, setLocalLabels] = useState<string[]>(['', ''])

  useEffect(() => {
    if (labels && labels.length) {
      if (labels.length === 1) {
        setLocalLabels([...labels, ''])
      } else {
        setLocalLabels(labels)
      }
    } else {
      setLocalLabels(['', ''])
    }
  }, [labels])

  const onHover = (index:number) => {
    setHovering(index);
  }

  const onHoverOut = () => {
    setHovering(undefined);
  }

  const onSetValue = (val:string, index:number) => {
    setLabels([
      ...labels.slice(0, index),
      val,
      ...labels.slice(index + 1)
    ]);
  }

  const addLabel = () => {
    setLabels([...labels, '']);
  }

  const onDelete = (index:number) => {
    setLabels([
      ...labels.slice(0, index),
      ...labels.slice(index + 1)
    ]);
  }

  return (
    <View style={[{ flex: 1 }, style]}>
      {
        localLabels.map(( label, index, list ) => {
          return (
            <View key={`sortable-option-${index}`} style={styles.inputWrapper}>
              <Input style={{ flex: 1, width: '100%', marginBottom: 10 }} value={label} placeholder={"Enter option..."} setValue={(val:string) => onSetValue(val, index)} />
              <TrashIcon style={{...cssStyles.trash, color: hovering === index ? Constants.colors.danger : Constants.colors.info }} onClick={() => onDelete(index)} onMouseEnter={() => onHover(index)} onMouseLeave={onHoverOut} />
            </View>
          )
        })
      }
      <NewButton style={{ marginTop: 10 }} name="Option" small={true} onClick={addLabel} />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7
  },
  inputWrapper: {
    flexDirection: 'row',
    width: '100%',
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  trash: {
    cursor: 'pointer',
    width: 15,
    height: 15,
    marginLeft: 10,
    marginTop: 10
  }
}
