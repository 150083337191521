import { LayoutChangeEvent, Text, View, TextInput, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../../constants';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import { Label, Info } from './../Input';
import Checkbox from './../Checkbox';
import { GET_LEADS } from './../../graphql/queries';
import { useLazyQuery } from "@apollo/client";
import { Ionicons } from '@expo/vector-icons';
import { EyeIcon, ClockIcon, PuzzleIcon, ReceiptRefundIcon, ShieldCheckIcon, CalendarIcon, VideoCameraIcon, LockClosedIcon, BanIcon } from '@heroicons/react/outline';
import Icon from '../Icon';

export enum Required {
  VERIFY = "VERIFY",
  VIDEO = "VIDEO",
  RESPONSE = "RESPONSE",
  CALENDAR = "CALENDAR",
  REDIRECT = "REDIRECT",
  UNOPENED = "UNOPENED",
  OPTEDOUT = "OPTEDOUT",
}


export default function LeadReportIcon({ type, initials, complete } : { type?: Required, initials?: string, complete?: boolean }): JSX.Element {
  const getColor = () => {
    if (complete === undefined) {
      return Constants.colors.secondary
    }

    switch (type) {
      case (Required.VERIFY):
        return Constants.colors.blue
      case (Required.VIDEO):
        return Constants.colors.darkBlue
      case (Required.OPTEDOUT):
        return Constants.colors.yellow
      case (Required.RESPONSE):
        return Constants.colors.purple
      case (Required.CALENDAR):
        return Constants.colors.pink
      case (Required.REDIRECT):
        return Constants.colors.yellow
      case (Required.UNOPENED):
        return Constants.colors.danger
      default:
        return Constants.colors.blue
    }
  }

  const getIcon = () => {
    switch (type) {
      case (Required.VERIFY):
        return <ShieldCheckIcon style={{...cssStyles.reportIcon, backgroundColor: getColor()}} />
      case (Required.VIDEO):
        return <Icon style={{...cssStyles.reportIcon, paddingLeft: 6, backgroundColor: getColor()}} name="video" color="white" width={14} height={19} />
      case (Required.RESPONSE):
        return <PuzzleIcon style={{...cssStyles.reportIcon, backgroundColor: getColor()}} />
      case (Required.CALENDAR):
        return <CalendarIcon style={{...cssStyles.reportIcon, backgroundColor: getColor()}} />
      case (Required.UNOPENED):
        return <LockClosedIcon style={{...cssStyles.reportIcon, backgroundColor: getColor()}} />
      case (Required.OPTEDOUT):
        return <BanIcon style={{...cssStyles.reportIcon, backgroundColor: getColor()}} />
      case (Required.REDIRECT):
        return <ReceiptRefundIcon style={{...cssStyles.reportIcon, backgroundColor: getColor(), transform: 'scaleX(-1)'}} />
      default:
        return <PuzzleIcon style={{...cssStyles.reportIcon, backgroundColor: getColor()}} />
    }
  }

  return (
    <View style={{ position: 'relative' }}>
      { type ? getIcon() : <Text style={{...cssStyles.reportIcon, backgroundColor: Constants.colors.primary, fontSize: 10, fontWeight: 'bold', paddingTop: 6, marginTop: 4 }}>{initials}</Text> }
      { complete ? <Ionicons style={styles.completeIcon} name="checkmark-circle" color={Constants.colors.lightGreen} size={14} /> : null }
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 8,
    width: '100%',
    border: '1px solid ' + Constants.colors.info + '44',
    marginBottom: 10,
    backgroundColor: '#FFF',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    height: 45
  },
  item: {
    width: 175,
  },
  lastActive: {
    color: Constants.colors.info,
    fontSize: 10
  },
  reportIcon: {
    width: 14,
    height: 14,
    padding: 5,
    marginBottom: 5,
    borderRadius: 12,
    textAlign: 'center',
    color: '#FFF'
  },
  completeIcon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  }
});

const cssStyles = {
  reportIcon: {
    width: 26,
    height: 26,
    padding: 5,
    marginBottom: 5,
    borderRadius: 13,
    textAlign: 'center',
    color: '#FFF',
    marginRight: 5
  }
}
