import {
    BlockNoteEditor,
    BlockSchema,
    DefaultBlockSchema,
    SlashMenuProsemirrorPlugin,
    SuggestionsMenuState,
  } from "@blocknote/core";
  import Tippy from "@tippyjs/react";
  import React, { FC, useEffect, useMemo, useRef, useState } from "react";
  import { ReactSlashMenuItem, DefaultSlashMenu } from "@blocknote/react";

  
  export type SlashMenuProps<BSchema extends BlockSchema = DefaultBlockSchema> =
    Pick<SlashMenuProsemirrorPlugin<BSchema, any>, "itemCallback"> &
      Pick<
        SuggestionsMenuState<ReactSlashMenuItem<BSchema>>,
        "filteredItems" | "keyboardHoveredItemIndex"
      >;
  
  export const SlashMenuPositioner = <
    BSchema extends BlockSchema = DefaultBlockSchema
  >(props: {
    editor: BlockNoteEditor<BSchema>;
    slashMenu?: FC<SlashMenuProps<BSchema>>;
    parentId?: string;
  }) => {
    const [show, setShow] = useState<boolean>(false);
    const [parent, setParent] = useState<HTMLElement | null>(null);

    const [filteredItems, setFilteredItems] =
      useState<ReactSlashMenuItem<BSchema>[]>();
    const [keyboardHoveredItemIndex, setKeyboardHoveredItemIndex] =
      useState<number>();
  
    const referencePos = useRef<DOMRect>();
  
    useEffect(() => {
      return props.editor.slashMenu.onUpdate((slashMenuState) => {
        setShow(slashMenuState.show);
        setFilteredItems(slashMenuState.filteredItems);
        setKeyboardHoveredItemIndex(slashMenuState.keyboardHoveredItemIndex);
  
        referencePos.current = slashMenuState.referencePos;
      });
    }, [props.editor]);

    useEffect(() => {
      if (props.parentId && !parent) {
        const parentElement = document.getElementById(props.parentId);
        setParent(parentElement)
      }
    });
  
    const getReferenceClientRect = useMemo(
      () => {
        if (!referencePos.current) {
          return undefined;
        }
  
        return () => referencePos.current!;
      },
      [referencePos.current] // eslint-disable-line
    );
  
    const slashMenuElement = useMemo(() => {
      if (!filteredItems || keyboardHoveredItemIndex === undefined) {
        return null;
      }
  
      const SlashMenu = props.slashMenu || DefaultSlashMenu;
  
      return (
        <SlashMenu
          filteredItems={filteredItems}
          itemCallback={(item) => props.editor.slashMenu.itemCallback(item)}
          keyboardHoveredItemIndex={keyboardHoveredItemIndex}
        />
      );
    }, [
      filteredItems,
      keyboardHoveredItemIndex,
      props.editor.slashMenu,
      props.slashMenu,
    ]);
  
    if (!parent) {
        return null;
      }
    return (
      <Tippy
        onClickOutside={() => setShow(false)}
        appendTo={parent}
        content={slashMenuElement}
        getReferenceClientRect={getReferenceClientRect}
        interactive={true}
        visible={show}
        animation={"fade"}
        placement={"bottom-start"}
      />
    );
  };