import { gql } from "@apollo/client";

export const LOGOUT_USER = gql`
  query logoutUser {
    logoutUser {
      success
      errors
    }
  }
`;

export const CREATE_CUSTOM_VALUE = gql`
  mutation createCustomValues($values: [CustomValueInput], $isCalendar: Boolean) {
    createCustomValues(values: $values, isCalendar: $isCalendar) {
      success
      errors
      values {
        id
        model
        modelName
        name
        value
        customFieldId
        created
      }
    }
  }
`;

export const NEW_PASSWORD = gql`
  mutation newPassword($oldPassword: String!, $password: String!, $password2: String!) {
    newPassword(oldPassword: $oldPassword, password: $password, password2: $password2) {
      success
      errors
    }
  }
`;

export const DELETE_USERS = gql`
  mutation deleteUsers($userIds: [ID]!) {
    deleteUsers(userIds: $userIds) {
      success
      errors
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: ID, $user: UserInput!, $cancelSubscription: Boolean) {
    updateUser(userId: $userId, user: $user, cancelSubscription: $cancelSubscription) {
      success
      errors
      user {
        organization {
          id
          v2
          name
          brand
          orgType
          message
          messageContent
          welcomeMessageContent
          autoReply
          autoReplyContent
          autoReplyEnabled
          twilioPhone
          messageAlertIds
          integrations {
            id
            userId
            created
            integrationType
          }
          forwardingPhone
          allowCallForwarding
          allowIncomingLeads
          allowSelfOnboarding
          allowAfterHours
          allowWeekends
          allowOldStats
          understandsTiming
          understandsContacts
          understandsEdit
          showMessages
          showAppt
          showCalendly
          showAudio
          showApptPlaceholders
          showCustomWindow
          showEventsPage
          stats {
            leads
            messages
            messagesSuccess
            messagesFailed
            responses
            deliveryRate
            responseRate
            openRate
            viewRate
            slideViewRate
          }
          access
          color
          image
          pitches {
            id
            name
          }
          users {
            id
            name
          }
          bank {
            name
            last4
          }
          billing {
            active
            expires
            periodEnd
            products {
              id
              name
              price
            }
            card {
              brand
              last4
              expMonth
              expYear
            }
            invoices {
              paid
              amount
              url
            }
            messages {
              cycle
              total
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($organizationId: ID!, $organization: OrganizationInput!) {
    updateOrganization(organizationId: $organizationId, organization: $organization) {
      success
      errors
      organization {
        id
        v2
        name
        brand
        orgType
        message
        messageContent
        welcomeMessageContent
        autoReply
        autoReplyContent
        autoReplyEnabled
        twilioPhone
        messageAlertIds
        integrations {
          id
          userId
          created
          integrationType
        }
        forwardingPhone
        allowCallForwarding
        allowIncomingLeads
        allowSelfOnboarding
        allowAfterHours
        allowWeekends
        allowOldStats
        understandsTiming
        understandsContacts
        understandsEdit
        showMessages
        showAppt
        showCalendly
        showAudio
        showApptPlaceholders
        showCustomWindow
        showEventsPage
        stats {
          leads
          messages
          messagesSuccess
          messagesFailed
          responses
          deliveryRate
          responseRate
          openRate
          viewRate
          slideViewRate
        }
        access
        color
        image
        pitches {
          id
          name
        }
        users {
          id
          name
        }
        bank {
          name
          last4
        }
        billing {
          active
          expires
          periodEnd
          products {
            id
            name
            price
          }
          card {
            brand
            last4
            expMonth
            expYear
          }
          invoices {
            paid
            amount
            url
          }
          messages {
            cycle
            total
          }
        }
      }
    }
  }
`;

export const INVITE_USERS = gql`
  mutation inviteUsers($users: [UserInviteInput]!, $token: String, $showCalendly: Boolean, $brand: String) {
    inviteUsers(users: $users, token: $token, showCalendly: $showCalendly, brand: $brand) {
      success
      errors
      users {
        email
      }
    }
  }
`;

export const FORGOT_PASSWORD_REQUEST = gql`
  mutation forgotPasswordRequest($email: String!, $brand: String) {
    forgotPasswordRequest(email: $email, brand: $brand) {
      success
      errors
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!, $password2: String!) {
    resetPassword(token: $token, password: $password, password2: $password2) {
      success
      errors
      token
    }
  }
`;

export const REGISTER_PHONE = gql`
  mutation registerPhone($phone: String, $brand: String) {
    registerPhone(phone: $phone, brand: $brand) {
      success
      errors
    }
  }
`;

export const CONFIRM_PHONE = gql`
  mutation confirmPhone($phone: String, $code: String) {
    confirmPhone(phone: $phone, code: $code) {
      success
      errors
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser($name: String, $email: String, $phone: String!, $password: String!, $password2: String!, $organizationName: String, $token: String, $phoneCode: String, $brand: String) {
    registerUser(name: $name, email: $email, phone: $phone, password: $password, password2: $password2, organizationName: $organizationName, token: $token, phoneCode: $phoneCode, brand: $brand) {
      success
      errors
      user {
        id
        new
        name
        firstName
        token
        organization {
          id
          v2
          name
          brand
          orgType
          message
          messageContent
          welcomeMessageContent
          autoReply
          autoReplyContent
          autoReplyEnabled
          twilioPhone
          messageAlertIds
          integrations {
            id
            userId
            created
            integrationType
          }
          forwardingPhone
          allowCallForwarding
          allowIncomingLeads
          allowSelfOnboarding
          allowAfterHours
          allowWeekends
          allowOldStats
          understandsTiming
          understandsContacts
          understandsEdit
          showMessages
          showAppt
          showCalendly
          showAudio
          showApptPlaceholders
          showCustomWindow
          showEventsPage
          stats {
            leads
            messages
            messagesSuccess
            messagesFailed
            responses
            deliveryRate
            responseRate
            openRate
            viewRate
            slideViewRate
          }
          access
          color
          image
          billing {
            active
            expires
            periodEnd
            products {
              id
              name
              price
            }
            card {
              brand
              last4
              expMonth
              expYear
            }
            invoices {
              paid
              amount
              url
            }
            messages {
              cycle
              total
            }
          }
          bank {
            name
            last4
          }
          pitches {
            id
            name
          }
          users {
            id
            name
          }
        }
        organizations {
          id
          name
        }
      }
    }
  }
`;

export const LOGIN_USER = gql`
  mutation loginUser($email: String, $password: String) {
    loginUser(email: $email, password: $password) {
      success
      errors
      token
      user {
        id
        new
        name
        firstName
        token
        organization {
          id
          v2
          name
          brand
          orgType
          message
          messageContent
          welcomeMessageContent
          autoReply
          autoReplyContent
          autoReplyEnabled
          twilioPhone
          messageAlertIds
          integrations {
            id
            userId
            created
            integrationType
          }
          forwardingPhone
          allowCallForwarding
          allowIncomingLeads
          allowSelfOnboarding
          allowAfterHours
          allowWeekends
          allowOldStats
          understandsTiming
          understandsContacts
          understandsEdit
          showMessages
          showAppt
          showCalendly
          showAudio
          showApptPlaceholders
          showCustomWindow
          showEventsPage
          stats {
            leads
            messages
            messagesSuccess
            messagesFailed
            responses
            deliveryRate
            responseRate
            openRate
            viewRate
            slideViewRate
          }
          access
          color
          image
          pitches {
            id
            name
          }
          users {
            id
            name
          }
          bank {
            name
            last4
          }
          billing {
            active
            expires
            periodEnd
            products {
              id
              name
              price
            }
            card {
              brand
              last4
              expMonth
              expYear
            }
            invoices {
              paid
              amount
              url
            }
            messages {
              cycle
              total
            }
          }
        }
        organizations {
          id
          name
        }
      }
    }
  }
`;

export const SWITCH_ORGANIZATION = gql`
  mutation switchOrganization($organizationId: ID!) {
    switchOrganization(organizationId: $organizationId) {
      success
      errors
      user {
        id
        new
        code
        name
        firstName
        email
        phone
        token
        showAppt
        showCalendly
        wpAdmin
        stats {
          leads
          messages
          views
          earnings
        }
        customValues {
          id
          model
          modelName
          name
          value
          customFieldId
          created
        }
        organization {
          id
          v2
          name
          brand
          orgType
          message
          messageContent
          welcomeMessageContent
          autoReply
          autoReplyContent
          autoReplyEnabled
          twilioPhone
          messageAlertIds
          integrations {
            id
            userId
            created
            integrationType
          }
          forwardingPhone
          allowCallForwarding
          allowIncomingLeads
          allowSelfOnboarding
          allowAfterHours
          allowWeekends
          allowOldStats
          understandsTiming
          understandsContacts
          understandsEdit
          showMessages
          showAppt
          showCalendly
          showAudio
          showApptPlaceholders
          showCustomWindow
          showEventsPage
          stats {
            leads
            messages
            messagesSuccess
            messagesFailed
            responses
            deliveryRate
            responseRate
            openRate
            viewRate
            slideViewRate
          }
          access
          color
          image
          pitches {
            id
            name
          }
          users {
            id
            name
          }
          bank {
            name
            last4
          }
          billing {
            active
            expires
            periodEnd
            products {
              id
              name
              price
            }
            card {
              brand
              last4
              expMonth
              expYear
            }
            invoices {
              paid
              amount
              url
            }
            messages {
              cycle
              total
            }
          }
        }
        integrations {
          id
          userId
          created
          integrationType
        }
        organizations {
          id
          name
          access
          bank {
            name
            last4
          }
        }
      }
    }
  }
`;

export const UPDATE_ENGAGEMENT_METRICS = gql`
  mutation updateEngagementMetrics($engagementMetrics: EngagementMetricsInput) {
    updateEngagementMetrics(engagementMetrics: $engagementMetrics) {
      success
      errors
      engagementMetrics {
        id
        view
        viewTime
        share
        reopen
        widget
        video
        scheduled
        completed
      }
    }
  }
`;
