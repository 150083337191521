import { gql } from "@apollo/client";


export const CREATE_LEAD = gql`
  mutation createLead($lead: LeadInput!, $welcomeMessageContent: JSON) {
    createLead(lead: $lead, welcomeMessageContent: $welcomeMessageContent) {
      success
      errors
      lead {
        id
        name
        phone
        firstName
        lastName
        email
        externalId
      }
    }
  }
`;


export const CREATE_LEADS = gql`
  mutation createLeads($leads: [LeadInput]!, $campaignId: ID, $groupId: ID) {
    createLeads(leads: $leads, campaignId: $campaignId, groupId: $groupId) {
      success
      errors
      leads {
        id
      }
    }
  }
`;


export const LEADS_FROM_CSV = gql`
  mutation leadsFromCsv($leads: [JSON]!, $campaignId: ID, $groupId: ID, $fullNameKey: String, $firstNameKey: String, $lastNameKey: String, $phoneKey: String) {
    leadsFromCsv(leads: $leads, campaignId: $campaignId, groupId: $groupId, fullNameKey: $fullNameKey, firstNameKey: $firstNameKey, lastNameKey: $lastNameKey, phoneKey: $phoneKey) {
      success
      errors
    }
  }
`;


export const ARCHIVE_LEADS = gql`
  mutation archiveLeads($leadIds: [ID]) {
    archiveLeads(leadIds: $leadIds) {
      success
      errors
    }
  }
`;


export const ADD_LEADS_TO_GROUP = gql`
  mutation addLeadsToGroup($leadIds: [ID]!, $groupId: ID!) {
    addLeadsToGroup(leadIds: $leadIds, groupId: $groupId) {
      success
      errors
    }
  }
`;


export const ADD_LEADS_TO_CAMPAIGN = gql`
  mutation addLeadsToCampaign($campaignId: ID!, $leadIds: [ID], $groupIds: [ID]) {
    addLeadsToCampaign(campaignId: $campaignId, leadIds: $leadIds, groupIds: $groupIds) {
      success
      errors
    }
  }
`;


export const REMOVE_LEADS_FROM_CAMPAIGN = gql`
  mutation removeLeadsFromCampaign($campaignId: ID!, $leadIds: [ID]!) {
    removeLeadsFromCampaign(campaignId: $campaignId, leadIds: $leadIds) {
      success
      errors
    }
  }
`;


export const REMOVE_LEADS_FROM_GROUP = gql`
  mutation removeLeadsFromGroup($groupId: ID!, $leadIds: [ID]!) {
    removeLeadsFromGroup(groupId: $groupId, leadIds: $leadIds) {
      success
      errors
    }
  }
`;


export const MESSAGE_LEADS = gql`
  mutation messageLeads($leadIds: [ID], $messageContent: JSON!, $groupIds: [ID]) {
    messageLeads(leadIds: $leadIds, messageContent: $messageContent, groupIds: $groupIds) {
      success
      errors
    }
  }
`;


export const MESSAGE_LEAD = gql`
  mutation messageLead($leadId: ID!, $message: String!) {
    messageLead(leadId: $leadId, message: $message) {
      success
      errors
      message {
        id
        userId
        campaignId
        campaignMessageId
        message
        created
        sent
        success
        delivered
        senderName
        aiGenerated
      }
    }
  }
`;


export const UPDATE_LEAD = gql`
  mutation updateLead($leadId: ID!, $lead: LeadInput!) {
    updateLead(leadId: $leadId, lead: $lead) {
      success
      errors
    }
  }
`;


export const LOAD_LEAD_ACTIVITY = gql`
  mutation loadLeadActivity($pitchId: String, $campaignId: String, $campaignLeadCode: String, $campaignFeatureCode: String, $token: String, $deviceId: String) {
    loadLeadActivity(pitchId: $pitchId, campaignId: $campaignId, campaignLeadCode: $campaignLeadCode, campaignFeatureCode: $campaignFeatureCode, token: $token, deviceId: $deviceId) {
      success
      errors
      lead {
        id
        name
        phone
        landingPageId
        groups {
          id
          name
        }
        appointments {
          id
          eventUrl
          eventDate
          eventTime
          pitchId
          pitchSlideId
        }
        widgets {
          widgetId
          label
          values
        }
        responsibleUser {
          id
          name
          phone
          firstName
          lastName
        }
      }
      pitch {
        id
        v2
        userId
        organizationId
        title
        description
        endText
        image
        color
        shareImage
        showPreviews
        created
        archived
        code
        structure {
          id
          label
          slideType
          thumbnail
          structure {
            id
            label
            type
          }
        }
        slides {
          id
          pitchId
          slideType
          internalTitle
          audioFile
          created
          title
          description
          thumbnail
          url
          calendars {
            id
            model
            modelName
            name
            value
            customFieldId
            created
          }
          isVideo
          processed
          content
          mimeType
          widgetStructure{
            id
            label
            type
          }
          widgets {
            id
            pitchSlideId
            label
            fineText
            widgetType
            labels
            start
            end
            created
          }
        }
        redirectType
        redirectUrl
        redirectPhone
        redirectButton
      }
      activity {
        ssid
        deviceId
      }
      times {
        pitchSlideId
        seconds
        video
        audio
        audioDuration
      }
      campaign {
        brandImage
        brandName
        brandColor
      }
    }
  }
`;


export const TRACK_LEAD = gql`
  mutation trackLead($leadActivitySsid: String!, $times: [LeadTimeInput], $widgets: [LeadWidgetInput], $events: [LeadAppointmentInput]) {
    trackLead(leadActivitySsid: $leadActivitySsid, times: $times, widgets: $widgets, events: $events) {
      success
      errors
    }
  }
`;


export const SUBMIT_CAMPAIGN_LANDING_PAGE = gql`
  mutation submitCampaignLandingPage($campaignCode: String!, $widgets: [LeadWidgetInput]!) {
    submitCampaignLandingPage(campaignCode: $campaignCode, widgets: $widgets) {
      success
      errors
    }
  }
`;


export const SUBMIT_GROUP_LANDING_PAGE = gql`
  mutation submitGroupLandingPage($groupCode: String!, $widgets: [LeadWidgetInput]!) {
    submitGroupLandingPage(groupCode: $groupCode, widgets: $widgets) {
      success
      errors
    }
  }
`;


export const CREATE_GROUP = gql`
  mutation createGroup($name: String) {
    createGroup(name: $name) {
      success
      errors
      group {
        id
      }
    }
  }
`;


export const UPDATE_GROUP = gql`
  mutation updateGroup($groupId: ID!, $group: GroupInput!) {
    updateGroup(groupId: $groupId, group: $group) {
      success
      errors
      group {
        id
      }
    }
  }
`;


export const ARCHIVE_GROUPS = gql`
  mutation archiveGroups($groupIds: [ID]!) {
    archiveGroups(groupIds: $groupIds) {
      success
      errors
    }
  }
`;

export const REGISTER_BROWSER = gql`
  mutation registerBrowser($campaignLeadCode: String, $campaignFeatureCode: String) {
    registerBrowser(campaignLeadCode: $campaignLeadCode, campaignFeatureCode: $campaignFeatureCode) {
      success
      errors
    }
  }
`;

export const CONFIRM_BROWSER = gql`
  mutation confirmBrowser($campaignLeadCode: String, $campaignFeatureCode: String, $code: String) {
    confirmBrowser(campaignLeadCode: $campaignLeadCode, campaignFeatureCode: $campaignFeatureCode, code: $code) {
      success
      token
      errors
    }
  }
`;

export const UPDATE_LEAD_APPOINTMENT = gql`
  mutation updateLeadAppointment($leadAppointmentId: ID!, $leadAppointment: LeadAppointmentInput) {
    updateLeadAppointment(leadAppointmentId: $leadAppointmentId, leadAppointment: $leadAppointment) {
      success
      errors
      appointment {
        id
        eventUrl
        eventDate
        eventTime
        campaigns {
          id
          name
        }
        pitchId
        pitchSlideId
      }
    }
  }
`;

export const CREATE_LEAD_APPOINTMENT = gql`
  mutation createLeadAppointment($leadId: ID!, $leadAppointment: LeadAppointmentInput) {
    createLeadAppointment(leadId: $leadId, leadAppointment: $leadAppointment) {
      success
      errors
      appointment {
        id
        eventUrl
        eventDate
        eventTime
        campaigns {
          id
          name
        }
        pitchId
        pitchSlideId
      }
    }
  }
`;

export const DELETE_LEAD_APPOINTMENT = gql`
  mutation deleteLeadAppointment($leadAppointmentId: ID!) {
    deleteLeadAppointment(leadAppointmentId: $leadAppointmentId) {
      success
      errors
    }
  }
`;

export const CANCEL_EVENTS = gql`
  mutation cancelEvents($eventIds: [ID!]) {
    cancelEvents(eventIds: $eventIds) {
        success
        errors
    }
  }
`;

export const ADD_CAMPAIGNS_TO_EVENTS = gql`
  mutation addCampaignsToEvents($campaignIds: [ID!], $eventIds: [ID!]) {
    addCampaignsToEvents(campaignIds: $campaignIds, eventIds: $eventIds) {
        success
        errors
    }
  }
`;

export const MESSAGE_EVENTS = gql`
  mutation messageEvents($messageContent: JSON!, $eventIds: [ID!]) {
    messageEvents(messageContent: $messageContent, eventIds: $eventIds) {
        success
        errors
    }
  }
`;