import { Text, View, TextInput, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Dashboard } from '@uppy/react'
import Uppy from '@uppy/core'
import Webcam from '@uppy/webcam'
import ScreenCapture from '@uppy/screen-capture'
import Transloadit from '@uppy/transloadit'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import ImageEditor from '@uppy/image-editor';

import '@uppy/screen-capture/dist/style.min.css';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';


function createUppy(audioOnly: boolean) {
  return new Uppy({
    id: Math.random().toString(36).substring(7),
    autoProceed: true,
    restrictions: {
      maxFileSize: null,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: audioOnly ? ['.mp3', '.wav'] : ["video/*", "image/*", ".jpg", ".jpeg", ".png", ".gif", ".mov", ".mp4"]
    }
  })
    .use(ImageEditor, { id: 'ImageEditor' })
    .use(Webcam, { countdown: true, id: 'Webcam' })
    .use(ScreenCapture, { id: 'ScreenCapture' })
    .use(ThumbnailGenerator, { id: 'ThumbnailGenerator' })
    .use(Transloadit, {
      service: 'https://api2.transloadit.com',
      params: {
        auth: { key: 'b1a72d13e46c40179788d8c5af356122' },
        steps: {
          ":original": {
            robot: "/upload/handle",
          },
          exported: {
            robot: "/s3/store",
            use: ":original",
            credentials: 'aws-s3',
            acl: 'public-read',
          }
        }
      },
      waitForEncoding: true,
      limit: 1,
      id: "Transloadit"
    });
}

export default function Uppyup({ plugins, audioOnly = false, width = 'auto', height = 300, onDone, onUploadComplete }: { plugins: string[], audioOnly?: boolean, width?: string | number, height?: number, onDone?: Function, onUploadComplete: Function }): JSX.Element {
  const [index, setIndex] = useState<number>(0);
  const [uppy] = useState(() => createUppy(audioOnly));

  useEffect(() => {
    uppy.setOptions({
      restrictions: {
        allowedFileTypes: audioOnly ? ['.mp3', '.wav'] : ["video/*", "image/*", ".jpg", ".jpeg", ".png", ".gif", ".mov", ".mp4"]
      }
    });
    uppy.getPlugin('Transloadit')?.setOptions({
      service: 'https://api2.transloadit.com',
      params: {
        auth: { key: 'b1a72d13e46c40179788d8c5af356122' },
        steps: {
          ":original": {
            robot: "/upload/handle",
          },
          exported: {
            robot: "/s3/store",
            use: ":original",
            credentials: 'aws-s3',
            acl: audioOnly ? 'private' : 'public-read',
          }
        }
      },
      waitForEncoding: true,
      limit: 1,
      id: "Transloadit"
    })
    uppy.on('transloadit:complete', result => {
      console.log(result)
      if (result?.results[':original'].length > 0) {
        let url = result.results[':original'][0].ssl_url;
        let mime = result.results[':original'][0].mime;
        onUploadComplete(url, mime);
      }
    });
  }, [audioOnly, onUploadComplete]);

  useEffect(() => {
    if (uppy) {
      setIndex(index + 1)
    }
  }, [plugins])

  return (
    <View style={{ zIndex: 1, width: width }}>
      <Dashboard
        key={`uppy-${index}`}
        height={height}
        width={width}
        doneButtonHandler={null}
        // doneButtonHandler={() => {
        //   uppy.reset();
        //   onDone()
        // }}
        hideProgressAfterFinish={true}
        uppy={uppy}
        plugins={plugins}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
});
