import { View, Text, StyleSheet, Dimensions, LayoutChangeEvent, Image } from 'react-native';
import React, { useState, useEffect } from 'react';

import Constants from './../../constants';
import Spinner from './../Spinner';
import Header from './../Pitch/Header';
import Footer from './../Pitch/Footer';
import SlideMainContent from './../Pitch/SlideMainContent';
import SlideWidgets from './../Pitch/SlideWidgets';
import PitchControls from './../Pitch/Controls';
import { LeadContext, WpLeadAction } from './../../contexts/lead';
import { WpPitchSlide, WpSlideType } from './../../contexts/pitch';
import { GET_CAMPAIGN, GET_LEADS } from './../../graphql/queries';
import { SUBMIT_CAMPAIGN_LANDING_PAGE } from './../../graphql/lead';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { WpFullCampaign } from '../../contexts/campaign';
import { Avatar, Button } from '@whistlepitch/wp-components';



export default function CampaignLandingPage({ campaignCode }: { campaignCode?: string }): JSX.Element {
  const { state, dispatch } = React.useContext(LeadContext);

  const [currentIndex, setCurrentIndex]= useState<number>(0);
  const [campaign, setCampaign]= useState<WpFullCampaign>();
  const [showContinue, setShowContinue]= useState<boolean>(true);
  const [brandColor, setBrandColor]= useState<string>();
  const [brandImage, setBrandImage]= useState<string>();
  const [width, setViewWidth]= useState<number>(0);
  const [done, setDone]= useState<boolean>(false);
  const [error, setError]= useState<string>();
  const [disabled, setDisabled]= useState<boolean>(false);
  const [aspectRatio, setAspectRatio] = useState(1)

  const { data, loading } = useQuery(GET_CAMPAIGN, {
    fetchPolicy: "no-cache",
    variables: {
      campaignCode: campaignCode
    }
  });

  const [submitLandingPage, submitLandingPageData] = useMutation(SUBMIT_CAMPAIGN_LANDING_PAGE, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (!brandImage) {
      return;
    }

    let isValid = true;
    Image.getSize(brandImage, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [brandImage]);

  useEffect(() => {
    if (campaign?.brandImage) {
      setBrandImage(campaign?.brandImage)
    } else if (campaign?.landingPage.image) {
      setBrandImage(campaign?.landingPage.image)
    }
  }, [campaign?.brandImage, campaign?.landingPage.image]);

  useEffect(() => {
    if (campaign?.brandColor) {
      setBrandColor(campaign?.brandColor)
    } else if (campaign?.landingPage.color) {
      setBrandColor(campaign?.landingPage.color)
    }
  }, [campaign?.brandColor, campaign?.landingPage.color]);

  useEffect(() => {
    if (data) {
      setCampaign(data.campaign.campaign)
    }
  }, [data])

  useEffect(() => {
    if (submitLandingPageData.data) {
      if (submitLandingPageData.data.submitCampaignLandingPage.success) {
        setDone(true)
      } else if (submitLandingPageData.data.submitCampaignLandingPage.errors.length) {
        setError(submitLandingPageData.data.submitCampaignLandingPage.errors[0])
      } else {
        setError('An unknown error occurred. Please refresh the page and try again.')
      }
      setCampaign(data.campaign.campaign)
    }
  }, [submitLandingPageData.data])

  useEffect(() => {
    if (state.lead?.widgets) {
      let vindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'opt-in')
      let pindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'phone')
      let hindex = state.lead.widgets.findIndex((widget) => widget.widgetId === 'hipaa')
      if (vindex > -1 && pindex > -1 && state.lead.widgets[vindex].values && state.lead.widgets[vindex].values.indexOf('Verified') !== -1 && state.lead.widgets[pindex].values.length && state.lead.widgets[pindex].values[0].length >= 10) {
        if (!Constants.oppText || (hindex > -1 && state.lead.widgets[hindex].values && state.lead.widgets[hindex].values.indexOf('Verified') !== -1)) {
          setDisabled(true)
          return;
        }
      }
    }
    setDisabled(false);
  }, [state.lead?.widgets])

  const onLayout = (event: LayoutChangeEvent) => {
    var {x, y, width, height} = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onSubmit = () => {
    submitLandingPage({
      variables: {
        campaignCode: campaignCode,
        widgets: state.lead?.widgets ?? []
      }
    })
  }

  const renderInactive = () => {
    return (
      <View style={styles.inactive}>
        <Text style={styles.inactiveText}>The content you are looking for is no longer active or has been removed.</Text>
      </View>
    )
  }

  const renderLandingPage = () => {
    if (campaign) {
      if (done) {
        return (
          <View style={styles.slideWrapper}>
            <SlideMainContent
              title={"Submitted!"}
              landingPageComplete={true}
              color={brandColor}
            />
          </View>
        )
      } else if (!campaign) {
        return <Spinner />
      }

      let structure = [
        {'id': 'name', 'label': 'Your Full Name', 'type': 'SHORT'},
        {'id': 'phone', 'label': 'Your Phone Number', 'type': 'SHORT'},
        ...campaign.landingPage.structure,
        {'id': 'opt-in', 'label': Constants.localizations.optIn, 'type': 'VERIFY'}
      ]
      if (Constants.oppText) {
        structure.push({'id': 'hipaa', 'label': 'I hereby authorize use or disclosure of my name, phone, and possible appointment date (protected health information) to OppText for the use of sending me timely materials related to my care.', 'type': 'VERIFY'})
      }

      return (
        <View style={styles.slideWrapper}>
          { campaign.landingPage.description ?
            <SlideMainContent
              title={campaign.landingPage.title ? campaign.landingPage.title : campaign.brandName}
              description={campaign.landingPage.description}
              color={brandColor}
            /> : null }
          <SlideWidgets color={brandColor} widgetStructure={structure} widgets={campaign.landingPage.widgets} leadWidgets={state.lead?.widgets ?? []} />
          <Button style={{ width: 150, marginLeft: 'auto', marginRight: 'auto', marginTop: 15, backgroundColor: brandColor }} type="Primary" size="Default" shape="Pill" onClick={onSubmit} disabled={submitLandingPageData.loading || !disabled}>
            { submitLandingPageData.loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontWeight: 'bold' }}>Submit</Text> }
          </Button>
          { !!error ? <Text style={styles.error}>{error}</Text> : null }
        </View>
      )
    }
    return <Spinner style={{ marginTop: 55 }} />;
  }

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, alignItems: 'center' }} onLayout={onLayout}>
        <View style={{ width: '100%', backgroundColor: brandColor ? brandColor : Constants.colors.darkBlue, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'relative', zIndex: 5, marginBottom: 55, height: 150 }}>
          <View style={styles.logo}>
            { brandImage ?
              <Image style={[styles.brand, { aspectRatio: 1 }]} source={{ uri: brandImage }} /> :
              <Text style={{ padding: 2, fontFamily: 'GothamBold', color: brandColor ? brandColor : Constants.colors.blue }}> </Text>
            }
          </View>
        </View>
        <View style={[styles.body, { width: '100%', maxWidth: 500, marginBottom: 45 }]}>
          { renderLandingPage() }
        </View>
      </View>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    position: 'relative',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  error: {
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
    color: Constants.colors.red,
    fontFamily: 'GothamMedium'
  },
  slideWrapper: {
    flex: 1,
    position: 'relative'
  },
  inactive: {
    margin: 25,
    marginBottom: 0,
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20
  },
  inactiveText: {
    color: Constants.colors.secondary,
    textAlign: 'center'
  },
  body: {
    flex: 1,
    position: 'relative',
    zIndex: 1
  },
  logo: {
    position: 'relative',
    top: '50%',
    width: 110,
    height: 110,
    backgroundColor: '#FFF',
    border: '1px solid #CCC',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 55,
    zIndex: 10,
    padding: 15
  },
  brand: {
    width: 110,
    clipPath: 'circle()'
  }
})
