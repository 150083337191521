import { View, Text, StyleSheet } from 'react-native';
import React, { useState } from 'react';

import Constants from '../constants';
import { LeadContext, leadReducer } from '../contexts/lead';
import CampaignOverview from '../components/CampaignOverview';
import PitchProvider from '../components/Pitch/PitchProvider';
import { useParams } from './../react-router';


export default function LeadPitchProvider(): JSX.Element {
  const params = useParams();

  return (
    <View style={{ flex: 1, backgroundColor: Constants.colors.backgroundLight }}>
      <PitchProvider campaignFeatureCode={params.campaignFeatureCode} campaignLeadCode={params.campaignLeadCode} />
    </View>
  );
}
