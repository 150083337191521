import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions, Pressable } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import { Ionicons, FontAwesome5 } from '@expo/vector-icons';


export default function Checkbox({ onSelect, style={}, color=Constants.colors.secondary, colorActive=Constants.colors.darkBlue, hoverable=true, selected=false, size=18 } : { onSelect: (val: boolean) => void, style?: object, color?: string, colorActive?: string, hoverable?: boolean, selected?: boolean, size?: number }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>();
  const [icon, setIcon] = useState<"check-square"|"square">(selected ? "check-square" : "square");

  useEffect(() => {
    setIcon(selected ? "check-square" : "square")
  }, [selected])

  const onHover = () => {
    setHovering(hoverable)
  }

  const onHoverOut = () => {
    setHovering(false)
  }

  const handleOnSelect = () => {
    onSelect(!selected);
  }

  return <FontAwesome5 onPress={handleOnSelect} style={[styles.checkbox, style, { opacity: hovering ? 0.4 : 1 }]} name={icon} size={size} color={selected || hovering ? colorActive : color} onMouseEnter={onHover} onMouseLeave={onHoverOut} />
}

const styles = StyleSheet.create({
  checkbox: {
    marginRight: 20
  }
});

const cssStyles = {
}
