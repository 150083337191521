import { View, Text, StyleSheet, Image } from 'react-native';
import React, { useEffect, useState } from 'react';

import Constants from '../../constants';
import FullscreenModal from './../Shared/FullscreenModal';
import CampaignLandingPageModal from './CampaignLandingPageModal';
import ManageLeadsModal from './../Leads/ManageLeadsModal';
import CampaignFeature from './CampaignFeature';
import { CampaignContext, WpCampaignStructure, WpFeatureType, WpStructureType, WpCampaignAction } from './../../contexts/campaign';
import { UPDATE_CAMPAIGN, ARCHIVE_CAMPAIGN, CLONE_CAMPAIGN } from './../../graphql/campaign';
import { UserContext } from './../../contexts/user';
import Input, { Label } from './../Input';
import { AntDesign, Feather, SimpleLineIcons, Ionicons } from '@expo/vector-icons';
import { MaterialCommunityIcons, Entypo, MaterialIcons } from '@expo/vector-icons';
import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { PitchSlideContext, WpFullPitch, WpPitchSlide, WpPitchStructure, slideReducer } from '../../contexts/pitch';
import PitchSlideEditor from '../Pitch/PitchSlideEditor';
import { useNavigate } from './../../react-router';
import { PageTitle } from '@whistlepitch/wp-components';
import WpModal from '../WpModal';
import ModalBody from '../ModalBody';
import Uppyup from '../Uppyup';
import ModalFooter from '../ModalFooter';
import { Button, Theme } from '../Button'
import Icon from '../Icon';
import CustomFieldDropdown from '../CustomFieldDropdown';
import Dropdown, { Item } from '../Dropdown';
import MessageFeed from '../Shared/MessageFeed';
import { WpLeadMessage } from '../../contexts/lead';
import Toast from 'react-native-toast-message';
import Hoverable from '../Shared/Hoverable';
import Header from '../Pitch/Header';
import SlideMainContent from '../Pitch/SlideMainContent';
import SlideWidgets from '../Pitch/SlideWidgets';
import PitchControls from '../Pitch/Controls';
import { GET_CAMPAIGN_CATEGORIES, GET_PITCH_SLIDE } from '../../graphql/queries';
import Spinner from '../Spinner';
import Footer from '../Pitch/Footer';
import Checkbox from '../Checkbox';


export default function CampaignGraphModal({ onSave, isVisible, onBack, onReport }: { onSave: () => void, isVisible: boolean, onBack: () => void, onReport: () => void }): JSX.Element {
  const slideContext = React.useReducer(slideReducer, { slide: null });
  const { state, dispatch } = React.useContext(CampaignContext);

  const userContext = React.useContext(UserContext);
  const [viewing, setViewing] = useState<"default" | "edit" | "message">("default")
  const [showCalendly, setShowCalendly] = useState<boolean>(!!userContext.state.user?.showCalendly || !!userContext.state.user?.organization?.showCalendly)
  const [showPreview, setShowPreview] = useState<boolean>();

  const [managingLeads, setManagingLeads] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [calendlyEventUri, setCalendlyEventUri] = useState<string>();
  const [hovering, setHovering] = useState<"prev" | "next">();
  const [calendlyEventName, setCalendlyEventName] = useState<string>();
  const [calendlyEventLink, setCalendlyEventLink] = useState<string>();
  const [brandName, setBrandName] = useState<string>();
  const [brandImage, setBrandImage] = useState<string>();
  const [brandColor, setBrandColor] = useState<string>();
  const [showBranding, setShowBranding] = useState<boolean>(false);
  const [showArchiving, setShowArchiving] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<boolean>(false);
  const [managingLibrary, setManagingLibrary] = useState<boolean>(false);
  const [pitchId, setPitchId] = useState<string>();
  const [managingLandingPage, setManagingLandingPage] = useState<boolean>(false);
  const [managingCalendlyTrigger, setManagingCalendlyTrigger] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [delay, setDelay] = useState<NodeJS.Timeout>();
  const [slideId, setSlideId] = useState<string>();
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState<number>(0);
  const [currentPitchId, setCurrentPitchId] = useState<string>();
  const [currentSlide, setCurrentSlide] = useState<WpPitchSlide>();
  const [currentPreview, setCurrentPreview] = useState<string>();
  const [messages, setMessages] = useState<WpLeadMessage[]>([]);
  const [previewItems, setPreviewItems] = useState<Item[]>([]);
  const [categoryItems, setCategoryItems] = useState<Item[]>([]);
  const [pitchObjs, setPitches] = useState<{ [k: string]: WpFullPitch }>({});
  const [ImageElement, setImageElement] = useState<JSX.Element>()
  const [aspectRatio, setAspectRatio] = useState<number>(0)
  const [pitchRefresh, setPitchRefresh] = useState<number>(0)
  const [appointmentHover, setAppointmentHover] = useState<"instant" | "pre" | "post" | undefined>()
  const [viewingTimeline, setViewingTimeline] = useState<"instant" | "pre" | "post">()
  const [structureLengths, setStrutureLengths] = useState<number[]>([0, 0, 0])

  const [description, setDescription] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [category, setCategory] = useState<string | null>(null);
  const [editingCategory, setEditingCategory] = useState<boolean>(false);

  const [updateCampaign, updateCampaignData] = useMutation(UPDATE_CAMPAIGN);
  const [cloneCampaign, cloneCampaignData] = useMutation(CLONE_CAMPAIGN, {
    fetchPolicy: "no-cache"
  })
  const [archiveCampaign, archiveCampaignData] = useMutation(ARCHIVE_CAMPAIGN, {
    fetchPolicy: "no-cache"
  })

  const [getCampaignCategories, getCampaignCategoriesData] = useLazyQuery(GET_CAMPAIGN_CATEGORIES, {
    fetchPolicy: "no-cache"
  })

  const [getSlide, getSlideData] = useLazyQuery(GET_PITCH_SLIDE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })

  const navigate = useNavigate();

  useEffect(() => {
    setShowCalendly(!!userContext.state.user?.showCalendly || !!userContext.state.user?.organization?.showCalendly)
  }, [userContext.state.user?.showCalendly, userContext.state.user?.organization?.showCalendly])

  useEffect(() => {
    if (archiveCampaignData.data) {
      navigate('/campaigns')
    }
  }, [archiveCampaignData.data])

  useEffect(() => {
    if (cloneCampaignData.data && cloneCampaignData.data.cloneCampaign.campaign.id) {
      window.location.href = `/campaign/${cloneCampaignData.data.cloneCampaign.campaign.id}`
    }
  }, [cloneCampaignData.data])

  useEffect(() => {
    if (getCampaignCategoriesData.data?.campaignCategories?.categories) {
      setCategoryItems(getCampaignCategoriesData.data.campaignCategories.categories.map((category: string) => {
        return {
          value: category,
          label: category
        }
      }));
    }
  }, [getCampaignCategoriesData.data])

  useEffect(() => {
    if (getSlideData.data?.pitchSlide?.slide && getSlideData.networkStatus === NetworkStatus.ready) {
      setCurrentSlide(getSlideData.data?.pitchSlide?.slide)
      setPitchRefresh(pitchRefresh + 1)
    }
  }, [getSlideData.data?.pitchSlide?.slide, getSlideData.networkStatus])

  useEffect(() => {
    if (state.campaign) {
      setBrandName(state.campaign.brandName)
      setBrandImage(state.campaign.brandImage)
      setBrandColor(state.campaign.brandColor)
      setCalendlyEventUri(state.campaign.calendlyEventUri)
      setCalendlyEventName(state.campaign.calendlyEventName)
      setCalendlyEventLink(state.campaign.calendlyEventLink)
      setDescription(state.campaign.description)
      setIsPublic(state.campaign.public)
      setCategory(state.campaign.category)
    }
  }, [state.campaign])

  useEffect(() => {
    let newLengths = [state.campaign?.structure?.feature.length ?? 0, state.campaign?.structure?.before.length ?? 0, state.campaign?.structure?.appointment.length ?? 0];
    setStrutureLengths(newLengths)
    if (newLengths[0] > 0) {
      setViewingTimeline('instant');
    } else if (newLengths[1] > 0) {
      setViewingTimeline('pre');
    } else if (newLengths[2] > 0) {
      setViewingTimeline('post');
    }
  }, [state.campaign?.structure?.feature.length, state.campaign?.structure?.before.length, state.campaign?.structure?.appointment.length])

  useEffect(() => {
    setManagingLibrary(false);
    if (updateCampaignData.data) {
      handleOnSave();
    }
  }, [updateCampaignData.data])

  useEffect(() => {
    if (state.campaign) {
      let structures = [
        state.campaign.structure.feature,
        state.campaign.structure.before,
        state.campaign.structure.appointment,
      ]

      let items = []
      let pitches: { [k: string]: WpFullPitch } = {};
      let new_messages: WpLeadMessage[] = [];
      for (let i = 0; i < structures.length; i++) {
        for (let j = 0; j < structures[i].length; j++) {
          let f = structures[i][j];

          if (f) {
            let findex = state.campaign.features.findIndex((feature) => feature.id === f.id)

            if (findex > -1) {
              let feature = state.campaign.features[findex];
              let message_text = '';
              if (feature.messageContent?.blocks && feature.messageContent.blocks.length) {
                for (let i = 0; i < feature.messageContent.blocks.length; i++) {
                  message_text += feature.messageContent.blocks[i].text + '\n';
                }
              }

              if (feature.pitch && feature.pitch.id && feature.pitch.structure.length) {
                message_text += `\n${Constants.app_url}/c/xY89zq/99ssnr`
              }

              new_messages.push({
                id: feature.id,
                campaignId: state.campaign.id,
                campaignMessageId: 'id',
                message: message_text,
                senderName: '',
                success: true,
                delivered: true,
                userId: userContext.state.user?.id ?? '',
                aiGenerated: false,
              })

              items.push({ value: 'feature-' + j, label: feature.label.substring(0, 23) + (feature.label.length > 23 ? '...' : '') })
              if (feature.pitch && feature.pitch.id && feature.pitch.structure.length) {
                pitches[feature.pitch.id] = feature.pitch;
                for (let k = 0; k < feature.pitch.structure.length; k++) {
                  let slide = feature.pitch.structure[k];
                  let val = 'pitch-' + feature.pitch.id + '-slide-' + slide.id;
                  if (k === feature.pitch.structure.length - 1) {
                    val += '-last';
                  }
                  items.push({ value: val, label: slide.label.substring(0, 23) + (slide.label.length > 23 ? '...' : '') })
                }
              }
            }
          }
        }
      }

      setMessages(new_messages)
      setPreviewItems(items)
      setPitches(pitches)
    }
  }, [state.campaign])

  useEffect(() => {
    if (!brandImage) {
      return;
    }

    let isValid = true;
    Image.getSize(brandImage, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [brandImage]);

  useEffect(() => {
    if (brandImage) {
      setImageElement(<Image style={{ borderRadius: 10, alignSelf: 'center', marginTop: 20, width: 200, aspectRatio }} source={{ uri: brandImage }} />)
    }
  }, [aspectRatio, brandImage])

  useEffect(() => {
    if (state?.campaign?.name && !name) {
      setName(state.campaign.name)
    }
  }, [state?.campaign?.name])

  useEffect(() => {
    if (state?.campaign?.name) {
      setName(state.campaign.name)
    }
  }, [state?.campaign?.id])

  useEffect(() => {
    if (state?.campaign?.id && name !== state.campaign.name) {
      if (delay) {
        clearTimeout(delay)
      }

      let cid = state.campaign.id;
      setDelay(setTimeout(() => {
        updateCampaign({
          variables: {
            campaignId: cid,
            name: name
          }
        })
      }, 350))
    }
  }, [name])

  const onSelectSlide = (id?: string, isNew?: boolean) => {
    if (id) {
      setIsNew(!!isNew)
      setSlideId(id)
    }
  }

  const handleOnSave = () => {
    setPitchRefresh(pitchRefresh + 1)
    if (currentSlide) {
      loadSlide(currentSlide.id)
    }
    setEditingCategory(false);
    onSave()
  }

  const renderControls = () => {
    return (
      <View style={styles.controls}>
        {Constants.controls.allowCampaignLandingPage ?
          <Hoverable tooltip="Landing Page" top={40} left={'-40%'} onPress={openBranding}>
            <Icon name="dashboard" onClick={startManagingLandingPage} />
          </Hoverable> : null}
        {Constants.controls.allowSlideTypeCalendly && showCalendly ?
          <Hoverable tooltip="Calendly" top={40} left={'-40%'} onPress={sartManagingCalendlyTrigger}>
            <Icon name="calendar" />
          </Hoverable> : null}
        {!Constants.blastView && !Constants.oppText ?
          <Hoverable tooltip="Campaign Branding" top={40} left={'-40%'} onPress={openBranding}>
            <Icon name="image" />
          </Hoverable> : null}
        {previewItems.length ?
          <Hoverable tooltip="Preview Campaign" top={40} left={'-40%'} onPress={() => setShowPreview(!showPreview)}>
            <Icon name="eye" color={showPreview ? Constants.colors.blue : "grey"} colorHover={showPreview ? Constants.colors.darkBlue : Constants.colors.blue} />
          </Hoverable> : null}
        <Hoverable tooltip="Copy Campaign" top={40} left={'-40%'} onPress={onCloneCampaign}>
          <Icon name="clone" />
        </Hoverable>
        {Constants.oppText ?
          <Hoverable tooltip="Campaign Library" top={40} left={'-40%'} onPress={onManageLibrary}>
            <Icon name="share" />
          </Hoverable> : null}
        <Hoverable tooltip="Delete Campaign" top={40} left={'-40%'} onPress={openArchiveCampaign}>
          <Icon name="trash" colorHover={Constants.colors.red} />
        </Hoverable>
      </View>
    )
  }

  const renderAppointmentControls = () => {
    if (Constants.oppText || Constants.tattooCare) {
      let notStarted = structureLengths.every(i => i === 0);

      return (
        <View style={styles.appointmentControls}>
          <View style={styles.appointmentControlGroup}>
            {notStarted || (structureLengths.length === 3 && structureLengths[0] > 0) ?
              <Hoverable tooltip={'Instant'} onPress={() => setViewingTimeline('instant')} onHover={() => setAppointmentHover('instant')} doneHover={() => setAppointmentHover(undefined)}>
                <MaterialCommunityIcons style={[styles.appointmentControl, viewingTimeline === 'instant' ? styles.appointmentActive : {}, appointmentHover === 'instant' ? styles.appointmentHovering : {}]} name="lightning-bolt-outline" size={24} color={Constants.colors.darkGrey} />
              </Hoverable> : null}
            {notStarted || (structureLengths.length === 3 && structureLengths[1] > 0) ?
              <Hoverable tooltip={'Pre-Appt.'} onPress={() => setViewingTimeline('pre')} onHover={() => setAppointmentHover('pre')} doneHover={() => setAppointmentHover(undefined)}>
                <Entypo name="back-in-time" style={[styles.appointmentControl, viewingTimeline === 'pre' ? styles.appointmentActive : {}, appointmentHover === 'pre' ? styles.appointmentHovering : {}]} size={22} color={Constants.colors.darkGrey} />
              </Hoverable> : null}
            {notStarted || (structureLengths.length === 3 && structureLengths[2] > 0) ?
              <Hoverable tooltip={'Post-Appt.'} onPress={() => setViewingTimeline('post')} onHover={() => setAppointmentHover('post')} doneHover={() => setAppointmentHover(undefined)}>
                <Entypo name="back-in-time" style={[styles.appointmentControl, viewingTimeline === 'post' ? styles.appointmentActive : {}, appointmentHover === 'post' ? styles.appointmentHovering : {}, { transform: 'scaleX(-1)' }]} size={22} color={Constants.colors.darkGrey} />
              </Hoverable> : null}
          </View>
        </View>
      )
    }
    return null;
  }

  const renderCampaignTimelines = () => {
    if (Constants.oppText || Constants.tattooCare) {
      if (!viewingTimeline) {
        return null;
      }

      let structureType = WpStructureType.FEATURE;
      let timelineKey = 'campaign-feature-initial';
      if (viewingTimeline !== 'instant') {
        structureType = viewingTimeline === 'pre' ? WpStructureType.BEFORE : WpStructureType.APPOINTMENT;
        timelineKey = viewingTimeline === 'pre' ? 'campaign-before-initial' : 'campaign-appointment-initial';
      }

      // console.log('Structure', state.campaign?.structure?.feature)

      return (
        <View style={{ flex: 1, position: 'relative' }}>
          {viewingTimeline === 'pre' ? renderTimeline(state?.campaign?.structure.before, structureType) : null}

          <CampaignFeature
            key={timelineKey}
            componentKey={timelineKey}
            isAppointment={viewingTimeline !== 'instant'}
            isFirst={viewingTimeline === 'instant' || (viewingTimeline === 'pre' && (!state.campaign?.structure?.before || state.campaign.structure?.before.length === 0))}
            isLast={(viewingTimeline === 'instant' && (!state.campaign?.structure?.feature || state.campaign.structure?.feature.length === 0)) || (viewingTimeline === 'post' && (state.campaign?.structure?.appointment.length ?? 0) === 0)}
            passedPitch={false}
            onSave={handleOnSave}
            structureType={structureType}
            onDelete={viewingTimeline === 'instant' ? undefined : removeAppt}
          />

          {viewingTimeline === 'instant' ? renderTimeline(state?.campaign?.structure.feature, structureType) : null}
          {viewingTimeline === 'post' ? renderTimeline(state?.campaign?.structure.appointment, structureType) : null}
        </View>
      )
    } else {
      return (
        <View style={{ flex: 1, position: 'relative' }}>
          <CampaignFeature
            key="campaign-feature-initial"
            isFirst={true}
            isLast={!state.campaign?.structure?.feature || state.campaign.structure?.feature.length === 0}
            passedPitch={false}
            onSave={handleOnSave}
            structureType={WpStructureType.FEATURE}
          />
          {renderTimeline(state?.campaign?.structure.feature)}
          <View style={styles.spacer}></View>
        </View>
      )
    }
  }

  const renderTimeline = (structure: WpCampaignStructure[] | undefined, structureType: WpStructureType = WpStructureType.FEATURE) => {
    if (structure) {
      let passedPitch = false;
      return (
        structure.map((feature, index, list) => {
          let isLast = false;
          if (index + 1 === list.length) {
            isLast = true;
          }

          if (feature.featureType === WpFeatureType.PITCH) {
            passedPitch = true;
          }

          return (
            <CampaignFeature
              key={`campaign-feature-${feature.id}`}
              index={index}
              featureId={feature.id}
              isLast={isLast && structureType !== WpStructureType.BEFORE}
              isFirst={index === 0 && structureType === WpStructureType.BEFORE}
              onSave={handleOnSave}
              setPitchId={setPitchId}
              passedPitch={true}
              onSelectSlide={onSelectSlide}
              structureType={structureType}
            />
          );
        })
      )

    }
    return null;
  }

  const removeAppt = () => {
    // updateCampaign({ variables: { campaignId: state.campaign?.id, hasAppt: false }})
    // dispatch({ type: WpCampaignAction.SET_HAS_APPT, hasAppt: false })
  }

  const startManagingLeads = () => {
    setManagingLeads(true);
  }

  const doneManagingLeads = () => {
    setManagingLeads(false);
  }

  const sartManagingCalendlyTrigger = () => {
    setManagingCalendlyTrigger(true);
  }

  const doneManagingCalendlyTrigger = () => {
    setManagingCalendlyTrigger(false);
  }

  const startManagingLandingPage = () => {
    // Toast.show({
    //   type: 'error',
    //   text1: 'Campaign landing pages currently won\'t send messages',
    //   text2: 'Click here to setup billing',
    //   visibilityTime: 40000
    //   // onPress: settingUpBilling
    // });
    // console.log("Toasting")
    // if (state.user && state.user.organization.billing) {
    //   Toast.show({
    //     type: 'error',
    //     text1: 'Campaign landing pages currently won\'t send messages',
    //     text2: 'Click here to setup billing',
    //     // onPress: settingUpBilling
    //   });
    // }
    setManagingLandingPage(true);
  }

  const doneManagingLandingPage = () => {
    setManagingLandingPage(false);
  }

  const doneEditingSlide = () => {
    setSlideId(undefined);
    handleOnSave();
  }

  const doneBranding = () => {
    setShowBranding(false);
    setNewImage(false);
    handleOnSave();
  }

  const openBranding = () => {
    setShowBranding(true);
  }

  const openArchiveCampaign = () => {
    setShowArchiving(true);
  }

  const saveBranding = () => {
    if (state.campaign) {
      updateCampaign({ variables: { campaignId: state.campaign.id, brandName: brandName, brandImage: brandImage, brandColor: brandColor } })
    }
    setShowBranding(false);
  }

  const saveCalendlyEventUri = () => {
    if (state.campaign && calendlyEventUri && calendlyEventUri.indexOf('|') > -1) {
      updateCampaign({ variables: { campaignId: state.campaign.id, calendlyEventUri: calendlyEventUri } })
    }
    doneManagingCalendlyTrigger();
  }

  const removeCalendlyEvent = () => {
    if (state.campaign?.calendlyEventUri) {
      updateCampaign({ variables: { campaignId: state.campaign.id, calendlyEventUri: null } })
    } else {
      setCalendlyEventUri(undefined)
    }
  }

  const onCloneCampaign = () => {
    if (state.campaign) {
      cloneCampaign({ variables: { campaignId: state.campaign.id } })
    }
  }

  const onManageLibrary = () => {
    if (state.campaign) {
      getCampaignCategories();
      setManagingLibrary(true);
    }
  }

  const onSuperAdminClone = () => {
    if (state.campaign) {
      cloneCampaign({ variables: { campaignId: state.campaign.id, organizationId: 1 } })
    }
  }

  const onNewImage = () => {
    setNewImage(true);
  }

  const onUploadComplete = (url: string, mime: string) => {
    setBrandImage(url);
    setNewImage(false);
  }

  const onArchiveCampaign = () => {
    if (state.campaign) {
      archiveCampaign({ variables: { campaignId: state.campaign.id } })
    }
  }

  const updatePreview = (val: string | string[] | null) => {
    let this_val: string = val;
    if (Array.isArray(val)) {
      if (val.length > 0) {
        this_val = val[0];
      } else {
        this_val = null;
      }
    }

    if (!this_val && previewItems.length) {
      this_val = previewItems[0].value;
    }

    setCurrentPreview(this_val)
    if (this_val && this_val.indexOf('feature') > -1) {
      setCurrentFeatureIndex(parseInt(this_val.split('-')[1]))
      setCurrentPitchId(undefined)
      setCurrentSlide(undefined)
    } else if (this_val && this_val.indexOf('pitch') > -1) {
      setCurrentPitchId(this_val.split('-')[1])
      loadSlide(this_val.split('-')[3])
    }
    setPitchRefresh(pitchRefresh + 1)
  }

  const loadSlide = (slideId: string) => {
    if (slideId) {
      if (getSlideData.data) {
        getSlideData.refetch({ pitchSlideId: slideId });
      } else {
        getSlide({ variables: { pitchSlideId: slideId } })
      }
    }
  }

  const onSelectCalendar = (uri: string | string[], label?: string) => {
    // https://api.calendly.com/users/EDBGTGZCWMWKDUDD|https://api.calendly.com/event_types/FFH4DXVMC43JQ6T2|https://calendly.com/jamescurran/whislepitchdemo
    let this_uri: string | undefined;

    if (Array.isArray(uri)) {
      if (uri.length) {
        this_uri = uri[0]
      } else {
        this_uri = undefined;
      }
    } else {
      this_uri = uri;
    }

    if (this_uri) {
      let uris = this_uri.split('|');
      if (uris.length === 3) {
        setCalendlyEventUri(this_uri)
        setCalendlyEventLink(uris[2])
        setCalendlyEventName(label)
      }
    }
  }

  const onHoverPrev = () => {
    setHovering("prev")
  }

  const onHoverNext = () => {
    setHovering("next")
  }

  const onHoverOut = () => {
    setHovering(undefined)
  }

  const onPrev = () => {
    let pindex = previewItems.findIndex((preview) => preview.value === currentPreview);
    if (pindex > 0) {
      updatePreview(previewItems[pindex - 1].value)
    }
  }

  const onNext = () => {
    let pindex = previewItems.findIndex((preview) => preview.value === currentPreview);
    if (pindex < previewItems.length - 1) {
      updatePreview(previewItems[pindex + 1].value)
    }
  }

  const handleChangeCategory = (value: string | string[] | null) => {
    if (value) {
      setCategory(value as string)
    }
  }

  const onEditCategory = () => {
    setEditingCategory(true);
  }

  const onClearCategory = () => {
    setCategory(null)
  }

  const onSaveLibrary = () => {
    if (state.campaign) {
      updateCampaign({
        variables: {
          campaignId: state.campaign.id,
          description: description ? description : null,
          public: isPublic,
          category: category ? category : null
        }
      })
    }
  }

  const onDoneManagingLibrary = () => {
    setManagingLibrary(false);
    setEditingCategory(false);
  }

  const renderBody = () => {
    if (state.campaign) {
      return (
        <View style={{ position: 'relative' }}>
          <WpModal isVisible={showBranding} closeModal={doneBranding}>
            <ModalBody title={`Edit Branding`} closeModal={doneBranding} width={500} >
              <Input name="Branding Name" info={'This will only be used if no Branding image is provided'} value={brandName} setValue={setBrandName} />
              <Input style={{ marginTop: 15, marginBottom: 20 }} name="Branding Color" isColor={true} value={brandColor} setValue={setBrandColor} />
              <Label name="Branding Image" />
              {brandImage && !newImage ?
                <View>
                  {ImageElement}
                  <Button style={{ marginTop: 25 }} width={'100%'} text="Upload New Brand Image" theme={Theme.LINK_PRIMARY} onPress={onNewImage} />
                </View> :
                <Uppyup plugins={[]} height={200} onDone={doneBranding} onUploadComplete={onUploadComplete} />
              }
              <ModalFooter>
                <Button key="btn-feature-save" text="Save" onPress={saveBranding} />
              </ModalFooter>
            </ModalBody>
          </WpModal>
          <WpModal isVisible={managingCalendlyTrigger} closeModal={doneManagingCalendlyTrigger}>
            <ModalBody title={`Start Campaign From Calendly`} closeModal={doneManagingCalendlyTrigger} width={500} >
              <View style={{ position: 'relative', zIndex: 5 }}>
                {calendlyEventUri ? <Button style={{ marginBottom: 10 }} width={'100%'} text={calendlyEventName} subText={calendlyEventLink} shadow={false} onDelete={removeCalendlyEvent} /> : null}
                <CustomFieldDropdown value={calendlyEventUri} setValue={onSelectCalendar} placeholder="Choose Calendly" calendarOnly={true} calendlyOnly={true} />
              </View>
              <ModalFooter>
                <Button key="btn-feature-save" text="Save" onPress={saveCalendlyEventUri} />
              </ModalFooter>
            </ModalBody>
          </WpModal>
          <WpModal isVisible={showArchiving} closeModal={setShowArchiving}>
            <ModalBody title={`Are you sure?`} closeModal={setShowArchiving} width={500} >
              <Text style={{ fontFamily: 'GothamMedium', fontSize: 14, color: "grey", paddingHorizontal: 10 }}>Deleting this campaign will remove all its related content and reporting. This cannot be undone.</Text>
              <ModalFooter>
                <Button key="btn-campaign-delete" text="Delete" theme={Theme.DANGER} onPress={onArchiveCampaign} />
              </ModalFooter>
            </ModalBody>
          </WpModal>
          <ManageLeadsModal title="Manage Campaign's Leads" editing={managingLeads} onClose={doneManagingLeads} campaignId={state.campaign.id} />
          <CampaignLandingPageModal isVisible={managingLandingPage} closeModal={doneManagingLandingPage} />
          <View style={styles.wrapper}>
            <Input style={{ marginTop: 40, marginBottom: 20, padding: 10, width: '100%', position: 'relative' }} isHeading={true} value={name} setValue={setName} />
            {renderAppointmentControls()}
            {renderCampaignTimelines()}
          </View>
        </View>
      )
    }
    return null;
  }

  const renderPreview = () => {
    if (showPreview && state.campaign) {
      return (
        <View style={{ width: 400, alignSelf: 'stretch', alignItems: 'center', backgroundColor: '#F2F2F2', padding: 20, position: 'relative' }}>
          <PageTitle title="Preview" size="Small" style={{ color: Constants.colors.darkBlue, marginBottom: 25 }} />
          <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', position: 'relative', zIndex: 5 }}>
            <Ionicons style={{ flex: 1, cursor: 'pointer', textAlign: 'center', opacity: hovering === "prev" ? 0.7 : 1.0 }} name={"chevron-back"} size={24} color={Constants.colors.blue} onPress={onPrev} onMouseEnter={onHoverPrev} onMouseLeave={onHoverOut} />
            <Dropdown style={{ flex: 4, zIndex: 5, position: 'relative', marginBottom: 5 }} placeholder={"Select Preview"} items={previewItems} value={currentPreview} onChange={updatePreview} />
            <Ionicons style={{ flex: 1, cursor: 'pointer', textAlign: 'center', opacity: hovering === "next" ? 0.7 : 1.0 }} name={"chevron-forward"} size={24} color={Constants.colors.blue} onPress={onNext} onMouseEnter={onHoverNext} onMouseLeave={onHoverOut} />
          </View>
          {currentPitchId ?
            <View style={{ height: 600, width: '100%', borderRadius: 10, overflow: 'scroll', backgroundColor: '#FFF', position: 'relative', zIndex: 1 }}>
              {/* <PitchProvider key={`pitch-${pitchRefresh}`} pitchId={currentPitchId} campaignId={state.campaign.id} /> */}

              <View style={{ alignItems: 'center' }}>
                <Header image={brandImage ? brandImage : userContext.state.user?.organization.image} name={brandName ? brandName : userContext.state.user?.organization.name} color={brandColor ? brandColor : userContext.state.user?.organization.color} onBack={onPrev} />
                <View style={[styles.body, { width: '100%', maxWidth: 500, marginBottom: 45 }]}>
                  {currentSlide ?
                    <View key={`pitch-${pitchRefresh}`} style={styles.slideWrapper}>
                      <SlideMainContent
                        slideId={currentSlide.id}
                        title={currentSlide.title}
                        slideType={currentSlide.slideType ? currentSlide.slideType : null}
                        thumbnail={currentSlide.thumbnail}
                        url={currentSlide.url}
                        audioFile={currentSlide.audioFile}
                        calendars={currentSlide.calendars}
                        isVideo={currentSlide.isVideo}
                        content={currentSlide.content}
                        color={brandColor ? brandColor : userContext.state.user?.organization.color}
                      />
                      <SlideWidgets color={brandColor ? brandColor : userContext.state.user?.organization.color} widgetStructure={currentSlide.widgetStructure} widgets={currentSlide.widgets} leadWidgets={[]} />
                    </View> : <Spinner />}
                </View>
                <View style={{ marginTop: 'auto' }}>
                  <PitchControls
                    onBack={onPrev}
                    onContinue={onNext}
                    showContinue={!!currentPreview && currentPreview.indexOf('last') === -1}
                    isFirst={true}
                    isLast={!!currentPreview && currentPreview.indexOf('last') > -1}
                    redirectType={pitchObjs[currentPitchId]?.redirectType}
                    redirectUrl={pitchObjs[currentPitchId]?.redirectUrl}
                    redirectButton={pitchObjs[currentPitchId]?.redirectButton}
                    redirectPhone={pitchObjs[currentPitchId]?.redirectPhone}
                    redirectPreview={true}
                    color={brandColor ? brandColor : userContext.state.user?.organization.color}
                  />
                </View>
                <Footer />
              </View>
            </View> :
            <View style={{ height: 300, width: '100%', borderRadius: 10, position: 'relative', zIndex: 1 }}>
              <MessageFeed onClickLink={onNext} messages={[messages[currentFeatureIndex]]} />
            </View>}
        </View>
      )
    } else {
      return null;
    }
  }

  return (
    <FullscreenModal
      isVisible={isVisible}
      onBack={onBack}
      onSave={!Constants.oppText && !Constants.tattooCare && state.campaign?.lastActive ? onReport : undefined}
      saveText="View Report"
      maxWidth="100%"
      controls={renderControls()}
    >
      <View style={{ flexDirection: 'row', flex: 1, alignItems: 'stretch', position: 'relative', overflow: 'hidden' }}>
        <View style={{ paddingBottom: 50, flex: 2, position: 'relative', overflow: 'scroll' }}>
          <PitchSlideContext.Provider value={{ state: slideContext[0], dispatch: slideContext[1] }}>
            <PitchSlideEditor slideId={slideId} isNew={isNew} onSave={doneEditingSlide} onClose={doneEditingSlide} />
            {renderBody()}
          </PitchSlideContext.Provider>

          <WpModal isVisible={managingLibrary} closeModal={onDoneManagingLibrary}>
            <ModalBody title={`Library Settings`} closeModal={onDoneManagingLibrary} width={500} >
              <Text style={{ fontFamily: 'GothamMedium', fontSize: 14, color: "grey", marginBottom: 20, position: 'relative', zIndex: 1 }}>The settings below manage how this campaign is presented to other {Constants.brand} users if you choose to make it public.</Text>
              <Input style={{ marginBottom: 10 }} multiline={true} key="library-description" name="Description" placeholder="Describe your campaign and it's uses to other users." value={description} setValue={setDescription} />
              <View style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <Label name={`Category`} />
                {editingCategory ?
                  <Button width={'auto'} small={true} buttonStyles={{ padding: 0 }} text="Clear" theme={Theme.LINK_PRIMARY} onPress={onClearCategory} /> :
                  <Button width={'auto'} small={true} buttonStyles={{ padding: 0 }} text="Edit" theme={Theme.LINK_PRIMARY} onPress={onEditCategory} />}
              </View>
              {editingCategory ?
                <Input style={{ marginBottom: 10 }} key="category-input" placeholder="Category" value={category ?? ''} setValue={setCategory} /> :
                <Dropdown
                  style={{ marginBottom: 10, position: 'relative', zIndex: 10 }}
                  key="library-category"
                  placeholder="Select Category"
                  items={categoryItems}
                  value={category ?? ''}
                  onChange={handleChangeCategory}
                  addCustomItem={true}
                  searchPlaceholder={"Search or create new..."}
                />}
              <View style={{ marginVertical: 10, position: 'relative', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', zIndex: 1 }}>
                <Label name="Campaign Is Public" />
                <Checkbox onSelect={() => setIsPublic(!isPublic)} style={{ margin: 0 }} colorActive={Constants.colors.blue} selected={isPublic} />
              </View>
              <ModalFooter>
                <Button key="btn-campaign-save" text="Save" theme={Theme.PRIMARY} loading={updateCampaignData.loading} disabled={updateCampaignData.loading} onPress={onSaveLibrary} />
              </ModalFooter>
            </ModalBody>
          </WpModal>
        </View>
        {renderPreview()}
      </View>
    </FullscreenModal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  divider: {
    alignSelf: 'stretch',
    backgroundColor: Constants.colors.secondaryLight,
    marginTop: 30,
    marginBottom: 30,
    height: 1
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    marginVertical: 15,
    position: 'relative'
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20,
    marginVertical: 25
  },
  spacer: {
    marginBottom: 25
  },
  wrapper: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: 25,
    width: '100%'
  },
  appointmentControlGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  appointmentControls: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 25,
    zIndex: 25
  },
  appointmentControlStart: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRightWidth: 0,
  },
  appointmentControl: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: Constants.colors.mediumGrey,
    borderRadius: 5,
    margin: 2
  },
  appointmentActive: {
    backgroundColor: Constants.colors.lightGrey
  },
  appointmentHovering: {
    backgroundColor: Constants.colors.mediumGrey
  },
  appointmentControlEnd: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderLeftWidth: 0,
  },
  body: {
    flex: 1,
    flexDirection: 'column'
  },
  slideWrapper: {
    flex: 1,
    position: 'relative',
    paddingTop: 100
  },
});

const cssStyles = {
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginBottom: 35
  },
  plusIcon: {
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginLeft: 10
  }
}
