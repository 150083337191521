import { Text, View, TextInput, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { WpWorkflowAction, WpWorkflowActionType, CampaignContext, WpWorkflowExpression } from './../contexts/campaign'
import { WpPitchSlide, WpSlideWidget, WpSlideType, WpSlideStructure, WpWidgetType } from './../contexts/pitch'
import { UserContext, WpModelSummary } from './../contexts/user'
import WorkflowActionOptions from './WorkflowActionOptions';
import Dropdown, { Item } from './Dropdown';
import CampaignDropdown from './CampaignDropdown';
import UserDropdown from './UserDropdown';
import MessageEditor from './MessageEditor';
import ModalFooter from './ModalFooter';
import Constants from './../constants';
import { Button, Theme } from './Button';
import Input, { Label, Info } from './Input';
import { GET_PITCH_SLIDES } from '../graphql/queries';
import { CREATE_CAMPAIGN_ACTION, UPDATE_CAMPAIGN_ACTION } from '../graphql/campaign';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Switch } from 'react-native-switch';

// const defaultMessage = {"blocks": [{"key": "1rgdk", "text": "Lead First Name Lead Last Name If/Then Description. You can reach them at Lead Phone", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [{"offset": 0, "length": 15, "key": 0}, {"offset": 16, "length": 14, "key": 1}, {"offset": 31, "length": 19, "key": 2}, {"offset": 74, "length": 10, "key": 3}], "data": {}}], "entityMap": {"0": {"type": "TOKEN", "mutability": "IMMUTABLE", "data": {"color": "#3961e5", "id": "#lead_first_name", "label": "Lead First Name"}}, "1": {"type": "TOKEN", "mutability": "IMMUTABLE", "data": {"color": "#8ea7f7", "id": "#lead_last_name", "label": "Lead Last Name"}}, "2": {"type": "TOKEN", "mutability": "IMMUTABLE", "data": {"color": "#B63070", "id": "#lead_actions", "label": "If/Then Description"}}, "3": {"type": "TOKEN", "mutability": "IMMUTABLE", "data": {"label": "Lead Phone", "id": "#lead_phone", "color": "#1E58F0"}}}}

export default function WorkflowActionEditor({ action, workflowId, onSave, closeModal } : { action:WpWorkflowAction, workflowId: string, onSave:Function, closeModal:Function }): JSX.Element {
  const campaignContext = React.useContext(CampaignContext);
  const userContext = React.useContext(UserContext);

  const [actionType, setActionType] = useState<WpWorkflowActionType>();
  const [messageContent, setMessageContent] = useState<object>();
  const [message, setMessage] = useState<string | null>(null);
  const [actionDefault, setDefault] = useState<boolean>(true);
  const [webhookUrl, setWebhookUrl] = useState<string>();
  const [nextCampaignId, setNextCampaignId] = useState<string>();
  const [userIds, setUserIds] = useState<string[]>([]);

  const [getPitchSlides, { data, networkStatus, refetch }] = useLazyQuery(GET_PITCH_SLIDES, {
    notifyOnNetworkStatusChange: true
  });
  const [updateAction, updateActionData] = useMutation(UPDATE_CAMPAIGN_ACTION, {
    fetchPolicy: "no-cache"
  });
  const [createCampaignAction, createActionData] = useMutation(CREATE_CAMPAIGN_ACTION, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    setActionType(action.actionType)
    setMessageContent(action.messageContent)
    setDefault(action.default)

    // if (!action.messageContent && (action.actionType === WpWorkflowActionType.ME || action.actionType === WpWorkflowActionType.SPECIFIC)) {
    //   setMessageContent(defaultMessage)
    // }
    setWebhookUrl(action.webhookUrl)
    setNextCampaignId(action.nextCampaignId)
    setUserIds(action.userIds)
  }, [action])

  useEffect(() => {
    if (updateActionData.data?.updateWorkflowAction?.action) {
      onSave(updateActionData.data.updateWorkflowAction.action)
    }
  }, [updateActionData.data]);

  useEffect(() => {
    if (createActionData.data?.createWorkflowAction.success) {
      saveAction(createActionData.data?.createWorkflowAction.action.id)
    }
  }, [createActionData.data]);

  const setNotDefault = () => {
    setDefault(false);
  }

  const getSettings = () => {
    if (actionType) {
      if ([WpWorkflowActionType.ME, WpWorkflowActionType.SPECIFIC, WpWorkflowActionType.LEAD].indexOf(actionType) > -1) {
        return (
          <View style={{ position: 'relative', zIndex: 10 }}>
            { actionType === WpWorkflowActionType.SPECIFIC ? <UserDropdown style={{ marginBottom: 15, position: 'relative', zIndex: 12 }} label={`Users`} info={"The users that will recieve the text notification."} placeholder="Select User" multiple={true} value={userIds} setValue={setUserIds} /> : null }
            { actionDefault && !messageContent && actionType !== WpWorkflowActionType.LEAD ?
              <View>
                <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.info }}>{WpWorkflowActionType.ME ? 'We will send you a pre-filled notification to let you' : 'We will send selected users a pre-filled notification to let them'} know when a lead completes your list of Ifs or you can <a style={{ color: Constants.colors.blue, opacity: 0.7 }} href="#" onClick={setNotDefault}>make your own</a></Text>
              </View>
              : <MessageEditor action={actionType !== WpWorkflowActionType.LEAD} leadPhone={actionType !== WpWorkflowActionType.LEAD} messageContent={messageContent} setMessageContent={setMessageContent} setMessage={setMessage} /> }
          </View>
        )
      } else if (WpWorkflowActionType.CAMPAIGN === actionType) {
        return (
          <View>
            <CampaignDropdown optional={false} value={nextCampaignId} setValue={setNextCampaignId} />
          </View>
        )
      } else if (WpWorkflowActionType.WEBHOOK === actionType) {
        return (
          <View>
            <Input name="Webhook URL" value={webhookUrl} setValue={setWebhookUrl} placeholder={"Enter Webhook URL..."} />
          </View>
        )
      }
    }
  }

  const saveAction = (id?: string) => {
    if (action.id === 'new' && !id) {
      createCampaignAction({ variables: {campaignWorkflowId: workflowId}});
    } else {
      let newAction = {
        workflowActionId: id ? id : action.id,
        actionType: actionType,
        messageContent: messageContent,
        webhookUrl: webhookUrl,
        userIds: userIds,
        nextCampaignId: nextCampaignId
      }

      updateAction({ variables: newAction })
    }
  }

  const onChangeActionType = (type: WpWorkflowActionType) => {
    setActionType(type)
    // if (!messageContent && (type === WpWorkflowActionType.ME || type === WpWorkflowActionType.SPECIFIC)) {
    //   setMessageContent(defaultMessage)
    // } else if (messageContent === defaultMessage && type === WpWorkflowActionType.LEAD) {
    //   setMessageContent(undefined)
    // }
  }

  if (!action) {
    return (<View></View>)
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <WorkflowActionOptions active={actionType} onPress={onChangeActionType} />
      <View style={{ flex: 1, position: 'relative', marginTop: 20, zIndex: 2 }}>
        { getSettings() }
      </View>

      <ModalFooter>
        <Button key="btn-action-edit-save" text="Save" onPress={saveAction} />
      </ModalFooter>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
});
