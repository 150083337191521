import { View, StyleSheet, Text, ScrollView, Image } from 'react-native';
import React from 'react';
import Modal from "react-native-modal";
import Constants from './../../constants';
import { Feather } from '@expo/vector-icons';
import { Button, BrandLogo } from '@whistlepitch/wp-components'


export default function ModalNavbar({ saveText, icon, onSave, onBack, children } : { saveText?: string, icon?: string, onSave?: () => void, onBack: () => void, children: React.ReactNode }): JSX.Element {
  const handleOnSave = () => {
    if (onSave !== undefined) {
      onSave()
    }
  }

  const handleOnBack = () => {
    onBack()
  }

  const loadLogo = () => {
    if (Constants.tattooCare) {
      return <Image style={{ width: 30, height: 30 }} source={require('./../../assets/tattoocare_logo.png')} />
    }
    return <BrandLogo brand={Constants.brand} />
  }

  return (
    <View style={styles.navbar}>
      {/* Branding and back button */}
      <View style={[styles.navbarSection, { justifyContent: 'flex-start' }]}>
        { loadLogo() }

        <Text style={{ fontWeight: 'bold', color: Constants.colors.primary, paddingLeft: 20 }} onPress={handleOnBack}>← Back</Text>
      </View>

      {/* Center Children */}
      <View style={[styles.navbarSection, { justifyContent: 'center' }]}>
        { children }
      </View>

      {/* Save button and optional icon */}
      <View style={[styles.navbarSection, { justifyContent: 'flex-end' }]}>
        { onSave === undefined ? null :
          <Button type="Primary" size="Default" shape="Pill" onClick={handleOnSave}>
            <Text style={{ color: '#FFF', fontWeight: 'bold' }}>{saveText}</Text>
          </Button>
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  navbar: {
    width: '100%',
    height: 70,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15.19,
    shadowColor: Constants.colors.dark,
    elevation: 5
  },
  navbarSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: 20
  }
});
