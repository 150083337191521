import { Text, View, StyleSheet, Dimensions, ScrollView, Image } from 'react-native';
import React, { useState, useEffect } from 'react';
import { PitchContext, PitchSlideContext, slideReducer, WpPitchAction } from '../contexts/pitch';
import WpModal from './WpModal';
import { Button, Theme } from './Button';
import PitchSlideThumbnail from './PitchSlideThumbnail';
import PitchSlideEditor from './Pitch/PitchSlideEditor';
import PitchTermintor from './PitchTermintor';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import SettingsDots from './SettingsDots';
import Input from './Input';
import PitchControls from './PitchControls';
import Uppyup from './Uppyup';
import CloseIcon from './CloseIcon';
import Constants from './../constants';
import { TrashIcon, DuplicateIcon, ExternalLinkIcon, XIcon } from '@heroicons/react/outline';
import PitchSlideCreateButton from './PitchSlideCreateButton';
import { UPDATE_PITCH } from '../graphql/pitch';
import { GET_PITCH } from '../graphql/queries';
import { useLazyQuery, useMutation } from "@apollo/client";


const window = Dimensions.get('window');

export default function PitchEditor({ pitchId, onClose=null } : { pitchId:string|null, onClose?:Function }): JSX.Element {
  const [ slideState, slideDispatch ] = React.useReducer(slideReducer, {slide: null});
  const { state, dispatch } = React.useContext(PitchContext);

  const [dimensions, setDimensions]= useState(window)

  const [slideId, setSlideId] = useState<string|null>(null)
  const [title, setTitle] = useState<string|null>(null)
  const [color, setColor] = useState<string>()
  const [image, setImage] = useState<string>()
  const [hovering, setHovering] = useState<boolean>()
  const [usingRedirect, setUsingRedirect] = useState<boolean>()
  const [ImageElement, setImageElement] = useState()
  const [titleTimeout, setTitleTimeout] = useState<timeout>()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [startEditing, setStartEditing] = useState<boolean>(false)
  const [aspectRatio, setAspectRatio] = useState(1);

  const [getPitch, getPitchData] = useLazyQuery(GET_PITCH, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [updatePitch, updatePitchData] = useMutation(UPDATE_PITCH, {
    fetchPolicy: "no-cache"
  })

  useEffect(() => {
    if (!image) {
      return;
    }

    let isValid = true;
    Image.getSize(image, (width, height) => {
      if (isValid) {
        setAspectRatio(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [image]);

  useEffect(() => {
    if (image) {
      setImageElement(<Image style={{ borderRadius: 10, width: 150, aspectRatio }} source={{ uri: image }} />)
    }
  }, [aspectRatio, image])

  useEffect(() => {
    if (state.pitch && pitchId) {
      setTitle(state.pitch.title);
      if (state.pitch.color) {
        setColor(state.pitch.color);
      }
      if (state.pitch.image) {
        setImage(state.pitch.image);
      } else {
        setImage(undefined)
        setImageElement(<Uppyup height={175} width={250} plugins={[]} onDone={() => {}} onUploadComplete={onUploadComplete} />)
      }
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [state.pitch])

  useEffect(() => {
    if (getPitchData.data?.pitch?.pitch) {
      dispatch({ type: WpPitchAction.REFRESH_PITCH, pitch: getPitchData.data?.pitch?.pitch})
    }
  }, [getPitchData.data, getPitchData.networkStatus])

  useEffect(() => {
    if (updatePitchData.data) {
      loadPitch();
    }
  }, [updatePitchData.data])

  useEffect(() => {
    if (pitchId) {
      loadPitch();
    }
  }, [pitchId])

  const loadPitch = () => {
    if (getPitchData.data) {
      getPitchData.refetch({ pitchId: pitchId });
    } else {
      getPitch({ variables: { pitchId: pitchId }})
    }
  }

  if (!state.pitch) {
    return (<View></View>)
  }

  const closeModal = () => {
    setIsVisible(false);

    setTimeout(() => {
      dispatch({ type: WpPitchAction.CLEAR_PITCH })
    }, 350)

    if (onClose !== null) {
      onClose();
    }
  }

  const onSelectSlide = (id: string) => {
    setSlideId(id)
  }

  const closeEditor = () => {
    loadPitch()
    setSlideId(null)
  }

  const updateRedirect = (redirectUrl?:string, redirectPhone?:string, redirectButton?:string) => {
    updatePitch({ variables: { pitchId: pitchId, redirectUrl: redirectUrl, redirectPhone: redirectPhone, redirectButton: redirectButton }})
  }

  const clearRedirect = () => {
    updatePitch({ variables: { pitchId: pitchId, redirectType: 'NONE' }})
  }

  const onLink = () => {
    setStartEditing(true);
    updatePitch({ variables: { pitchId: pitchId, redirectType: 'LINK' }})
  }

  const onContact = () => {
    setStartEditing(true);
    updatePitch({ variables: { pitchId: pitchId, redirectType: 'CONTACT' }})
  }

  const updateTitle = (val:string) => {
    setTitle(val)

    if (titleTimeout) {
      clearTimeout(titleTimeout)
    }

    setTitleTimeout(setTimeout(() => {
      updatePitch({ variables: { pitchId: pitchId, title: val }})
    }, 1200))
  }

  const updateColor = (val:string) => {
    setColor(val)
    updatePitch({ variables: { pitchId: pitchId, color: val }})
  }

  const updateImage = (val:string) => {
    setImage(val)
    updatePitch({ variables: { pitchId: pitchId, image: val }})
  }

  const removeImage = () => {
    setImage(undefined)
    updatePitch({ variables: { pitchId: pitchId, image: null }})
  }

  const onDone = () => {
    closeModal();
  }

  const onUploadComplete = (url:string, mimeType:string) => {
    updateImage(url);
  }

  const startUsingRedirect = () => {
    setUsingRedirect(true);
  }

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  const renderRedirect = () => {
    if (state.pitch?.redirectType) {
      return <PitchTermintor type={state.pitch.redirectType} startEditing={startEditing} onClear={clearRedirect} onSave={updateRedirect} />
    } else if (usingRedirect) {
      return (
        <View style={styles.terminators}>
          <Button width={170} text="+ Redirect to URL" theme={Theme.ACCENT} onPress={onLink} />
          <Button width={170} text="+ Prompt to Call/Text" theme={Theme.PRIMARY} onPress={onContact} />
        </View>
      )
    }
    return null;
  }

  return (
    <View>
      <WpModal isVisible={isVisible} >
        <CloseIcon onPress={closeModal} />
        <ScrollView style={styles.scrollView} contentContainerStyle={{  }}>
          <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start' }}>
            <View style={{ width: 250 }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Branding Logo</Text>
              { ImageElement }
              { image ? <TrashIcon style={{...cssStyles.icon, color: hovering ? Constants.colors.danger : Constants.colors.info}} onClick={removeImage} onMouseEnter={onHover} onMouseLeave={onHoverOut} /> : null }
            </View>

            <View style={{ alignItems: 'center'}}>
              <View style={{ position: 'relative' }}>
                <SettingsDots>
                  <PitchControls />
                </SettingsDots>
                <Input style={{ width: dimensions.width * 0.8, maxWidth: 250, marginLeft: 'auto', marginRight: 'auto' }} name="Name" value={title} setValue={updateTitle} />
              </View>
              { !state.pitch || !state.pitch.structure?.length ?
                <PitchSlideCreateButton key={`pitch-slide-first-option`} first='Create New Slide' onPress={onSelectSlide} /> :
                state.pitch?.structure.map((slide, index, list) => {
                  let isLast = false;
                  if (index + 1 === list.length) {
                    isLast = true;
                  }

                  return (
                    <PitchSlideThumbnail key={`pitch-slide-${slide.id}-option`} isLast={isLast} slide={slide} onPress={onSelectSlide} />
                  );
                })
              }
              { renderRedirect() }
            </View>

            <View>
              <Input style={{ width: 250 }} name="Branding Color" isColor={true} value={color} setValue={updateColor} />
              { !state.pitch.redirectType && !usingRedirect ? <Button style={{ marginLeft: 'auto' }} width={150} text="Add Redirect" onPress={startUsingRedirect} /> : null }
            </View>
          </View>
        </ScrollView>
      </WpModal>
      <WpModal isVisible={slideId !== null} closeModal={closeEditor}>
        <PitchSlideContext.Provider value={{ state: slideState, dispatch: slideDispatch }}>
          <PitchSlideEditor slideId={slideId} closeModal={closeEditor} />
        </PitchSlideContext.Provider>
      </WpModal>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: '100%',
    margin: 0,
    paddingVertical: 70
  },
  terminators: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30
  },
  pitch: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 10,
  }
});

const cssStyles = {
  icon: {
    color: '#FFF',
    width: 15,
    height: 15,
    position: 'absolute',
    top: 15,
    right: 75,
    cursor: 'pointer'
  },
}
