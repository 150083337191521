import { View, StyleSheet, Text, FlatList } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import Constants from './../../constants';
import { MaterialIcons } from '@expo/vector-icons';
import { WpLeadFeature, WpLeadMessage } from './../../contexts/lead';
import { formatDateString, stringToDate } from './../../tools/tools';
import { ClockIcon } from '@heroicons/react/solid'
import Spinner from '../Spinner';
import { useMutation } from '@apollo/client';
import { RETRY_CAMPAIGN_FEATURE_QUEUE } from '../../graphql/campaign';
import { FlashList } from '@shopify/flash-list'


export function MessageBubble({ userId, message, onClickLink, pending = false, aiGenerated = false, margin = '45%', maxWidth = '50%', backgroundColor = '#F3F3F3' }: { userId?: string, message?: string, onClickLink?: () => void, pending?: boolean, aiGenerated?: boolean, margin?: string, maxWidth?: string, backgroundColor?: string }): JSX.Element {
  let bubble, arrow, color, arrowOverlap;

  if (aiGenerated) {
    bubble = [styles.bubbleSent, { backgroundColor: Constants.colors.darkPurple }];
    arrow = [styles.rightArrow, { backgroundColor: Constants.colors.darkPurple }];
    color = '#FFF';
    arrowOverlap = styles.rightArrowOverlap;
  } else if (userId !== undefined && userId !== null) {
    bubble = [styles.bubbleSent, { marginLeft: margin }];
    arrow = styles.rightArrow;
    color = '#FFF';
    arrowOverlap = styles.rightArrowOverlap;
  } else if (pending) {
    bubble = [styles.bubbleSent, { backgroundColor: Constants.colors.primaryMedium }];
    arrow = [styles.rightArrow, { backgroundColor: Constants.colors.primaryMedium }];
    color = '#FFF';
    arrowOverlap = styles.rightArrowOverlap;
  } else {
    bubble = [styles.bubbleReceived, { marginRight: margin }];
    arrow = styles.leftArrow;
    color = '#333';
    arrowOverlap = styles.leftArrowOverlap;
  }


  return (
    <View style={[bubble, { maxWidth: maxWidth }]}>
      <Text style={{ fontSize: 14, color: color, justifyContent: "center", cursor: !!onClickLink ? 'pointer' : 'default' }} onClick={onClickLink}>{message}</Text>
      <View style={arrow}></View>
      <View style={[arrowOverlap, { backgroundColor: backgroundColor }]}></View>
    </View>
  )
}


export default function MessageFeed({ messages, queuedMessage = undefined, featureQueue = undefined, onClickLink, backgroundColor = '#F3F3F3', masked = false }: { messages: WpLeadMessage[], queuedMessage?: WpLeadMessage, featureQueue?: WpLeadFeature[], onClickLink?: () => void, backgroundColor?: string, masked?: boolean }): JSX.Element {
  const [messageList, setMessageList] = useState<MessageOrFeature[]>([]);
  const [mergedList, setMergedList] = useState<MessageOrFeature[]>([]);
  const [retrying, setRetrying] = useState<string>('');
  const [retried, setRetried] = useState<string[]>([]);
  const flashListRef = useRef(null);

  const [retryCampaignFeatureQueue, { data, loading, error }] = useMutation(RETRY_CAMPAIGN_FEATURE_QUEUE, {
    fetchPolicy: "no-cache",
  });

  // Merge messages and features
  useEffect(() => {
    const newList = mergeMessagesAndFeatures(messages, featureQueue ?? []);
    setMergedList(newList);
  }, [messages, featureQueue]);

  useEffect(() => {
    if (queuedMessage) {
      setMessageList([...mergedList, queuedMessage]);
    } else {
      setMessageList(mergedList);
    }
  }, [mergedList, queuedMessage]);

  useEffect(() => {
    if (data && retrying) {
      setRetried([...retried, retrying]);
      setRetrying('');
    }
  }, [data, retrying]);

  useEffect(() => {
    if (flashListRef.current && messageList.length > 0) {
      setTimeout(() => {
        flashListRef.current.scrollToEnd({ animated: false });
      }, 100);
    }
  }, [messageList]);


  const onRetry = (itemId: string) => {
    if (retrying.indexOf(itemId) === -1) {
      setRetrying(itemId);
      retryCampaignFeatureQueue({ variables: { campaignFeatureQueueId: itemId } });
    }
  }

  function isWpLeadMessage(item: any): item is WpLeadMessage {
    return (item as WpLeadFeature).complete === undefined;
  }

  const renderItem = ({ item, index }: { item: MessageOrFeature, index: number }) => {
    if (item) {
      let justifyContent: 'flex-end' | 'flex-start' = 'flex-end';
      let senderName = 'Sending';
      let date;
      let subtext;
      let userId;
      let aiGenerated;
      let error = false;
      let scheduled = false;
      let optOut = false;
      let alreadyRetried = false;
      let pending = queuedMessage && item.id === queuedMessage.id;
      if (isWpLeadMessage(item)) {
        userId = item.userId;
        aiGenerated = item.aiGenerated;
        justifyContent = userId || aiGenerated ? 'flex-end' : 'flex-start';
        senderName = aiGenerated ? 'AI' : item.senderName ? `${item.senderName}` : ''
        // Truncate senderName
        if (senderName.length > 30) {
          senderName = senderName.substring(0, 30) + '...';
        }

        date = pending ? (item.created ? ' - ' + formatDateString(item.created) : '') : (item.sent ? ' - ' + formatDateString(item.sent) : '');
        subtext = pending ? `Sending${date}` : `${senderName}${date}`;
      } else {
        pending = true;
        error = !!item.error;
        optOut = item.error?.indexOf('opted out') !== -1;
        alreadyRetried = retried.indexOf(item.id) !== -1;
        scheduled = !error;
        senderName = error ? 'Error' : 'Scheduled';
        if (alreadyRetried) {
          senderName = 'Retrying';
        }
        date = item.scheduledFor ? ' - ' + formatDateString(item.scheduledFor) : '';
        subtext = `${senderName}${date}`;
      }

      // let textJustify = { alignSelf }
      if (masked && senderName !== 'Sending') {
        senderName = Constants.localizations.Lead
      }

      return (
        <View style={styles.bubbleWrapper} key={index}>
          <MessageBubble key={index} userId={userId} pending={pending} aiGenerated={aiGenerated} message={item.message} onClickLink={onClickLink} backgroundColor={backgroundColor} />
          <View style={{ flexDirection: 'row', justifyContent, alignItems: 'center' }}>
            {error && !optOut && !retrying && !alreadyRetried && <MaterialIcons name="refresh" size={14} color={Constants.colors.primary} style={{ marginTop: 4, marginRight: 3, cursor: 'pointer' }} onClick={() => onRetry(item.id)} />}
            {error && !optOut && retrying === item.id && <Spinner size={12} style={{ marginTop: 4, marginRight: 3 }} />}
            {error && <MaterialIcons name="error-outline" size={14} color={Constants.colors.red} style={{ marginTop: 4 }} />}
            {scheduled && <ClockIcon color="grey" style={cssStyles.icon} />}
            <Text style={[styles.messageSubtext, error || scheduled ? { paddingLeft: 3 } : {}]}>{subtext}</Text>
          </View>
        </View>
      )
    } else {
      return null
    }
  }

  const renderEmptyContainer = () => {
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', marginVertical: 15 }}>
        <Text style={{ color: Constants.colors.secondary, fontFamily: 'GothamMedium' }}>- No Messages Found -</Text>
      </View>
    )
  }

  return (
    <FlashList
      key={'message-feed'}
      ref={flashListRef}
      data={messageList}
      estimatedItemSize={100}
      renderItem={renderItem}
      keyExtractor={(item, index) => index.toString()}
      contentContainerStyle={{ paddingVertical: 10, backgroundColor }}
      ListEmptyComponent={renderEmptyContainer()}
    />
  );
}

// Helper function to merge messages and features
function mergeMessagesAndFeatures(messages: WpLeadMessage[], features: WpLeadFeature[]): MessageOrFeature[] {
  let merged: MessageOrFeature[] = [];
  let i = 0, j = 0;

  while (i < messages.length && j < features.length) {
    // Assuming 'created' in messages and 'scheduledFor' in features are ISO date strings
    if (!messages[i].created || stringToDate(messages[i].created ?? '') <= stringToDate(features[j].scheduledFor)) {
      merged.push(messages[i]);
      i++;
    } else {
      merged.push(features[j]);
      j++;
    }
  }

  // Add any remaining items from either array to the merged array
  while (i < messages.length) {
    merged.push(messages[i]);
    i++;
  }

  while (j < features.length) {
    merged.push(features[j]);
    j++;
  }

  return merged;
}

type MessageOrFeature = WpLeadMessage | WpLeadFeature;

const styles = StyleSheet.create({
  messageSubtext: {
    color: Constants.colors.info,
    fontSize: 12,
    paddingHorizontal: 35,
    marginTop: 4,
  },
  bubbleWrapper: {
    width: '100%',
    flexDirection: 'column',
    marginVertical: 8
  },
  bubbleReceived: {
    backgroundColor: "#E3E3E3",
    padding: 10,
    marginLeft: '5%',
    borderRadius: 15,
    marginTop: 5,
    marginRight: "45%",
    maxWidth: '50%',
    alignSelf: 'flex-start',
  },
  bubbleSent: {
    backgroundColor: Constants.colors.primary,
    padding: 10,
    marginLeft: '45%',
    borderRadius: 15,
    marginTop: 5,
    marginRight: "5%",
    maxWidth: '50%',
    alignSelf: 'flex-end',
  },
  rightArrow: {
    position: "absolute",
    backgroundColor: Constants.colors.primary,
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomLeftRadius: 25,
    right: -10
  },
  rightArrowOverlap: {
    position: "absolute",
    backgroundColor: "#F3F3F3",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomLeftRadius: 18,
    right: -20
  },

  /*Arrow head for recevied messages*/
  leftArrow: {
    position: "absolute",
    backgroundColor: "#E3E3E3",
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomRightRadius: 25,
    left: -10
  },
  leftArrowOverlap: {
    position: "absolute",
    backgroundColor: "#F3F3F3",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomRightRadius: 18,
    left: -20
  },
})

const cssStyles = {
  icon: {
    width: 14,
    height: 14,
    minWidth: 14,
    minHeight: 14,
    color: Constants.colors.grey,
    marginTop: 4
  }
}