import React, { useState, useEffect } from 'react';
import constants from '../constants';
import { UserContext } from '../contexts/user';
import BasicLayout from '../components/Shared/BasicLayout';
import Spinner from '../components/Spinner';
import Heading from '../components/Shared/Heading';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigate } from '../react-router';


function renderStatusRow(label: string, status?: string) {
  return (
    <div style={statusRowStyle}>
      <div style={statusLabelStyle}>{label}</div>
      <div>
        {status === 'reachable' || status === 'success' ? <FontAwesome name="check-circle" size={24} color={constants.colors.green} /> : !!status ? <FontAwesome name="times-circle" size={24} color={constants.colors.red} /> : <Spinner />}
      </div>
    </div>
  );
}

function StatusBody({ name }: { name: 'WhistlePitch' | 'BlastView' | 'TattooCare' | 'OppText' }) {
  const { state, dispatch } = React.useContext(UserContext)

  const [uiStatus, setUiStatus] = useState<string>();
  const [apiStatus, setApiStatus] = useState<string>();
  const [celeryStatus, setCeleryStatus] = useState<string>();
  const [beatStatus, setBeatStatus] = useState<string>();

  useEffect(() => {
    if (state.user?.token && name) {
      // Options for the POST request
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };

      // // Check UI URL
      // fetch(constants.app_urls[name] + '/health', { method: 'GET' })
      //   .then(response => setUiStatus(response.ok ? 'reachable' : 'unreachable'))
      //   .catch(error => setUiStatus('unreachable'));

      // Check API URL
      fetch(constants.base_urls[name], { method: 'HEAD' })
        .then(response => setApiStatus(response.ok ? 'reachable' : 'unreachable'))
        .catch(error => setApiStatus('unreachable'));

      // Fetch celery status
      fetch(`${constants.base_url}/super/status/${state.user.token}`, {
        ...options,
        body: JSON.stringify({ brand: name, stack: 'celery' })
      })
        .then(response => response.json())
        .then(data => setCeleryStatus(data.status))
        .catch(error => setCeleryStatus('error'));

      // Fetch beat status
      fetch(`${constants.base_url}/super/status/${state.user.token}`, {
        ...options,
        body: JSON.stringify({ brand: name, stack: 'beat' })
      })
        .then(response => response.json())
        .then(data => setBeatStatus(data.status))
        .catch(error => setBeatStatus('error'));
    }
  }, [state.user, name]);

  return (
    <div
      key={`status-${name}`}
      style={statusBodyStyle}
    >
      <Heading text={name} />
      <div style={statusGridStyle}>
        {/* {renderStatusRow('UI', uiStatus)} */}
        {renderStatusRow('Async Stack', beatStatus)}
        {renderStatusRow('Backend', apiStatus)}
        {renderStatusRow('Async Workers', celeryStatus)}
      </div>
    </div>
  );
}


export default function Status() {
  const { state, dispatch } = React.useContext(UserContext)

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (state.user) {
  //     if (!state.user.wpAdmin) {
  //       navigate("/login")
  //     }
  //   }
  // }, [state.user])

  // if (!state.user) {
  //   return null;
  // }

  return (
    <BasicLayout style={{ flex: 1 }} active={'/status'}>
      <Heading text={`Status`} />
      <div style={gridStyle}>
        <StatusBody name="WhistlePitch" />
        <StatusBody name="OppText" />
        <StatusBody name="BlastView" />
        {/* <StatusBody name="TattooCare" /> */}
      </div>
    </BasicLayout>
  );
}

const gridStyle = {
  marginTop: 15,
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  '@media (maxWidth: 768px)': {
    flexDirection: 'column',
  },
};

const statusBodyStyle = {
  flex: '1 1 calc(50% - 10px)',  // Adjusts the flex property to fill the available space
  backgroundColor: '#fff',
  marginBottom: '40px',
  border: '1px solid ' + constants.colors.light,
  borderRadius: '8px',
  padding: '20px',
  boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
  '@media (maxWidth: 768px)': {
    flex: '1 1 100%',  // Adjusts the flex property to fill the available space on smaller screens
  },
};

const statusGridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '10px 20px',
  marginTop: 10,
};

const statusRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const statusLabelStyle = {
  textAlign: 'right',
  flex: 1,
  marginRight: 10
};