import { View, Text, ActivityIndicator, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from '../react-router';
import { useMutation, useQuery } from "@apollo/client";

import Constants from '../constants';
import { CampaignContext, WpCampaignAction, campaignReducer } from '../contexts/campaign';
import { UserContext, WpUser } from '../contexts/user';
import { GET_GROUP } from '../graphql/queries';
import CampaignOverview from '../components/CampaignOverview';
import CampaignEditor from '../components/Campaign/CampaignEditor';
import Navbar from '../components/Navbar';
import { DashboardNav } from '@whistlepitch/wp-components';
import LeadTable from '../components/Leads/LeadTable';
import Input from '../components/Input';
import Spinner from '../components/Spinner';
import { WpGroup } from '../contexts/lead';
import NewLeadModal from '../components/Leads/NewLeadModal';
import NewButton from '../components/NewButton';
import { UPDATE_GROUP } from '../graphql/lead';
import { GET_USERS } from './../graphql/queries';
import UserTable from '../components/User/UserTable';
import NewUserModal from '../components/User/NewUserModal';
import UserDetailsModal from '../components/User/UserDetailsModal';
import BasicLayout from '../components/Shared/BasicLayout';


export default function Users(): JSX.Element {
  const { state } = React.useContext(UserContext);

  const [showNewUser, setShowNewUser] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);

  const navigate = useNavigate();

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }

  const onOpenNewUser = () => {
    setShowNewUser(true)
  }

  const onCloseNewUser = () => {
    setShowNewUser(false)
  }

  const onRefresh = () => {
    setRefresh(refresh + 1)
  }

  return (
    <BasicLayout style={styles.container} active={'/groups'}>
      <View style={styles.header}>
        <Text style={styles.heading}>Users</Text>
        <NewButton name="User" onClick={onOpenNewUser} />
      </View>

      <UserTable refresh={refresh} />

      <NewUserModal isVisible={showNewUser} onClose={onCloseNewUser} onCreated={onRefresh} />
    </BasicLayout>
  )

  // return (
  //   <View style={styles.container}>
  //     <Navbar active={'/users'} />
  //     <View style={{ flex: 1, padding: 25 }}>
  //       <UserDetailsModal isVisible={showUserDetails} user={state.user ? state.user : undefined} onClose={closeUserDetails} />
  //       <DashboardNav name={state.user?.name ?? ''} dropOptions={[{ id: 'users', label: 'Users', value: 'users' }, { id: 'account', label: 'Account', value: 'account' }]} actionOne={state.user?.wpAdmin} onAction={onDashboardAction} onDropdownSelect={onDashboardAction} />
  //
  //       <View style={{ marginTop: 25, padding: 10 }}>
  //         <View style={styles.header}>
  //           <Text style={styles.heading}>Users</Text>
  //           <NewButton name="User" onClick={onOpenNewUser} />
  //         </View>
  //
  //         <UserTable refresh={refresh} />
  //
  //         <NewUserModal isVisible={showNewUser} onClose={onCloseNewUser} onCreated={onRefresh} />
  //       </View>
  //     </View>
  //   </View>
  // );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: Constants.colors.backgroundLight,
  },
  campaign: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
});
