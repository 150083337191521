import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import Constants from '../constants';
import { Access, UserContext, WpUserAction } from '../contexts/user';
import { WpEvent } from '../contexts/lead';
import GroupOverview from '../components/Leads/GroupOverview';
import Navbar from '../components/Navbar';
import Spinner from '../components/Spinner';
import ManageLeadsModal from '../components/Leads/ManageLeadsModal';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import Input, { Label } from '../components/Input';
import Thumbnail from '../components/Shared/Thumbnail';
import { GET_EVENTS } from '../graphql/queries';
import { CANCEL_EVENTS, ADD_CAMPAIGNS_TO_EVENTS, MESSAGE_EVENTS } from '../graphql/lead';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, IconButton, PageTitle, DashboardNav, DashboardLayout } from '@whistlepitch/wp-components'
import { Button as LocalButton, Theme } from './../components/Button';
import { PlusIcon } from '@heroicons/react/outline';
import { numberWithCommas } from '../tools/tools'
import Checkbox from '../components/Checkbox';
import Icon, { IconTypes } from '../components/Icon';
import WpModal from '../components/WpModal';
import MessageEditor from '../components/MessageEditor';
import CampaignDropdown from '../components/CampaignDropdown';
import Toast from 'react-native-toast-message';
import NewButton from '../components/NewButton';
import { useNavigate } from '../react-router';
import UserDetailsModal from '../components/User/UserDetailsModal';
import BasicLayout from '../components/Shared/BasicLayout';
import BillingModal from '../components/User/BillingModal';
import { UPDATE_ORGANIZATION, UPDATE_USER } from '../graphql/user';
import { MessageBubble } from '../components/Shared/MessageFeed';


export enum EventAction {
  CANCEL = "CANCEL",
  CONFIRM = "CONFIRM",
  EDIT_OPTIN = "EDIT_OPTIN",
  CAMPAIGN = "CAMPAIGN",
  MESSAGE = "MESSAGE",
}

export default function Events(): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const [events, setEvents] = useState<WpEvent[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [hovering, setHovering] = useState<"events" | "leads">();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isBilling, setIsBilling] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<EventAction>();
  const [actionWas, setActionWas] = useState<EventAction>();
  const [campaignId, setCampaignId] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [messageContent, setMessageContent] = useState<object>();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [optinMessage, setOptinMessage] = useState<string>();
  const [optinMessageContent, setOptinMessageContent] = useState<object>();
  const [understandTiming, setUnderstandTiming] = useState<boolean>(false);
  const [understandContacts, setUnderstandContacts] = useState<boolean>(false);
  const [understandEdit, setUnderstandEdit] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const [getEvents, { data, loading, refetch, networkStatus }] = useLazyQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [cancelEvents, cancelEventsData] = useMutation(CANCEL_EVENTS, {
    fetchPolicy: "network-only",
  });

  const [addCampaignsToEvents, addCampaignsToEventsData] = useMutation(ADD_CAMPAIGNS_TO_EVENTS, {
    fetchPolicy: "network-only",
  });

  const [messageEvents, messageEventsData] = useMutation(MESSAGE_EVENTS, {
    fetchPolicy: "network-only",
  });

  const [updateUser, updateUserData] = useMutation(UPDATE_USER, {
    fetchPolicy: "network-only",
  });

  const [updateOrganization, updateOrganizationData] = useMutation(UPDATE_ORGANIZATION, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (addCampaignsToEventsData.data || messageEventsData.data) {
      closeModal();
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: `Your messages are now being sent out in the background.`
      });
      loadEvents();
    }
  }, [addCampaignsToEventsData.data, messageEventsData.data])

  useEffect(() => {
    if (cancelEventsData.data) {
      closeModal();
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: `Successfully cancelled event(s).`
      });
      loadEvents();
    }
  }, [cancelEventsData.data])

  useEffect(() => {
    if (updateOrganizationData.data?.updateOrganization) {
      let org = updateOrganizationData.data.updateOrganization.organization;
      setOptinMessage(org.message)
      setOptinMessageContent(org.messageContent)

      dispatch({ type: WpUserAction.REFRESH_ORGANIZATION, organization: org })
      confirmCampaign(actionWas);
      setLoading(false);
    }
  }, [updateOrganizationData.data])

  useEffect(() => {
    if (!events.length && state.user) {
      loadEvents();
    }
  }, [state.user])

  useEffect(() => {
    if (data?.events?.events) {
      setEvents(data.events.events)
    }
  }, [data])

  const loadEvents = () => {
    if (state.user) {
      if (data) {
        refetch();
      } else {
        getEvents();
      }
    }
  }

  const onSelectEvent = (id: string) => {
    const index = selectedEvents.indexOf(id);
    if (index > -1) {
      setSelectedEvents([
        ...selectedEvents.slice(0, index),
        ...selectedEvents.slice(index + 1),
      ])
    } else {
      setSelectedEvents([
        ...selectedEvents,
        id
      ])
    }
  }

  const getSelectedText = () => {
    if (selectedEvents.length) {
      let name = 'Event'
      return `${selectedEvents.length} ${name}${selectedEvents.length > 1 ? 's' : ''} Selected`
    } else {
      return 'Select'
    }
  }

  const onClear = () => {
    setSelectedEvents([]);
  }

  const closeModal = () => {
    setIsVisible(false);
    setLoading(false);
    setAction(undefined);
  }

  const onSearch = (val?: string) => {
    if (val) {
      setSearch(val);
    } else {
      setSearch('');
    }
  }

  const editOptin = () => {
    startAction(EventAction.EDIT_OPTIN);
  }

  const renderOptInMessage = () => {
    if (Constants.blastView) {
      return (
        <View style={{ flex: 1, position: 'relative' }}>
          <Text style={{ fontFamily: 'GothamMedium', color: Constants.colors.darkBlue, textAlign: 'center', fontSize: 18, marginVertical: 20, paddingHorizontal: 10 }}>Opt-in Message</Text>
          {optinMessage ?
            <MessageBubble userId={"1"} message={optinMessage} margin={'5%'} maxWidth={'90%'} backgroundColor={'white'} /> :
            <Text key="no-optin" style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>No opt-in message has been set. Follow the link below to create one.</Text>}
          <LocalButton style={{ marginTop: 5, marginBottom: 15 }} width={'100%'} text="Edit Opt-in Message" theme={Theme.LINK_PRIMARY} onPress={editOptin} />
        </View>
      )
    }
    return null;
  }

  const confirmCampaign = (was: EventAction) => {
    setActionWas(was);
    startAction(EventAction.CONFIRM);
  }

  const completeConfirm = () => {
    if (actionWas === EventAction.CAMPAIGN) {
      if (state.user?.organization.billing?.active) {
        addCampaignsToEvents({ variables: { eventIds: selectedEvents, campaignIds: [campaignId] } });
      } else {
        settingUpBilling()
        setLoading(false);
      }
    } else {
      if (state.user?.organization.billing?.active) {
        messageEvents({ variables: { eventIds: selectedEvents, messageContent: messageContent } });
      } else {
        settingUpBilling()
        setLoading(false);
      }
    }
  }

  const getTitle = () => {
    let name = 'Event'
    let campaign = Constants.blastView ? 'Blast' : 'Campaign'
    switch (action) {
      case EventAction.MESSAGE:
        return `Message ${name}`
      case EventAction.CAMPAIGN:
        return Constants.blastView ? `Send ${campaign} to ${name}` : `Add ${name} to ${campaign}`
      default:
        return "Are You Sure?"
    }
  }

  const renderActionBody = () => {
    let name = 'event'
    let cancelText = `Cancelling an ${name} will cancel all pending messages/campaigns associated with the event time and location. You will still be able to see and manage this ${name}.`;
    switch (action) {
      case EventAction.CANCEL:
        return (<Text>{cancelText}</Text>)
      case EventAction.CONFIRM:
        if (Constants.blastView) {
          return (
            <View style={{ flex: 1, position: 'relative' }}>
              <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>The message shown below will be sent to any new {Constants.localizations.leads} that have not yet opted-in to receive SMS messages from you. If they opt-in, {Constants.localizations.campaigns} and messages will continue from that point.</Text>
              {renderOptInMessage()}

              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandTiming(val)} selected={understandTiming} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand that messages are only sent out between 8am-8pm MST. If you try to send a message outside of these times, our servers will wait until 8am the following morning to send them out.</Text>
              </View>
              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandContacts(val)} selected={understandContacts} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand {Constants.localizations.leads} I add personally will need to opt-in to receive future communication. Any {Constants.localizations.leads} added via a {Constants.brand} landing page do not need an additional opt-in.</Text>
              </View>
              <View style={{ marginVertical: 10, position: 'relative', marginHorizontal: 5, flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onSelect={(val) => setUnderstandEdit(val)} selected={understandEdit} size={24} />
                <Text style={{ fontFamily: 'Gotham' }}>I understand I can edit the opt-in message anytime in settings.</Text>
              </View>
            </View>
          )
        } else {
          return (
            <View style={{ flex: 1, position: 'relative' }}>
              <Text style={{ fontFamily: 'GothamMedium', color: '#555', paddingHorizontal: 10 }}>Adding a {Constants.localizations.lead} to a {Constants.localizations.campaign} will start the process of sending out each of your messages. Make certain your {Constants.localizations.campaign} is fully set up how you want it before adding any {Constants.localizations.leads}.</Text>
              <Text style={{ fontFamily: 'GothamMedium', color: '#999', marginTop: 20, paddingHorizontal: 10 }}>Note: Messages are only sent out between 8am-8pm MST. If you try to send a message outside of these times, our servers will wait until 8am the following morning to send them out.</Text>
            </View>
          )
        }
      case EventAction.MESSAGE:
        return (<MessageEditor messageContent={messageContent} apptPlaceholders={state.user?.organization.showApptPlaceholders} setMessageContent={setMessageContent} setMessage={setMessage} />)
      case EventAction.EDIT_OPTIN:
        return (<MessageEditor messageContent={optinMessageContent} includePlaceholders={false} setMessageContent={setOptinMessageContent} setMessage={setOptinMessage} />)
      case EventAction.CAMPAIGN:
        return (<CampaignDropdown style={{ zIndex: 5 }} value={campaignId} setValue={setCampaignId} />)
      default:
        return null;
    }
  }

  const getButtonText = () => {
    switch (action) {
      case EventAction.MESSAGE:
        return "Send Message"
      case EventAction.CAMPAIGN:
        return "Continue"
      case EventAction.EDIT_OPTIN:
        return "Continue"
      default:
        return "Confirm"
    }
  }

  const isDisabled = () => {
    switch (action) {
      case EventAction.CONFIRM:
        return Constants.blastView && (!understandContacts || !understandEdit || !understandTiming)
      case EventAction.MESSAGE:
        return !messageContent
      case EventAction.CAMPAIGN:
        return !campaignId
      default:
        return false
    }
  }

  const onConfirm = () => {
    setLoading(true);
    switch (action) {
      case EventAction.CANCEL:
        cancelEvents({ variables: { eventIds: selectedEvents } });
        return;
      case EventAction.MESSAGE:
        if (Constants.blastView && state.user?.organization.understandsTiming) {
          setActionWas(EventAction.MESSAGE)
          completeConfirm();
        } else {
          confirmCampaign(EventAction.MESSAGE);
          setLoading(false);
        }
        return;
      case EventAction.CONFIRM:
        completeConfirm();
        return;
      case EventAction.CAMPAIGN:
        if (Constants.blastView && state.user?.organization.understandsTiming) {
          setActionWas(EventAction.CAMPAIGN)
          completeConfirm();
        } else {
          confirmCampaign(EventAction.CAMPAIGN);
          setLoading(false);
        }
      case EventAction.EDIT_OPTIN:
        if (state.user?.organization.access && [Access.ADMIN, Access.MANAGER, Access.OWNER].indexOf(state.user.organization.access) > -1 && !updateOrganizationData.loading) {
          updateOrganization({
            variables: {
              organizationId: state.user.organization.id,
              organization: {
                messageContent: optinMessageContent
              }
            }
          })
        }
        return;
      default:
        closeModal();
    }
  }

  const onCancelEvent = () => {
    startAction(EventAction.CANCEL);
  }

  const onAddCampaigns = () => {
    startAction(EventAction.CAMPAIGN);
  }

  const onMessageLeads = () => {
    startAction(EventAction.MESSAGE);
  }

  const startAction = (action: EventAction) => {
    if (selectedEvents.length > 0) {
      setAction(action);
      openModal();
    } else {
      Toast.show({
        type: 'error',
        text1: 'Select Events',
        text2: `Select events from below first.`
      });
    }
  }

  const openModal = () => {
    setIsVisible(true);
  }

  const settingUpBilling = () => {
    setIsBilling(true);
  }

  const doneSetupBilling = () => {
    setIsBilling(false);
  }

  const onCompleteBilling = (token?: string, plan?: string) => {
    if (state.user) {
      updateUser({
        variables: {
          userId: state.user.id,
          user: {
            token: token,
            plan: plan
          }
        }
      })
    }
  }

  const onCancel = () => {
    setLoading(true);
    switch (action) {
      case EventAction.EDIT_OPTIN:
        setLoading(false);
        if (actionWas) {
          confirmCampaign(actionWas);
        } else {
          closeModal();
        }
        return;
      default:
        return
    }
  }

  const renderBody = () => {
    if (loading) {
      return (
        <View>
          <Spinner />
        </View>
      )
    } else if (state.user) {
      return (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.heading}>{'Events'}</Text>
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 10, marginBottom: 20 }}>
            {selectedEvents.length > 0 ?
              <View style={styles.controlsWrapper}>
                <View style={styles.controls}>
                  <Checkbox color={Constants.colors.darkBlue} onSelect={onClear} selected={true} />
                  <Text style={{ color: Constants.colors.darkBlue, fontFamily: 'GothamMedium' }}>{getSelectedText()}</Text>
                </View>
              </View> : null}
            <IconButton type="White" shape="Circle" onClick={onMessageLeads}>
              <Icon name="message" raw={true} colorHover="currentColor" color="currentColor" />
            </IconButton>
            <IconButton type="White" shape="Circle" onClick={onAddCampaigns}>
              <Icon name="campaignPlus" raw={true} colorHover="currentColor" color="currentColor" />
            </IconButton>
            <IconButton type="White" shape="Circle" onClick={onCancelEvent}>
              <Icon name="archive" raw={true} colorHover="currentColor" color="currentColor" />
            </IconButton>
          </View>
          <View style={styles.thumbnailWrapper}>
            {events && events.length ? events.map((event, index) => {
              // Format the date and time
              const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
              const eventTimeFormatted = event.eventTime ? new Date(event.eventTime.replace(' ', 'T') + 'Z').toLocaleString('en-US', options) : ''
              const eventAddressFormatted = event.eventAddress ?
                ` - ${event.eventAddress.includes(',') ?
                  event.eventAddress.substring(event.eventAddress.indexOf(',') + 1).trim() :
                  event.eventAddress
                }` :
                ``;

              const eventTitle = `${event.eventLocation}${eventAddressFormatted}`;

              let descriptionIcon: IconTypes = 'clock';
              let description = eventTimeFormatted;
              if (event.cancelled) {
                descriptionIcon = 'archive';
                description = 'Cancelled'
              }
              let subtext = `${Constants.localizations.Leads}: ${numberWithCommas(event.appointments.length)}`;

              if (search) {
                if (eventTitle.toLowerCase().indexOf(search.toLowerCase()) === -1 || subtext.toLowerCase().indexOf(search.toLowerCase()) === -1 || description.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                  return null;
                }
              }
              return (
                <Thumbnail
                  key={`event-${event.id}-${index}`}
                  text={eventTitle}
                  subtext={subtext}
                  description={description}
                  descriptionIcon={descriptionIcon}
                  selectable={true}
                  selected={selectedEvents.indexOf(event.id) > -1}
                  onSelect={() => onSelectEvent(event.id)}
                />
              );
            }) : <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary }}>- No Events Found -</Text>}
          </View>
        </View>
      )
    } else {
      return (
        <View>
        </View>
      )
    }
  }

  return (
    <BasicLayout style={styles.container} active={'/events'} onSearch={onSearch}>
      {renderBody()}

      <WpModal isVisible={isVisible} closeModal={closeModal}>
        <ModalBody title={getTitle()} closeModal={closeModal}>
          {renderActionBody()}
          <ModalFooter>
            {action === EventAction.EDIT_OPTIN ? <LocalButton style={{ marginTop: 10, marginBottom: 15 }} text="Cancel" theme={Theme.LINK_NEUTRAL} onPress={onCancel} /> : null}
            <Button disabled={isDisabled()} onClick={onConfirm} shape="Pill" size="Default" type={action === EventAction.CANCEL ? "Danger" : "Primary"}>
              {loading || updateOrganizationData.loading ? <Spinner color="#FFF" /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>{getButtonText()}</Text>}
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <BillingModal isVisible={isBilling} card={state.user?.organization?.billing?.card} loading={updateUserData.loading} onClose={doneSetupBilling} onComplete={onCompleteBilling} />
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginBottom: 50,
    marginTop: 5
  },
  icon: {
    cursor: 'pointer'
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
  newBtn: {
    fontFamily: 'GothamBold',
    fontSize: 15,
    color: Constants.colors.darkBlue,
    marginLeft: 10
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap'
  },
  controlsWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: Constants.colors.lightBlue,
    alignItems: 'center',
    marginRight: 10
  },
  controls: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
})
