import { Text, View, Pressable, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { PresentationChartBarIcon, AnnotationIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import Constants from './../constants'
import { WpFeatureType } from './../contexts/campaign';
import { IconButton } from '@whistlepitch/wp-components';


function toTitleCase(str:string) {
  return str.replace(
    /\w\S*/g,
    function(txt:string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}


export default function CampaignFeatureOptions({ onPress, showRedirect=false } : { onPress: (type?: WpFeatureType) => void, showRedirect?: boolean }): JSX.Element {
  const handleOnPress = (featureType?: WpFeatureType) => {
    onPress(featureType);
  }

  return (
    <View style={styles.container}>
      <IconButton label="Attach Slide" type="AccentBlue" onClick={() => handleOnPress()}>
        <PresentationChartBarIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton label="New Message" type="AccentBlue" onClick={() => handleOnPress(WpFeatureType.MESSAGE)}>
        <AnnotationIcon style={cssStyles.icon} />
      </IconButton>
      { showRedirect ?
        <IconButton label="Redirect" type="AccentBlue" onClick={() => handleOnPress(WpFeatureType.REDIRECT)}>
          <ExternalLinkIcon style={cssStyles.icon} />
        </IconButton> : null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 10
  },
  iconWrapper: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 8
  },
  separator: {
    marginHorizontal: 10,
    borderRightColor: Constants.colors.info,
    borderRightWidth: 1,
    width: 2,
    height: '90%'
  }
});

const cssStyles = {
  icon: {
    height: 30,
    width: 30,
    marginVertical: 10,
  }
}
