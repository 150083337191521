import { Text, LayoutChangeEvent, View, StyleSheet, Image, ActivityIndicator, Dimensions, TextInput } from 'react-native';
import React, { useState, useEffect } from 'react';
import { PitchContext, PitchSlideContext, WpPitchSlideAction, WpSlideType } from './../../contexts/pitch';
import { CampaignContext, WpCampaignAction, WpLandingPage } from './../../contexts/campaign';
import { GET_PITCH_SLIDE } from '../../graphql/queries';
import { UPDATE_PITCH_SLIDE, CREATE_SLIDE_WIDGET } from '../../graphql/pitch';
import { UPDATE_CAMPAIGN } from '../../graphql/campaign';
import Constants from '../../constants';
import ModalBody from './../ModalBody';
import { Feather } from '@expo/vector-icons';
import WpModal from './../WpModal';
import ModalFooter from './../ModalFooter';
import { Button, Theme } from './../Button';
import Uppyup from './../Uppyup';
import Input, { Info, Label } from './../Input';
import SlideWidget from './../Pitch/SlideWidget';
import { useLazyQuery, useMutation } from "@apollo/client";
import Collapsible from 'react-native-collapsible/Collapsible';
import ReactPlayer from 'react-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from 'react-native-toast-message';
import { TrashIcon } from '@heroicons/react/outline';
import { UPDATE_LANDING_PAGE } from '../../graphql/campaign';
import Dropdown from '../Dropdown';
import { Slider } from '@miblanchard/react-native-slider';
import CampaignDropdown from '../CampaignDropdown';
import Checkbox from '../Checkbox';
import Spinner from '../Spinner';
import { UserContext, WpCampaign } from '../../contexts/user';
import { Avatar } from '@whistlepitch/wp-components';

const window = Dimensions.get('window');

export default function LandingPageModal({ isVisible, landingPage, closeModal }: { landingPage?: WpLandingPage, isVisible?: boolean, closeModal: () => void }): JSX.Element {
  const { state, dispatch } = React.useContext(UserContext);
  const [viewWidth, setViewWidth] = useState<number>(0);
  const [editing, setEditing] = useState<string>();
  const [newProfile, setNewProfile] = useState<boolean>();
  const [newExplainer, setNewExplainer] = useState<boolean>();
  const [selectingCampaign, setSelectingCampaign] = useState<boolean>(false);

  const [dimensions, setDimensions] = useState(window)
  const [id, setId] = useState<string>()
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [color, setColor] = useState<string>()
  const [profile, setProfile] = useState<string>()
  const [campaignId, setCampaignId] = useState<string>()
  const [localCampaigns, setLocalCampaigns] = useState<WpCampaign[]>([])

  const [copyText, setCopyText] = useState<string>("Save and Copy Link")
  const [viewing, setViewing] = useState<"details" | "branding">("details")

  const [showControls, setShowControls] = useState<boolean>(false)
  const [dropownOpened, setDropownOpened] = useState<boolean>(false)
  const [aspectRatioProfile, setAspectRatioProfile] = useState<number>(0)
  const [ProfileElement, setProfileElement] = useState<JSX.Element>()

  const [updateLandingPage, updateLandingPageData] = useMutation(UPDATE_LANDING_PAGE, {
    fetchPolicy: "no-cache"
  })

  useEffect(() => {
    if (!profile) {
      return;
    }

    let isValid = true;
    Image.getSize(profile, (width, height) => {
      if (isValid) {
        setAspectRatioProfile(width / height);
      }
    });

    return () => {
      isValid = false;
    };
  }, [profile, aspectRatioProfile]);

  useEffect(() => {
    if (profile) {
      setProfileElement(<View style={styles.logo} ><Image style={[styles.profile, { aspectRatio: 1 }]} source={{ uri: profile }} /></View>)
    }
  }, [aspectRatioProfile, profile])

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  useEffect(() => {
    if (landingPage) {
      if (title === undefined || id !== landingPage.id) {
        setId(landingPage.id)
        setTitle(landingPage.title)
        setDescription(landingPage.description)
        setColor(landingPage.color)
        setProfile(landingPage.image)
        setLocalCampaigns(landingPage.campaigns)
        if (landingPage.campaigns?.length && !Constants.tattooCare) {
          setCampaignId(landingPage.campaigns[0].id)
        }
      }
    }
  }, [landingPage?.id]);

  useEffect(() => {
    if (updateLandingPageData.data?.updateLandingPage?.success && isVisible) {
      setLocalCampaigns(updateLandingPageData.data?.updateLandingPage?.landingPage?.campaigns)
    }
  }, [updateLandingPageData.data?.updateLandingPage])

  const onCloseModal = () => {
    closeModal();
    // setTitle(undefined)
    // setDescription(undefined)
    // setColor(undefined)
    // setProfile(undefined)
    // setLocalCampaigns([])
    // setCampaignId(undefined)
    setCopyText("Save and Copy Link")
  }

  const onUploadProfileComplete = (url: string, mime: string) => {
    setProfile(url);
    setNewProfile(false);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var { x, y, width, height } = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onSave = () => {
    if (landingPage) {
      updateLandingPage({
        variables: {
          landingPageId: landingPage.id,
          landingPage: {
            title: title,
            description: description,
            color: color,
            image: profile,
            campaignId: campaignId
          }
        }
      })
      onCloseModal();
    }
  }

  const onNewProfile = () => {
    setNewProfile(true);
  }

  const onAddCampaign = () => {
    setSelectingCampaign(true);
  }

  const confirmAddCampaign = () => {
    if (landingPage) {
      updateLandingPage({
        variables: {
          landingPageId: landingPage.id,
          landingPage: {
            campaignsToAdd: [campaignId],
          }
        }
      })
      setCampaignId(undefined);
      setSelectingCampaign(false);
    }
  }

  const removeCampaign = (removeId: string) => {
    if (landingPage) {
      updateLandingPage({
        variables: {
          landingPageId: landingPage.id,
          landingPage: {
            campaignsToRemove: [removeId],
          }
        }
      })
    }
  }

  const confirmCopy = () => {
    setCopyText('Copied! Saving...')
    Toast.show({
      type: 'success',
      text1: 'Link Copied',
    });
    onSave();
  }

  const renderCampaigns = () => {
    if (Constants.tattooCare) {
      return (
        <View>
          <Label style={{ marginTop: 15, marginBottom: 7 }} name={Constants.localizations.Campaigns} />
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 5 }}>
            {localCampaigns?.length ? localCampaigns.map((campaign, index) => {
              return <Button key={`campaign-button-${index}`} theme={Theme.DETAILS} style={{ marginBottom: 10, paddingRight: 20 }} width={'auto'} text={campaign.name} shadow={false} onDelete={() => removeCampaign(campaign.id)} />
            }) : null
            }
          </View>
          <Text onPress={onAddCampaign} style={[styles.newBtn, { marginTop: 15 }]}>+ Add {Constants.localizations.campaign}</Text>
        </View>
      )
    } else {
      return <CampaignDropdown style={{ marginTop: 10 }} instantOnly={true} optional={false} label={Constants.localizations.Campaign} info={`Select a ${Constants.localizations.campaign} to send out when the landing page is submitted.`} value={campaignId} setValue={setCampaignId} />
    }
  }

  const renderBranding = () => {
    return (
      <View style={{ position: 'relative', zIndex: 2 }}>
        <Input name="Landing Page Branding Color" style={{ marginBottom: 20 }} isColor={true} value={color} setValue={setColor} />
        <Label name="Landing Page Branding Image" />
        {profile && !newProfile ?
          <View style={{ alignItems: 'center' }}>
            {ProfileElement}
            <Button style={{ marginTop: 25 }} width={'100%'} text="Upload New Brand Image" theme={Theme.LINK_PRIMARY} onPress={onNewProfile} />
          </View> :
          <Uppyup plugins={[]} height={200} onDone={onCloseModal} onUploadComplete={onUploadProfileComplete} />
        }
        {renderCampaigns()}

        <WpModal key="modal-add-campaign" isVisible={selectingCampaign}>
          <ModalBody title={"Add Campaign"} closeModal={setSelectingCampaign}>
            <View style={{ position: 'relative', zIndex: 10 }}>
              <CampaignDropdown optional={false} label={Constants.localizations.Campaign} info={`Select a ${Constants.localizations.campaign} to send out when the landing page is submitted.`} value={campaignId} setValue={setCampaignId} />
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 25, position: 'relative', zIndex: 1 }}>
              <Button key="btn-campaign-add" text="Confirm" onPress={confirmAddCampaign} />
            </View>
          </ModalBody>
        </WpModal>
      </View>
    )
  }

  return (
    <WpModal key="modal-card" isVisible={!!isVisible}>
      <ModalBody title={"Edit Landing Page"} closeModal={onCloseModal}>
        <Input style={{ marginBottom: 10 }} name="Title" placeholder="Enter a title" value={title} setValue={setTitle} />
        <Input style={{ marginBottom: 10 }} name="Description" optional={true} multiline={true} placeholder="Enter a description" value={description} setValue={setDescription} />

        {renderBranding()}

        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 25, position: 'relative', zIndex: 1 }}>
          <CopyToClipboard text={`${Constants.app_url}/p/${landingPage?.code}`} onCopy={confirmCopy}>
            <Text style={{ marginLeft: 5, color: Constants.colors.blue, cursor: 'pointer' }}>{copyText}</Text>
          </CopyToClipboard>
          <Button key="btn-feature-save" text="Save" onPress={onSave} />
          <CopyToClipboard text={`${Constants.app_url}/p/${landingPage?.code}`} onCopy={confirmCopy}>
            <Text style={{ marginLeft: 5, color: 'white' }}>{copyText}</Text>
          </CopyToClipboard>
        </View>
      </ModalBody>
    </WpModal>
  );
}

const styles = StyleSheet.create({
  pitch: {
    flexDirection: 'row',
    margin: 25,
    paddingLeft: 35,
    borderRadius: 15,
    backgroundColor: '#FFF',
    justifyContent: 'space-evenly',
  },
  header: {
    flex: 2,
    fontSize: 28,
    alignSelf: 'center',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  newBtn: {
    fontFamily: 'GothamMedium',
    fontSize: 11,
    color: Constants.colors.blue,
  },
  controlActive: {
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: Constants.colors.blue,
    borderBottomWidth: 1
  },
  control: {
    color: '#656565',
    fontFamily: 'GothamMedium',
    fontSize: 14,
    cursor: 'pointer',
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#FFFFFF00',
    borderBottomWidth: 1
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: 15,
    flexWrap: 'wrap',
    flex: 1,
    position: 'relative'
  },
  column: {
    flexWrap: 'wrap',
    flex: 1,
    minWidth: 300,
    position: 'relative'
  },
  logo: {
    width: 110,
    height: 110,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 55,
    overflow: "hidden"
  },
  profile: {
    width: 110,
    clipPath: 'circle()'
  },
  bubbleWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 10,
    marginHorizontal: 10,
    overflowX: 'scroll'
  },
  bubble: {
    fontFamily: 'GothamMedium',
    paddingVertical: 13,
    paddingHorizontal: 20,
    borderRadius: 20,
    height: 40,
    color: Constants.colors.darkBlue,
    fontSize: 12,
    backgroundColor: '#F5F7FB',
    marginBottom: 15,
    whiteSpace: 'nowrap'
  }
});

const cssStyles = {
  player: {
    marginTop: 15,
    borderRadius: 15,
    padding: 0,
    overflow: 'hidden'
  }
}
