import { Text, View, TextInput, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { WpWorkflowTrigger, WpWorkflowTriggerType, CampaignContext, WpWorkflowExpression } from './../contexts/campaign'
import { WpPitchSlide, WpSlideWidget, WpSlideType, WpSlideStructure, WpWidgetType } from './../contexts/pitch'
import { UserContext, WpModelSummary } from './../contexts/user'
import WorkflowTriggerOptions from './WorkflowTriggerOptions';
import Dropdown, { Item } from './Dropdown';
import ModalFooter from './ModalFooter';
import Constants from './../constants';
import { Button, Theme } from './Button';
import Input, { Label, Info } from './Input';
import { GET_PITCH_SLIDES } from '../graphql/queries';
import { CREATE_CAMPAIGN_TRIGGER, UPDATE_CAMPAIGN_TRIGGER } from '../graphql/campaign';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Switch } from 'react-native-switch';
import { Slider } from '@miblanchard/react-native-slider';


export default function WorkflowTriggerEditor({ trigger, workflowId, pitchId, onSave, closeModal } : { trigger:WpWorkflowTrigger, workflowId:string, pitchId:string, onSave:Function, closeModal:Function }): JSX.Element {
  const campaignContext = React.useContext(CampaignContext);
  const userContext = React.useContext(UserContext);

  const [triggerType, setTriggerType] = useState<WpWorkflowTriggerType>()
  const [triggerOption, setTriggerOption] = useState<string|null>()
  const [seconds, setSeconds] = useState<number>()
  const [days, setDays] = useState<number>()
  const [hours, setHours] = useState<number>()
  const [performedAction, setPerformedAction] = useState<boolean>()
  const [widgetExpression, setWidgetExpression] = useState<WpWorkflowExpression>()
  const [expressions, setExpressions] = useState<Item[]>([]);
  const [slide, setSlide] = useState<WpPitchSlide>();
  const [slideId, setSlideId] = useState<string>();
  const [slides, setSlides] = useState<WpPitchSlide[]>([]);
  const [slideItems, setSlideItems] = useState<Item[]>([]);
  const [calendarItems, setCalendarItems] = useState<Item[]>([]);
  const [response, setResponse] = useState<string>();
  const [other, setOther] = useState<string>();
  const [responseItems, setResponseItems] = useState<Item[]>([]);
  const [start, setStart] = useState<number>(1);
  const [end, setEnd] = useState<number>(10);
  const [rating, setRating] = useState<number|number[]>();
  const [widget, setWidget] = useState<WpSlideStructure>();
  const [open, setOpen] = useState<"trigger-option"|"slide-widget"|"widget-response"|"widget-expression">();
  const [widgetId, setWidgetId] = useState<string>();
  const [widgets, setWidgets] = useState<WpSlideStructure[]>([]);
  const [widgetItems, setWidgetItems] = useState<Item[]>([]);

  const [getPitchSlides, { data, networkStatus, refetch }] = useLazyQuery(GET_PITCH_SLIDES, {
    notifyOnNetworkStatusChange: true
  });
  const [updateTrigger, updateTriggerData] = useMutation(UPDATE_CAMPAIGN_TRIGGER, {
    fetchPolicy: "no-cache"
  });
  const [createCampaignTrigger, createTriggerData] = useMutation(CREATE_CAMPAIGN_TRIGGER, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    setTriggerType(trigger.triggerType)
    setSlideId(trigger.triggerOption)
    setSeconds(trigger.seconds)
    setDays(trigger.days)
    setHours(trigger.hours)
    setPerformedAction(trigger.performedAction)
    setWidgetExpression(trigger.widgetExpression ? trigger.widgetExpression : WpWorkflowExpression.EQ)
    setWidgetId(trigger.widgetId)
    setResponse(trigger.triggerResponses?.length ? trigger.triggerResponses[0] : undefined)
    setOther(trigger.triggerResponses?.length ? trigger.triggerResponses[0] : undefined)
    let t = setTimeout(() => {
      handleTypeChange();
    }, 250)

    return () => clearTimeout(t);
  }, [trigger])

  useEffect(() => {
    if (createTriggerData.data?.createWorkflowTrigger.success) {
      saveTrigger(createTriggerData.data?.createWorkflowTrigger.trigger.id);
    }
  }, [createTriggerData.data]);

  useEffect(() => {
    let items = [{value: WpWorkflowExpression.EQ, label: 'Equal To'}, {value: WpWorkflowExpression.NE, label: 'Not Equal To'}]

    if (widget?.type === WpWidgetType.RATING) {
      items.push({value: WpWorkflowExpression.LT, label: 'Less Than'})
      items.push({value: WpWorkflowExpression.LE, label: 'Less Than or Equal To'})
      items.push({value: WpWorkflowExpression.GT, label: 'Greater Than'})
      items.push({value: WpWorkflowExpression.GE, label: 'Greater Than or Equal To'})
      setExpressions(items)

      // console.log("Setting rating for slider", widget.options, widget, response, other)

      for (let i = 0; i < widget.options.length; i++) {
        if (i === 0) {
          if (widget.options[0] !== undefined && widget.options[0] !== null) {
            setStart(parseInt(widget.options[0]));
          } else {
            setStart(1)
          }
        } else if (i === 1) {
          let e: number = 10;
          if (widget.options[1] !== undefined && widget.options[1] !== null) {
            e = parseInt(widget.options[1])
          }
          if (response) {
            setRating(parseInt(response));
          } else {
            setRating(e);
            setResponse(e.toString());
            setOther(e.toString());
          }
          setEnd(e);
        }
      }
    } else if (widget?.type === WpWidgetType.SHORT && response && response !== 'Any') {
      setResponse('Other')
      setStart(1);
      setRating(1);
      setEnd(10)
    } else {
      setStart(1);
      setRating(1);
      setEnd(10)
    }
  }, [widget])

  useEffect(() => {
    handleTypeChange();
  }, [triggerType]);

  useEffect(() => {
    if (updateTriggerData.data?.updateWorkflowTrigger?.trigger) {
      onSave(updateTriggerData.data.updateWorkflowTrigger.trigger)
    }
  }, [updateTriggerData.data]);

  useEffect(() => {
    if (pitchId) {
      loadSlides();
    }
  }, [pitchId])

  useEffect(() => {
    if (data?.pitchSlides?.slides) {
      setSlides(data.pitchSlides.slides)
    }
  }, [data, networkStatus])

  useEffect(() => {
    if (slides) {
      handleTypeChange();
    }
  }, [slides])

  useEffect(() => {
    if (widgets) {
      loadWidgetItems()
    }
  }, [widgets])

  const handleTypeChange = () => {
    if (triggerType === WpWorkflowTriggerType.VIEWED) {
      loadSlideItems();
    } else if (triggerType && [WpWorkflowTriggerType.CALENDAR, WpWorkflowTriggerType.WIDGET].indexOf(triggerType) > -1) {
      loadSlideItems(false);
      loadWidgetItems();
    }
  }

  const loadSlides = () => {
    if (data) {
      refetch({ pitchId: pitchId });
    } else {
      getPitchSlides({ variables: { pitchId: pitchId }});
    }
  }

  const changeRating = (val: number | number[]) => {
    let n = 1
    if (Array.isArray(val)) {
      if (val.length) {
        n = val[0];
      }
    } else {
      n = val;
    }

    setResponse(val.toString());
    setOther(val.toString());
    setRating(val);
  }

  const loadSlideItems = (includeOverall:boolean=true) => {
    let items = includeOverall ? [{value: 'overall', label: 'Overall'}] : [];
    let calendars = [];

    let s;
    for (let i = 0; i < slides.length; i++) {
      if (slideId === slides[i].id) {
        s = slides[i];
      }
      items.push({value: slides[i].id, label: slides[i].title})
      if (slides[i].slideType === WpSlideType.CALENDAR) {
        calendars.push({value: slides[i].id, label: slides[i].title})
      }
    }
    setSlideItems(items)
    setCalendarItems(calendars)
    setSlide(s)

    if (s) {
      setWidgets(s.widgetStructure)
    }
  }

  const loadWidgetItems = () => {
    let items = [];
    let w;
    for (let i = 0; i < widgets.length; i++) {
      if (widgetId === widgets[i].id) {
        w = widgets[i];
      }
      items.push({value: widgets[i].id, label: widgets[i].label})
    }
    setWidgetItems(items)
    setWidget(w)

    if (w?.options) {
      let options = [];
      for (let i = 0; i < w.options.length; i++) {
        options.push({value: w.options[i], label: w.options[i]})
      }
      setResponseItems(options);
    }
  }

  const onSelectSlide = (value:string) => {
    let sindex = slides.findIndex(s => s.id === value)
    let s = sindex > -1 ? slides[sindex] : undefined

    setSlide(s);
    setSlideId(value)
    setTriggerOption(value);

    if (s) {
      setWidgets(s.widgetStructure)
    }
  }

  const onSelectWidget = (value:string) => {
    let windex = widgets.findIndex(w => w.id === value)
    let w = windex > -1 ? widgets[windex] : undefined;

    setWidget(w)
    setWidgetId(value);

    if (w?.type === WpWidgetType.VERIFY) {
      setResponse('Verified')
      setOther('Verified')
    } else if (w?.options) {
      let options = [];
      for (let i = 0; i < w.options.length; i++) {
        options.push({value: w.options[i], label: w.options[i]})
      }
      setResponseItems(options);
    }
  }

  const setViewTime = () => {
    setPerformedAction(true)
  }

  const setWatchTime = () => {
    setPerformedAction(false)
  }

  const updateSeconds = (s: string) => {
    setSeconds(s && s !== '' ? parseInt(s) : 0)
   }

  const updateResponse = (r: string) => {
    setResponse(r)
    if (widget?.type === WpWidgetType.SHORT && r === 'Other') {
      if (response === 'Any') {
        setOther(undefined)
      }
    } else {
      setOther(r)
    }
   }

  const renderWidgetDropdown = () => {
    if (slideId && widgetItems && triggerType === WpWorkflowTriggerType.WIDGET) {
      return (
        <View style={{ position: 'relative', zIndex: open === 'slide-widget' ? 100 : 1, paddingTop: 20 }}>
          <Label name={`Widget`} />
          <Dropdown
            key={`dropdown-slide-widget`}
            style={{ position: 'relative', zIndex: 5 }}
            placeholder={"Select a Widget"}
            items={widgetItems}
            value={widgetId}
            onChange={onSelectWidget}
            onOpen={() => setOpen('slide-widget')}
          />
        </View>
      )
    }
    return null;
  }

  const renderResponse = () => {
    if (widget?.type === WpWidgetType.RATING) {
      return (
        <View style={{ marginVertical: 10, marginLeft: 10 }}>
          <Label name={`${response}`} />
          <Slider
            step={1}
            value={rating}
            onValueChange={changeRating}
            minimumValue={start}
            maximumValue={end}
            minimumTrackTintColor={`${Constants.colors.blue}55`}
            thumbTintColor={Constants.colors.blue}
          />
        </View>
      )
    } else if (widget) {
      return (
        <Dropdown
          key={`dropdown-trigger-widget-response`}
          style={{ position: 'relative', zIndex: open === 'widget-response' ? 100 : 1, marginTop: 10 }}
          placeholder={"Select a response"}
          // multiple={true}  // TODO: add this back in when we support multiple responses
          items={responseItems}
          value={response?.toString()}
          onChange={updateResponse}
          onOpen={() => setOpen('widget-response')}
        />
      )
    }
    return null;
  }

  const renderOther = () => {
    if (widget?.type === WpWidgetType.SHORT && response !== 'Any') {
      return (
        <Input style={{ marginTop: 15 }} placeholder="Enter required response" name="If lead answers:" fontSize={18} value={other} setValue={setOther} />
      )
    }
    return null;
  }

  const renderWidgetOptionDropdowns = () => {
    if (widgetId && triggerType === WpWorkflowTriggerType.WIDGET) {
      if (widget && widget.type === WpWidgetType.VERIFY) {
        return (
          <View style={{ position: 'relative', zIndex: 3, paddingTop: 20 }}>
            <Info info={`Trigger when lead has verified.`} />
          </View>
        )
      } else {
        return (
          <View style={{ position: 'relative', zIndex: open === 'widget-response' || open === 'widget-expression' ? 100 : 1, zIndex: 3, paddingTop: 20 }}>
            <Label name={`Response is`} />

            { widget?.type === WpWidgetType.RATING ?
              <Dropdown
                key={`dropdown-trigger-widget-expression`}
                style={{ position: 'relative', zIndex: 3 }}
                placeholder={"Select comparison"}
                items={expressions}
                value={widgetExpression?.toString()}
                onChange={setWidgetExpression}
                onOpen={() => setOpen('widget-expression')}
              /> : null }
            { renderResponse() }
          </View>
        )
      }
    }
    return null;
  }

  const renderWatchType = () => {
    if (slideId && slide?.isVideo && triggerType === WpWorkflowTriggerType.VIEWED) {
      return (
        <View>
          <View style={{ position: 'relative', zIndex: 3, paddingTop: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text key={`select-view-time`} style={[styles.bubble, performedAction ? { backgroundColor: Constants.colors.blue, color: 'white' } : {}]} onPress={setViewTime}>Viewed Slide</Text>
            <Text key={`select-watch-time`} style={[styles.bubble, performedAction ? {} : { backgroundColor: Constants.colors.blue, color: 'white' }]} onPress={setWatchTime}>Watched Video</Text>
          </View>
          <Input style={{ marginTop: 15 }} placeholder="Seconds spent viewing" name="Seconds viewed" keyboardType={'numeric'} optional={true} fontSize={18} value={seconds} setValue={updateSeconds} />
        </View>
      )
    } else if (triggerType === WpWorkflowTriggerType.VIEWED) {
      return <Input style={{ marginTop: 15 }} placeholder="Seconds spent viewing" name="Seconds viewed" keyboardType={'numeric'} optional={true} fontSize={18} value={seconds} setValue={updateSeconds} />
    }
    return null;
  }

  const getSettings = () => {
    if (triggerType) {
      if ([WpWorkflowTriggerType.VIEWED, WpWorkflowTriggerType.WIDGET].indexOf(triggerType) > -1) {
        return (
          <View style={{ position: 'relative', zIndex: 10 }}>
            <Label name={`Trigger ${triggerType === WpWorkflowTriggerType.WIDGET ? 'from slide' : 'when lead views'}:`} />
            <Dropdown
              key={`dropdown-trigger-option`}
              style={{ position: 'relative', zIndex: open === 'trigger-option' ? 100 : 1 }}
              placeholder={"Select a Slide"}
              items={slideItems}
              value={slideId}
              onChange={onSelectSlide}
              onOpen={() => setOpen('trigger-option')}
            />
            { renderWatchType() }
            { renderWidgetDropdown() }
            { renderWidgetOptionDropdowns() }
            { renderOther() }
          </View>
        )
      } else if ([WpWorkflowTriggerType.REDIRECT, WpWorkflowTriggerType.CALENDAR].indexOf(triggerType) > -1) {
        let labelPos = WpWorkflowTriggerType.REDIRECT === triggerType ? 'Lead Followed Redirect' : 'Lead Scheduled';
        let labelNeg = WpWorkflowTriggerType.REDIRECT === triggerType ? 'Lead Didn\'t Follow Redirect' : 'Lead Didn\'t Schedule';
        return (
          <View style={{ position: 'relative' }}>
            { WpWorkflowTriggerType.CALENDAR === triggerType && calendarItems ?
                <Dropdown
                  key={`dropdown-trigger-option`}
                  style={{ position: 'relative', zIndex: 10 }}
                  placeholder={"Select a Slide"}
                  items={calendarItems}
                  value={slideId}
                  onChange={onSelectSlide}
                /> : null
            }

            { WpWorkflowTriggerType.REDIRECT === triggerType || slideId ?
              <View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 15, position: 'relative' }}>
                  <Label name={labelPos} />
                  <Switch
                    value={performedAction}
                    onValueChange={setPerformedAction}
                    backgroundActive={Constants.colors.primary}
                    backgroundInactive={Constants.colors.infoLight}
                    circleActiveColor={'#FFF'}
                    circleInActiveColor={'#FFF'}
                    renderActiveText={false}
                    renderInActiveText={false}
                    circleBorderWidth={0}
                    circleSize={22}
                    switchLeftPx={3}
                    switchRightPx={3}
                    barHeight={26}
                  />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Label name={labelNeg} />
                  <Switch
                    value={!performedAction}
                    onValueChange={(value:boolean) => setPerformedAction(!value)}
                    backgroundActive={Constants.colors.primary}
                    backgroundInactive={Constants.colors.infoLight}
                    circleActiveColor={'#FFF'}
                    circleInActiveColor={'#FFF'}
                    renderActiveText={false}
                    renderInActiveText={false}
                    circleBorderWidth={0}
                    circleSize={22}
                    switchLeftPx={3}
                    switchRightPx={3}
                    barHeight={26}
                  />
                </View>
              </View> : null
            }
          </View>
        )
      } else if (WpWorkflowTriggerType.UNOPENED === triggerType) {
        return (
          <View>
            <Info info="Triggers when a pitch has not been opened. Cannot be combined with any other triggers." />
          </View>
        )
      }
    }
  }

  const saveTrigger = (id?: string) => {
    if (trigger.id === 'new' && !id) {
      createCampaignTrigger({ variables: {campaignWorkflowId: workflowId}});
    } else {
      let newTrigger = {
        workflowTriggerId: id ? id : trigger.id,
        triggerType: triggerType,
        triggerOption: slideId,
        triggerResponses: [other],
        seconds: seconds,
        days: days,
        hours: hours,
        performedAction: performedAction,
        widgetExpression: widgetExpression,
        widgetId: widgetId
      }

      updateTrigger({ variables: newTrigger })
    }
  }

  const onChangeType = (type: WpWorkflowTriggerType) => {
    setSlideId(undefined)
    setTriggerType(type)
  }

  if (!trigger) {
    return (<View></View>)
  }

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <WorkflowTriggerOptions active={triggerType} onPress={onChangeType} />
      <View style={{ flex: 1, position: 'relative', marginTop: 20, zIndex: 2 }}>
        { getSettings() }
      </View>

      <ModalFooter>
        <Button key="btn-trigger-edit-save" text="Save" onPress={saveTrigger} />
      </ModalFooter>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  bubble: {
    fontFamily: 'GothamMedium',
    paddingVertical: 13,
    paddingHorizontal: 20,
    borderRadius: 20,
    height: 40,
    color: Constants.colors.darkBlue,
    fontSize: 12,
    backgroundColor: '#F5F7FB',
    marginBottom: 15,
    width: '45%',
    textAlign: 'center'
  }
});
