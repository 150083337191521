import { convertFromRaw, convertToRaw, CompositeDecorator, ContentState, Modifier, Editor, EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import Constants from './../constants';

const window = Dimensions.get('window');
const HANDLED = 'handled';
const NOT_HANDLED = 'not-handled';
const MAX_LENGTH = 240

const rawContent = {
  blocks: [],
  entityMap: {}
};

export default function MessageEditor({ action, leadPhone, apptPlaceholders, messageContent, includePlaceholders, placeholderText = 'Type your message here', setMessageContent, setMessage }: { action?: boolean, leadPhone?: boolean, apptPlaceholders?: boolean, includePlaceholders?: boolean, messageContent?: object, placeholderText?: string, setMessageContent: (content: object) => void, setMessage: Function }): JSX.Element {
  const [hovering, setHovering] = useState<number | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState(screen);
  const [length, setLength] = useState(0);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  const blocks = convertFromRaw(messageContent && Object.keys(messageContent).length !== 0 ? messageContent : rawContent);
  const decorator = new CompositeDecorator([
    {
      strategy: getEntityStrategy('IMMUTABLE'),
      component: TokenSpan,
    }
  ]);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(blocks, decorator));

  const editor = React.useRef(null);
  const focus = () => {
    editor.current.focus();
  }

  const handleBeforeInput = (chars: string, editorState: EditorState) => {
    chars = chars.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    const currentContentState = editorState.getCurrentContent();
    const currentContentLength = currentContentState.getPlainText('').length

    if (currentContentLength < MAX_LENGTH) {
      // setLength(currentContentLength+1)
      const selectionState = editorState.getSelection();

      onChange(EditorState.push(
        editorState,
        Modifier.replaceText(
          currentContentState,
          selectionState,
          chars
        )
      ));
    }

    return HANDLED;
  };

  const handlePastedText = (chars: string, state: EditorState) => {
    chars = chars.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    const currentContentState = editorState.getCurrentContent();
    const currentContentLength = currentContentState.getPlainText('').length
    const overflowChars = chars.length + currentContentLength - MAX_LENGTH;

    if (overflowChars > 0 && chars.length) {
      const newChars = chars.substring(0, chars.length - overflowChars);
      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        newChars
      );
      setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));
      setLength(newChars.length + currentContentLength)
    } else {
      const selectionState = editorState.getSelection();

      onChange(EditorState.push(
        editorState,
        Modifier.replaceText(
          currentContentState,
          selectionState,
          chars
        )
      ));
    }

    return HANDLED;
  };

  const onChange = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    setMessageContent(convertToRaw(content))
    setMessage(content.getPlainText())
    setEditorState(editorState);

    const currentContentLength = content.getPlainText('').length
    setLength(currentContentLength)
  }

  let placeholders = [
    { label: `${Constants.localizations.Lead} First Name`, id: '#lead_first_name', color: Constants.colors.primary },
    { label: `${Constants.localizations.Lead} Last Name`, id: '#lead_last_name', color: Constants.colors.primaryMedium },
  ]
  if (leadPhone) {
    placeholders.push({ label: `${Constants.localizations.Lead} Phone`, id: '#lead_phone', color: Constants.colors.blue });
  }
  if (action) {
    placeholders.push({ label: 'List of Ifs', id: '#lead_actions', color: Constants.colors.red });
  }
  if (apptPlaceholders) {
    placeholders.push({ label: 'Event Date', id: '#appointment', color: Constants.colors.green });
    placeholders.push({ label: 'Event Time', id: '#appt_time', color: Constants.colors.green });
    placeholders.push({ label: 'Event Location', id: '#appt_location', color: Constants.colors.green });
  }
  if (includePlaceholders === false) {
    placeholders = [];
  }

  const insertPlaceholder = (label: string, placeholder: object) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity('TOKEN', 'IMMUTABLE', placeholder);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const textWithEntity = Modifier.replaceText(contentState, selection, label, null, entityKey);
    let newEditorState = EditorState.set(editorState, {
      currentContent: textWithEntity,
    });

    focus();
    setEditorState(newEditorState)

    newEditorState = EditorState.moveSelectionToEnd(newEditorState);
    setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));

    const newContentState = newEditorState.getCurrentContent();
    const currentContentLength = newContentState.getPlainText('').length
    setLength(currentContentLength + 1)
  }

  // const { state, dispatch } = React.useContext(CampaignContext);
  // const { data, loading, error } = useQuery(GET_CAMPAIGN, {
  //   variables: {
  //     campaignId: campaignId
  //   }
  // });

  // useEffect(() => {
  //   if (data?.campaign?.campaign) {
  //     dispatch({ type: WpCampaignAction.REFRESH_CAMPAIGN, campaign: data?.campaign?.campaign})
  //   }
  // }, [data])

  return (
    <div style={{ ...styles.root, maxWidth: dimensions.width * 0.8 }}>
      <div style={{ ...styles.editor, color: !!messageContent ? Constants.colors.dark : Constants.colors.secondary }} onClick={focus}>
        <Editor
          style={{ lineHeight: 5 }}
          editorState={editorState}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          onChange={onChange}
          onInput={onChange}
          onFocus={() => setFocused(true)}
          placeholder={focused ? "" : placeholderText}
          ref={editor}
        />
      </div>
      <div style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'row', marginBottom: 20, marginTop: 5, color: '#999' }}>
        {length && length >= MAX_LENGTH / 2 ? <span>2 messages per {Constants.localizations.lead}</span> : null}
        {length ? <span style={{ marginLeft: 'auto' }}>{length} / {MAX_LENGTH}</span> : null}
      </div>
      <div style={styles.placeholderButtons}>
        {placeholders.map((placeholder, index) => {
          return (
            <input
              key={placeholder.id}
              onClick={() => insertPlaceholder(placeholder.label, placeholder)}
              style={{ ...styles.button, marginBottom: 15, color: '#102A9C', backgroundColor: '#F5F7FB', opacity: index === hovering ? 0.7 : 1 }}
              type="button"
              onMouseEnter={() => setHovering(index)}
              onMouseLeave={() => setHovering(null)}
              value={placeholder.label}
            />
          );
        })}
      </div>
    </div>
  );
}

function getEntityStrategy(mutability) {
  return function (contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }
        return contentState.getEntity(entityKey).getMutability() === mutability;
      },
      callback
    );
  };
}

const TokenSpan = (props) => {
  const placeholder = props.contentState.getEntity(props.entityKey).getData();
  return (
    <span data-offset-key={props.offsetkey} style={{ ...styles.button, color: '#102A9C', backgroundColor: '#F5F7FB' }}>
      {props.children}
    </span>
  );
};

const styles = {
  root: {
    width: '100%',
    fontFamily: 'sans-serif',
    fontSize: 12
  },
  editor: {
    border: '1px solid ' + Constants.colors.secondaryLight,
    cursor: 'text',
    minHeight: 100,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 8,
    lineHeight: 2.5
  },
  button: {
    textAlign: 'center',
    marginRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
    fontWeight: 'bold',
    border: 0,
    cursor: 'pointer',
    fontFamily: 'GothamMedium'
  },
  immutable: {
    backgroundColor: Constants.colors.primaryMedium,
    color: '#FFF',
    padding: '2px 2px',
    borderRadius: 5
  },
  placeholderButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  }
};
