import { Text, View, Dimensions, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { XIcon, PlusCircleIcon } from '@heroicons/react/solid'
import Constants from './../constants';


export default function ModalBody({ title, children, onCreate, closeModal, width = 500, style = {} }: { title?: string, children: React.ReactNode, onCreate?: Function, closeModal?: Function, width?: number, style?: object }): JSX.Element {
  const [viewWidth, setViewWidth] = useState<number>(window.innerWidth)

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }

  useEffect(() => {
    const updateDimensions = () => {
      let w = window.innerWidth;
      setViewWidth(w > 768 ? w * 0.8 : w * 0.93);
    }

    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <View style={[styles.body, { width: viewWidth, maxWidth: width > viewWidth ? viewWidth : width }, style]}>
      <View style={[styles.headerWrapper, { justifyContent: title ? 'space-between' : 'flex-end' }]}>
        {title ? <Text style={styles.header}>{title}</Text> : null}
        {closeModal ? <XIcon style={cssStyles.icon} onClick={handleCloseModal} /> : null}
        {onCreate ? <PlusCircleIcon style={{ ...cssStyles.icon, color: Constants.colors.primary }} onClick={onCreate} /> : null}
      </View>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    margin: 25,
    marginBottom: 40,
    backgroundColor: '#FFF',
    borderRadius: 15,
    padding: 20
  },
  header: {
    fontSize: 20,
    color: Constants.colors.darkBlue,
    fontWeight: 'bold',
    paddingRight: 25,
    marginVertical: 20,
    width: '100%',
    textAlign: 'center'
  },
  headerWrapper: {
    flexDirection: 'row',
    borderBottomWidth: 0,
    borderBottomColor: Constants.colors.secondaryLight,
    marginBottom: 15,
    position: 'relative'
  }
});

const cssStyles = {
  icon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 25,
    height: 25,
    cursor: 'pointer',
    color: Constants.colors.secondary,
  }
}
