import { View, StyleSheet, Text, ScrollView, LayoutChangeEvent, Dimensions } from 'react-native';
import React from 'react';
import Modal from "react-native-modal";
import Constants from './../../constants';
import ModalNavbar from './ModalNavbar';


export default function FullscreenModal({ saveText="Save", maxWidth=800, isVisible, onSave, onBack, children, controls } : { saveText?: string, maxWidth?: number | string, isVisible: boolean, onSave?: () => void, onBack: () => void, children: React.ReactNode, controls: React.ReactNode }): JSX.Element {
  const [viewWidth, setViewWidth] = React.useState<number>(Dimensions.get("window").width)
  const [viewHeight, setViewHeight] = React.useState<number>(Dimensions.get("window").height)

  const handleOnBack = () => {
    onBack()
  }

  const onLayout = (event: LayoutChangeEvent) => {
    setViewWidth(Dimensions.get("window").width)
    setViewHeight(Dimensions.get("window").height)
  }

  return (
    <View style={{ flex: 1, position: 'relative' }} onLayout={onLayout}>
      <Modal style={styles.modal} deviceHeight={viewHeight} deviceWidth={viewWidth} isVisible={isVisible} backdropOpacity={1} backdropColor={Constants.colors.backgroundLight}>
        <ModalNavbar onBack={handleOnBack} saveText={saveText} onSave={onSave}>
          { controls }
        </ModalNavbar>
        <View style={[styles.scrollViewWrapper, { maxWidth: maxWidth }]}>
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={styles.container}
          >
            { children }
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  modal: {
    position: 'relative',
    justifyContent: 'flex-start',
    margin: 0,
  },
  container: {
    position: 'relative',
    height: '100%'
  },
  scrollViewWrapper: {
    flex: 1,
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto'
  }
});
