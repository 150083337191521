import { Text, View, Pressable, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { CloudUploadIcon, CalendarIcon, DesktopComputerIcon, VideoCameraIcon, PuzzleIcon, MusicNoteIcon } from '@heroicons/react/outline';
import Constants from './../constants'
import { WpSlideType } from './../contexts/pitch';
import { Feather } from '@expo/vector-icons';
import { IconButton } from '@whistlepitch/wp-components';
import Icon from './Icon';
import { UserContext } from '../contexts/user';

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}


export default function PitchSlideOptions({ active, onPress }: { active?: WpSlideType | null, onPress: (type?: WpSlideType | null) => void }): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [hovering, setHovering] = useState<WpSlideType | null>(null);

  const onHover = (what: WpSlideType) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(null);
  }

  const getColor = (type: WpSlideType) => {
    return {
      YOUTUBE: Constants.colors.danger,
      UPLOAD: Constants.colors.primary,
      SCREEN: Constants.colors.success,
      RECORD: Constants.colors.warning,
      CALENDAR: Constants.colors.accent,
      TEXT: Constants.colors.blue,
      AUDIO: Constants.colors.blue
    }[type]
  }

  const getExtraStyles = (type: WpSlideType) => {
    let color = getColor(type);
    let opacity = hovering === type || active === type ? 0.6 : 1;

    return {
      opacity: opacity,
      backgroundColor: hovering === type || active === type ? color : '#FFF',
      color: hovering !== type && active !== type ? color : '#FFF',
      border: '1px solid ' + color
    }
  }

  return (
    <View style={styles.container}>
      {!Constants.blastView ?
        <IconButton style={{ flex: 1 }} label="Upload Content" type={active === WpSlideType.UPLOAD ? "Blue" : "AccentBlue"} onClick={() => onPress(WpSlideType.UPLOAD)}>
          <CloudUploadIcon style={cssStyles.icon} />
        </IconButton> : null}
      {/*<IconButton style={{ width: 70 }} label="Screen Record" type={active === WpSlideType.SCREEN ? "Blue" : "AccentBlue"} onClick={() => onPress(WpSlideType.SCREEN)}>
        <DesktopComputerIcon style={cssStyles.icon} />
      </IconButton> */}
      {/*<IconButton style={{ width: 70 }} label="Record" type={active === WpSlideType.RECORD ? "Blue" : "AccentBlue"} onClick={() => onPress(WpSlideType.RECORD)}>
        <VideoCameraIcon style={cssStyles.icon} />
      </IconButton>*/}
      {Constants.controls.allowSlideTypeYoutube ?
        <IconButton style={{ flex: 1 }} label="YouTube" type={active === WpSlideType.YOUTUBE ? "Blue" : "AccentBlue"} onMouseEnter={() => onHover(WpSlideType.YOUTUBE)} onMouseLeave={onHoverOut} onClick={() => onPress(WpSlideType.YOUTUBE)}>
          <Icon style={{ position: 'relative', top: -2 }} name="video" width={15} height={21.9} color="currentColor" colorHover="currentColor" />
        </IconButton> : null}
      {Constants.controls.allowSlideTypeAudio && state.user?.organization?.showAudio ?
        <IconButton style={{ flex: 1 }} label="Audio" type={active === WpSlideType.AUDIO ? "Blue" : "AccentBlue"} onMouseEnter={() => onHover(WpSlideType.AUDIO)} onMouseLeave={onHoverOut} onClick={() => onPress(WpSlideType.AUDIO)}>
          <MusicNoteIcon style={cssStyles.icon} />
        </IconButton> : null}
      {Constants.controls.allowSlideTypeCalendly ?
        <IconButton style={{ flex: 1 }} label="Calendly" type={active === WpSlideType.CALENDAR ? "Blue" : "AccentBlue"} onClick={() => onPress(WpSlideType.CALENDAR)}>
          <CalendarIcon style={cssStyles.icon} />
        </IconButton> : null}
      {Constants.controls.allowSlideTypeText || (Constants.whistlePitch && state.user?.organization?.id === '59') ?
        <IconButton style={{ flex: 1 }} label="Text" type={active === WpSlideType.TEXT ? "Blue" : "AccentBlue"} onClick={() => onPress(WpSlideType.TEXT)}>
          <Icon style={{ position: 'relative', top: -2 }} name="bars" width={20} height={20} color="currentColor" colorHover="currentColor" />
        </IconButton> : null}
      {!Constants.blastView ?
        <IconButton style={{ flex: 1 }} label="Widgets Only" type={active === null ? "Blue" : "AccentBlue"} onClick={() => onPress(null)}>
          <PuzzleIcon style={cssStyles.icon} />
        </IconButton> : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    marginBottom: 15
  },
  iconWrapper: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 8,
    textAlign: 'center'
  },
  text: {
    fontWeight: 'bold',
    fontSize: 10
  }
});

const cssStyles = {
  icon: {
    paddingBottom: 5,
    height: 20,
    minWidth: 50,
  }
}
