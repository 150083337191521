import { View, Text, StyleSheet, Image, LayoutChangeEvent } from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import Toast from 'react-native-toast-message';

import Constants from '../../constants';
import { UserContext } from '../../contexts/user';
import CampaignOverview from '../../components/CampaignOverview';
import LeadTable from '../../components/Leads/LeadTable';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import WpModal from '../../components/WpModal';
import ModalFooter from '../../components/ModalFooter';
import ModalBody from '../../components/ModalBody';
import { Button, Brand, PageTitle, Input, BrandLogo, BrandHeading } from '@whistlepitch/wp-components';
import Wave from 'react-wavify'
import ReactPlayer from 'react-player'
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { useNavigate } from '../../react-router';
import { TRY_NOW } from '../../graphql/mutations';
import { useLazyQuery, useMutation } from "@apollo/client";


enum IconType {
  SETTINGS = "SETTINGS"
}

interface SummaryData {
  label: string
  value: number
  tooltip: string
}

function WpIcon({ type=IconType.SETTINGS } : { type?: IconType }): JSX.Element {
  if (type === IconType.SETTINGS) {
    return <Image style={{ width: 26, height: 26 }} source={require('./../../assets/fi_settings.png')} />
  } else {
    return <View></View>
  }
}


export default function WhistlePitchLandingPage(): JSX.Element {
  const player = useRef(null);

  const [viewWidth, setViewWidth] = useState<number>(0);
  const [videoWidth, setVideoWidth] = useState<number>(0);
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [showTryNow, setShowTryNow] = useState<boolean>(false);

  const navigate = useNavigate();

  const [tryNow, { data, loading }] = useMutation(TRY_NOW, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (data) {
      setShowTryNow(false);
      if (data.tryNow.success) {
        Toast.show({
          type: 'success',
          text1: 'Check your phone!',
          text2: 'A WhistlePitch is on its way. Open it up for more info!'
        });
      } else {
        Toast.show({
          type: 'error',
          text1: 'Error',
          text2: 'We couldn\'t send you a message at this time.'
        });
      }
    }
  }, [data])

  const onTryNow = () => {
    tryNow({ variables: { name: name, phone: phone }})
  }

  const openTryNow = () => {
    setShowTryNow(true);
  }

  const closeTryNow = () => {
    setShowTryNow(false);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    var {x, y, width, height} = event.nativeEvent.layout;

    setViewWidth(width)
  }

  const onVideoLayout = (event: LayoutChangeEvent) => {
    var {x, y, width, height} = event.nativeEvent.layout;

    setVideoWidth(width)
  }

  const onLogin = () => {
    // window.top.location.href = `https://www.whistlepitch.com/dashboard`;
    navigate('/dashboard')
  }

  const changeName = (e: any) => {
    setName(e.target.value)
  }

  const changePhone = (e: any) => {
    setPhone(e.target.value)
  }

  return (
    <View style={{ flex: 1, backgroundColor: '#FFF', position: 'relative' }} onLayout={onLayout}>
      <WpModal isVisible={showTryNow}>
        <ModalBody title="Text Yourself an Example" closeModal={closeTryNow}>
          <Input
            id="name"
            label="Full Name"
            name="name"
            placeholder="Enter Your Name..."
            onChange={changeName}
          />
          <Input
            style={{ marginTop: 15 }}
            id="phone"
            label="Full Phone"
            name="phone"
            placeholder="Enter Your Phone Number..."
            onChange={changePhone}
          />
          <ModalFooter>
            <Button style={{ width: 100 }} onClick={onTryNow} shape="Pill" size="Default" type="Primary">
              { loading ? <Spinner /> : <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>Confirm</Text> }
            </Button>
          </ModalFooter>
        </ModalBody>
      </WpModal>
      <View style={styles.navbar}>
        <View style={{ flexDirection: 'row', gap: 15, alignItems: 'center' }}>
          <BrandLogo brand={Constants.brand} />
          { viewWidth > 400 ? <BrandHeading size="Small" /> : null }
        </View>
        <View style={{ justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row' }}>
          <Text style={styles.login} onPress={onLogin}>Login</Text>
          <Button onClick={openTryNow} shape="Pill" size="Default" type="Primary">
            <Text style={{ color: '#FFF', fontFamily: 'GothamMedium' }}>Try Now</Text>
          </Button>
        </View>
      </View>
      <View style={{ position: 'relative', zIndex: 1 }}>
        <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 1, backgroundColor: '#F8F9FF' }}>
          <View style={{ maxWidth: 700, marginTop: 225, marginBottom: 10, alignItems: 'center', padding: 25 }}>
            <PageTitle style={{ fontFamily: 'GothamBold', textAlign: 'center' }} title="the best way to mass communicate with your audience" size="Large" />
            {/* <PageTitle style={{ fontFamily: 'GothamBold', textAlign: 'center' }} title="to do what you want" size="Large" /> */}
            <View style={{ maxWidth: 650, marginTop: 25, width: '100%', alignItems: 'center' }}>
              <Text style={styles.subheading}>Mass texting is usually really bleh, so we decided to attach content, add tracking, and allow triggers to make it 10,000 times more effective and fun. That’s Science.</Text>
            </View>

            <Button style={{ marginTop: 50, backgroundColor: Constants.colors.darkBlue }} onClick={openTryNow} shape="Pill" size="Large" type="Primary">
              <Text style={{ color: '#FFF', fontSize: 20, paddingHorizontal: 15, fontFamily: 'GothamMedium' }}>Text Yourself an Example</Text>
            </Button>
          </View>
          <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2 }}>
            <Wave
              style={{ height: 500 }}
              fill='#FFF'
              paused={true}
              options={{
                height: 200,
                amplitude: 700,
                points: 2
              }}
            />
          </View>
        </View>

        <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 3 }}>
          <PageTitle style={{ fontFamily: 'GothamBold', textAlign: 'center', fontSize: 28, color: Constants.colors.darkBlue, marginBottom: 40 }} title="How it works" size="Large" />

          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 105, flexWrap: 'wrap', marginBottom: 50, padding: 15 }}>
            <Image style={{ width: 310, height: 268, marginHorizontal: 50 }} source={require('./../../assets/landingpage/bv_subscribers.png')} />
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Add your target audience" />
              <Text style={styles.useCasesText}>Your audience is truly unlimited with WhistlePitch. All you need is a name and a phone number to get started. We make it easy for you to add contacts, create groups and communicate with exactly who you want to reach.</Text>
            </View>
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 105, flexWrap: 'wrap-reverse', marginBottom: 50, padding: 15 }}>
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Send text messages with unique links" />
              <Text style={styles.useCasesText}>Easily attach your content to text message blasts with WhistlePitch. Content is neatly packaged into a unique link so we can track each individual's experience.</Text>
            </View>
            <Image style={{ width: 240, height: 262, marginHorizontal: 50 }} source={require('./../../assets/landingpage/wp_unique_links.png')} />
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 105, flexWrap: 'wrap', marginBottom: 50, padding: 15 }}>
            <Image style={{ width: 240, height: 482, marginHorizontal: 50 }} source={require('./../../assets/landingpage/wp_smart_content.png')} />
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Engage users with smart content" />
              <Text style={styles.useCasesText}>At WhistlePitch, we are proud to have pioneered smart content. Smart content is content that allows tracking and triggers. We've made it easy to build, easy to brand, and we always make sure what you send is clean and engaging.</Text>
            </View>
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 105, flexWrap: 'wrap-reverse', marginBottom: 50, padding: 15 }}>
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Track real time engagement" />
              <Text style={styles.useCasesText}>Whether your target audience is 1 or 10,000 - because of our smart content, we can give you real time analytics of engagement. At a quick glance you will understand the current status of your campaign, and know exactly where your audience is in your smart content slideshow.</Text>
            </View>
            <Image style={{ width: 310, height: 190, marginHorizontal: 50 }} source={require('./../../assets/landingpage/bv_preview.png')} />
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 105, flexWrap: 'wrap', marginBottom: 50, padding: 15 }}>
            <Image style={{ width: 310, height: 268, marginHorizontal: 50 }} source={require('./../../assets/landingpage/wp_automation.png')} />
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Trigger automation based on engagement" />
              <Text style={styles.useCasesText}>Our If/Then feature allows you to trigger any type of automation based on real time engagement with your smart content. Not only can you use some of our internal automation like notifications, automated texts to recipients, or automated adds to different campaigns; but you can trigger any event to begin with 3rd party platforms that also have an open API.</Text>
            </View>
          </View>

          <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 1 }}>
            <Wave
              style={{ height: 500 }}
              fill='#EBF0FF'
              paused={true}
              options={{
                height: 200,
                amplitude: 700,
                points: 2
              }}
            />
          </View>
        </View>

        <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2, backgroundColor: '#EBF0FF' }}>
          <View style={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 30 }}>
            <View style={{ alignItems: 'center' }}>
              <Image style={{ width: 65, height: 65 }} source={require('./../../assets/MassTexting.png')} />
              <PageTitle style={cssStyles.howWorksTitle} title="Mass Texting" size="Small" />
              <Text style={styles.howWorksText}>Easily text your entire audience with unique, trackable links to content you want them to view.</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <Image style={{ width: 68, height: 65 }} source={require('./../../assets/Video.png')} />
              <PageTitle style={cssStyles.howWorksTitle} title="Content" size="Small" />
              <Text style={styles.howWorksText}>Attach slides of content (videos, graphics, trainings, etc.) to your text message blast. Easily brand your content with your logo and company colors.</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <Image style={{ width: 68, height: 65 }} source={require('./../../assets/Tracking.png')} />
              <PageTitle style={cssStyles.howWorksTitle} title="Tracking" size="Small" />
              <Text style={styles.howWorksText}>Track every second of your audience's engagement with your content so you know exactly who to follow up with and how..</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <Image style={{ width: 60, height: 65 }} source={require('./../../assets/Funnels.png')} />
              <PageTitle style={cssStyles.howWorksTitle} title="Funnels" size="Small" />
              <Text style={styles.howWorksText}>Funnel your audience with automated actions based on their engagement and help them achieve your desired outcomes.</Text>
            </View>
          </View>

          <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2 }}>
            <Wave
              style={{ height: 500 }}
              fill='#FFF'
              paused={true}
              options={{
                height: 200,
                amplitude: 700,
                points: 2
              }}
            />
          </View>
        </View>

        <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2, backgroundColor: '#FFF' }}>
          <PageTitle style={{ fontFamily: 'GothamBold', textAlign: 'center', fontSize: 28, marginBottom: 100, color: Constants.colors.darkBlue }} title="Use cases" size="Large" />
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 30, flexWrap: 'wrap', marginBottom: 50, padding: 15 }}>
            <Image style={{ width: 390, height: 482, marginHorizontal: 50 }} source={require('./../../assets/AppointmentFunnels.png')} />
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Appointment funnels" />
              <Text style={styles.useCasesText}>WhistlePitch increases the rate at which your audience schedules, and more importantly, keeps appointments. WhistlePitch sends unique links via text to your audience that navigates them to the content you want them to view, and then it immediately schedules their appointment while their engagement is highest. Don’t let your leads sit around and go cold--get them interacting with your company ASAP!</Text>
            </View>
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 30, flexWrap: 'wrap-reverse', marginBottom: 50, padding: 15 }}>
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Group blasts" />
              <Text style={styles.useCasesText}>Engage with your audience in more meaningful, personalized, and human ways using WhistlePitch. Send your content directly to your entire audience’s phones. Direct them to personalized videos, documents, surveys, or whatever content you need them to view so they can progress. Track every second and every click of their engagement.</Text>
            </View>
            <Image style={{ width: 390, height: 482, marginHorizontal: 50 }} source={require('./../../assets/GroupBlasts.png')} />
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 30, flexWrap: 'wrap', marginBottom: 50, padding: 15 }}>
            <Image style={{ width: 340, height: 482, marginHorizontal: 50 }} source={require('./../../assets/AutomatedIntroductions.png')} />
            <View>
              <PageTitle style={cssStyles.useCasesTitle} title="Automated introductions" />
              <Text style={styles.useCasesText}>Alleviate the anxiety your audience might have by sending personalized, custom videos based on whatever criteria you desire. Your videos will be sent to their phones with unique, trackable links that lets you know to the second how long each recipient viewed your video so you can prioritize your audience and your follow-up efforts.</Text>
            </View>
          </View>

          <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2 }}>
            <Wave
              style={{ height: 500 }}
              fill='#EBF0FF'
              paused={true}
              options={{
                height: 200,
                amplitude: 700,
                points: 2
              }}
            />
          </View>
        </View>

        <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2, backgroundColor: '#EBF0FF' }}>
          <View style={{ maxWidth: 650, marginBottom: 10, alignItems: 'center', padding: 25 }}>
            <PageTitle style={{ fontFamily: 'GothamBold', textAlign: 'center' }} title="A better way to mass communicate" size="Default" />
            <Button style={{ marginTop: 50, backgroundColor: Constants.colors.darkBlue }} onClick={openTryNow} shape="Pill" size="Large" type="Primary">
              <Text style={{ color: '#FFF', fontSize: 18, paddingHorizontal: 25, fontFamily: 'GothamMedium' }}>Text Yourself an Example</Text>
            </Button>
          </View>

          <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2 }}>
            <Wave
              style={{ height: 500 }}
              fill='#FFF'
              paused={true}
              options={{
                height: 200,
                amplitude: 700,
                points: 2
              }}
            />
          </View>
        </View>

        <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2, backgroundColor: '#FFF' }}>

          <PageTitle style={{ fontFamily: 'GothamBold', textAlign: 'center', color: Constants.colors.darkBlue }} title="Why WhistlePitch" size="Default" />
          <View style={{ width: '100%', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', position: 'relative', zIndex: 3, marginTop: 30, marginBottom: 120 }}>
            <View style={{ position: 'relative' }}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <WpIcon />
                <Text style={styles.featureText}>get applications to schedule an interview</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center', position: 'relative', left: -50 }}>
                <WpIcon />
                <Text style={styles.featureText}>get sales inquires to book with your reps</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <WpIcon />
                <Text style={styles.featureText}>get voters to vote for you</Text>
              </View>
            </View>
            <View style={{ position: 'relative' }}>
              <Image style={{ width: 241, height: 482, marginHorizontal: 75, marginVertical: 30 }} source={require('./../../assets/landing_page_phone.png')} />
            </View>
            <View style={{ position: 'relative' }}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <WpIcon />
                <Text style={styles.featureText}>get fans to buy concert tickets</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center', position: 'relative', right: -50 }}>
                <WpIcon />
                <Text style={styles.featureText}>get employees to be compliant</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <WpIcon />
                <Text style={styles.featureText}>get users to understand next steps</Text>
              </View>
            </View>
          </View>

          <View style={{ maxWidth: 850, marginBottom: 10, alignItems: 'center' }}>
            <PageTitle style={{ fontFamily: 'GothamBold', textAlign: 'center', color: Constants.colors.darkBlue }} title="What our customers are saying" size="Default" />

            <View style={[styles.playerWrapper, { width: '90%', maxWidth: 850, marginTop: 100, borderRadius: 5 }]} onLayout={onVideoLayout}>
              <ReactPlayer
                ref={player}
                key={`video-player`}
                style={cssStyles.player}
                url={'https://whistlepitch-test.s3.us-west-1.amazonaws.com/48/6daf31921549afad394a992e359d09/Hunter---Bacon---WP-Testimonial.mp4'}
                light="https://whistlepitch.s3.us-west-1.amazonaws.com/88/c1b8e0ad3a452384b4470b0a5133d8/thumbnail.png"
                controls={true}
                pip={false}
                width='100%'
                playsinline={true}
                height={viewWidth * 9 / 16}
              />
            </View>
            <View style={{ marginTop: 50, alignItems: 'center' }}>
              <PageTitle style={cssStyles.howWorksTitle} title="Bacon" />
              <Text style={styles.customersText}>It’s important to be able to respond to leads immediately when they come in and while they’re still active. With WhistePitch, it creates a personalized, automated video where the sales team member can create that immediate connection with their contacts. Through WhistlePitch, 80% of our inbound leads converted into meetings with our sales team.</Text>
            </View>
          </View>

          <View style={{ width: '100%', alignItems: 'center', position: 'relative', zIndex: 2 }}>
            <Wave
              style={{ height: 500 }}
              fill={Constants.colors.darkBlue}
              paused={true}
              options={{
                height: 200,
                amplitude: 700,
                points: 2
              }}
            />
            <View style={{ position: 'absolute', bottom: 40, left: 0, right: 0, flexDirection: viewWidth > 675 ? 'row' : 'column', justifyContent: 'space-between', alignItems: viewWidth > 675 ? 'flex-start' : 'center', paddingHorizontal: 40 }}>
              <View style={{ alignItems: viewWidth > 675 ? 'flex-start' : 'center' }}>
                <View style={{ flexDirection: 'row', gap: 10 }}>
                  <BrandLogo size="Small" type="White" />
                  <BrandHeading size="Small" type="White" />
                </View>
                <View style={{ flexDirection: 'row', gap: 20, marginTop: 20 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Feather name="phone" size={14} color="white" />
                    { viewWidth > 400 ? <Text style={{ color: '#FFF', paddingLeft: 15 }}>800 123 1234</Text> : null }
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <Feather name="mail" size={14} color="white" />
                    { viewWidth > 400 ? <Text style={{ color: '#FFF', paddingLeft: 15 }}>info@whistlepitch.com</Text> : null }
                  </View>
                </View>
              </View>
              <View style={{ alignItems: viewWidth > 675 ? 'flex-start' : 'center', marginTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                  <Text style={{ color: '#FFF' }}>WhistlePitch</Text>
                  <MaterialIcons name="copyright" size={12} color="white" />
                  <Text style={{ color: '#FFF' }}>2019-2022</Text>
                </View>
                <Text style={{ color: '#FFF', opacity: 0.5, fontSize: 10, marginTop: 5 }}>{Constants.version}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight,
  },
  login: {
    color: Constants.colors.darkBlue,
    marginHorizontal: 25,
    fontFamily: 'GothamMedium',
  },
  navbar: {
    backgroundColor: '#FFF',
    zIndex: 10,
    position: 'absolute',
    top: 0,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: 20,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 14,
    shadowColor: '#000',
  },
  subheading: {
    fontFamily: 'GothamBold',
    color: Constants.colors.mediumBlue,
    textAlign: 'center',
    fontSize: 20
  },
  featureText: {
    fontFamily: 'GothamMedium',
    width: 220,
    fontSize: 18,
    color: Constants.colors.darkBlue,
    marginLeft: 17,
    paddingVertical: 25
  },
  howWorksImg: {
    width: 65,
    height: 65
  },
  howWorksText: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: 'Gotham',
    color: Constants.colors.darkBlue,
    width: 250,
    textAlign: 'center'
  },
  useCasesText: {
    fontSize: 13,
    lineHeight: 19,
    fontFamily: 'Gotham',
    color: Constants.colors.dark,
    width: 400
  },
  customersText: {
    fontSize: 13,
    lineHeight: 19,
    fontFamily: 'Gotham',
    color: Constants.colors.dark,
    width: 400,
    textAlign: 'center'
  },
  playerWrapper: {
    position: 'relative',
    // flex: 1,
    overflow: 'hidden'
  }
})

const cssStyles = {
  svg: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  container: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden'
  },
  howWorksTitle: {
    fontFamily: 'GothamBold',
    color: Constants.colors.darkBlue,
    fontSize: 16,
    marginTop: 15,
    marginBottom: 10
  },
  useCasesTitle: {
    fontFamily: 'GothamBold',
    color: Constants.colors.blue,
    fontSize: 20,
    marginTop: 15,
    marginBottom: 10,
    width: 400,
    lineHeight: 1.2
  },
  player: {
    borderRadius: 10,
    padding: 0,
    overflow: 'hidden',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  }
}
