import { View, Text } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useEffect, useState } from 'react';
import Dashboard from './screens/Dashboard';
import Account from './screens/Account';
import { UserContext, userReducer } from './contexts/user';
import { PitchContext, pitchReducer, initialState } from './contexts/pitch';
import Constants from './constants';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { Router, Routes, Route, Navigate } from "./react-router";
import Navbar from './components/Navbar';
import WhistlePitchLandingPage from './screens/LandingPages/WhistlePitchLandingPage';
import BlastViewLandingPage from './screens/LandingPages/BlastViewLandingPage';
import Register from './screens/Register';
import Campaign from './screens/Campaign';
import CampaignLibrary from './screens/CampaignLibrary';
import Library from './screens/Library';
import Login from './screens/Login';
import ResetPassword from './screens/ResetPassword';
import ForgotPasswordRequest from './screens/ForgotPasswordRequest';
import Campaigns from './screens/Campaigns';
import Groups from './screens/Groups';
import Events from './screens/Events';
import LeadPitchProvider from './screens/LeadPitchProvider';
import GroupLandingPageProvider from './screens/GroupLandingPageProvider';
import Toast, { BaseToast, ErrorToast } from 'react-native-toast-message';
import ReactPixel from 'react-facebook-pixel';
import { useFonts } from 'expo-font';

// import '@whistlepitch/wp-components/build/fonts/gotham/gotham-300.css'
// import '@whistlepitch/wp-components/build/fonts/gotham/gotham-400.css'
// import '@whistlepitch/wp-components/build/fonts/gotham/gotham-500.css'
// import '@whistlepitch/wp-components/build/fonts/gotham/gotham-700.css'
// import '@whistlepitch/wp-components/build/fonts/gotham/index.css'


import './assets/css/wp_reset.css';
import './assets/css/wp_core.css';
import './assets/css/wp_fix.css';

// import './assets/gotham/gotham-300.css'
// import './assets/gotham/gotham-400.css'
// import './assets/gotham/gotham-500.css'
// import './assets/gotham/gotham-700.css'

import CampaignLandingPageProvider from './screens/CampaignLandingPageProvider';
import LandingPageProvider from './screens/LandingPageProvider';
import Group from './screens/Group';
import Users from './screens/Users';
import Messages from './screens/Messages';
import ClearOldToken from './screens/ClearOldToken';
import Logout from './screens/Logout';
import LandingPages from './screens/LandingPages';
import LandingPage from './screens/LandingPage';
import BodyParts from './screens/BodyParts';
import OppTextLandingPage from './screens/LandingPages/OppTextLandingPage';
import SuperAdmin from './screens/SuperAdmin';
import Status from './screens/Status';
import Policy from './screens/Policy';

const httpLink = createHttpLink({
  uri: Constants.api,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      // 'X-USER-TOKEN': token ? token : "",
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  })
});

const toastConfig = {
  success: (props) => (
    <BaseToast
      {...props}
      style={{ borderLeftColor: Constants.colors.blue, marginLeft: 'auto', marginRight: 25, position: 'relative', zIndex: 99999 }}
    />
  ),
  error: (props) => (
    <ErrorToast
      {...props}
      style={{ borderLeftColor: Constants.colors.red, marginLeft: 'auto', marginRight: 25, position: 'relative', zIndex: 99999 }}
    />
  )
};

export default function App() {
  const userContext = React.useReducer(userReducer, { user: null });
  const pitchContext = React.useReducer(pitchReducer, { pitch: null });

  const [title, setTitle] = useState("WhistlePitch");
  const [LiveLandingPage, setLandingPage] = useState<JSX.Element | null>(null);

  useEffect(() => {
    // let link = document.querySelector("link[rel~='icon']");
    // if (!link) {
    //   link = document.createElement('link');
    //   link.rel = 'icon';
    //   document.getElementsByTagName('head')[0].appendChild(link);
    // }

    let isWhistlePitch = process.env.EXPO_BLAST_VIEW !== '1';
    if (process.env.EXPO_BLAST_VIEW === '1') {
      setTitle("BlastView")
      setLandingPage(<BlastViewLandingPage />)
    } else if (process.env.EXPO_OPP_TEXT === '1') {
      setTitle("OppText")
      setLandingPage(<OppTextLandingPage />)
      // link.href = 'https://stackoverflow.com/favicon.ico';
    } else {
      setLandingPage(<WhistlePitchLandingPage />)
    }

    ReactPixel.init(Constants.facebookPixelId) // facebookPixelId
  }, [])

  useEffect(() => {
    document.title = title;
  }, [title]);

  const [loaded] = useFonts({
    GothamBlack: require('./assets/Fonts/GothamBlack.otf'),
    GothamBold: require('./assets/Fonts/GothamBold.otf'),
    Gotham: require('./assets/Fonts/GothamLight.otf'),
    GothamBook: require('./assets/Fonts/GothamMedium.otf'),
    GothamMedium: require('./assets/Fonts/GothamMedium.otf'),
    // GothamBook: require('./assets/gotham/GothamBold.woff2'),
  });

  if (!loaded) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Router>
        <UserContext.Provider value={{ state: userContext[0], dispatch: userContext[1] }}>
          <PitchContext.Provider value={{ state: pitchContext[0], dispatch: pitchContext[1] }}>
            <Routes>
              <Route path="/" element={LiveLandingPage} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/health" element={<Text>OK</Text>} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/account" element={<Account />} />
              <Route path="/users" element={<Users />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/events" element={<Events />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/group/:groupId" element={<Group />} />
              <Route path="/landing-pages" element={<LandingPages />} />
              <Route path="/landing-page/:landingPageId" element={<LandingPage />} />
              <Route path="/body-parts" element={<BodyParts />} />
              <Route path="/library" element={<Library />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/clear-token" element={<ClearOldToken />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/forgot-password-request" element={<ForgotPasswordRequest />} />
              <Route path="/campaigns" element={<Campaigns />} />
              <Route path="/campaign-library" element={<CampaignLibrary />} />
              <Route path="/campaign/:campaignId" element={<Campaign />} />
              <Route path="/register" element={<Register />} />
              <Route path="/super-admin" element={<SuperAdmin />} />
              <Route path="/status" element={<Status />} />
              <Route path="/register/:token" element={<Register />} />
              <Route path="/g/:groupCode" element={<GroupLandingPageProvider />} />
              <Route path="/p/:landingPageCode" element={<LandingPageProvider />} />
              <Route path="/c/:campaignLeadCode/:campaignFeatureCode" element={<LeadPitchProvider />} />
              <Route path="/l/:campaignCode" element={<CampaignLandingPageProvider />} />
              <Route
                path="*"
                element={<Navigate to="/" replace />}
              />
            </Routes>
          </PitchContext.Provider>
        </UserContext.Provider>
      </Router>
      <Toast config={toastConfig} />
    </ApolloProvider>
  );
}