import { Text, View, Pressable, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { UserCircleIcon, UsersIcon, UserIcon, SpeakerphoneIcon, ServerIcon } from '@heroicons/react/outline';
import Constants from './../constants'
import { WpWorkflowActionType } from './../contexts/campaign';
import { IconButton } from '@whistlepitch/wp-components';
import { UserContext } from '../contexts/user';


export default function WorkflowActionOptions({ active, onPress }: { active?: WpWorkflowActionType, onPress: Function }): JSX.Element {
  const userContext = React.useContext(UserContext);
  const [showAppt, setShowAppt] = useState<boolean>(!!userContext.state.user?.showAppt)
  const [hovering, setHovering] = useState<WpWorkflowActionType | null>(null);

  const onHover = (what: WpWorkflowActionType) => {
    setHovering(what);
  }

  const onHoverOut = () => {
    setHovering(null);
  }

  const getColor = (type: WpWorkflowActionType) => {
    return {
      ME: Constants.colors.primary,
      SPECIFIC: Constants.colors.primaryMedium,
      LEAD: Constants.colors.accent,
      CAMPAIGN: Constants.colors.success,
      WEBHOOK: Constants.colors.warning,
    }[type]
  }

  const getExtraStyles = (type: WpWorkflowActionType) => {
    let color = getColor(type);
    let opacity = hovering === type ? 0.6 : 1;

    if (active !== type) {
      return {
        opacity: opacity,
        backgroundColor: hovering === type ? color : '#FFF',
        color: hovering !== type ? color : '#FFF',
        border: '1px solid ' + color
      }
    } else {
      return {
        opacity: opacity,
        backgroundColor: color,
        color: '#FFF',
        border: '1px solid ' + color
      }
    }
  }

  return (
    <View style={styles.container}>
      <IconButton style={{ flex: 1 }} label="Notify Me" type={active === WpWorkflowActionType.ME ? "Blue" : "AccentBlue"} onClick={() => onPress(WpWorkflowActionType.ME)}>
        <UserCircleIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ flex: 1 }} label="Notify Users" type={active === WpWorkflowActionType.SPECIFIC ? "Cyan" : "AccentCyan"} onClick={() => onPress(WpWorkflowActionType.SPECIFIC)}>
        <UsersIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ flex: 1 }} label="Message Lead" type={active === WpWorkflowActionType.LEAD ? "Purple" : "AccentPurple"} onClick={() => onPress(WpWorkflowActionType.LEAD)}>
        <UserIcon style={cssStyles.icon} />
      </IconButton>
      <IconButton style={{ flex: 1 }} label="Add to Campaign" type={active === WpWorkflowActionType.CAMPAIGN ? "Green" : "AccentGreen"} onClick={() => onPress(WpWorkflowActionType.CAMPAIGN)}>
        <SpeakerphoneIcon style={cssStyles.icon} />
      </IconButton>
      {showAppt ?
        <IconButton style={{ flex: 1 }} label="Webhook" type={active === WpWorkflowActionType.WEBHOOK ? "Red" : "AccentRed"} onClick={() => onPress(WpWorkflowActionType.WEBHOOK)}>
          <ServerIcon style={cssStyles.icon} />
        </IconButton> : null}
    </View>
  );
}

const styles = StyleSheet.create({
  separator: {
    marginHorizontal: 10,
    borderRightColor: Constants.colors.info,
    borderRightWidth: 1,
    width: 2,
    height: '90%'
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    marginBottom: 15
  },
  iconWrapper: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 8,
    textAlign: 'center'
  },
  text: {
    fontWeight: 'bold',
    fontSize: 10
  }
});

const cssStyles = {
  icon: {
    paddingBottom: 5,
    height: 20,
    minWidth: 50,
  }
}
