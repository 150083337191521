import { View, StyleSheet, Text, Image } from 'react-native';
import React, { useState } from 'react';
import Constants from './../../constants';
import {loadStripe} from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Button, Theme } from './../Button';
import Spinner from '../Spinner';
import Input from '../Input';


const stripePromise = loadStripe(Constants.stripePk);

export function BankForm({ themeColor, fineText, onComplete, stripeLoading, setStripeLoading } : { themeColor?: string, fineText?: string, onComplete: (error, token) => void, stripeLoading?: boolean, setStripeLoading?: (val: boolean) => void }): JSX.Element {
  const stripe = useStripe();

  const [routingNumber, setRoutingNumber]= useState<string>()
  const [accountNumber, setAccountNumber]= useState<string>()
  const [accountNumber2, setAccountNumber2]= useState<string>()
  const [name, setName]= useState<string>()

  const handleSubmit = async () => {
    if (!stripe) {
      handleOnComplete('An error occured', null)
    } else if (accountNumber !== accountNumber2) {
      handleOnComplete('Account numbers must match.', null)
    } else {
      const {error, token } = await stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: routingNumber,
        account_number: accountNumber,
        account_holder_name: name,
        account_holder_type: 'individual',
      });

      handleOnComplete(error, token)
    }
  };

  const onSubmit = () => {
    if (setStripeLoading) {
      setStripeLoading(true)
      handleSubmit()
    }
  }

  const handleOnComplete = (error, token) => {
    if (setStripeLoading) {
      setStripeLoading(false);
    }
    onComplete(error, token)
  }

  return (
    <View style={{ alignItems: 'center' }}>
      <Input key={`bank-account-holder`} style={{ marginBottom: 15, width: '100%' }} name={"Account Holder Name"} value={name} placeholder="Enter Routing Number..." setValue={setName} />
      <Input key={`bank-account-routing`} style={{ marginBottom: 15, width: '100%' }} name={"Routing Number"} value={routingNumber} placeholder="Enter Routing Number..." setValue={setRoutingNumber} />
      <Input key={`bank-account-number`} style={{ marginBottom: 15, width: '100%' }} name={"Account Number"} value={accountNumber} placeholder="Enter Account Number..." setValue={setAccountNumber} />
      <Input key={`bank-account-number2`} style={{ marginBottom: 15, width: '100%' }} name={"Confirm Account Number"} value={accountNumber2} placeholder="Re-enter Account Number..." setValue={setAccountNumber2} />
      <Text style={{ fontFamily: 'Gotham', color: Constants.colors.secondary, fontSize: 10 }}>{fineText}</Text>
      <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 35 }}>
        <Button style={{ width: '100%', marginTop: 20 }} loading={stripeLoading} shadow={false} buttonStyles={{ backgroundColor: themeColor ? themeColor : Constants.colors.blue }} text="Confirm" width={'100%'} theme={Theme.PRIMARY} onPress={onSubmit} />
      </View>
    </View>
  );
}

export default function StripeCardElement({ themeColor, fineText, onComplete, stripeLoading, setStripeLoading } : { themeColor?: string, fineText?: string, onComplete: (error, payentMethod) => void, stripeLoading?: boolean, setStripeLoading?: (val: boolean) => void }): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <BankForm themeColor={themeColor} fineText={fineText} onComplete={onComplete} stripeLoading={stripeLoading} setStripeLoading={setStripeLoading} />
    </Elements>
  );
}

const styles = StyleSheet.create({
  thumbnail: {
    borderRadius: 10,
    backgroundColor: '#FFF',
    fontWeight: 'bold',
    position: 'relative',
    width: 285
  },
  imageWrapper: {
    height: 145,
    width: '100%',
    backgroundColor: Constants.colors.secondaryLight,
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    padding: 30,
    fontSize: 16,
    fontWeight: 'bold',
    color: Constants.colors.blue,
    fontFamily: 'GothamMedium'
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
  }
});
