import { Text, View, TextInput, StyleSheet, KeyboardTypeOptions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Constants from './../constants';
import Dropdown, { Item } from './Dropdown';
import { Label, Info } from './Input';
import { GET_USERS } from './../graphql/queries';
import { useQuery } from "@apollo/client";
import Spinner from './Spinner';

export default function UserDropdown({ value, setValue, placeholder, label, info, small=false, multiple=true, filterSelf=true, style={} } : { value:string|string[]|undefined, setValue:Function, placeholder?: string, label?: string, info?: string, small?: boolean, multiple?:boolean, filterSelf?:boolean, style?:object }): JSX.Element {
  const [userItems, setUserItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedText, setSelectedText] = useState<string>();

  const { data } = useQuery(GET_USERS)

  useEffect(() => {
    if (data?.users?.users) {
      let users = []
      for (let i = 0; i < data.users.users.length; i++) {
        let c = data.users.users[i];
        users.push({value: c.id, label: c.name})
      }
      setUserItems(users)
      setLoading(false)
    }
  }, [data])

  // useEffect(() => {
  //   if (Array.isArray(value)) {
  //     let user_one;
  //     if (value.length > 0) {
  //       const uindex = userItems.findIndex(u => u.value === value[0]);
  //       if (uindex > -1) {
  //         user_one = userItems[uindex].label;
  //       }
  //     }
  //
  //     if (value.length === 2) {
  //       const uindex = userItems.findIndex(u => u.value === value[1]);
  //       let user_two = '';
  //       if (uindex > -1) {
  //         user_two = userItems[uindex].label;
  //       }
  //       setSelectedText(`${user_one} and ${user_two}`)
  //     } else if (value.length === 1) {
  //       setSelectedText(`${user_one}`)
  //     } else if (value.length > 2) {
  //       setSelectedText(`${user_one} and ${value.length - 1} other${value.length - 1 > 1 ? 's' : ''}`)
  //     } else {
  //       setSelectedText(undefined)
  //     }
  //   }
  // }, [value])

  const renderEmptyList = () => {
    if (loading) {
      return <Spinner />
    }
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Text style={{ color: Constants.colors.info, textAlign: 'center' }}>No Users Found</Text>
      </View>
    )
  }

  return (
    <View style={[{ position: 'relative' }, style]}>
      { label ? <Label name={label} /> : null }
      { info ? <Info info={info} /> : null }
      <Dropdown
        key={`dropdown-users`}
        style={{ position: 'relative', zIndex: 1 }}
        placeholder={placeholder ? placeholder : "Select Users"}
        items={userItems}
        multiple={multiple}
        value={multiple && !value ? [] : value}
        onChange={setValue}
        small={small}
        selectedText={'{count} user(s)'}
        ListEmptyComponent={renderEmptyList()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    backgroundColor: '#FFF'
  },
  label: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  info: {
    color: Constants.colors.secondary,
    marginBottom: 5
  },
  sketchPicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
  },
  colorInput: {
    borderRadius: 4,
    height: 15
  },
  colorWrapper: {
    position: 'relative',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 10,
    borderRadius: 7,
    cursor: 'pointer',
    zIndex: 10,
    backgroundColor: '#FFF'
  }
});

const cssStyles = {
  icon: {
    width: 30,
    height: 30,
    color: Constants.colors.info
  },
  input: {
    boxSizing: 'border-box',
    marginBottom: 20,
    border: '1px solid ' + Constants.colors.info + '44',
    padding: 5,
    width: '100%',
    height: 40,
    borderRadius: 7,
    flex: 1
  }
}
