import { Text, View, Pressable, StyleSheet, ActivityIndicator } from 'react-native';
import React, { useState } from 'react';
import Constants from './../constants';
import { XIcon } from '@heroicons/react/outline';


export default function CloseIcon({ onPress } : { onPress:Function }): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);

  const onHover = () => {
    setHovering(true);
  }

  const onHoverOut = () => {
    setHovering(false);
  }

  return (
    <View style={styles.container}>
      <XIcon style={{...cssStyles.xIcon, opacity: hovering ? 0.6 : 1 }} onMouseEnter={onHover} onMouseLeave={onHoverOut} onClick={onPress} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 30,
    right: 30,
    cursor: 'pointer',
    zIndex: 10
  }
});

const cssStyles = {
  xIcon: {
    width: 30,
    height: 30,
    color: Constants.colors.secondary,
    cursor: 'pointer'
  }
}
