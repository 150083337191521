import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import Constants from '../constants';
import { UserContext } from '../contexts/user';
import CampaignOverview from '../components/CampaignOverview';
import Navbar from '../components/Navbar';
import Spinner from '../components/Spinner';
import ModalBody from '../components/ModalBody';
import ModalFooter from '../components/ModalFooter';
import Thumbnail from '../components/Shared/Thumbnail';
import Input, { Label } from '../components/Input';
import { Button, Theme } from '../components/Button';
import { useNavigate } from '../react-router';
import { GET_CAMPAIGNS } from '../graphql/queries';
import { CampaignContext, campaignReducer, WpFullCampaign, WpFeatureType, WpCampaignStructure } from '../contexts/campaign';
import { WpCampaign } from '../contexts/user';
import { CREATE_CAMPAIGN, UPDATE_CAMPAIGN } from '../graphql/campaign';
import { useLazyQuery, useMutation } from "@apollo/client";
import { DashboardNav } from '@whistlepitch/wp-components'
import NewButton from '../components/NewButton';
import UserDetailsModal from '../components/User/UserDetailsModal';
import BasicLayout from '../components/Shared/BasicLayout';


export default function Campaigns(): JSX.Element {
  const { state } = React.useContext(UserContext);
  const [campaigns, setCampaigns] = useState<WpFullCampaign[]>([]);
  const [search, setSearch] = useState<string>('');
  const [hovering, setHovering] = useState<"campaigns" | "leads">();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);

  const navigate = useNavigate();

  const [getCampaigns, { data, loading, refetch }] = useLazyQuery(GET_CAMPAIGNS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [createCampaign, createCampaignData] = useMutation(CREATE_CAMPAIGN, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (!data) {
      loadCampaigns();
    }
  }, [state.user])

  useEffect(() => {
    if (data?.campaigns?.campaigns) {
      setCampaigns(data.campaigns.campaigns)
    }
  }, [data])

  useEffect(() => {
    if (createCampaignData?.data?.createCampaign?.campaign) {
      openCampaign(createCampaignData.data.createCampaign.campaign.id);
    }
  }, [createCampaignData?.data])

  const onDashboardAction = (e: any) => {
    if (e === 'users') {
      navigate('/users');
    } else if (e === 'account') {
      setShowUserDetails(true);
    }
  }

  const closeUserDetails = () => {
    setShowUserDetails(false);
  }


  const loadCampaigns = () => {
    if (state.user) {
      if (data) {
        refetch({
          includeFeatures: false,
          includeStats: false
        });
      } else {
        getCampaigns({
          variables: {
            includeFeatures: false,
            includeStats: false
          }
        });
      }
    }
  }

  const onHoverCampaigns = () => {
    setHovering("campaigns");
  }

  const onHoverOut = () => {
    setHovering(undefined);
  }

  const onCreateCampaign = () => {
    createCampaign()
  }

  const openCampaign = (campaignId: string, isV2: boolean) => {
    if (isV2) {
      let domain = __DEV__ ? 'http://localhost:3000' : 'https://main.d16ta6dyg5gp.amplifyapp.com';
      window.location.href = `${domain}/campaigns/${campaignId}?token=${state.user?.token}`
    } else {
      navigate(`/campaign/${campaignId}`)
    }
  }

  const onSearch = (val?: string) => {
    if (val) {
      setSearch(val);
    } else {
      setSearch('');
    }
  }

  const getFirstImage = (campaignStructure: WpCampaignStructure[]) => {
    let image;

    for (let i = 0; i < campaignStructure.length; i++) {
      if (campaignStructure[i].featureType === WpFeatureType.PITCH) {
        let structure = campaignStructure[i].structure;
        for (let j = 0; j < structure.length; j++) {
          if (structure[j].id !== "redirect" && structure[j].thumbnail) {
            image = structure[j].thumbnail
          }
          if (image) {
            break;
          }
        }
      }
      if (image) {
        break;
      }
    }

    return image;
  }

  const renderBody = (leadOnly = false) => {
    if (loading && !leadOnly) {
      return (
        <View>
          <Spinner />
        </View>
      )
    } else if (state.user && !loading) {
      // <IconButton type={"Primary"} shape={"Circle"} shadow={true}>
      //   <PlusIcon style={{ width: 20, height: 20 }} />
      // </IconButton>

      return (
        <View style={styles.container}>
          <View style={styles.thumbnailWrapper}>
            {campaigns && campaigns.length ? campaigns.filter(c => leadOnly ? c.leadId : !c.leadId).map((campaign, index) => {
              if (search && !campaign.name.toLowerCase().includes(search.toLowerCase())) {
                return null;
              }

              let image = getFirstImage(campaign.structure.feature);
              if (!image) {
                image = getFirstImage(campaign.structure.before);
              }
              if (!image) {
                image = getFirstImage(campaign.structure.appointment);
              }

              // Function to count the number of items in the non-null property
              const countItems = (structure: {
                feature: WpCampaignStructure[],
                appointment: WpCampaignStructure[],
                before: WpCampaignStructure[]
              }) => {
                const calculatePitchSlides = (array: WpCampaignStructure[]) => {
                  return array.reduce((sum, item) => {
                    if (item.featureType === "PITCH") {
                      const slides = item.structure.filter(s => s.id !== 'redirect');
                      return sum + slides.length;
                    }
                    return sum;
                  }, 0);
                };

                if (structure.appointment && Array.isArray(structure.appointment) && structure.appointment.length > 0) {
                  return structure.appointment.length + calculatePitchSlides(structure.appointment);
                } else if (structure.before && Array.isArray(structure.before) && structure.before.length > 0) {
                  return structure.before.length + calculatePitchSlides(structure.before);
                } else if (structure.feature && Array.isArray(structure.feature) && structure.feature.length > 0) {
                  return structure.feature.length + calculatePitchSlides(structure.feature);
                }
                return 0;
              };

              const itemCount = countItems(campaign.structure);

              return (
                <Thumbnail
                  key={`campaign-${campaign.id}-${index}`}
                  text={campaign.name}
                  subtext={campaign.v2 && campaign.leadName ? `For ${campaign.leadName}` : `${itemCount} ${itemCount === 1 ? 'step' : 'steps'}`}
                  image={image}
                  icon="bars"
                  onPress={() => openCampaign(campaign.id, campaign.v2)}
                />
              );
            }) : <Text style={{ width: '100%', textAlign: 'center', color: Constants.colors.secondary }}>- No {Constants.blastView ? 'Blasts' : 'Campaigns'} Found -</Text>}
          </View>
        </View>
      )
    } else {
      return (
        <View>
        </View>
      )
    }
  }

  // return (
  //   <View style={{ flex: 1, flexDirection: 'row', backgroundColor: Constants.colors.backgroundLight }}>
  //     <Navbar active={'/campaigns'} />
  //     <View style={[{ flex: 1, padding: 25 }, styles.container]}>
  //       <UserDetailsModal isVisible={showUserDetails} user={state.user ? state.user : undefined} onClose={closeUserDetails} />
  //       <DashboardNav name={state.user?.name ?? ''} dropOptions={Constants.dropdownOptions} actionOne={state.user?.wpAdmin} onAction={onDashboardAction} onDropdownSelect={onDashboardAction} />
  //       <View style={{ marginTop: 25 }}></View>
  //       { renderBody() }
  //     </View>
  //   </View>
  // );

  return (
    <BasicLayout style={styles.container} active={'/campaigns'} onSearch={onSearch}>
      <View style={{ ...styles.container, paddingBottom: 0 }}>
        <View style={styles.header}>
          <Text style={styles.heading}>{Constants.blastView ? 'Blasts' : 'Campaigns'}</Text>
          <NewButton name={Constants.blastView ? 'Blast' : 'Campaign'} onClick={onCreateCampaign} loading={createCampaignData?.loading} />
        </View>
      </View>
      {renderBody()}
      {state.user?.organization.v2 ?
        <View style={{ ...styles.container, paddingBottom: 0 }}>
          <View style={styles.header}>
            <Text style={styles.heading}>{Constants.localizations.Lead} {Constants.localizations.Campaigns}</Text>
          </View>
        </View> : <></>}
      {state.user?.organization.v2 ? renderBody(true) : <></>}
    </BasicLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
    backgroundColor: Constants.colors.backgroundLight
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    marginTop: 5
  },
  heading: {
    fontSize: 30,
    color: Constants.colors.blue,
    fontFamily: 'GothamBold',
    marginRight: 10
  },
  newBtn: {
    fontFamily: 'GothamBold',
    fontSize: 15,
    color: Constants.colors.darkBlue,
    marginLeft: 10
  },
  icon: {
    cursor: 'pointer'
  },
  thumbnailWrapper: {
    flexDirection: 'row',
    gap: 36,
    flexWrap: 'wrap'
  }
})
