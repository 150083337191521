import { View } from 'react-native';
import React, { useEffect } from 'react';
// import { default as BlastView } from '@whistlepitch/wp-components/build/components/Views/Blastview/BlastView';
import { BlastView } from '@whistlepitch/wp-components';
import { useNavigate } from '../../react-router';
import Constants from '../../constants';
import Spinner from '../../components/Spinner';


export default function BlastViewLandingPage(): JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    if (!__DEV__) {
      window.location.href = 'https://home.blastview.io';
    }
  }, [])

  const openTryNow = () => {
    window.location.href = 'https://app.whistlepitch.com/l/97ELb2';
  }

  const onLogin = () => {
    navigate('/dashboard')
  }

  return (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      { __DEV__ ?
        <BlastView title="Exclusive content subscriptions for your followers" description="Like a text message-based Patreon but 100x simpler to set up and manage." onLogin={onLogin} onGetStarted={openTryNow} /> :
        <Spinner /> }
    </View>
  );
}
