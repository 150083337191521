import React from 'react';
import { WpPitch } from './campaign';
import { WpCustomValue } from './user';

export interface WpFullPitch {
  id: string
  v2: boolean
  title: string
  color: string
  image: string
  redirectType: WpRedirectType
  redirectUrl: string
  redirectPhone: string
  redirectButton: string
  slides: WpPitchSlide[]
  structure: WpPitchStructure[]
}

export interface WpPitchSlide {
  id: string
  title: string
  description: string
  content: string
  internalTitle: string
  url: string
  thumbnail: string
  isVideo: boolean
  processed: boolean
  mimeType: string
  audioFile: string
  slideType: WpSlideType
  widgetStructure: WpSlideStructure[]
  widgets: WpSlideWidget[]
  calendars: WpCustomValue[]
}

export interface WpSlideWidget {
  id: string
  widgetLabel: string
  label: string
  fineText: string
  widgetType: WpWidgetType
  labels: string[]
  start: number
  end: number
}

export interface WpPitchStructure {
  id: string
  label: string
  thumbnail: string
  slideType: WpSlideType
  isVideo: boolean
  structure: WpSlideStructure[]
  type: WpRedirectType
  phone: string
  url: string
  button: string
}

export interface WpSlideStructure {
  id: string
  label: string
  options: string[]
  type: WpWidgetType
}

interface WpPitchState {
  pitch: WpFullPitch | null,
  pitches: WpPitch[]
}

interface WpPitchSlideState {
  slide: WpPitchSlide | null,
  editing: string | null
}

export const initialState: WpPitchState = {
  pitch: null,
  pitches: []
}

export const initialSlideState: WpPitchSlideState = {
  slide: null,
  editing: null
}

export enum WpRedirectType {
  LINK = "LINK",
  CONTACT = "CONTACT",
}

export enum WpSlideType {
  YOUTUBE = "YOUTUBE",
  UPLOAD = "UPLOAD",
  RECORD = "RECORD",
  SCREEN = "SCREEN",
  CALENDAR = "CALENDAR",
  TEXT = "TEXT",
  AUDIO = "AUDIO"
}

export enum WpWidgetType {
  YESNO = "YESNO",
  RATING = "RATING",
  SHORT = "SHORT",
  DATE = "DATE",
  CHOICE = "CHOICE",
  CHECKBOX = "CHECKBOX",
  DROPDOWN = "DROPDOWN",
  VERIFY = "VERIFY",
}

export enum WpPitchAction {
  REFRESH_PITCH = "REFRESH_PITCH",
  REFRESH_PITCHES = "REFRESH_PITCHES",
  CLEAR_PITCH = "CLEAR_PITCH",
  CREATE_PITCH_SLIDE = "CREATE_PITCH_SLIDE",
  DELETE_PITCH_SLIDE = "DELETE_PITCH_SLIDE",
}

export enum WpPitchSlideAction {
  REFRESH_PITCH_SLIDE = "REFRESH_PITCH_SLIDE",
  CLEAR_PITCH_SLIDE = "CLEAR_PITCH_SLIDE",
  SET_EDITING_WIDGET = "SET_EDITING_WIDGET",
  SAVE_WIDGET = "SAVE_WIDGET",
  DELETE_WIDGET = "DELETE_WIDGET",
  CLEAR_EDITING_WIDGET = "CLEAR_EDITING_WIDGET"
}

interface WpPitchActions {
  type: WpPitchAction,
  pitch?: WpFullPitch,
  pitches?: WpPitch[],
  structure?: WpPitchStructure,
  slide?: WpPitchSlide
}

interface WpPitchSlideActions {
  type: WpPitchSlideAction,
  slide: WpPitchSlide,
  editing?: string,
  structure?: WpSlideStructure[]
}

export const PitchContext = React.createContext<{
  state: WpPitchState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});

export const pitchReducer = (state: WpPitchState, action: WpPitchActions) => {
  let sindex = null;

  switch (action.type) {
    case WpPitchAction.REFRESH_PITCH:
      const { pitch } = action;

      return {
        ...state,
        pitch: pitch
      }
    case WpPitchAction.REFRESH_PITCHES:
      const { pitches } = action;

      return {
        ...state,
        pitches: pitches
      }
    case WpPitchAction.CLEAR_PITCH:
      return {
        ...state,
        pitch: null
      }
    case WpPitchAction.CREATE_PITCH_SLIDE:
      return {
        ...state,
        pitch: {
          ...state.pitch,
          structure: action.structure,
          slides: [...state.pitch?.slides ?? [], action.slide]
        }
      }
    case WpPitchAction.DELETE_PITCH_SLIDE:
      sindex = state.pitch?.slides.findIndex(slide => slide.id === action.slide?.id)

      return {
        ...state,
        pitch: {
          ...state.pitch,
          structure: action.structure,
        }
      }
    default:
      return state;
  }
};

export const PitchSlideContext = React.createContext<{
  state: WpPitchSlideState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialSlideState,
  dispatch: () => null
});

export const slideReducer = (state: WpPitchSlideState, action: WpPitchSlideActions) => {
  switch (action.type) {
    case WpPitchSlideAction.REFRESH_PITCH_SLIDE:
      const { slide } = action;

      return {
        ...state,
        slide: slide
      }
    case WpPitchSlideAction.CLEAR_PITCH_SLIDE:
      return {
        ...state,
        slide: null
      }
    case WpPitchSlideAction.SET_EDITING_WIDGET:
      return {
        ...state,
        slide: {
          ...state.slide,
          widgetStructure: action.structure ? action.structure : state.slide?.widgetStructure
        },
        editing: action.editing
      }
    case WpPitchSlideAction.CLEAR_EDITING_WIDGET:
      let structure = state.slide?.widgetStructure ?? [];
      let windex = structure.findIndex(widget => widget.id === 'new');
      if (windex > -1) {
        structure = [
          ...structure.slice(0, windex),
          ...structure.slice(windex + 1)
        ]
      }
      return {
        ...state,
        slide: {
          ...state.slide,
          widgetStructure: structure
        },
        editing: null
      }
    case WpPitchSlideAction.SAVE_WIDGET:
      return {
        ...state,
        slide: {
          ...state.slide,
          widgetStructure: action.structure ? action.structure : state.slide?.widgetStructure
        },
        editing: undefined
      }
    case WpPitchSlideAction.DELETE_WIDGET:
      return {
        ...state,
        slide: {
          ...state.slide,
          widgetStructure: action.structure ? action.structure : state.slide?.widgetStructure
        },
        editing: undefined
      }
    default:
      return state;
  }
};
