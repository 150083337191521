import { View, Text, Image, StyleSheet, Linking } from 'react-native';
import React, { useState, useEffect } from 'react';

import Constants from './../../constants';
import { Button, Theme } from './../Button';
import WpModal from './../WpModal';
import ModalBody from './../ModalBody';
import { WpRedirectType } from './../../contexts/pitch';


export default function Controls({ onContinue, onBack, showContinue, isFirst, isLast, redirectType, redirectUrl, redirectPhone, redirectButton, redirectPreview, campaignFeatureCode, campaignLeadCode, color }: { onContinue: Function, onBack: Function, showContinue?: boolean, isFirst?: boolean, isLast?: boolean, redirectType?: WpRedirectType, redirectUrl?: string, redirectPhone?: string, redirectButton?: string, redirectPreview?: boolean, campaignFeatureCode?: string, campaignLeadCode?: string, color?: string }): JSX.Element {
  const [aspectRatio, setAspectRatio] = useState(1)
  const [isVisible, setIsVisible] = useState(false)

  const onRedirect = () => {
    if (redirectType === WpRedirectType.CONTACT && redirectPhone) {
      onOpenModal();
    } else if (redirectType === WpRedirectType.LINK && redirectUrl) {
      let url = redirectUrl;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`;
      }

      // console.log("Url", `${Constants.base_url}/redirect/${campaignLeadCode}/${campaignFeatureCode}?url=${encodeURIComponent(url)}`)
      // http://localhost:4000/redirect/JvKCsK/wGXx89?url=www.whistlepitch.com
      if (redirectPreview) {
        window.open(url, '_blank');
      } else {
        window.location.href = `${Constants.base_url}/redirect/${campaignLeadCode}/${campaignFeatureCode}?url=${encodeURIComponent(url)}`;
      }
    } else {
      onContinue();
    }
  }

  const renderRedirect = () => {
    if (isLast && showContinue) {
      return <Button shadow={false} buttonStyles={{ paddingHorizontal: 20, backgroundColor: color ? color : Constants.colors.blue }} text={redirectButton && redirectType ? redirectButton : 'Continue'} width={'auto'} theme={Theme.PRIMARY} onPress={onRedirect} />
    } else if (showContinue) {
      return <Button shadow={false} buttonStyles={{ backgroundColor: color ? color : Constants.colors.blue }} text="Continue" width={125} theme={Theme.PRIMARY} onPress={onContinue} />
    } else {
      return null
    }
  }

  const onOpenModal = () => {
    setIsVisible(true);
  }

  const onCloseModal = () => {
    setIsVisible(false);
  }

  const prompText = () => {
    window.location.href = `sms:${redirectPhone}`;
  }

  const prompCall = () => {
    window.location.href = `tel:${redirectPhone}`;
  }

  return (
    <View style={styles.controls}>
      <WpModal isVisible={isVisible}>
        <ModalBody title={"Call/Text"} closeModal={onCloseModal}>
          <Button shadow={false} style={{ marginTop: 15 }} text={`Text ${redirectPhone}`} width={'100%'} theme={Theme.PRIMARY} onPress={prompText} />
          <Button shadow={false} style={{ marginTop: 15 }} text={`Call ${redirectPhone}`} width={'100%'} theme={Theme.ACCENT} onPress={prompCall} />
        </ModalBody>
      </WpModal>
      { renderRedirect() }
    </View>
  );
}

const styles = StyleSheet.create({
  controls: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  brand: {
    height: 30
  }
})
